<div *ngIf="savingContent" class="overlay">
  <aacms-loader class="savingContentLoader">
  </aacms-loader>
</div>
<app-toolbar></app-toolbar>
<div class="router-outlet">
  <router-outlet></router-outlet>
</div>

<div class="messages">
  <div *ngFor="let alert of alerts">
    <alert class="alert-text" [type]="alert.type" [dismissible]="true" (onClosed)="onClosed(alert)"
      [dismissOnTimeout]="alert.timeout">
      <div *ngIf="alert.style === AlertStyle.ALERT_SIMPLE">
        {{alert.text}}
      </div>
      <div *ngIf="alert.style === AlertStyle.ALERT_WITH_BOLD_VALUE_IN_MIDDLE">
        {{alert.firstText}}
        <b>{{alert.value}}</b>
        {{alert.secondText}}
      </div>
      <div *ngIf="alert.style === AlertStyle.ALERT_WITH_URL">
        {{alert.text}}
        <br>
        <a [href]="alert.url">{{alert.url}}</a>
      </div>
    </alert>
  </div>
</div>
