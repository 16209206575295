import { Location } from "./Location";
import { MultiLanguageText } from "./MultiLanguageText";
import { ElementType, UiElement } from "./UiElements";

export abstract class GeoJsonElement {
    public type: string;

    constructor(type: string) {
        this.type = type;
    }

    public abstract equal(element: GeoJsonElement): boolean;
}

export class GeoJsonProperties {
    public topRight: Location = new Location();
    public bottomLeft: Location = new Location();
    public zoomLevel: number = 0;
    public filename: string;

    constructor(properties?: any) {
        if (properties) {
            if (properties.topRight !== undefined) {
                this.topRight = new Location(properties.topRight);
            }
            if (properties.bottomLeft !== undefined) {
                this.bottomLeft = new Location(properties.bottomLeft);
            }
            if (properties.zoomLevel !== undefined) {
                this.zoomLevel = properties.zoomLevel;
            }
            if (properties.filename !== undefined) {
                this.filename = properties.filename;
            }
        }
    }

    public equal(geoJsonProperties: GeoJsonProperties): boolean {
        if (
            !this.topRight.equal(geoJsonProperties.topRight) ||
            !this.bottomLeft.equal(geoJsonProperties.bottomLeft) ||
            this.zoomLevel !== geoJsonProperties.zoomLevel ||
            this.filename !== geoJsonProperties.filename
        ) {
            return false;
        }
        return true;
    }
}

export class GeoJson extends GeoJsonElement {
    public features: GeoJsonFeature[] = [];
    public properties: GeoJsonProperties = new GeoJsonProperties();

    constructor(json?: any) {
        super('FeatureCollection');
        if (json) {
            if (json.properties) {
                this.properties = new GeoJsonProperties(json.properties);
            } else {
                this.properties = new GeoJsonProperties();
            }
            if (json.features) {
                this.features = [];
                for(const feature of json.features) {
                    this.features.push(new GeoJsonFeature(feature));
                }
            }
        }
    }

    public equal(geoJsonElement: GeoJson): boolean {
        if (
            !this.properties.equal(geoJsonElement.properties) ||
            this.features.length !== geoJsonElement.features.length
        ) {
            return false;
        }
        for (let i = 0; i < this.features.length; i++) {
            if (!this.features[i].equal(geoJsonElement.features[i])) {
              return false;
            }
        }
        return true;
    }
}

export class GeoJsonFeatureProperties {
    public trackerId: string;
    public radius: number = 25;
    public id: string;
    public active = true;
    public title: MultiLanguageText = new MultiLanguageText();
    public position: number;

    constructor(properties?: any) {
        if (properties) {
            if (properties.trackerId !== undefined) {
                this.trackerId = properties.trackerId;
            }
            if (properties.radius !== undefined) {
                this.radius = properties.radius;
            }
            if (properties.id !== undefined) {
                this.id = properties.id;
            }
            if (properties.active !== undefined) {
                this.active = properties.active;
            }
            if (properties.title !== undefined) {
                this.title = new MultiLanguageText(properties.title);
            }
            if (properties.position !== undefined) {
                this.position = properties.position;
            }
        }
    }

    public equal(properties: GeoJsonFeatureProperties): boolean {
        if (
            this.trackerId !== properties.trackerId ||
            this.radius !== properties.radius ||
            this.id !== properties.id ||
            this.active !== properties.active ||
            !this.title.equal(properties.title) ||
            this.position !== properties.position
        ) {
            return false;
        }
        return true;
    }
}

export class GeoJsonFeature extends GeoJsonElement {
    public properties: GeoJsonFeatureProperties = new GeoJsonFeatureProperties();
    public geometry: GeometryGeoJsonElement;

    constructor(json?: any) {
        super('Feature');
        if (json) {
            if (json.properties) {
                this.properties = new GeoJsonFeatureProperties(json.properties);
            } else {
                this.properties = new GeoJsonFeatureProperties();
            }
            if (json.geometry) {
                switch(json.geometry.type) {
                    case 'Polygon':
                        this.geometry = new GeoJsonPolygon(json.geometry);
                        break;
                    case 'Point':
                        this.geometry = new GeoJsonPoint(json.geometry);
                        break;
                    case 'LineString':
                        this.geometry = new GeoJsonLineString(json.geometry);
                        break;
                    case 'MultiPoint':
                        this.geometry = new GeoJsonMultiPoint(json.geometry);
                        break;
                    case 'MultiLineString':
                        this.geometry = new GeoJsonMultiLineString(json.geometry);
                        break;
                    case 'MultiPolygon':
                        this.geometry = new GeoJsonMultiPolygon(json.geometry);
                        break;
                }
            }
        }
    }

    public equal(feature: GeoJsonFeature): boolean {
        if (
            !this.properties.equal(feature.properties) ||
            (this.geometry && !feature.geometry) ||
            (!this.geometry && feature.geometry) ||
            (this.geometry && !this.geometry.equal(feature.geometry))
        ) {
            return false;
        }
        return true;
    }
}

export abstract class GeometryGeoJsonElement extends GeoJsonElement {
    abstract coordinates: any;
    constructor(type: string) {
        super(type);
    }

    abstract coordinatesValid(): boolean;
}

export class GeoJsonPoint extends GeometryGeoJsonElement {
    public coordinates: [number, number];

    constructor(json?: any) {
        super('Point');
        if (json) {
            if (json.coordinates) {
                this.coordinates = [json.coordinates[0], json.coordinates[1]];
            }
        }
    }

    public equal(geoJsonPoint: GeoJsonPoint): boolean {
        if (
            (this.coordinates && !geoJsonPoint.coordinates) ||
            (!this.coordinates && geoJsonPoint.coordinates) ||
            this.coordinates.length !== geoJsonPoint.coordinates.length ||
            this.coordinates[0] !== geoJsonPoint.coordinates[0] ||
            this.coordinates[1] !== geoJsonPoint.coordinates[1]
        ) {
            return false;
        }
        return true;
    }

    public setCoordinates(lat: number, lon: number): void {
        this.coordinates = [lat, lon];
    }

    public coordinatesValid(): boolean {
        if (this.coordinates === undefined) {
            return false;
        }
        if (
            this.coordinates[0] === undefined || this.coordinates[1] === undefined ||
            this.coordinates[0] === null || this.coordinates[1] === null ||
            isNaN(this.coordinates[0]) || isNaN(this.coordinates[1])
        ) {
            return false;
        }
        return true;
    }
}

export class GeoJsonLineString extends GeometryGeoJsonElement {
    public coordinates: Array<[number, number]>;
    
    constructor(json?: any) {
        super('LineString');
        if (json) {
            if (json.coordinates && json.coordinates.length > 0) {
                this.coordinates = [];
                for(const point of json.coordinates) {
                    this.coordinates.push([point[0], point[1]]);
                }
            }
        }
    }

    public equal(geoJsonLineString: GeoJsonLineString): boolean {
        if (
            (this.coordinates && !geoJsonLineString.coordinates) ||
            (!this.coordinates && geoJsonLineString.coordinates) ||
            this.coordinates.length !== geoJsonLineString.coordinates.length
        ) {
            return false;
        }
        for (let i = 0; i < this.coordinates.length; i++) {
            if (
                this.coordinates[i][0] !== geoJsonLineString.coordinates[i][0] ||
                this.coordinates[i][1] !== geoJsonLineString.coordinates[i][1] ||
                this.coordinates[i][0] === null || this.coordinates[i][1] === null ||
                isNaN(this.coordinates[i][0]) || isNaN(this.coordinates[i][1])
            ) {
                return false;
            }
        }
        return true;
    }

    public setCoordinates(points: Array<[number, number]>): void {
        this.coordinates = points;
    }

    public coordinatesValid(): boolean {
        if (this.coordinates === undefined) {
            return false;
        }
        for (const coordinate of this.coordinates) {
            if (coordinate[0] === undefined || coordinate[1] === undefined) {
                return false;
            }
        }
        return true;
    }
}

export class GeoJsonPolygon extends GeometryGeoJsonElement {
    public coordinates: Array<Array<[number, number]>>;

    constructor(json?: any) {
        super('Polygon');
        if (json) {
            if (json.coordinates && json.coordinates.length > 0) {
                this.coordinates = new Array<Array<[number, number]>>();
                for (const points of json.coordinates) {
                    const array: [number, number][] = [];
                    for (const point of points) {
                        array.push([point[0], point[1]]);
                    }
                    this.coordinates.push(array);
                }
            }
        }
    }

    public equal(geoJsonPolygon: GeoJsonPolygon): boolean {
        if (
            (this.coordinates && !geoJsonPolygon.coordinates) ||
            (!this.coordinates && geoJsonPolygon.coordinates) ||
            this.coordinates.length !== geoJsonPolygon.coordinates.length
        ) {
            return false;
        }
        for (let i = 0; i < this.coordinates.length; i++) {
            if (this.coordinates[i].length !== geoJsonPolygon.coordinates[i].length) {
                return false;
            }
            for (let j = 0; j < this.coordinates[i].length; j++) {
                if (
                    this.coordinates[i][j][0] !== geoJsonPolygon.coordinates[i][j][0] ||
                    this.coordinates[i][j][1] !== geoJsonPolygon.coordinates[i][j][1]
                ) {
                    return false;
                }
            }
        }
        return true;
    }

    public setCoordinates(polygons: Array<Array<[number, number]>>): void {
        this.coordinates = polygons;
    }

    public coordinatesValid(): boolean {
        if (this.coordinates === undefined) {
            return false;
        }
        for (const coordinateContainer of this.coordinates) {
            for (const coordinate of coordinateContainer) {
                if (
                    coordinate[0] === undefined || coordinate[1] === undefined ||
                    coordinate[0] === null || coordinate[1] === null ||
                    isNaN(coordinate[0]) || isNaN(coordinate[1])
                ) {
                    return false;
                }
            }
        }
        return true;
    }
}

export class GeoJsonMultiPoint extends GeometryGeoJsonElement {
    public coordinates: Array<[number, number]>;
    
    constructor(json?: any) {
        super('MultiPoint');
        if (json) {
            if (json.coordinates && json.coordinates.length > 0) {
                this.coordinates = [];
                for(const point of json.coordinates) {
                    this.coordinates.push([point[0], point[1]]);
                }
            }
        }
    }

    public equal(geoJsonMultiPoint: GeoJsonMultiPoint): boolean {
        if (
            (this.coordinates && !geoJsonMultiPoint.coordinates) ||
            (!this.coordinates && geoJsonMultiPoint.coordinates) ||
            this.coordinates.length !== geoJsonMultiPoint.coordinates.length
        ) {
            return false;
        }
        for (let i = 0; i < this.coordinates.length; i++) {
            if (
                this.coordinates[i][0] !== geoJsonMultiPoint.coordinates[i][0] ||
                this.coordinates[i][1] !== geoJsonMultiPoint.coordinates[i][1]
            ) {
                return false;
            }
        }
        return true;
    }

    public setCoordinates(points: Array<[number, number]>): void {
        this.coordinates = points;
    }
    
    public coordinatesValid(): boolean {
        if (this.coordinates === undefined) {
            return false;
        }
        for (const coordinate of this.coordinates) {
            if (
                coordinate[0] === undefined || coordinate[1] === undefined ||
                coordinate[0] === null || coordinate[1] === null ||
                isNaN(coordinate[0]) || isNaN(coordinate[1])
            ) {
                return false;
            }
        }
        return true;
    }
}

export class GeoJsonMultiLineString extends GeometryGeoJsonElement {
    public coordinates: Array<Array<[number, number]>>;
    
    constructor(json?: any) {
        super('MultiLineString');
        if (json) {
            if (json.coordinates && json.coordinates.length > 0) {
                this.coordinates = new Array<Array<[number, number]>>();
                for (const points of json.coordinates) {
                    const array: [number, number][] = [];
                    for (const point of points) {
                        array.push([point[0], point[1]]);
                    }
                    this.coordinates.push(array);
                }
            }
        }
    }

    public equal(geoJsonMultiLineString: GeoJsonMultiLineString): boolean {
        if (
            (this.coordinates && !geoJsonMultiLineString.coordinates) ||
            (!this.coordinates && geoJsonMultiLineString.coordinates) ||
            this.coordinates.length !== geoJsonMultiLineString.coordinates.length
        ) {
            return false;
        }
        for (let i = 0; i < this.coordinates.length; i++) {
            if (this.coordinates[i].length !== geoJsonMultiLineString.coordinates[i].length) {
                return false;
            }
            for (let j = 0; j < this.coordinates[i].length; j++) {
                if (
                    this.coordinates[i][j][0] !== geoJsonMultiLineString.coordinates[i][j][0] ||
                    this.coordinates[i][j][1] !== geoJsonMultiLineString.coordinates[i][j][1]
                ) {
                    return false;
                }
            }
        }
        return true;
    }

    public setCoordinates(lines: Array<Array<[number, number]>>): void {
        this.coordinates = lines;
    }

    public coordinatesValid(): boolean {
        if (this.coordinates === undefined) {
            return false;
        }
        for (const coordinateContainer of this.coordinates) {
            for (const coordinate of coordinateContainer) {
                if (
                    coordinate[0] === undefined || coordinate[1] === undefined ||
                    coordinate[0] === null || coordinate[1] === null ||
                    isNaN(coordinate[0]) || isNaN(coordinate[1])
                ) {
                    return false;
                }
            }
        }
        return true;
    }
}

export class GeoJsonMultiPolygon extends GeometryGeoJsonElement {
    public coordinates: Array<Array<Array<[number, number]>>>;
    
    constructor(json?: any) {
        super('MultiPolygon');
        if (json) {
            if (json.coordinates && json.coordinates.length > 0) {
                this.coordinates = new Array<Array<Array<[number, number]>>>();
                for (const polygons of json.coordinates) {
                    const array: Array<Array<[number, number]>> = [];
                    for (const polygon of polygons) {
                        const array1: Array<[number, number]> = [];
                        for (const point of polygon) {
                            array1.push([point[0], point[1]]);
                        }
                        array.push(array1);
                    }
                    this.coordinates.push(array);
                }
            }
        }
    }

    public equal(geoJsonMultiPolygon: GeoJsonMultiPolygon): boolean {
        if (
            (this.coordinates && !geoJsonMultiPolygon.coordinates) ||
            (!this.coordinates && geoJsonMultiPolygon.coordinates) ||
            this.coordinates.length !== geoJsonMultiPolygon.coordinates.length
        ) {
            return false;
        }
        for (let i = 0; i < this.coordinates.length; i++) {
            if (this.coordinates[i].length !== geoJsonMultiPolygon.coordinates[i].length) {
                return false;
            }
            for (let j = 0; j < this.coordinates[i].length; j++) {
                if (this.coordinates[i][j].length !== geoJsonMultiPolygon.coordinates[i][j].length) {
                    return false;
                }
                for (let k = 0; k < this.coordinates[i][j].length; k++) {
                    if (
                        this.coordinates[i][j][k][0] !== geoJsonMultiPolygon.coordinates[i][j][k][0] ||
                        this.coordinates[i][j][k][1] !== geoJsonMultiPolygon.coordinates[i][j][k][1]
                    ) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    public setCoordinates(polygons: Array<Array<Array<[number, number]>>>): void {
        this.coordinates = polygons;
    }

    public coordinatesValid(): boolean {
        if (this.coordinates === undefined) {
            return false;
        }
        for (const coordinateContainerContainer of this.coordinates) {
            for (const coordinateContainer of coordinateContainerContainer) {
                for (const coordinate of coordinateContainer) {
                    if (
                        coordinate[0] === undefined || coordinate[1] === undefined ||
                        coordinate[0] === null || coordinate[1] === null ||
                        isNaN(coordinate[0]) || isNaN(coordinate[1])
                    ) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
}


export class MapElement extends UiElement {
    public mapObject: GeoJson = undefined;
    public filename: string;
    constructor(mapElement?: MapElement) {
      super(ElementType.MAP, mapElement);
      if (mapElement) {
        this.filename = mapElement.filename;
        if (mapElement.mapObject) {
            this.mapObject = new GeoJson(mapElement.mapObject);
        }
      }
    }

    public setGeoJson(mapObject: GeoJson): void {
        if (this.mapObject) {
            this.mapObject.features = [];
            for (const feature of mapObject.features) {
                this.mapObject.features.push(new GeoJsonFeature(feature));
            }
        } else {
            this.mapObject = mapObject;
        }
    }
  
    public equal(mapElement: MapElement): boolean {
      if (
          !mapElement ||
          this.filename !== mapElement.filename
          ) {
        return false;
      }
      if (
        !this.mapObject && mapElement.mapObject ||
        this.mapObject && !mapElement.mapObject
      ) {
        return false;
      }
      if (
        this.mapObject && mapElement.mapObject &&
        !this.mapObject.equal(mapElement.mapObject)
      ) {
        return false;
      }
      return super.equal(mapElement);
    }

    public cleanForUpLoad(): void {
      
    }
  }