<div class="content">
    <div class="edit-image-left-side">
      <aacms-loader class="imageLoader" size="128px"
        *ngIf="!this.place">
      </aacms-loader>
        <div class="map-container"><div id="map"></div></div>
    </div>

    <div class="edit-image-right-side">
      <ng-template [ngIf]="this.place && this.place.title">
        <div class="header">
          <div class="path-title-container">
            <div class="path">
              <div class="path-option" (click)="this.routeTo(appState.exhibitions)">{{'EXHIBITIONS' | translate}}</div>
              /
              <div class="path-option" (click)="this.routeTo(appState.images)">{{ Tools.shortenString(contextService.currentExhibition && contextService.currentExhibition.title && getLanguageValue(contextService.currentExhibition.title).length > 0 ? getLanguageValue(contextService.currentExhibition.title) : ('TRACKERS' | translate)) }}</div>
              /
              <div class="path-option" (click)="this.routeTo(appState.editPlace)">{{ Tools.shortenString(place && getLanguageValue(place.title).length > 0 ? getLanguageValue(place.title) : ('UNNAMED_PLACE' | translate)) }}</div>
            </div>
            <div class="place-title-edit-container">
              <h1 class="edit-indicator">
                <i class="fa fa-pencil" aria-hidden="true"
                  [ngStyle]="{'color': this.placeChanged || this.infoCardsChanged ? '#ffc107' : 'black' }"></i>
              </h1>
              <div class="place-title-container">
                <h1 class="place-title" *ngIf="this.getLanguageValue(this.place.title).length > 0">
                  {{this.getLanguageValue(this.place.title)}}
                </h1>
                <h1 class="place-title" *ngIf="this.getLanguageValue(this.place.title).length == 0">
                  <i>{{'UNNAMED_PLACE' | translate }}</i>
                </h1>
              </div>
            </div>
          </div>
          <aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
            (languageChanged)="this.updateLanguage($event)">
          </aacms-language-selector>
        </div>
        <div class="custom-hr"></div>
        <div>
          <i>{{ 'PLACE_SETTINGS' | translate }}:</i>
        </div>
        <div class="image-infoCard" (click)="this.selectInfoCard(undefined)"
          [style.border]="!this.selectedInfoCard ? '2px solid #87ceeb': '2px solid gainsboro'">
          <div class="image-infoCard-meta">
            <div class="image-infoCard-title">
              <span *ngIf="this.getLanguageValue(this.place.title).length > 0">
                {{this.getLanguageValue(this.place.title)}}
              </span>
              <i *ngIf="this.getLanguageValue(this.place.title).length === 0">
                {{ 'UNNAMED_PLACE' | translate }}
              </i>
            </div>
          </div>
          <div class="image-infoCard-edit-buttons">
            <button class="image-infoCard-edit-button" (click)="routeToEditPlaceMeta($event)" [matTooltip]="'EDIT' | translate" triggers="hover">
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="custom-hr"></div>
        <div class="infoCard-list-label">
          {{ 'PLACE_INFOCARDS' | translate }}
        </div>
        <div *ngIf="this.place && this.infoCards.length > 0" class="infoCard-info">
          <div class="infoCard-list">
            <ng-template ngFor let-infoCard [ngForOf]="this.infoCards" [ngForTrackBy]="trackBy">
              <div class="infoCard" (click)="selectInfoCard(infoCard)" [style]="getInfoCardListEntryStyle(infoCard)">
                <div class="infoCard-meta" [style]="getInfoCardListTitleStyle(infoCard)">
                  <div class="infoCard-title">
                    <span *ngIf="this.getLanguageValueFromInfoCard(infoCard).length > 0">
                      <b class="infoCard-position">#{{infoCard.position + 1}}</b> {{this.getLanguageValueFromInfoCard(infoCard)}}
                    </span>
                    <i *ngIf="this.getLanguageValueFromInfoCard(infoCard).length === 0">
                      <b class="infoCard-position">#{{infoCard.position + 1}}</b> {{ 'UNNAMED_INFOCARD' | translate }}
                    </i>
                  </div>
                </div>
                <div class="infoCard-edit-buttons">
                  <button class="infoCard-edit-button" *ngIf="config.getEnabledContentTypes().length > 1"
                    (click)="toggleType(infoCard, $event)" [matTooltip]="'TOGGLE_TYPE' | translate" triggers="hover">
                    <i *ngIf="infoCard.contentType == contentTypes.URL" class="fa fa-globe exhibition-icon"
                      aria-hidden="true"></i>
                    <i *ngIf="infoCard.contentType == contentTypes.Custom" class="fa fa-file exhibition-icon"
                      aria-hidden="true"></i>
                  </button>
                  <button class="infoCard-edit-button" (click)="routeToEditInfoCard($event, infoCard)" [matTooltip]="'EDIT' | translate" triggers="hover">
                    <i class="fa fa-pencil exhibition-icon" aria-hidden="true"></i>
                  </button>
                  <button *ngIf="!isInfoCardActive(infoCard)" class="infoCard-edit-button" (click)="toggleActive(infoCard, $event)"
                    [matTooltip]="'ACTIVATE_FOR_PUBLISH' | translate" triggers="hover" >
                    <i class="fa fa-eye-slash exhibition-icon" aria-hidden="true"></i>
                  </button>
                  <button *ngIf="isInfoCardActive(infoCard)" class="infoCard-edit-button" (click)="toggleActive(infoCard, $event)"
                    [matTooltip]="'DEACTIVATE_FOR_PUBLISH' | translate" triggers="hover" >
                    <i class="fa fa-eye exhibition-icon" aria-hidden="true"></i>
                  </button>
                  <button class="infoCard-edit-button" (click)="deleteInfoCard(infoCard, $event)" [matTooltip]="'DELETE' | translate" triggers="hover">
                    <i class="fa fa-trash exhibition-icon" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="move-buttons">
            <button type="submit" class="move-button btn btn-light" (click)="moveInfoCardUp()"
              [matTooltip]="'MOVE_INFOCARD_UP' | translate" matTooltipPosition="left"  >
              <i class="fa fa-angle-up" aria-hidden="true"></i>
            </button>
            <button type="submit" class="move-button btn btn-light" (click)="addNewInfoCard()"
              [matTooltip]="'ADD_NEW_INFOCARD' | translate" matTooltipPosition="left"  >
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
            <button type="submit" class="move-button btn btn-light" (click)="moveInfoCardDown()"
              [matTooltip]="'MOVE_INFOCARD_DOWN' | translate" matTooltipPosition="left"  >
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div *ngIf="this.place && this.infoCards.length === 0" class="no-infoCard-info">
          <i class="no-infoCard-info-text">{{ 'NO_INFOCARDS_AVAILABLE' | translate }}</i>
          <div class="move-buttons">
            <button type="submit" class="move-button btn btn-light" (click)="addNewInfoCard()"
              [matTooltip]="'ADD_NEW_INFOCARD' | translate" matTooltipPosition="left"  >
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="custom-hr"></div>
        <div class="row button-row">
          <button (click)="cancel()" type="button"
            class="btn btn-secondary custom-button">{{'BACK_TO_OVERVIEW' | translate }}</button>
          <button (click)="openModal(deleteModalTemplate)" type="button"
            class="btn btn-danger custom-button">{{'DELETE_PLACE' | translate }}</button>
          <button (click)="openModal(cancelModalTemplate)" type="button"
            [disabled]="!this.placeChanged && !this.infoCardsChanged"
            class="btn btn-warning custom-button">{{'DISCARD' | translate }}</button>
          <button (click)="this.onSave()" type="button" [disabled]="!this.placeChanged && !this.infoCardsChanged"
            class="btn btn-success custom-button">{{'SAVE' | translate }}</button>
        </div>
      </ng-template>
    </div>
  </div>

  <ng-template #deleteModalTemplate>
    <div class="modal-header">
      <h4 *ngIf="!this.place.title.de" class="modal-title text-break pull-left">{{'REALY_DELETE_PLACE' | translate }}</h4>
      <h4 *ngIf="this.place.title.de" class="modal-title text-break pull-left">
        {{'REALY_DELETE_PLACE1' | translate }}
        <i>'{{this.place.title.de}}'</i> {{'REALY_DELETE_PLACE2' | translate }}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-footer">
      <button (click)="modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
      <button (click)="deletePlace()" type="button" class="btn btn-danger">{{'YES_DELETE' | translate }}</button>
    </div>
  </ng-template>

  <ng-template #cancelModalTemplate>
    <div class="modal-header">
      <h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
    </div>
    <div class="modal-footer">
      <button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
      <button (click)="onDiscard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
    </div>
  </ng-template>
