import { Output, EventEmitter, Component, Input } from '@angular/core';

@Component({
	selector: 'input-number',
	templateUrl: './input-number.component.html',
	styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent {

	private numberRegex = /^(?:\-?(\d+[\.,]?\d*|\d*[\.,]\d+))|\-$/;

	private wasUserInput: boolean = false;
	private previousValue: string = "";
	public valueString: string = "";
	@Input() set value(val: number) {
		if(val === null || val === undefined) { return; }
		// ignore events looping from a call to EventEmitter::emit()
		if(this.wasUserInput && val === this.value) { return; }
		this.wasUserInput = false; // reset flag
		this.valueString = this.clamp(val).toString();
		this.valueChange.emit(val);
	}
	get value() {
		if(!this.valueString || this.valueString == '-') { return 0; }
		return this.clamp(parseFloat(this.valueString.replace(',', '.')));
	}
	@Output() valueChange: EventEmitter<number> = new EventEmitter<number>(false);

	@Input() min: number = undefined;
	@Input() max: number = undefined;

	public constructor() { }

	public updateValue(value: string, input: HTMLInputElement): void {
		if(value === "") {
			this.valueChange.emit(this.value);
			return;
		}
		let match = this.numberRegex.exec(value);
		if(!match || match.length === 0 || match[0] !== value) {
			this.valueString = this.previousValue;
			input.value = this.valueString;
		} else {
			this.wasUserInput = true;
			this.valueChange.emit(this.value);
		}
	}

	public onBeforeInput(_event: InputEvent) {
		this.previousValue = this.valueString;
	}

	public onFocusLost(_event) {
		// update input-box representation of current value
		this.valueString = this.value.toString();
	}

	private clamp(val: number): number {
		if(this.max !== undefined) { val = Math.min(val, this.max); }
		if(this.min !== undefined) { val = Math.max(val, this.min); }
		return val;
	}
}
