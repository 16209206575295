import { Output, EventEmitter, Component, Input, ViewChild, ElementRef } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { StringService } from '../services/string.service';
import { AlertType, AlertSimple, AlertWithUrl } from '../models/Alert';

@Component({
  selector: 'dropin',
  templateUrl: './dropin.component.html',
  styleUrls: ['./dropin.component.scss']
})
export class DropinComponent {

  private dropzoneColor = 'transparent';
  public hover = false;

  @ViewChild('input') inputElement: ElementRef;

  @Input() multipleAllowed = true;
  @Input() accept = '';
  @Input() filterFileExtensions: String[] = [];
  @Input() maxFileSize: number;
  @Input() disabled: boolean = false;

  private currentFiles = [];
  @Output() sendFiles: EventEmitter<File[]> = new EventEmitter<File[]>();

  public constructor(
    public alertService: AlertService,
    public stringService: StringService
  ) { }

  public drop(event): void {
    if (!this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      const files = <File[]>event.dataTransfer.files;
      this.handleFiles(files);
    }
  }

  public dragover(event): void {
    event.preventDefault();
    event.dataTransfer.effectAllowed = 'copyMove';
  }

  public dragenter(): void {
    if (!this.disabled) {
      this.dropzoneColor = '#eee';
    }
  }

  public dragleave(): void {
    this.dropzoneColor = 'transparent';
  }

  public getDropzoneStyle(): Object {
    let styles;
    if (this.disabled) {
      styles = {
        'background-color': '#e9ecef'
      };
    } else {
      styles = {
        'background-color': this.dropzoneColor
      };
    }
    return styles;
  }

  public handleFileInput(event): void {
    const files = <File[]>event.target.files;
    this.handleFiles(files);
    this.resetHandleFileEvent();
  }

  private resetHandleFileEvent(): void {
    this.inputElement.nativeElement.value = '';
  }

  private handleFiles(files: File[]): void {
    let filteredFiles: File[] = files;
    if (this.filterFileExtensions.length > 0) {
      filteredFiles = this.filterFilesByExtension(filteredFiles);
    }
    this.currentFiles = this.filterFilesByTypeAndMaxFileSize(filteredFiles);

    this.updateFilesAtParent();
  }

  private filterFilesByTypeAndMaxFileSize(files: File[]): File[] {
    const filesLeft = [];
    for (const file of files) {
      if (!this.isFileTypeAccepted(file)) {
        this.alertService.alert(
          new AlertSimple(
            this.stringService.get('WRONG_FILE_FORMAT'),
            AlertType.Error));
      } else if (file.size > this.maxFileSize) {
        this.alertService.alert(
          new AlertWithUrl(
            this.stringService.get('FILE_TO_BIG1') + ' ' +
            this.formatBytes(this.maxFileSize) + ' ' +
            this.stringService.get('FILE_TO_BIG2') + '\r\n' +
            file.name + ' -> ' + this.formatBytes(file.size) + ' \r\n' +
            this.stringService.get('HOW_TO_COMPRESS'),
            'https://www.youtube.com/watch?v=EaqfOleVrzM',
              AlertType.Error));
      } else {
        filesLeft.push(file);
      }
    }
    return filesLeft;
  }

  private filterFilesByExtension(files: File[]): File[] {
    const acaptableFiles: File[] = [];
    for (const file of files) {
      let valid = true;
      for (const extension of this.filterFileExtensions) {
        if (file.name.endsWith(extension.toString())) {
          this.alertService.alert(
            new AlertSimple(
              this.stringService.get('WRONG_FILE_FORMAT'),
              AlertType.Error));
          valid = false;
        }
      }
      if (valid === true) {
        acaptableFiles.push(file);
      }
    }
    return acaptableFiles;
  }

  private updateFilesAtParent(): void {
    this.sendFiles.emit(this.currentFiles);
  }

  public formatBytes(bytes, decimals = 2): string {
    if (bytes === 0) { return '0 Bytes'; }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
}

  private isFileTypeAccepted(file: File): boolean {
    if (this.accept === '' || this.accept === '*') { return true; }

    this.accept = this.accept.toLocaleLowerCase();
    const fileType = file.type.toLocaleLowerCase();
    const fileTypeExtension = fileType.substring(fileType.indexOf('/') + 1, fileType.length);
    const fileExtension = file.name.substring(file.name.indexOf('.') + 1, file.name.length);

    if (this.accept.includes(fileExtension) || this.accept.includes(fileTypeExtension)) {
      return true;
    }

    const acceptedFileTypes: string[] = this.accept.split(',');
    for (let acceptedFileType of acceptedFileTypes) {
      acceptedFileType = acceptedFileType.trim();

      if (acceptedFileType === fileType) {
        return true;
      } else if (acceptedFileType.includes('/*') && acceptedFileType.includes(fileType.substring(0, fileType.indexOf('/')))) {
        return true;
      }
    }
    return false;
  }

  public useMousePointer(): boolean {
    if (this.hover) {
      if (!this.disabled) {
        return true;
      }
    }
    return false;
  }
}
