import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap, share, map } from 'rxjs/operators';

import { Config } from '../Config';

import { AlertService } from './alert.service';
import { UserConfig } from '../models/UserConfig';
import { AuthService } from './auth.service';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json'
	}),
	observe: 'response' as 'body'
};

@Injectable({
	providedIn: 'root',
})
export class UserConfigService {

	private baseUrl = new Config().GetBaseUrl();

	private configCache: UserConfig | Promise<UserConfig> | null = null;

	public constructor(
		private http: HttpClient,
		private authService: AuthService,
		private alertService: AlertService
	) { }

	private loadConfig(): Promise<UserConfig> {
		return new Promise<UserConfig>((resolve, reject) => {
			this.http.get<UserConfig>(this.getUserConfigUrl()).subscribe(
				config => {
					this.configCache = new UserConfig(config);
					resolve(config);
				},
				error => {
					this.configCache = null;
					this.handleError<UserConfig>('getConfig', error);
					reject(error);
				}
			)
		});
	}

	public async getConfig() : Promise<UserConfig> {
		if(!this.authService.getUser()?.isLoggedIn()) {
			this.configCache = null;
			return null;
		}
		if(this.configCache === null) {
			this.configCache = this.loadConfig();
		}
		if(this.configCache instanceof Promise) {
			return new UserConfig(await this.configCache);
		}
		return new UserConfig(this.configCache);
	}

	/**
	 * @brief Get the currently cached UserConfig instance.
	 * If none is cached yet, a load is internally triggered.
	 * This method is mainly relevant for use in Angular, where it is polled for a value regularly.
	 */
	public getSync() : UserConfig {
		if(!this.authService.getUser()?.isLoggedIn()) {
			this.configCache = null;
			return null;
		}
		if(this.configCache instanceof UserConfig) {
			return this.configCache;
		}
		// start load if required
		if(this.configCache === null) { this.getConfig().then(() => {}); }
	}

	public setConfig(config: UserConfig): Observable<UserConfig> {
		return this.http.put<UserConfig>(this.getUserConfigUrl(), config, httpOptions).pipe(
			map(
				(config: any) => {
					this.configCache = new UserConfig(config.body);
					return new UserConfig(this.configCache);
				},
				error => { this.handleError<UserConfig>('setConfig', error); }
			),
			share()
		);
	}

	/**
	  * Handle Http operation that failed.
	  * Let the app continue.
	  * @param operation - name of the operation that failed
	  * @param result - optional value to return as the observable result
	  * @returns an errorObject or an Object of the given resultType
	  */
	private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
		if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
			this.alertService.serverError();
		}
		return (error: any): Observable<T> => {
			this.logError(`${operation} failed: ${error.message}`);
			return of(result as T);
		};
	}

	/**
	   * triggers when a httpResponse error occurs
	   * will print the value to the console
	   * @param value value to be printed
	   */
	private logError(value: any): void {
		console.error(value);
	}

	/**
	   * returns the url to the FrontendConfig endpoint of the backend
	   * @returns the created url to the FrontendConfig endpoint
	   */
	private getUserConfigUrl(extension?: string): string {
		let url = this.baseUrl;
		if (!url.endsWith('/')) {
		  url = `${url}/`;
		}
		return `${url}userConfig/`;
	}
}
