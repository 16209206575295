import { MultiLanguageData } from "./MultiLanguageData";
import { MultiLanguageFileDataType } from "./MultiLanguageFileDataType";

export class MultiLanguageMediaIdValue {
	public mediaId: string = "";

	constructor(value?: MultiLanguageMediaIdValue) {
		if (!value) { return; }
		this.mediaId = value.mediaId;
	}

	public static fromMediaId(mediaId: string): MultiLanguageMediaIdValue {
		let result = new MultiLanguageMediaIdValue();
		result.mediaId = mediaId;
		return result;
	}

	public equal(value: MultiLanguageMediaIdValue) {
		return (this.mediaId === value.mediaId);
	}
}

export class MultiLanguageMediaId extends MultiLanguageData<MultiLanguageMediaIdValue> {

    constructor(multilangData?: MultiLanguageData<MultiLanguageMediaIdValue>) {
        super(MultiLanguageMediaIdValue, multilangData);
    }

	public static fromLegacy(legacy: MultiLanguageFileDataType): MultiLanguageMediaId {
		return new MultiLanguageMediaId(JSON.parse(JSON.stringify(legacy.languages)));
	}

	public toLegacy() : MultiLanguageFileDataType {
		return new MultiLanguageFileDataType(JSON.parse(JSON.stringify(this.languages)));
	}

}