<div class="modal-header">
  <h4 class="modal-title text-break pull-left">{{'CREATE_NEW_HOLOGRAM' | translate }}</h4>
  <button *ngIf="!uploadInProgress" type="button" class="close pull-right" aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <dropin (sendFiles)="this.handleFilesFromDropin($event)" [maxFileSize]="this.getMaxContentFileSize()"
    [accept]="'.glb'" [filterFileExtensions]="['.gltf']" [disabled]="this.uploadInProgress">
  </dropin>

  <div class="current-files">
    <div *ngFor="let file of currentFiles; trackBy: trackByFn">
      <div class="current-file">
        <span class="current-file-name">{{file.name}}</span>
        <a class="file-delete" *ngIf="!uploadInProgress" (click)="this.removeFile(file)">
          <i class="fa fa-times" aria-hidden="true"></i>
        </a>
        <aacms-loader class="imageLoader" size="16px" *ngIf="!modelLoaded(file) && uploadInProgress">
        </aacms-loader>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="onClose()"
    [disabled]="uploadInProgress">{{'ABORT' | translate }}</button>
  <button type="button" class="btn btn-success" (click)="onCreate()"
    [disabled]="uploadInProgress || this.currentFiles.length === 0">{{'YES_CREATE' | translate}}</button>
</div>
