export class Lock {
    userId : string;       // id des Users, der das Lock hat
    sessionId: string;   // einzigartige Session-ID, beim Login generiert
    resource : string; // Ressource, die gelocked wird. Format `${model}-${id}` bzw. `app-menu` beim Appmenu
    type : string; // welcher Typ. Vorerst immer "full"
    
    // beim Erstellen nicht da, wird da vergeben
    lockId : string;        // UUID des locks, beim POST vergeben
    created : number; // timestamp in ms, wann das Lock vergeben wurde
    validUntil : number; // timestamp in ms, wann das Lock expired
    
    // nur wenn gesperrt, fuer die Anzeige
    username: string;

    constructor(lock?: Lock) {
        if (lock === undefined) { return; }
        this.userId = lock.userId;
        this.sessionId = lock.sessionId;
        this.resource = lock.resource;
        this.type = lock.type;
        this.lockId = lock.lockId;
        this.created = lock.created;
        this.validUntil = lock.validUntil;
        this.username = lock.username;
    }

    public equal(lock: Lock) {
        if (
            this.userId !== lock.userId ||
            this.sessionId !== lock.sessionId ||
            this.resource !== lock.resource ||
            this.type !== lock.type ||
            this.lockId !== lock.lockId ||
            this.created !== lock.created ||
            this.validUntil !== lock.validUntil ||
            this.username !== lock.username
        ) {
            return false;
        }
        return true;
    }
}