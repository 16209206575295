import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable, of } from "rxjs";
import { tap, share } from "rxjs/operators";

import { InfoCard } from "../models/InfoCard";
import { Config } from "../Config";

import { AlertService } from "./alert.service";
import { StringService } from "./string.service";
import { AlertWithBoldValueInMiddle, AlertType } from "../models/Alert";
import { CurrentContextService } from "./currentContext.service";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
  observe: "response" as "body",
};

@Injectable({
  providedIn: "root",
})
export class InfoCardService {
  private baseUrl = new Config().GetBaseUrl();

  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService,
    private contextService: CurrentContextService
  ) {}

  public getInfoCardsByPlaceId(parentPlaceId: string): Observable<InfoCard[]> {
    return this.http
      .get<InfoCard[]>(this.getInfoCardUrl(`?parentPlaceId=${parentPlaceId}`))
      .pipe(
        tap(
          (response) => {
            return response;
          },
          (error) => {
            this.handleError<InfoCard[]>("getInfoCardByImageId", error);
          }
        ),
        share()
      );
  }

  public getInfoCardById(id: string): Observable<InfoCard> {
    return this.http.get<InfoCard>(this.getInfoCardUrl(`${id}`)).pipe(
      tap(
        (response) => {
          return response;
        },
        (error) => {
          this.alertService.alert(
            new AlertWithBoldValueInMiddle(
              this.stringService.get("INFOCARD_NOT_FOUND1"),
              id,
              this.stringService.get("INFOCARD_NOT_FOUND2"),
              AlertType.Error
            ),
            true
          );
          this.handleError<InfoCard>("getInfoCardById", error);
        }
      ),
      share()
    );
  }

  public createInfoCard(infoCard: InfoCard): Observable<any> {
    infoCard.createdTime = new Date().getTime();
    infoCard.updatedTime = infoCard.createdTime;
    return this.http.post<any>(this.getInfoCardUrl(), infoCard, httpOptions).pipe(
      tap(
        (response) => {
          const location = response.headers.get("Location")
            ? response.headers.get("Location")
            : response.headers.get("location");
          const id = location.substring(location.lastIndexOf("/") + 1);
          // infoCard.id = id;
          this.alertService.alert(
            new AlertWithBoldValueInMiddle(
              this.stringService.get("INFOCARD_CREATED2"),
              id,
              this.stringService.get("INFOCARD_CREATED3"),
              AlertType.Success
            ),
            true
          );
        },
        (error) => {
          this.handleError<InfoCard>("createInfoCard", error);
        }
      ),
      share()
    );
  }

  public updateInfoCard(infoCard: InfoCard): Observable<any> {
    infoCard.updatedTime = new Date().getTime();
    const infoCardCopy = new InfoCard(infoCard);
    for (const uiElement of infoCardCopy.uiElements) {
      uiElement.cleanForUpLoad();
    }
    return this.http.put(this.getInfoCardUrl(`${infoCard.id}`), infoCardCopy, httpOptions).pipe(
      tap(
        (response) => {
          if (this.contextService.getInCurrentAppLanguage(infoCard.title) !== "") {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get("INFOCARD_UPDATED1"),
                this.contextService.getInCurrentAppLanguage(infoCard.title),
                this.stringService.get("INFOCARD_UPDATED3"),
                AlertType.Success
              ),
              true
            );
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get("INFOCARD_UPDATED2"),
                infoCard.id,
                this.stringService.get("INFOCARD_UPDATED3"),
                AlertType.Success
              ),
              true
            );
          }
        },
        (error) => {
          this.handleError<InfoCard>("updateInfoCard", error);
        }
      ),
      share()
    );
  }

  public deleteInfoCard(infoCard: InfoCard | string): Observable<any> {
    const id = typeof infoCard === "string" ? infoCard : infoCard.id;

    return this.http.delete(this.getInfoCardUrl(`${id}`), httpOptions).pipe(
      tap(
        (response) => {
          if (
            typeof infoCard === "object" &&
            this.contextService.getInCurrentAppLanguage(infoCard.title) !== ""
          ) {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get("INFOCARD_DELETED1"),
                this.contextService.getInCurrentAppLanguage(infoCard.title),
                this.stringService.get("INFOCARD_DELETED3"),
                AlertType.Info
              ),
              true
            );
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get("INFOCARD_DELETED2"),
                id,
                this.stringService.get("INFOCARD_DELETED3"),
                AlertType.Info
              ),
              true
            );
          }
        },
        (error) => {
          this.handleError<InfoCard>("deleteInfoCard", error);
        }
      ),
      share()
    );
  }

  private handleError<T>(
    operation = "operation",
    error: HttpErrorResponse,
    result?: T
  ): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private logError(value: any): void {
    console.error(value);
  }

  private getInfoCardUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith("/")) {
      url = `${url}/`;
    }
    url = `${url}infocards/`;
    if (extension) {
      if (!(extension.indexOf("?") >= 0)) {
        if (!extension.endsWith("/")) {
          extension = `${extension}/`;
        }
      }
      url = `${url}${extension}`;
    }
    return url;
  }
}
