import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Alert, AlertSimple, AlertType } from '../models/Alert';
import { StringService } from './string.service';


@Injectable({
  providedIn: 'root',
})
export class AlertService {

  private subject = new Subject<Alert>();
  private keepAfterRouteChange = false;

  public constructor(private router: Router, private stringService: StringService) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          this.keepAfterRouteChange = false;
        } else {
          this.clear();
        }
      }
    });
  }

  public getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  public serverError(alert = new AlertSimple(this.stringService.get('INTERNAL_SERVER_ERROR'), AlertType.Error)): void {
    this.keepAfterRouteChange = false;
    this.subject.next(alert);
  }

  public alert(alert: Alert, keepAfterRouteChange: boolean = false): void {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next(alert);
  }

  private clear(): void {
    this.subject.next();
  }
}
