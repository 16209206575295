<!-- Hidden container that displays the poi thumbnail to calculate its size / size ratio -->
<div #shadowContainer class="point-container" style="visibility: hidden;" (resized)="this.onShadowContainerResized($event)">
	<img class="texture-point-image unselectable"
		*ngIf="Tools.isFileImage(textureData.get(this._currentLanguage)) && config.isPoiStylesEnabled() && textureData.get(this._currentLanguage)?.isLoaded === true && textureData.get(this._currentLanguage).fileSafeUrl"
		[src]="textureData.get(this._currentLanguage).fileSafeUrl" draggable="false"
		ondragstart="return false;" />
	<div *ngIf="Tools.isFileModel(textureData.get(this._currentLanguage)) && config.isPoiStylesEnabled()">
		<img class="model"
			*ngIf="!this.textureData.get(this._currentLanguage).media.thumbUrl" src="../../assets/3DModellPlaceholder.png">
		<img #pointImage class="model"
			*ngIf="this.textureData.get(this._currentLanguage).media.thumbUrl" [src]="this.getThumbUrl(textureData.get(this._currentLanguage).media)">
	</div>
</div>

<div #pointContainer class="point-container" *ngIf="this.poi && this.imageView" (click)="selectPoi(); pop.show()"
	[ngStyle]="setPointContainerStyle()" (mouseenter)="this.hovered = true" (mouseleave)="this.hovered = false">

	<div #point class="texture-point">
		<div class="resizers" [style.border]="this.scaleAllowed ? '2px solid yellow' : 'none'">

			<div *ngIf="!config.isPoiStylesEnabled() || !poi.textureId || !textureData.isLoaded" class="point"
				[style.opacity]="this.poi.active ? '0.7' : '0.3'" draggable="false" ondragstart="return false;">
			</div>
			<img class="texture-point-image unselectable"
				*ngIf="Tools.isFileImage(textureData.get(this._currentLanguage)) && config.isPoiStylesEnabled() && textureData.get(this._currentLanguage)?.isLoaded === true && textureData.get(this._currentLanguage).fileSafeUrl"
				[ngStyle]="setPointStyle()" [src]="textureData.get(this._currentLanguage).fileSafeUrl" draggable="false"
				ondragstart="return false;" />
			<div class="model-container"
				*ngIf="Tools.isFileModel(textureData.get(this._currentLanguage)) && config.isPoiStylesEnabled()">
				<img class="model"
					*ngIf="!this.textureData.get(this._currentLanguage).media.thumbUrl && !this.showModel()"
					[ngStyle]="setPointStyle()" src="../../assets/3DModellPlaceholder.png">
				<img #pointImage class="model"
					*ngIf="this.textureData.get(this._currentLanguage).media.thumbUrl && !this.showModel()"
					[ngStyle]="setPointStyle()" [src]="this.getThumbUrl(textureData.get(this._currentLanguage).media)">
				<model-viewer class="model"
					*ngIf="poi.multiLanguageTextureId.get(this._currentLanguage) && this.textureData.get(this._currentLanguage).isLoaded && this.textureData.get(this._currentLanguage).fileSafeUrl && this.showModel()"
					[ngStyle]="setPointStyle()" [attr.src]="textureData.get(this._currentLanguage).fileSafeUrl" autoplay
					draggable="false" ondragstart="return false;" [orientation]="this.getOrientationString()">
				</model-viewer>
			</div>

			<div class="texture-point-position unselectable" [popover]="popTemplate" #pop="bs-popover"
				[placement]="popoverPlacement" outsideClick="true" (keypress)="eventHandler($event)" container="body"
				[adaptivePosition]="false" (keyup.escape)="pop.hide()">
				{{this.poi.position + 1}}
			</div>
			<div class="resizer bottom-right" *ngIf="this.scaleAllowed"
				(mousedown)="this.scaleModeBottomRight = true"></div>
			<div class="resizer bottom-left" *ngIf="this.scaleAllowed"
				(mousedown)="this.scaleModeBottomLeft = true">
			</div>
			<div class="resizer top-right" *ngIf="this.scaleAllowed"
				(mousedown)="this.scaleModeTopRight = true">
			</div>
			<div class="resizer top-left" *ngIf="this.scaleAllowed" (mousedown)="this.scaleModeTopLeft = true">
			</div>
		</div>
	</div>
</div>

<ng-template #popTemplate>
	<div>
		<button *ngIf="!this.poi.id.startsWith('tempId')" type="button" class="btn btn-primary pop-button"
			(click)="editPoint($event)">
			<i class="fa fa-pencil" aria-hidden="true"></i> {{'EDIT' | translate }}
		</button>
		<div>
		</div>
		<button type="button" class="btn btn-warning pop-button" (click)="movePoint($event)">
			<i class="fa fa-arrows-alt" aria-hidden="true"></i> {{'MOVE' | translate}}
		</button>
		<div>
		</div>
		<button type="button" class="btn btn-warning pop-button" (click)="scalePoint($event)">
			<i class="fa fa-expand" aria-hidden="true"></i> {{'SCALE' | translate}}
		</button>
		<div>
		</div>
		<button type="button" class="btn btn-secondary pop-button" (click)="cancelPoint($event)">
			<i class="fa fa-times" aria-hidden="true"></i> {{'ABORT' | translate}}
		</button>
	</div>
</ng-template>