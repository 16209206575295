import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { Config } from '../Config';
import { AlertSimple, AlertType, AlertWithBoldValueInMiddle } from '../models/Alert';
import { Hologram } from '../models/Hologram';
import { AlertService } from './alert.service';
import { CurrentContextService } from './currentContext.service';
import { StringService } from './string.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root',
})
export class HologramService {

  private baseUrl = new Config().GetBaseUrl();

  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService,
    private contextService: CurrentContextService
  ) { }

  public getHolograms(exhibitionId?: string): Observable<Hologram[]> {
    let extension = '';
    if (exhibitionId && exhibitionId !== '') {
      extension = `?exhibitionIds=${exhibitionId}`;
    }
    return this.http.get<Hologram[]>(this.getHologramUrl() + extension).pipe(
      tap(
        response => { },
        error => { this.handleError<Hologram[]>('getHolograms', error); }
      ),
      share()
    );
  }

  public getHologramById(id: string): Observable<Hologram> {
    return this.http.get<Hologram>(this.getHologramUrl(`${id}`)).pipe(
      tap(
        response => { },
        error => {
          this.handleError<Hologram>('getHologramById', error);
          this.alertService.alert(
            new AlertWithBoldValueInMiddle(
              this.stringService.get('HOLOGRAM_NOT_FOUND1'),
              id,
              this.stringService.get('HOLOGRAM_NOT_FOUND2'),
              AlertType.Error),
            true);
        }
      ),
      share()
    );
  }

  public createHologram(hologram: Hologram): Observable<any> {
    hologram.createdTime = new Date().getTime();
    hologram.updatedTime = hologram.createdTime;
    return this.http.post<any>(this.getHologramUrl(), hologram, httpOptions).pipe(
      tap(
        response => {
          if (this.contextService.getInCurrentAppLanguage(hologram.title) !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('HOLOGRAM_CREATED1'),
                this.contextService.getInCurrentAppLanguage(hologram.title),
                this.stringService.get('HOLOGRAM_CREATED3'),
                AlertType.Success),
              true);
          } else {
            const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
            const id = location.substring(location.lastIndexOf('/') + 1);
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('HOLOGRAM_CREATED2'),
                id,
                this.stringService.get('HOLOGRAM_CREATED3'),
                AlertType.Success),
              true);
          }
        },
        error => { this.handleError<Hologram>('createHologram', error); }
      ),
      share()
    );
  }

  public updateHologram(hologram: Hologram): Observable<any> {
    hologram.updatedTime = new Date().getTime();
    const hologramCopy = new Hologram(hologram);
    for (const uiElement of hologramCopy.uiElements) {
      uiElement.cleanForUpLoad();
    }
    return this.http.put(this.getHologramUrl(`${hologram.id}`), hologramCopy, httpOptions).pipe(
      tap(
        response => {
          if (this.contextService.getInCurrentAppLanguage(hologram.title) !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('HOLOGRAM_UPDATED1'),
                this.contextService.getInCurrentAppLanguage(hologram.title),
                this.stringService.get('HOLOGRAM_UPDATED3'),
                AlertType.Success),
              true);
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('HOLOGRAM_UPDATED2'),
                hologram.id,
                this.stringService.get('HOLOGRAM_UPDATED3'),
                AlertType.Success),
              true);
          }
        },
        error => { this.handleError<Hologram>('updateHologram', error); }
      ),
      share()
    );
  }

  public updateHologramActivation(hologram: Hologram): Observable<any> {
    hologram.updatedTime = new Date().getTime();
    return this.http.put(this.getHologramUrl(`${hologram.id}`), hologram, httpOptions).pipe(
      tap(
        response => {
          if (hologram.active) {
            this.alertService.alert(
              new AlertSimple(
                this.stringService.get('HOLOGRAM_ACTIVATED'),
                AlertType.Success),
              true);
          } else {
            this.alertService.alert(
              new AlertSimple(
                this.stringService.get('HOLOGRAM_DEACTIVATED'),
                AlertType.Info),
              true);
          }
        },
        error => { this.handleError<Hologram>('updateHologramActivation', error); }
      ),
      share()
    );
  }

  public deleteHologram(hologram: Hologram | string): Observable<any> {
    const id = typeof hologram === 'string' ? hologram : hologram.id;
    return this.http.delete(this.getHologramUrl(`${id}`), httpOptions).pipe(
      tap(
        response => {
          if (typeof hologram === 'object' && this.contextService.getInCurrentAppLanguage(hologram.title) !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('HOLOGRAM_DELETED1'),
                this.contextService.getInCurrentAppLanguage(hologram.title),
                this.stringService.get('HOLOGRAM_DELETED3'),
                AlertType.Info),
              true);
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('HOLOGRAM_DELETED2'),
                id,
                this.stringService.get('HOLOGRAM_DELETED3'),
                AlertType.Info),
              true);
          }
        },
        error => { this.handleError<Hologram>('deleteHologram', error); }
      ),
      share()
    );
  }

  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private logError(value: any): void {
    console.error(value);
  }

  private getHologramUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}holograms/`;
    if (extension) {
      if (!extension.endsWith('/')) {
        extension = `${extension}/`;
      }
      url = `${url}${extension}`;
    }
    return url;
  }
}
