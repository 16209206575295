<div (ngSubmit)="onConfirm()">
  <div class="modal-header">
    <h4 class="modal-title text-break pull-left">
      {{ 'WARNING' | translate }}
    </h4>
    <button type="button" class="close pull-right" (click)="hideConfirmationModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'UNSAVED_CHANGES' | translate }}</p>
  </div>
  <div class="modal-footer">
    <div class="button-row">
      <button type="submit" class="btn button btn-danger" (click)="onConfirm()">
        {{ 'DISCARD_CHANGES_AND_LEAVE' | translate }}
      </button>
      <button type="button" class="btn button btn-warning" (click)="onCancel()">
        {{ 'STAY' | translate }}
      </button>
      <button type="button" class="btn button btn-success" (click)="saveAndCancel()">
        {{ 'SAVE_AND_LEAVE' | translate }}
      </button>
    </div>
  </div>
</div>
