<div class="meta">
	<div class="smartphone-left-side">
		<div class="checkbox-container" [ngClass]="{'disabled': this.forceVisible}">
			<label class="checkbox">
				<input type="checkbox" [disabled]="this.forceVisible" (click)="this.toggleCardVisibility()"
					[checked]="this.card.cardVisible">
				<span class="checkmark" triggers="hover"></span>
				<div class="checkbox-text">
					{{ 'POI_CARD_VISIBILITY' | translate }}
				</div>
			</label>
		</div>
		<div class="checkbox-container">
			<label class="checkbox">
				<input type="checkbox" (click)="this.toggleAutogenerateCard()" [checked]="!this.card.autogenerateCard">
				<span class="checkmark" triggers="hover"></span>
				<div class="checkbox-text">
					{{ 'POI_CARD_AUTOGENERATE' | translate }}
				</div>
			</label>
		</div>
		<div class="card-image-lable">
			{{'POI_CARD_IMAGE_LABEL' | translate}}
		</div>

		<div *ngIf="!this.card.autogenerateCard && this.card?.cardImageFileData">
			<manage-media2 [elementType]="ElementType.IMAGE"
				[mediaLoader]="this.mediaLoader" [currentLanguage]="this.currentLanguage"
				[mediaData]="this.card.cardImageFileData"
				[maxFileSize]="this.config.getMenuEntryImageFileSize()"
				(mediaChanged)="this.cardChange.emit()"
			></manage-media2>
		</div>
		<div class="card-title-lable">
			{{'POI_CARD_TITLE_LABEL' | translate}}
		</div>
		<div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
			<input rows="1" [disabled]="true" class="form-control textInput" aria-label="Large"
				[(ngModel)]="this.title.de" (ngModelChange)="acceptChangesToText($event)">
			<div class="languageIndicator">
				<img class="language-button-image" src="../assets/flags/de.svg">
			</div>
		</div>
		<div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
			<input rows="1" [disabled]="true" class="form-control textInput" aria-label="Large"
				[(ngModel)]="this.title.gb" (ngModelChange)="acceptChangesToText($event)">
			<div class="languageIndicator">
				<img class="language-button-image" src="../assets/flags/gb.svg">
			</div>
		</div>
		<div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
			<input rows="1" [disabled]="true" class="form-control textInput" aria-label="Large"
				[(ngModel)]="this.title.jp" (ngModelChange)="acceptChangesToText($event)">
			<div class="languageIndicator">
				<img class="language-button-image" src="../assets/flags/jp.svg">
			</div>
		</div>
		<div class="card-content-lable">
			{{'POI_CARD_TEXT_LABEL' | translate}}
		</div>
		<div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
			<textarea rows="3" [disabled]="this.card.autogenerateCard" class="form-control textInput"
				aria-label="Large" [(ngModel)]="this.card.cardText.de"
				(ngModelChange)="acceptChangesToText($event)"></textarea>
			<div class="languageIndicator">
				<img class="language-button-image" src="../assets/flags/de.svg">
			</div>
		</div>
		<div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
			<textarea rows="3" [disabled]="this.card.autogenerateCard" class="form-control textInput"
				aria-label="Large" [(ngModel)]="this.card.cardText.gb"
				(ngModelChange)="acceptChangesToText($event)"></textarea>
			<div class="languageIndicator">
				<img class="language-button-image" src="../assets/flags/gb.svg">
			</div>
		</div>
		<div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
			<textarea rows="3" [disabled]="this.card.autogenerateCard" class="form-control textInput"
				aria-label="Large" [(ngModel)]="this.card.cardText.jp"
				(ngModelChange)="acceptChangesToText($event)"></textarea>
			<div class="languageIndicator">
				<img class="language-button-image" src="../assets/flags/jp.svg">
			</div>
		</div>
	</div>
	<div class="smartphone-right-side">
		<div #smartphone [ngStyle]="this.getSmartphoneStyle()" class="smartphone">
			<div class="smartphone-content-container">
				<div class="smartphone-content">
					<div class="tracked-image-container" *ngIf="this.isTracker">
						<img class="tracked-image"
							*ngIf="this.trackerFileData && this.trackerFileData.get(CubeSides.image) && this.trackerFileData.get(CubeSides.image).fileSafeUrl"
							[src]="this.trackerFileData.get(CubeSides.image).fileSafeUrl">
						<aacms-cuboid-quick-viewer #cuboidViewer class="cuboid-viewer"
							*ngIf="this.trackerFileData && this.cuboid" [cuboid]="getTracker(this.cuboid)"
							[top]="this.getImageUrl(this.trackerFileData.get(CubeSides.top))"
							[bottom]="this.getImageUrl(this.trackerFileData.get(CubeSides.bottom))"
							[left]="this.getImageUrl(this.trackerFileData.get(CubeSides.left))"
							[right]="this.getImageUrl(this.trackerFileData.get(CubeSides.right))"
							[back]="this.getImageUrl(this.trackerFileData.get(CubeSides.back))"
							[front]="this.getImageUrl(this.trackerFileData.get(CubeSides.front))">
						</aacms-cuboid-quick-viewer>
					</div>
					<div class="poi-card-container" [style]="this.getBorderStyle()">
						<div class="poi-card">
							<div class="poi-card-title poi-card-content">
								{{this.getLanguageValue(this.title)}}
							</div>
							<div class="poi-card-text poi-card-content" *ngIf="!this.card.autogenerateCard">
								{{this.getLanguageValue(this.card.cardText)}}
							</div>
							<div class="poi-card-text poi-card-content" *ngIf="this.card.autogenerateCard">
								{{this.getTextFromFirstUiElement()}}
							</div>
						</div>
						<div class="poi-card-image-container" *ngIf="this.hasLoadedCardImage()">
							<img class="poi-card-image" [style]="this.getAutoCardImageSytle()"
								[src]="getCardImageSrc()">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="hint">
			<i>{{ 'PREVIEW_MAY_LOOK_DIFFERENT' | translate }}</i>
		</div>
		<div class="screen-size-button-row">
			<div class="screen-size-button-group btn-group" btnRadioGroup [(ngModel)]="this.currentSelectedResolution">
				<label class="btn btn-light" btnRadio="small" uncheckable tabindex="0" role="button"
					(click)="this.changeSmartphoneStyle('14','7')">{{'SMALL_PHONE' | translate}}</label>
				<label class="btn btn-light" btnRadio="big" uncheckable tabindex="0" role="button"
					(click)="this.changeSmartphoneStyle('16','8')">{{'BIG_PHONE' | translate}}</label>
				<label class="btn btn-light" btnRadio="tablet" uncheckable tabindex="0" role="button"
					(click)="this.changeSmartphoneStyle('16','11')">{{'TABLET' | translate}}</label>
			</div>
			<div class="screen-rotate-button">
				<button type="button" [disabled]="true" class="btn btn-light" (click)="this.rotateSmartphone()"
					[matTooltip]="'ROTATE_SMARTPHONE_DISABLED' | translate">
					<img class="screen-rotate-button-image" src="../../assets/screen_rotation-24px.svg" />
				</button>
			</div>
		</div>
	</div>
</div>