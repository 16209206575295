<div class="cuboid-content">
  <div #sceneContainer class="sceneContainer" (resized)="onResize()">
    <div class="scene" [ngStyle]="getSceneStyle()">
      <div *ngIf="this.arLayer; then arLayerCube else trackerLayerCube"></div>
      <ng-template #trackerLayerCube>
        <div #cube class="cube" [ngStyle]="getCubeStyle()">
          <div class="cube__face" [ngStyle]="getCubeStyleFaceFront()">
            <div *ngIf="imageDataMap && imageDataMap.get(cubeSides.front); then poilayerFront else dropinFront"></div>
            <ng-template #poilayerFront>
              <aacms-poi-layer class="tracker-layer unselectable" [imageData]="this.imageDataMap.get(cubeSides.front)"
                [mapId]="cubeSides.front" [currentLanguage]="this.currentLanguage"></aacms-poi-layer>
            </ng-template>
          </div>
          <div class="cube__face" [ngStyle]="getCubeStyleFaceBack()">
            <div *ngIf="imageDataMap && imageDataMap.get(cubeSides.back); then poilayerBack else dropinBack"></div>
            <ng-template #poilayerBack>
              <aacms-poi-layer class="tracker-layer unselectable" [imageData]="this.imageDataMap.get(cubeSides.back)"
                [mapId]="cubeSides.back" [currentLanguage]="this.currentLanguage"></aacms-poi-layer>
            </ng-template>
          </div>
          <div class="cube__face" [ngStyle]="getCubeStyleFaceRight()">
            <div *ngIf="imageDataMap && imageDataMap.get(cubeSides.right); then poilayerRight else dropinRight"></div>
            <ng-template #poilayerRight>
              <aacms-poi-layer class="tracker-layer unselectable" [imageData]="this.imageDataMap.get(cubeSides.right)"
                [mapId]="cubeSides.right" [currentLanguage]="this.currentLanguage"></aacms-poi-layer>
            </ng-template>
          </div>
          <div class="cube__face" [ngStyle]="getCubeStyleFaceLeft()">
            <div *ngIf="imageDataMap && imageDataMap.get(cubeSides.left); then poilayerLeft else dropinLeft"></div>
            <ng-template #poilayerLeft>
              <aacms-poi-layer class="tracker-layer unselectable" [imageData]="this.imageDataMap.get(cubeSides.left)"
                [mapId]="cubeSides.left" [currentLanguage]="this.currentLanguage"></aacms-poi-layer>
            </ng-template>
          </div>
          <div class="cube__face" [ngStyle]="getCubeStyleFaceTop()">
            <div *ngIf="imageDataMap && imageDataMap.get(cubeSides.top); then poilayerTop else dropinTop"></div>
            <ng-template #poilayerTop>
              <aacms-poi-layer class="tracker-layer unselectable" [imageData]="this.imageDataMap.get(cubeSides.top)"
                [mapId]="cubeSides.top" [currentLanguage]="this.currentLanguage"></aacms-poi-layer>
            </ng-template>
          </div>
          <div class="cube__face" [ngStyle]="getCubeStyleFaceBottom()">
            <div *ngIf="imageDataMap && imageDataMap.get(cubeSides.bottom); then poilayerBottom else dropinBottom">
            </div>
            <ng-template #poilayerBottom>
              <aacms-poi-layer class="tracker-layer unselectable" [imageData]="this.imageDataMap.get(cubeSides.bottom)"
                [mapId]="cubeSides.bottom" [currentLanguage]="this.currentLanguage"></aacms-poi-layer>
            </ng-template>
          </div>
        </div>
      </ng-template>
      <ng-template #arLayerCube>
        <div #cube class="cube" [ngStyle]="getCubeStyle()">
          <div class="cube__face" [ngStyle]="getCubeStyleFaceFront()">
            <aacms-poi-layer *ngIf="imageDataMap && imageDataMap.get(cubeSides.front)"
              class="tracker-layer unselectable" [imageData]="this.imageDataMap.get(cubeSides.front)"
              [mapId]="cubeSides.front" [imageId]="this.arLayer.id" [currentLanguage]="this.currentLanguage">
            </aacms-poi-layer>
            <div class="ar-layer-image-container" *ngIf="this.useArLayerImage(cubeSides.front)">
              <div
                *ngIf="arLayerImageDataMap && arLayerImageDataMap.get(cubeSides.front); then poilayerFront else dropinFront">
              </div>
              <ng-template #poilayerFront>
                <aacms-poi-layer class="ar-layer unselectable"
                  [imageData]="this.arLayerImageDataMap.get(cubeSides.front)" [mapId]="cubeSides.front"
                  [imageId]="this.arLayer.id" [currentLanguage]="this.currentLanguage"></aacms-poi-layer>
              </ng-template>
            </div>
          </div>
          <div class="cube__face" [ngStyle]="getCubeStyleFaceBack()">
            <aacms-poi-layer *ngIf="imageDataMap && imageDataMap.get(cubeSides.back)" class="tracker-layer unselectable"
              [imageData]="this.imageDataMap.get(cubeSides.back)" [mapId]="cubeSides.back" [imageId]="this.arLayer.id" [currentLanguage]="this.currentLanguage">
            </aacms-poi-layer>
            <div class="ar-layer-image-container" *ngIf="this.useArLayerImage(cubeSides.back)">
              <div
                *ngIf="arLayerImageDataMap && arLayerImageDataMap.get(cubeSides.back); then poilayerBack else dropinBack">
              </div>
              <ng-template #poilayerBack>
                <aacms-poi-layer class="ar-layer unselectable"
                  [imageData]="this.arLayerImageDataMap.get(cubeSides.back)" [mapId]="cubeSides.back"
                  [imageId]="this.arLayer.id" [currentLanguage]="this.currentLanguage"></aacms-poi-layer>
              </ng-template>
            </div>
          </div>
          <div class="cube__face" [ngStyle]="getCubeStyleFaceRight()">
            <aacms-poi-layer *ngIf="imageDataMap && imageDataMap.get(cubeSides.right)"
              class="tracker-layer unselectable" [imageData]="this.imageDataMap.get(cubeSides.right)"
              [mapId]="cubeSides.right" [imageId]="this.arLayer.id" [currentLanguage]="this.currentLanguage">
            </aacms-poi-layer>
            <div class="ar-layer-image-container" *ngIf="this.useArLayerImage(cubeSides.right)">
              <div
                *ngIf="arLayerImageDataMap && arLayerImageDataMap.get(cubeSides.right); then poilayerRight else dropinRight">
              </div>
              <ng-template #poilayerRight>
                <aacms-poi-layer class="ar-layer unselectable"
                  [imageData]="this.arLayerImageDataMap.get(cubeSides.right)" [mapId]="cubeSides.right"
                  [imageId]="this.arLayer.id" [currentLanguage]="this.currentLanguage"></aacms-poi-layer>
              </ng-template>
            </div>
          </div>
          <div class="cube__face" [ngStyle]="getCubeStyleFaceLeft()">
            <aacms-poi-layer *ngIf="imageDataMap && imageDataMap.get(cubeSides.left)" class="tracker-layer unselectable"
              [imageData]="this.imageDataMap.get(cubeSides.left)" [mapId]="cubeSides.left" [imageId]="this.arLayer.id" [currentLanguage]="this.currentLanguage">
            </aacms-poi-layer>
            <div class="ar-layer-image-container" *ngIf="this.useArLayerImage(cubeSides.left)">
              <div
                *ngIf="arLayerImageDataMap && arLayerImageDataMap.get(cubeSides.left); then poilayerLeft else dropinLeft">
              </div>
              <ng-template #poilayerLeft>
                <aacms-poi-layer class="ar-layer unselectable"
                  [imageData]="this.arLayerImageDataMap.get(cubeSides.left)" [mapId]="cubeSides.left"
                  [imageId]="this.arLayer.id" [currentLanguage]="this.currentLanguage"></aacms-poi-layer>
              </ng-template>
            </div>
          </div>
          <div class="cube__face" [ngStyle]="getCubeStyleFaceTop()">
            <aacms-poi-layer *ngIf="imageDataMap && imageDataMap.get(cubeSides.top)" class="tracker-layer unselectable"
              [imageData]="this.imageDataMap.get(cubeSides.top)" [mapId]="cubeSides.top" [imageId]="this.arLayer.id" [currentLanguage]="this.currentLanguage">
            </aacms-poi-layer>
            <div class="ar-layer-image-container" *ngIf="this.useArLayerImage(cubeSides.top)">
              <div
                *ngIf="arLayerImageDataMap && arLayerImageDataMap.get(cubeSides.top); then poilayerTop else dropinTop">
              </div>
              <ng-template #poilayerTop>
                <aacms-poi-layer class="ar-layer unselectable" [imageData]="this.arLayerImageDataMap.get(cubeSides.top)"
                  [mapId]="cubeSides.top" [imageId]="this.arLayer.id" [currentLanguage]="this.currentLanguage"></aacms-poi-layer>
              </ng-template>
            </div>
          </div>
          <div class="cube__face" [ngStyle]="getCubeStyleFaceBottom()">
            <aacms-poi-layer *ngIf="imageDataMap && imageDataMap.get(cubeSides.bottom)"
              class="tracker-layer unselectable" [imageData]="this.imageDataMap.get(cubeSides.bottom)"
              [mapId]="cubeSides.bottom" [imageId]="this.arLayer.id" [currentLanguage]="this.currentLanguage">
            </aacms-poi-layer>
            <div class="ar-layer-image-container" *ngIf="this.useArLayerImage(cubeSides.bottom)">
              <div
                *ngIf="arLayerImageDataMap && arLayerImageDataMap.get(cubeSides.bottom); then poilayerBottom else dropinBottom">
              </div>
              <ng-template #poilayerBottom>
                <aacms-poi-layer class="ar-layer unselectable"
                  [imageData]="this.arLayerImageDataMap.get(cubeSides.bottom)" [mapId]="cubeSides.bottom"
                  [imageId]="this.arLayer.id" [currentLanguage]="this.currentLanguage"></aacms-poi-layer>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <ng-template #dropinFront>
    <dropin class="dropin-component" *ngIf="this.enableFileControl" [multipleAllowed]="false"
      [maxFileSize]="getMaxContentFileSize()" (sendFiles)="handleFilesFromDropin($event)" [accept]="'.jpg,.jpeg,.png'"></dropin>
    <div class="tracker-layer-text" *ngIf="!this.enableFileControl">
      {{'CUBOID_FRONT' | translate }}
    </div>
  </ng-template>
  <ng-template #dropinBack>
    <dropin class="dropin-component" *ngIf="this.enableFileControl" [multipleAllowed]="false"
      [maxFileSize]="getMaxContentFileSize()" (sendFiles)="handleFilesFromDropin($event)" [accept]="'.jpg,.jpeg,.png'"></dropin>
    <div class="tracker-layer-text" *ngIf="!this.enableFileControl">
      {{'CUBOID_BACK' | translate }}
    </div>
  </ng-template>
  <ng-template #dropinLeft>
    <dropin class="dropin-component" *ngIf="this.enableFileControl" [multipleAllowed]="false"
      [maxFileSize]="getMaxContentFileSize()" (sendFiles)="handleFilesFromDropin($event)" [accept]="'.jpg,.jpeg,.png'"></dropin>
    <div class="tracker-layer-text" *ngIf="!this.enableFileControl">
      {{'CUBOID_LEFT' | translate }}
    </div>
  </ng-template>
  <ng-template #dropinRight>
    <dropin class="dropin-component" *ngIf="this.enableFileControl" [multipleAllowed]="false"
      [maxFileSize]="getMaxContentFileSize()" (sendFiles)="handleFilesFromDropin($event)" [accept]="'.jpg,.jpeg,.png'"></dropin>
    <div class="tracker-layer-text" *ngIf="!this.enableFileControl">
      {{'CUBOID_RIGHT' | translate }}
    </div>
  </ng-template>
  <ng-template #dropinTop>
    <dropin class="dropin-component" *ngIf="this.enableFileControl" [multipleAllowed]="false"
      [maxFileSize]="getMaxContentFileSize()" (sendFiles)="handleFilesFromDropin($event)" [accept]="'.jpg,.jpeg,.png'"></dropin>
    <div class="tracker-layer-text" *ngIf="!this.enableFileControl">
      {{'CUBOID_TOP' | translate }}
    </div>
  </ng-template>
  <ng-template #dropinBottom>
    <dropin class="dropin-component" *ngIf="this.enableFileControl" [multipleAllowed]="false"
      [maxFileSize]="getMaxContentFileSize()" (sendFiles)="handleFilesFromDropin($event)" [accept]="'.jpg,.jpeg,.png'"></dropin>
    <div class="tracker-layer-text" *ngIf="!this.enableFileControl">
      {{'CUBOID_BOTTOM' | translate }}
    </div>
  </ng-template>

  <div class="screen-size-button-group btn-group" btnRadioGroup [(ngModel)]="_sideModel" (ngModelChange)="changeSide()">
    <button class="btn btn-light" [btnRadio]="cubeSides.front" uncheckable tabindex="0"
      role="button">{{ 'CUBOID_FRONT' | translate }}</button>
    <button class="btn btn-light" [btnRadio]="cubeSides.right" uncheckable tabindex="0"
      role="button">{{ 'CUBOID_RIGHT' | translate }}</button>
    <button class="btn btn-light" [btnRadio]="cubeSides.back" uncheckable tabindex="0"
      role="button">{{ 'CUBOID_BACK' | translate }}</button>
    <button class="btn btn-light" [btnRadio]="cubeSides.left" uncheckable tabindex="0"
      role="button">{{ 'CUBOID_LEFT' | translate }}</button>
    <button class="btn btn-light" [btnRadio]="cubeSides.top" uncheckable tabindex="0"
      role="button">{{ 'CUBOID_TOP' | translate }}</button>
    <button class="btn btn-light" [btnRadio]="cubeSides.bottom" uncheckable tabindex="0"
      role="button">{{ 'CUBOID_BOTTOM' | translate }}</button>
  </div>

  <div *ngIf="!this.arLayer && this.imageDataMap && this.enableFileControl" class="image-available">
    <label>{{'REAL_CUBOID_DESIGN' | translate }}</label><br />
    <label for="current-file">{{'CURRENT_IMAGE' | translate }}</label>
    <div *ngIf="imageDataMap && imageDataMap.get(this.side)" class="current-file"
      [style]="this.getBackgroundColorForRating()">
      <div class="current-file-name text-truncate">
        {{this.imageDataMap.get(this.side).media ? this.imageDataMap.get(this.side).media.name : this.imageDataMap.get(this.side).file.name}}
      </div>
      <div class="update-buttons">
        <div class="tracking-quality"
          *ngIf="this.imageDataMap.get(this.side).media && this.imageDataMap.get(this.side).media.rating !== undefined"
          [matTooltip]="'TRACKING_QUALITY' | translate" triggers="hover">
          {{this.imageDataMap.get(this.side).media.rating}}%
        </div>
        <div *ngIf="this.imageDataMap.get(this.side).fileSafeUrl" class="download-file" [matTooltip]="'DOWNLOAD_MEDIA' | translate" triggers="hover"
          (click)="downloadFileToSystem(this.imageDataMap.get(this.side))">
          <i class="fa fa-download"></i>
        </div>
        <div class="file-delete" (click)="removeImage()" [matTooltip]="'REMOVE_MEDIA' | translate" triggers="hover">
          <i class="fa fa-times"></i>
        </div>
      </div>
    </div>
    <div *ngIf="!imageDataMap || !imageDataMap.get(this.side)" class="no-file">
      <i>{{'NO_FILE_PRESENT' | translate}}</i>
    </div>
  </div>

  <div *ngIf="this.arLayer && this.arLayerImageDataMap && this.enableFileControl" class="image-available">
    <div class="checkbox-container">
      <label class="checkbox" id="showArLayerImageCheckbox" [matTooltip]="'USE_ARLAYER_IMAGE' | translate" triggers="hover">
        <input type="checkbox" (click)="useArLayerImageChanged()" [checked]="useArLayerImage(this.side)">
        <span class="checkmark"></span>
        <div class="checkbox-text">
          {{'AR_CUBOID_DESIGN' | translate }}
        </div>
      </label>
    </div>
    <label for="current-file">{{'CURRENT_IMAGE' | translate }}</label>
    <div *ngIf="arLayerImageDataMap.get(this.side)" class="current-file" [style]="this.getBackgroundColorForRating()">
      <div class="current-file-name">
        {{this.arLayerImageDataMap.get(this.side).media ? this.arLayerImageDataMap.get(this.side).media.name : this.arLayerImageDataMap.get(this.side).file.name}}
      </div>
      <div *ngIf="this.arLayerImageDataMap.get(this.side).fileSafeUrl" class="download-file"
        (click)="downloadFileToSystem(this.arLayerImageDataMap.get(this.side))" [matTooltip]="'DOWNLOAD_MEDIA' | translate" triggers="hover">
        <i class="fa fa-download">
        </i>
      </div>
      <div class="file-delete" (click)="removeImage()" *ngIf="this.useArLayerImage(this.side)"
      [matTooltip]="'REMOVE_MEDIA' | translate" triggers="hover">
        <i class="fa fa-times">
        </i>
      </div>
    </div>
    <div *ngIf="!arLayerImageDataMap || !arLayerImageDataMap.get(this.side)" class="no-file">
      <i>{{'NO_FILE_PRESENT' | translate}}</i>
    </div>
  </div>
</div>
