<div class="content">
	<tabset>
		<tab heading="{{'ADMIN_CONFIG_TAB' | translate}}" class="tab">
			<div class="config-form">
				<div class="form-group" *ngIf="userConfig">
					<label>{{'ADMIN_CONFIG_HIGHLIGHTCOLOR' | translate }}:</label>
					<input type="color" class="form-control form-control-lg" [(ngModel)]="userConfig.highlightColor">
				</div>
				<div class="custom-hr"></div>
				<div class="form-group" *ngIf="userConfig">
					<label>{{'ADMIN_CONFIG_LOGO' | translate }}:</label><br/>
					<ng-template [ngIf]="userConfig.logoMediaId">
						<aacms-media-viewer class="imageViewer logoPreview"
							[mediaId]="userConfig.logoMediaId"
							[autoSize]="true"
							backgroundStyle="white">
						</aacms-media-viewer>
					</ng-template>
					<ng-template [ngIf]="!userConfig.logoMediaId">
						<img class="logoPreview" src="../../assets/logo_border.png">
					</ng-template>

					<manage-media2 class="logoChooser" [elementType]="ElementType.IMAGE"
						[mediaLoader]="mediaLoader" [currentLanguage]="null"
						[mediaData]="userConfig"
						[dataAdapter]="ManageMedia2Adapters.Unilang('logoMediaId', null)"
						[maxFileSize]="config.getMenuHeaderImageFileSize()"
					>
						<div class="hasNone">Logo auf ein eigenes wechseln:</div>
						<div class="hasFallbackMedia"></div>
						<div class="hasMedia"></div>
					</manage-media2>
				</div>
				<div class="custom-hr"></div>
				<div class="form-group">
					<div class="checkbox-container">
						<label class="checkbox">
						<input type="checkbox" [(ngModel)]="userConfig.mapDownloadActive" *ngIf="userConfig">
						<span class="checkmark" triggers="hover"></span>
						<div class="checkbox-text">
							{{ 'ADMIN_CONFIG_OFFLINEMAP_DOWNLOADACTIVE' | translate }}
						</div>
						</label>
					</div>
					<div class="map-container"><div id="map"></div></div>
				</div>
				<div class="custom-hr"></div>
				<div class="button-row">
					<button type="submit" class="btn btn-warning custom-button" (click)="openModal(discardUserConfigModalTemplate)"
						[disabled]="!this.hasUserConfigChanged()">{{'DISCARD' | translate }}</button>
					<button type="submit" class="btn btn-success custom-button" (click)="saveConfig()"
						[disabled]="!this.hasUserConfigChanged()">{{'SAVE' | translate }}</button>
				</div>
			</div>
		</tab>
		<tab heading="{{'ADMIN_USERS_TAB' | translate}}" class="tab">
			<div class="button-bar" *ngIf="showTasksButtons()">
				<button type="submit" class="btn btn-secondary button-bar-button"
					(click)="taskService.importDatabase()">{{'IMPORT_DATABASE' | translate }}</button>
				<button type="submit" class="btn btn-secondary button-bar-button"
					(click)="taskService.dumpDatabase()">{{'DUMP_DATABASE' | translate }}</button>
			</div>
			<div class="custom-hr" *ngIf="showTasksButtons()"></div>
			<div class="display">
				<div class="user-form">
					<h4 *ngIf="!editMode">{{'CREATE_USER' | translate }}</h4>
					<h4 *ngIf="editMode">{{'EDIT_USER' | translate }}</h4>
					<div class="form-group">
						<label for="name">{{'NAME:' | translate }}</label>
						<input [(ngModel)]="user.realName" name="name" type="text" class="form-control form-control-lg"
							id="name" [placeholder]="'NAME' | translate">
					</div>
					<div class="form-group">
						<label for="username">{{'USERNAME:' | translate }}</label>
						<input [(ngModel)]="user.name" name="username" type="text" class="form-control form-control-lg"
							id="username" [placeholder]="'USERNAME' | translate">
					</div>
					<div class="form-group">
						<label for="password">{{'PASSWORD:' | translate }}</label>
						<input [(ngModel)]="user.password" name="password" type="password" class="form-control form-control-lg"
							id="password" [placeholder]="'PASSWORD' | translate">
					</div>

					<div class="form-group" *ngIf="!isLoggedInUser(user)">
						<label for="role">{{'USER_ROLE' | translate }}</label>
						<div class="form-check" *ngFor="let role of roles; trackBy: trackByFn">
							<input class="form-check-input" type="checkbox" value="" id="{{role}}" (change)="check(role)"
								[checked]="isChecked(role)">
							<label class="form-check-label" for="{{role}}">
								{{role}}
							</label>
						</div>
					</div>
					<div class="custom-hr"></div>
					<div class="button-row">
						<button *ngIf="!editMode" type="submit" class="btn btn-primary custom-button" (click)="createUser()">
							{{'CREATE'| translate }}
						</button>

						<button *ngIf="editMode" type="submit" class="btn btn-warning custom-button" (click)="cancel()">
							{{'ABORT' | translate }}
						</button>
						<button *ngIf="editMode" type="submit" class="btn btn-success custom-button" (click)="updateUser()">
							{{'SAVE' | translate }}
						</button>
					</div>
				</div>

				<div class="user-list">
					<div class="user-list-header">
						<h4>{{'LIST_OF_USERS' | translate }}</h4>
					</div>
					<div *ngFor="let user of users; trackBy: trackByFn">
						<div class="card user-card">
							<div class="card-body row">
								<div class="card-content">
									<h5 class="card-title">{{user.realName}}</h5>
									<h6 class="card-subtitle mb-2 text-muted">{{user.name}}</h6>
									<b>{{'USER_ROLES' | translate }} </b>
									<div *ngFor="let priviledge of user.privileges; trackBy: trackByFn">
										{{priviledge}}
									</div>
								</div>
								<span class="card-spacer"></span>
								<div class="icons">
									<button (click)="editUser(user)" class="icon-button"
										[matTooltip]="'EDIT_USER_DETAILS' | translate" triggers="hover">
										<i class="fa fa-pencil" aria-hidden="true"></i>
									</button>
									<button (click)="openModal(deleteModalTemplate, user)" class="icon-button"
										[disabled]="isLoggedInUser(user)" [matTooltip]="'DELETE_USER' | translate"
										triggers="hover">
										<i class="fa fa-trash" aria-hidden="true"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</tab>
	</tabset>
</div>

<ng-template #deleteModalTemplate>
	<div class="modal-header">
		<h4 class="modal-title text-break pull-left">
			{{'USER_DELETE_QUESTION1' | translate }} '{{user.realName}}' {{'USER_DELETE_QUESTION1' | translate }}
		</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-footer">
		<button (click)="cancel()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
		<button (click)="deleteUser()" type="button" class="btn btn-danger">{{'YES_DELETE' | translate }}</button>
	</div>
</ng-template>

<ng-template #discardUserConfigModalTemplate>
	<div class="modal-header">
	  <h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
	</div>
	<div class="modal-footer">
	  <button (click)="this.modalRef.hide()" type="button" class="btn btn-success">{{'NO_ABORT'| translate}}</button>
	  <button (click)="discardConfig()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
	</div>
  </ng-template>
