<div class="container">
  <h4>{{'EDIT_USER' | translate }}</h4>
  <form>
    <div class="form-group">
      <label for="name">{{'NAME:' | translate }}</label>
      <input [(ngModel)]="user.realName" name="name" type="text" class="form-control form-control-lg" id="name"
        [placeholder]="'NAME' | translate">
    </div>
    <div class="form-group">
      <label for="password-old">{{'PASSWORD_OLD:' | translate }}</label>
      <input [(ngModel)]="oldPwd" name="password-old" type="password" class="form-control form-control-lg"
        id="password-old" [placeholder]="'OLD_PASSWORD' | translate" autocomplete="off">
    </div>
    <div class="form-group">
      <label for="password-new">{{'PASSWORD_NEW:' | translate }}</label>
      <input [(ngModel)]="newPwd" name="password-new" type="password" class="form-control form-control-lg"
        id="password-new" [placeholder]="'NEW_PASSWORD' | translate" autocomplete="off">
    </div>
    <div class="form-group">
      <label for="password-new-confirm">{{'PASSWORD_NEW_CONFIRM:' | translate }}</label>
      <input [(ngModel)]="newPwdConfirm" name="password-new-confirm" type="password"
        class="form-control form-control-lg" id="password-new-confirm" [placeholder]="'NEW_PASSWORD' | translate"
        autocomplete="off">
    </div>
    <div class="button-row">
      <button type="button" class="btn btn-secondary custom-button" (click)="cancel()">{{'BACK_TO_OVERVIEW' | translate }}</button>
      <button type="submit" class="btn btn-success custom-button" (click)="updateUser()">{{'SAVE' | translate }}</button>
    </div>
  </form>
</div>
