import { Pipe, PipeTransform } from '@angular/core';
import { Tour } from '../models/Tour';

@Pipe({
    name: 'ToursFilter'
})
export class ToursFilterPipe implements PipeTransform {

    transform(tours: Tour[], searchText: string): any[] {
        if (!tours) { return []; }
        if (!searchText) { return tours; }
        searchText = searchText.toLowerCase();
        return tours.filter(tour => {
            if (tour.title.de) {
                return tour.title.de.toLowerCase().includes(searchText);
            }
        });
    }
}
