<div class="modal-header">
  <div class="modal-title text-break">
    <b>{{'DELETE_EXHIBITION?' | translate }}</b>
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <a> {{'REALY_DELETE1' |translate }}
    <i *ngIf="exhibition.title != undefined && getLanguageValue(exhibition.title).length != 0">'{{getLanguageValue(exhibition.title)}}'</i>
    <i *ngIf="!exhibition.title || getLanguageValue(exhibition.title).length == 0">'{{exhibition.id}}'</i>
    {{'REALY_DELETE2' | translate }}</a>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="onClose()">{{'ABORT' | translate }}</button>
  <button type="button" class="btn btn-danger" (click)="onDelete()">{{'YES_DELETE' | translate }}</button>
</div>
