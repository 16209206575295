import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { Config } from '../Config';

import { AlertService } from './alert.service';
import { StringService } from './string.service';
import { AlertWithUrl, AlertWithBoldValueInMiddle, AlertType, AlertSimple } from '../models/Alert';
import { CurrentContextService } from './currentContext.service';

/**
 * basic httpHeaders that will be used with every request
 */
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root',
})
export class TaskService {

  /**
	 * the baseurl to reach the backend
	 */
  private baseUrl = new Config().GetBaseUrl();

  /**
	 * @param http is used to send Request to the backend and process the response
	 * @param alertService is used to display relevant information to the user
	 * @param stringService the StringService is used to get strings in the correct Language, that are displayed to the user.
	 */
  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService,
    private contextService: CurrentContextService
  ) { }

  public dumpDatabase(): void {
    const body = '{ "task": "dump-database" }';
    this.postTask(body);
  }

  public importDatabase(): void {
    const body = '{ "task": "import-database" }';
    this.postTask(body);
  }

  public postTask(body: string): void {
    const subscription = this.http.post(this.getTaskUrl(), body).subscribe(
      res => {
        this.alertService.alert(
          new AlertSimple(
            this.stringService.get('TASK_EXECUTED'),
            AlertType.Success),
          true);
      },
      error => { this.handleError<any>('postTask', error); },
      () => { subscription.unsubscribe(); }
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   * @returns an errorObject or an Object of the given resultType
   */
  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  /**
	 * triggers when an httpError occurs
	 * is used to write an value to the console
	 * @param value the value to be written to the console
	 */
  private logError(value: any): void {
    console.error(value);
  }

  /**
	 * creates the url to the tasks endpoint of the backend
	 * @param extension can be added to extend the url with an suffix
	 * @returns returns the created url
	 */
  private getTaskUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}tasks/`;
    if (extension) {
      if (!(extension.indexOf('?') >= 0)) {
        if (!extension.endsWith('/')) {
          extension = `${extension}/`;
        }
      }
      url = `${url}${extension}`;
    }
    return url;
  }

}

