import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentContextService } from '../services/currentContext.service';
import { AppState } from '../current.app.state';
import { AuthService } from '../services/auth.service';
import { VERSION } from '../../environments/version';
import { Config } from '../Config';
import { UserConfigService } from '../services/userConfig.service';

/**
 * The LoginComponent is used to handle the LoginPage
 */
@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	/**
	 * tells if the password is  visible or hidden
	 */
	public showPassword = false;

	/**
	 * the path which MonaLisa should be displayed
	 * depending on the visibility of the password
	 */
	public path = '';

	/**
	 * constant value that routes to Mono that can see the input
	 */
	private path1 = '/assets/loginImages/' + new Config().getLoginImageInvisible();

	/**
	 * constant value that routes to Mono that can not see the input
	 */
	private path2 = '/assets/loginImages/' + new Config().getLoginImageVisible();

	/**
	 * should the username be saved in the localstorage or not
	 */
	public rememberMe = false;

	/**
	 * the input of the password textarea
	 */
	public password = '';

	/**
	 * the input of the username textarea
	 */
	public username = '';

	/**
	 * is true when the credentials will be entered on time false
	 * and then displays the information that the credentials were wrong
	 */
	public wrongCredentials = false;

	/**
	 * Displays the version of the CMS
	 */
	public versionString = '';
	public versionHash = '';

	/**
	   * Variable for the configuration.
	   */
	public config: Config = new Config();

	/**
	 * Indicated wether the login is disabled or not. Boolean type.
	 */
	public loginDisabled = false;

	/**
	 * Creates an instance of LoginComponent
	 * @param router needed to route to the exhibitions after successful login
	 * @param contextService needed to set the current AppState to the correct value
	 * @param authService needed to test the credentials of the user and log the user in
	 */
	public constructor(
		private router: Router,
		private contextService: CurrentContextService,
		private authService: AuthService,
		private instanceConfigService: UserConfigService
	) {
		const sub = this.authService.status().subscribe(
			status => {
				const backendVersionHash: string = null == status.version.commit ? '' : status.version.commit.substr(0, 7);
				this.versionHash = 'Frontend: ' + VERSION.hash + ' // Backend: ' + backendVersionHash;
				sub.unsubscribe();
			});
		this.versionString = 'v' + VERSION.version;
		if (this.config.isDemoInstance()) {
			this.username = this.config.getDefaultUsername();
			this.password = this.config.getDefaultPassword();;
		}
	}

	/**
	 * initializes the variables and sets the correct AppState
	 * it also displays the username if remeberMe was set last time
	 */
	public ngOnInit(): void {
		this.showPassword = false;
		this.path = this.path1;
		this.contextService.setCurrentState(AppState.login);
		const username = localStorage.getItem('username');

		if (username && username !== '') {
			this.username = username;
			this.rememberMe = true;
		}
	}

	/**
	 * toggles the showPassword boolean if the user clicks on the toggle button
	 */
	public togglePassword(): void {
		this.showPassword = !this.showPassword;
		if (this.showPassword) {
			this.path = this.path2;
		} else {
			this.path = this.path1;
		}
	}

	/**
	 * Tries to login the user with the given credentials
	 * if they were correct the user will be redirected to the exhibitions
	 * else the user will be informed over false credentials
	 */
	public login(): void {
		if (this.loginDisabled) { return; }
		if (this.username.length === 0 || this.password.length === 0) {
			return;
		}
		this.contextService.setSavingContent(true);
		this.loginDisabled = true;
		this.wrongCredentials = false;
		const handleError = (error) => {
			this.loginDisabled = false;
			this.contextService.setSavingContent(false);
			console.log(error);
		};
		const sub = this.authService.login(this.username, this.password).subscribe(
			response => {
				// login successfull, continue with loading config
				this.instanceConfigService.getConfig().then(() => {
					this.loginDisabled = false;
					this.contextService.setSavingContent(false);
					if (this.rememberMe) {
						localStorage.setItem('username', this.username);
					} else {
						localStorage.removeItem('username');
					}
					this.router.navigate(['exhibitions/']);
				}).catch(handleError);
				sub.unsubscribe();
			}, error => {
				handleError(error);
				if (error.status === 401) {
					this.wrongCredentials = true;
				}
				sub.unsubscribe();
			}
		);
	}


}
