import { Component } from '@angular/core';

/**
 * is used to inform the user that the desired page could not be found
 */
@Component({
  selector: 'app-page-not-found',
  template:
    `
    <div style="text-align: center;">
        <img style="max_height: 20vh; width: 20vw; margin: 10vh;" src="../assets/404.png">
        <h1>404 - Seite nicht gefunden!</h1>
    </div>
    `,
  styles: []
})
export class PageNotFoundComponent {
}
