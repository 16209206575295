import { StringService } from './string.service';
import { CubeSides } from './../models/CubeSides';
import { AlertWithBoldValueInMiddle, AlertType } from './../models/Alert';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap, share, map, catchError } from 'rxjs/operators';

import { Media } from '../models/Media';
import { Config } from '../Config';
import { AlertService } from './alert.service';

/**
 * basic HttpHeaders that will be used with every request
 */
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

/**
 * The MediaService handles all requests that affect the media endpoint
 */
@Injectable({
  providedIn: 'root',
})
export class MediaService {

  /**
	 * the baseUrl to send requests to the backend
	 */
  private baseUrl = new Config().GetBaseUrl();

  /**
	 * creates an instance of the MediaService
	 * @param http used to send Requests to the backend end receives the responses
	 * @param alertService is used to print Request Errors for the user
	 */
  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService
  ) { }

  /**
	 * GET
	 * requests the Media with the given id from the Database
	 * @param id the MediaId that should be requested
	 * @returns the Media if found in the database, else returns an error
	 */
  public getMediaById(id: string): Observable<Media> {
    return this.http.get<Media>(this.getMediaUrl(`${id}`)).pipe(
      tap(
        response => { },
        error => { this.handleError<Media>('getMediaById', error); }
      ),
      share()
    );
  }

  /**
	 * GET
	 * Downloads the blob of the file from the backend.
	 * @param url The url where the file can be found.
	 */
  public downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      tap(
        response => {}
      ),
      share()
    );
  }

  /**
	 * POST
	 * Uploads any File (Image, Obj, Mtl, ...) to the backend
	 * @param file the FileData that should be uploaded
	 * @returns returns a location Header of the File, where to find it in the backend, else returns an error
	 */
  public uploadFile(file: File, isTracker: boolean = false): Observable<any> {
    const mediaUrl = this.getMediaUrl();
    const http = this.http;
    const handleError = this.handleError;

    const fileExtension = file.name.substr(file.name.lastIndexOf('.') + 1);
    let headers: HttpHeaders;
    if (isTracker) {
      headers = new HttpHeaders({
        'Content-Type': 'text/base64',
        'x-filetype': file.type,
        'x-filesize': file.size.toString(),
        'x-filename': btoa(unescape(encodeURIComponent(file.name))),
        'x-file-extension': fileExtension,
        'X-reject-if-untrackable': 'true'
      });
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'text/base64',
        'x-filetype': file.type,
        'x-filesize': file.size.toString(),
        'x-filename': btoa(unescape(encodeURIComponent(file.name))),
        'x-file-extension': fileExtension
      });
    }
    return new Observable<any>(sub => {
      const reader = new FileReader();
      reader.onloadend = function () {
        const base64data = reader.result;
        http.post<any>(mediaUrl, base64data, {
          reportProgress: false,
          observe: 'response',
          headers: headers
        }).pipe(
          tap(
            response => { },
            error => { handleError<Media>('uploadFile', error); },
          ),
          share()
        ).subscribe(sub);
      };
      reader.readAsDataURL(file);
    });
  }

  /**
	 * PUT
	 * updates the given media with the given information
	 * @param media the media that should be updated
	 * @returns nothing if the request was successful, else it returns an error
	 */
  public updateMedia(media: Media): Observable<any> {
    return this.http.put(this.getMediaUrl(`${media.id}`), media, httpOptions).pipe(
      tap(
        response => { },
        error => { this.handleError<Media>('updateMedia', error); }
      ),
      share()
    );
  }

  /**
	 * DELETE
	 * removes the given Media from the database
	 * @param media the media to be removed
	 * @returns nothing if the request was successful, else return an error
	 */
  public deleteMedia(media: Media | string): Observable<any> {
    const id = typeof media === 'string' ? media : media.id;
    return this.http.delete(this.getMediaUrl(`${id}`), httpOptions).pipe(
      tap(
        response => { },
        error => { this.handleError<Media>('deleteMedia', error); }
      ),
      share()
    );
  }

  /**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 * @returns an errorObject or an Object of the given resultType
	 */
  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  /**
	 * triggers when an httpError occurs
	 * writes the given errorValue to the console
	 * @param value the value to write
	 */
  private logError(value: any): void {
    console.error(value);
  }

  /**
	 * is used to return the url to the Media endpoint of the backend
	 * @param extension can be used to add a suffix to the endpointUrl
	 * @returns returns the created url
	 */
  private getMediaUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}media/`;
    if (extension) {
      if (!extension.endsWith('/')) {
        extension = `${extension}/`;
      }
      url = `${url}${extension}`;
    }
    return url;
  }

}
