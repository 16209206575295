import { MultiLanguageText } from "./MultiLanguageText";

export class Exhibition {

  public id: string;
  public title: MultiLanguageText;
  public createdTime: number;
  public updatedTime: number;
  public exportTime: number;
  public thumbs: string[] = [];
  public active = true;

  public constructor(exhibition?: Exhibition) {
    if (!exhibition) { return; }
    this.id = exhibition.id;
    this.title = new MultiLanguageText(exhibition.title);
    this.createdTime = exhibition.createdTime;
    this.updatedTime = exhibition.updatedTime;
    this.exportTime = exhibition.exportTime;
    this.active = exhibition.active;
    for (const thumb of exhibition.thumbs) {
      this.thumbs.push(thumb);
    }
  }

  public equal(exhibition: Exhibition): boolean {
    let returnValue = true;
    if (
      this.id !== exhibition.id ||
      !this.title.equal(exhibition.title) ||
      this.createdTime !== exhibition.createdTime ||
      this.updatedTime !== exhibition.updatedTime ||
      this.active !== exhibition.active
    ) {
      returnValue = false;
    }
    for (const localthumb of this.thumbs) {
      for (const thumb of exhibition.thumbs) {
        if (localthumb !== thumb) {
          returnValue = false;
        }
      }
    }
    return returnValue;
  }
}
