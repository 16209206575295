import { Language } from './models/Language';
import { environment } from '../environments/environment';
import { ContentTypes } from './models/ContentTypes';

/**
 * Configuration class
 */
export class Config {

  /**
   * The backend URL generated for the "auto" base url.
   */
  private static autoBaseUrl = '';

  /**
   * A set of base urls for the backend access.
   */
  private baseUrls = {
    'local': 'http://localhost:4202/', // Local Backend required
    'dev': 'https://dev.api.cms.augmented-art.de', // Dev
    'test': 'https://test.api.cms.augmented-art.de', // Test
    'tesa': 'https://tesa.api.augmented-art.de',
    'demo': 'https://demo.api.cms.augmented-art.de/', // Demo
    'auto': '', // determine the correct url automagically
    'postinstall': '<<backend-url>>' // inserted via sed
  };

  /**
   * The key specifies which backend url is currently used.
   */
  private currentBaseUrl = 'auto';

  /**
   * Specifies which language is used for the application.
   */
  private applicationLanguage: Language = Language.DE;

  /**
   * Maximum file size
   * 512KB = 524288
   * 1MB = 1048576
   * 6MB = 6291456
   * 10MB = 10485760
   */
  private menuEntryImageFileSize = 524288;
  private menuHeaderImageFileSize = 1048576;
  private maxImageFileSize = 2097152;
  private max3DModelFileSize = 6291456;
  private maxVideoFileSize = 15728640;
  private maxPoiContentFileSize = 6291456;
  private poiStyleFileSize = 6291456; // 524288;

  /**
   * Push Notification Content Sizes
   */
  private maxPushNotificationmaxTitleLength = 60;
  private maxPushNotificationmaxTextLength = 170;
  private maxPushNotificationUrlLength = 200;
  private maxNotificationTextLength = 300;
  private maxTitleTextLength = 200;
  private maxPoiStyleNameLength = 50;

  /**
	 * Checks whether a base url has been set via postinstall script.
	 * @returns True if an url was set, false otherwise.
	 */
  private hasSetPostinstallUrl(): boolean {
    // note that we cannot use the constant here as it will be replaced via sed
    // technically, one if would probably be enough, but for now it's better to be safe than sorry
    // also, this check won't be called often anyway
    const url = this.baseUrls['postinstall'];
    return !(url.length === 15 &&
      url.startsWith('<<') &&
      url.endsWith('>>') &&
      url.substr(2, 11) === 'backend-url');
  }

  /**
	 * Checks whether this instance is running on localhost.
	 * @returns True if that seems to be the case, false otherwise
	 */
  private isRunningOnLocalhost(): boolean {
    // note: we could check here if we're using the 127.0.0.1/8 subnet, but Sebastian is probably the only one using such constructs
    const hostname = window.location.hostname;
    return hostname === 'localhost' ||
      hostname === '127.0.0.1' ||
      hostname.endsWith('.local');
  }


  /**
   * This functions attempts to automatically find the fitting backend url.
   * @returns A valid backend url.
   */
  private FindBaseUrl(): string {
    // automagically determine the backend url
    // first, check if the postinstall inserted an url and use it if so
    if (this.hasSetPostinstallUrl()) {
      const url = this.baseUrls['postinstall'];
      console.log('Using backend url set via postinstall.', url);
      return url;
    } else if (this.isRunningOnLocalhost()) {
      const url = this.baseUrls['dev'];
      console.log('Using dev backend.', url);
      return url;
    } else {
      let parts = window.location.hostname.split('.');
      let url = '';

	  if (parts.length > 0 && parts[0] === 'www') {
		// if we're on the www subdomain, we need to ignore that prefix
		// shift removes the first element from the array, allowing the URL to be
		// determined as if it was never there
		parts.shift();
	  }

      if (parts.length === 2) {
        url = window.location.protocol + '//api.' + window.location.hostname;
      } else {
        const prefix = parts.shift();
        url = window.location.protocol + '//' + prefix + '.api.' + parts.join('.');
      }

      console.log('Generated backend url from current domain.', url);
      return url;
    }

  }

  /**
   * Returns the current base url for the backend access.
   * @returns the current base url.
   */
  public GetBaseUrl(): string {
    if (this.currentBaseUrl === 'auto') {
      if (Config.autoBaseUrl.length === 0) {
        Config.autoBaseUrl = this.FindBaseUrl();
      }

      return Config.autoBaseUrl;
    }

    return this.baseUrls[this.currentBaseUrl];
  }

  /**
   * Returns the current application language.
   * @returns the current application language.
   */
  public getApplicationLanguage(): Language {
    return this.applicationLanguage;
  }

  /**
   * Returns the maximum File size.
   * @returns the maximum File size.
   */
  public getMaxImageFileSize(): number {
    return environment.maxImageFileSize ? environment.maxImageFileSize : this.maxImageFileSize;
  }

  /** Returns the maximum file size for 3d models */
  public getMax3DModelSize(): number {
    return environment.max3DModelFileSize ? environment.max3DModelFileSize : this.max3DModelFileSize;
  }

  public getMaxGPXFileSize(): number {
    return environment.maxImageFileSize ? environment.maxImageFileSize : this.maxImageFileSize;
  }

  public getMaxVideoFileSize(): number {
    return environment.maxVideoFileSize ? environment.maxVideoFileSize : this.maxVideoFileSize;
  }

  public getMaxPoiContentFileSize(): number {
    return environment.maxPoiContentFileSize ? environment.maxPoiContentFileSize : this.maxPoiContentFileSize;
  }

  public getMenuHeaderImageFileSize(): number {
    return environment.menuHeaderImageFileSize ? environment.menuHeaderImageFileSize : this.menuHeaderImageFileSize;
  }

  public getMenuEntryImageFileSize(): number {
    return environment.menuEntryImageFileSize ? environment.menuEntryImageFileSize : this.menuEntryImageFileSize;
  }

  public getPoiStyleFileSize(): number {
    return environment.poiStyleFileSize ? environment.poiStyleFileSize : this.poiStyleFileSize;
  }

  /* unused
  public getDefaultMaxSize(): number {
    if (this.maxImageFileSize > this.maxPoiContentFileSize) {
      return this.maxImageFileSize;
    } else {
      return this.maxPoiContentFileSize;
    }
  }
  */
  public getMaxExhibitionTitleLength() {
    return 200;
  }

  public getMaxPoiStyleNameLength() {
    return this.maxPoiStyleNameLength;
  }

  public getMaxPushNotificationmaxTitleLength() {
    return this.maxPushNotificationmaxTitleLength;
  }

  public getMaxPushNotificationmaxTextLength() {
    return this.maxPushNotificationmaxTextLength;
  }

  public getMaxPushNotificationUrlLength() {
    return this.maxPushNotificationUrlLength;
  }

  public getMaxNotificationTextLength() {
    return this.maxNotificationTextLength;
  }

  public getMaxTitleTextLength() {
    return this.maxTitleTextLength;
  }

  public isDemoInstance(): boolean {
    return environment.environmentName === 'DEMO';
  }

  public getDefaultUsername(): string {
    return environment.defaultUsername;
  }
  public getDefaultPassword(): string {
    return environment.defaultPassword;
  }

  public isAppMenuEnabled(): boolean {
    return environment.appMenuEnabled;
  }

  public isPushNotificationEnabled(): boolean {
    return environment.pushNotificationEnabled;
  }

  public isToursEnabled(): boolean {
    return environment.toursEnabled;
  }

  public getEnabledContentTypes(): ContentTypes[] {
    return environment.enabledContentTypes;
  }

  public isHologramTrackerEnabled(): boolean {
    return environment.hologramTrackerEnabled;
  }

  public isCuboidTrackerEnabled(): boolean {
    return environment.cuboidTrackerEnabled;
  }

  public isPoiStylesEnabled(): boolean {
    return environment.poiStylesEnabled;
  }

  public getLoginImageVisible(): string {
    return environment.loginPictureVisible;
  }

  public getLoginImageInvisible(): string {
    return environment.loginPictureInvisible;
  }

  public isMaintainance(): boolean {
    return environment.maintainance;
  }

  public getMapUrl(): string {
    return 'https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=u9BYfHwTWyBZIma9xZOl';//environment.mapLibreUrl;
  }
}
