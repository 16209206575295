import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router, ActivationEnd } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from '../current.app.state';
import { FileDataType } from '../models/FileDataType';
import { Language } from '../models/Language';
import { Media } from '../models/Media';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { AudioElement, ElementType, ImageElement, ModelElement, VideoElement } from '../models/UiElements';
import { CurrentContextService } from '../services/currentContext.service';
import { MediaService } from '../services/media.service';
import { PoiManagerService } from '../services/poi-manager.service';
import { Tools } from '../Tools';
import { CubeSides } from '../models/CubeSides';
import { ArLayer } from '../models/ArLayer';
import { ArLayerService } from '../services/arLayer.service';
import { Cuboid } from '../models/Cuboid';
import { CuboidService } from '../services/cuboid.service';
import { Poi, PoiTextureIdValue } from '../models/Poi';
import { Config } from '../Config';
import { StringService } from '../services/string.service';
import { PoiService } from '../services/poi.service';
import { PoiTexture } from '../models/PoiTexture';
import { PoiTextureService } from '../services/poi-texture.service';
import { ContentTypes } from '../models/ContentTypes';
import { PoiStyleUploadModal } from '../modals/poi-style-upload/poi-style-upload.modal.component';
import { TabType } from '../models/TabType';

@Component({
	selector: 'aacms-edit-ar-layer-poi',
	templateUrl: './edit-ar-layer-poi.component.html',
	styleUrls: ['./edit-ar-layer-poi.component.scss']
})
export class EditArLayerPoiComponent implements OnInit, OnDestroy {

	public modalRef: BsModalRef;

	public arLayer: ArLayer;

	public cuboid: Cuboid;

	public poi: Poi;
	private originalPoi: Poi;

	public cuboidImageDataMap: Map<CubeSides, FileDataType> = new Map([
		[CubeSides.top, null],
		[CubeSides.bottom, null],
		[CubeSides.front, null],
		[CubeSides.back, null],
		[CubeSides.left, null],
		[CubeSides.right, null]
	]);
	public arLayerImageDataMap: Map<CubeSides, FileDataType> = new Map([
		[CubeSides.top, null],
		[CubeSides.bottom, null],
		[CubeSides.front, null],
		[CubeSides.back, null],
		[CubeSides.left, null],
		[CubeSides.right, null]
	]);
	public originalArLayerImageDataMap: Map<CubeSides, FileDataType> = new Map([
		[CubeSides.top, null],
		[CubeSides.bottom, null],
		[CubeSides.front, null],
		[CubeSides.back, null],
		[CubeSides.left, null],
		[CubeSides.right, null]
	]);
	public imageDataLoaded = false;

	public elementType = ElementType;
	private mediaToDelete: FileDataType[] = [];
	public contentChanged = false;

	public poiTextures: PoiTexture[] = [];

	public distance: string;

	@ViewChild('smartphone') smartphone;
	@ViewChild('cancelModalTemplate', { static: false }) cancelModalTemplate: TemplateRef<any>;

	public Language = Language;
	public contentType = ContentTypes;
	public cubeSides = CubeSides;
	public appState = AppState;
	public contentTypes = ContentTypes;
	public config: Config = new Config();

	public currentLanguage: Language;

	private saveContentSubject: Subscription;

	private navigationSubscription: Subscription;

	public Tools = Tools;

	public currentTab: TabType;
	public tabType = TabType;

	public constructor(
		public contextService: CurrentContextService,
		public pms: PoiManagerService,
		private mediaService: MediaService,
		private modalService: BsModalService,
		private arLayerService: ArLayerService,
		private cuboidService: CuboidService,
		private router: Router,
		private route: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private stringService: StringService,
		private poiService: PoiService,
		private poiTextureService: PoiTextureService
	) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof ActivationEnd) {
				this.arLayer = null;
				this.cuboid = null;
				this.pms.selectedPoi = null;
				this.currentLanguage = Language.DE;
				const arlayerId = e.snapshot.paramMap.get('arLayerId');
				const cuboidId = e.snapshot.paramMap.get('cuboidId');
				const poiId = e.snapshot.paramMap.get('poiId');
				if (arlayerId && cuboidId && poiId) {
					this.contextService.setCurrentState(AppState.editArLayerPoi);
					if (this.poi && poiId !== this.poi.id) {
						this.poi = null;
						this.originalPoi = null;
						this.mediaToDelete = [];
						this.contentChanged = false;
					}
					this.getARLayer(arlayerId);
					this.getCuboid(cuboidId);
					this.getPoi(poiId);
					this.getPoiTextures();
				}
			}
		});
	}

	public ngOnInit(): void {
		this.saveContentSubject = this.contextService.saveYourContent.subscribe(
			saveContent => {
				if (saveContent) {
					this.save();
				}
			}
		);
	}

	public ngOnDestroy(): void {
		this.navigationSubscription.unsubscribe();
		this.saveContentSubject.unsubscribe();
		this.returnToArLayer();
	}

	@HostListener('window:beforeunload')
	canDeactivate(): Observable<boolean> | boolean {
		return (this.contentChanged || this.pms.poisChanged) ? false : true;
	}

	public async getPoi(poiId): Promise<any> {
		if (this.pms.isLoadingPois) {
			await this.pms.isLoadingPois;
		}
		this.poi = this.pms.getCurrentPoiById(poiId);
		if (this.poi === undefined) {
			const imageId = this.route.snapshot.paramMap.get('imageId');
			this.router.navigate(['images/' + imageId]);
		}
		this.distance = this.poi.distanceToTracker.toString();
		this.originalPoi = this.pms.getOriginalPoiById(poiId);
		if (this.originalPoi === undefined) {
			this.originalPoi = new Poi(this.poi);
		}
		this.pms.selectedPoi = this.poi;
		this.checkForContentChange();
	}

	public askToSave(): void {
		this.modalRef = this.modalService.show(this.cancelModalTemplate, { keyboard: false, ignoreBackdropClick: true });
	}

	public discard(): void {
		this.mediaToDelete = [];
		this.poi = this.pms.resetPoiById(this.poi.id, this.originalPoi);
		this.distance = this.poi.distanceToTracker.toString();
		this.smartphone.onDiscard();
		this.checkForContentChange();
		this.hideModal();
	}

	public hideModal(): void {
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	public showContentTypesSelection(): boolean {
		return this.config.getEnabledContentTypes().length > 1;
	}

	public getStringNameForContentType(type: ContentTypes): string {
		switch (type) {
			case ContentTypes.Custom: {
				return this.stringService.get('CUSTOM');
			}
			case ContentTypes.URL: {
				return this.stringService.get('URL');
			}
		}
	}

	public updateDistanceToTracker(value, input: HTMLInputElement): void {
		value = Tools.checkForNumber(value, true);
		if (value === null) {
			value = this.poi.distanceToTracker.toString();
		} else if (value === '') {
			this.poi.distanceToTracker = 0;
		} else {
			this.poi.distanceToTracker = +value;
		}
		this.distance = value;
		this.checkForContentChange();
		input.value = value;
	}

	private getARLayer(arLayerId: string) {
		this.imageDataLoaded = false;
		const sub = this.arLayerService.getArLayerById(arLayerId).subscribe(
			arLayer => {
				this.arLayer = new ArLayer(arLayer);
				const requests = [];
				if (this.arLayer.mediaIds.top && this.arLayer.mediaIds.top.mediaId) {
					requests.push(this.loadARLayerImage(this.arLayer.mediaIds.top.mediaId, CubeSides.top));
				}
				if (this.arLayer.mediaIds.bottom && this.arLayer.mediaIds.bottom.mediaId) {
					requests.push(this.loadARLayerImage(this.arLayer.mediaIds.bottom.mediaId, CubeSides.bottom));
				}
				if (this.arLayer.mediaIds.left && this.arLayer.mediaIds.left.mediaId) {
					requests.push(this.loadARLayerImage(this.arLayer.mediaIds.left.mediaId, CubeSides.left));
				}
				if (this.arLayer.mediaIds.right && this.arLayer.mediaIds.right.mediaId) {
					requests.push(this.loadARLayerImage(this.arLayer.mediaIds.right.mediaId, CubeSides.right));
				}
				if (this.arLayer.mediaIds.front && this.arLayer.mediaIds.front.mediaId) {
					requests.push(this.loadARLayerImage(this.arLayer.mediaIds.front.mediaId, CubeSides.front));
				}
				if (this.arLayer.mediaIds.back && this.arLayer.mediaIds.back.mediaId) {
					requests.push(this.loadARLayerImage(this.arLayer.mediaIds.back.mediaId, CubeSides.back));
				}
				const sub1 = forkJoin(requests).subscribe(
					() => {
						this.imageDataLoaded = true;
						sub1.unsubscribe();
					}
				);
				sub.unsubscribe();
			}
		);
	}

	private loadARLayerImage(mediaId: string, mapId: CubeSides): Observable<any> {
		const fileDataType: FileDataType = new FileDataType();
		return this.mediaService.getMediaById(mediaId).pipe(
			tap(
				media => {
					fileDataType.mediaId = mediaId;
					fileDataType.media = new Media(media);
					const sub = this.mediaService.downloadFile(media.rawUrl).subscribe(
						blob => {
							const file = Tools.blobToFile(blob, 'image.jpg');
							fileDataType.file = file;
							fileDataType.fileSafeUrl = this.createImageUrl(file);
							this.arLayerImageDataMap.set(mapId, fileDataType);
							this.originalArLayerImageDataMap.set(mapId, new FileDataType(fileDataType));
							sub.unsubscribe();
						}
					);
				})
		);
	}

	private getCuboid(cuboidId: string) {
		this.imageDataLoaded = false;
		const sub = this.cuboidService.getCuboidById(cuboidId).subscribe(
			cuboid => {
				this.cuboid = new Cuboid(cuboid);
				this.contextService.getExhibition(this.cuboid.exhibitionIds[0]);
				const requests = [];
				if (this.cuboid.trackerIds.top && this.cuboid.trackerIds.top.mediaId) {
					requests.push(this.loadCuboidImage(this.cuboid.trackerIds.top.mediaId, CubeSides.top));
				}
				if (this.cuboid.trackerIds.bottom && this.cuboid.trackerIds.bottom.mediaId) {
					requests.push(this.loadCuboidImage(this.cuboid.trackerIds.bottom.mediaId, CubeSides.bottom));
				}
				if (this.cuboid.trackerIds.left && this.cuboid.trackerIds.left.mediaId) {
					requests.push(this.loadCuboidImage(this.cuboid.trackerIds.left.mediaId, CubeSides.left));
				}
				if (this.cuboid.trackerIds.right && this.cuboid.trackerIds.right.mediaId) {
					requests.push(this.loadCuboidImage(this.cuboid.trackerIds.right.mediaId, CubeSides.right));
				}
				if (this.cuboid.trackerIds.front && this.cuboid.trackerIds.front.mediaId) {
					requests.push(this.loadCuboidImage(this.cuboid.trackerIds.front.mediaId, CubeSides.front));
				}
				if (this.cuboid.trackerIds.back && this.cuboid.trackerIds.back.mediaId) {
					requests.push(this.loadCuboidImage(this.cuboid.trackerIds.back.mediaId, CubeSides.back));
				}
				const sub1 = forkJoin(requests).subscribe(
					() => {
						this.imageDataLoaded = true;
						sub1.unsubscribe();
					}
				);
				sub.unsubscribe();
			}
		);
	}

	private loadCuboidImage(mediaId: string, mapId: CubeSides): Observable<any> {
		const fileDataType: FileDataType = new FileDataType();
		return this.mediaService.getMediaById(mediaId).pipe(
			tap(
				media => {
					fileDataType.mediaId = mediaId;
					fileDataType.media = new Media(media);
					const sub = this.mediaService.downloadFile(media.rawUrl).subscribe(
						blob => {
							const file = Tools.blobToFile(blob, 'image.jpg');
							fileDataType.file = file;
							fileDataType.fileSafeUrl = this.createImageUrl(file);
							this.cuboidImageDataMap.set(mapId, fileDataType);
							sub.unsubscribe();
						}
					);
				})
		);
	}

	public getPoiTextures(): void {
		this.poiTextures = [];
		this.loadDefaultTextures();
		const subscription = this.poiTextureService.getTextures().subscribe(
			textures => {
				for (const texture of textures) {
					this.poiTextures.push(new PoiTexture(texture));
				}
				subscription.unsubscribe();
			}
		);
	}

	public loadDefaultTextures(): void {
		const defaultTexture1 = new PoiTexture();
		defaultTexture1.id = '00000000-0000-0000-0000-000000000001';
		defaultTexture1.mediaId = '00000000-0000-0000-0000-000000000001';
		defaultTexture1.name = this.stringService.get('DEFAULT_TEXTURE_1');
		this.poiTextures.push(defaultTexture1);
		const defaultTexture2 = new PoiTexture();
		defaultTexture2.id = '00000000-0000-0000-0000-000000000002';
		defaultTexture2.mediaId = '00000000-0000-0000-0000-000000000002';
		defaultTexture2.name = this.stringService.get('DEFAULT_TEXTURE_2');
		this.poiTextures.push(defaultTexture2);
		const defaultTransparentCircle = new PoiTexture();
		defaultTransparentCircle.id = '00000000-0000-0000-0000-000000000003';
		defaultTransparentCircle.mediaId = '00000000-0000-0000-0000-000000000003';
		defaultTransparentCircle.name = this.stringService.get('DEFAULT_TEXTURE_3');
		this.poiTextures.push(defaultTransparentCircle);
	}

	private createImageUrl(file: File): SafeUrl {
		const urlCreator = window.URL;
		const url = this.sanitizer.bypassSecurityTrustUrl(
			urlCreator.createObjectURL(file));
		return url;
	}

	public getLanguageValue(text: MultiLanguageText): string {
		return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
	}

	public checkForContentChange(): void {
		if (!this.poi.equal(this.originalPoi)) {
			this.contentChanged = true;
			return;
		}
		this.contentChanged = false;
	}

	public save(): void {
		this.contextService.setSaveYourContent(false);
		let saveContent = (this.contentChanged) ? true : false;
		let savePois = (this.pms.poisChanged) ? true : false;
		if (saveContent || savePois) {
			this.contextService.setSavingContent(true);
		}
		if (this.contentChanged) {
			const requests: Observable<any>[] = [];
			for (const object of this.mediaToDelete) {
				if (object !== undefined && object.mediaId !== undefined) {
					requests.push(this.mediaService.deleteMedia(object.mediaId).pipe(
						tap()
					));
				}
			}
			const sub = forkJoin(requests).subscribe(
				response => {
				}, error => {
				},
				() => {
					this.originalPoi = new Poi(this.poi);
					const sub1 = this.poiService.updatePoi(this.poi).subscribe(
						response => {
							this.checkForContentChange();
							this.hideModal();
							saveContent = false;
							if (!saveContent && !savePois) {
								this.contextService.setSavingContent(false);
							}
							sub1.unsubscribe();
							sub.unsubscribe();
						});
				});
		}
		if (this.pms.poisChanged) {
			const savePoiSubscription = this.pms.saveChangedPois();
			savePoiSubscription.add(() => {
				savePois = false;
				if (!saveContent && !savePois) {
					this.contextService.setSavingContent(false);
				}
				savePoiSubscription.unsubscribe();
			});
		}
	}

	public openModal(modalTemplate: TemplateRef<any>): void {
		this.modalRef = this.modalService.show(modalTemplate);
	}

	public delete(): void {
		this.pms.pois = this.pms.pois.filter(poi => poi.id !== this.poi.id);
		const sub = this.poiService.deletePoi(this.poi).subscribe(() => {
			this.pms.updatePoiPositions();
			this.pms.poisChanged = false;
			this.contentChanged = false;
			this.pms.saveChangedPois();
			this.hideModal();
			this.returnToArLayer();
			sub.unsubscribe();
		});
	}

	public returnToArLayer(): void {
		this.router.navigate(['cuboids/' + this.route.snapshot.paramMap.get('cuboidId') + '/arlayers/' + this.route.snapshot.paramMap.get('arLayerId')]);
	}

	public setLanguage(language: Language): void {
		this.currentLanguage = language;
	}

	public isLanguage(language: Language): boolean {
		return language === this.currentLanguage;
	}

	public updateLanguage(language: Language): void {
		this.currentLanguage = language;
		setTimeout(() => {
			this.pms.reloadPoiLayer();
		});
	}

	public showPoiTexturesSelection(): boolean {
		return this.config.isPoiStylesEnabled();
	}

	public poiTextureChanged(event: Event): void {
		let textureId = (event.target as any).value;
		if (textureId) {
			this.poi.multiLanguageTextureId.set(this.currentLanguage, PoiTextureIdValue.fromTextureId(textureId));
			this.checkForContentChange();
			this.pms.reloadPoi(this.poi);
		} else {
			event.preventDefault();
			this.createPoiStyles();
		}
	}

	public createPoiStyles(): void {
		this.modalRef = this.modalService.show(PoiStyleUploadModal,
			{ class: 'modal-lg', keyboard: false, ignoreBackdropClick: true });
		const subscription = this.modalService.onHide.subscribe(() => {
			this.getPoiTextures();
			subscription.unsubscribe();
		});
	}

	public togglePoiActivation(): void {
		this.poi.active = !this.poi.active;
		this.checkForContentChange();
	}

	public toggleOpener(): void {
		this.poi.opener = !this.poi.opener;
		this.checkForContentChange();
	}

	public getTrackerFileData(): Map<CubeSides, FileDataType> {
		return new Map([
			[CubeSides.top, this.arLayerImageDataMap.get(CubeSides.top) !== null && this.arLayer.mediaIds.top.useImage ? this.arLayerImageDataMap.get(CubeSides.top) : this.cuboidImageDataMap.get(CubeSides.top)],
			[CubeSides.bottom, this.arLayerImageDataMap.get(CubeSides.bottom) !== null && this.arLayer.mediaIds.bottom.useImage ? this.arLayerImageDataMap.get(CubeSides.bottom) : this.cuboidImageDataMap.get(CubeSides.bottom)],
			[CubeSides.front, this.arLayerImageDataMap.get(CubeSides.front) !== null && this.arLayer.mediaIds.front.useImage ? this.arLayerImageDataMap.get(CubeSides.front) : this.cuboidImageDataMap.get(CubeSides.front)],
			[CubeSides.back, this.arLayerImageDataMap.get(CubeSides.back) !== null && this.arLayer.mediaIds.back.useImage ? this.arLayerImageDataMap.get(CubeSides.back) : this.cuboidImageDataMap.get(CubeSides.back)],
			[CubeSides.left, this.arLayerImageDataMap.get(CubeSides.left) !== null && this.arLayer.mediaIds.left.useImage ? this.arLayerImageDataMap.get(CubeSides.left) : this.cuboidImageDataMap.get(CubeSides.left)],
			[CubeSides.right, this.arLayerImageDataMap.get(CubeSides.right) !== null && this.arLayer.mediaIds.right.useImage ? this.arLayerImageDataMap.get(CubeSides.right) : this.cuboidImageDataMap.get(CubeSides.right)]
		]);
	}

	public routeTo(appState: AppState): void {
		switch (appState) {
			case AppState.exhibitions:
				this.router.navigate(['exhibitions']);
				break;
			case AppState.images:
				this.router.navigate(['exhibitions', this.cuboid.exhibitionIds[0], 'images']);
				break;
			case AppState.editCuboid:
				this.router.navigate(['cuboids', this.cuboid.id]);
				break;
			case AppState.editARLayer:
				this.router.navigate(['cuboids', this.cuboid.id, 'arlayers', this.arLayer.id]);
				break;
			default:
				break;
		}
	}

	public isTabAllowed(type: ContentTypes): boolean {
		for (const contentType of this.config.getEnabledContentTypes()) {
			if (contentType === type) {
				return true;
			}
		}
		return false;
	}
}
