import { Config } from "../Config";
import { Card } from "./Card";
import { ContentTypes } from "./ContentTypes";
import { Location } from "./Location";
import { MultiLanguageFileDataType } from "./MultiLanguageFileDataType";
import { MultiLanguageText } from "./MultiLanguageText";
import { Tracker } from "./Tracker";
import { ModelHelper } from "./ModelHelper";
import { UiElement } from "./UiElements";
import { TriggerBehavior } from "./TriggerBehavior";

export class Place extends Tracker {
    public location: Location = new Location();
    public radius: number;
    public card: Card = new Card();
    public uiElements: UiElement[] = [];
    public popupElements: UiElement[] = [];
    public cardsActive: boolean = true;
    public triggerBehavior = TriggerBehavior.Always;
    public contentType: ContentTypes = new Config().getEnabledContentTypes()[0];
    public url: MultiLanguageText = new MultiLanguageText();
    public notifyImage = new MultiLanguageFileDataType();
    public notifyText = new MultiLanguageText();

    constructor(tracker?: Place) {
        super(tracker);
        if (tracker !== undefined) {
            this.location = new Location(tracker.location);
            this.radius = tracker.radius;
            this.card = new Card(tracker.card);
            this.uiElements = ModelHelper.cloneUiElementArray(tracker.uiElements).sort((uie1, uie2) => uie1.position - uie2.position);
            this.popupElements = ModelHelper.cloneUiElementArray(tracker.popupElements).sort((uie1, uie2) => uie1.position - uie2.position);
            this.cardsActive = tracker.cardsActive;
            this.triggerBehavior = tracker.triggerBehavior || TriggerBehavior.Always;
            this.contentType = tracker.contentType;
            this.url = new MultiLanguageText(tracker.url);
            this.notifyImage = new MultiLanguageFileDataType(tracker.notifyImage);
            this.notifyText = new MultiLanguageText(tracker.notifyText);
        }
    }

    public equal(place: Place): boolean {
        if (
            !super.equal(place) ||
            !this.location.equal(place.location) ||
            this.radius !== place.radius ||
            !this.card.equal(place.card) ||
            this.cardsActive !== place.cardsActive ||
            this.triggerBehavior !== place.triggerBehavior ||
            this.contentType !== place.contentType ||
            !this.url.equal(place.url) ||
            !this.notifyImage.equal(place.notifyImage) ||
            !this.notifyText.equal(place.notifyText)
        ) {
            return false;
        }

        if(!ModelHelper.arrayEqual(this.uiElements, place.uiElements)) { return false; }
        if(!ModelHelper.arrayEqual(this.popupElements, place.popupElements)) { return false; }

        return true;
    }
}