import { Poi } from './../models/Poi';
import { AuthService } from './../services/auth.service';
import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router, ActivationEnd } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from '../current.app.state';
import { FileDataType } from '../models/FileDataType';
import { Language } from '../models/Language';
import { Media } from '../models/Media';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { AudioElement, ElementType, ImageElement, ModelElement, VideoElement } from '../models/UiElements';
import { CurrentContextService } from '../services/currentContext.service';
import { MediaService } from '../services/media.service';
import { PoiManagerService } from '../services/poi-manager.service';
import { Tools } from '../Tools';
import { CubeSides } from '../models/CubeSides';
import { ArLayer } from '../models/ArLayer';
import { ArLayerService } from '../services/arLayer.service';
import { Cuboid } from '../models/Cuboid';
import { CuboidService } from '../services/cuboid.service';
import { StringService } from '../services/string.service';
import { Config } from '../Config';
import { ContentTypes } from '../models/ContentTypes';
import { saveAs } from 'file-saver';
import { TabType } from '../models/TabType';

@Component({
  selector: 'aacms-edit-ar-layer-meta',
  templateUrl: './edit-ar-layer-meta.component.html',
  styleUrls: ['./edit-ar-layer-meta.component.scss']
})
export class EditArLayerMetaComponent implements OnInit, OnDestroy {

  public modalRef: BsModalRef;

  public arLayer: ArLayer;
  private originalArLayer: ArLayer;

  public cuboid: Cuboid;

  public cuboidImageDataMap: Map<CubeSides, FileDataType> = new Map([
    [CubeSides.top, null],
    [CubeSides.bottom, null],
    [CubeSides.front, null],
    [CubeSides.back, null],
    [CubeSides.left, null],
    [CubeSides.right, null]
  ]);
  public arLayerImageDataMap: Map<CubeSides, FileDataType> = new Map([
    [CubeSides.top, null],
    [CubeSides.bottom, null],
    [CubeSides.front, null],
    [CubeSides.back, null],
    [CubeSides.left, null],
    [CubeSides.right, null]
  ]);
  public originalArLayerImageDataMap: Map<CubeSides, FileDataType> = new Map([
    [CubeSides.top, null],
    [CubeSides.bottom, null],
    [CubeSides.front, null],
    [CubeSides.back, null],
    [CubeSides.left, null],
    [CubeSides.right, null]
  ]);
  public imageDataLoaded = false;

  public arLayerOpacity = 100;
  public enableTrackerLayer = true;

  public elementType = ElementType;
  private mediaToDelete: FileDataType[] = [];
  public contentChanged = false;

  public cubeSides = CubeSides;
  public config = new Config();

  @ViewChild('smartphone') smartphone;

  @ViewChild('cancelModalTemplate', { static: false }) cancelModalTemplate: TemplateRef<any>;

  public mapIds: CubeSides[] = [CubeSides.top, CubeSides.bottom, CubeSides.front, CubeSides.back, CubeSides.left, CubeSides.right];
  public Language = Language;
  public appState = AppState;
  public Tools = Tools;

  public hovered = false;

  public contentTypes = ContentTypes;

  public currentLanguage: Language;

  private saveContentSubject: Subscription;
  private navigationSubscription: Subscription;

  public openingPoi: Poi;

  public currentTab: TabType;
  public tabType = TabType;

  public constructor(
    public contextService: CurrentContextService,
    public pms: PoiManagerService,
    private mediaService: MediaService,
    private modalService: BsModalService,
    private arLayerService: ArLayerService,
    private cuboidService: CuboidService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private stringService: StringService,
    private authService: AuthService
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof ActivationEnd) {
        this.arLayer = null;
        this.cuboid = null;
        this.pms.selectedPoi = null;
        this.pms.isLoadingPois.then(
          () => {
            for (const poi of this.pms.pois) {
              if (poi.opener) {
                this.openingPoi = poi;
                break;
              }
            }
          }
        );
        this.currentLanguage = Language.DE;
        const arlayerId = e.snapshot.paramMap.get('arLayerId');
        const cuboidId = e.snapshot.paramMap.get('cuboidId');
        if (arlayerId && cuboidId) {
          this.contextService.setCurrentState(AppState.editArLayerMeta);
          this.getARLayer(arlayerId);
          this.getCuboid(cuboidId);
        }
      }
    });
  }

  public ngOnInit(): void {
    this.saveContentSubject = this.contextService.saveYourContent.subscribe(
      saveContent => {
        if (saveContent) {
          this.save();
        }
      }
    );
  }

  public ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
    this.saveContentSubject.unsubscribe();
    this.returnToArLayer();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (this.contentChanged || this.pms.poisChanged) ? false : true;
  }

  public handleFilesFromDropin(files: File[]): void {
    if (files.length > 0) {
      this.contextService.setSavingContent(true);
      this.mediaService.uploadFile(files[0]).subscribe(
        response => {
          if (response.status < 400) {
            const location: string = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
            const mediaId: string = location.substring(location.lastIndexOf('/') + 1);
            let unboxingModel = new FileDataType();
            unboxingModel.mediaId = mediaId;
            unboxingModel.isLoaded = false;
            unboxingModel.file = files[0];
            unboxingModel = Tools.createModelUrl(unboxingModel);
            unboxingModel.isLoaded = true;
            unboxingModel.media = new Media();
            this.arLayer.unboxingModel.set(this.currentLanguage, unboxingModel);
            this.checkForContentChange();
          }
          this.contextService.setSavingContent(false);
        });
    }
  }

  public askToSave(): void {
    this.modalRef = this.modalService.show(this.cancelModalTemplate, { keyboard: false, ignoreBackdropClick: true });
  }

  public discard(): void {
    this.mediaToDelete = [];
    this.arLayer = new ArLayer(this.originalArLayer);
    this.arLayerImageDataMap = new Map(this.originalArLayerImageDataMap);
    this.smartphone.onDiscard();
    this.checkForContentChange();
    this.hideModal();
  }

  public hideModal(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  private getARLayer(arLayerId: string) {
    this.imageDataLoaded = false;
    const sub = this.arLayerService.getArLayerById(arLayerId).subscribe(
      arLayer => {
        this.arLayer = new ArLayer(arLayer);
        this.originalArLayer = new ArLayer(arLayer);
        const requests = [];
        if (this.arLayer.mediaIds.top && this.arLayer.mediaIds.top.mediaId) {
          requests.push(this.loadARLayerImage(this.arLayer.mediaIds.top.mediaId, CubeSides.top));
        }
        if (this.arLayer.mediaIds.bottom && this.arLayer.mediaIds.bottom.mediaId) {
          requests.push(this.loadARLayerImage(this.arLayer.mediaIds.bottom.mediaId, CubeSides.bottom));
        }
        if (this.arLayer.mediaIds.left && this.arLayer.mediaIds.left.mediaId) {
          requests.push(this.loadARLayerImage(this.arLayer.mediaIds.left.mediaId, CubeSides.left));
        }
        if (this.arLayer.mediaIds.right && this.arLayer.mediaIds.right.mediaId) {
          requests.push(this.loadARLayerImage(this.arLayer.mediaIds.right.mediaId, CubeSides.right));
        }
        if (this.arLayer.mediaIds.front && this.arLayer.mediaIds.front.mediaId) {
          requests.push(this.loadARLayerImage(this.arLayer.mediaIds.front.mediaId, CubeSides.front));
        }
        if (this.arLayer.mediaIds.back && this.arLayer.mediaIds.back.mediaId) {
          requests.push(this.loadARLayerImage(this.arLayer.mediaIds.back.mediaId, CubeSides.back));
        }
        if (this.arLayer.unboxingModel) {
          for(const value of this.arLayer.unboxingModel.getAllValues()) {
            if (value !== undefined) {
              requests.push(this.loadModel(value));
            }
          }
        }
        const sub1 = forkJoin(requests).subscribe(
          () => {
            this.imageDataLoaded = true;
            sub1.unsubscribe();
          }
        );
        sub.unsubscribe();
      }
    );
  }

  public loadModel(fileData: FileDataType): Observable<any> {
    return this.mediaService.getMediaById(fileData.mediaId).pipe(
      tap(
        media => {
          fileData.media = new Media(media);
          fileData.isLoaded = false;
          const sub = this.mediaService.downloadFile(fileData.media.rawUrl).subscribe(
            blob => {
              const file = Tools.blobToFile(blob, media.name);
              fileData.file = file;
              if (Tools.isFileModel(fileData)) {
                fileData = Tools.createModelUrl(fileData);
              }
              fileData.isLoaded = true;
              sub.unsubscribe();
            }
          );
        }
      ));
  }

  private loadARLayerImage(mediaId: string, mapId: CubeSides): Observable<any> {
    const fileDataType: FileDataType = new FileDataType();
    return this.mediaService.getMediaById(mediaId).pipe(
      tap(
        media => {
          fileDataType.mediaId = mediaId;
          fileDataType.media = new Media(media);
          const sub = this.mediaService.downloadFile(media.rawUrl).subscribe(
            blob => {
              const file = Tools.blobToFile(blob, 'image.jpg');
              fileDataType.file = file;
              fileDataType.fileSafeUrl = this.createImageUrl(file);
              this.arLayerImageDataMap.set(mapId, fileDataType);
              this.originalArLayerImageDataMap.set(mapId, new FileDataType(fileDataType));
              sub.unsubscribe();
            }
          );
        })
    );
  }

  private getCuboid(cuboidId: string) {
    this.imageDataLoaded = false;
    const sub = this.cuboidService.getCuboidById(cuboidId).subscribe(
      cuboid => {
        this.cuboid = new Cuboid(cuboid);
        this.contextService.getExhibition(this.cuboid.exhibitionIds[0]);
        const requests = [];
        if (this.cuboid.trackerIds.top && this.cuboid.trackerIds.top.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.top.mediaId, CubeSides.top));
        }
        if (this.cuboid.trackerIds.bottom && this.cuboid.trackerIds.bottom.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.bottom.mediaId, CubeSides.bottom));
        }
        if (this.cuboid.trackerIds.left && this.cuboid.trackerIds.left.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.left.mediaId, CubeSides.left));
        }
        if (this.cuboid.trackerIds.right && this.cuboid.trackerIds.right.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.right.mediaId, CubeSides.right));
        }
        if (this.cuboid.trackerIds.front && this.cuboid.trackerIds.front.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.front.mediaId, CubeSides.front));
        }
        if (this.cuboid.trackerIds.back && this.cuboid.trackerIds.back.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.back.mediaId, CubeSides.back));
        }
        const sub1 = forkJoin(requests).subscribe(
          () => {
            this.imageDataLoaded = true;
            sub1.unsubscribe();
          }
        );
        sub.unsubscribe();
      }
    );
  }

  private loadCuboidImage(mediaId: string, mapId: CubeSides): Observable<any> {
    const fileDataType: FileDataType = new FileDataType();
    return this.mediaService.getMediaById(mediaId).pipe(
      tap(
        media => {
          fileDataType.mediaId = mediaId;
          fileDataType.media = new Media(media);
          const sub = this.mediaService.downloadFile(media.rawUrl).subscribe(
            blob => {
              const file = Tools.blobToFile(blob, 'image.jpg');
              fileDataType.file = file;
              fileDataType.fileSafeUrl = this.createImageUrl(file);
              this.cuboidImageDataMap.set(mapId, fileDataType);
              sub.unsubscribe();
            }
          );
        })
    );
  }

  private createImageUrl(file: File): SafeUrl {
    const urlCreator = window.URL;
    const url = this.sanitizer.bypassSecurityTrustUrl(
      urlCreator.createObjectURL(file));
    return url;
  }

  public getLanguageValue(text: MultiLanguageText): string {
    return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
  }

  public checkForContentChange(): void {
    for (const imageDataKey of this.arLayerImageDataMap.keys()) {
      if (this.imageDataChanged(this.arLayerImageDataMap.get(imageDataKey), this.originalArLayerImageDataMap.get(imageDataKey))) {
        this.contentChanged = true;
        return;
      }
    }
    this.contentChanged = !this.arLayer.equal(this.originalArLayer);
  }

  private imageDataChanged(imageData: FileDataType, originalImageData: FileDataType): boolean {
    if (
      !imageData && originalImageData ||
      imageData && !originalImageData
    ) {
      return true;
    }
    if (!imageData && !originalImageData) {
      return false;
    }
    if (!imageData.equal(originalImageData)) {
      return true;
    }
    return false;
  }

  public save(): void {
    this.contextService.setSaveYourContent(false);
    let saveContent = (this.contentChanged) ? true : false;
    let savePois = (this.pms.poisChanged) ? true : false;

    if (saveContent || savePois) {
      this.contextService.setSavingContent(true);
    }
    if (this.contentChanged) {
      const requests: Observable<any>[] = [];
      for (const side of this.mapIds) {
        if (
          this.arLayerImageDataMap.get(side) &&
          this.originalArLayerImageDataMap.get(side) &&
          !this.arLayerImageDataMap.get(side).equal(this.originalArLayerImageDataMap.get(side))
        ) {
          requests.push(this.mediaService.uploadFile(this.arLayerImageDataMap.get(side).file).pipe(
            tap(
              response => {
                const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
                this.arLayer.mediaIds.setSide(side, location.substring(location.lastIndexOf('/') + 1));
              }
            )));
          if (this.originalArLayerImageDataMap.get(side)) {
            requests.push(this.mediaService.deleteMedia(this.originalArLayerImageDataMap.get(side).mediaId));
          }
        } else if (this.arLayerImageDataMap.get(side) && !this.originalArLayerImageDataMap.get(side)) {
          requests.push(this.mediaService.uploadFile(this.arLayerImageDataMap.get(side).file).pipe(
            tap(
              response => {
                const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
                this.arLayer.mediaIds.setSide(side, location.substring(location.lastIndexOf('/') + 1));
              }
            )));
        } else if (!this.arLayerImageDataMap.get(side) && this.originalArLayerImageDataMap.get(side)) {
          requests.push(this.mediaService.deleteMedia(this.originalArLayerImageDataMap.get(side).mediaId));
          this.arLayer.mediaIds.setSide(side, null);
        }
        if (this.arLayerImageDataMap.get(side)) {
          this.originalArLayerImageDataMap.set(side, new FileDataType(this.arLayerImageDataMap.get(side)));
        } else {
          this.originalArLayerImageDataMap.set(side, null);
        }
      }
      for (const object of this.mediaToDelete) {
        if (object !== undefined && object.mediaId !== undefined) {
          requests.push(this.mediaService.deleteMedia(object.mediaId).pipe(
            tap()
          ));
        }
      }
      const sub = forkJoin(requests).subscribe(
        response => {
        }, error => {
        },
        () => {
          const sub3 = this.arLayerService.updateArLayer(this.arLayer).subscribe(
            response => {
              this.originalArLayer = new ArLayer(this.arLayer);
              this.checkForContentChange();
              this.hideModal();
              saveContent = false;
              if (!saveContent && !savePois) {
                this.contextService.setSavingContent(false);
              }
              sub3.unsubscribe();
              sub.unsubscribe();
            });
        });
    }
    if (this.pms.poisChanged) {
      const savePoiSubscription = this.pms.saveChangedPois();
      savePoiSubscription.add(() => {
        savePois = false;
        if (!saveContent && !savePois) {
          this.contextService.setSavingContent(false);
        }
        savePoiSubscription.unsubscribe();
      });
    }
  }

  public openModal(modalTemplate: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(modalTemplate);
  }

  public returnToArLayer(): void {
    this.router.navigate(['cuboids/' + this.route.snapshot.paramMap.get('cuboidId') + '/arlayers/' + this.route.snapshot.paramMap.get('arLayerId')]);
  }

  public setLanguage(language: Language): void {
    this.currentLanguage = language;
  }

  public isLanguage(language: Language): boolean {
    return language === this.currentLanguage;
  }

  public toggleArLayerCardsActivation(): void {
    this.arLayer.cardsActive = !this.arLayer.cardsActive;
    this.checkForContentChange();
  }

  public updateLanguage(language: Language): void {
    this.currentLanguage = language;
    setTimeout(() => {
      this.pms.reloadPoiLayer();
    });
  }

  public toggleArLayerActivation(): void {
    this.arLayer.active = !this.arLayer.active;
    this.checkForContentChange();
  }

  public showContentTypesSelection(): boolean {
    return this.config.getEnabledContentTypes().length > 1;
  }

  public getStringNameForContentType(type: ContentTypes): string {
    switch (type) {
      case ContentTypes.Custom: {
        return this.stringService.get('CUSTOM');
      }
      case ContentTypes.URL: {
        return this.stringService.get('URL');
      }
    }
  }

  public getTrackerFileData(): Map<CubeSides, FileDataType> {
    return new Map([
      [CubeSides.top, this.arLayerImageDataMap.get(CubeSides.top) !== null && this.arLayer.mediaIds.top.useImage ? this.arLayerImageDataMap.get(CubeSides.top) : this.cuboidImageDataMap.get(CubeSides.top)],
      [CubeSides.bottom, this.arLayerImageDataMap.get(CubeSides.bottom) !== null && this.arLayer.mediaIds.bottom.useImage ? this.arLayerImageDataMap.get(CubeSides.bottom) : this.cuboidImageDataMap.get(CubeSides.bottom)],
      [CubeSides.front, this.arLayerImageDataMap.get(CubeSides.front) !== null && this.arLayer.mediaIds.front.useImage ? this.arLayerImageDataMap.get(CubeSides.front) : this.cuboidImageDataMap.get(CubeSides.front)],
      [CubeSides.back, this.arLayerImageDataMap.get(CubeSides.back) !== null && this.arLayer.mediaIds.back.useImage ? this.arLayerImageDataMap.get(CubeSides.back) : this.cuboidImageDataMap.get(CubeSides.back)],
      [CubeSides.left, this.arLayerImageDataMap.get(CubeSides.left) !== null && this.arLayer.mediaIds.left.useImage ? this.arLayerImageDataMap.get(CubeSides.left) : this.cuboidImageDataMap.get(CubeSides.left)],
      [CubeSides.right, this.arLayerImageDataMap.get(CubeSides.right) !== null && this.arLayer.mediaIds.right.useImage ? this.arLayerImageDataMap.get(CubeSides.right) : this.cuboidImageDataMap.get(CubeSides.right)]
    ]);
  }

  public routeTo(appState: AppState): void {
    switch (appState) {
      case AppState.exhibitions:
        this.router.navigate(['exhibitions']);
        break;
      case AppState.images:
        this.router.navigate(['exhibitions', this.cuboid.exhibitionIds[0], 'images']);
        break;
      case AppState.editCuboid:
        this.router.navigate(['cuboids', this.cuboid.id]);
        break;
      case AppState.editARLayer:
        this.router.navigate(['cuboids', this.cuboid.id, 'arlayers', this.arLayer.id]);
        break;
      default:
        break;
    }
  }

  public isTabAllowed(type: ContentTypes): boolean {
    for (const contentType of this.config.getEnabledContentTypes()) {
      if (contentType === type) {
        return true;
      }
    }
    return false;
  }

  public removeUnboxingModelFile(): void {
    this.arLayer.unboxingModel.set(this.currentLanguage, undefined);
    this.checkForContentChange();
  }

  public downloadFileToSystem(file: FileDataType): void {
    saveAs(file.file, file.media.name);
  }

  public getThumbUrl(media: Media): string {
    if (media) {
      if (media.thumbUrl) {
        return this.authService.addTokenToUrl(media.thumbUrl);
      } else if (media.rawUrl) {
        return this.authService.addTokenToUrl(media.rawUrl);
      } else {
        return '../../assets/image-placeholder.png';
      }
    }
    return '';
  }

  public openBoxPoiChanged(): void {
    for (const poi of this.pms.pois) {
      poi.opener = false;
    }
    if (this.openingPoi) {
      this.openingPoi.opener = true;
    }
    this.pms.checkForPoisChanged();
  }

  public getPoiSelectionValue(poi: Poi): string {
    let value = '';
    value = value + (poi.position + 1) + ': ';
    if (poi.title.getInLanguage(this.currentLanguage)) {
      value = value + poi.title.getInLanguage(this.currentLanguage);
    } else {
      value = value + this.stringService.get('UNNAMED_POI');
    }
    return value;
  }
}
