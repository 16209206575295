// This is a new version of the ugly MultiLanguageFileDataType, that also contains
// data for UI-logic, that is then manually cleaned before upload (EWWW!)
// This class ONLY contains data - the ui logic data is stored elsewhere!

import { Tools } from "../Tools";
import { Language } from "./Language";

export type ctorOf<T> = new (...args: any) => T;

export interface MultiLanguageDataValue {
    equal(value: MultiLanguageDataValue): boolean;
}

export class MultiLanguageDataEntry<TValue extends MultiLanguageDataValue> {
    public key: Language;
    public value: TValue;

    constructor(ctor?: ctorOf<TValue>, pair?: MultiLanguageDataEntry<TValue>) {
        if (pair !== undefined) {
            this.key = pair.key;
            if (pair.value !== undefined) {
                this.value = new ctor(pair.value);
            }
        }
    }

    public equal(pair: MultiLanguageDataEntry<TValue>): boolean {
        if(this.key !== pair.key) { return false; }
        if(this.value == null && pair.value == null) { return true; }
        if((this.value != null && pair.value == null)
            || (this.value == null && pair.value != null)) { return false; }
        return this.value.equal(pair.value);
    }
}

export class MultiLanguageData<TValue extends MultiLanguageDataValue> {
    public languages: MultiLanguageDataEntry<TValue>[] = [];

    constructor(private ctor: ctorOf<TValue>, multilangData?: MultiLanguageData<TValue>) {
        if (multilangData === undefined) {
            const enumValues = Object.values(Language);
            for (const language of enumValues.slice(enumValues.length / 2, enumValues.length)) {
                const pair = new MultiLanguageDataEntry<TValue>();
                pair.key = +language;
                pair.value = undefined;
                this.languages.push(pair);
            }
        } else {
            if (multilangData.languages !== undefined) {
                if (multilangData.languages.length > 0) {
                    for (const pair of multilangData.languages) {
                        this.languages.push(new MultiLanguageDataEntry<TValue>(ctor, pair));
                    }
                } else {
                    const enumValues = Object.values(Language);
                    for (const language of enumValues.slice(enumValues.length / 2, enumValues.length)) {
                        const pair = new MultiLanguageDataEntry<TValue>();
                        pair.key = +language;
                        pair.value = undefined;
                        this.languages.push(pair);
                    }
                }
            }
        }
    }

    public getValueCtor() : ctorOf<TValue> { return this.ctor; }

    public getAllValues(): TValue[] {
        return this.languages.map((p) => p.value);
    }

    public getAllKeys(): Language[] {
        return this.languages.map((p) => p.key);
    }

    public get(language: Language): TValue {
        for(const pair of this.languages) {
            if (pair.key === language) {
                return pair.value;
            }
        }
        return undefined;
    }

    public getWithFallback(language: Language): TValue {
        let langPrioList = Tools.allLangs();
        { // first try main requested language
            let mainLang = this.get(language);
            if(mainLang !== undefined) { return mainLang; }
        } // then as fallback, iterate through prio list if languages
        for(let i = 0; i < langPrioList.length; ++i) {
            let fallbackLang = this.get(i as Language);
            if(fallbackLang !== undefined) { return fallbackLang; }
        }
        return undefined;
    }

    public has(language: Language): boolean {
        return (this.get(language) !== undefined);
    }

    public hasAny(): boolean {
        for(const pair of this.languages) {
            if(pair.value !== undefined) { return true; }
        }
        return false;
    }

    public hasAll(languages: Language[]): boolean {
        for(let lang of languages) {
            if(!this.has(lang)) { return false; }
        }
        return true;
    }

    public set(language: Language, value: TValue): void {
        for(const pair of this.languages) {
            if (pair.key === language) {
                pair.value = value;
                return;
            }
        }
        const pair = new MultiLanguageDataEntry<TValue>();
        pair.key = language;
        pair.value = value;
        this.languages.push(pair);
    }

    public unset(language: Language) {
        this.languages
            .filter((p) => p.key === language)
            .forEach((p) => { p.value = undefined; });
    }

    public equal(multilangData: MultiLanguageData<TValue>): boolean {
        if(this.languages.length != multilangData.languages.length) { return false; }
        for(let i = 0; i < this.languages.length; ++i) {
            if(!this.languages[i].equal(multilangData.languages[i])) { return false; }
        }
        return true;
    }

    public forEach(predicate: (key: Language, value: TValue) => void) {
        this.languages
            .filter((l) => l.value)
            .forEach((l) => predicate(l.key, l.value));
    }
}