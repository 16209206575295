import { Pipe, PipeTransform } from '@angular/core';
import { Tracker } from '../models/Tracker';

/**
 * the ImagesFilterPipe is used to Filter all Images by its title
 */
@Pipe({
    name: 'TrackerFilter'
})
export class TrackersFilterPipe implements PipeTransform {
    /**
     * filters the ImagesList by the imageTitle containing the searchText
     * @param trackers the images to be filtered
     * @param searchText the text to be compared
     * @returns returns an imageList with all matching images
     */
    transform(trackers: Tracker[], searchText: string): any[] {
        if (!trackers) { return []; }
        if (!searchText) { return trackers; }
        searchText = searchText.toLowerCase();
        return trackers.filter(tracker => {
            if (tracker.title.de) {
                return tracker.title.de.toLowerCase().includes(searchText);
            }
        });
    }
}
