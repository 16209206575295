import { Config } from "../Config";
import { Card } from "./Card";
import { ContentTypes } from "./ContentTypes";
import { HologramMediaIdValue } from "./Hologram";
import { MultiLanguageData } from "./MultiLanguageData";
import { MultiLanguageText } from "./MultiLanguageText";
import { ModelHelper } from "./ModelHelper";
import { UiElement } from "./UiElements";

export class HologramCard {
    public position: number;
    public active: boolean = true;
    public title: MultiLanguageText = new MultiLanguageText();
    public id: string;
	public model: MultiLanguageData<HologramMediaIdValue> = new MultiLanguageData<HologramMediaIdValue>(HologramMediaIdValue);
    public createdTime: number;
    public updatedTime: number;
    public parentHologramId: string;
    public card: Card = new Card();
    public uiElements: UiElement[] = [];
    public contentType: ContentTypes = new Config().getEnabledContentTypes()[0];
    public url: MultiLanguageText = new MultiLanguageText();

    constructor(hologramCard?: HologramCard) {
        if (hologramCard === undefined) { return; }
        this.position = hologramCard.position;
        this.active = hologramCard.active;
        this.title = new MultiLanguageText(hologramCard.title);
        this.id = hologramCard.id;
        this.model = new MultiLanguageData<HologramMediaIdValue>(HologramMediaIdValue, hologramCard.model);
        this.createdTime = hologramCard.createdTime;
        this.updatedTime = hologramCard.updatedTime;
        this.parentHologramId = hologramCard.parentHologramId;
        this.card = new Card(hologramCard.card);
        this.contentType = hologramCard.contentType;
        this.url = new MultiLanguageText(hologramCard.url);
        this.uiElements = [];
        this.uiElements = ModelHelper.cloneUiElementArray(hologramCard.uiElements).sort((uie1, uie2) => uie1.position - uie2.position);
    }

    public equal(hologramCard: HologramCard): boolean {
        if (
            this.position !== hologramCard.position ||
            this.active !== hologramCard.active ||
            this.id !== hologramCard.id ||
            !this.model.equal(hologramCard.model) ||
            this.createdTime !== hologramCard.createdTime ||
            this.updatedTime !== hologramCard.updatedTime ||
            this.parentHologramId !== hologramCard.parentHologramId ||
            !this.title.equal(hologramCard.title) ||
            this.uiElements.length !== hologramCard.uiElements.length ||
            this.contentType !== hologramCard.contentType ||
            !this.url.equal(hologramCard.url) ||
            !this.card.equal(hologramCard.card)
        ) {
            return false;
        }

        if(!ModelHelper.arrayEqual(this.uiElements, hologramCard.uiElements)) { return false; }

        return true;
    }
  }