export class Location {
    public lon: number;
    public lat: number;
    public ele: number;
    public position: number;

    constructor(location?: Location) {
        if (!location) { return; }
        this.lon = location.lon;
        this.lat = location.lat;
        this.ele = location.ele;
        this.position = location.position;
    }

    public equal(location: Location): boolean {
        if (
            this.lon !== location.lon ||
            this.lat !== location.lat ||
            this.ele !== location.ele ||
            this.position !== location.position
        ) {
            return false;
        }
        return true;
    }

    public containsLonAndLat(): boolean {
        return this.lon !== undefined && this.lat !== undefined && this.lon !== null && this.lat !== null;
    }
}