import { Component, ViewChild, ElementRef, AfterViewInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { FileDataType } from '../models/FileDataType';
import { Config } from '../Config';
import { saveAs } from 'file-saver';
import { CubeSides } from '../models/CubeSides';
import { Cuboid } from '../models/Cuboid';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { ArLayer } from '../models/ArLayer';
import { PoiManagerService } from '../services/poi-manager.service';
import { Tools } from '../Tools';
import { Language } from '../models/Language';

@Component({
  selector: 'aacms-cuboid-viewer',
  templateUrl: './cuboid-viewer.component.html',
  styleUrls: ['./cuboid-viewer.component.scss']
})
export class CuboidViewerComponent implements AfterViewInit {

  @Input() cuboid: Cuboid;
  @Input() imageDataMap: Map<CubeSides, FileDataType>;
  @Input() arLayer: ArLayer;
  @Input() arLayerImageDataMap: Map<CubeSides, FileDataType>;
  @Input() enableArLayer = false;
  @Input() enableFileControl = true;
  @Input() currentLanguage: Language;
  @Output() arLayerImageDataMapChanged: EventEmitter<Map<CubeSides, FileDataType>> = new EventEmitter();
  @Output() imageDataMapChanged: EventEmitter<Map<CubeSides, FileDataType>> = new EventEmitter();

  @Input()
  set sideModel(value: CubeSides) {
    this._sideModel = value;
    this.changeSide();
  }
  @Output() sideModelChanged:  EventEmitter<CubeSides> = new EventEmitter();

  @ViewChild('sceneContainer') sceneContainer: ElementRef;
  public _sideModel: CubeSides = CubeSides.front;
  public side: CubeSides = CubeSides.front;

  public trackerOpacity: string;

  public _arLayerOpacity: number;
  @Input()
  set arLayerOpacity(value: number) {
    this._arLayerOpacity = value / 100;
    document.documentElement.style.setProperty('--arLayerOpacity', this._arLayerOpacity.toString());
  }

  public _enableTrackerLayer: boolean;
  @Input()
  set enableTrackerLayer(enable: boolean) {
    this._enableTrackerLayer = enable;
    if (enable) {
      this.trackerOpacity = '1';
    } else {
      this.trackerOpacity = '0';
    }
    document.documentElement.style.setProperty('--trackerOpacity', this.trackerOpacity);
  }

  public cubeSides = CubeSides;
  public Tools = Tools;

  private maxNumberPixels;
  private cubeWidth;
  private cubeHeight;
  private cubeDepth;
  private topPx;
  private leftPx;

  constructor(
    private pms: PoiManagerService,
    private sanitizer: DomSanitizer
   ) { }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.setMaxNumberPixels();
    this.updateCuboidSize(this.cuboid);
  }

  ngAfterViewInit(): void {
    this.setMaxNumberPixels();
    this.updateCuboidSize(this.cuboid);
    this.changeSide();
  }

  private setMaxNumberPixels(): void {
    this.maxNumberPixels = this.sceneContainer.nativeElement.offsetWidth;
  }

  public updateCuboidSize(cuboid: Cuboid) {
    const array = [(cuboid.width) ? cuboid.width : 10, (cuboid.height)? cuboid.height : 10, (cuboid.depth) ? cuboid.depth : 10];
    const biggest = Math.max.apply(Math, array);
    this.cubeWidth = (cuboid.width) ? cuboid.width / biggest * this.maxNumberPixels : 10 / biggest * this.maxNumberPixels;
    this.cubeHeight = (cuboid.height) ? cuboid.height / biggest * this.maxNumberPixels : 10 / biggest * this.maxNumberPixels;
    this.cubeDepth = (cuboid.depth) ? cuboid.depth / biggest * this.maxNumberPixels : 10 / biggest * this.maxNumberPixels;

    this.topPx = (this.cubeHeight / 2) - (this.cubeDepth / 2);
    this.leftPx = (this.cubeWidth / 2) - (this.cubeDepth / 2);
  }

  public changeSide(): void {
    if (this._sideModel === this.side) {
      return;
    } else if (this._sideModel === undefined) {
      this._sideModel = this.side;
    }
    this.side = this._sideModel;
  }

  public getMaxContentFileSize(): number {
    return new Config().getMaxImageFileSize();
  }

  public handleFilesFromDropin(files: File[]): void {
    if (files.length > 0) {
      const imageData = new FileDataType();
      //imageData.mediaId = uuid.v4();
      this.cuboid.trackerIds.setSide(this.side, imageData.mediaId);
      imageData.file = files[0];
      imageData.fileSafeUrl = this.createImageUrl(files[0]);
      if (this.arLayer) {
        this.arLayerImageDataMap.set(this.side, imageData);
        this.arLayerImageDataMapChanged.emit(this.imageDataMap);
      } else {
        this.imageDataMap.set(this.side, imageData);
        this.imageDataMapChanged.emit(this.imageDataMap);
      }
    }
  }

  private createImageUrl(file: File): SafeUrl {
    const urlCreator = window.URL;
    const url = urlCreator.createObjectURL(file);
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    return safeUrl;
  }

  public downloadFileToSystem(file: FileDataType): void {
    saveAs(file.file, file.media.name);
  }

  public removeImage(): void {
    if (this.arLayer && this.arLayerImageDataMap) {
      this.arLayerImageDataMap.set(this.side, null);
      this.arLayerImageDataMapChanged.emit(this.imageDataMap);
    } else {
      this.imageDataMap.set(this.side, null);
      this.imageDataMapChanged.emit(this.imageDataMap);
    }
  }

  public useArLayerImage(side: CubeSides): boolean {
    return this.arLayer.mediaIds.getSide(side).useImage;
  }

  public useArLayerImageChanged(): void {
    this.arLayer.mediaIds.toggleUseImage(this.side);
    this.arLayerImageDataMapChanged.emit(this.arLayerImageDataMap);
    this.pms.reloadPoiLayer();
  }

  public getSceneStyle(): Object {
    return {
      'width': this.maxNumberPixels + 'px',
      'height': this.maxNumberPixels + 'px',
      'perspective': 2 * this.maxNumberPixels + 'px'
    };
  }

  public getCubeStyle(): Object {
    let transform = '';
    switch (this.side) {
      case CubeSides.front: {
        transform = 'translateZ(' + (-0.52 * this.cubeDepth) + 'px) rotateY(0deg)';
        break;
      }
      case CubeSides.back: {
        transform = 'translateZ(' + (-0.52 * this.cubeDepth) + 'px) rotateY(180deg)';
        break;
      }
      case CubeSides.right: {
        transform = 'translateZ(' + (-0.52 * this.cubeWidth) + 'px) rotateY(-90deg)';
        break;
      }
      case CubeSides.left: {
        transform = 'translateZ(' + (-0.52 * this.cubeWidth) + 'px) rotateY(90deg)';
        break;
      }
      case CubeSides.top: {
        transform = 'translateZ(' + (-0.52 * this.cubeHeight) + 'px) rotateX(-90deg)';
        break;
      }
      case CubeSides.bottom: {
        transform = 'translateZ(' + (-0.52 * this.cubeHeight) + 'px) rotateX(90deg)';
        break;
      }
      default: {
        transform = 'translateZ(' + (-0.5 * this.cubeDepth) + 'px)';
      }
    }

    return {
      'width': this.cubeWidth + 'px',
      'height': this.cubeHeight + 'px',
      'transform': transform
    };
  }

  public getCubeStyleFaceFront(): Object {
    return {
      'width': this.cubeWidth + 'px',
      'height': this.cubeHeight + 'px',
      'transform': 'rotateY(0deg) translateZ(' + (0.5 * this.cubeDepth) + 'px)'
    };
  }

  public getCubeStyleFaceBack(): Object {
    return {
      'width': this.cubeWidth + 'px',
      'height': this.cubeHeight + 'px',
      'transform': 'rotateY(180deg) translateZ(' + (0.5 * this.cubeDepth) + 'px)'
    };
  }

  public getCubeStyleFaceRight(): Object {
    return {
      'width': this.cubeDepth + 'px',
      'height': this.cubeHeight + 'px',
      'left': this.leftPx + 'px',
      'transform': 'rotateY(90deg) translateZ(' + (0.5 * this.cubeWidth) + 'px)'
    };
  }

  public getCubeStyleFaceLeft(): Object {
    return {
      'width': this.cubeDepth + 'px',
      'height': this.cubeHeight + 'px',
      'left': this.leftPx + 'px',
      'transform': 'rotateY(-90deg) translateZ(' + (0.5 * this.cubeWidth) + 'px)'
    };
  }

  public getCubeStyleFaceTop(): Object {
    return {
      'width': this.cubeWidth + 'px',
      'height': this.cubeDepth + 'px',
      'top': this.topPx + 'px',
      'transform': 'rotateX(90deg) translateZ(' + (0.5 * this.cubeHeight) + 'px)'
    };
  }

  public getCubeStyleFaceBottom(): Object {
    return {
      'width': this.cubeWidth + 'px',
      'height': this.cubeDepth + 'px',
      'top': this.topPx + 'px',
      'transform': 'rotateX(-90deg) translateZ(' + (0.5 * this.cubeHeight) + 'px)'
    };
  }

  public getBackgroundColorForRating(): Object {
    if (!this.arLayer && this.imageDataMap.get(this.side).media && this.imageDataMap.get(this.side).media.rating !== undefined) {
      return {
        'background-color': Tools.getBackgroundColorForRating(this.imageDataMap.get(this.side).media.rating)
      };
    } else {
      return {
        'background-color': '#E0FDEB'
      };
    }
  }
}
