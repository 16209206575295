import { Output, EventEmitter, Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { Config } from '../../Config';
import { GeoJsonFeature, GeoJsonPoint } from '../../models/GeoJson';
import { UserConfigService } from '../../services/userConfig.service';

@Component({
	selector: 'leaflet-map',
	templateUrl: './leaflet-map.component.html',
	styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent implements AfterViewInit {

	private _features: GeoJsonFeature[] = [];
	@Input()
	public set features(features: GeoJsonFeature[]) {
		this._features = features;
		this.updateMarkers();
		//this.featuresChange.emit(features);
	}

	@Input()
	public onNewFeature: (feature: GeoJsonFeature) => void = null;

	public get features(): GeoJsonFeature[] { return this._features; }
	@Output() featuresChange: EventEmitter<GeoJsonFeature[]> = new EventEmitter<GeoJsonFeature[]>();

	/* VIEW */
	// ##########################################
	@ViewChild('map') private mapContainer;
	private mapContainerResizeObserver: ResizeObserver = new ResizeObserver(() => this.handleMapResize());
	private map: L.Map = null;
	private defaultBounds = L.latLngBounds(L.latLng(47.26543, 5.864417), L.latLng(55.14777, 15.05078));
	private markers: Map<GeoJsonFeature, (L.Marker | L.Circle)[]> = new Map<GeoJsonFeature, (L.Marker | L.Circle)[]>();
	private config: Config = new Config();

	public constructor(
		private userConfigService: UserConfigService
	) {}


	public ngOnDestroy(): void {
		//TODO
		this.mapContainerResizeObserver.unobserve(this.mapContainer.nativeElement);
		this.mapContainerResizeObserver.disconnect();
	}

	public ngAfterViewInit(): void {
		if(this.map) { return; }
		this.map = L.map(this.mapContainer.nativeElement, { center: [1, 1], zoom: 1, doubleClickZoom: false });
		this.mapContainerResizeObserver.observe(this.mapContainer.nativeElement);
		this.map.on('dblclick', (event: any) => {
			if (this.map.getZoom() >= 1) {
				const newPoint = new GeoJsonPoint();
				newPoint.setCoordinates(event.latlng.lat, event.latlng.lng);
				const newFeature = new GeoJsonFeature();
				newFeature.geometry = newPoint;
				this.onNewFeature(newFeature);
				this._features.push(newFeature);
				this.selectFeature(newFeature); // handles signaling
			}
		});
		this.map.fitBounds(this.defaultBounds);
		const tiles = L.tileLayer(new Config().getMapUrl(), {
			tileSize: 256,
			minZoom: 1,
			attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
			crossOrigin: true
		});
		tiles.addTo(this.map);
		L.control.scale().addTo(this.map);
		this.updateMarkers();
	}

	private updateMarkers() {
		if(!this.map) { return; }
		this.map.eachLayer((layer) => {
			if(layer !== undefined &&
				(layer instanceof L.Marker || layer instanceof L.Circle)) { layer.remove(); }
		});
		this.markers.clear();

		for(let index = 0; index < this._features.length; ++index) {
			let feature = this._features[index];
			let latlng = L.latLng(feature.geometry.coordinates[0], feature.geometry.coordinates[1]);

			let myCustomColour = '#D3D3D3';
			if (feature.properties.active) {
				myCustomColour = this.userConfigService.getSync().highlightColor;
			}
			const markerHtmlStyles = `
				background: ${myCustomColour};
				width: 46px;
				height: 46px;
				display: block;
				left: -22px;
				top: -31px;
				position: relative;
				border-radius: 50% 50% 50% 0;
				transform: rotate(-45deg);
				display: flex;
				justify-content: center;
				align-items: center;
			`;
			const icon = L.divIcon({
				className: "leaflet-marker",
				iconAnchor: [0, 23],
				//labelAnchor: [-6, 0],
				popupAnchor: [0, -36],
				html: `<span class="poi-marker" style="${markerHtmlStyles}"><div class="leaflet-marker-text-ui"><div>${index + 1}</div></div></span>`
			});
			const marker = L.marker(latlng, { draggable: true, icon: icon });
			const triggerRadiusMarker: L.Circle = L.circle(latlng, {radius: feature.properties.radius});
			marker.on('dragend', (event: any) => {
				event.target._icon.style.cursor = undefined;
				let latlng = event.target.getLatLng();
				(feature.geometry as GeoJsonPoint).setCoordinates(latlng.lat, latlng.lng);
				this.selectFeature(feature); // handles signaling
			});
			marker.on('mousemove', (event: MouseEvent) => {
				triggerRadiusMarker.setLatLng(marker.getLatLng());
			});
			marker.on('dragstart', (event) => {
				event.target._icon.style.cursor = 'move';
			});
			marker.on('click', (event) => {
				this.selectFeature(feature);
			});

			triggerRadiusMarker.addTo(this.map);
			marker.addTo(this.map);
			this.markers.set(feature, [marker, triggerRadiusMarker]);
		}
	}

	/* ############################################################ */
	/* PUBLIC API */
	/* ############################################################ */
	public selectFeature(feature: GeoJsonFeature) {
		for(let feature of this._features) { feature.properties.active = false; }
		feature.properties.active = true;
		this.featuresChange.emit(this._features);
		this.updateMarkers();
	}
	public panToFeature(feature: GeoJsonFeature) {
		let markers = this.markers.get(feature);
		if(!markers || markers.length == 0) { return; }
		this.map.panTo(markers[0].getLatLng());
	}


	/* ############################################################ */
	/* EVENT HANDLERS */
	/* ############################################################ */
	private handleMapResize() {
		if(!this.map) { return; }
		this.map.invalidateSize();
		if(this.map.getBounds().contains(this.defaultBounds)) {
			this.map.fitBounds(this.defaultBounds);
		}
	}
}
