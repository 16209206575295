import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '../current.app.state';
import { FileDataType } from '../models/FileDataType';
import { Place } from '../models/Place';
import { Language } from '../models/Language';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { ElementType, UiElement } from '../models/UiElements';
import { CurrentContextService } from '../services/currentContext.service';
import { PlaceService } from '../services/place.service';
import { MediaService } from '../services/media.service';
import { Tools } from '../Tools';
import { Config } from '../Config';
import { StringService } from '../services/string.service';
import { ContentTypes } from '../models/ContentTypes';
import { saveAs } from 'file-saver';
import { TabType } from '../models/TabType';
import * as L from 'leaflet';
import { CubeSides } from '../models/CubeSides';

@Component({
  selector: 'aacms-edit-place-meta',
  templateUrl: './edit-place-meta.component.html',
  styleUrls: ['./edit-place-meta.component.scss']
})
export class EditPlaceMetaComponent implements OnInit, OnDestroy {

  public map: any;
  public marker: any;

  public modalRef: BsModalRef;
  public place: Place;
  private originalPlace: Place;
  public elementType = ElementType;
  public contentChanged = false;

  public contentTypes = ContentTypes;

  @ViewChild('smartphone') smartphone;
  @ViewChild('popupEditor') popupEditor;
  @ViewChild('cancelModalTemplate', { static: false }) cancelModalTemplate: TemplateRef<any>;

  public placeDataMap: Map<CubeSides, FileDataType> = new Map([[CubeSides.image, null]]);

  public Language = Language;
  public appState = AppState;
  public Tools = Tools;

  public currentLanguage: Language;
  public useCustomPopup: boolean = false;
  private customPopupBackup: UiElement[] = null;

  private saveContentSubject: Subscription;

  public config: Config = new Config();

  public currentTab: TabType;
  public tabType = TabType;

  public lonInput: string;
  public latInput: string;
  public radiusInput: string;

  public lonLatInputConfig = {
    decimalCharacter: '.',
    decimalPlaces: '15',
    digitGroupSeparator: ',',
    modifyValueOnWheel: false,
    onInvalidPaste: 'ignore',
    outputFormat: 'number',
    showWarnings: false
  }

  public radiusInputConfig = {
    maximumValue: '1000',
    minimumValue: '0',
    decimalPlaces: '0',
    decimalCharacter: ',',
    digitGroupSeparator: '',
    modifyValueOnWheel: false,
    onInvalidPaste: 'ignore',
    outputFormat: 'number',
    showWarnings: false
  }

  public constructor(
    public contextService: CurrentContextService,
    private modalService: BsModalService,
    private placeService: PlaceService,
    private router: Router,
    private route: ActivatedRoute,
    private stringService: StringService,
    private mediaService: MediaService
  ) { }

  public ngOnInit(): void {
    this.contextService.setCurrentState(AppState.editPlaceMeta);
    this.currentLanguage = Language.DE;
    this.saveContentSubject = this.contextService.saveYourContent.subscribe(
      saveContent => {
        if (saveContent) {
          this.save();
        }
      }
    );
    this.getPlace();
  }

  public ngOnDestroy(): void {
    this.saveContentSubject.unsubscribe();
    this.returnToPlace();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (this.contentChanged) ? false : true;
  }

  public discard(): void {
    this.place = new Place(this.originalPlace);
    this.lonInput = this.place.location.lon + '';
    this.latInput = this.place.location.lat + '';
    this.radiusInput = this.place.radius + '';
    this.smartphone.onDiscard();
    this.popupEditor.onDiscard();
		this.useCustomPopup = this.place.popupElements.length !== 0;
    this.checkForContentChange();
    this.redrawCircle();
    this.hideModal();
  }

  public hideModal(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  public getPlace(): void {
    const id = this.route.snapshot.paramMap.get('placeId');
    const sub = this.placeService.getPlaceById(id).subscribe(
        place => {
          this.place = new Place(place);
          this.useCustomPopup = this.place.popupElements.length !== 0;
          this.customPopupBackup = this.place.popupElements;
          this.contextService.getExhibition(this.place.exhibitionIds[0]);
          this.originalPlace = new Place(place);
          this.latInput = place.location.lat + '';
          this.lonInput = place.location.lon + '';
          this.radiusInput = place.radius + '';
          setTimeout(() => {
            this.initMap();
          });
          sub.unsubscribe();
        },
        error => {
          this.router.navigate(['places']);
        });
  }

  private initMap(): void {
    this.map = L.map('map', {center:[1, 1], zoom: 1, doubleClickZoom: false });
    setTimeout(()=> {
      this.redrawCircle();
      let bounds;
      if (this.marker !== undefined && this.marker !== undefined) {
        bounds = this.marker.getBounds();
      } else {
        bounds = L.latLngBounds(L.latLng(47.26543, 5.864417), L.latLng(55.14777, 15.05078));
      }
      this.map.fitBounds(bounds);
    });
    this.map.on('click', (e) => {
      if (this.marker === undefined) {
        this.lonInput = e.latlng.lng + '';
        this.latInput = e.latlng.lat + '';
        if (+this.radiusInput <= 0 || +this.radiusInput > 1000 || isNaN(+this.radiusInput)) {
          this.radiusInput = '100';
          this.checkForContentChange();
        }
      }
    });
    const tiles = L.tileLayer(new Config().getMapUrl(), {
      tileSize: 256,
      minZoom: 1,
      attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
      crossOrigin: true
    });
    tiles.addTo(this.map);
    L.control.scale().addTo(this.map);
  }

  private trackCursor(event, marker, location) {
    marker.setLatLng(event.latlng);
  }

  public redrawCircle(): any {
    if (this.map !== undefined) {
      if (this.place.location.lat === undefined || this.place.location.lon === undefined) {
        if (this.marker) {
          this.map.removeLayer(this.marker);
          this.marker = undefined;
        }
      } else {
        if (this.marker === undefined && this.place.location.lat !== undefined && this.place.location.lon !== undefined
          && !isNaN(this.place.location.lat) && !isNaN(this.place.location.lon) && this.place.location.lat !== null && this.place.location.lon !== null) {
          if (this.place.radius !== undefined && this.place.radius > 0) {
            this.marker = L.circle(L.latLng(this.place.location.lat, this.place.location.lon), {radius: this.place.radius}).addTo(this.map);
          } else {
            this.marker = L.circle(L.latLng(this.place.location.lat, this.place.location.lon), {radius: 0.1}).addTo(this.map);
          }
          const marker1 = this.marker;
          this.marker.on('mousedown', () => {
            this.map.dragging.disable()
            this.map.on('mousemove', (event) => {this.trackCursor(event, marker1, location)});
          });
          this.marker.on('mouseup', (event) => {
            this.latInput = event.latlng.lat;
            this.lonInput = event.latlng.lng;
            this.redrawCircle();
            this.checkForContentChange();
            this.map.dragging.enable()
            this.map.off('mousemove');
          });
        }
        if (this.marker !== undefined) {
          this.marker.setLatLng(L.latLng(this.place.location.lat, this.place.location.lon));
          if (this.place.radius !== undefined && this.place.radius > 0) {
            this.marker.setRadius(this.place.radius);
          }
        }
      }
    }
  }

  public getLanguageValue(text: MultiLanguageText): string {
    return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
  }

  public checkForContentChange(): void {
    if (this.radiusInput.length > 0 && this.radiusInput !== undefined && this.radiusInput !== null && !isNaN(+this.radiusInput)) {
      this.place.radius = +this.radiusInput;
    } else {
      this.place.radius = undefined;
    }
    if (this.lonInput.length > 0 && this.lonInput !== undefined && this.lonInput !== null && !isNaN(+this.lonInput)) {
      this.place.location.lon = +this.lonInput;
    } else {
      this.place.location.lon = undefined;
    }
    if (this.latInput.length > 0 && this.latInput !== undefined && this.latInput !== null && !isNaN(+this.latInput)) {
      this.place.location.lat = +this.latInput;
    } else {
      this.place.location.lat = undefined;
    }
    this.contentChanged = !this.place.equal(this.originalPlace);
  }

  public save(): void {
    this.hideModal();
    this.contextService.setSaveYourContent(false);
    let saveContent = (this.contentChanged) ? true : false;
    if (saveContent) {
      this.contextService.setSavingContent(true);
    }
    if (this.contentChanged) {
      const sub = this.placeService.updatePlace(this.place).subscribe(
        response => {
          this.originalPlace = new Place(this.place);
          saveContent = false;
          if (!saveContent) {
            this.checkForContentChange();
            this.contextService.setSavingContent(false);
          }
          sub.unsubscribe();
      });
    }
  }

  public openModal(modalTemplate: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(modalTemplate);
  }

  public returnToPlace(): void {
    this.router.navigate(['places', this.route.snapshot.paramMap.get('placeId')]);
  }

  public setLanguage(language: Language): void {
    this.currentLanguage = language;
  }

  public isLanguage(language: Language): boolean {
    return language === this.currentLanguage;
  }

  public updateLanguage(language: Language): void {
    this.currentLanguage = language;
  }

  public toggleUseCustomPopup(): void {
    this.useCustomPopup = !this.useCustomPopup;
    if(!this.useCustomPopup) { // got disabled, backup elements into gui state then, clear model
      this.customPopupBackup = this.place.popupElements;
      this.place.popupElements = [];
    } else { // restore from backup, if any
      if(this.customPopupBackup != null) {
        this.place.popupElements = this.customPopupBackup;
      }
    }
    this.checkForContentChange();
  }

  public togglePlaceActivation(): void {
    this.place.active = !this.place.active;
    this.checkForContentChange();
  }

  public toggleArPlaceCardsActivation(): void {
    this.place.cardsActive = !this.place.cardsActive;
    this.checkForContentChange();
  }

  public getStringNameForContentType(type: ContentTypes): string {
    switch (type) {
      case ContentTypes.Custom: {
        return this.stringService.get('CUSTOM');
      }
      case ContentTypes.URL: {
        return this.stringService.get('URL');
      }
    }
  }


  public showContentTypesSelection(): boolean {
    return this.config.getEnabledContentTypes().length > 1;
  }

  public downloadFileToSystem(file: FileDataType): void {
    saveAs(file.file, file.media.name);
  }

  public routeTo(appState: AppState): void {
    switch (appState) {
      case AppState.exhibitions:
        this.router.navigate(['exhibitions']);
        break;
      case AppState.images:
        this.router.navigate(['exhibitions', this.place.exhibitionIds[0], 'images']);
        break;
      case AppState.editPlace:
        this.router.navigate(['places', this.place.id]);
        break;
      default:
        break;
    }
  }

  public isTabAllowed(type: ContentTypes): boolean {
    for (const contentType of this.config.getEnabledContentTypes()) {
      if (contentType === type) {
        return true;
      }
    }
    return false;
  }
}
