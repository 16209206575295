import { Accessibility } from './Accessibility';
import { Card } from './Card';
import { Location } from './Location';
import { ContentPage } from './MenuElements';
import { MultiLanguageFileDataType } from './MultiLanguageFileDataType';
import { MultiLanguageMediaId } from './MultiLanguageMediaId';
import { MultiLanguageText } from './MultiLanguageText';

export class Waypoint {
    public location: Location;
    public trackerId: string;
    public id: string;
    public active = true;
    public title: MultiLanguageText = new MultiLanguageText();
    public position: number;
    public marker: any;

    constructor(waypoint?: Waypoint) {
        if (!waypoint) { return; }
        this.location = new Location(waypoint.location);
        this.trackerId = waypoint.trackerId;
        this.id = waypoint.id;
        this.active = waypoint.active;
        this.title = new MultiLanguageText(waypoint.title);
        this.position = waypoint.position;
        this.marker = waypoint.marker;
    }

    public equal(waypoint: Waypoint): boolean {
        if (
            this.trackerId !== waypoint.trackerId ||
            !this.location.equal(waypoint.location) ||
            this.id !== waypoint.id ||
            this.active !== waypoint.active ||
            !this.title.equal(waypoint.title) ||
            this.position !== waypoint.position ||
            this.marker !== waypoint.marker
        ) {
            return false;
        }
        return true;
    }
}

export class Tour {
    public id: string;
    public isMandatory: boolean = false;
    public mediaId: string;
    public position: number;
    public title: MultiLanguageText = new MultiLanguageText();
    public href: ContentPage = new ContentPage();
    public card: Card = new Card();
    public active: boolean = true;
    public createdTime: number;
    public updatedTime: number;
    public exportTime: number;
    public walkTourInOrder: boolean = false;
    public accessibility: Accessibility = Accessibility.NOT_BARRIER_FREE;
    public menuThumb: MultiLanguageMediaId = new MultiLanguageMediaId();

    constructor(tour?: Tour) {
        if (!tour) { return; }
        this.id = tour.id;
        this.isMandatory = tour.isMandatory;
        this.mediaId = tour.mediaId;
        this.position = tour.position;
        this.title = new MultiLanguageText(tour.title);
        this.href = new ContentPage(tour.href);
        this.card = new Card(tour.card);
        this.active = tour.active;
        this.createdTime = tour.createdTime;
        this.updatedTime = tour.updatedTime;
        this.exportTime = tour.exportTime;
        this.walkTourInOrder = tour.walkTourInOrder;
        if (tour.accessibility !== undefined) {
            this.accessibility = tour.accessibility;
        }
        this.menuThumb = new MultiLanguageMediaId(tour.menuThumb);
    }

    public equal(tour: Tour): boolean {
        if (
            this.id !== tour.id ||
            this.isMandatory !== tour.isMandatory ||
            this.mediaId !== tour.mediaId ||
            this.position !== tour.position ||
            !this.title.equal(tour.title) ||
            !this.href.equal(tour.href) ||
            !this.card.equal(tour.card) ||
            this.createdTime !== tour.createdTime ||
            this.updatedTime !== tour.updatedTime ||
            this.exportTime !== tour.exportTime ||
            this.active !== tour.active ||
            this.walkTourInOrder !== tour.walkTourInOrder ||
            this.accessibility !== tour.accessibility ||
            !this.menuThumb.equal(tour.menuThumb)
        ) {
            return false;
        }
        return true;
    }
}