import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Config } from '../Config';
import { Media } from '../models/Media';
import { FileDataType } from '../models/FileDataType';
import { ElementType, UiElement, TextElement } from '../models/UiElements';
import { Language } from '../models/Language';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { MediaService } from '../services/media.service';
import { Tools } from '../Tools';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { CubeSides } from '../models/CubeSides';
import { CurrentContextService } from '../services/currentContext.service';
import { Place } from '../models/Place';
import { UserConfigService } from '../services/userConfig.service';

class MediaData {
	constructor(
		public media: Media,
		public file: File,
		public fileSafeUrl: SafeUrl
	) {}
}

@Component({
  selector: 'aacms-location-notify-editor',
  templateUrl: './location-notify-editor.component.html',
  styleUrls: ['./location-notify-editor.component.scss']
})
export class LocationNotifyEditorComponent {

  public _place: Place;
  @Input() set place(place: Place) {
    this._place = place;
    this.loadNotifyImages();
    this.loadCardImages();
  }
  @Input() currentLanguage: Language = Language.DE;

  @Input() trackerFileData: Map<CubeSides, FileDataType>;

  @Output() placeChange: EventEmitter<void> = new EventEmitter<void>();

	public mediaData: Map<string, MediaData> = new Map<string, MediaData>();

  public currentSelectedResolution = 'big';
  private smartphoneMultiplier = 2.7;
  private smartphoneHeight = 16;
  private smartphoneWidth = 8;
  private portraitMode = true;

  public config = new Config();
  public elementType = ElementType;
  public Language = Language;
  public cubeSides = CubeSides;

  constructor(
    private mediaService: MediaService,
    private sanitizer: DomSanitizer,
    private contextService: CurrentContextService,
    private userConfigService: UserConfigService
  ) { }

  private loadNotifyImages(): void {
    for(const value of this._place.notifyImage.getAllValues()) {
      if(value !== undefined) {
        this.loadNotifyImage(value);
      }
    }
  }

  private loadNotifyImage(fileData: FileDataType): void {
    fileData.isLoaded = false;
    if (this._place.notifyImage) {
      if (fileData.mediaId) {
        const sub = this.mediaService.getMediaById(fileData.mediaId).subscribe(
          media => {
            fileData.media = new Media(media);
            let url;
            if (media.thumbUrl !== undefined) {
              url = media.thumbUrl;
            } else if (media.rawUrl !== undefined) {
              url = media.rawUrl;
            }
            if (url !== undefined) {
              const sub1 = this.mediaService.downloadFile(url).subscribe(
                blob => {
                  const file = Tools.blobToFile(blob, media.name);
                  fileData.file = file;
                  fileData.fileSafeUrl = this.createImageUrl(fileData.file);
                  fileData.isLoaded = true;
                  sub1.unsubscribe();
                }
              );
            }
            sub.unsubscribe();
          }
        );
      }
    }
  }

  private loadCardImages(): void {
		this.mediaData.clear();
		this._place.card.cardImageFileData.forEach((lang, value) => {
			this.loadCardImage(value.mediaId);
		});
	}

	private loadCardImage(mediaId: string): void {
		if (mediaId) {
			const sub = this.mediaService.getMediaById(mediaId).subscribe(
				media => {
					media = new Media(media);
					let url;
					if (media.thumbUrl !== undefined) {
						url = media.thumbUrl;
					} else if (media.rawUrl !== undefined) {
						url = media.rawUrl;
					}
					if (url !== undefined) {
						const sub1 = this.mediaService.downloadFile(url).subscribe(
							blob => {
								const file = Tools.blobToFile(blob, media.name);
								this.mediaData.set(mediaId, new MediaData(
									media, file, this.createImageUrl(file)
								));
								sub1.unsubscribe();
							}
						);
					}
					sub.unsubscribe();
				}
			);
		}
	}

  public getNotifyImageSrc(): SafeUrl {
    if (this._place.notifyImage !== undefined && this._place.notifyImage.get(this.currentLanguage) !== undefined && this._place.notifyImage.get(this.currentLanguage).fileSafeUrl !== undefined) {
      return this._place.notifyImage.get(this.currentLanguage).fileSafeUrl;
    } else {
      return undefined;
    }
  }

  private createImageUrl(file: File): SafeUrl {
    const urlCreator = window.URL;
    const url = this.sanitizer.bypassSecurityTrustUrl(
      urlCreator.createObjectURL(file)
    );
    return url;
  }

  public handleFilesFromDropin(files: File[]): void {
    if (files.length > 0) {
      this.contextService.setSavingContent(true);
      this.mediaService.uploadFile(files[0]).subscribe(
        response => {
          if (response.status < 400) {
            const location: string = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
            const mediaId: string = location.substring(location.lastIndexOf('/') + 1);
            const fileData = new FileDataType();
            fileData.isLoaded = false;
            fileData.file = files[0];
            fileData.fileSafeUrl = this.createImageUrl(files[0]);
            fileData.isLoaded = true;
            fileData.mediaId = mediaId;
            this._place.notifyImage.set(this.currentLanguage, fileData);
            this.placeChange.emit();
          }
          this.contextService.setSavingContent(false);
        }
      );
    }
  }

  public downloadFileToSystem(file: FileDataType) {
    saveAs(file.file, file.media.name);
  }

  public removeImageFile(): void {
    this._place.notifyImage.set(this.currentLanguage, undefined);
    this.placeChange.emit();
  }

  public isLanguage(language: Language): boolean {
    return language === this.currentLanguage;
  }

  public acceptChangesToText(change: string): void {
    this.placeChange.emit();
  }

  public getSmartphoneStyle() {
    const width = (this.smartphoneWidth * this.smartphoneMultiplier) + 'rem';
    const height = (this.smartphoneHeight * this.smartphoneMultiplier) + 'rem';
    const portraitBorder = '20px 10px 40px 10px';
    const landscapeBorder = '10px 40px 10px 20px';

    const smartphoneStyle = {
      'height': this.portraitMode ? height : width,
      'width': this.portraitMode ? width : height,
      'border-width': this.portraitMode ? portraitBorder : landscapeBorder
    };

    return smartphoneStyle;
  }

  public changeSmartphoneStyle(height, width): void {
    this.smartphoneHeight = height;
    this.smartphoneWidth = width;
  }

  public rotateSmartphone(): void {
    this.portraitMode = !this.portraitMode;
  }

  public getLanguageValue(text: MultiLanguageText): string {
    const value = new MultiLanguageText(text).getInLanguage(this.currentLanguage);
    return value.length > 0 ? value : ' ';
  }

  public getImageUrl(fileData: FileDataType): SafeUrl {
    if (fileData) {
      return fileData.fileSafeUrl;
    } else {
      return null;
    }
  }

  public getBorderStyle(): Object {
    return {'border-left': '5px solid ' + this.userConfigService.getSync().highlightColor};
  }

  public getTextFromFirstUiElement(): string {
    if (this.place !== undefined) {
      for (const uiElement of this.place.uiElements) {
        if (uiElement instanceof TextElement) {
          const value = (<TextElement>uiElement).text.getInLanguage(this.currentLanguage);
          return value.replace(/<.*?>/g, '');
        }
      }
    }
    return '';
  }

  public getAutoCardImageSytle(): Object {
    let position = '50% 50%';
    let fit = 'cover';
    return {
      'object-fit': fit,
      'object-position': position,
      'width': '80px',
      'height': '80px'
    };
  }

  public getCardImageSrc(): SafeUrl {
    if (!this._place.card.autogenerateCard) {
			if (this._place.card.cardImageFileData) {
				let mediaId = this._place.card.cardImageFileData.getWithFallback(this.currentLanguage)?.mediaId;
				if(!this.mediaData.has(mediaId)) { return null; }
				return this.mediaData.get(mediaId)?.fileSafeUrl;
			}
    } else {
      if (this.trackerFileData && this.trackerFileData.get(this.cubeSides.image) && this.trackerFileData.get(this.cubeSides.image).fileSafeUrl) {
        return this.trackerFileData.get(this.cubeSides.image).fileSafeUrl;
      } else if (this.trackerFileData && this.trackerFileData.get(this.cubeSides.front) && this.trackerFileData.get(this.cubeSides.front).fileSafeUrl) {
        return this.trackerFileData.get(this.cubeSides.front).fileSafeUrl;
      }
    }
    return null;
  }
}
