
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { StringService } from '../services/string.service';
import { Config } from '../Config';
import { FileDataType } from '../models/FileDataType';
import { ElementType } from '../models/UiElements';
import { saveAs } from 'file-saver';
import { MapElement } from '../models/GeoJson';

@Component({
  selector: 'manage-media',
  templateUrl: './manage-media.component.html',
  styleUrls: ['./manage-media.component.scss']
})
export class ManageMediaComponent {

  @Input() elementType: ElementType;
  @Input() mediaData: FileDataType;
  @Input() mapData: MapElement;
  @Input() filesize: number;
  @Output() upateFile: EventEmitter<File> = new EventEmitter<File>();
  @Output() removeMediaFile: EventEmitter<any> = new EventEmitter<any>();

  public elementTypeEnum = ElementType;

  public config: Config = new Config();

  public constructor(
    public alertService: AlertService,
    public stringService: StringService
  ) { }

  public handleFilesFromDropin(files: File[]): void {
    if (files.length > 0) {
      this.upateFile.emit(files[0]);
    }
  }

  public downloadFileToSystem(file: FileDataType): void {
    saveAs(file.file, file.media.name);
  }

  public downloadMapDataToSystem(mapData: MapElement): void {
    const json = JSON.stringify(mapData.mapObject);
    const uri = "data:text/json;charset=UTF-8," + encodeURIComponent(json);
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", uri);
    downloadAnchorNode.setAttribute("download", mapData.filename);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  public removeModel(): void {
    this.removeMediaFile.emit();
  }

  public getMaxContentFileSize(): number {
    return this.filesize ? this.filesize : new Config().getMaxPoiContentFileSize();
  }

  public getAcceptedFileTypes(): string {
    switch (this.elementType) {
      case ElementType.IMAGE: {
        return '.jpg,.jpeg,.png';
      }
      case ElementType.AUDIO: {
        return 'audio/mp3';
      }
      case ElementType.VIDEO: {
        return 'video/mp4';
      }
      case ElementType.MODEL: {
        return '.glb';
      }
      case ElementType.MAP: {
        return '.geojson,.gpx';
      }
    }
  }
}
