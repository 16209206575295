import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap, share } from 'rxjs/operators';

import { Poi } from '../models/Poi';
import { Config } from '../Config';

import { AlertService } from './alert.service';
import { StringService } from './string.service';
import { AlertWithBoldValueInMiddle, AlertType } from '../models/Alert';
import { CurrentContextService } from './currentContext.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root',
})
export class PoiService {

  private baseUrl = new Config().GetBaseUrl();

  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService,
    private contextService: CurrentContextService
  ) { }

  public getPoisByImageId(parentImageId: string): Observable<Poi[]> {
    return this.http.get<Poi[]>(this.getPoiUrl(`?parentImageId=${parentImageId}`)).pipe(
      tap(
        response => {
          return response;
        },
        error => { this.handleError<Poi[]>('getPoiByImageId', error); }
      ),
      share()
    );
  }

  public getPoiById(id: string): Observable<Poi> {
    return this.http.get<Poi>(this.getPoiUrl(`${id}`)).pipe(
      tap(
        response => {
          return response;
        },
        error => {
          this.alertService.alert(
            new AlertWithBoldValueInMiddle(
              this.stringService.get('POI_NOT_FOUND1'),
              id,
              this.stringService.get('POI_NOT_FOUND2'),
              AlertType.Error),
            true);
          this.handleError<Poi>('getPoiById', error);
        }
      ),
      share()
    );
  }

  public createPoi(poi: Poi): Observable<any> {
    poi.createdTime = new Date().getTime();
    poi.updatedTime = poi.createdTime;
    return this.http.post<any>(this.getPoiUrl(), poi, httpOptions).pipe(
      tap(
        response => {
          const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
          const id = location.substring(location.lastIndexOf('/') + 1);
          // poi.id = id;
          this.alertService.alert(
            new AlertWithBoldValueInMiddle(
              this.stringService.get('POI_CREATED1'),
              id,
              this.stringService.get('POI_CREATED2'),
              AlertType.Success),
            true);
        },
        error => { this.handleError<Poi>('createPoi', error); }
      ),
      share()
    );
  }

  public updatePoi(poi: Poi): Observable<any> {
    poi.updatedTime = new Date().getTime();
    const poiCopy = new Poi(poi);
    for (const uiElement of poiCopy.uiElements) {
      uiElement.cleanForUpLoad();
    }
    return this.http.put(this.getPoiUrl(`${poi.id}`), poiCopy, httpOptions).pipe(
      tap(
        response => {
          if (this.contextService.getInCurrentAppLanguage(poi.title) !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('POI_UPDATED1'),
                this.contextService.getInCurrentAppLanguage(poi.title),
                this.stringService.get('POI_UPDATED3'),
                AlertType.Success),
              true);
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('POI_UPDATED1'),
                poi.id,
                this.stringService.get('POI_UPDATED3'),
                AlertType.Success),
              true);
          }
        },
        error => { this.handleError<Poi>('updatePoi', error); }
      ),
      share()
    );
  }

  public deletePoi(poi: Poi | string): Observable<any> {
    const id = typeof poi === 'string' ? poi : poi.id;

    return this.http.delete(this.getPoiUrl(`${id}`), httpOptions).pipe(
      tap(
        response => {
          if (typeof poi === 'object' && this.contextService.getInCurrentAppLanguage(poi.title) !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('POI_DELETED1'),
                this.contextService.getInCurrentAppLanguage(poi.title),
                this.stringService.get('POI_DELETED3'),
                AlertType.Info),
              true);
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('POI_DELETED1'),
                id,
                this.stringService.get('POI_DELETED3'),
                AlertType.Info),
              true);
          }
        },
        error => { this.handleError<Poi>('deletePoi', error); }
      ),
      share()
    );
  }

  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private logError(value: any): void {
    console.error(value);
  }

  private getPoiUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}pois/`;
    if (extension) {
      if (!(extension.indexOf('?') >= 0)) {
        if (!extension.endsWith('/')) {
          extension = `${extension}/`;
        }
      }
      url = `${url}${extension}`;
    }
    return url;
  }
}