import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { User } from '../models/User';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { CurrentContextService } from '../services/currentContext.service';

import { AppState } from '../current.app.state';
import { AlertService } from '../services/alert.service';
import { StringService } from '../services/string.service';
import { Observable } from 'rxjs';
import { AlertType, AlertSimple } from '../models/Alert';

/**
 * EditUserComponent is used to manage the current logged in users
 */
@Component({
  selector: 'edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  /**
   * the current selected user
   * will be empty when creating a new user
   */
  public user: User;

  /**
   * stores the input of the oldPassword Textarea
   */
  public oldPwd = '';

  /**
   * stores the input of the newPassword Textarea
   */
  public newPwd = '';

  /**
   * stores the input of the newPasswordConfirm Textarea
   */
  public newPwdConfirm = '';

  /**
   * Creates an instance of EditUserComponent
   * @param location is used to navigate Back to the previous page after successful change of the user information
   * @param userService is used to get and update the user
   * @param authService is used to authenticate if the old password was correct
   * @param alertService is used to display a failing operation to the user
   * @param stringService is used to get the Strings that are displayed on the page
   * @param currentContextService is used to set the correct AppState
   */
  public constructor(
    private location: Location,
    private userService: UserService,
    private authService: AuthService,
    private alertService: AlertService,
    private stringService: StringService,
    private currentContextService: CurrentContextService
  ) {
    this.user = this.authService.getUser();
  }

  /**
   * sets the correct appState of the Application
   */
  public ngOnInit(): void {
    this.currentContextService.setCurrentState(AppState.editUser);
  }

  /**
  * Checks the old Password if it is correct,
  * when not it will display an alert
  * if correct it will check if the newPassword is equal to the newPasswordCheck
  * and response with a navigation and and alert if true
  * else it displays an alert with the false information
  */
  private isPwdValid(): Observable<boolean> {
    return new Observable<boolean>((sub) => {
      let valid = true;
      this.authService.login(this.user.name, this.oldPwd).subscribe(response => {
        if (this.oldPwd === this.newPwd) {
          this.alertService.alert(
            new AlertSimple(
              this.stringService.get('ERROR_OLD_PWD_EQL_NEW_PWD'),
              AlertType.Error));
          valid = false;
        }
        if (this.newPwd !== this.newPwdConfirm) {
          this.alertService.alert(
            new AlertSimple(
              this.stringService.get('ERROR_NEW_PWD_NOT_EQL_NEW_PWD_CONFIRM'),
              AlertType.Error));
          valid = false;
        }
        sub.next(valid);
      }, error => {
        this.alertService.alert(
          new AlertSimple(
            this.stringService.get('OLD_PASSWORD_IS_INCORRECT'),
            AlertType.Error));
        valid = false;
        sub.next(valid);
      });
    });
  }

  /**
   * updates the user if the credentials were correct
   */
  public updateUser(): void {
    this.isPwdValid().subscribe(valid => {
      if (!valid) { return; }
      if (this.newPwd !== '' && this.newPwdConfirm !== '') {
        this.user.password = this.newPwd;
      }

      this.userService.updateUser(this.user).subscribe(
        response => {
          this.cancel();
        }
      );
    });
  }

  /**
   * called when user uses the back button or some informations were changed
   * navigates back to the previous page
   */
  public cancel(): void {
    this.location.back();
  }

}
