import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap, share } from 'rxjs/operators';

import { Config } from '../Config';

import { AlertService } from './alert.service';
import { StringService } from './string.service';
import { AlertType, AlertSimple, AlertWithBoldValueInMiddle, Alert, AlertWithUrl } from '../models/Alert';
import { PoiTexture } from '../models/PoiTexture';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root',
})
export class PoiTextureService {

  private baseUrl = new Config().GetBaseUrl();

  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService
  ) { }

  public getTextures(trackerId?: string): Observable<PoiTexture[]> {
    let extension = '';
    if (trackerId && trackerId !== '') {
      extension = `?trackerId=${trackerId}`;
    }
    return this.http.get<PoiTexture[]>(this.getTextureUrl() + extension).pipe(
      tap(
        response => { },
        error => { this.handleError<PoiTexture>('getTextures', error); }
      ),
      share()
    );
  }

  public getTextureById(id: string): Observable<PoiTexture> {
    return this.http.get<PoiTexture>(this.getTextureUrl(`${id}`)).pipe(
      tap(
        response => { },
        error => {
          this.handleError<PoiTexture>('getTextureById', error);
          this.alertService.alert(
            new AlertSimple(
              this.stringService.get('TEXTURE_NOT_FOUND'),
              AlertType.Error),
            true);
        }
      ),
      share()
    );
  }

  public createTexture(texture: PoiTexture): Observable<any> {
    return this.http.post<any>(this.getTextureUrl(), texture, httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(
            new AlertSimple(this.stringService.get('TEXTURE_CREATED'), AlertType.Success), true
          );
        },
        error => { this.handleError<PoiTexture>('createTexture', error); }
      ),
      share()
    );
  }

  public updateTexture(texture: PoiTexture): Observable<any> {
    console.log(texture);
    return this.http.put(this.getTextureUrl(texture.id), texture, httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(
            new AlertSimple(this.stringService.get('TEXTURE_UPDATED'), AlertType.Success), true
          );
        },
        error => { this.handleError<PoiTexture>('updateTexture', error); }
      ),
      share()
    );
  }

  public deleteTexture(texture: PoiTexture): Observable<any> {
    return this.http.delete(this.getTextureUrl(`${texture.id}`), httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(
            new AlertSimple(this.stringService.get('TEXTURE_DELETED'), AlertType.Success), true
          );
        },
        error => {
          if (error.status === 409) {
            if (error.error && error.error.conflictingPois && error.error.conflictingPois.length > 0) {
              for (const conflictingPoi of error.error.conflictingPois) {
                const alert: Alert = new AlertWithUrl(
                  this.stringService.get('POI_STYLE_IN_USE'),
                  conflictingPoi.link,
                  AlertType.Error, 0
                );
                this.alertService.alert(alert, true);
              }
            }
          } else {
            this.handleError<PoiTexture>('deleteTexture', error);
          }
        }
      ),
      share()
    );
  }

  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private logError(value: any): void {
    console.error(value);
  }

  private getTextureUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}poi-textures/`;
    if (extension) {
      if (!extension.endsWith('/')) {
        extension = `${extension}/`;
      }
      url = `${url}${extension}`;
    }
    return url;
  }
}
