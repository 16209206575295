import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Language } from "../app/models/Language";
import { Media } from "../app/models/Media";
import { MultiLanguageData, MultiLanguageDataValue } from "../app/models/MultiLanguageData";
import { MediaService } from "../app/services/media.service";
import { Tools } from "../app/Tools";
import { MediaData, MediaLoader } from "./MediaLoader";

export class MultilangMediaLoader<TValue extends MultiLanguageDataValue> {
    private mediaLoader: MediaLoader;

    constructor(
        mediaService: MediaService,
        sanitizer: DomSanitizer,
        private mediaIdMapFn: (TValue) => string = (val: TValue) => { return (val as any).mediaId; }
    ) {
        this.mediaLoader = new MediaLoader(mediaService, sanitizer);
    }

    public reset() {
        this.mediaLoader.reset();
    }

    public getLoader() : MediaLoader { return this.mediaLoader; }

    /**
     * Start download of all contained mediaIds in the given multilangData.
     * @param multilangData
     */
    public loadEager(multilangData: MultiLanguageData<TValue>) {
        multilangData?.forEach((lang, value) => {
            let mediaId = this.mediaIdMapFn(value);
            this.mediaLoader.get(mediaId);
        });
    }

    public isLoadedWithFallback(multilangData: MultiLanguageData<TValue>, language: Language) : boolean {
        if(!multilangData) { return false; }
        if(!multilangData.hasAny()) { return false; }
        let mediaId = this.mediaIdMapFn(multilangData.getWithFallback(language));
        return this.mediaLoader.isLoaded(mediaId);
    }

    public getWithFallback(multilangData: MultiLanguageData<TValue>, language: Language) : MediaData {
        if(!multilangData) { return null; }
        if(!multilangData.hasAny()) { return null; }
        let mediaId = this.mediaIdMapFn(multilangData.getWithFallback(language));
        return this.mediaLoader.get(mediaId);
    }
}

export class MultilangMediaLoaderSingle<TValue extends MultiLanguageDataValue> {
    private multilangData: MultiLanguageData<TValue>;
    private mediaLoader: MultilangMediaLoader<TValue>;

    constructor(
        mediaService: MediaService,
        sanitizer: DomSanitizer,
        mediaIdMapFn: (TValue) => string
    ) {
        this.mediaLoader = new MultilangMediaLoader(mediaService, sanitizer, mediaIdMapFn);
    }

    public reset(multilangData: MultiLanguageData<TValue>) {
        this.mediaLoader.reset();
        this.multilangData = multilangData;
        this.mediaLoader.loadEager(this.multilangData);
    }

    public getLoader() : MediaLoader { return this.mediaLoader.getLoader(); }

    public isLoadedWithFallback(language: Language) : boolean {
        return this.mediaLoader.isLoadedWithFallback(this.multilangData, language);
    }
    public getWithFallback(language: Language) : MediaData {
        return this.mediaLoader.getWithFallback(this.multilangData, language);
    }
}