<div class="popup-page-editor" [ngClass]="{'disabled' : !this.enabled}">
  <div class="popup-left-side">
    <ng-template [ngIf]="this.enabled">
      <ng-content select="[popup-left-side-above]"></ng-content>

      <ng-template #editText [ngIf]="currentSelectedIs(this.elementType.TEXT)">
        <div class="poi-description" ondragenter="event.preventDefault(); event.dataTransfer.dropEffect = 'none'"
          ondragover="event.preventDefault(); event.dataTransfer.dropEffect = 'none'">
          <richtext-editor #richtextde *ngIf="this.isLanguage(Language.DE)"
            [(ngModel)]="$any(this.currentSelected).text.de" (ngModelChange)="acceptChangesToText($event)"
            [config]="editorConfig"></richtext-editor>
          <richtext-editor #richtextgb *ngIf="this.isLanguage(Language.GB)"
            [(ngModel)]="$any(this.currentSelected).text.gb" (ngModelChange)="acceptChangesToText($event)"
            [config]="editorConfig"></richtext-editor>
          <richtext-editor #richtextjp *ngIf="this.isLanguage(Language.JP)"
            [(ngModel)]="$any(this.currentSelected).text.jp" (ngModelChange)="acceptChangesToText($event)"
            [config]="editorConfig"></richtext-editor>
        </div>
      </ng-template>

      <ng-template #editImage [ngIf]="currentSelectedIs(this.elementType.IMAGE)">
        <manage-media [elementType]="this.elementType.IMAGE" [mediaData]="$any(this.currentSelected).imageObject.get(this.language)"
          (upateFile)="updateImageFile($event, this.language)" (removeMediaFile)="removeImageFile(this.language)">
        </manage-media>
      </ng-template>

      <ng-template #noneSelected [ngIf]="currentSelectedIs('None')">
        <i>{{'NO_ELEMENT_SELECTED' | translate }}</i>
      </ng-template>
    </ng-template>
  </div>
  <div class="popup-right-side">
    <div class="custom-row">
      <div class="smartphone-with-buttons">
        <div class="new-element-button-row row">
          <div class="new-element-button">
            <button type="submit" class="btn btn-light" (click)="addNewElement(this.elementType.IMAGE)"
              [matTooltip]="'ADD_IMAGE_ELEMENT' | translate" [disabled]="!this.enabled">
              <i class="fa fa-image" aria-hidden="true"></i>
            </button>
          </div>
          <div class="new-element-button">
            <button type="submit" class="btn btn-light" (click)="addNewElement(this.elementType.TEXT)"
              [matTooltip]="'ADD_TEXT_ELEMENT' | translate" [disabled]="!this.enabled">
              <i class="fa fa-paragraph" aria-hidden="true"></i>
              <!-- <img src="../../assets/text_fields-24px.svg"/> -->
            </button>
          </div>
        </div>
        <div #smartphone [ngStyle]="getSmartphoneStyle()" class="smartphone">
          <div #popup class="popupWindow">
            <h1>{{this.title}}</h1>
            <ng-template [ngIf]="this.uiElements.length === 0">
              <ng-content select="[popup-editor-default-content]"></ng-content>
            </ng-template>
            <ng-template ngFor let-item [ngForOf]="this.uiElements">
              <div [id]="'element-'+item.position" class="element"
                (mouseup)="setCurrentSelectedElement(item)"
                (mouseenter)="this.hovered = item.position"
                (mouseleave)="this.hovered = ''">
                <div *ngIf="!item.active" class="notVisible">
                  <i class="fa fa-eye-slash not-visible-icon" aria-hidden="true"></i>
                </div>
                <ng-template #text [ngIf]="item.type === this.elementType.TEXT">
                  <div class="text-element">
                    <div *ngIf="!isTextElementEmpty(item)">
                      <div class="text" *ngIf="isLanguage(Language.DE)"
                        [innerHTML]="this.getSanitizedText($any(item).text.de)"></div>
                      <div class="text" *ngIf="isLanguage(Language.GB)"
                        [innerHTML]="this.getSanitizedText($any(item).text.gb)"></div>
                      <div class="text" *ngIf="isLanguage(Language.JP)"
                        [innerHTML]="this.getSanitizedText($any(item).text.jp)"></div>
                    </div>
                    <div class="empty-element-text" *ngIf="isTextElementEmpty(item)">
                      <i>{{'TEXT_ELEMENT' | translate}} - {{'EMPTY_ELEMENT' | translate}}</i>
                    </div>
                  </div>
                </ng-template>

                <ng-template #image [ngIf]="item.type === this.elementType.IMAGE">
                  <div class="image-element element-with-multilang-fallback">
                    <div *ngIf="!isImageElementEmpty(item)">
                      <div class="multilang-fallback-overlay" *ngIf="!$any(item).imageObject.has(this.language)">
                        <i>{{'IMAGE_ELEMENT_MULTILANG_FALLBACK' | translate}}</i>
                      </div>
                      <div *ngIf="$any(item).imageObject.getWithFallback(this.language).fileSafeUrl && $any(item).imageObject.getWithFallback(this.language).isLoaded && !this.reload">
                        <img class="image" [src]="$any(item).imageObject.getWithFallback(this.language).fileSafeUrl">
                      </div>
                      <aacms-loader class="elementLoader" size="128px" *ngIf="!$any(item).imageObject.getWithFallback(this.language).isLoaded">
                      </aacms-loader>
                    </div>
                    <div class="empty-element-text" *ngIf="isImageElementEmpty(item)">
                      <i>{{'IMAGE_ELEMENT' | translate}} - {{'EMPTY_ELEMENT' | translate}}</i>
                    </div>
                  </div>
                </ng-template>
              </div>
            </ng-template>
            <button class="popupButton popupButtonGreen">{{ 'TRACKER_POPUP_BUTTON_SEARCH_NOW' | translateWith:this.language }}</button>
            <button class="popupButton popupButtonRed">{{ 'TRACKER_POPUP_BUTTON_FOR_LATER' | translateWith:this.language }}</button>
          </div>
        </div>
        <div class="hint">
          <i>{{ 'PREVIEW_MAY_LOOK_DIFFERENT' | translate }}</i>
        </div>
        <div class="screen-size-button-row">
          <div class="screen-size-button-group btn-group" btnRadioGroup [(ngModel)]="currentSelectedResolution">
            <label class="btn btn-light" btnRadio="small" uncheckable tabindex="0" role="button"
              (click)="changeSmartphoneStyle('14','7')">{{'SMALL_PHONE' | translate}}</label>
            <label class="btn btn-light" btnRadio="big" uncheckable tabindex="0" role="button"
              (click)="changeSmartphoneStyle('16','8')">{{'BIG_PHONE' | translate}}</label>
            <label class="btn btn-light" btnRadio="tablet" uncheckable tabindex="0" role="button"
              (click)="changeSmartphoneStyle('16','11')">{{'TABLET' | translate}}</label>
          </div>
        </div>
      </div>
      <div class="control-buttons">
        <div class="control-button">
          <button type="submit" class="btn btn-light" (click)="moveElementUp()"
            [disabled]="this.currentSelected == undefined" [matTooltip]="'MOVE_ELEMENT_UP' | translate" placement="left"
           >
            <i class="fa fa-angle-up" aria-hidden="true"></i>
          </button>
        </div>
        <div class="control-button">
          <button type="submit" class="btn btn-light" (click)="moveElementDown()"
            [disabled]="this.currentSelected == undefined" [matTooltip]="'MOVE_ELEMENT_DOWN' | translate" placement="left"
           >
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </button>
        </div>
        <div class="control-button">
          <button type="submit" class="btn btn-light" (click)="this.updateVisibility()"
            [class.active]="this.currentSelected && !this.currentSelected.active"
            [disabled]="this.currentSelected == undefined" [matTooltip]="'HIDE_ELEMENT' | translate" placement="left"
           >
            <i class="fa fa-eye-slash" aria-hidden="true"></i>
          </button>
        </div>
        <div class="control-button">
          <button type="submit" class="btn btn-light" (click)="deleteElement()"
            [disabled]="this.currentSelected === undefined || this.currentSelected.isMandatory" [matTooltip]="'DELETE_ELEMENT' | translate" placement="left"
           >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
        <div class="control-button">
          <button type="submit" class="btn btn-light" (click)="unsetCurrentSelected()"
            [disabled]="this.currentSelected == undefined" [matTooltip]="'UNSELECT_ELEMENT' | translate" placement="left"
           >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
