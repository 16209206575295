import { TriggerBehavior } from "./TriggerBehavior";

export class TrackerTriggerPosition {
    public longitude: number = 0;
    public latitude: number = 0;
    public triggerBehavior: TriggerBehavior = TriggerBehavior.Always;
    public radius: number = 5;

    constructor(trigger?: TrackerTriggerPosition) {
        if(!trigger) { return; }
        this.longitude = trigger.longitude;
        this.latitude = trigger.latitude;
        this.triggerBehavior = trigger.triggerBehavior;
        this.radius = trigger.radius;
    }

    public equal(trigger: TrackerTriggerPosition): boolean {
        return (this.longitude === trigger.longitude
            && this.latitude === trigger.latitude
            && this.radius === trigger.radius
            && this.triggerBehavior === trigger.triggerBehavior);
    }
}
export class TrackerTriggerOpticalMarker {
    public mediaId: string;

    constructor(trigger?: TrackerTriggerOpticalMarker) {
        if(!trigger) { return; }
        this.mediaId = trigger.mediaId;
    }

    public equal(trigger: TrackerTriggerOpticalMarker): boolean {
        return (trigger.mediaId === this.mediaId);
    }
}