<div class="header">
  <div class="title">
    <h2>{{ 'PUSH_NOTIFICATIONS' | translate }}</h2>
  </div>
  <aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
    (languageChanged)="this.updateLanguage($event)">
  </aacms-language-selector>
</div>
<div class="custom-hr"></div>
<div class="content">
  <div class="left">
    <div>
      {{ 'CREATE_NEW_PUSH_NOTIFICATION' | translate }}:
    </div>
    <div class="inputArea">
      <div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
        <input rows="1" maxlength="{{this.config.getMaxPushNotificationmaxTitleLength()}}"
          placeholder="{{ 'PUSH_NOTIFICATION_TITLE' | translate }}" class="form-control textInput" aria-label="Large"
          [(ngModel)]="this.currentNotification.title.de">
        <div class="languageIndicator">
          <img class="language-button-image" src="../assets/flags/de.svg">
        </div>
        <div class="remainingCharacter">
          {{this.config.getMaxPushNotificationmaxTitleLength() - this.currentNotification.title.de.length}}
        </div>
      </div>
      <div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
        <input rows="1" maxlength="{{this.config.getMaxPushNotificationmaxTitleLength()}}"
          placeholder="{{ 'PUSH_NOTIFICATION_TITLE' | translate }}" class="form-control textInput" aria-label="Large"
          [(ngModel)]="this.currentNotification.title.gb">
        <div class="languageIndicator">
          <img class="language-button-image" src="../assets/flags/gb.svg">
        </div>
        <div class="remainingCharacter">
          {{this.config.getMaxPushNotificationmaxTitleLength() - this.currentNotification.title.gb.length}}
        </div>
      </div>
      <div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
        <input rows="1" maxlength="{{this.config.getMaxPushNotificationmaxTitleLength()}}"
          placeholder="{{ 'PUSH_NOTIFICATION_TITLE' | translate }}" class="form-control textInput" aria-label="Large"
          [(ngModel)]="this.currentNotification.title.jp">
        <div class="languageIndicator">
          <img class="language-button-image" src="../assets/flags/jp.svg">
        </div>
        <div class="remainingCharacter">
          {{this.config.getMaxPushNotificationmaxTitleLength() - this.currentNotification.title.jp.length}}
        </div>
      </div>
      <div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
        <textarea rows="5" maxlength="{{this.config.getMaxPushNotificationmaxTextLength()}}"
          placeholder="{{ 'PUSH_NOTIFICATION_TEXT' | translate }}" class="form-control textInput" aria-label="Large"
          [(ngModel)]="this.currentNotification.text.de"></textarea>
        <div class="languageIndicator">
          <img class="language-button-image" src="../assets/flags/de.svg">
        </div>
        <div class="remainingCharacter">
          {{this.config.getMaxPushNotificationmaxTextLength() - this.currentNotification.text.de.length}}
        </div>
      </div>
      <div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
        <textarea rows="5" maxlength="{{this.config.getMaxPushNotificationmaxTextLength()}}"
          placeholder="{{ 'PUSH_NOTIFICATION_TEXT' | translate }}" class="form-control textInput" aria-label="Large"
          [(ngModel)]="this.currentNotification.text.gb"></textarea>
        <div class="languageIndicator">
          <img class="language-button-image" src="../assets/flags/gb.svg">
        </div>
        <div class="remainingCharacter">
          {{this.config.getMaxPushNotificationmaxTextLength() - this.currentNotification.text.gb.length}}
        </div>
      </div>
      <div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
        <textarea rows="5" maxlength="{{this.config.getMaxPushNotificationmaxTextLength()}}"
          placeholder="{{ 'PUSH_NOTIFICATION_TEXT' | translate }}" class="form-control textInput" aria-label="Large"
          [(ngModel)]="this.currentNotification.text.jp"></textarea>
        <div class="languageIndicator">
          <img class="language-button-image" src="../assets/flags/jp.svg">
        </div>
        <div class="remainingCharacter">
          {{this.config.getMaxPushNotificationmaxTextLength() - this.currentNotification.text.jp.length}}
        </div>
      </div>
      <div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
        <input rows="1" maxlength="{{this.config.getMaxPushNotificationUrlLength()}}"
          placeholder="{{ 'PUSH_NOTIFICATION_URL' | translate }}" class="form-control textInput" aria-label="Large"
          [(ngModel)]="this.currentNotification.url.de">
        <div class="languageIndicator">
          <img class="language-button-image" src="../assets/flags/de.svg">
        </div>
        <div class="remainingCharacter">
          {{this.config.getMaxPushNotificationUrlLength() - this.currentNotification.url.de.length}}
        </div>
      </div>
      <div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
        <input rows="1" maxlength="{{this.config.getMaxPushNotificationUrlLength()}}"
          placeholder="{{ 'PUSH_NOTIFICATION_URL' | translate }}" class="form-control textInput" aria-label="Large"
          [(ngModel)]="this.currentNotification.url.gb">
        <div class="languageIndicator">
          <img class="language-button-image" src="../assets/flags/gb.svg">
        </div>
        <div class="remainingCharacter">
          {{this.config.getMaxPushNotificationUrlLength() - this.currentNotification.url.gb.length}}
        </div>
      </div>
      <div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
        <input rows="1" maxlength="{{this.config.getMaxPushNotificationUrlLength()}}"
          placeholder="{{ 'PUSH_NOTIFICATION_URL' | translate }}" class="form-control textInput" aria-label="Large"
          [(ngModel)]="this.currentNotification.url.jp">
        <div class="languageIndicator">
          <img class="language-button-image" src="../assets/flags/jp.svg">
        </div>
        <div class="remainingCharacter">
          {{this.config.getMaxPushNotificationUrlLength() - this.currentNotification.url.jp.length}}
        </div>
      </div>
    </div>
    <div class="date-time-picker-text">
      {{ 'WHEN_TO_SEND' | translate }}
      <br/>
    </div>
    <div class="date-time-picker-row row">
      <div class="date-picker">
        <input type="text" placeholder="{{'DATE' | translate }}" class="form-control" bsDatepicker
          [(bsValue)]="this.currentDate" placement="right" [outsideClick]="true" [bsConfig]="{ isAnimated: false }"
          [minDate]="minDate">
      </div>
      <div class="time-picker">
        <timepicker [(ngModel)]="this.currentDate" [showMeridian]="false" [arrowkeys]="true" [showSpinners]="false"
          [hourStep]="1" [minuteStep]="1" (ngModelChange)="timeChanged()">
        </timepicker>
      </div>
    </div>
    <div class="custom-hr"></div>
    <div class="row">
      <div class="col-10">
        <div *ngIf="isLimitReached()" class="py-2 px-3 rounded bg-warning text-dark text-center">
          {{ 'MAX_PUSHNOTIFICATIONS_PER_DAY_REACHED' | translate }}
          {{ 'NEXT_AVAILABLE_TIME' | translate}} {{nextTime | date:'dd.MM.yyyy HH:mm' }}.
          {{ 'CONTACT_TO_INCREASE_LIMIT' | translate }}
        </div>
      </div>
      <div class="col-2 align-self-center">
        <button class="btn btn-primary submitButton custom-button" type="input" (click)="this.askToSend()"
        [disabled]="this.isDisabled()">
        {{ 'SEND_PUSH_NOTIFICATION' | translate }}
      </button>
      </div>
    </div>
  </div>
  <div class="right">
    <div>
      {{ 'ALREADY_SENT_PUSH_NOTIFICATIONS' | translate }}:
    </div>
    <div class="pushNotificationList">
      <ng-template ngFor let-pushNotification [ngForOf]="this.notifications">
        <div class="pushNotification">
          <div class="card-content">
            <div *ngIf="this.isLanguage(Language.DE)">
              <div class="pushNotificationTitle">
                <b *ngIf="pushNotification.title.de.length > 0">{{pushNotification.title.de}}</b>
                <i *ngIf="pushNotification.title.de.length == 0">{{ 'NO_TITLE_AVAILABLE' | translate }}</i>
              </div>
              <div class="pushNotificationText">
                <div *ngIf="pushNotification.text.de.length > 0">{{pushNotification.text.de}}</div>
                <i *ngIf="pushNotification.text.de.length == 0">{{ 'NO_TEXT_AVAILABLE' | translate }}</i>
              </div>
              <div class="pushNotificationUrl">
                <a *ngIf="pushNotification.url.de.length > 0" target="_blank"
                  href="{{pushNotification.url.de}}">{{pushNotification.url.de}}</a>
                <i *ngIf="pushNotification.url.de.length == 0">{{ 'NO_URL_AVAILABLE' | translate }}</i>
              </div>
            </div>
            <div *ngIf="this.isLanguage(Language.GB)">
              <div class="pushNotificationTitle">
                <b *ngIf="pushNotification.title.gb.length > 0">{{pushNotification.title.gb}}</b>
                <i *ngIf="pushNotification.title.gb.length == 0">{{ 'NO_TITLE_AVAILABLE' | translate }}</i>
              </div>
              <div class="pushNotificationText">
                <div *ngIf="pushNotification.text.gb.length > 0">{{pushNotification.text.gb}}</div>
                <i *ngIf="pushNotification.text.gb.length == 0">{{ 'NO_TEXT_AVAILABLE' | translate }}</i>
              </div>
              <div class="pushNotificationUrl">
                <a *ngIf="pushNotification.url.gb.length > 0" target="_blank"
                  href="{{pushNotification.url.gb}}">{{pushNotification.url.gb}}</a>
                <i *ngIf="pushNotification.url.gb.length == 0">{{ 'NO_URL_AVAILABLE' | translate }}</i>
              </div>
            </div>
            <div *ngIf="this.isLanguage(Language.JP)">
              <div class="pushNotificationTitle">
                <b *ngIf="pushNotification.title.jp.length > 0">{{pushNotification.title.jp}}</b>
                <i *ngIf="pushNotification.title.jp.length == 0">{{ 'NO_TITLE_AVAILABLE' | translate }}</i>
              </div>
              <div class="pushNotificationText">
                <div *ngIf="pushNotification.text.jp.length > 0">{{pushNotification.text.jp}}</div>
                <i *ngIf="pushNotification.text.jp.length == 0">{{ 'NO_TEXT_AVAILABLE' | translate }}</i>
              </div>
              <div class="pushNotificationUrl">
                <a *ngIf="pushNotification.url.jp.length > 0" target="_blank"
                  href="{{pushNotification.url.jp}}">{{pushNotification.url.jp}}</a>
                <i *ngIf="pushNotification.url.jp.length == 0">{{ 'NO_URL_AVAILABLE' | translate }}</i>
              </div>
            </div>
            <div *ngIf="pushNotification.executionDate" class="pushNotificationExecutionDate">
              {{ 'EXECUTION_TIME' | translate }}: {{pushNotification.executionDate | date:'dd.MM.y  hh:mm'}}
            </div>
          </div>
          <span class="card-spacer"></span>
          <div class="icons">
            <button (click)="openModal(deleteModalTemplate, pushNotification)" class="icon-button"
              [matTooltip]="'DELETE_PUSH_NOTIFICATION' | translate" triggers="hover">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #sendModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-break pull-left">{{'REALY_SEND_PUSH_NOTIFICATION' | translate}}</h4>
  </div>
  <div class="modal-body"
    *ngIf="this.emptyGermanFields.length > 0 || this.emptyEnglishFields.length > 0 || this.emptyJapaneseFields.length > 0">
    <h5>{{'EMPTY_FIELDS' | translate}}</h5>
    <ng-template [ngIf]="this.emptyGermanFields.length > 0">
      <b>{{'GERMAN' | translate}}:</b><br>
      <ng-template ngFor let-missingElement [ngForOf]="this.emptyGermanFields">
        - {{missingElement}} <br>
      </ng-template>
    </ng-template>
    <ng-template [ngIf]="this.emptyEnglishFields.length > 0">
      <b>{{'ENGLISH' | translate}}:</b><br>
      <ng-template ngFor let-missingElement [ngForOf]="this.emptyEnglishFields">
        - {{missingElement}} <br>
      </ng-template>
    </ng-template>
    <ng-template [ngIf]="this.emptyJapaneseFields.length > 0">
      <b>{{'JAPANESE' | translate}}:</b><br>
      <ng-template ngFor let-missingElement [ngForOf]="this.emptyJapaneseFields">
        - {{missingElement}} <br>
      </ng-template>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button (click)="this.createPushNotification()" type="button"
      class="btn btn-success">{{'SEND_PUSH_NOTIFICATION'| translate}}</button>
    <button (click)="this.discard()" type="button" class="btn btn-danger">{{'NO_ABORT' | translate }}</button>
  </div>
</ng-template>

<ng-template #deleteModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-break pull-left">
      {{'PUSH_DELETE_QUESTION' | translate }}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
    <button (click)="deletePushNotification()" type="button" class="btn btn-danger">{{'YES_DELETE' | translate }}</button>
  </div>
</ng-template>
