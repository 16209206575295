import { FileDataType } from "./FileDataType";
import { Language } from "./Language";
import { LanguagePair } from "./LanguagePair";

export class MultiLanguageFileDataType {

public languages: LanguagePair[] = [];

    constructor(container?: MultiLanguageFileDataType){
        if (container === undefined) {
            const enumValues = Object.values(Language);
            for (const language of enumValues.slice(enumValues.length / 2, enumValues.length)) {
              const pair = new LanguagePair();
              pair.key = +language;
              pair.value = undefined;
              this.languages.push(pair);
            }
        } else {
            if (container.languages !== undefined) {
              if (container.languages.length > 0) {
                for (const pair of container.languages) {
                  this.languages.push(new LanguagePair(pair));
                }
              } else {
                const enumValues = Object.values(Language);
                for (const language of enumValues.slice(enumValues.length / 2, enumValues.length)) {
                  const pair = new LanguagePair();
                  pair.key = +language;
                  pair.value = undefined;
                  this.languages.push(pair);
                }
              }
            }
        }
    }

    public getAllValues(): FileDataType[] {
        const data: FileDataType[] = [];
        for(const pair of this.languages) {
            data.push(pair.value);
        }
        return data;
    }

    public getAllKeys(): Language[] {
        const data: Language[] = [];
        for(const pair of this.languages) {
            data.push(pair.key);
        }
        return data;
    }

    public get(language: Language): FileDataType {
        for(const pair of this.languages) {
            if (pair.key === language) {
                return pair.value;
            }
        }
        return undefined;
    }

    public getWithFallback(language: Language): FileDataType {
        let langPrioList = Object.keys(Language).filter(v => !isNaN(Number(v)));
        { // first try main requested language
            let mainLang = this.get(language);
            if(mainLang !== undefined) { return mainLang; }
        } // then as fallback, iterate through prio list if languages
        for(let i = 0; i < langPrioList.length; ++i) {
            let fallbackLang = this.get(i as Language);
            if(fallbackLang !== undefined) { return fallbackLang; }
        }
        return undefined;
    }

    public has(language: Language): boolean {
        return (this.get(language) !== undefined);
    }

    public hasAny(): boolean {
        for(const pair of this.languages) {
            if(pair.value !== undefined) { return true; }
        }
        return false;
    }

    public set(language: Language, fileData: FileDataType): void {
        for(const pair of this.languages) {
            if (pair.key === language) {
                pair.value = fileData;
                return;
            }
        }
        const pair = new LanguagePair();
        pair.key = language;
        pair.value = fileData;
        this.languages.push(pair);
    }

    public equal(container: MultiLanguageFileDataType): boolean {
        if (this.languages !== undefined && container.languages !== undefined) {
            for (const pair of this.languages) {
                for(const pairText of container.languages) {
                    if (pair.key === pairText.key) {
                        if (pair.value === undefined && pairText.value === undefined) {
                            return true;
                        }
                        if (
                            pair.value !== undefined && pairText.value === undefined ||
                            pair.value === undefined && pairText.value !== undefined ||
                            !pair.value.equal(pairText.value)
                            ) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    public clean(): void {
      for (const language of this.languages) {
        if (language.value !== undefined) {
          language.value.clean();
        }
      }
    }
}
