
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from './current.app.state';
import { AuthService } from './services/auth.service';
import { CurrentContextService } from './services/currentContext.service';

@Injectable({
  providedIn: 'root',
  deps: [AuthService, Router]
})
export class AuthInterceptor implements HttpInterceptor {

  public constructor(
    private auth: AuthService,
    private router: Router,
    private contextService: CurrentContextService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getLoginToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 && this.contextService.getCurrentState() !== AppState.editUser) {
          this.router.navigate(['login']);
          this.auth.logout();
          this.contextService.setSavingContent(false);
        } else if (err.status === 423) {
          this.router.navigate(['exhibitions']);
        }
      }
    }));
  }
}
