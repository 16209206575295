import { MapElement } from "./GeoJson";
import { AudioElement, ElementType, ImageElement, ModelElement, TextElement, UiElement, VideoElement } from "./UiElements";

type HasEqualityOperator = { equal(other: HasEqualityOperator) : boolean; };

type ctorFor<T> = new (...args: any) => T;

export class ModelHelper {
	public static cloneUiElementArray(uiElements: UiElement[]): UiElement[] {
		let result: UiElement[] = [];
		if (uiElements) {
			for (const element of uiElements) {
				if (element.type === ElementType.AUDIO) {
					result.push(new AudioElement(<AudioElement>element));
				} else if (element.type === ElementType.IMAGE) {
					result.push(new ImageElement(<ImageElement>element));
				} else if (element.type === ElementType.MODEL) {
					result.push(new ModelElement(<ModelElement>element));
				} else if (element.type === ElementType.TEXT) {
					result.push(new TextElement(<TextElement>element));
				} else if (element.type === ElementType.VIDEO) {
					result.push(new VideoElement(<VideoElement>element));
				} else if (element.type === ElementType.MAP) {
					result.push(new MapElement(<MapElement>element));
				}
			}
		}
		return result;
	}

	public static arrayClone<TItem>(ctor: ctorFor<TItem>, elements?: TItem[]): TItem[] {
		let result: TItem[] = [];
		if(elements) {
			for(let element of elements) {
				result.push(new ctor(element));
			}
		}
		return result;
	}

	public static arrayEqual(elements1: HasEqualityOperator[], elements2: HasEqualityOperator[]): boolean {
		if (elements1.length !== elements2.length) { return false; }
		for (let i = 0; i < elements1.length; i++) {
			if (!elements1[i].equal(elements2[i])) {
				return false;
			}
		}
		return true;
	}
}

