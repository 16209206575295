import { CubeSides } from './CubeSides';

export class CuboidMediaData {
  public mediaId: string;
  public useImage = true;

  constructor(cuboidMediaData?: CuboidMediaData) {
    if (!cuboidMediaData) {
      return;
    }
    this.mediaId = cuboidMediaData.mediaId;
    this.useImage = cuboidMediaData.useImage;
  }

  public equal(cuboidMediaData: CuboidMediaData) {
    if (
      this.mediaId !== cuboidMediaData.mediaId ||
      this.useImage !== cuboidMediaData.useImage
    ) {
      return false;
    }
    return true;
  }
}

export class CuboidMediaIds {

  public top: CuboidMediaData = new CuboidMediaData();
  public bottom: CuboidMediaData = new CuboidMediaData();
  public front: CuboidMediaData = new CuboidMediaData();
  public back: CuboidMediaData = new CuboidMediaData();
  public left: CuboidMediaData = new CuboidMediaData();
  public right: CuboidMediaData = new CuboidMediaData();

  constructor(cuboidMediaId?: CuboidMediaIds) {
    if (!cuboidMediaId) {
      return;
    }
    this.top = new CuboidMediaData(cuboidMediaId.top);
    this.bottom = new CuboidMediaData(cuboidMediaId.bottom);
    this.front = new CuboidMediaData(cuboidMediaId.front);
    this.back = new CuboidMediaData(cuboidMediaId.back);
    this.left = new CuboidMediaData(cuboidMediaId.left);
    this.right = new CuboidMediaData(cuboidMediaId.right);
  }

  public equal(cuboidMediaId: CuboidMediaIds): boolean {
    if (
      !this.top.equal(cuboidMediaId.top) ||
      !this.bottom.equal(cuboidMediaId.bottom) ||
      !this.front.equal(cuboidMediaId.front) ||
      !this.back.equal(cuboidMediaId.back) ||
      !this.left.equal(cuboidMediaId.left) ||
      !this.right.equal(cuboidMediaId.right)
    ) {
      return false;
    }
    return true;
  }

  public setSide(side: CubeSides, mediaId: string): boolean {
    switch (side) {
      case CubeSides.top: {
        this.top.mediaId = mediaId;
        return true;
      }
      case CubeSides.bottom: {
        this.bottom.mediaId = mediaId;
        return true;
      }
      case CubeSides.front: {
        this.front.mediaId = mediaId;
        return true;
      }
      case CubeSides.back: {
        this.back.mediaId = mediaId;
        return true;
      }
      case CubeSides.left: {
        this.left.mediaId = mediaId;
        return true;
      }
      case CubeSides.right: {
        this.right.mediaId = mediaId;
        return true;
      }
      default: {
        return false;
      }
    }
  }

  public toggleUseImage(side: CubeSides): boolean {
    switch (side) {
      case CubeSides.top: {
        this.top.useImage = !this.top.useImage;
        return true;
      }
      case CubeSides.bottom: {
        this.bottom.useImage = !this.bottom.useImage;
        return true;
      }
      case CubeSides.front: {
        this.front.useImage = !this.front.useImage;
        return true;
      }
      case CubeSides.back: {
        this.back.useImage = !this.back.useImage;
        return true;
      }
      case CubeSides.left: {
        this.left.useImage = !this.left.useImage;
        return true;
      }
      case CubeSides.right: {
        this.right.useImage = !this.right.useImage;
        return true;
      }
      default: {
        return false;
      }
    }
  }

  public getSide(side: CubeSides): CuboidMediaData {
    switch (side) {
      case CubeSides.top: {
        return this.top;
      }
      case CubeSides.bottom: {
        return this.bottom;
      }
      case CubeSides.front: {
        return this.front;
      }
      case CubeSides.back: {
        return this.back;
      }
      case CubeSides.left: {
        return this.left;
      }
      case CubeSides.right: {
        return this.right;
      }
      default: {
        return undefined;
      }
    }
  }
}
