import { Pipe, PipeTransform } from '@angular/core';
import { GeoJsonFeature } from '../models/GeoJson';

@Pipe({
    name: 'GeoJsonFeatureFilter',
    pure: false
})
export class GeoJsonFeatureFilterPipe implements PipeTransform {

    transform(features: GeoJsonFeature[]): GeoJsonFeature[] {
        if (!features) { return []; }
        return features.filter(feature => feature.geometry.type === 'Point').sort((a, b) => a.properties.position - b.properties.position);
    }
}
