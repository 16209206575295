<div class="language-selection-label">{{'SELECT_LANGUAGE' | translate }}</div>
<div class="language-selection">
  <button #buttonDE class="language-button" (click)="setLanguage(Language.DE)" [ngStyle]="getStyle(Language.DE)"
    [matTooltip]="'GERMAN' | translate" triggers="hover">
    <img class="language-button-image" src="../assets/flags/de.svg">
  </button>
  <button #buttonGB class="language-button" (click)="setLanguage(Language.GB)" [ngStyle]="getStyle(Language.GB)"
    [matTooltip]="'ENGLISH' | translate" triggers="hover">
    <img class="language-button-image" src="../assets/flags/gb.svg">
  </button>
  <button #buttonJP class="language-button" (click)="setLanguage(Language.JP)" [ngStyle]="getStyle(Language.JP)"
    [matTooltip]="'JAPANESE' | translate" triggers="hover">
    <img class="language-button-image" src="../assets/flags/jp.svg">
  </button>
</div>
