import { Pipe, PipeTransform } from '@angular/core';
import { PoiTexture } from '../models/PoiTexture';

/**
 * The TranslatePipe is used to get the Strings from the StringService in the current Application language,
 * so it is easyer to request the strings in html
 */
@Pipe({
  name: 'AlphabeticalOrderPipe',
  pure: true
})
export class AlphabeticalOrderPipe implements PipeTransform {

  public constructor() { }

  transform(poitextures: PoiTexture[]): PoiTexture[] {
    return poitextures.sort((t1, t2) => {
        if (t1.name.toUpperCase() < t2.name.toUpperCase()) { return -1; }
        if (t1.name.toUpperCase() > t2.name.toUpperCase()) { return 1; }
        return 0;
    });
  }
}
