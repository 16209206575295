<div *ngIf="this.arLayer && this.poi" class="edit-arLayer-poi-main-content">
	<div class="edit-arlayer-poi-left-side" *ngIf="this.cuboid">
		<aacms-cuboid-viewer #cuboidViewer class="cuboid-viewer" [cuboid]="this.cuboid"
			[currentLanguage]="this.currentLanguage" [(imageDataMap)]="this.cuboidImageDataMap" [arLayer]="this.arLayer"
			[enableFileControl]="false" [(arLayerImageDataMap)]="this.arLayerImageDataMap"
			[(sideModel)]="this.poi.imageSide">
		</aacms-cuboid-viewer>
	</div>
	<div class="edit-arlayer-poi-right-side">
		<div class="header">
			<div class="path-title-container">
				<div class="path">
					<div class="path-option" (click)="this.routeTo(appState.exhibitions)">{{'EXHIBITIONS' | translate}}
					</div>
					/
					<div class="path-option" (click)="this.routeTo(appState.images)">{{
						Tools.shortenString(contextService.currentExhibition && contextService.currentExhibition.title
						&& getLanguageValue(contextService.currentExhibition.title).length > 0 ?
						getLanguageValue(contextService.currentExhibition.title) : ('TRACKERS' | translate)) }}</div>
					/
					<div class="path-option" (click)="this.routeTo(appState.editCuboid)">{{ Tools.shortenString(cuboid
						&& getLanguageValue(cuboid.title).length > 0 ? getLanguageValue(cuboid.title) : ('CUBOID' |
						translate)) }}</div>
					/
					<div class="path-option" (click)="this.routeTo(appState.editARLayer)">{{ Tools.shortenString(arLayer
						&& getLanguageValue(arLayer.title).length > 0 ? getLanguageValue(arLayer.title) : ('AR_LAYER' |
						translate)) }}</div>
					/
					<div class="last-path-option">{{ Tools.shortenString(poi && getLanguageValue(poi.title).length > 0 ?
						getLanguageValue(poi.title) : ('POI' | translate)) }}</div>
				</div>
				<div class="arlayer-title-edit-container">
					<h1 class="edit-indicator">
						<i class="fa fa-pencil" aria-hidden="true"
							[ngStyle]="{'color': contentChanged || pms.poisChanged ? '#ffc107' : 'black' }"></i>
					</h1>
					<div class="arlayer-title-container">
						<h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.poi.title).length > 0">
							{{this.getLanguageValue(this.poi.title)}}
						</h1>
						<h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.poi.title).length == 0">
							<i>{{'UNNAMED_POI' | translate }}</i>
						</h1>
					</div>
				</div>
			</div>
			<aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
				(languageChanged)="this.updateLanguage($event)">
			</aacms-language-selector>
		</div>
		<div class="custom-hr"></div>
		<tabset [justified]="false">
			<tab heading="{{'POI_GENERAL_TAB' | translate}}" class="general-tab tab"
				(selectTab)="this.currentTab = tabType.GeneralTab">
				<div class="poi-title-input">
					<div>{{'TITLE' | translate }}</div>
					<div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
						<input class="form-control textInput" [(ngModel)]="this.poi.title.de"
							maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
							(ngModelChange)="checkForContentChange()" />
						<div class="languageIndicator">
							<img class="language-button-image" src="../assets/flags/de.svg">
						</div>
						<div class="align-self-center px-2">
							{{this.config.getMaxTitleTextLength() - this.poi.title.de.length}}
						</div>
					</div>
					<div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
						<input class="form-control textInput" [(ngModel)]="this.poi.title.gb"
							maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
							(ngModelChange)="checkForContentChange()" />
						<div class="languageIndicator">
							<img class="language-button-image" src="../assets/flags/gb.svg">
						</div>
						<div class="align-self-center px-2">
							{{this.config.getMaxTitleTextLength() - this.poi.title.gb.length}}
						</div>
					</div>
					<div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
						<input class="form-control textInput" [(ngModel)]="this.poi.title.jp"
							maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
							(ngModelChange)="checkForContentChange()" />
						<div class="languageIndicator">
							<img class="language-button-image" src="../assets/flags/jp.svg">
						</div>
						<div class="align-self-center px-2">
							{{this.config.getMaxTitleTextLength() - this.poi.title.jp.length}}
						</div>
					</div>
				</div>
				<div *ngIf="this.showContentTypesSelection()">{{ 'TYPE_OF_CONTENT_PAGE' | translate }}</div>
				<div *ngIf="this.showContentTypesSelection()" class="selection-container">
					<select class="selection" [(ngModel)]="poi.contentType" (change)="checkForContentChange()">
						<ng-template ngFor let-type [ngForOf]="config.getEnabledContentTypes()">
							<option role="selection-item" [ngValue]="type">{{ getStringNameForContentType(type) }}
							</option>
						</ng-template>
					</select>
				</div>
				<div *ngIf="showPoiTexturesSelection()" class="selection-container">
					<div>{{ 'POI_TEXTURES' | translate }}:</div>
					<div>
						<select class="selection" (change)="this.poiTextureChanged($event)">
							<option *ngFor="let poiTexture of this.poiTextures | AlphabeticalOrderPipe" role="selection-item" value="{{poiTexture.id}}"
								[selected]="this.poi.multiLanguageTextureId.get(this.currentLanguage)?.textureId === poiTexture.id">
								{{ poiTexture.name }}
							</option>
							<option role="selection-item" value="" class="create-poi-texture-option">
								{{ 'CREATE_NEW_POI_TEXTURE' | translate }}
							</option>
						</select>
					</div>
				</div>
				<div class="checkbox-container">
					<label class="checkbox">
						<input type="checkbox" (click)="this.togglePoiActivation()" [checked]="this.poi.active">
						<span class="checkmark" triggers="hover"></span>
						<div class="checkbox-text">
							{{ 'POI_ACTIVE_INACTIVE' | translate }}
						</div>
					</label>
				</div>
				<div>{{ 'DISTANCE' | translate}}</div>
				<div class="distance-input-group">
					<input #distanceInput class="form-control .form-control-sm" [(ngModel)]="this.distance"
						(ngModelChange)="this.updateDistanceToTracker($event, distanceInput)">
				</div>
			</tab>
			<tab heading="{{ 'POI_CARD_TAB' | translate }}" class="card-tab tab"
				(selectTab)="this.currentTab = tabType.CardTab">
				<div class="smartphone-container">
					<aacms-info-card-editor [card]="this.poi.card" [title]="this.poi.title" [poi]="this.poi"
						[currentLanguage]="this.currentLanguage" [trackerFileData]="getTrackerFileData()"
						[cuboid]="this.cuboid" (cardChange)="this.checkForContentChange()"
						[uiElements]="this.poi.uiElements">
					</aacms-info-card-editor>
				</div>
			</tab>
			<tab heading="{{ 'POI_CONTENT_PAGE_TAB' | translate }}" class="content-page-tab tab"
				*ngIf="this.isTabAllowed(contentTypes.Custom)" (selectTab)="this.currentTab = tabType.UiElementsTab">
				<div class="smartphone-container">
					<aacms-content-page #smartphone [(uiElements)]="this.poi.uiElements"
						[isVisible]="currentTab === tabType.UiElementsTab" (uiElementsChange)="checkForContentChange()"
						[language]="this.currentLanguage">
					</aacms-content-page>
				</div>
			</tab>
			<tab heading="{{ 'POI_URL_PAGE_TAB' | translate }}" class="url-page-tab tab"
				*ngIf="this.isTabAllowed(contentTypes.URL)" (selectTab)="this.currentTab = tabType.UrlTab">
				<div class="smartphone-container">
					<aacms-url-page [url]="this.poi.url" (urlChange)="checkForContentChange()"
						[language]="this.currentLanguage">
					</aacms-url-page>
				</div>
			</tab>
		</tabset>
		<div class="custom-hr"></div>
		<div class="button-row row">
			<button type="submit" class="btn btn-secondary custom-button" (click)="returnToArLayer()">
				{{'BACK_TO_ARLAYER' | translate}}
			</button>
			<button (click)="openModal(deleteModalTemplate)" type="button" class="btn btn-danger custom-button">
				{{'DELETE_POI' | translate }}
			</button>
			<button type="submit" class="btn btn-warning custom-button" [disabled]="!contentChanged && !pms.poisChanged"
				(click)="openModal(cancelModalTemplate)">
				{{'DISCARD' | translate }}
			</button>
			<button type="submit" class="btn btn-success custom-button" [disabled]="!contentChanged && !pms.poisChanged"
				(click)="save()">
				{{'SAVE_CHANGES' | translate }}
			</button>
		</div>
	</div>
</div>

<ng-template #cancelModalTemplate>
	<div class="modal-header">
		<h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
	</div>
	<div class="modal-footer">
		<button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate
			}}</button>
		<button (click)="discard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
	</div>
</ng-template>


<ng-template #deleteModalTemplate>
	<div class="modal-header">
		<h4 *ngIf="poi == null || !poi.title.de" class="modal-title text-break pull-left">{{'DELETE_POI_QUESTION' |
			translate }}
		</h4>
		<h4 *ngIf="poi && poi.title.de" class="modal-title text-break pull-left">{{'DELETE_POI_QUESTION1' | translate }}
			<i>'{{poi.title.de}}'</i> {{'DELETE_POI_QUESTION2' | translate }}
		</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-footer">
		<button (click)="modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
		<button (click)="delete()" type="button" class="btn btn-danger">{{'YES_DELETE' | translate}}</button>
	</div>
</ng-template>