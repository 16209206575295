import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { tap } from 'rxjs/operators';
import { Config } from '../../Config';
import { AlertService } from '../../services/alert.service';
import { MediaService } from '../../services/media.service';
import { StringService } from '../../services/string.service';
import { TourService } from '../../services/tour.service';
import { Tour } from '../../models/Tour';
import { GPXParserService } from '../../services/gpx.parser.service';
import { GeoJson, MapElement } from '../../models/GeoJson';
import { GeoJsonParserService } from '../../services/geoJson.parser.service';

@Component({
  selector: 'modal-content',
  templateUrl: './tours-multi-upload.modal.component.html',
  styleUrls: ['./tours-multi-upload.modal.component.scss']
})
export class MultiUploadToursModal implements OnInit {

  public currentFiles: File[] = [];
  public uploadInProgress = false;
  private loadedTours: Map<string, boolean> = new Map<string, boolean>();
  private amount = 0;

  public constructor(
    public bsModalRef: BsModalRef,
    private tourService: TourService,
    private mediaService: MediaService,
    public alertService: AlertService,
    public stringService: StringService,
    private gpxParser: GPXParserService,
    private geoJsonParser: GeoJsonParserService
  ) { }

  public handleFilesFromDropin(event: File[]) {
    this.handleFiles(event);
  }

  public ngOnInit(): void {
    this.uploadInProgress = false;
  }

  private handleFiles(files: File[]): void {
    for (const file of files) {
      this.currentFiles.push(file);
      this.loadedTours.set(file.name, false);
    }
  }

  public removeFile(file: File): void {
    if (this.currentFiles.includes(file)) {
      this.currentFiles = this.currentFiles.filter(element => element !== file);
    }
  }

  public onClose(): void {
    this.bsModalRef.hide();
  }

  public async onCreate(): Promise<void> {
    this.uploadInProgress = true;
    this.amount = this.currentFiles.length;
    for (let index = 0; index < this.currentFiles.length; index++) {
      let uploadWasGood = true;
      const file = this.currentFiles[index];
      const tour = new Tour();
      if (file.name.endsWith('gpx')) {
        await this.gpxParser.parse(file).then(
          geoJson => {
            this.setGeoJsonOfTour(new GeoJson(geoJson), tour);
          },
           error => {
              uploadWasGood = false;
              console.log(error);
           }
        );
      } else if (file.name.endsWith('geojson')) {
        await this.geoJsonParser.parse(file).then(
          geoJson => {
            this.setGeoJsonOfTour(new GeoJson(geoJson), tour);
          },
           error => {
              uploadWasGood = false;
           }
        );
      }
      tour.title.de = file.name.substring(0, file.name.lastIndexOf('.'));
      tour.title.gb = file.name.substring(0, file.name.lastIndexOf('.'));
      tour.title.jp = file.name.substring(0, file.name.lastIndexOf('.'));
      if (uploadWasGood) {
        await this.createTour(tour, file.name);
      } else {
        this.updateUploadProgress(file.name);
      }
    }
  }

  private setGeoJsonOfTour(geoJson: GeoJson, tour: Tour): void {
    const mapElement = new MapElement();
    mapElement.mapObject = geoJson;
    mapElement.isMandatory = true;
    mapElement.position = tour.href.uiElements.length;
    tour.href.uiElements.push(mapElement);
  }

  private async createTour(tour: Tour, filename: string): Promise<any> {
    return this.tourService.createTour(tour).pipe(tap(response2 => {
      this.updateUploadProgress(filename);
    })).toPromise();
  }

  private updateUploadProgress(filename: string): void {
    this.amount--;
      this.loadedTours.set(filename, true);
      if (this.amount === 0) {
        this.bsModalRef.hide();
        this.uploadInProgress = false;
      }
  }

  public tourLoaded(file: File): boolean {
    return this.loadedTours.get(file.name);
  }

  public trackByFn(index, item) {
    return item.id;
  }

  public getMaxContentFileSize(): number {
    return new Config().getMaxGPXFileSize();
  }
}
