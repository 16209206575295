import { Component, HostListener, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { Config } from '../Config';
import { AppState } from '../current.app.state';
import { ContentTypes } from '../models/ContentTypes';
import { CubeSides } from '../models/CubeSides';
import { FileDataType } from '../models/FileDataType';
import { Image } from '../models/Image';
import { Language } from '../models/Language';
import { Media } from '../models/Media';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { Poi } from '../models/Poi';
import { CurrentContextService } from '../services/currentContext.service';
import { ImageService } from '../services/image.service';
import { MediaService } from '../services/media.service';
import { PoiManagerService } from '../services/poi-manager.service';
import { Tools } from '../Tools';

@Component({
	selector: 'aacms-edit-image',
	templateUrl: './edit-image.component.html',
	styleUrls: ['./edit-image.component.scss']
})
export class EditImageComponent implements OnInit, OnDestroy {

	public image: Image = new Image();
	public originalImage: Image = new Image();

	public imageData: FileDataType = null;

	public imageChanged = false;

	public saving = false;

	public modalRef: BsModalRef;

	public config: Config = new Config();
	public appState = AppState;
	public Language = Language;
	public contentTypes = ContentTypes;
	public cubeSides = CubeSides;

	public currentLanguage: Language = null;

	private enabledContentTypes: ContentTypes[];

	private navigationSubscription: Subscription;
	private saveContentSubject: Subscription;

	public Tools = Tools;

	public constructor(
		private route: ActivatedRoute,
		private imageService: ImageService,
		public contextService: CurrentContextService,
		public pms: PoiManagerService,
		private modalService: BsModalService,
		private router: Router,
		private mediaService: MediaService,
		private sanitizer: DomSanitizer
	) {
		this.enabledContentTypes = this.config.getEnabledContentTypes();
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.image = null;
				this.imageData = null;
				this.contextService.setCurrentState(AppState.editImage);
				this.currentLanguage = Language.DE;
				this.getImage();
			}
		});
	}

	public ngOnInit(): void {
		this.pms.clearPois();
		this.saveContentSubject = this.contextService.saveYourContent.subscribe(
			saveContent => {
				if (saveContent) {
					this.onSave();
				}
			}
		);
	}

	public ngOnDestroy(): void {
		this.navigationSubscription.unsubscribe();
		this.saveContentSubject.unsubscribe();
	}

	@HostListener('window:beforeunload')
	canDeactivate(): Observable<boolean> | boolean {
		return (this.imageChanged || this.pms.poisChanged) ? false : true;
	}

	public getImage(): void {
		const id = this.route.snapshot.paramMap.get('imageId');
		const sub = this.imageService.getImageById(id).subscribe(
			image => {
				this.image = new Image(image);
				this.originalImage = new Image(image);
				if (this.image.mediaId) {
					this.getMedia();
				}
				this.contextService.getExhibition(this.image.exhibitionIds[0]);
				sub.unsubscribe();
			},
			error => {
				this.router.navigate(['images']);
			});
	}

	private checkForImageChange(): void {
		if (!this.image.equal(this.originalImage)) {
			this.imageChanged = true;
			return;
		}
		this.imageChanged = false;
	}

	public deletePoi(poiToDelete: Poi, event: MouseEvent) {
		event.stopPropagation();
		this.pms.pois = this.pms.pois.filter(poi => !poiToDelete.equal(poi));
		if (this.pms.selectedPoi && poiToDelete.equal(this.pms.selectedPoi)) {
			this.pms.selectedPoi = null;
		}
		this.pms.updatePoiPositions();
		this.pms.reloadPoiLayer();
		this.pms.checkForPoisChanged();
	}

	public selectPoi(poi: Poi): void {
		if (poi === null) {
			this.pms.selectedPoi = null;
		} else {
			this.pms.selectedPoi = poi;
		}
		this.pms.selectedPoi = poi;
	}

	public movePoiUp(): void {
		if (this.pms.selectedPoi && this.pms.selectedPoi.position > 0) {
			const temp = this.pms.pois[this.pms.selectedPoi.position - 1];
			this.pms.pois[this.pms.selectedPoi.position - 1] = this.pms.pois[this.pms.selectedPoi.position];
			this.pms.pois[this.pms.selectedPoi.position] = temp;
			this.pms.updatePoiPositions();
		}
	}

	public movePoiDown(): void {
		if (this.pms.selectedPoi && this.pms.selectedPoi.position < this.pms.pois.length - 1) {
			const temp = this.pms.pois[this.pms.selectedPoi.position + 1];
			this.pms.pois[this.pms.selectedPoi.position + 1] = this.pms.pois[this.pms.selectedPoi.position];
			this.pms.pois[this.pms.selectedPoi.position] = temp;
			this.pms.updatePoiPositions();
		}
	}

	public removeImageDescription() {
		this.image.title = new MultiLanguageText();
		this.image.uiElements = [];
		this.checkForImageChange();
	}

	public async onSave(): Promise<any> {
		this.contextService.setSaveYourContent(false);
		let savingPois = this.pms.poisChanged ? true : false;
		if (savingPois || this.imageChanged) {
			this.contextService.setSavingContent(true);
		}
		if (this.imageChanged) {
			const saveImageSubscription = this.imageService.updateImage(this.image).subscribe(
				() => {
					this.originalImage = new Image(this.image);
					saveImageSubscription.unsubscribe();
					this.contextService.setSavingContent(false);
					this.imageChanged = false;
				}
			);
		}
		if (this.pms.poisChanged) {
			const savePoiSubsciption: Subscription = this.pms.saveChangedPois();
			savePoiSubsciption.add(
				() => {
					savingPois = false;
					if (!savingPois) {
						this.contextService.setSavingContent(false);
					}
					savePoiSubsciption.unsubscribe();
				}
			);
		}
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	public onDiscard(): void {
		if (this.imageChanged) {
			this.image = new Image(this.originalImage);
			this.imageChanged = false;
		}
		if (this.pms.poisChanged) {
			this.pms.resetCurrentPois();
			this.pms.reloadPoiLayer();
		}
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	public getMedia(): void {
		const sub = this.mediaService.getMediaById(this.image.mediaId).subscribe(media => {
			this.imageData = new FileDataType();
			this.imageData.mediaId = this.image.mediaId;
			this.imageData.media = new Media(media);
			const sub1 = this.mediaService.downloadFile(this.imageData.media.rawUrl).subscribe(
				blob => {
					const file = Tools.blobToFile(blob, 'image.jpg');
					this.imageData.file = file;
					this.imageData.fileSafeUrl = this.createImageUrl(file);
					sub1.unsubscribe();
				}
			);
			sub.unsubscribe();
		});
	}

	public routeToEditPoi(poi: Poi): void {
		this.router.navigate(['images/' + this.image.id + '/pois/' + poi.id]);
	}

	public routeToEditImageMeta() {
		this.pms.selectedPoi = null;
		this.router.navigate(['images/' + this.image.id + '/meta/']);
	}

	public openModal(modalTemplate: TemplateRef<any>): void {
		this.modalRef = this.modalService.show(modalTemplate);
	}

	public delete(): void {
		this.modalRef.hide();
		this.imageChanged = false;
		this.pms.poisChanged = false;
		this.contextService.setSavingContent(true);
		const sub = this.imageService.deleteImage(this.image).subscribe(
			() => {
				this.contextService.setSavingContent(false);
				this.cancel();
				sub.unsubscribe();
			}
		);
	}

	public cancel(): void {
		if (this.modalRef) { this.modalRef.hide(); }
		this.router.navigate(['exhibitions', this.image.exhibitionIds[0], 'images']);
	}

	public getLanguageValue(text: MultiLanguageText): string {
		return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
	}

	public getStyle(language: Language): Object {
		const style = {
			'filter': this.currentLanguage === language ? 'brightness(100%)' : 'brightness(50%)'
		};
		return style;
	}

	public setLanguage(language: Language): void {
		this.currentLanguage = language;
	}

	public isLanguage(language: Language): boolean {
		return language === this.currentLanguage;
	}

	public updateLanguage(language: Language): void {
		this.currentLanguage = language;
		setTimeout(() => {
			this.pms.reloadPoiLayer();
		});
	}

	private createImageUrl(file: File): SafeUrl {
		const urlCreator = window.URL;
		const url = urlCreator.createObjectURL(file);
		const safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
		return safeUrl;
	}

	public getPoiListEntryStyle(poi: Poi): Object {
		let borderStyle = '';
		if (poi.active) {
			borderStyle = (this.pms.selectedPoi !== null && this.pms.selectedPoi.id === poi.id) ? '2px solid #87ceeb' : '2px solid gainsboro';
		} else {
			borderStyle = (this.pms.selectedPoi !== null && this.pms.selectedPoi.id === poi.id) ? '2px solid rgba(255,0,0,0.3)' : '2px solid rgba(220,220,220,0.3)';
		}
		const style = {
			'border': borderStyle
		};
		return style;
	}

	public getPoiListTitleStyle(poi: Poi): Object {
		const style = {
			'opacity': poi.active ? '1' : '0.3'
		};
		return style;
	}

	public toggleActive(poi: Poi, event: MouseEvent): void {
		event.stopPropagation();
		poi.active = !poi.active;
		this.pms.checkForPoisChanged();
	}

	public toggleType(poi: Poi, event: MouseEvent): void {
		event.stopPropagation();

		const index = this.enabledContentTypes.indexOf(poi.contentType);
		poi.contentType = this.enabledContentTypes[(index + 1) % this.enabledContentTypes.length];

		this.pms.checkForPoisChanged();
	}

	public toggleTypeImage(image: Image, event: MouseEvent): void {
		event.stopPropagation();

		const index = this.enabledContentTypes.indexOf(image.contentType);
		image.contentType = this.enabledContentTypes[(index + 1) % this.enabledContentTypes.length];

		this.checkForImageChange();
	}

	public routeTo(appState: AppState): void {
		switch (appState) {
			case AppState.exhibitions:
				this.router.navigate(['exhibitions']);
				break;
			case AppState.images:
				this.router.navigate(['exhibitions', this.image.exhibitionIds[0], 'images']);
				break;
			default:
				break;
		}
	}
}
