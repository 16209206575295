import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { Config } from '../Config';

import { AlertService } from './alert.service';
import { StringService } from './string.service';
import { PushNotification } from '../models/PushNotification';
import { AlertWithBoldValueInMiddle, AlertType, AlertSimple } from '../models/Alert';
import { CurrentContextService } from './currentContext.service';
import { tap, share } from 'rxjs/operators';

/**
 * basic httpHeaders that will be used with every request
 */
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  observe: 'response' as 'body'
};

/**
 * The PushNotificationService handles all request to the PushNotification endpoint of the backend
 */
@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {

  /**
	 * the baseurl to reach the backend
	 */
  private baseUrl = new Config().GetBaseUrl();

  /**
	 * Creates an instance of the PushNotificationService
	 * @param http is used to send Request to the backend and process the response
	 * @param alertService is used to display relevant information to the user
	 * @param stringService the StringService is used to get strings in the correct Language, that are displayed to the user.
	 */
  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService,
    private contextService: CurrentContextService
  ) { }

  public getPushNotifications(): Observable<PushNotification[]> {
    return this.http.get<PushNotification[]>(this.getPushNotificationUrl()).pipe(
      tap(
        notifications => {
          const pushNotifications: PushNotification[] = [];
          for (const notification of notifications) {
            pushNotifications.push(new PushNotification(notification));
          }
          return pushNotifications;
        },
        error => { this.handleError<PushNotification[]>('getPushNotifications', error); }
      ),
      share()
    );
  }

  public createPushNotification(notification: PushNotification): Observable<PushNotification> {
    notification.date = Date.now();
    return this.http.post<any>(this.getPushNotificationUrl(), notification, httpOptions).pipe(
      tap(
        response => {
          const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
          notification.id = location.substring(location.lastIndexOf('/') + 1);
          if (this.contextService.getInCurrentAppLanguage(notification.title).length > 0) {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('PUSH_NOTIFICATION_CREATED_1'),
                this.contextService.getInCurrentAppLanguage(notification.title),
                this.stringService.get('PUSH_NOTIFICATION_CREATED_2'),
                AlertType.Info),
              true);
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('PUSH_NOTIFICATION_CREATED_1'),
                this.stringService.get('NO_TITLE_AVAILABLE'),
                this.stringService.get('PUSH_NOTIFICATION_CREATED_2'),
                AlertType.Info),
              true);
          }
        },
        error => {
          if (error.status === 412) {
            this.alertService.alert(
              new AlertSimple(this.stringService.get('PUSHNOTIFICATION_SEND_FAIL'), AlertType.Error),
              true);
          } else {
            this.handleError<PushNotification>('createPushNotification', error);
          }
        }
      ),
      share()
    );
  }

  public deletePushNotifications(notification: PushNotification): Observable<PushNotification[]> {
    return this.http.delete<PushNotification[]>(this.getPushNotificationUrl(`${notification.id}`), httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(
            new AlertSimple(
              this.stringService.get('PUSHNOTIFICATION_DELETED'),
              AlertType.Info),
            true);
        },
        error => { this.handleError<PushNotification[]>('deletePushNotifications', error); }
      ),
      share()
    );
  }

  public getPushNotificationSchedule(): Observable<PushNotification[]> {
    return this.http.get<PushNotification[]>(this.getPushNotificationUrl('-/schedule')).pipe(
      tap(
        response => { },
        error => { this.handleError<PushNotification[]>('getPushNotificationLimit', error); }
      ),
      share()
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   * @returns an errorObject or an Object of the given resultType
   */
  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  /**
	 * triggers when an httpError occurs
	 * is used to write an value to the console
	 * @param value the value to be written to the console
	 */
  private logError(value: any): void {
    console.error(value);
  }

  /**
	 * creates the url to the pushNotification endpoint of the backend
	 * @param extension can be added to extend the url with an suffix
	 * @returns returns the created url
	 */
  private getPushNotificationUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}push-notifications/`;
    if (extension) {
      if (!(extension.indexOf('?') >= 0)) {
        if (!extension.endsWith('/')) {
          extension = `${extension}/`;
        }
      }
      url = `${url}${extension}`;
    }
    return url;
  }

}

