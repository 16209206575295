import { ContentPage, GridMenu, ListMenu, TourMenu, ARMenu, Menu, MenuEntry } from './MenuElements';
import { MultiLanguageText } from './MultiLanguageText';
import { AudioElement, ElementType, ImageElement, ModelElement, TextElement, UiElement, VideoElement } from './UiElements';
import { ContentTypes } from './ContentTypes';
import { Config } from '../Config';
import { MapElement } from './GeoJson';
import { MultiLanguageMediaId } from './MultiLanguageMediaId';

export enum MenuStyle {
	LIST = 0,
	GRID = 1,
	CONTENT = 2,
	TOUR_ENTRY = 97,
	AR = 98,
	TOURS = 99
}

/**
 * Ugly ass data-structure that converts the retarded non-recursive
 * datastructure design of Menu / MenuEntry
 * into something recursive, so displaying it becomes feasible.
 */
export class AppMenu {
	public menuStyle: MenuStyle = MenuStyle.LIST;
	public menuId: string;
	public entryId: string;
	public title: MultiLanguageText = new MultiLanguageText();
	public mediaIdHeader: MultiLanguageMediaId = new MultiLanguageMediaId();
	public mediaIdEntry: MultiLanguageMediaId = new MultiLanguageMediaId();
	public position = -1;
	public showLogo = true;
	public childElements: AppMenu[] = [];
	public uiElements: UiElement[] = [];
	public url: MultiLanguageText = new MultiLanguageText();
	public contentType: ContentTypes = new Config().getEnabledContentTypes()[0];
	public depth = 0;
	public isMandatory: boolean = false;

	constructor(entry?: any) {
		if (entry) {
			if (entry instanceof AppMenu) {
				this.createFromAppMenu(<AppMenu>entry);
			} else if (entry instanceof Menu) {
				this.createFromMenu(<Menu>entry);
			} else if (entry instanceof MenuEntry) {
				this.createFromMenuEntry(<MenuEntry>entry);
			}
		}
	}

	private createFromMenuEntry(menuEntry: MenuEntry): void {
		this.entryId = menuEntry.id;
		this.mediaIdEntry = new MultiLanguageMediaId(menuEntry.mediaId);
		this.position = menuEntry.position;
		if (menuEntry.isMandatory !== undefined) {
			this.isMandatory = menuEntry.isMandatory;
		}
		this.title = new MultiLanguageText(menuEntry.title);
		if(menuEntry.href instanceof Menu) {
			this.menuId = menuEntry.href.id;
			this.showLogo = menuEntry.href.showLogo;
			this.mediaIdHeader = new MultiLanguageMediaId(menuEntry.href.mediaId);
			this.childElements = [];
			for (const entry of menuEntry.href.elements) {
				this.childElements.push(new AppMenu(entry));
			}
			this.childElements = this.childElements.sort((uie1, uie2) => uie1.position - uie2.position);

			if (menuEntry.href instanceof ListMenu) { this.menuStyle = MenuStyle.LIST; }
			else if (menuEntry.href instanceof GridMenu) { this.menuStyle = MenuStyle.GRID; }
			else if (menuEntry.href instanceof TourMenu) { this.menuStyle = MenuStyle.TOURS; this.childElements = []; }
			else if (menuEntry.href instanceof ARMenu) { this.menuStyle = MenuStyle.AR; this.childElements = []; }
		} else if (menuEntry.href instanceof ContentPage) {
			this.menuStyle = MenuStyle.CONTENT;
			this.url = (<ContentPage>menuEntry.href).url;
			this.contentType = (<ContentPage>menuEntry.href).contentType;
			this.showLogo = undefined;
			this.uiElements = [];
			for (const uiElement of (<ContentPage>menuEntry.href).uiElements) {
				switch (uiElement.type) {
					case ElementType.IMAGE: {
						this.uiElements.push(new ImageElement(<ImageElement>uiElement));
						break;
					}
					case ElementType.AUDIO: {
						this.uiElements.push(new AudioElement(<AudioElement>uiElement));
						break;
					}
					case ElementType.VIDEO: {
						this.uiElements.push(new VideoElement(<VideoElement>uiElement));
						break;
					}
					case ElementType.MODEL: {
						this.uiElements.push(new ModelElement(<ModelElement>uiElement));
						break;
					}
					case ElementType.TEXT: {
						this.uiElements.push(new TextElement(<TextElement>uiElement));
						break;
					}
					case ElementType.MAP: {
						this.uiElements.push(new MapElement(<MapElement>uiElement));
						break;
					}
				}
			}
			this.uiElements = this.uiElements.sort((uie1, uie2) => uie1.position - uie2.position);
		}
	}

	private createFromMenu(menu: Menu): void {
		if (menu instanceof ListMenu) {
			this.menuStyle = MenuStyle.LIST;
		} else if (menu instanceof GridMenu) {
			this.menuStyle = MenuStyle.GRID;
		} else if (menu instanceof TourMenu) {
			this.menuStyle = MenuStyle.TOURS;
		} else if (menu instanceof ARMenu) {
			this.menuStyle = MenuStyle.AR;
		}
		this.menuId = menu.id;
		this.showLogo = menu.showLogo;
		this.mediaIdHeader = new MultiLanguageMediaId(menu.mediaId);
		this.title = new MultiLanguageText();
		this.position = undefined;
		this.childElements = [];
		for (const menuEntry of menu.elements) {
			this.childElements.push(new AppMenu(menuEntry));
		}
		this.childElements = this.childElements.sort((uie1, uie2) => uie1.position - uie2.position);
	}

	private createFromAppMenu(appMenu: AppMenu): void {
		this.menuStyle = appMenu.menuStyle;
		this.menuId = appMenu.menuId;
		this.entryId = appMenu.entryId;
		this.depth = appMenu.depth;
		this.showLogo = appMenu.showLogo;
		this.title = new MultiLanguageText(appMenu.title);
		this.mediaIdHeader = new MultiLanguageMediaId(appMenu.mediaIdHeader);
		this.mediaIdEntry = new MultiLanguageMediaId(appMenu.mediaIdEntry);
		this.position = appMenu.position;
		this.contentType = appMenu.contentType;
		this.url = new MultiLanguageText(appMenu.url);
		this.childElements = [];
		for (const menu of appMenu.childElements) {
			this.childElements.push(new AppMenu(menu));
		}
		this.childElements = this.childElements.sort((uie1, uie2) => uie1.position - uie2.position);
		this.uiElements = [];
		for (const uiElement of appMenu.uiElements) {
			switch (uiElement.type) {
				case ElementType.IMAGE: {
					this.uiElements.push(new ImageElement(<ImageElement>uiElement));
					break;
				}
				case ElementType.AUDIO: {
					this.uiElements.push(new AudioElement(<AudioElement>uiElement));
					break;
				}
				case ElementType.VIDEO: {
					this.uiElements.push(new VideoElement(<VideoElement>uiElement));
					break;
				}
				case ElementType.MODEL: {
					this.uiElements.push(new ModelElement(<ModelElement>uiElement));
					break;
				}
				case ElementType.TEXT: {
					this.uiElements.push(new TextElement(<TextElement>uiElement));
					break;
				}
				case ElementType.MAP: {
					this.uiElements.push(new MapElement(<MapElement>uiElement));
					break;
				}
			}
			this.uiElements = this.uiElements.sort((uie1, uie2) => uie1.position - uie2.position);
		}
	}

	public equal(appMenu: AppMenu) {
		if (this.menuStyle !== appMenu.menuStyle) {
			//console.log('menuStyle', this.menuStyle, appMenu.menuStyle);
			return false;
		}
		if (
			this.menuId !== appMenu.menuId ||
			this.entryId !== appMenu.entryId ||
			this.position !== appMenu.position ||
			this.showLogo !== appMenu.showLogo ||
			this.depth !== appMenu.depth ||
			this.contentType !== appMenu.contentType ||

			this.mediaIdHeader !== undefined && appMenu.mediaIdHeader === undefined ||
			this.mediaIdHeader === undefined && appMenu.mediaIdHeader !== undefined ||

			this.mediaIdEntry !== undefined && appMenu.mediaIdEntry === undefined ||
			this.mediaIdEntry === undefined && appMenu.mediaIdEntry !== undefined
		) {
			//console.log('values', this, appMenu);
			return false;
		}
		if (
			this.mediaIdEntry !== undefined && !this.mediaIdEntry.equal(appMenu.mediaIdEntry) ||
			this.mediaIdHeader !== undefined && !this.mediaIdHeader.equal(appMenu.mediaIdHeader)
		) {
			//console.log('mediaDataEntry/Header', this.mediaIdEntry, this.mediaIdHeader)
			return false;
		}
		if (this.title && appMenu.title) {
			if (!this.title.equal(appMenu.title)) {
				//console.log('title', this.title, appMenu.title);
				return false;
			}
		} else if (this.title && !appMenu.title) {
			//console.log('title', this.title, appMenu.title);
			return false;
		} else if (!this.title && appMenu.title) {
			//console.log('title', this.title, appMenu.title);
			return false;
		}
		if (this.url && appMenu.url) {
			if (!this.url.equal(appMenu.url)) {
				//console.log('url', this.url, appMenu.url);
				return false;
			}
		} else if (this.url && !appMenu.url) {
			//console.log('url', this.url, appMenu.url);
			return false;
		} else if (!this.url && appMenu.url) {
			//console.log('url', this.url, appMenu.url);
			return false;
		}
		if (this.childElements && appMenu.childElements) {
			if (this.childElements.length !== appMenu.childElements.length) {
				//console.log('childElements', this.childElements, appMenu.childElements);
				return false;
			}
			for (let i = 0; i < this.childElements.length; i++) {
				if (
					this.childElements[i] && !appMenu.childElements[i] ||
					!this.childElements[i] && appMenu.childElements[i] ||
					!this.childElements[i].equal(appMenu.childElements[i])
				) {
					//console.log('childElements', this.childElements[i], appMenu.childElements[i]);
					return false;
				}
			}
		}
		if (this.uiElements && appMenu.uiElements) {
			if (this.uiElements.length !== appMenu.uiElements.length) {
				//console.log('uiElements', this.uiElements, appMenu.uiElements);
				return false;
			}
			for (let i = 0; i < this.uiElements.length; i++) {
				if (
					this.uiElements[i] && !appMenu.uiElements[i] ||
					!this.uiElements[i] && appMenu.uiElements[i] ||
					!this.uiElements[i].equal(appMenu.uiElements[i])
				) {
					//console.log('uiElements', this.uiElements[i], appMenu.uiElements[i]);
					return false;
				}
			}
		}
		return true;
	}

	public getAsMenu(): Menu {
		if (this.menuStyle === MenuStyle.LIST) {
			const listMenu = new ListMenu();
			listMenu.showLogo = this.showLogo;
			listMenu.mediaId = new MultiLanguageMediaId(this.mediaIdHeader);
			listMenu.id = this.menuId;
			for (const menu of this.childElements) {
				listMenu.elements.push(menu.getEntry());
			}
			return listMenu;
		} else if (this.menuStyle === MenuStyle.GRID) {
			const gridMenu = new GridMenu();
			gridMenu.showLogo = this.showLogo;
			gridMenu.mediaId = new MultiLanguageMediaId(this.mediaIdHeader);
			gridMenu.id = this.menuId;
			for (const menu of this.childElements) {
				gridMenu.elements.push(menu.getEntry());
			}
			return gridMenu;
		}
		return undefined;
	}

	private getEntry(): MenuEntry {
		const entry = new MenuEntry();
		entry.id = this.entryId;
		entry.mediaId = new MultiLanguageMediaId(this.mediaIdEntry);
		entry.position = this.position;
		entry.isMandatory = this.isMandatory;
		entry.title = new MultiLanguageText(this.title);
		if (this.menuStyle === MenuStyle.LIST) {
			const listMenu = new ListMenu();
			listMenu.showLogo = this.showLogo;
			listMenu.mediaId = new MultiLanguageMediaId(this.mediaIdHeader);
			listMenu.id = this.menuId;
			for (const menu of this.childElements) {
				listMenu.elements.push(menu.getEntry());
			}
			entry.href = listMenu;
		} else if (this.menuStyle === MenuStyle.GRID) {
			const gridMenu = new GridMenu();
			gridMenu.showLogo = this.showLogo;
			gridMenu.mediaId = new MultiLanguageMediaId(this.mediaIdHeader);
			gridMenu.id = this.menuId;
			for (const menu of this.childElements) {
				gridMenu.elements.push(menu.getEntry());
			}
			entry.href = gridMenu;
		} else if (this.menuStyle === MenuStyle.CONTENT) {
			const contentPage = new ContentPage();
			contentPage.contentType = this.contentType;
			contentPage.url = this.url;
			for (const uiElement of this.uiElements) {
				switch (uiElement.type) {
					case ElementType.IMAGE: {
						contentPage.uiElements.push(new ImageElement(<ImageElement>uiElement));
						break;
					}
					case ElementType.AUDIO: {
						contentPage.uiElements.push(new AudioElement(<AudioElement>uiElement));
						break;
					}
					case ElementType.VIDEO: {
						contentPage.uiElements.push(new VideoElement(<VideoElement>uiElement));
						break;
					}
					case ElementType.MODEL: {
						contentPage.uiElements.push(new ModelElement(<ModelElement>uiElement));
						break;
					}
					case ElementType.TEXT: {
						contentPage.uiElements.push(new TextElement(<TextElement>uiElement));
						break;
					}
					case ElementType.MAP: {
						contentPage.uiElements.push(new MapElement(<MapElement>uiElement));
						break;
					}
				}
			}
			contentPage.uiElements = contentPage.uiElements.sort((uie1, uie2) => uie1.position - uie2.position);
			entry.href = contentPage;
		} else if (this.menuStyle === MenuStyle.TOURS) {
			const tourMenu = new TourMenu();
			tourMenu.showLogo = this.showLogo;
			tourMenu.mediaId = new MultiLanguageMediaId(this.mediaIdHeader);
			tourMenu.id = this.menuId;
			entry.href = tourMenu;
		} else if (this.menuStyle === MenuStyle.AR) {
			const arMenu = new ARMenu();
			arMenu.showLogo = this.showLogo;
			arMenu.mediaId = new MultiLanguageMediaId(this.mediaIdHeader);
			arMenu.id = this.menuId;
			entry.href = arMenu;
		}
		return entry;
	}

	public cleanForUpload(): void {
		if (this.menuStyle === MenuStyle.CONTENT) {
			for (const uiElement of this.uiElements) {

				if (uiElement.type === ElementType.AUDIO) {
					const audioElement = (<AudioElement>uiElement);
					if (audioElement.audioObject !== undefined) {
						audioElement.audioObject.forEach((_l, obj) => obj.clean());
					}
				} else if (uiElement.type === ElementType.VIDEO) {
					const videoElement = (<VideoElement>uiElement);
					if (videoElement.videoObject !== undefined) {
						videoElement.videoObject.forEach((_l, obj) => obj.clean());
					}
				} else if (uiElement.type === ElementType.IMAGE) {
					const imageElement = (<ImageElement>uiElement);
					if (imageElement.imageObject !== undefined) {
						imageElement.imageObject.forEach((_l, obj) => obj.clean());
					}
				} else if (uiElement.type === ElementType.MODEL) {
					const modelElement = (<ModelElement>uiElement);
					if (modelElement.modelObject !== undefined) {
						modelElement.modelObject.forEach((_l, obj) => obj.clean());
					}
				}
			}
		} else {
			for (const child of this.childElements) {
				child.cleanForUpload();
			}
		}
	}
}
