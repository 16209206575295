<div class="content">
	<div class="menu-tree">
		<div class="row" *ngIf="this.fullAppMenu === undefined">
			<i>{{ 'CREATE_NEW_MENU' | translate }}</i>
			<div class="controlbuttons">
				<button class="iconbutton" (click)="addMenuEntry(MenuStyle.LIST)"
					[matTooltip]="'ADD_LIST_MENU' | translate" triggers="hover">
					<i class="fa fa-list" aria-hidden="true"></i>
				</button>
				<button class="iconbutton" (click)="addMenuEntry(MenuStyle.GRID)"
					[matTooltip]="'ADD_GRID_MENU' | translate" triggers="hover">
					<i class="fa fa-th-large" aria-hidden="true"></i>
				</button>
			</div>
		</div>

		<div class="row" *ngIf="this.fullAppMenu !== undefined">
			<span class="menuitem" *ngIf="this.fullAppMenu.menuStyle === MenuStyle.LIST"
				[ngStyle]="getSelectedStyle(this.fullAppMenu)" (click)="onItemSelect()">
				<i class="fa fa-list menuitem-icon" aria-hidden="true"></i> {{ 'LISTMENU' | translate }}
			</span>
			<span class="menuitem" *ngIf="this.fullAppMenu.menuStyle === MenuStyle.GRID"
				[ngStyle]="getSelectedStyle(this.fullAppMenu)" (click)="onItemSelect()">
				<i class="fa fa-th-large menuitem-icon" aria-hidden="true"></i> {{ 'GRIDMENU' | translate }}
			</span>

			<div class="addbuttons" *ngIf="this.fullAppMenu.depth < 4">
				<button class="iconbutton" (click)="addMenuEntry(MenuStyle.LIST, this.fullAppMenu)"
					[matTooltip]="'ADD_LIST_MENU' | translate" triggers="hover">
					<i class="fa fa-list" aria-hidden="true"></i>
				</button>
				<button class="iconbutton" (click)="addMenuEntry(MenuStyle.GRID, this.fullAppMenu)"
					[matTooltip]="'ADD_GRID_MENU' | translate" triggers="hover">
					<i class="fa fa-th-large" aria-hidden="true"></i>
				</button>
				<button class="iconbutton" (click)="addMenuEntry(MenuStyle.CONTENT, this.fullAppMenu)"
					[matTooltip]="'ADD_CONTENT_PAGE' | translate" triggers="hover">
					<i class="fa fa-file" aria-hidden="true"></i>
				</button>
			</div>
			<ng-template #recursiveList let-list>
				<div class="col">
					<div *ngFor="let menuEntry of list">
						<span [ngStyle]="getSelectedStyle(menuEntry)" (click)="onItemSelect(menuEntry)">
							<span [ngSwitch]="menuEntry.menuStyle">
								<span class="menuitem" *ngSwitchCase="MenuStyle.LIST">
									<i class="fa fa-list menuitem-icon" aria-hidden="true"></i>
									<span class="menuitem-title"
										*ngIf="this.getLanguageValue(menuEntry.title).length > 0">
										{{ this.getLanguageValue(menuEntry.title) }} </span>
									<span *ngIf="this.getLanguageValue(menuEntry.title).length === 0">
										{{ 'MENU_ENTRY' | translate }} </span>
									<!--{{ 'MENU_ENTRY_LISTMENU' | translate }}-->
								</span>
								<span class="menuitem" *ngSwitchCase="MenuStyle.GRID">
									<i class="fa fa-th-large menuitem-icon" aria-hidden="true"></i>
									<span class="menuitem-title"
										*ngIf="this.getLanguageValue(menuEntry.title).length > 0">
										{{ this.getLanguageValue(menuEntry.title) }} </span>
									<span *ngIf="this.getLanguageValue(menuEntry.title).length === 0">
										{{ 'MENU_ENTRY' | translate }} </span>
									<!--{{ 'MENU_ENTRY_GRIDMENU' | translate }}-->
								</span>
								<span class="menuitem" *ngSwitchCase="MenuStyle.TOURS">
									<i class="fa fa-map-marker menuitem-icon" aria-hidden="true"></i>
									<span class="menuitem-title"
										*ngIf="this.getLanguageValue(menuEntry.title).length > 0">
										{{ this.getLanguageValue(menuEntry.title) }} </span>
									<span *ngIf="this.getLanguageValue(menuEntry.title).length === 0">
										{{ 'MENU_ENTRY' | translate }} </span>
									<!--{{ 'MENU_ENTRY_GRIDMENU' | translate }}-->
								</span>
								<span class="menuitem tours-menuitem" *ngSwitchCase="MenuStyle.TOUR_ENTRY">
									<i class="fa fa-map tour-icon menuitem-icon" aria-hidden="true"
										[style.color]="this.getTourEntryStyleColor(menuEntry)"></i>
									<span class="menuitem-title"
										*ngIf="this.getLanguageValue(menuEntry.title).length > 0"
										[style.color]="this.getTourEntryStyleColor(menuEntry)">
										{{ this.getLanguageValue(menuEntry.title) }} </span>
									<span *ngIf="this.getLanguageValue(menuEntry.title).length === 0"
										[style.color]="this.getTourEntryStyleColor(menuEntry)">
										{{ 'MENU_ENTRY' | translate }} </span>
									<span class="special-item" (click)="this.routeToTour(menuEntry)"
										[matTooltip]="'NAVIGATE_TO_TOUR' | translate">
										<i class="fa fa-share" aria-hidden="true"></i>
									</span>
								</span>
								<span class="menuitem" *ngSwitchCase="MenuStyle.AR">
									<i class="fa fa-street-view menuitem-icon" aria-hidden="true"></i>
									<span class="menuitem-title"
										*ngIf="this.getLanguageValue(menuEntry.title).length > 0">
										{{ this.getLanguageValue(menuEntry.title) }} </span>
									<span *ngIf="this.getLanguageValue(menuEntry.title).length === 0">
										{{ 'MENU_ENTRY' | translate }} </span>
								</span>
								<span class="menuitem" *ngSwitchDefault>
									<i class="fa fa-file menuitem-icon" aria-hidden="true"></i>
									<span class="menuitem-title"
										*ngIf="this.getLanguageValue(menuEntry.title).length > 0">
										{{ this.getLanguageValue(menuEntry.title) }} </span>
									<span *ngIf="this.getLanguageValue(menuEntry.title).length === 0">
										{{ 'MENU_ENTRY' | translate }} </span>
									<!--{{ 'MENU_ENTRY_CONTENTPAGE' | translate }}-->
								</span>
							</span>
						</span>

						<div *ngIf="menuEntry.depth < 4 && menuEntry.menuStyle !== MenuStyle.CONTENT && menuEntry.menuStyle !== MenuStyle.AR && menuEntry.menuStyle !== MenuStyle.TOURS && menuEntry.menuStyle !== MenuStyle.TOUR_ENTRY"
							class="addbuttons">
							<div *ngIf="menuEntry.depth < 3">
								<button class="iconbutton" (click)="addMenuEntry(MenuStyle.LIST, menuEntry)"
									[matTooltip]="'ADD_LIST_MENU' | translate" triggers="hover">
									<i class="fa fa-list" aria-hidden="true"></i>
								</button>
								<button class="iconbutton" (click)="addMenuEntry(MenuStyle.GRID, menuEntry)"
									[matTooltip]="'ADD_GRID_MENU' | translate" triggers="hover">
									<i class="fa fa-th-large" aria-hidden="true"></i>
								</button>
							</div>
							<button class="iconbutton" (click)="addMenuEntry(MenuStyle.CONTENT, menuEntry)"
								[matTooltip]="'ADD_CONTENT_PAGE' | translate" triggers="hover">
								<i class="fa fa-file" aria-hidden="true"></i>
							</button>
						</div>
						<ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: menuEntry.childElements }">
						</ng-container>
					</div>
				</div>
			</ng-template>
			<ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: this.fullAppMenu.childElements }">
			</ng-container>
		</div>
		<div class="control-buttons">
			<div class="control-button">
				<button type="submit" class="btn btn-light" (click)="moveMenuEntryUp()"
					[disabled]="this.currentAppMenu == undefined || !this.canMoveMenuEntryUp()"
					[matTooltip]="'MOVE_ELEMENT_UP' | translate">
					<i class="fa fa-angle-up" aria-hidden="true"></i>
				</button>
			</div>
			<div class="control-button">
				<button type="submit" class="btn btn-light" (click)="moveMenuEntryDown()"
					[disabled]="this.currentAppMenu == undefined || !this.canMoveMenuEntryDown()"
					[matTooltip]="'MOVE_ELEMENT_DOWN' | translate">
					<i class="fa fa-angle-down" aria-hidden="true"></i>
				</button>
			</div>
			<div class="control-button">
				<button type="submit" class="btn btn-light"
					[disabled]="this.currentAppMenu && this.currentAppMenu.isMandatory" (click)="deleteMenuEntry()"
					[matTooltip]="'DELETE_ELEMENT' | translate">
					<i class="fa fa-trash" aria-hidden="true"></i>
				</button>
			</div>
		</div>
	</div>
	<div class="menu-entry-content">
		<div class="header">
			<div class="arlayer-title-edit-container">
				<h1 class="edit-indicator">
					<i class="fa fa-pencil" aria-hidden="true"
						[ngStyle]="{'color': contentChanged ? '#ffc107' : 'black' }"></i>
				</h1>
				<div class="arlayer-title-container">
					<h1 class="arlayer-title">
						{{this.getAppMenuTitle(this.currentAppMenu, '', '', 'APP_MENU' | translate )}}
					</h1>
				</div>
			</div>
			<aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
				(languageChanged)="this.updateLanguage($event)">
			</aacms-language-selector>
		</div>
		<div class="menu-entry-content-container"
			*ngIf="this.currentAppMenu && this.menuVisible && this.currentAppMenu.menuStyle !== MenuStyle.TOUR_ENTRY">
			<tabset [justified]="false">
				<tab [heading]="this.getTabHeaderName()" class="settings-tab tab"
					(selectTab)="this.currentTab = tabType.GeneralTab">
					<div class="settings-tab-content">
						<div class="left">
							<div
								*ngIf="this.currentAppMenu.menuStyle !== MenuStyle.CONTENT && this.currentAppMenu.menuStyle !== MenuStyle.TOURS && this.currentAppMenu.menuStyle !== MenuStyle.AR">
								<div *ngIf="this.currentAppMenu.menuStyle === MenuStyle.LIST">
									{{ 'HEADERIMAGE_FOR_LIST_MENU' | translate }}</div>
								<div *ngIf="this.currentAppMenu.menuStyle === MenuStyle.GRID">
									{{ 'HEADERIMAGE_FOR_GRID_MENU' | translate }}</div>

								<manage-media2 [elementType]="ElementType.IMAGE"
									[mediaLoader]="this.mediaLoader.getLoader()" [currentLanguage]="this.currentLanguage"
									[mediaData]="this.currentAppMenu.mediaIdHeader"
									[maxFileSize]="config.getMenuHeaderImageFileSize()"
									(mediaChanged)="this.checkForChange()"
								>
									<div class="hasNone"></div>
									<div class="hasFallbackMedia"></div>
									<div class="hasMedia"></div>
								</manage-media2>
								<div class="checkbox-container">
									<label class="checkbox">
										<input type="checkbox" (click)="toggleShowLogo()"
											[checked]="this.currentAppMenu.showLogo">
										<span class="checkmark" triggers="hover" placement="bottom"></span>
										<div class="checkbox-text">
											{{ 'SHOW_LOGO' | translate }}
										</div>
									</label>
								</div>
								<div class="my-2">
									{{ 'TYPE_OF_MENU_ENTRY' | translate }}
									<div class="selection-container">
										<select class="selection" [(ngModel)]="this.currentAppMenu.menuStyle"
											(change)="this.checkForChange()">
											<option *ngFor="let style of getMenuStylesAsArray()" [ngValue]="style">
												{{getStringNameForMenuStyle(style)}}</option>
										</select>
									</div>
								</div>
							</div>
							<div *ngIf="this.parentAppMenu !== undefined">
								<div *ngIf="this.currentAppMenu.menuStyle === MenuStyle.LIST">
									{{ 'TITEL_OF_LIST_MENUENTRY' | translate }}</div>
								<div *ngIf="this.currentAppMenu.menuStyle === MenuStyle.GRID">
									{{ 'TITEL_OF_GRID_MENUENTRY' | translate }}</div>
								<div *ngIf="this.currentAppMenu.menuStyle === MenuStyle.CONTENT">
									{{ 'TITEL_OF_CONTENT_MENUENTRY' | translate }}</div>
								<div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
									<input type="text" class="form-control textInput"
										[(ngModel)]='this.currentAppMenu.title.de'
										maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
										(ngModelChange)="checkForChange()" />
									<div class="languageIndicator">
										<img class="language-button-image" src="../assets/flags/de.svg">
									</div>
									<div class="align-self-center px-2">
										{{this.config.getMaxTitleTextLength() - this.currentAppMenu.title.de.length}}
									</div>
								</div>
								<div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
									<input ctype="text" class="form-control textInput"
										[(ngModel)]='this.currentAppMenu.title.gb'
										maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
										(ngModelChange)="checkForChange()" />
									<div class="languageIndicator">
										<img class="language-button-image" src="../assets/flags/gb.svg">
									</div>
									<div class="align-self-center px-2">
										{{this.config.getMaxTitleTextLength() - this.currentAppMenu.title.gb.length}}
									</div>
								</div>
								<div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
									<input type="text" class="form-control textInput"
										[(ngModel)]='this.currentAppMenu.title.jp'
										maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
										(ngModelChange)="checkForChange()" />
									<div class="languageIndicator">
										<img class="language-button-image" src="../assets/flags/jp.svg">
									</div>
									<div class="align-self-center px-2">
										{{this.config.getMaxTitleTextLength() - this.currentAppMenu.title.jp.length}}
									</div>
								</div>
								<div *ngIf="this.currentAppMenu.menuStyle === MenuStyle.LIST">
									{{ 'IMAGE_OF_LIST_MENUENTRY' | translate }}
									{{this.getAppMenuTitle(this.parentAppMenu, '\"','\"')}}</div>
								<div *ngIf="this.currentAppMenu.menuStyle === MenuStyle.GRID">
									{{ 'IMAGE_OF_GRID_MENUENTRY' | translate }}
									{{this.getAppMenuTitle(this.parentAppMenu, ' \"','\"')}}</div>
								<div *ngIf="this.currentAppMenu.menuStyle === MenuStyle.CONTENT">
									{{ 'IMAGE_OF_CONTENT_MENUENTRY' | translate }}
									{{this.getAppMenuTitle(this.parentAppMenu, ' \"','\"')}}</div>

								<manage-media2 [elementType]="ElementType.IMAGE"
									[mediaLoader]="this.mediaLoader.getLoader()" [currentLanguage]="this.currentLanguage"
									[mediaData]="this.currentAppMenu.mediaIdEntry"
									[maxFileSize]="config.getMenuEntryImageFileSize()"
									(mediaChanged)="this.checkForChange()"
								>
									<div class="hasNone"></div>
									<div class="hasFallbackMedia"></div>
									<div class="hasMedia"></div>
								</manage-media2>
							</div>
							<div
								*ngIf="this.currentAppMenu.menuStyle === MenuStyle.CONTENT && this.showContentTypesSelection()">
								{{ 'TYPE_OF_CONTENT_PAGE' | translate }}
								<div class="selection-container">
									<select class="selection" [(ngModel)]="this.currentAppMenu.contentType"
										(change)="this.checkForChange()">
										<ng-template ngFor let-type [ngForOf]="this.config.getEnabledContentTypes()">
											<option role="selection-item" [ngValue]="this.type">
												{{ this.getStringNameForContentType(this.type) }}
											</option>
										</ng-template>
									</select>
								</div>
							</div>
						</div>
						<div class="right">
							<div *ngIf="this.currentAppMenu.menuStyle !== MenuStyle.CONTENT">
								<div class="smartphone-row">
									<div [ngStyle]="getSmartphoneStyle()" class="smartphone">
										<div class="nav-button-container">
											<div class="nav-button back" *ngIf="this.parentAppMenu"
												(click)="onNavigateBack()">
												<i class="fa fa-arrow-left" aria-hidden="true"></i>
											</div>
											<div class="nav-button close" *ngIf="!this.parentAppMenu">
												<i class="fa fa-times" aria-hidden="true"></i>
											</div>
										</div>
										<div class="listMenu" *ngIf="this.currentAppMenu.menuStyle === MenuStyle.LIST">
											<div class="listMenuHeaderImageContainer" *ngIf="isMediaLoaded(this.currentAppMenu.mediaIdHeader)">
												<img class="listMenuHeaderImage"
													[ngStyle]="getListMenuHeaderImageStyle()"
													*ngIf="isMediaLoaded(this.currentAppMenu.mediaIdHeader)"
													[src]="getMediaDataForId(this.currentAppMenu.mediaIdHeader)?.getThumbOrRawUrl()">
												<aacms-logo *ngIf="this.currentAppMenu.showLogo" class="logo"></aacms-logo>
											</div>
											<aacms-loader class="listMenuHeaderImageLoaderContainer" size="128px"
												*ngIf="hasMedia(this.currentAppMenu.mediaIdHeader) && !isMediaLoaded(this.currentAppMenu.mediaIdHeader)">
											</aacms-loader>
											<ng-template ngFor let-childElement
												[ngForOf]="this.currentAppMenu.childElements">
												<div class="listEntry" (click)="onItemSelect(childElement)">
													<span class="text">{{ this.getLanguageValue(childElement.title) }}</span>
													<span class="spacer"></span>
													<div class="listEntryImageContainer"
														*ngIf="hasMedia(childElement.mediaIdEntry) && isMediaLoaded(childElement.mediaIdEntry)">
														<img class="listEntryImage"
															[src]="getMediaDataForId(childElement.mediaIdEntry)?.getThumbOrRawUrl()">
													</div>
													<aacms-loader class="LoaderContainer" size="32px"
														*ngIf="hasMedia(childElement.mediaIdEntry) && !isMediaLoaded(childElement.mediaIdEntry)">
													</aacms-loader>
												</div>
											</ng-template>
										</div>
										<div class="gridMenu"
											*ngIf="this.currentAppMenu.menuStyle === MenuStyle.GRID || this.currentAppMenu.menuStyle === MenuStyle.TOURS">
											<div class="gridMenuHeaderImageContainer"
												*ngIf="isMediaLoaded(this.currentAppMenu.mediaIdHeader)">
												<img class="gridMenuHeaderImage"
													[ngStyle]="getListMenuHeaderImageStyle()"
													*ngIf="isMediaLoaded(this.currentAppMenu.mediaIdHeader)"
													[src]="getMediaDataForId(this.currentAppMenu.mediaIdHeader)?.getThumbOrRawUrl()">
												<aacms-logo class="logo"></aacms-logo>
											</div>
											<aacms-loader class="gridMenuHeaderImageLoaderContainer" size="128px"
												*ngIf="hasMedia(this.currentAppMenu.mediaIdHeader) && !isMediaLoaded(this.currentAppMenu.mediaIdHeader)">
											</aacms-loader>
											<ng-template ngFor let-childElement
												[ngForOf]="this.currentAppMenu.childElements">
												<div class="gridEntry" (click)="onItemSelect(childElement)">
													<div class="gridEntryImageContainer"
														*ngIf="hasMedia(childElement.mediaIdEntry) && isMediaLoaded(childElement.mediaIdEntry)">
														<img class="gridEntryImage"
															[src]="getMediaDataForId(childElement.mediaIdEntry)?.getThumbOrRawUrl()">
													</div>
													<span class="text"
														*ngIf="this.getLanguageValue(childElement.title)">{{
														this.getLanguageValue(childElement.title) }}</span>
													<aacms-loader class="LoaderContainer" size="64px"
														*ngIf="hasMedia(childElement.mediaIdEntry) && !isMediaLoaded(childElement.mediaIdEntry)">
													</aacms-loader>
												</div>
											</ng-template>
										</div>
									</div>
								</div>
								<div class="hint">
									<i>{{ 'PREVIEW_MAY_LOOK_DIFFERENT' | translate }}</i>
								</div>
								<div class="screen-size-button-row">
									<div class="screen-size-button-group btn-group" btnRadioGroup
										[(ngModel)]="currentSelectedResolution">
										<label class="btn btn-light" btnRadio="small" uncheckable tabindex="0"
											role="button" (click)="changeSmartphoneStyle('14','7')">{{'SMALL_PHONE' |
											translate}}</label>
										<label class="btn btn-light" btnRadio="big" uncheckable tabindex="0"
											role="button" (click)="changeSmartphoneStyle('16','8')">{{'BIG_PHONE' |
											translate}}</label>
										<label class="btn btn-light" btnRadio="tablet" uncheckable tabindex="0"
											role="button" (click)="changeSmartphoneStyle('16','11')">{{'TABLET' |
											translate}}</label>
									</div>
									<div class="screen-rotate-button">
										<button type="button" class="btn btn-light" (click)="rotateSmartphone()">
											<img class="screen-rotate-button-image"
												src="../../assets/screen_rotation-24px.svg" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</tab>
				<tab heading="{{'MENU_POINT_CONTENT' | translate}}" class="content-tab tab"
					(selectTab)="this.currentTab = tabType.UiElementsTab"
					*ngIf="this.currentAppMenu.menuStyle === MenuStyle.CONTENT && this.isTabAllowed(contentTypes.Custom)">
					<aacms-content-page [(uiElements)]="this.currentAppMenu.uiElements"
						(uiElementsChange)="checkForChange()" [language]="this.currentLanguage" [showBackButton]="true"
						(onBackButton)="onNavigateBack()" [isVisible]="currentTab === tabType.UiElementsTab">
					</aacms-content-page>
				</tab>
				<tab heading="{{'MENU_POINT_URL' | translate}}" class="url-tab tab"
					(selectTab)="this.currentTab = tabType.UrlTab"
					*ngIf="this.currentAppMenu.menuStyle === MenuStyle.CONTENT && this.isTabAllowed(contentTypes.URL)">
					<aacms-url-page [url]="this.currentAppMenu.url" (urlChange)="checkForChange()"
						[language]="this.currentLanguage" [showBackButton]="true" (onBackButton)="onNavigateBack()">
					</aacms-url-page>
				</tab>
			</tabset>
		</div>
		<div class="menu-entry-content-container"
			*ngIf="this.currentAppMenu && this.menuVisible && this.currentAppMenu.menuStyle === MenuStyle.TOUR_ENTRY">
			<tabset [justified]="false">
				<tab [heading]="this.getTabHeaderName()" class="settings-tab tab"
					(selectTab)="this.currentTab = tabType.GeneralTab">
					<div class="settings-tab-content">
						<div class="left">
							{{ 'IMAGE_OF_MENUENTRY' | translate }}

							<manage-media2 [elementType]="ElementType.IMAGE"
								[mediaLoader]="this.mediaLoader.getLoader()" [currentLanguage]="this.currentLanguage"
								[mediaData]="this.currentAppMenu.mediaIdEntry"
								[maxFileSize]="config.getMenuEntryImageFileSize()"
								(mediaChanged)="this.checkForChange()"
							>
								<div class="hasNone"></div>
								<div class="hasFallbackMedia"></div>
								<div class="hasMedia"></div>
							</manage-media2>
						</div>
						<div class="right">

						</div>
					</div>
				</tab>
			</tabset>
		</div>
		<div class="button-row row">
			<button type="submit" class="btn btn-warning custom-button" (click)="openModal(DiscardModalTemplate)"
				[disabled]="!this.contentChanged">{{'DISCARD' | translate }}</button>
			<button type="submit" class="btn btn-success custom-button" (click)="onSave()"
				[disabled]="!this.contentChanged">{{'SAVE' | translate }}</button>
			<button *ngIf="isAdmin()" type="submit" class="btn btn-primary custom-button"
				(click)="openModal(PublishModalTemplate)" [disabled]="this.contentChanged">{{'PUBLISH' | translate
				}}</button>
		</div>
	</div>
</div>

<ng-template #DiscardModalTemplate>
	<div class="modal-header">
		<h4 class="modal-title text-break pull-left"> {{'REALLY_DISCARD' | translate }} </h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-footer">
		<button (click)="modalRef.hide()" type="button" class="btn btn-light">{{'NO_ABORT' | translate }}</button>
		<button (click)="onDiscard()" type="button" class="btn btn-warning">{{'YES_DISCARD' | translate}}</button>
	</div>
</ng-template>

<ng-template #PublishModalTemplate>
	<div class="modal-header">
		<h4 class="modal-title text-break pull-left"> {{'PUBLISH_MENU' | translate }} </h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-footer">
		<button (click)="modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
		<button (click)="onPublish()" type="button" class="btn btn-success">{{'YES_UPLOAD' | translate}}</button>
	</div>
</ng-template>