import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularResizeEventModule } from 'angular-resize-event';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AdminComponent } from './admin-page/admin.component';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth.interceptor';
import { ContentPageComponent } from './content-page/content-page.component';
import { PopupPageComponent } from './popup-page/popup-page.component';
import { CuboidQuickViewerComponent } from './cuboid-quick-viewer/cuboid-quick-viewer.component';
import { CuboidViewerComponent } from './cuboid-viewer/cuboid-viewer.component';
import { DropinComponent } from './dropin/dropin.component';
import { EditArLayerMetaComponent } from './edit-ar-layer-meta/edit-ar-layer-meta.component';
import { EditArLayerPoiComponent } from './edit-ar-layer-poi/edit-ar-layer-poi.component';
import { EditArLayerComponent } from './edit-ar-layer/edit-ar-layer.component';
import { EditCuboidMetaComponent } from './edit-cuboid-meta/edit-cuboid-meta.component';
import { EditCuboidComponent } from './edit-cuboid/edit-cuboid.component';
import { EditImageMetaComponent } from './edit-image-meta/edit-image-meta.component';
import { EditImageComponent } from './edit-image/edit-image.component';
import { EditPoiComponent } from './edit-poi/edit-poi.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ExhibitionFilterPipe } from './exhibitions/exhibition.filter.pipe';
import { ExhibitionsComponent } from './exhibitions/exhibitions.component';
import { AdminGuard } from './guards/admin-guard';
import { AuthGuard } from './guards/auth-guard';
import { FeatureGuard } from './guards/feature-guard';
import { PendingChangesGuard } from './guards/pending-changes-guard';
import { ImagesComponent } from './images/images.component';
import { TrackersFilterPipe } from './images/images.filter.pipe';
import { InfoCardEditorComponent } from './info-card-editor/info-card-editor.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { LoginComponent } from './login/login.component';
import { ManageMediaComponent } from './manage-media/manage-media.component';
import { ConfirmationModalComponent } from './modals/confirmation/confirmation-modal.component';
import { DeactivateExhibitonModal } from './modals/exhibition/deactivate.modal.component';
import { DeleteExhibitonModal } from './modals/exhibition/delete.modal.component';
import { MultiUploadImagesModal } from './modals/image/multiUpload.modal.component';
import { PoiOrderPipe } from './orderby.pipe';
import { AlphabeticalOrderPipe } from './poi-textures-manager/alphabetical-order.pipe';
import { PageNotFoundComponent } from './page-not-found.component';
import { PoiLayerComponent } from './poi-layer/poi-layer.component';
import { PoiTexturesManagerComponent } from './poi-textures-manager/poi-textures-manager.component';
import { POIComponent } from './poi/poi.component';
import { PushNotificationComponent } from './push-notification/push-notification.component';
import { AeSelectComponent } from './richtext-editor/ae-select/ae-select.component';
import { RichtextEditorToolbarComponent } from './richtext-editor/richtext-editor-toolbar.component';
import { RichtextEditorComponent } from './richtext-editor/richtext-editor.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { TranslatePipe } from './translate.pipe';
import { TranslateWithPipe } from './translateWith.pipe';
import { UrlPageComponent } from './url-page/url-page.compoent';
import { AlertService } from './services/alert.service';
import { AppMenuService } from './services/app-menu.service';
import { ArLayerService } from './services/arLayer.service';
import { AuthService } from './services/auth.service';
import { CuboidService } from './services/cuboid.service';
import { CurrentContextService } from './services/currentContext.service';
import { ExhibitionService } from './services/exhibition.service';
import { ImageService } from './services/image.service';
import { PoiManagerService } from './services/poi-manager.service';
import { PoiTextureService } from './services/poi-texture.service';
import { PushNotificationService } from './services/pushNotification.service';
import { UserService } from './services/user.service';
import { MediaService } from './services/media.service';
import { PoiService } from './services/poi.service';
import { StringService } from './services/string.service';
import { PoiStyleUploadModal } from './modals/poi-style-upload/poi-style-upload.modal.component';
import { ToursComponent } from './tours/tours.component';
import { EditTourComponent } from './edit-tour/edit-tour.component';
import { GPXParserService } from './services/gpx.parser.service';
import { GeoJsonParserService } from './services/geoJson.parser.service';
import { TourService } from './services/tour.service';
import { ToursFilterPipe } from './tours/tours.filter.pipe';
import { EditTourMetaComponent } from './edit-tour-meta/edit-tour-meta.component';
import { MultiUploadToursModal } from './modals/tours-upload/tours-multi-upload.modal.component';
import { MultiUploadHologramsUploadModal } from './modals/hologram-upload/hologram-upload.modal.component';
import { GeoJsonFeatureFilterPipe } from './edit-tour/geojsonfeature.filter.pipe';
import { TaskService } from './services/task.service';
import { MaintainanceComponent } from './maintainance/maintainance.component';
import { LockService } from './services/lock.service';
import { ResourceGuard } from './guards/resource-guard';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { EditPlaceComponent } from './edit-place/edit-place.component';
import { EditPlaceMetaComponent } from './edit-place-meta/edit-place-meta.component';
import { PlaceService } from './services/place.service';
import { InfoCardService } from './services/infoCard.service';
import { AutonumericModule } from '@angularfy/autonumeric';
import { EditInfoCardMetaComponent } from './edit-info-card/edit-info-card.component';
import { TrackerSelectionModal } from './modals/tracker-selection/tracker-selection.modal.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TrackerService } from './services/tracker.service';
import { LocationNotifyEditorComponent } from './location-notify-editor/location-notify-editor.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditHologramComponent } from './edit-hologram/edit-hologram.component';
import { EditHologramMetaComponent } from './edit-hologram-meta/edit-hologram-meta.component';
import { EditHologramCardMetaComponent } from './edit-hologram-card/edit-hologram-card.component';
import { InputNumberComponent } from './controls/input-number/input-number.component';
import { LeafletMapComponent } from './controls/leaflet-map/leaflet-map.component';
import { InputTriggerBehaviorComponent } from './controls/trigger-behavior-selector/input-trigger-behavior.component';
import { ManageMedia2Component } from './controls/manage-media2/manage-media2.component';
import { MediaViewerComponent } from './controls/media-viewer/media-viewer.component';
import { UserConfigService } from './services/userConfig.service';
import { LoaderComponent } from './controls/loader/loader.component';
import { AppLogoComponent } from './controls/app-logo/app-logo';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularResizeEventModule,
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    SortableModule.forRoot(),
    CollapseModule.forRoot(),
    AlertModule.forRoot(),
    ProgressbarModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    ButtonsModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    AutonumericModule.forRoot(),
    MatTooltipModule
  ],
  declarations: [
    AppComponent,
    ToolbarComponent,
    AdminComponent,
    ImagesComponent,
    ExhibitionsComponent,
    PoiLayerComponent,
    LoginComponent,
    DropinComponent,
    EditImageComponent,
    EditHologramComponent,
    EditHologramCardMetaComponent,
    EditHologramMetaComponent,
    EditCuboidComponent,
    EditCuboidMetaComponent,
    EditArLayerComponent,
    EditArLayerMetaComponent,
    EditArLayerPoiComponent,
    EditImageMetaComponent,
    CuboidViewerComponent,
    CuboidQuickViewerComponent,
    EditPoiComponent,
    PushNotificationComponent,
    AppMenuComponent,
    ContentPageComponent,
    PopupPageComponent,
    UrlPageComponent,
    InfoCardEditorComponent,
    LanguageSelectorComponent,
    ManageMediaComponent,
    ManageMedia2Component,
    MediaViewerComponent,
    EditUserComponent,
    PageNotFoundComponent,
    POIComponent,
    PoiTexturesManagerComponent,
    DeleteExhibitonModal,
    DeactivateExhibitonModal,
    ConfirmationModalComponent,
    MultiUploadImagesModal,
    MultiUploadToursModal,
    MultiUploadHologramsUploadModal,
    TrackerSelectionModal,
    TrackersFilterPipe,
    ExhibitionFilterPipe,
    RichtextEditorComponent,
    RichtextEditorToolbarComponent,
    AeSelectComponent,
    LoaderComponent,
    ToursComponent,
    EditTourComponent,
    EditTourMetaComponent,
    MaintainanceComponent,
    EditPlaceComponent,
    EditPlaceMetaComponent,
    EditInfoCardMetaComponent,
    LocationNotifyEditorComponent,
    TranslatePipe,
    TranslateWithPipe,
    PoiOrderPipe,
    AlphabeticalOrderPipe,
    PoiStyleUploadModal,
    ToursFilterPipe,
    GeoJsonFeatureFilterPipe,
    TermsOfUseComponent,
    InputNumberComponent,
    InputTriggerBehaviorComponent,
    LeafletMapComponent,
    AppLogoComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthGuard,
    AdminGuard,
    FeatureGuard,
    PendingChangesGuard,
    ResourceGuard,
    AlertService,
    AppMenuService,
    ArLayerService,
    AuthService,
    CuboidService,
    CurrentContextService,
    ExhibitionService,
    ImageService,
    MediaService,
    PoiManagerService,
    PoiTextureService,
    PoiService,
    PushNotificationService,
    StringService,
    UserService,
    TaskService,
    LockService,
    GPXParserService,
    GeoJsonParserService,
    TaskService,
    TourService,
    PlaceService,
    InfoCardService,
    TrackerService,
    ToursFilterPipe,
    UserConfigService
  ],
  entryComponents: [
    POIComponent,
    DeleteExhibitonModal,
    DeactivateExhibitonModal,
    MultiUploadImagesModal,
    MultiUploadToursModal,
    PoiStyleUploadModal,
    TrackerSelectionModal,
    ConfirmationModalComponent
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})


export class AppModule {
}
