import { Component, Output, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Language } from '../models/Language';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertType, AlertWithBoldValueInMiddle } from '../models/Alert';
import { StringService } from '../services/string.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'aacms-url-page',
  templateUrl: './url-page.component.html',
  styleUrls: ['./url-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrlPageComponent {

  @Input() url: MultiLanguageText;
  @Output() urlChange: EventEmitter<MultiLanguageText> = new EventEmitter<MultiLanguageText>();
  @Input() language: Language;
  @Input() showBackButton: boolean;
  @Output() onBackButton: EventEmitter<void> = new EventEmitter<void>();

  public urlToDisplay = {
    de: null,
    gb: null,
    jp: null
  };

  public Language = Language;

  public currentSelectedResolution = 'big';
  private smartphoneMultiplier = 2.7;
  private smartphoneHeight = 16;
  private smartphoneWidth = 8;
  private portraitMode = true;

  private regex = new RegExp('^(?:(?:https?|ftp):\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!10(?:\\.\\d{1,3}){3})(?!127(?:\\.\\d{1,3}){3})(?!169\\.254(?:\\.\\d{1,3}){2})(?!192\\.168(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\x{00a1}\\-\\x{ffff}0-9]+-?)*[a-z\\x{00a1}\\-\\x{ffff}0-9]+)(?:\\.(?:[a-z\\x{00a1}\\-\\x{ffff}0-9]+-?)*[a-z\\x{00a1}\\-\\x{ffff}0-9]+)*(?:\\.(?:[a-z\\x{00a1}\\-\\x{ffff}]{2,})))(?::\\d{2,5})?(?:\\/[^\\s]*)?$');

  constructor(
    private alertService: AlertService,
    private stringService: StringService,
    private sanitizer: DomSanitizer
  ) {

  }

  public ngOnInit() {
    this.urlToDisplay.de = this.sanitizer.bypassSecurityTrustResourceUrl(this.url.de);
    this.urlToDisplay.gb = this.sanitizer.bypassSecurityTrustResourceUrl(this.url.gb);
    this.urlToDisplay.jp = this.sanitizer.bypassSecurityTrustResourceUrl(this.url.jp);
  }

  public emitUrlChange(): void {
    this.urlChange.emit(this.url);
  }

  public updateUrl(): void {
    if (this.checkUrl(this.url.de, Language.DE)) {
      this.urlToDisplay.de = this.sanitizer.bypassSecurityTrustResourceUrl(this.url.de);
    }
    if (this.checkUrl(this.url.gb, Language.GB)) {
      this.urlToDisplay.gb = this.sanitizer.bypassSecurityTrustResourceUrl(this.url.gb);
    }
    if (this.checkUrl(this.url.jp, Language.JP)) {
      this.urlToDisplay.jp = this.sanitizer.bypassSecurityTrustResourceUrl(this.url.jp);
    }
  }

  public isLanguage(language: Language): boolean {
    return language === this.language;
  }

  public changeSmartphoneStyle(height, width): void {
    this.smartphoneHeight = height;
    this.smartphoneWidth = width;
  }

  public rotateSmartphone(): void {
    this.portraitMode = !this.portraitMode;
  }

  public getSmartphoneStyle() {
    const width = (this.smartphoneWidth * this.smartphoneMultiplier) + 'rem';
    const height = (this.smartphoneHeight * this.smartphoneMultiplier) + 'rem';
    const portraitBorder = '20px 10px 40px 10px';
    const landscapeBorder = '10px 40px 10px 20px';

    const smartphoneStyle = {
      'height': this.portraitMode ? height : width,
      'width': this.portraitMode ? width : height,
      'border-width': this.portraitMode ? portraitBorder : landscapeBorder
    };

    return smartphoneStyle;
  }

  public getSafeUrl() {
    switch (this.language) {
      case this.Language.DE: {
        return this.urlToDisplay.de;
      }
      case this.Language.GB: {
        return this.urlToDisplay.gb;
      }
      case this.Language.JP: {
        return this.urlToDisplay.jp;
      }
    }
  }

  public startsWithHttp(): boolean {
    switch (this.language) {
      case this.Language.DE: {
        return this.url.de.startsWith('http://');
      }
      case this.Language.GB: {
        return this.url.gb.startsWith('http://');
      }
      case this.Language.JP: {
        return this.url.jp.startsWith('http://');
      }
    }
  }

  private checkUrl(valueToCheck: string, language: Language): boolean {
    if (valueToCheck.length > 0 &&
      !valueToCheck.includes(':') &&
      !valueToCheck.startsWith('https://') &&
      !valueToCheck.startsWith('http://')) {
        if (language === Language.DE) {
          this.url.de = 'https://' + valueToCheck;
        } else if (language === Language.GB) {
          this.url.gb = 'https://' + valueToCheck;
        } else if (language === Language.JP) {
          this.url.jp = 'https://' + valueToCheck;
        }
      valueToCheck = 'https://' + valueToCheck;
    }
    if (valueToCheck.length > 0 && !this.regex.test(valueToCheck)) {
      let country = '';
      if (Language.DE === language) {
        country = this.stringService.get('GERMAN');
      } else if (Language.GB === language) {
        country = this.stringService.get('ENGLISH');
      } else if (Language.JP === language) {
        country = this.stringService.get('JAPANESE');
      }
      this.alertService.alert(
        new AlertWithBoldValueInMiddle(
          this.stringService.get('URL_DOES_NOT_MATCH_1'),
          country,
          this.stringService.get('URL_DOES_NOT_MATCH_2'),
          AlertType.Warning));
      return false;
    } else {
      return true;
    }
  }

  public onNavigateBack(): void {
    this.onBackButton.emit();
  }
}
