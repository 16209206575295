import { Component, HostListener, TemplateRef, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { Cuboid } from '../models/Cuboid';
import { FileDataType } from '../models/FileDataType';
import { Language } from '../models/Language';
import { CuboidService } from '../services/cuboid.service';
import { ArLayerService } from '../services/arLayer.service';
import { CurrentContextService } from '../services/currentContext.service';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { AppState } from '../current.app.state';
import { MediaService } from '../services/media.service';
import { Media } from '../models/Media';
import { Tools } from '../Tools';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { tap, catchError } from 'rxjs/operators';
import { ArLayer } from '../models/ArLayer';
import { CubeSides } from '../models/CubeSides';


@Component({
  selector: 'aacms-edit-cuboid',
  templateUrl: './edit-cuboid.component.html',
  styleUrls: ['./edit-cuboid.component.scss']
})
export class EditCuboidComponent implements OnInit, OnDestroy {

  public cuboid: Cuboid;
  public originalCuboid: Cuboid;

  public cuboidChanged = false;

  public modalRef: BsModalRef;

  public cubeSides = CubeSides;
  public appState = AppState;

  public currentSelectedImage: CubeSides = CubeSides.top;

  public imageDataMap: Map<CubeSides, FileDataType> = new Map([
    [CubeSides.top, null],
    [CubeSides.bottom, null],
    [CubeSides.front, null],
    [CubeSides.back, null],
    [CubeSides.left, null],
    [CubeSides.right, null]
  ]);

  public imageDataLoaded = false;

  public Language = Language;
  public currentLanguage: Language = null;

  private navigationSubscription: Subscription;
  private saveContentSubject: Subscription;

  public arLayers: ArLayer[] = [];
  public originalArLayers: ArLayer[] = [];
  public arLayersToDelete: ArLayer[] = [];

  public arLayersChanged = false;

  public selectedArLayer: ArLayer;

  public Tools = Tools;

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private cuboidService: CuboidService,
    public contextService: CurrentContextService,
    private mediaService: MediaService,
    private sanitizer: DomSanitizer,
    private arLayerService: ArLayerService
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof ActivationEnd) {
        this.cuboid = null;
        const cuboidId = e.snapshot.paramMap.get('cuboidId');
        if (cuboidId) {
          this.contextService.setCurrentState(AppState.editCuboid);
          this.currentLanguage = Language.DE;
          this.arLayersToDelete = [];
          this.getCuboid(cuboidId);
          this.getArLayers(cuboidId);
        }
      }
    });
  }

  public ngOnInit() {
    this.saveContentSubject = this.contextService.saveYourContent.subscribe(
      saveContent => {
        if (saveContent) {
          this.onSave();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
    this.saveContentSubject.unsubscribe();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.cuboidChanged || this.arLayersChanged ? false : true;
  }

  private getCuboid(cuboidId: string) {
    this.imageDataLoaded = false;
    this.cuboid = null;
    const sub = this.cuboidService.getCuboidById(cuboidId).subscribe(
      cuboid => {
        this.cuboid = new Cuboid(cuboid);
        this.contextService.getExhibition(this.cuboid.exhibitionIds[0]);
        this.originalCuboid = new Cuboid(cuboid);
        const requests = [];
        if (this.cuboid.trackerIds.top && this.cuboid.trackerIds.top.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.top.mediaId, CubeSides.top));
        }
        if (this.cuboid.trackerIds.bottom && this.cuboid.trackerIds.bottom.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.bottom.mediaId, CubeSides.bottom));
        }
        if (this.cuboid.trackerIds.left && this.cuboid.trackerIds.left.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.left.mediaId, CubeSides.left));
        }
        if (this.cuboid.trackerIds.right && this.cuboid.trackerIds.right.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.right.mediaId, CubeSides.right));
        }
        if (this.cuboid.trackerIds.front && this.cuboid.trackerIds.front.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.front.mediaId, CubeSides.front));
        }
        if (this.cuboid.trackerIds.back && this.cuboid.trackerIds.back.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.back.mediaId, CubeSides.back));
        }
        const sub1 = forkJoin(requests).subscribe(
          () => {
            this.imageDataLoaded = true;
            sub1.unsubscribe();
          }
        );
        sub.unsubscribe();
      }
    );
  }

  private loadCuboidImage(mediaId: string, mapId: CubeSides): Observable<any> {
    const fileDataType: FileDataType = new FileDataType();
    return this.mediaService.getMediaById(mediaId).pipe(
      tap(
        media => {
          fileDataType.mediaId = mediaId;
          fileDataType.media = new Media(media);
          const sub = this.mediaService.downloadFile(media.rawUrl).subscribe(
            blob => {
              const file = Tools.blobToFile(blob, 'image.jpg');
              fileDataType.file = file;
              fileDataType.fileSafeUrl = this.createImageUrl(file);
              this.imageDataMap.set(mapId, fileDataType);
              sub.unsubscribe();
            }
          );
        }),
        catchError(
          error => {
            return of([]);
        })
    );
  }

  private getArLayers(cuboidId: string): void {
    this.arLayers = [];
    const sub = this.arLayerService.getArLayers(cuboidId).subscribe(
      arLayers => {
        this.arLayers = [];
        for (const arLayer of arLayers) {
          this.arLayers.push(new ArLayer(arLayer));
          this.originalArLayers.push(new ArLayer(arLayer));
        }
        this.arLayers = this.arLayers.sort((arLayer1, arLayer2) => arLayer1.position - arLayer2.position);
        this.originalArLayers = this.originalArLayers.sort((arLayer1, arLayer2) => arLayer1.position - arLayer2.position);
        sub.unsubscribe();
      }
    );
  }

  private createImageUrl(file: File): SafeUrl {
    const urlCreator = window.URL;
    const url = urlCreator.createObjectURL(file);
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    return safeUrl;
  }

  public setCurrentImage(currentSide: CubeSides): void {
    this.currentSelectedImage = currentSide;
  }

  public isImageAvailable(): boolean {
    return (
      this.cuboid !== undefined &&
      this.cuboid.trackerIds !== undefined &&
      this.imageDataMap.get(this.currentSelectedImage) !== null
    );
  }

  public isImageFileAvailable(): boolean {
    return (
      this.cuboid !== undefined &&
      this.cuboid.trackerIds !== undefined &&
      this.imageDataMap.get(this.currentSelectedImage) !== null &&
      this.imageDataMap.get(this.currentSelectedImage).file !== null
    );
  }

  public removeImage(): void {
    this.imageDataMap.set(this.currentSelectedImage, null);
    this.cuboid.trackerIds.setSide(this.currentSelectedImage, null);
    this.checkForCuboidChange();
  }

  public routeToEditCuboidMeta(): void {
    this.router.navigate(['cuboids/' + this.cuboid.id + '/meta/']);
  }

  public removeCuboidDescription(): void {
    this.cuboid.title = new MultiLanguageText();
    this.checkForCuboidChange();
  }

  public selectArLayer(arLayer: ArLayer): void {
    if (arLayer === null) {
      this.selectedArLayer = null;
    } else {
      this.selectedArLayer = arLayer;
    }
  }

  public checkForCuboidChange(): void {
    if (!this.cuboid.equal(this.originalCuboid)) {
      this.cuboidChanged = true;
      return;
    }
    this.cuboidChanged = false;
  }

  public getArLayerListTitleStyle(arLayer: ArLayer): Object {
    const style = {
      'opacity': arLayer.active ? '1' : '0.3'
    };
    return style;
  }

  public routeToEditArLayer(arLayer: ArLayer): void {
    this.router.navigate(['cuboids/' + this.cuboid.id + '/arlayers/' + arLayer.id]);
  }

  public getLanguageValue(text: MultiLanguageText): string {
    return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
  }

  public updateLanguage(language: Language): void {
    this.currentLanguage = language;
  }

  public toggleActive(arLayer: ArLayer, event: MouseEvent): void {
    event.stopPropagation();
    arLayer.active = !arLayer.active;
    this.checkForArLayersChanged();
  }

  public addArLayer(): void {
    const arLayer = new ArLayer();
    arLayer.position = this.arLayers.length;
    arLayer.cuboidId = this.cuboid.id;
    const sub = this.arLayerService.createArLayer(arLayer).subscribe(
      response => {
        const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
        arLayer.id = location.substring(location.lastIndexOf('/') + 1);
        this.arLayers.push(arLayer);
        this.originalArLayers.push(new ArLayer(arLayer));
        sub.unsubscribe();
      }
    );
  }

  public moveArLayerUp(): void {
    if (this.selectedArLayer && this.selectedArLayer.position > 0) {
      const temp = this.arLayers[this.selectedArLayer.position - 1];
      this.arLayers[this.selectedArLayer.position - 1] = this.arLayers[this.selectedArLayer.position];
      this.arLayers[this.selectedArLayer.position] = temp;
      this.updateArLayerPositions();
    }
  }

  public moveArLayerDown(): void {
    if (this.selectedArLayer && this.selectedArLayer.position < this.arLayers.length - 1) {
      const temp = this.arLayers[this.selectedArLayer.position + 1];
      this.arLayers[this.selectedArLayer.position + 1] = this.arLayers[this.selectedArLayer.position];
      this.arLayers[this.selectedArLayer.position] = temp;
      this.updateArLayerPositions();
    }
  }

  public deleteArLayer(arLayerToDelete: ArLayer, event: MouseEvent) {
    event.stopPropagation();
    this.arLayers = this.arLayers.filter(arLayer => !arLayerToDelete.equal(arLayer));
    if (this.selectedArLayer && arLayerToDelete.equal(this.selectedArLayer)) {
      this.selectedArLayer = null;
    }
    this.arLayersToDelete.push(arLayerToDelete);
    this.updateArLayerPositions();
  }

  private updateArLayerPositions(): void {
    let counter = 0;
    for (const arLayer of this.arLayers) {
      arLayer.position = counter;
      counter++;
    }
    this.checkForArLayersChanged();
  }

  private checkForArLayersChanged(): void {
    if (this.arLayers.length !== this.originalArLayers.length) {
      this.arLayersChanged = true;
    } else {
      for (let i = 0; i < this.arLayers.length; i = i + 1) {
        if (!this.arLayers[i].equal(this.originalArLayers[i])) {
          this.arLayersChanged = true;
          return;
        }
      }
      this.arLayersChanged = false;
    }
  }

  public cancel(): void {
    if (this.modalRef) { this.modalRef.hide(); }
    this.router.navigate(['exhibitions', this.cuboid.exhibitionIds[0], 'images']);
  }

  public openModal(modalTemplate: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(modalTemplate);
  }

  public onDiscard(): void {
    if (this.cuboidChanged) {
      this.cuboid = new Cuboid(this.originalCuboid);
      this.checkForCuboidChange();
    }
    if (this.arLayersChanged) {
      this.arLayers = [];
      for (const arLayer of this.originalArLayers) {
        this.arLayers.push(new ArLayer(arLayer));
      }
      this.checkForArLayersChanged();
    }
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  public onSave(): void {
    this.contextService.setSaveYourContent(false);
    if (this.cuboidChanged || this.arLayersChanged) {
      this.contextService.setSavingContent(true);
    }
    const requests = [];
    if (this.arLayersChanged) {
      for (const arLayer of this.arLayers) {
        for (const originalArLayer of this.originalArLayers) {
          if (arLayer.id === originalArLayer.id && !arLayer.equal(originalArLayer)) {
            requests.push(this.arLayerService.updateArLayer(arLayer));
            break;
          }
        }
      }
      for (const arLayer of this.arLayersToDelete) {
        requests.push(this.arLayerService.deleteArLayer(arLayer));
      }
    }
    if (requests.length === 0 && this.cuboidChanged) {
      const sub = this.cuboidService.updateCuboid(this.cuboid).subscribe(
        () => {
          this.originalCuboid = new Cuboid(this.cuboid);
          this.contextService.setSavingContent(null);
          this.checkForCuboidChange();
          sub.unsubscribe();
        }
      );
    } else {
      const sub = forkJoin(requests).subscribe(
        () => {
          this.originalArLayers = [];
          for (const arLayer of this.arLayers) {
            this.originalArLayers.push(new ArLayer(arLayer));
          }
          this.checkForArLayersChanged();
          const sub1 = this.cuboidService.updateCuboid(this.cuboid).subscribe(
              () => {
                this.originalCuboid = new Cuboid(this.cuboid);
                this.contextService.setSavingContent(null);
                this.checkForCuboidChange();
                sub1.unsubscribe();
              }
            );
            sub.unsubscribe();
        });
    }
  }

  public delete(): void {
    this.modalRef.hide();
    this.cuboidChanged = false;
    this.contextService.setSavingContent(true);
    const sub = this.cuboidService.deleteCuboid(this.cuboid).subscribe(
      () => {
        this.contextService.setSavingContent(false);
        this.cancel();
        sub.unsubscribe();
      }
    );
  }

  public isLanguage(language: Language): boolean {
    return language === this.currentLanguage;
  }

  public routeTo(appState: AppState): void {
    switch (appState) {
      case AppState.exhibitions:
        this.router.navigate(['exhibitions']);
        break;
      case AppState.images:
        this.router.navigate(['exhibitions', this.cuboid.exhibitionIds[0], 'images']);
        break;
      default:
        break;
    }
  }
}
