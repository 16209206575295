import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Cuboid } from '../../models/Cuboid';
import { Exhibition } from '../../models/Exhibition';
import { Image } from '../../models/Image';
import { Language } from '../../models/Language';
import { Media } from '../../models/Media';
import { Place } from '../../models/Place';
import { Tracker } from '../../models/Tracker';
import { AuthService } from '../../services/auth.service';
import { StringService } from '../../services/string.service';
import * as L from 'leaflet';
import { Location } from '../../models/Location';
import { Config } from '../../Config';
import { Hologram } from '../../models/Hologram';

@Component({
  selector: 'modal-content',
  templateUrl: './tracker-selection.modal.component.html',
  styleUrls: ['./tracker-selection.modal.component.scss']
})
export class TrackerSelectionModal {

  public event: EventEmitter<Tracker> = new EventEmitter();
  public selectedTracker: Tracker;
  public selectedTrackerId: string;

  private medias: Map<string, Media>;
  private loadedTrackers: Map<string, boolean>;
  private currentLanguage: Language;

  private maps: Map<string, any> = new Map();

  public imageClass = Image;
  public initDone: boolean = false;

  public exhibitions: Map<Exhibition, Tracker[]>;

  public expanded: any;

  private selectedTrackerMap: any;

  public constructor(
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    private stringService: StringService
  ) { }

  public ngAfterViewInit(): void {
    for (const exhibition of this.exhibitions.keys()) {
      if (this.selectedTracker !== undefined) {
        break;
      }
      for(const tracker of this.exhibitions.get(exhibition)) {
        if (tracker.id === this.selectedTrackerId) {
          this.selectTracker(tracker);
        }
      }
    }
    this.initDone = true;
  }

  public toggleCollapse(exhibition: Exhibition) {
    if (this.expanded === exhibition.id) {
      this.expanded = '';
    } else {
      this.expanded = exhibition.id;
      this.reinitMaps(exhibition);
    }
  }

  public reinitMaps(exhibition: Exhibition): void {
    for (const tracker of this.exhibitions.get(exhibition)) {
      if (tracker instanceof Place) {
        setTimeout(() => {
          if (this.maps.get('map:' + tracker.id) !== undefined) {
            this.maps.get('map:' + tracker.id).remove();
          }
          this.initMap(tracker);
        });
      }
    }
  }

  private initMap(place: Place, id = 'map:' + place.id): any {
    const map = L.map(
      id,
      {
        zoomControl: false,
        attributionControl: false,
        dragging: false,
        boxZoom: false,
        doubleClickZoom: false,
        scrollWheelZoom: false,
        touchZoom: false,
        keyboard: false
      }
    );
    const boundOffset = 100;
    let bounds;
    if (place.location !== undefined && place.location.lat !== undefined && place.location.lon !== undefined) {
      if (place.radius !== undefined) {
        bounds = L.latLngBounds(this.calculateBounds(place.location, place.radius + boundOffset), this.calculateBounds(place.location, -place.radius - boundOffset));
        L.circle(L.latLng(place.location.lat, place.location.lon), place.radius).addTo(map);
      } else {
        bounds = L.latLngBounds(this.calculateBounds(place.location, 100 + boundOffset), this.calculateBounds(place.location, -100 - boundOffset));
        L.circle(L.latLng(place.location.lat, place.location.lon), 1).addTo(map);
      }
    } else {
      bounds = L.latLngBounds(L.latLng(47.26543, 5.864417), L.latLng(55.14777, 15.05078));
    }
    map.fitBounds(bounds);
    const tiles = L.tileLayer(new Config().getMapUrl(), {
      tileSize: 256,
      minZoom: 1,
      attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
      crossOrigin: true
    });
    tiles.addTo(map);
    this.maps.set(id, map);
    return map;
  }

  public isSelectedTracker(tracker: Tracker): boolean {
    if (this.selectedTracker !== undefined) {
      return tracker.id === this.selectedTracker.id;
    }
    return false;
  }

  private calculateBounds(location: Location, radius): void {
    const earthRadius = 6378137;
    const dLat = radius / earthRadius;
    const dLng = radius / (earthRadius * Math.cos(Math.PI * location.lat / 180));
    const latO = location.lat + dLat * 180 / Math.PI;
    const lngO = location.lon + dLng * 180 / Math.PI;
    return L.latLng(latO, lngO);
  }

  public isTrackerImage(tracker: Tracker): boolean {
    return tracker instanceof Image;
  }

  public isTrackerHologram(tracker: Tracker): boolean {
    return tracker instanceof Hologram;
  }

  public isTrackerCuboid(tracker: Tracker): boolean {
    return tracker instanceof Cuboid;
  }

  public getTrackerMediaId(tracker: Tracker) {
		if (tracker instanceof Hologram) {
			return tracker.model.getWithFallback(this.currentLanguage)?.mediaId;
		}
		return null;
	}

  public isTrackerPlace(tracker: Tracker): boolean {
    return tracker instanceof Place;
  }

  public getTrackerTitle(tracker: Tracker): string {
    if (!tracker.title.de) {
      if (this.isTrackerCuboid(tracker)) {
        return this.stringService.get('UNNAMED_CUBOID');
      } else if (this.isTrackerImage(tracker)) {
        return this.stringService.get('UNNAMED_IMAGE');
      } else if (this.isTrackerPlace(tracker)) {
        return this.stringService.get('UNNAMED_PLACE');
      } else {
        return this.stringService.get('');
      }
    }
    let title = tracker.title.getInLanguage(this.currentLanguage);
    return title;
  }

  public trackerLoaded(image: Tracker, loaded?: boolean): boolean {
    if (loaded) {
      this.loadedTrackers.set(image.id, loaded);
    }
    return !this.loadedTrackers.has(image.id);
  }

  public getThumbUrl(mediaId: string): string {
    if (!mediaId) { return '../../assets/image-placeholder.png'; }
    const media = this.medias.get(mediaId);
    if (media) {
      if (media.thumbUrl) {
        return this.authService.addTokenToUrl(media.thumbUrl);
      } else if (media.rawUrl) {
        return this.authService.addTokenToUrl(media.rawUrl);
      } else {
        return '../../assets/image-placeholder.png';
      }
    }
    return '';
  }

  public getTracker(tracker: Tracker): Cuboid {
    if (tracker instanceof Cuboid) {
      return <Cuboid>tracker;
    } else {
      return null;
    }
  }

  public getCuboidThumbUrl(mediaId: string): string {
    if (!mediaId) { return null; }
    const media = this.medias.get(mediaId);
    if (media) {
      if (media.thumbUrl) {
        return this.authService.addTokenToUrl(media.thumbUrl);
      } else if (media.rawUrl) {
        return this.authService.addTokenToUrl(media.rawUrl);
      } else {
        return null;
      }
    }
    return null;
  }

  public selectTracker(tracker: Tracker) {
    this.selectedTracker = tracker;
    if (this.isTrackerPlace(tracker)) {
      this.clearSelectedTrackerMap();
      setTimeout(()=> {
        this.selectedTrackerMap = this.initMap(<Place>tracker, 'selectedTrackerMap');
      });
    }
  }

  private clearSelectedTrackerMap(): void {
    if (this.selectedTrackerMap !== undefined) {
      this.selectedTrackerMap.off();
      this.selectedTrackerMap.remove();
      this.selectedTrackerMap = undefined;
    }
  }

  public onClose(): void {
    this.bsModalRef.hide();
  }

  public onSave(): void {
    if (this.selectedTracker !== undefined) {
      this.event.emit(this.selectedTracker);
    }
    this.bsModalRef.hide();
  }
}
