import { MultiLanguageText } from './MultiLanguageText';

export class PushNotification {
  public id: string;
  public date: number = new Date().getTime();
  public title: MultiLanguageText = new MultiLanguageText();
  public text: MultiLanguageText = new MultiLanguageText();
  public url: MultiLanguageText = new MultiLanguageText();
  public executionDate: number;
  public executed: boolean = false;

  constructor(pushNotification?: PushNotification) {
    if (!pushNotification) { return; }
    this.id = pushNotification.id;
    this.date = pushNotification.date;
    this.title = new MultiLanguageText(pushNotification.title);
    this.text = new MultiLanguageText(pushNotification.text);
    this.url = new MultiLanguageText(pushNotification.url);
    this.executionDate = pushNotification.executionDate;
    this.executed = pushNotification.executed;
  }

  public equal(pushNotification: PushNotification): boolean {
    if (this.id !== pushNotification.id ||
      this.date !== pushNotification.date ||
      this.executionDate !== pushNotification.executionDate ||
      this.executed !== pushNotification.executed ||
      !this.title.equal(pushNotification.title) ||
      !this.text.equal(pushNotification.text) ||
      !this.url.equal(pushNotification.url)) {
      return false;
    }
    return true;
  }
}
