import { Config } from "../Config";
import { Card } from "./Card";
import { ContentTypes } from "./ContentTypes";
import { MapElement } from "./GeoJson";
import { MultiLanguageText } from "./MultiLanguageText";
import { AudioElement, ElementType, ImageElement, ModelElement, TextElement, UiElement, VideoElement } from "./UiElements";

export class InfoCard {
    public position: number;
    public active: boolean = true;
    public title: MultiLanguageText = new MultiLanguageText();
    public id: string;
    public createdTime: number;
    public updatedTime: number;
    public parentPlaceId: string;
    public card: Card = new Card();
    public uiElements: UiElement[] = [];
    public cardsActive: boolean = true;
    public contentType: ContentTypes = new Config().getEnabledContentTypes()[0];
    public url: MultiLanguageText = new MultiLanguageText();

    constructor(infoCard?: InfoCard) {
        if (infoCard === undefined) { return; } 
        this.position = infoCard.position;
        this.active = infoCard.active;
        this.title = new MultiLanguageText(infoCard.title);
        this.id = infoCard.id;
        this.createdTime = infoCard.createdTime;
        this.updatedTime = infoCard.updatedTime;
        this.parentPlaceId = infoCard.parentPlaceId;
        this.card = new Card(infoCard.card);
        this.cardsActive = infoCard.cardsActive;
        this.contentType = infoCard.contentType;
        this.url = new MultiLanguageText(infoCard.url);
        this.uiElements = [];
        if (infoCard.uiElements) {
            for (const element of infoCard.uiElements) {
                if (element.type === ElementType.AUDIO) {
                  this.uiElements.push(new AudioElement(<AudioElement>element));
                } else if (element.type === ElementType.IMAGE) {
                  this.uiElements.push(new ImageElement(<ImageElement>element));
                } else if (element.type === ElementType.MODEL) {
                  this.uiElements.push(new ModelElement(<ModelElement>element));
                } else if (element.type === ElementType.TEXT) {
                  this.uiElements.push(new TextElement(<TextElement>element));
                } else if (element.type === ElementType.VIDEO) {
                  this.uiElements.push(new VideoElement(<VideoElement>element));
                } else if (element.type === ElementType.MAP) {
                  this.uiElements.push(new MapElement(<MapElement>element));
                }
            }
        }
        this.uiElements = this.uiElements.sort((uie1, uie2) => uie1.position - uie2.position);
    }

    public equal(infoCard: InfoCard): boolean {
        if (
            this.position !== infoCard.position ||
            this.active !== infoCard.active ||
            this.id !== infoCard.id ||
            this.createdTime !== infoCard.createdTime ||
            this.updatedTime !== infoCard.updatedTime ||
            this.parentPlaceId !== infoCard.parentPlaceId ||
            !this.title.equal(infoCard.title) ||
            this.uiElements.length !== infoCard.uiElements.length ||
            this.cardsActive !== infoCard.cardsActive ||
            this.contentType !== infoCard.contentType ||
            !this.url.equal(infoCard.url) ||
            !this.card.equal(infoCard.card)
        ) {
            return false;
        }
        for (let i = 0; i < this.uiElements.length; i++) {
            if (!this.uiElements[i].equal(infoCard.uiElements[i])) {
              return false;
            }
          }
        return true;
    }
}