import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Config } from '../Config';
import { AppState } from '../current.app.state';
import { ContentTypes } from '../models/ContentTypes';
import { CubeSides } from '../models/CubeSides';
import { FileDataType } from '../models/FileDataType';
import { Image } from '../models/Image';
import { Language } from '../models/Language';
import { Media } from '../models/Media';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { Poi, PoiTextureIdValue } from '../models/Poi';
import { PoiTexture } from '../models/PoiTexture';
import { ElementType } from '../models/UiElements';
import { CurrentContextService } from '../services/currentContext.service';
import { ImageService } from '../services/image.service';
import { MediaService } from '../services/media.service';
import { PoiManagerService } from '../services/poi-manager.service';
import { PoiTextureService } from '../services/poi-texture.service';
import { PoiService } from '../services/poi.service';
import { StringService } from '../services/string.service';
import { Tools } from '../Tools';
import { PoiStyleUploadModal } from '../modals/poi-style-upload/poi-style-upload.modal.component';
import { TabType } from '../models/TabType';
import { PoiLayerComponent } from '../poi-layer/poi-layer.component';
import { MultiLanguageData } from '../models/MultiLanguageData';

@Component({
	selector: 'edit-poi',
	templateUrl: './edit-poi.component.html',
	styleUrls: ['./edit-poi.component.scss']
})
export class EditPoiComponent implements OnInit, OnDestroy {

	public modalRef: BsModalRef;

	@ViewChild('poiLayer') poiLayer: PoiLayerComponent;
	@ViewChild('cancelModalTemplate', { static: false }) cancelModalTemplate: TemplateRef<any>;

	public poi: Poi;
	private originalPoi: Poi;
	private mediaToDelete: FileDataType[] = [];
	public contentChanged = false;
	public image: Image;
	public imageDataMap: Map<CubeSides, FileDataType> = new Map([[CubeSides.image, null]]);

	public cubeSides = CubeSides;
	public contentTypes = ContentTypes;
	public Language = Language;
	public appState = AppState;

	public currentLanguage: Language;

	public distance: string;

	@ViewChild('smartphone') smartphone;

	public elementType = ElementType;
	public contentType = ContentTypes;

	public config: Config = new Config();

	private navigationSubscription: Subscription;

	private saveContentSubject: Subscription;

	public poiTextures: PoiTexture[] = [];

	public Tools = Tools;

	public currentTab: TabType;
	public tabType = TabType;

	public constructor(
		public contextService: CurrentContextService,
		public pms: PoiManagerService,
		private mediaService: MediaService,
		private modalService: BsModalService,
		private poiService: PoiService,
		private router: Router,
		private route: ActivatedRoute,
		private imageService: ImageService,
		private sanitizer: DomSanitizer,
		private stringService: StringService,
		private poiTextureService: PoiTextureService
	) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.reloadContent();
			}
		});
	}

	public ngOnInit(): void {
		this.contextService.setCurrentState(AppState.editPoint);
		this.currentLanguage = Language.DE;
		this.saveContentSubject = this.contextService.saveYourContent.subscribe(
			saveContent => {
				if (saveContent) {
					this.save();
				}
			}
		);
		this.getImage();
		this.getPoi();
		this.getPoiTextures();
	}

	public ngOnDestroy(): void {
		this.contentChanged = false;
		this.navigationSubscription.unsubscribe();
		this.saveContentSubject.unsubscribe();
		this.returnToImage();
	}

	@HostListener('window:beforeunload')
	canDeactivate(): Observable<boolean> | boolean {
		return (this.contentChanged || this.pms.poisChanged) ? false : true;
	}

	public askToSave(): Observable<any> {
		this.modalRef = this.modalService.show(this.cancelModalTemplate, { keyboard: false, ignoreBackdropClick: true });
		return this.modalService.onHide.asObservable();
	}

	public reloadContent() {
		const id = this.route.snapshot.paramMap.get('imageId');
		if (this.poi && id !== this.poi.id) {
			this.poi = null;
			this.originalPoi = null;
			this.mediaToDelete = [];
			this.contentChanged = false;
			this.image = null;
			this.imageDataMap.set(CubeSides.image, null);
			this.getImage();
			this.getPoi();
			this.getPoiTextures();
		}
	}

	public async getPoi(): Promise<any> {
		const poiId = this.route.snapshot.paramMap.get('poiId');
		if (this.pms.isLoadingPois) {
			await this.pms.isLoadingPois;
		}
		this.poi = this.pms.getCurrentPoiById(poiId);
		if (this.poi === undefined) {
			const imageId = this.route.snapshot.paramMap.get('imageId');
			this.router.navigate(['images/' + imageId]);
		}
		this.distance = this.poi.distanceToTracker.toString();
		this.originalPoi = this.pms.getOriginalPoiById(poiId);
		if (this.originalPoi === undefined) {
			this.originalPoi = new Poi(this.poi);
		}
		this.pms.selectedPoi = this.poi;
		this.checkForContentChange();
	}

	public updateDistanceToTracker(value, input: HTMLInputElement): void {
		value = Tools.checkForNumber(value, true);
		if (value === null) {
			value = this.poi.distanceToTracker.toString();
		} else if (value === '') {
			this.poi.distanceToTracker = 0;
		} else {
			this.poi.distanceToTracker = +value;
		}
		this.distance = value;
		this.checkForContentChange();
		input.value = value;
	}

	public getImage(): void {
		const id = this.route.snapshot.paramMap.get('imageId');
		const sub3 = this.imageService.getImageById(id).subscribe(
			image => {
				this.image = new Image(image);
				this.contextService.getExhibition(this.image.exhibitionIds[0]);
				const imageData = new FileDataType();
				this.imageDataMap.set(CubeSides.image, imageData);
				imageData.mediaId = image.mediaId;
				const sub1 = this.mediaService.getMediaById(this.image.mediaId).subscribe(
					media => {
						imageData.media = new Media(media);
						const sub2 = this.mediaService.downloadFile(imageData.media.rawUrl).subscribe(
							blob => {
								imageData.file = Tools.blobToFile(blob, imageData.media.name);
								imageData.fileSafeUrl = this.createImageUrl(imageData.file);
								imageData.isLoaded = true;
								sub2.unsubscribe();
							}
						);
						sub1.unsubscribe();
					}
				);
				sub3.unsubscribe();
			});
	}

	public getPoiTextures(): void {
		this.poiTextures = [];
		this.loadDefaultTextures();
		const subscription = this.poiTextureService.getTextures().subscribe(
			textures => {
				for (const texture of textures) {
					this.poiTextures = [...this.poiTextures, new PoiTexture(texture)];
				}
				subscription.unsubscribe();
			}
		);
	}

	public loadDefaultTextures(): void {
		const defaultTexture1 = new PoiTexture();
		defaultTexture1.id = '00000000-0000-0000-0000-000000000001';
		defaultTexture1.mediaId = '00000000-0000-0000-0000-000000000001';
		defaultTexture1.name = this.stringService.get('DEFAULT_TEXTURE_1');
		this.poiTextures.push(defaultTexture1);
		const defaultTexture2 = new PoiTexture();
		defaultTexture2.id = '00000000-0000-0000-0000-000000000002';
		defaultTexture2.mediaId = '00000000-0000-0000-0000-000000000002';
		defaultTexture2.name = this.stringService.get('DEFAULT_TEXTURE_2');
		this.poiTextures.push(defaultTexture2);
		const defaultTransparentCircle = new PoiTexture();
		defaultTransparentCircle.id = '00000000-0000-0000-0000-000000000003';
		defaultTransparentCircle.mediaId = '00000000-0000-0000-0000-000000000003';
		defaultTransparentCircle.name = this.stringService.get('DEFAULT_TEXTURE_3');
		this.poiTextures.push(defaultTransparentCircle);
	}


	private createImageUrl(file: File): SafeUrl {
		const urlCreator = window.URL;
		const url = this.sanitizer.bypassSecurityTrustUrl(
			urlCreator.createObjectURL(file));
		return url;
	}

	public checkForContentChange(): void {
		this.contentChanged = !this.poi.equal(this.originalPoi);
		this.pms.checkForPoisChanged();
	}

	public async save(): Promise<any> {
		this.contextService.setSaveYourContent(false);
		let saveContent = (this.contentChanged) ? true : false;
		let savePois = (this.pms.poisChanged) ? true : false;
		if (saveContent || savePois) {
			this.contextService.setSavingContent(true);
		}
		if (this.contentChanged) {
			const requests: Observable<any>[] = [];
			for (const object of this.mediaToDelete) {
				if (object !== undefined && object.mediaId !== undefined) {
					requests.push(this.mediaService.deleteMedia(object.mediaId).pipe(
						tap()
					));
				}
			}
			await forkJoin(requests).pipe(tap(
				response => {
				}, error => {
				},
				async () => {
					this.originalPoi = new Poi(this.poi);
					this.checkForContentChange();
					this.hideModal();
					saveContent = false;
					if (!saveContent && !savePois) {
						this.contextService.setSavingContent(false);
					}
				})).toPromise();
		}
		if (this.pms.poisChanged) {
			const savePoiSubscription = this.pms.saveChangedPois();
			savePoiSubscription.add(() => {
				savePois = false;
				if (!saveContent && !savePois) {
					this.contextService.setSavingContent(false);
				}
				savePoiSubscription.unsubscribe();
			});
		}
	}

	public discard(): void {
		this.mediaToDelete = [];
		this.poi = this.pms.resetPoiById(this.poi.id, this.originalPoi);
		this.distance = this.poi.distanceToTracker.toString();
		this.smartphone.onDiscard();
		this.checkForContentChange();
		this.hideModal();
	}

	public openModal(modalTemplate: TemplateRef<any>): void {
		this.modalRef = this.modalService.show(modalTemplate);
	}

	private hideModal(): void {
		if (this.modalRef) { this.modalRef.hide(); }
	}

	public delete(): void {
		this.pms.pois = this.pms.pois.filter(poi => poi.id !== this.poi.id);
		const sub = this.poiService.deletePoi(this.poi).subscribe(() => {
			this.pms.updatePoiPositions();
			this.pms.poisChanged = false;
			this.contentChanged = false;
			this.pms.saveChangedPois();
			this.hideModal();
			this.returnToImage();
			sub.unsubscribe();
		});
	}

	public returnToImage(): void {
		this.router.navigate(['images', this.route.snapshot.paramMap.get('imageId')]);
	}

	public isLanguage(language: Language): boolean {
		return language === this.currentLanguage;
	}

	public updateLanguage(language: Language): void {
		this.currentLanguage = language;
		setTimeout(() => {
			this.pms.reloadPoiLayer();
		});
	}

	public getLanguageValue(text: MultiLanguageText): string {
		return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
	}

	public showContentTypesSelection(): boolean {
		return this.config.getEnabledContentTypes().length > 1;
	}

	public showPoiTexturesSelection(): boolean {
		return this.config.isPoiStylesEnabled();
	}

	public poiTextureChanged(): void {
		if (!this.poi.multiLanguageTextureId.hasAll(Tools.allLangs())) {
			this.createPoiStyles();
			return;
		}
		this.checkForContentChange();
		this.pms.reloadPoi(this.poi);
	}

	public createPoiStyles(): void {
		this.modalRef = this.modalService.show(PoiStyleUploadModal,
			{ class: 'modal-lg', keyboard: false, ignoreBackdropClick: true });
		const subscription = this.modalService.onHide.subscribe(() => {
			this.getPoiTextures();
			this.poi.multiLanguageTextureId = new MultiLanguageData<PoiTextureIdValue>(PoiTextureIdValue, this.originalPoi.multiLanguageTextureId);
			subscription.unsubscribe();
		});
	}

	public togglePoiActivation(): void {
		this.poi.active = !this.poi.active;
		this.checkForContentChange();
	}

	public togglePoiOpener(): void {
		this.poi.opener = !this.poi.opener;
		this.checkForContentChange();
	}

	public getStringNameForContentType(type: ContentTypes): string {
		switch (type) {
			case ContentTypes.Custom: {
				return this.stringService.get('CUSTOM');
			}
			case ContentTypes.URL: {
				return this.stringService.get('URL');
			}
		}
	}

	public routeTo(appState: AppState): void {
		switch (appState) {
			case AppState.exhibitions:
				this.router.navigate(['exhibitions']);
				break;
			case AppState.images:
				this.router.navigate(['exhibitions', this.image.exhibitionIds[0], 'images']);
				break;
			case AppState.editImage:
				this.router.navigate(['images', this.image.id]);
				break;
			default:
				break;
		}
	}

	public isTabAllowed(type: ContentTypes): boolean {
		for (const contentType of this.config.getEnabledContentTypes()) {
			if (contentType === type) {
				return true;
			}
		}
		return false;
	}

	public isActiveTexture3DModel(): boolean {
		return (this.poiLayer && this.poiLayer.isSelectedPoi3DModel());
	}
	public getModelRotate(dimIdx: number): number {
		return this.poi.multiLanguageTextureId.get(this.currentLanguage).rotation[dimIdx];
	}
	public setModelRotate(dimIdx: number, newValue: any) {
		this.poi.multiLanguageTextureId.get(this.currentLanguage).rotation[dimIdx] = newValue;
		this.checkForContentChange();
	}
}
