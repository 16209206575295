<div *ngIf="this.hologram" class="edit-hologram-meta-main-content">
	<div class="edit-hologram-meta-left-side">
		<aacms-media-viewer #mediaViewer class="modelViewer"
			[modelAlways]="true" backgroundStyle="white"
			[mediaId]="this.hologram?.model?.getWithFallback(this.currentLanguage)?.mediaId">
		</aacms-media-viewer>
		<manage-media2 [elementType]="ElementType.MODEL"
			[mediaLoader]="this.mediaLoader" [currentLanguage]="this.currentLanguage"
			[mediaData]="this.hologram?.model"
			[maxFileSize]="this.config.getMax3DModelSize()"
			(mediaChanged)="this.checkForContentChange()">
			<div class="hasNone">{{'NO_HOLOGRAM_AVAILABLE' | translate}}</div>
			<div class="hasFallbackMedia">{{'HOLOGRAM_UPLOAD_LANGSPECIFIC_MODEL' | translate}}</div>
			<div class="hasMedia">{{'CURRENT_HOLOGRAM' | translate }}</div>
		</manage-media2>
	</div>
	<div class="edit-hologram-meta-right-side">
		<div class="header">
			<div class="path-title-container">
				<div class="path">
					<div class="path-option" (click)="this.routeTo(appState.exhibitions)">{{'EXHIBITIONS' | translate}}
					</div>
					/
					<div class="path-option" (click)="this.routeTo(appState.images)">{{
						Tools.shortenString(contextService.currentExhibition && contextService.currentExhibition.title
						&& getLanguageValue(contextService.currentExhibition.title).length > 0 ?
						getLanguageValue(contextService.currentExhibition.title) : ('TRACKERS' | translate)) }}</div>
					/
					<div class="path-option" (click)="this.routeTo(appState.editHologram)">
						{{ Tools.shortenString(hologram && getLanguageValue(hologram.title).length > 0 ? getLanguageValue(hologram.title) : ('UNNAMED_HOLOGRAM' | translate)) }}
					</div>
					/
					<div class="last-path-option">{{'HOLOGRAM_POI' | translate}}</div>
				</div>
				<div class="arlayer-title-edit-container">
					<h1 class="edit-indicator">
						<i class="fa fa-pencil" aria-hidden="true"
							[ngStyle]="{'color': this.contentChanged ? '#ffc107' : 'black' }"></i>
					</h1>
					<div class="arlayer-title-container">
						<h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.hologram.title).length > 0">
							{{this.getLanguageValue(this.hologram.title)}}
						</h1>
						<h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.hologram.title).length == 0">
							<i>{{'UNNAMED_HOLOGRAM' | translate }}</i>
						</h1>
					</div>
				</div>
			</div>
			<aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
				(languageChanged)="this.updateLanguage($event)">
			</aacms-language-selector>
		</div>
		<div class="custom-hr"></div>
		<tabset [justified]="false">
			<tab heading="{{'HOLOGRAM_GENERAL_TAB' | translate}}" class="hologram-general-tab tab"
				(selectTab)="this.selectTab(tabType.GeneralTab)">
				<div>{{'TITLE' | translate }}</div>
				<div class="hologram-title-input">
					<div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
						<input class="form-control textInput" [(ngModel)]="this.hologram.title.de"
							maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
							(ngModelChange)="checkForContentChange()" />
						<div class="languageIndicator">
							<img class="language-button-image" src="../assets/flags/de.svg">
						</div>
						<div class="align-self-center px-2">
							{{this.config.getMaxTitleTextLength() - this.hologram.title.de.length}}
						</div>
					</div>
					<div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
						<input class="form-control textInput" [(ngModel)]="this.hologram.title.gb"
							maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
							(ngModelChange)="checkForContentChange()" />
						<div class="languageIndicator">
							<img class="language-button-image" src="../assets/flags/gb.svg">
						</div>
						<div class="align-self-center px-2">
							{{this.config.getMaxTitleTextLength() - this.hologram.title.gb.length}}
						</div>
					</div>
					<div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
						<input class="form-control textInput" [(ngModel)]="this.hologram.title.jp"
							maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
							(ngModelChange)="checkForContentChange()" />
						<div class="languageIndicator">
							<img class="language-button-image" src="../assets/flags/jp.svg">
						</div>
						<div class="align-self-center px-2">
							{{this.config.getMaxTitleTextLength() - this.hologram.title.jp.length}}
						</div>
					</div>
				</div>
				<div *ngIf="this.showContentTypesSelection()">{{ 'TYPE_OF_CONTENT_PAGE' | translate }}</div>
				<div *ngIf="this.showContentTypesSelection()" class="selection-container">
					<select class="selection" [(ngModel)]="this.hologram.contentType"
						(change)="this.checkForContentChange()">
						<ng-template ngFor let-type [ngForOf]="this.config.getEnabledContentTypes()">
							<option role="selection-item" [ngValue]="this.type">{{
								this.getStringNameForContentType(this.type) }}
							</option>
						</ng-template>
					</select>
				</div>
				<div class="checkbox-container">
					<label class="checkbox">
						<input type="checkbox" (click)="this.toggleHologramActivation()" [checked]="this.hologram.active">
						<span class="checkmark" triggers="hover"></span>
						<div class="checkbox-text">
							{{ 'HOLOGRAM_ACTIVE_INACTIVE' | translate }}
						</div>
					</label>
				</div>
				<div class="checkbox-container">
					<label class="checkbox">
						<input type="checkbox" (click)="this.toggleHologramCardsActivation()"
							[checked]="this.hologram.cardsActive">
						<span class="checkmark" triggers="hover"></span>
						<div class="checkbox-text">
							{{ 'HOLOGRAM_CARDS_ACTIVE_INACTIVE' | translate }}
						</div>
					</label>
				</div>
				<div *ngIf="this.mediaViewer.ready">
					<div class="model-scale-edit" *ngIf="this.hologram.model.has(this.currentLanguage)">
						<label>{{ 'HOLOGRAM_SCALE_FACTOR' | translate }}</label>
						<input-number min="0.000001" max="100"
							[value]="this.hologram.model.get(this.currentLanguage).scaleFactor"
							(valueChange)="this.setModelScaleFactor($event)"></input-number>
					</div>
					<br/>
					{{ 'HOLOGRAM_DIMENSIONS' | translate }}
					{{ 'HOLOGRAM_DIMENSIONS_WIDTH' | translate }}: {{ this.calculateScaledModelDimensions().x.toFixed(2) }}m,
					{{ 'HOLOGRAM_DIMENSIONS_HEIGHT' | translate }}: {{ this.calculateScaledModelDimensions().y.toFixed(2) }}m,
					{{ 'HOLOGRAM_DIMENSIONS_DEPTH' | translate }}: {{ this.calculateScaledModelDimensions().z.toFixed(2) }}m
				</div>
			</tab>
			<tab heading="{{ 'EDIT_TAB_TRIGGERS' | translate }}" class="triggers-tab tab"
				(selectTab)="this.selectTab(tabType.TriggersTab)">
				<div class="trigger-list-container">
					{{ 'HOLOGRAM_POSITION_TRIGGERS' | translate }}<br/>
					<leaflet-map #positionTriggerMap [features]="this.triggerMapFeatures"
						(featuresChange)="this.updateFromTriggerMapFeatures($event)"
						[onNewFeature]="this.onNewTrigger"
					></leaflet-map>
					<br/>
					<ng-template ngFor let-trigger [ngForOf]="this.hologram.positionTriggers" let-index="index">
						<div class="trigger-container" (click)="this.selectPositionTrigger(index)"
							[style.border]="this.triggerMapFeatures[index].properties.active ? '2px solid #87ceeb': '2px solid gainsboro'">
							<div class="trigger-position">
								<b>#{{index + 1}}</b>
							</div>
							<div class="trigger-meta">
								<span><b>{{ 'LATITUDE' | translate }}:</b> {{trigger.latitude.toFixed(5)}}°</span>
								<span><b>{{ 'LONGITUDE' | translate }}:</b> {{trigger.longitude.toFixed(5)}}°</span>
								<span>
									<b>{{ 'HOLOGRAM_TRIGGER_BEHAVIOR' | translate }}:</b>
									<input-trigger-behavior [(triggerBehavior)]="trigger.triggerBehavior" (triggerBehaviorChange)="this.updatedPositionTriggerType()">
									</input-trigger-behavior>
								</span>
							</div>
							<div class="update-buttons">
								<button class="trigger-edit-button" (click)="this.updatePositionTriggerRadius(index, $event)" [matTooltip]="'UPDATE_DISTANCE' | translate" triggers="hover">
									<span class="radius-button">{{trigger.radius}}m</span>
								</button>
								<button class="trigger-edit-button" (click)="this.removePositionTrigger(index)"
									[matTooltip]="'REMOVE_TRIGGER' | translate" triggers="hover">
									<i class="fa fa-trash exhibition-icon" aria-hidden="true"></i>
								</button>
							</div>
						</div>
					</ng-template>
				</div>
				<!--
				<div class="custom-hr"></div>
				<div class="trigger-list-container">
					{{ 'HOLOGRAM_OPTICAL_TRIGGERS' | translate }}<br/>
					<ng-template ngFor let-trigger [ngForOf]="this.hologram.opticalTriggers">
						{{trigger.mediaId}}
					</ng-template>
				</div>
				-->
			</tab>
			<tab heading="{{ 'HOLOGRAM_CARD_TAB' | translate }}" class="card-tab tab"
				(selectTab)="this.selectTab(tabType.CardTab)">
				<div class="smartphone-container">
					<aacms-info-card-editor [card]="this.hologram.card" [title]="this.hologram.title"
						[currentLanguage]="this.currentLanguage" [trackerFileData]="getTrackerFileData()"
						[isTracker]="false" (cardChange)="this.checkForContentChange()"
						[uiElements]="this.hologram.uiElements">
					</aacms-info-card-editor>
				</div>
			</tab>
			<tab heading="{{ 'HOLOGRAM_CONTENT_PAGE_TAB' | translate }}" class="content-page-tab tab"
				[disabled]="this.hologram.contentType !== contentTypes.Custom"
				*ngIf="this.isTabAllowed(contentTypes.Custom)" (selectTab)="this.selectTab(tabType.UiElementsTab)">
				<div class="smartphone-container">
					<aacms-content-page #smartphone class="smartphone" [(uiElements)]="this.hologram.uiElements"
						[isVisible]="currentTab === tabType.UiElementsTab" (uiElementsChange)="checkForContentChange()"
						[language]="this.currentLanguage">
					</aacms-content-page>
				</div>
			</tab>
			<tab heading="{{ 'HOLOGRAM_URL_PAGE_TAB' | translate }}" class="url-page-tab tab"
				[disabled]="this.hologram.contentType !== contentTypes.URL"
				*ngIf="this.isTabAllowed(contentTypes.URL)" (selectTab)="this.selectTab(tabType.NotifyEditorTab)">
				<div class="smartphone-container">
					<aacms-url-page [url]="this.hologram.url" (urlChange)="this.checkForContentChange()"
						[language]="this.currentLanguage">
					</aacms-url-page>
				</div>
			</tab>
			<tab heading="{{ 'TRACKER_POPUP_PAGE_TAB' | translate }}" class="popup-page-tab tab"
				(selectTab)="this.selectTab(tabType.PopupTab)">
				<div class="smartphone-container">
					<div class="checkbox-container">
						<label class="checkbox">
							<input type="checkbox" (click)="this.toggleUseCustomPopup()"
								[checked]="this.useCustomPopup">
							<span class="checkmark" triggers="hover"></span>
							<div class="checkbox-text">
								{{ 'USE_CUSTOM_POPUP' | translate }}
							</div>
						</label>
					</div>
					<aacms-popup-page #popupEditor class="smartphone" [(uiElements)]="this.hologram.popupElements"
						[isVisible]="currentTab === tabType.PopupTab" (uiElementsChange)="checkForContentChange()"
						[language]="this.currentLanguage"
						title="{{ 'POPUP_TITLE_HOLOGRAM_ELEMENT' | translateWith:this.currentLanguage }}"
						[enabled]="this.useCustomPopup">
						<p popup-left-side-above>
							{{ 'TRACKER_POPUP_PAGE_HELP' | translate }}
						</p>
						<ng-container popup-editor-default-content>
							<ng-template [ngIf]="!this.useCustomPopup">
								<div class="element">
									<div class="image-element">
										<img class="image" src="../../assets/popups/Hologram_Tut.png">
									</div>
								</div>
								<div class="element">
									<div class="text-element">
										{{ 'POPUP_TEXT_HOLOGRAM_ELEMENT' | translateWith:this.currentLanguage }}
									</div>
								</div>
							</ng-template>
						</ng-container>
					</aacms-popup-page>
				</div>
			</tab>
		</tabset>
		<div class="custom-hr"></div>
		<div class="button-row row">
			<button type="submit" class="btn btn-secondary custom-button" (click)="returnToHologram()">
				{{'BACK_TO_HOLOGRAM' | translate}}
			</button>
			<button type="submit" class="btn btn-warning custom-button" [disabled]="!this.contentChanged"
				(click)="openModal(cancelModalTemplate)">
				{{'DISCARD' | translate }}
			</button>
			<button type="submit" class="btn btn-success custom-button" [disabled]="!this.contentChanged"
				(click)="this.onSave()">
				{{'SAVE_CHANGES' | translate }}
			</button>
		</div>
	</div>
</div>

<ng-template #cancelModalTemplate>
	<div class="modal-header">
		<h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
	</div>
	<div class="modal-footer">
		<button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate
			}}</button>
		<button (click)="discard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
	</div>
</ng-template>