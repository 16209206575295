<div class="meta">
	<div class="smartphone-left-side">
		<ng-template #editText [ngIf]="currentSelectedIs(this.elementType.TEXT)">
			<div class="poi-description" ondragenter="event.preventDefault(); event.dataTransfer.dropEffect = 'none'"
				ondragover="event.preventDefault(); event.dataTransfer.dropEffect = 'none'">
				<richtext-editor #richtextde *ngIf="this.isLanguage(Language.DE)"
					[(ngModel)]="$any(this.currentSelected).text.de" (ngModelChange)="acceptChangesToText($event)"
					[config]="editorConfig"></richtext-editor>
				<richtext-editor #richtextgb *ngIf="this.isLanguage(Language.GB)"
					[(ngModel)]="$any(this.currentSelected).text.gb" (ngModelChange)="acceptChangesToText($event)"
					[config]="editorConfig"></richtext-editor>
				<richtext-editor #richtextjp *ngIf="this.isLanguage(Language.JP)"
					[(ngModel)]="$any(this.currentSelected).text.jp" (ngModelChange)="acceptChangesToText($event)"
					[config]="editorConfig"></richtext-editor>
			</div>
		</ng-template>

		<ng-template #editImage [ngIf]="currentSelectedIs(this.elementType.IMAGE)">
			<manage-media2 [elementType]="ElementType.IMAGE"
				[mediaLoader]="this.mediaLoader.getLoader()" [currentLanguage]="this.language"
				[mediaData]="$any(this.currentSelected).imageObject"
				[maxFileSize]="config.getMaxPoiContentFileSize()"
				(mediaChanged)="this.fireChangeEvent()">
			</manage-media2>
		</ng-template>

		<ng-template #editAudio [ngIf]="currentSelectedIs(this.elementType.AUDIO)">
			<manage-media2 [elementType]="ElementType.AUDIO"
				[mediaLoader]="this.mediaLoader.getLoader()" [currentLanguage]="this.language"
				[mediaData]="$any(this.currentSelected).audioObject"
				[maxFileSize]="config.getPoiStyleFileSize()"
				(mediaChanged)="this.fireChangeEvent()">
			</manage-media2>
		</ng-template>

		<ng-template #editVideo [ngIf]="currentSelectedIs(this.elementType.VIDEO)">
			<manage-media2 [elementType]="ElementType.VIDEO"
				[mediaLoader]="this.mediaLoader.getLoader()" [currentLanguage]="this.language"
				[mediaData]="$any(this.currentSelected).videoObject"
				[maxFileSize]="config.getMaxVideoFileSize()"
				(mediaChanged)="this.fireChangeEvent()">
			</manage-media2>
		</ng-template>

		<ng-template #editModel [ngIf]="currentSelectedIs(this.elementType.MODEL)">
			<manage-media2 [elementType]="ElementType.MODEL"
				[mediaLoader]="this.mediaLoader.getLoader()" [currentLanguage]="this.language"
				[mediaData]="$any(this.currentSelected).modelObject"
				[maxFileSize]="config.getMaxPoiContentFileSize()"
				(mediaChanged)="this.fireChangeEvent()">
			</manage-media2>
		</ng-template>

		<ng-template #editMap [ngIf]="currentSelectedIs(this.elementType.MAP)">
			<manage-media *ngIf="!this.currentSelected.isMandatory" [elementType]="this.elementType.MAP"
				[mapData]="$any(this.currentSelected)" (upateFile)="updateMapFile($event)"
				(removeMediaFile)="removeMapFile()">
			</manage-media>
			<div *ngIf="!this.currentSelected.isMandatory" class="custom-hr"></div>
			<div class="map-settings-container" *ngIf="$any(this.currentSelected).mapObject !== undefined">
				<div class="notification-text">{{'BOUNDING_TEXT' | translate }}</div>
				<button type="submit" class="btn btn-primary bounding-button" (click)="setBoundingBox()">
					{{'BOUNDING_BUTTON_TEXT' | translate}}
				</button>
				<div class="bounding-box-values-container-header">
					{{'BOUNDINGBOX_VALUES_BOTTOMLEFT' | translate}}
				</div>
				<div class="bounding-box-values-container">
					<div class="bounding-box-value-container">
						<div class="bounding-box-value-header">
							{{'PLACE_LOCATION_LON_INPUT' | translate}}
						</div>
						<div class="bounding-box-value">
							{{this.getMapProperties().bottomLeft.lon}}
						</div>
					</div>
					<div class="bounding-box-value-container">
						<div class="bounding-box-value-header">
							{{'PLACE_LOCATION_LAT_INPUT' | translate}}
						</div>
						<div class="bounding-box-value">
							{{this.getMapProperties().bottomLeft.lat}}
						</div>
					</div>
				</div>
				<div class="bounding-box-values-container-header">
					{{'BOUNDINGBOX_VALUES_TOPRIGHT' | translate}}
				</div>
				<div class="bounding-box-values-container">
					<div class="bounding-box-value-container">
						<div class="bounding-box-value-header">
							{{'PLACE_LOCATION_LON_INPUT' | translate}}
						</div>
						<div class="bounding-box-value">
							{{this.getMapProperties().topRight.lon}}
						</div>
					</div>
					<div class="bounding-box-value-container">
						<div class="bounding-box-value-header">
							{{'PLACE_LOCATION_LAT_INPUT' | translate}}
						</div>
						<div class="bounding-box-value">
							{{this.getMapProperties().topRight.lat}}
						</div>
					</div>
				</div>
			</div>
		</ng-template>

		<ng-template #noneSelected [ngIf]="currentSelectedIs('None')">
			<i>{{'NO_ELEMENT_SELECTED' | translate }}</i>
		</ng-template>
	</div>
	<div class="smartphone-right-side">
		<div class="custom-row">
			<div class="smartphone-with-buttons">
				<div class="new-element-button-row row">
					<div class="new-element-button">
						<button type="submit" class="btn btn-light" (click)="addNewElement(this.elementType.IMAGE)"
							[matTooltip]="'ADD_IMAGE_ELEMENT' | translate">
							<i class="fa fa-image" aria-hidden="true"></i>
						</button>
					</div>
					<div class="new-element-button">
						<button type="submit" class="btn btn-light" (click)="addNewElement(this.elementType.VIDEO)"
							[matTooltip]="'ADD_VIDEO_ELEMENT' | translate">
							<i class="fa fa-film" aria-hidden="true"></i>
						</button>
					</div>
					<div class="new-element-button">
						<button type="submit" class="btn btn-light" (click)="addNewElement(this.elementType.TEXT)"
							[matTooltip]="'ADD_TEXT_ELEMENT' | translate">
							<i class="fa fa-paragraph" aria-hidden="true"></i>
							<!-- <img src="../../assets/text_fields-24px.svg"/> -->
						</button>
					</div>
					<div class="new-element-button">
						<button type="submit" class="btn btn-light" (click)="addNewElement(this.elementType.AUDIO)"
							[matTooltip]="'ADD_AUDIO_ELEMENT' | translate">
							<i class="fa fa-music" aria-hidden="true"></i>
						</button>
					</div>
					<div class="new-element-button">
						<button type="submit" class="btn btn-light" (click)="addNewElement(this.elementType.MODEL)"
							[matTooltip]="'ADD_MODEL_ELEMENT' | translate">
							<i class="fa fa-cube" aria-hidden="true"></i>
							<!-- <img src="../../assets/3d_rotation-24px.svg"/> -->
						</button>
					</div>
					<div class="new-element-button">
						<button type="submit" class="btn btn-light" (click)="addNewElement(this.elementType.MAP)"
							[matTooltip]="'ADD_MAP_ELEMENT' | translate">
							<i class="fa fa-map" aria-hidden="true"></i>
						</button>
					</div>
				</div>
				<div #smartphone [ngStyle]="getSmartphoneStyle()" class="smartphone">
					<div class="nav-button-container">
						<button class="nav-button back" [disabled]="!this.showBackButton" (click)="onNavigateBack()">
							<i class="fa fa-arrow-left" aria-hidden="true"></i>
						</button>
					</div>
					<ng-template ngFor let-item [ngForOf]="this.uiElements">
						<div [id]="'element-'+item.position" class="element" (mouseup)="setCurrentSelectedElement(item)"
							(mouseenter)="this.hovered = item.position" (mouseleave)="this.hovered = ''">
							<div *ngIf="!item.active" class="notVisible">
								<i class="fa fa-eye-slash not-visible-icon" aria-hidden="true"></i>
							</div>
							<ng-template #text [ngIf]="item.type === this.elementType.TEXT">
								<div class="text-element">
									<div *ngIf="!isTextElementEmpty(item)">
										<div class="text" *ngIf="isLanguage(Language.DE)"
											[innerHTML]="this.getSanitizedText($any(item).text.de)"></div>
										<div class="text" *ngIf="isLanguage(Language.GB)"
											[innerHTML]="this.getSanitizedText($any(item).text.gb)"></div>
										<div class="text" *ngIf="isLanguage(Language.JP)"
											[innerHTML]="this.getSanitizedText($any(item).text.jp)"></div>
									</div>
									<div class="empty-element-text" *ngIf="isTextElementEmpty(item)">
										<i>{{'TEXT_ELEMENT' | translate}} - {{'EMPTY_ELEMENT' | translate}}</i>
									</div>
								</div>
							</ng-template>

							<ng-template #audio [ngIf]="item.type === this.elementType.AUDIO">
								<div class="audio-element element-with-multilang-fallback">
									<div *ngIf="!isAudioElementEmpty(item)">
										<div class="multilang-fallback-overlay"
											*ngIf="!$any(item).audioObject.has(this.language)">
											<i>{{'AUDIO_ELEMENT_MULTILANG_FALLBACK' | translate}}</i>
										</div>
										<aacms-media-viewer
											[mediaId]="$any(item).audioObject.getWithFallback(this.language)?.mediaId"
											[backgroundStyle]="white"
											[autoSize]="true"
											(focus)="this.setCurrentSelectedElement(item);"
										></aacms-media-viewer>
									</div>
									<div class="empty-element-text" *ngIf="isAudioElementEmpty(item)">
										<i>{{'AUDIO_ELEMENT' | translate}} - {{'EMPTY_ELEMENT' | translate}}</i>
									</div>
								</div>
							</ng-template>

							<ng-template #video [ngIf]="item.type === this.elementType.VIDEO">
								<div class="video-element element-with-multilang-fallback">
									<div *ngIf="!isVideoElementEmpty(item)">
										<div class="multilang-fallback-overlay"
											*ngIf="!$any(item).videoObject.has(this.language)">
											<i>{{'VIDEO_ELEMENT_MULTILANG_FALLBACK' | translate}}</i>
										</div>
										<aacms-media-viewer
											[mediaId]="$any(item).videoObject.getWithFallback(this.language)?.mediaId"
											[autoSize]="true"
											[backgroundStyle]="white"
											(focus)="this.setCurrentSelectedElement(item);"
										></aacms-media-viewer>
									</div>
									<div class="empty-element-text" *ngIf="isVideoElementEmpty(item)">
										<i>{{'VIDEO_ELEMENT' | translate}} - {{'EMPTY_ELEMENT' | translate}}</i>
									</div>
								</div>
							</ng-template>

							<ng-template #image [ngIf]="item.type === this.elementType.IMAGE">
								<div class="image-element element-with-multilang-fallback"
									[ngStyle]="getImageElementStyle(item)">
									<div *ngIf="!isImageElementEmpty(item)">
										<div class="multilang-fallback-overlay"
											*ngIf="!$any(item).imageObject.has(this.language)">
											<i>{{'IMAGE_ELEMENT_MULTILANG_FALLBACK' | translate}}</i>
										</div>
										<aacms-media-viewer class="imageViewer" *ngIf="!isImageElementEmpty(item)"
											[mediaId]="$any(item).imageObject.getWithFallback(this.language)?.mediaId"
											[autoSize]="true"
											backgroundStyle="white">
										</aacms-media-viewer>
									</div>
									<div class="empty-element-text" *ngIf="isImageElementEmpty(item)">
										<i>{{'IMAGE_ELEMENT' | translate}} - {{'EMPTY_ELEMENT' | translate}}</i>
									</div>
								</div>
							</ng-template>

							<ng-template #model [ngIf]="item.type === this.elementType.MODEL">
								<div class="model-element element-with-multilang-fallback">
									<div *ngIf="!isModelElementEmpty(item)">
										<div class="multilang-fallback-overlay"
											*ngIf="!$any(item).modelObject.has(this.language)">
											<i>{{'MODEL_ELEMENT_MULTILANG_FALLBACK' | translate}}</i>
										</div>
										<aacms-media-viewer class="modelViewer" *ngIf="!isModelElementEmpty(item)"
											[mediaId]="$any(item).modelObject.getWithFallback(this.language)?.mediaId"
											[autoSize]="true"
											backgroundStyle="white">
										</aacms-media-viewer>
									</div>
									<div class="empty-element-text" *ngIf="isModelElementEmpty(item)">
										<i>{{'MODEL_ELEMENT' | translate}} - {{'EMPTY_ELEMENT' | translate}}</i>
									</div>
								</div>
							</ng-template>

							<ng-template #map [ngIf]="item.type === this.elementType.MAP">
								<div class="map-element" (click)="setCurrentSelectedElement(item)">
									<div class="map-container" [hidden]="isMapElementEmpty(item)">
										<div class="map" [id]="'map-'+item.position"></div>
									</div>
									<div class="empty-element-text" *ngIf="isMapElementEmpty(item)">
										<i>{{'MAP_ELEMENT' | translate}} - {{'EMPTY_ELEMENT' | translate}}</i>
									</div>
								</div>
							</ng-template>
						</div>
					</ng-template>
				</div>
				<div class="hint">
					<i>{{ 'PREVIEW_MAY_LOOK_DIFFERENT' | translate }}</i>
				</div>
				<div class="screen-size-button-row">
					<div class="screen-size-button-group btn-group" btnRadioGroup
						[(ngModel)]="currentSelectedResolution">
						<label class="btn btn-light" btnRadio="small" uncheckable tabindex="0" role="button"
							(click)="changeSmartphoneStyle('14','7')">{{'SMALL_PHONE' | translate}}</label>
						<label class="btn btn-light" btnRadio="big" uncheckable tabindex="0" role="button"
							(click)="changeSmartphoneStyle('16','8')">{{'BIG_PHONE' | translate}}</label>
						<label class="btn btn-light" btnRadio="tablet" uncheckable tabindex="0" role="button"
							(click)="changeSmartphoneStyle('16','11')">{{'TABLET' | translate}}</label>
					</div>
					<div class="screen-rotate-button">
						<button type="button" class="btn btn-light" (click)="rotateSmartphone()"
							[matTooltip]="'ROTATE_SMARTPHONE' | translate">
							<img class="screen-rotate-button-image" src="../../assets/screen_rotation-24px.svg" />
						</button>
					</div>
				</div>
			</div>
			<div class="control-buttons">
				<div class="control-button">
					<button type="submit" class="btn btn-light" (click)="moveElementUp()"
						[disabled]="this.currentSelected == undefined" [matTooltip]="'MOVE_ELEMENT_UP' | translate"
						placement="left">
						<i class="fa fa-angle-up" aria-hidden="true"></i>
					</button>
				</div>
				<div class="control-button">
					<button type="submit" class="btn btn-light" (click)="moveElementDown()"
						[disabled]="this.currentSelected == undefined" [matTooltip]="'MOVE_ELEMENT_DOWN' | translate"
						placement="left">
						<i class="fa fa-angle-down" aria-hidden="true"></i>
					</button>
				</div>
				<div class="control-button">
					<button type="submit" class="btn btn-light" (click)="this.updateVisibility()"
						[class.active]="this.currentSelected && !this.currentSelected.active"
						[disabled]="this.currentSelected == undefined" [matTooltip]="'HIDE_ELEMENT' | translate"
						placement="left">
						<i class="fa fa-eye-slash" aria-hidden="true"></i>
					</button>
				</div>
				<div class="control-button">
					<button type="submit" class="btn btn-light" (click)="deleteElement()"
						[disabled]="this.currentSelected === undefined || this.currentSelected.isMandatory"
						[matTooltip]="'DELETE_ELEMENT' | translate" placement="left">
						<i class="fa fa-trash" aria-hidden="true"></i>
					</button>
				</div>
				<div class="control-button">
					<button type="submit" class="btn btn-light" (click)="unsetCurrentSelected()"
						[disabled]="this.currentSelected == undefined" [matTooltip]="'UNSELECT_ELEMENT' | translate"
						placement="left">
						<i class="fa fa-times" aria-hidden="true"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="custom-hr"></div>
		<div class="single-element-notification">
			<i>{{'SMARTPHONE_SINGLE_ELEMENT_INFO' | translate}}</i>
		</div>
	</div>
</div>