import { Component, OnInit } from '@angular/core';
import { Config } from '../Config';
import { CurrentContextService } from '../services/currentContext.service';
import { AppState } from '../current.app.state';

@Component({
  selector: 'terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit{

  public config: Config = new Config();

  public subdomainName: string = 'xy.augmented-art.de';

  public constructor(private contextService: CurrentContextService) { }

  public ngOnInit(): void {
    this.contextService.setCurrentState(AppState.termsOfUse);

    this.subdomainName = window.location.hostname;
  }
}
