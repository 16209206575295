<div class="mona">
  <img class="mona-image" [src]="path">
</div>

<div class="login">

  <div class="error-message alert alert-danger" role="alert" *ngIf="wrongCredentials">
    {{'WRONG_CREDENTIALS' | translate }}
  </div>

  <div class="input-group input-group-lg">
    <input type="text" class="form-control" aria-label="Large" [(ngModel)]="username"
      [placeholder]="'USERNAME' | translate" aria-describedby="inputGroup-sizing-sm" autofocus>
  </div>

  <div *ngIf="!showPassword" class="password-group input-group input-group-lg custom-input-group">
    <input type="password" class="form-control password-group-textarea textInput" id="password" aria-label="Large"
      [(ngModel)]="password" [placeholder]="'PASSWORD' | translate" aria-describedby="inputGroup-sizing-sm"
      (keydown.enter)="login()">
    <div class="languageIndicator">
      <div class="password-group-icon" (click)="togglePassword()">
        <i class="fa fa-eye" aria-hidden="true"></i>
      </div>
    </div>
  </div>

  <div *ngIf="showPassword" class="password-group input-group input-group-lg custom-input-group">
    <input type="text" class="form-control password-group-textarea textInput" id="password" aria-label="Large"
      [(ngModel)]="password" [placeholder]="'PASSWORD' | translate" aria-describedby="inputGroup-sizing-sm"
      (keydown.enter)="login()">
    <div class="languageIndicator">
      <div class="password-group-icon" (click)="togglePassword()">
        <i class="fa fa-eye-slash" aria-hidden="true"></i>
      </div>
    </div>
  </div>

  <div class="input-group mb-3 remember-me">
    <input class="remember-me-checkbox" type="checkbox" [(ngModel)]="rememberMe">
    <div class="remember-me-checkbox-text">
      <a>{{'REMEMBER_ME' | translate }}</a>
    </div>
  </div>

  <div class="login-button">
    <button class="btn btn-primary btn-block" (click)="login()" type="submit"
      [disabled]="loginDisabled || this.username.length === 0 || this.password.length === 0">{{'LOGIN' | translate }}</button>
  </div>
</div>

<div class="impressum-container">
  <span *ngIf="this.versionString" [matTooltip]="this.versionHash" triggers="hover">
    {{versionString}}
  </span>
  <a class="impressum" href="https://augmented-art.de/impressum.html">Impressum</a>
  <a class="impressum" href="/terms-of-use">Nutzungsbedingungen</a>
</div>
