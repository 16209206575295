<div *ngIf="this.place" class="edit-place-meta-main-content">
    <div class="edit-place-meta-left-side">
        <aacms-loader class="imageLoader" size="128px" *ngIf="!this.place">
        </aacms-loader>
        <div class="map-container"><div id="map"></div></div>
    </div>
    <div class="edit-place-meta-right-side">
      <div class="header">
        <div class="path-title-container">
          <div class="path">
            <div class="path-option" (click)="this.routeTo(appState.exhibitions)">{{'EXHIBITIONS' | translate}}</div>
            /
            <div class="path-option" (click)="this.routeTo(appState.images)">{{ Tools.shortenString(contextService.currentExhibition && contextService.currentExhibition.title && getLanguageValue(contextService.currentExhibition.title).length > 0 ? getLanguageValue(contextService.currentExhibition.title) : ('TRACKERS' | translate)) }}</div>
            /
            <div class="path-option" (click)="this.routeTo(appState.editPlace)">{{ Tools.shortenString(place && getLanguageValue(place.title).length > 0 ? getLanguageValue(place.title) : ('UNNAMED_PLACE' | translate)) }}</div>
            /
            <div class="last-path-option">{{'PLACE_POI' | translate}}</div>
          </div>
          <div class="arlayer-title-edit-container">
            <h1 class="edit-indicator">
              <i class="fa fa-pencil" aria-hidden="true"
                [ngStyle]="{'color': this.contentChanged ? '#ffc107' : 'black' }"></i>
            </h1>
            <div class="arlayer-title-container">
              <h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.place.title).length > 0">
                {{this.getLanguageValue(this.place.title)}}
              </h1>
              <h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.place.title).length == 0">
                <i>{{'UNNAMED_PLACE' | translate }}</i>
              </h1>
            </div>
          </div>
        </div>
        <aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
          (languageChanged)="this.updateLanguage($event)">
        </aacms-language-selector>
      </div>
      <div class="custom-hr"></div>
      <tabset [justified]="false">
        <tab heading="{{'PLACE_GENERAL_TAB' | translate}}" class="place-general-tab tab" (selectTab)="this.currentTab = tabType.GeneralTab">
          <div>{{'TITLE' | translate }}</div>
          <div class="place-title-input">
            <div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
              <input class="form-control textInput" [(ngModel)]="this.place.title.de" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
                (ngModelChange)="checkForContentChange()" />
              <div class="languageIndicator">
                <img class="language-button-image" src="../assets/flags/de.svg">
              </div>
              <div class="align-self-center px-2">
                {{this.config.getMaxTitleTextLength() - this.place.title.de.length}}
              </div>
            </div>
            <div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
              <input class="form-control textInput" [(ngModel)]="this.place.title.gb" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
                (ngModelChange)="checkForContentChange()" />
              <div class="languageIndicator">
                <img class="language-button-image" src="../assets/flags/gb.svg">
              </div>
              <div class="align-self-center px-2">
                {{this.config.getMaxTitleTextLength() - this.place.title.gb.length}}
              </div>
            </div>
            <div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
              <input class="form-control textInput" [(ngModel)]="this.place.title.jp" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
                (ngModelChange)="checkForContentChange()" />
              <div class="languageIndicator">
                <img class="language-button-image" src="../assets/flags/jp.svg">
              </div>
              <div class="align-self-center px-2">
                {{this.config.getMaxTitleTextLength() - this.place.title.jp.length}}
              </div>
            </div>
          </div>
          <div *ngIf="this.showContentTypesSelection()">{{ 'TYPE_OF_CONTENT_PAGE' | translate }}</div>
          <div *ngIf="this.showContentTypesSelection()" class="selection-container">
            <select class="selection" [(ngModel)]="this.place.contentType" (change)="this.checkForContentChange()">
              <ng-template ngFor let-type [ngForOf]="this.config.getEnabledContentTypes()">
                <option role="selection-item" [ngValue]="this.type">{{ this.getStringNameForContentType(this.type) }}
                </option>
              </ng-template>
            </select>
          </div>
          <div class="checkbox-container">
            <label class="checkbox">
              <input type="checkbox" (click)="this.togglePlaceActivation()" [checked]="this.place.active">
              <span class="checkmark" triggers="hover"></span>
              <div class="checkbox-text">
                {{ 'PLACE_ACTIVE_INACTIVE' | translate }}
              </div>
            </label>
          </div>
          <div class="checkbox-container">
            <label class="checkbox">
              <input type="checkbox" (click)="this.toggleArPlaceCardsActivation()" [checked]="this.place.cardsActive">
              <span class="checkmark" triggers="hover"></span>
              <div class="checkbox-text">
                {{ 'PLACE_CARDS_ACTIVE_INACTIVE' | translate }}
              </div>
            </label>
          </div>
          <div>{{ 'PLACE_TRIGGER_BEHAVIOR' | translate }}</div>
          <div class="selection-container">
            <input-trigger-behavior [(triggerBehavior)]="this.place.triggerBehavior" (triggerBehaviorChange)="this.checkForContentChange()">
            </input-trigger-behavior>
          </div>
          <div>{{'PLACE_LOCATION_INPUT' | translate }}</div>
          <div class="location-input-container">
              <div class="location-input-group">
                <div>{{'PLACE_LOCATION_LON_INPUT' | translate }}</div>
                <input class="form-control location-input" [(ngModel)]="this.lonInput" (ngModelChange)="checkForContentChange(); this.redrawCircle();" autonumeric [options]="lonLatInputConfig"/>
              </div>
              <div class="location-input-group">
                <div>{{'PLACE_LOCATION_LAT_INPUT' | translate }}</div>
                <input class="form-control location-input" [(ngModel)]="this.latInput" (ngModelChange)="checkForContentChange(); this.redrawCircle();" autonumeric [options]="lonLatInputConfig"/>
              </div>
          </div>
          <button type="submit" class="btn btn-danger position-clear-button" (click)="this.lonInput = ''; this.latInput = '';">
            {{'CLEAR_POSITION_INPUT' | translate}}
          </button>
          <div class="example-text">
            <i>{{'LON_LAT_EXAMPLETEXT' | translate}}</i>
          </div>
          <div>{{'PLACE_RADIUS_INPUT' | translate }}</div>
          <div class="radius-input-container">
            <input class="form-control radius-input" [(ngModel)]="this.radiusInput" (ngModelChange)="checkForContentChange(); this.redrawCircle();" autonumeric [options]="radiusInputConfig"/>
          </div>
        </tab>
        <tab heading="{{ 'PLACE_CARD_TAB' | translate }}" class="card-tab tab" (selectTab)="this.currentTab = tabType.CardTab">
          <div class="smartphone-container">
            <aacms-info-card-editor [card]="this.place.card" [title]="this.place.title"
              [currentLanguage]="this.currentLanguage" [trackerFileData]="this.placeDataMap" [isTracker]="false"
              (cardChange)="this.checkForContentChange()" [uiElements]="this.place.uiElements">
            </aacms-info-card-editor>
          </div>
        </tab>
        <tab heading="{{ 'PLACE_CONTENT_PAGE_TAB' | translate }}" class="content-page-tab tab"
          *ngIf="this.isTabAllowed(contentTypes.Custom)" (selectTab)="this.currentTab = tabType.UiElementsTab">
          <div class="smartphone-container">
            <aacms-content-page #smartphone class="smartphone" [(uiElements)]="this.place.uiElements" [isVisible]="currentTab === tabType.UiElementsTab"
              (uiElementsChange)="checkForContentChange()" [language]="this.currentLanguage">
            </aacms-content-page>
          </div>
        </tab>
        <tab heading="{{ 'PLACE_URL_PAGE_TAB' | translate }}" class="url-page-tab tab"
          *ngIf="this.isTabAllowed(contentTypes.URL)" (selectTab)="this.currentTab = tabType.NotifyEditorTab">
          <div class="smartphone-container">
            <aacms-url-page [url]="this.place.url" (urlChange)="this.checkForContentChange()"
              [language]="this.currentLanguage">
            </aacms-url-page>
          </div>
        </tab>
        <tab heading="{{ 'PLACE_NOTIFY_EDITOR_TAB' | translate }}" class="notify-editor-page-tab tab" (selectTab)="this.currentTab = tabType.UrlTab">
          <div class="smartphone-container">
            <aacms-location-notify-editor [place]="this.place" (placeChange)="this.checkForContentChange()" [trackerFileData]="this.placeDataMap"
              [currentLanguage]="this.currentLanguage">
            </aacms-location-notify-editor>
          </div>
        </tab>
        <tab heading="{{ 'TRACKER_POPUP_PAGE_TAB' | translate }}" class="popup-page-tab tab"
          (selectTab)="this.currentTab = tabType.PopupTab">
          <div class="smartphone-container">
            <div class="checkbox-container">
              <label class="checkbox">
                <input type="checkbox" (click)="this.toggleUseCustomPopup()" [checked]="this.useCustomPopup">
                <span class="checkmark" triggers="hover"></span>
                <div class="checkbox-text">
                  {{ 'USE_CUSTOM_POPUP' | translate }}
                </div>
              </label>
            </div>
            <aacms-popup-page #popupEditor class="smartphone" [(uiElements)]="this.place.popupElements" [isVisible]="currentTab === tabType.PopupTab"
              (uiElementsChange)="checkForContentChange()" [language]="this.currentLanguage" title="{{ 'POPUP_TITLE_PLACE_ELEMENT' | translateWith:this.currentLanguage }}"
              [enabled]="this.useCustomPopup">
              <p popup-left-side-above>
                {{ 'TRACKER_POPUP_PAGE_HELP' | translate }}
              </p>
              <ng-container popup-editor-default-content>
                <ng-template [ngIf]="!this.useCustomPopup">
                  <div class="element">
                    <div class="image-element">
                      <img class="image" src="../../assets/popups/Place_Tut.png">
                    </div>
                  </div>
                  <div class="element">
                    <div class="text-element">
                      {{ 'POPUP_TEXT_PLACE_ELEMENT' | translateWith:this.currentLanguage }}
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </aacms-popup-page>
          </div>
        </tab>
      </tabset>
      <div class="custom-hr"></div>
      <div class="button-row row">
        <button type="submit" class="btn btn-secondary custom-button" (click)="returnToPlace()">
          {{'BACK_TO_PLACE' | translate}}
        </button>
        <button type="submit" class="btn btn-warning custom-button"
          [disabled]="!this.contentChanged"
          (click)="openModal(cancelModalTemplate)">
          {{'DISCARD' | translate }}
        </button>
        <button type="submit" class="btn btn-success custom-button"
          [disabled]="!this.contentChanged"
          (click)="this.save()">
          {{'SAVE_CHANGES' | translate }}
        </button>
      </div>
    </div>
  </div>

  <ng-template #cancelModalTemplate>
    <div class="modal-header">
      <h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
    </div>
    <div class="modal-footer">
      <button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
      <button (click)="discard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
    </div>
  </ng-template>
