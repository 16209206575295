<div>
  <dropin *ngIf="((this.mediaData === undefined || this.mediaData.file === undefined) && this.elementType !== elementTypeEnum.MAP) || ((this.mapData !== undefined && this.mapData.mapObject === undefined) && this.elementType === elementTypeEnum.MAP)" class="dropin" (sendFiles)="handleFilesFromDropin($event)" [maxFileSize]="getMaxContentFileSize()"
    [multipleAllowed]="false" [accept]="getAcceptedFileTypes()">
  </dropin>
  <div class="current-files">
    <div *ngIf="this.mediaData !== undefined && this.mediaData.file !== undefined && this.elementType !== elementTypeEnum.MAP">
      <div class="current-file">
        <span class="current-file-name" *ngIf="this.mediaData.file !== undefined">
          {{this.mediaData.file.name}}
        </span>
        <span *ngIf="this.mediaData.fileSafeUrl" [matTooltip]="'DOWNLOAD_MEDIA' | translate"
        triggers="hover" class="download-file"(click)="downloadFileToSystem(this.mediaData)">
          <i class="fa fa-download"></i>
        </span>
        <span class="file-delete" (click)="removeModel()" [matTooltip]="'REMOVE_MEDIA' | translate" triggers="hover">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
    </div>
    <div *ngIf="this.mapData !== undefined && this.mapData.mapObject !== undefined && this.elementType === elementTypeEnum.MAP">
      <div class="current-file">
        <span class="current-file-name" *ngIf="this.mapData.filename !== undefined">
          {{this.mapData.filename}}
        </span>
        <span *ngIf="this.mapData.mapObject !== undefined" class="download-file" (click)="downloadMapDataToSystem(this.mapData)" [matTooltipPosition]="'DOWNLOAD_MEDIA' | translate"
        triggers="hover">
          <i class="fa fa-download"></i>
        </span>
        <span class="file-delete" (click)="removeModel()" [matTooltip]="'REMOVE_MEDIA' | translate" triggers="hover">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
    </div>
  </div>
</div>
