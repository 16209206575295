<div *ngIf="this.isReady()">
	<ng-content *ngIf="!this.hasAny()" select=".hasNone"></ng-content>
	<ng-content *ngIf="this.hasAny() === true && this.shouldShowDropin()" select=".hasFallbackMedia"></ng-content>
	<dropin *ngIf="this.shouldShowDropin()"
		class="dropin" (sendFiles)="handleFilesFromDropin($event)" [maxFileSize]="getMaxContentFileSize()"
		[multipleAllowed]="false" [accept]="getAcceptedFileTypes()">
	</dropin>
	<div class="current-files" *ngIf="this.shouldShowCurrentFiles()">
		<ng-content select=".hasMedia"></ng-content>
		<div>
			<div class="current-file">
				<span class="current-file-name" *ngIf="this.getCurrentFile()">
					{{this.getCurrentFile().getName()}}
				</span>
				<span *ngIf="this.getCurrentFile()?.getRawFile()" [matTooltip]="'DOWNLOAD_MEDIA' | translate" triggers="hover"
					class="download-file" (click)="this.downloadFileToSystem(this.getCurrentFile())">
					<i class="fa fa-download"></i>
				</span>
				<span class="file-delete" (click)="unsetCurrentFile()" [matTooltip]="'REMOVE_MEDIA' | translate"
					triggers="hover">
					<i class="fa fa-times" aria-hidden="true"></i>
				</span>
			</div>
		</div>
	</div>
</div>