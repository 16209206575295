<div class="flex-box">
  <div *ngIf="currentExhibition" class="exhibiton-name-container">
    <b>{{'EXHIBITION' | translate }}</b>
    <div class="exhibiton-name" *ngIf="getLanguageValue(currentExhibition.title)">
      {{getLanguageValue(currentExhibition.title)}}
    </div>
    <div class="exhibiton-name" *ngIf="!getLanguageValue(currentExhibition.title)">
      <i>{{'UNNAMED_EXHIBITION' | translate}}</i>
    </div>
  </div>
  <div class="searchbar-header">
    <input [(ngModel)]="searchText" type="text" class="form-control form-control-lg searchbar"
      [placeholder]="'SEARCH' | translate" aria-label="Suche" aria-describedby="basic-addon2"
      [matTooltip]="'FILTER_TRACKERS_BY_NAME' | translate" triggers="hover">
    <div *ngIf="this.currentExhibition" class="new-tracker-buttons">
      <button type="button" class="btn btn-secondary new-tracker-button"
        (click)="this.goToExhibitions()">{{'BACK_TO_EXHIBITIONS' | translate }}
      </button>
      <button *ngIf="!config.isCuboidTrackerEnabled() && !config.isToursEnabled()" class="btn btn-success new-tracker-button"
        (click)="this.createImages()">{{'CREATE_NEW_IMAGES' | translate }}
      </button>
      <div *ngIf="config.isCuboidTrackerEnabled() || config.isToursEnabled()" class="btn-group" dropdown placement="bottom right">
        <button id="button-alignment" dropdownToggle type="button"
          class="btn btn-success dropdown-toggle new-tracker-button" aria-controls="dropdown-alignment">
          {{'CREATE_NEW_TRACKER' | translate }} <span class="caret"></span>
        </button>
        <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
          aria-labelledby="button-alignment">
          <li role="menuitem">
            <a class="dropdown-item" (click)="this.createImages()">
              {{'CREATE_NEW_IMAGES' | translate }}
            </a>
          </li>
          <li role="menuitem" *ngIf="config.isCuboidTrackerEnabled()">
            <a class="dropdown-item" (click)="this.createCuboid()">
              {{'CREATE_NEW_CUBOID' | translate }}
            </a>
          </li>
          <li role="menuitem" *ngIf="config.isToursEnabled()">
            <a class="dropdown-item" (click)="this.createPlace()">
              {{'CREATE_NEW_PLACE' | translate }}
            </a>
          </li>
          <li role="menuitem" *ngIf="config.isHologramTrackerEnabled()">
            <a class="dropdown-item" (click)="this.createHologram()">
              {{'CREATE_NEW_HOLOGRAM' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div *ngIf="this.trackersEmpty() && initDone" class="no-trackers">
    <div class="no-trackers-text">
      {{'NO_TRACKER_FOUND' | translate }}
    </div>
  </div>
  <div *ngIf="!this.trackersEmpty()" class="cards">
    <div *ngFor="let tracker of this.getTrackersAsList() | TrackerFilter : searchText; trackBy: trackByFn">
      <!-- PLACE -->
      <div class="image-card" *ngIf="this.isPlace(tracker)" [ngStyle]="this.getStyle(tracker)">
        <div class="publish-indicator-container">
          <div class="publish-indicator"
            [matTooltip]="'EXPORTSTATUS_' + getExportStatus(tracker) | translate"
            [style.background-color]="getBackgroundColor(getExportStatus(tracker))"
          >
          </div>
        </div>
        <div class="checkbox-container" *ngIf="this.initDone">
          <label class="checkbox">
            <input type="checkbox" (click)="this.onCheckbox($event, tracker)" [checked]="this.isActive(tracker)">
            <span class="checkmark" [matTooltip]="'DE_ACTIVATE_FOR_PUBLISH' | translate" triggers="hover"></span>
          </label>
        </div>
        <div class="card-content" [matTooltip]="'GO_TO_IMAGEVIEW' | translate" triggers="hover"
       (click)="this.routeTo(tracker)">
          <div class="map-container">
            <div id="{{'map:' + tracker.id}}" class="map"></div>
          </div>
          <div class="card-body">
            <h5 class="card-title">
              {{this.getTrackerTitle(tracker)}}
            </h5>
          </div>
        </div>
      </div>
      <!-- IMAGE -->
      <div class="image-card" *ngIf="this.isImage(tracker)" [ngStyle]="this.getStyle(tracker)">
        <div class="publish-indicator-container">
          <div class="publish-indicator"
            [matTooltip]="'EXPORTSTATUS_' + getExportStatus(tracker) | translate"
            [style.background-color]="getBackgroundColor(getExportStatus(tracker))"
          >
          </div>
        </div>
        <div class="checkbox-container" *ngIf="this.initDone">
          <label class="checkbox">
            <input type="checkbox" (click)="this.onCheckbox($event, tracker)" [checked]="this.isActive(tracker)">
            <span class="checkmark" [matTooltip]="'DE_ACTIVATE_FOR_PUBLISH' | translate" triggers="hover"
            ></span>
          </label>
        </div>
        <div class="card-content" [matTooltip]="'GO_TO_IMAGEVIEW' | translate" triggers="hover" (click)="this.routeTo(tracker)">
          <aacms-media-viewer class="mediaViewer" [mediaId]="tracker.mediaId">
          </aacms-media-viewer>
          <div class="card-body">
            <h5 class="card-title">
              {{this.getTrackerTitle(tracker)}}
            </h5>
          </div>
        </div>
      </div>
      <!-- HOLOGRAM -->
      <div class="image-card" *ngIf="this.isHologram(tracker)" [ngStyle]="this.getStyle(tracker)">
        <div class="publish-indicator-container">
          <div class="publish-indicator"
            [matTooltip]="'EXPORTSTATUS_' + getExportStatus(tracker) | translate"
            [style.background-color]="getBackgroundColor(getExportStatus(tracker))"
          >
          </div>
        </div>
        <div class="checkbox-container" *ngIf="this.initDone">
          <label class="checkbox">
            <input type="checkbox" (click)="this.onCheckbox($event, tracker)" [checked]="this.isActive(tracker)">
            <span class="checkmark" [matTooltip]="'DE_ACTIVATE_FOR_PUBLISH' | translate" triggers="hover"
            ></span>
          </label>
        </div>
        <div class="card-content" [matTooltip]="'GO_TO_HOLOGRAMVIEW' | translate" triggers="hover" (click)="this.routeTo(tracker)">
          <aacms-media-viewer class="mediaViewer" [mediaId]="getTrackerMediaId(tracker)">
          </aacms-media-viewer>
          <div class="card-body">
            <h5 class="card-title">
              {{this.getTrackerTitle(tracker)}}
            </h5>
          </div>
        </div>
      </div>
      <!-- CUBOID -->
      <div class="image-card" *ngIf="isCuboid(tracker)" [ngStyle]="this.getStyle(tracker)">
        <div class="publish-indicator-container">
          <div class="publish-indicator"
            [matTooltip]="'EXPORTSTATUS_' + getExportStatus(tracker) | translate"
            [style.background-color]="getBackgroundColor(getExportStatus(tracker))"
          >
          </div>
        </div>
        <div class="checkbox-container" *ngIf="this.initDone">
          <label class="checkbox">
            <input type="checkbox" (click)="this.onCheckbox($event, tracker)" [checked]="this.isActive(tracker)">
            <span class="checkmark" [matTooltip]="'DE_ACTIVATE_FOR_PUBLISH' | translate" triggers="hover"></span>
          </label>
        </div>
        <div class="card-content" [matTooltip]="'GO_TO_CUBOIDVIEW' | translate" triggers="hover"
         (click)="this.routeTo(tracker)">
          <div *ngIf="this.initDone && getTracker(tracker).trackerIds" class="cuboid riffle-background">
            <aacms-cuboid-quick-viewer #cuboidViewer class="cuboid-viewer" [cuboid]="getTracker(tracker)"
              [top]="this.getCuboidThumbUrl(getTracker(tracker).trackerIds.top.mediaId)"
              [bottom]="this.getCuboidThumbUrl(getTracker(tracker).trackerIds.bottom.mediaId)"
              [left]="this.getCuboidThumbUrl(getTracker(tracker).trackerIds.left.mediaId)"
              [right]="this.getCuboidThumbUrl(getTracker(tracker).trackerIds.right.mediaId)"
              [back]="this.getCuboidThumbUrl(getTracker(tracker).trackerIds.back.mediaId)"
              [front]="this.getCuboidThumbUrl(getTracker(tracker).trackerIds.front.mediaId)">
            </aacms-cuboid-quick-viewer>
            <aacms-loader class="imageLoader" size="64px" *ngIf="this.trackerLoaded(tracker) && tracker.mediaId">
            </aacms-loader>
          </div>
          <div class="card-body">
            <h5 class="card-title">
              {{this.getTrackerTitle(tracker)}}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
