import { FileDataType } from "./FileDataType";
import { Language } from "./Language";

export class LanguagePair {

    public key: Language;
    public value: FileDataType;

    constructor(pair?: LanguagePair) {
        if (pair !== undefined) {
            this.key = pair.key;
            if (pair.value !== undefined) {
                this.value = new FileDataType(pair.value);
            }
        }
    }

    public getKey(): Language {
        return this.key;
    }

    public getValue(): FileDataType {
        return this.value;
    }

    public equal(pair: LanguagePair): boolean {
        if (
            this.key !== pair.key ||
            this.value !== pair.value
        ) {
            return false;
        }
        return true;
    }
}