import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MediaLoader, MediaType } from "../../../helper-classes/MediaLoader";
import { MediaService } from "../../services/media.service";

const MODEL_FALLBACK_THUMBNAIL_URL: string = '../../assets/3DModellPlaceholder.png';
const IMAGE_FALLBACK_THUMBNAIL_URL: string = '../../assets/image-placeholder.png';

@Component({
	selector: 'aacms-media-viewer',
	templateUrl: './media-viewer.component.html',
	styleUrls: ['./media-viewer.component.scss']
})
export class MediaViewerComponent {

	/* input parameters */
	@Input() mediaId: string;
	@Input() styles: any = {};
	@Input() backgroundStyle: string = "riffle";
	@Input() autoSize: boolean = false;

	@Output() focus: EventEmitter<any> = new EventEmitter<any>();

	// model specific
	@Input() modelAlways: boolean = false;
	@Input() modelHover: boolean = true;
	@Input() modelAutorotate: boolean = true;
	@Input() modelCameraControls: boolean = true;
	@Input() mediaLoader: MediaLoader;

	/* output parameters */
	@Output() get modelViewer() { return this._modelViewer?.nativeElement; }
	@Output() get ready(): boolean {
		return !this.shouldShowLoadAnimation();
	}

	/* ui */
	@ViewChild('modelViewer') _modelViewer;

	/* enums */
	public MediaType = MediaType;

	/* template variables */
	private mouseOver: boolean = false;
	private hoverDelayTimer = null;

	constructor(
		private mediaService: MediaService,
		private sanitizer: DomSanitizer
	) {
		if(!this.mediaLoader) { // fallback to creating our own for convenience
			this.mediaLoader = new MediaLoader(this.mediaService, this.sanitizer);
		}
	}

	public getThumbnailUrl() {
		if(!this.mediaId) { return null; }
		let media = this.mediaLoader.get(this.mediaId);
		if(media.type == MediaType.Model) {
			return media.getThumbUrl() || MODEL_FALLBACK_THUMBNAIL_URL;
		} else if(media.type == MediaType.Image) {
			return media.getThumbOrRawUrl() || IMAGE_FALLBACK_THUMBNAIL_URL;
		}
	}
	public getRawUrl() {
		return this.mediaLoader.get(this.mediaId)?.getRawUrl();
	}

	public isFallbackThumbnail() : boolean {
		if(this.shouldShowLoadAnimation()) { return false; }
		return this.getThumbnailUrl() === MODEL_FALLBACK_THUMBNAIL_URL
			|| this.getThumbnailUrl() === IMAGE_FALLBACK_THUMBNAIL_URL;
	}

	public shouldShowThumbnail() : boolean {
		return !this.shouldShowLoadAnimation() && !this.getThumbnailUrl();
	}

	public shouldShowLoadAnimation() : boolean {
		return !this.mediaId || !this.mediaLoader.isLoaded(this.mediaId) || this.mediaLoader.get(this.mediaId).isDownloadRunning();
	}

	public getMediaType() : MediaType {
		let media = this.mediaLoader.get(this.mediaId);
		if(!media) { return MediaType.Undertermined; }
		return media.type;
	}

	// ##########
	// # VIDEOS & AUDIOS
	// ##########

	// wrapper for getRawUrl() to workaround browser bug, where media element doesn't refresh on source change
	private _prevRawUrl = null;
	public getDelayedRawUrl() {
		const newRawUrl = this.getRawUrl();
		if(newRawUrl == null) { this._prevRawUrl = null; } // apply null directly
		if(this._prevRawUrl === newRawUrl) { return newRawUrl; }
		this._prevRawUrl = newRawUrl; // url changed, update _prevRawUrl, but
		return null; // return null once, to force element to be re-rendered
	}

	// ##########
	// # MODELS
	// ##########
	public shouldShowModel(): boolean {
		if(this.getMediaType() !== MediaType.Model) { return false; }
		if(this.modelAlways) { return true; } // we always show it
		if(!this.modelHover) { return false; } // we never show it
		// hovering is active
		return (this.mouseOver && this.hoverDelayTimer == null);
	}

	public onMouseEnter() {
		if(this.mouseOver) { return; }
		this.mouseOver = true;
		this.hoverDelayTimer = setTimeout(() => {
			this.hoverDelayTimer = null;
		}, 500);
	}
	public onMouseLeave() {
		this.mouseOver = false;
		if (this.hoverDelayTimer !== null) {
			clearTimeout(this.hoverDelayTimer);
			this.hoverDelayTimer = null;
		}
	}
	public onClick(event: MouseEvent) {
		if (this.shouldShowModel()) {
			event.preventDefault();
			event.stopPropagation();
		}
	}
}
