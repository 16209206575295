<div class="main-content">
  <b class="page-title">
    {{'TOURS' | translate }}
  </b>
  <div class="searchbar-header">
    <input [(ngModel)]="searchText" type="text" class="form-control form-control-lg searchbar"
      [placeholder]="'SEARCH' | translate" aria-label="Suche" aria-describedby="basic-addon2"
      [matTooltip]="'FILTER_TOURS_BY_NAME' | translate" triggers="hover"
      (ngModelChange)="this.filterTours()">
    <div *ngIf="this.tours" class="new-tour-buttons">
      <button type="button" class="btn btn-secondary new-tour-button"
        (click)="this.routeToExhibitions()">{{'BACK_TO_EXHIBITIONS' | translate }}
      </button>
      <button *ngIf="this.isAdmin()" type="button" class="btn btn-primary new-tour-button"
        (click)="this.openModal(applyExhibitionsModalTemplate)">{{'APPLY_ALL_TOURS' | translate }}</button>
      <button class="btn btn-success new-tour-button" (click)="this.createTours()">{{'CREATE_NEW_TOUR' | translate }}
      </button>
    </div>
  </div>

  <div *ngIf="!this.tours || this.tours.length === 0 && initDone" class="no-trackers">
    <div class="no-trackers-text">
      {{'NO_TOUR_FOUND' | translate }}
    </div>
  </div>
  <div *ngIf="this.tours && this.tours.length > 0" class="cards">
    <div class="tour-element" *ngFor="let tour of this.filteredTours">
      <div class="image-card" [ngStyle]="this.getStyle(tour)">
        <div class="publish-indicator-container">
          <div class="publish-indicator"
            [matTooltip]="'EXPORTSTATUS_' + getExportStatus(tour) | translate"
            [style.background-color]="getBackgroundColor(getExportStatus(tour))"
           >
          </div>
        </div>
        <div class="card-content" [matTooltip]="'GO_TO_TOURVIEW' | translate" triggers="hover" matTooltipPlacement="bottom">
          <div class="image" (click)="this.routeTo(tour)">
            <div id="{{'map:' + tour.id}}" class="card-img-top"></div>
          </div>
          <div class="checkbox-container" *ngIf="this.initDone">
            <label class="checkbox">
              <input type="checkbox" (click)="this.onCheckbox($event, tour)" [checked]="tour.active">
              <span class="checkmark" [matTooltip]="'DE_ACTIVATE_FOR_PUBLISH' | translate" triggers="hover"></span>
            </label>
          </div>
        </div>
        <div class="card-meta">
          <h5 class="card-title" (click)="this.routeTo(tour)">
            {{this.getLanguageValue(tour.title)}}
          </h5>
          <div class="tour-icons">
            <button *ngIf="this.isAdmin()" (click)="this.applyToBackend(tour, applyExhibitionModalTemplate)"
              class="tour-icon-button tour-icon-button-apply" [matTooltip]="'APPLY_TO_BACKEND' | translate"
              triggers="hover">
              <i class="fa fa-cloud-upload tour-icon" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #applyExhibitionModalTemplate>
  <div class="modal-header">
    <h4 *ngIf="currentTourToUpload.title != undefined && getLanguageValue(currentTourToUpload.title).length != 0" class="modal-title text-break pull-left">{{'REALY_UPLOAD1' | translate }}
      <i>'{{ getLanguageValue(currentTourToUpload.title) }}'</i> {{'REALY_UPLOAD2' | translate}}</h4>
    <h4 *ngIf="!currentTourToUpload.title || getLanguageValue(currentTourToUpload.title).length == 0" class="modal-title text-break pull-left">{{'REALY_UPLOAD_TOUR1' | translate }}
      <i>'{{ currentTourToUpload.id }}'</i> {{'REALY_UPLOAD_TOUR2' | translate}}</h4>
  </div>
  <div class="modal-footer">
    <button (click)="this.modalRef.hide()" type="button"
      class="btn btn-warning">{{'NO_DONT_UPLOAD' | translate }}</button>
    <button (click)="this.applyChanges()" type="button" class="btn btn-success">{{'YES_UPLOAD' | translate }}</button>
  </div>
</ng-template>

<ng-template #applyExhibitionsModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-break pull-left">{{'REALY_UPLOAD_ALL_TOURS' | translate }}</h4>
  </div>
  <div class="modal-footer">
    <button (click)="this.modalRef.hide()" type="button"
      class="btn btn-warning">{{'NO_DONT_UPLOAD' | translate }}</button>
    <button (click)="this.applyAllChanges()" type="button"
      class="btn btn-success">{{'YES_UPLOAD' | translate }}</button>
  </div>
</ng-template>
