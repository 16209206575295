import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Config } from '../Config';
import { FileDataType } from '../models/FileDataType';
import { ElementType, UiElement, TextElement } from '../models/UiElements';
import { Language } from '../models/Language';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { MediaService } from '../services/media.service';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { Card } from '../models/Card';
import { Cuboid } from '../models/Cuboid';
import { Tracker } from '../models/Tracker';
import { CubeSides } from '../models/CubeSides';
import { Poi } from '../models/Poi';
import { CurrentContextService } from '../services/currentContext.service';
import { MediaData, MediaLoader, MediaType } from '../../helper-classes/MediaLoader';
import { UserConfigService } from '../services/userConfig.service';

@Component({
	selector: 'aacms-info-card-editor',
	templateUrl: './info-card-editor.component.html',
	styleUrls: ['./info-card-editor.component.scss']
})
export class InfoCardEditorComponent {

	@Input() title: MultiLanguageText;
	@Input() card: Card;
	@Input() forceVisible: boolean = false;
	@Input() currentLanguage: Language = Language.DE;
	@Input() trackerFileData: Map<CubeSides, FileDataType>;
	@Input() cuboid: Cuboid;
	@Input() poi: Poi;
	@Input() uiElements: UiElement[];
	@Input() isTracker = true;
	@Output() cardChange: EventEmitter<void> = new EventEmitter<void>();

	public mediaLoader: MediaLoader;

	public currentSelectedResolution = 'big';
	private smartphoneMultiplier = 2.7;
	private smartphoneHeight = 16;
	private smartphoneWidth = 8;
	private portraitMode = true;

	public config = new Config();
	public ElementType = ElementType;
	public Language = Language;
	public CubeSides = CubeSides;

	constructor(
		private mediaService: MediaService,
		private sanitizer: DomSanitizer,
		private userConfigService: UserConfigService
	) {
		this.mediaLoader = new MediaLoader(this.mediaService, this.sanitizer);
	}

	public toggleAutogenerateCard() {
		this.card.autogenerateCard = !this.card.autogenerateCard;
		this.cardChange.emit();
	}

	public isLanguage(language: Language): boolean {
		return language === this.currentLanguage;
	}

	public acceptChangesToText(change: string): void {
		this.cardChange.emit();
	}

	public getSmartphoneStyle() {
		const width = (this.smartphoneWidth * this.smartphoneMultiplier) + 'rem';
		const height = (this.smartphoneHeight * this.smartphoneMultiplier) + 'rem';
		const portraitBorder = '20px 10px 40px 10px';
		const landscapeBorder = '10px 40px 10px 20px';

		const smartphoneStyle = {
			'height': this.portraitMode ? height : width,
			'width': this.portraitMode ? width : height,
			'border-width': this.portraitMode ? portraitBorder : landscapeBorder
		};

		return smartphoneStyle;
	}

	public changeSmartphoneStyle(height, width): void {
		this.smartphoneHeight = height;
		this.smartphoneWidth = width;
	}

	public rotateSmartphone(): void {
		this.portraitMode = !this.portraitMode;
	}

	public getLanguageValue(text: MultiLanguageText): string {
		const value = new MultiLanguageText(text).getInLanguage(this.currentLanguage);
		return value.length > 0 ? value : ' ';
	}

	public toggleCardVisibility(): void {
		this.card.cardVisible = !this.card.cardVisible;
		this.cardChange.emit();
	}

	public getTracker(tracker: Tracker): Cuboid {
		if (tracker instanceof Cuboid) {
			return <Cuboid>tracker;
		} else {
			return null;
		}
	}

	public getImageUrl(fileData: FileDataType): SafeUrl {
		if (fileData) {
			return fileData.fileSafeUrl;
		} else {
			return null;
		}
	}

	public getBorderStyle(): Object {
		return { 'border-left': '5px solid ' + this.userConfigService.getSync().highlightColor };
	}

	public getTextFromFirstUiElement(): string {
		for (const uiElement of this.uiElements) {
			if (uiElement.active && uiElement instanceof TextElement) {
				const value = (<TextElement>uiElement).text.getInLanguage(this.currentLanguage);
				return value.replace(/<.*?>/g, '');
			}
		}
		return '';
	}

	public getAutoCardImageSytle(): Object {
		let position = '50% 50%';
		let fit = 'cover';
		if (this.poi) {
			position = this.poi.x + '% ' + this.poi.y + '%';
		}
		if (this.poi && this.card.autogenerateCard) {
			fit = 'none';
		}
		return {
			'object-fit': fit,
			'object-position': position,
			'width': '80px',
			'height': '80px'
		};
	}

	public getCardImageMediaId(): string {
		if(!this.card.autogenerateCard) { // manual card
			return this.card?.cardImageFileData.getWithFallback(this.currentLanguage)?.mediaId;
		} else { // autogenerated card, extract from object
			if(this.poi) {
				return this.trackerFileData?.get(this.poi.imageSide)?.mediaId;
			} else {
				if(this.trackerFileData?.get(CubeSides.image)) {
					return this.trackerFileData?.get(CubeSides.image)?.mediaId;
				} else if(this.trackerFileData?.get(CubeSides.front)) {
					return this.trackerFileData?.get(CubeSides.front)?.mediaId;
				}
			}
		}
		return "";
	}

	public hasLoadedCardImage(): boolean {
		let mediaId = this.getCardImageMediaId();
		if(!mediaId) { return true; }
		return this.mediaLoader.isLoaded(mediaId);
	}

	public getCardImage(): MediaData {
		return this.mediaLoader.get(this.getCardImageMediaId());
	}

	public getCardImageSrc(): SafeUrl {
		let cardImage = this.getCardImage();
		if(cardImage == null) { return null; }
		let thumbUrl = cardImage?.getThumbUrl();
		if(thumbUrl != null) { return thumbUrl; }
		if(cardImage.type == MediaType.Model) {
			return '../assets/3DModellPlaceholder.png'
		} else if(cardImage.type == MediaType.Image) {
			return '../assets/image-placeholder.png'
		}
		return null;
	}
}
