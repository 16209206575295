<div class="content">
	<div class="edit-image-left-side">
		<aacms-loader class="imageLoader" size="128px" *ngIf="!this.tour">
		</aacms-loader>
		<div *ngIf="this.mapElementRef !== undefined && this.mapElementRef.mapObject !== undefined" class="map-container">
			<div id="map" [ngClass]="{'fullscreenMap':this.mapFullscreen}"></div>
		</div>
		<div *ngIf="this.mapElementRef === undefined || this.mapElementRef.mapObject === undefined" class="no-map-container">
			{{'NO_MAP_PRESENT' | translate}}
		</div>
	</div>

	<div class="edit-image-right-side">
		<ng-template [ngIf]="this.tour && this.tour.title">
			<div class="header">
				<div class="path-title-container">
					<div class="path">
						<div class="path-option" (click)="this.routeTo(appState.tours)">{{'TOURS' | translate}}</div>
						/
						<div class="last-path-option">
							{{ Tools.shortenString(tour && getLanguageValue(tour.title).length > 0 ?
							getLanguageValue(tour.title) : ('TOUR' | translate)) }}
						</div>
					</div>
					<div class="tour-title-edit-container">
						<h1 class="edit-indicator">
							<i class="fa fa-pencil" aria-hidden="true"
								[ngStyle]="{'color': this.tourChanged ? '#ffc107' : 'black' }"></i>
						</h1>
						<div class="tour-title-container">
							<h1 class="tour-title" *ngIf="this.getLanguageValue(this.tour.title).length > 0">
								{{this.getLanguageValue(this.tour.title)}}
							</h1>
							<h1 class="tour-title" *ngIf="this.getLanguageValue(this.tour.title).length == 0">
								<i>{{'UNNAMED_TOUR' | translate }}</i>
							</h1>
						</div>
					</div>
				</div>
				<aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
					(languageChanged)="this.updateLanguage($event)">
				</aacms-language-selector>
			</div>
			<div class="custom-hr"></div>
			<div>
				<i>{{ 'TOUR_SETTINGS' | translate }}:</i>
			</div>
			<div class="image-poi" (click)="this.selectMarker(undefined)"
				[style.border]="!this.selectedMarker ? '2px solid #87ceeb': '2px solid gainsboro'">
				<div class="image-poi-meta">
					<div class="image-poi-title" *ngIf="this.getLanguageValue(this.tour.title).length > 0">
						{{this.getLanguageValue(this.tour.title)}}
					</div>
					<div class="image-poi-title" *ngIf="this.getLanguageValue(this.tour.title).length === 0">
						<i>
							{{ 'UNNAMED_TOUR' | translate }}
						</i>
					</div>
				</div>
				<div class="image-poi-edit-buttons">
					<button class="image-poi-edit-button" (click)="routeToEditTourMeta($event)"
						[matTooltip]="'EDIT' | translate" triggers="hover">
						<i class="fa fa-pencil" aria-hidden="true"></i>
					</button>
				</div>
			</div>

			<div *ngIf="this.tour !== undefined && this.mapElementRef.mapObject !== undefined" class="poi-list-label">
				<div class="custom-hr"></div>
				{{ 'TOUR_WAYPOINTS' | translate }}
			</div>
			<div *ngIf="this.tour && this.mapElementRef.mapObject && this.mapElementRef.mapObject.features && this.mapElementRef.mapObject.features.length > 0"
				class="poi-info">
				<div class="poi-list">
					<ng-template ngFor let-marker
						[ngForOf]="this.mapElementRef.mapObject.features | GeoJsonFeatureFilter"
						[ngForTrackBy]="trackBy">
						<div class="poi" (click)="selectMarker(marker)" [style]="getWaypointListEntryStyle(marker)">
							<div class="poi-meta" [style]="getWaypointListTitleStyle(marker)">
								<div class="poi-title">
									<a *ngIf="this.getLanguageValueFromMarker(marker).length > 0">
										<b class="poi-position">#{{marker.properties.position}}</b>
										{{this.getLanguageValueFromMarker(marker)}}
									</a>
									<i *ngIf="this.getLanguageValueFromMarker(marker).length === 0">
										<b class="poi-position">#{{marker.properties.position}}</b> {{
										'UNNAMED_WAYPOINT' | translate }}
									</i>
								</div>
							</div>
							<div class="poi-edit-buttons">
								<button class="poi-edit-button" (click)="updateDistance(marker)"
									[matTooltip]="'UPDATE_DISTANCE' | translate" triggers="hover">
									<span class="radius-button">{{marker.properties.radius}}m</span>
								</button>
								<button class="poi-edit-button" (click)="routeToTracker(marker)"
									[disabled]="marker.properties.trackerId === undefined"
									[matTooltip]="'ROUTE_TO_TRACKER' | translate" triggers="hover">
									<i class="fa fa-share exhibition-icon" aria-hidden="true"></i>
									<!--fa-external-link-square-->
								</button>
								<button class="poi-edit-button" (click)="openTrackerSelection(marker)"
									[matTooltip]="'EDIT' | translate" triggers="hover">
									<i class="fa fa-pencil exhibition-icon" aria-hidden="true"></i>
								</button>
								<button *ngIf="!isMarkerActive(marker)" class="poi-edit-button"
									(click)="toggleActive(marker, $event)"
									[matTooltip]="'ACTIVATE_FOR_PUBLISH' | translate" triggers="hover">
									<i class="fa fa-eye-slash exhibition-icon" aria-hidden="true"></i>
								</button>
								<button *ngIf="isMarkerActive(marker)" class="poi-edit-button"
									(click)="toggleActive(marker, $event)"
									[matTooltip]="'DEACTIVATE_FOR_PUBLISH' | translate" triggers="hover">
									<i class="fa fa-eye exhibition-icon" aria-hidden="true"></i>
								</button>
								<button class="poi-edit-button" (click)="deleteMarker(marker, $event)"
									[matTooltip]="'DELETE' | translate" triggers="hover">
									<i class="fa fa-trash exhibition-icon" aria-hidden="true"></i>
								</button>
							</div>
						</div>
					</ng-template>
				</div>
				<div class="move-buttons">
					<button type="submit" class="move-button btn btn-light" (click)="moveWaypointUp()"
						[matTooltip]="'MOVE_WAYPOINT_UP' | translate" matTooltipPosition="left">
						<i class="fa fa-angle-up" aria-hidden="true"></i>
					</button>
					<button type="submit" class="move-button btn btn-light" (click)="moveWaypointDown()"
						[matTooltip]="'MOVE_WAYPOINT_DOWN' | translate" matTooltipPosition="left">
						<i class="fa fa-angle-down" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			<div
				*ngIf="this.tour && this.mapElementRef.mapObject && this.mapElementRef.mapObject.features && this.mapElementRef.mapObject.features.length === 0">
				<i>{{ 'NO_WAYPOINTS_AVAILABLE' | translate }}</i>
			</div>
			<div class="custom-hr"></div>
			<div class="row button-row">
				<button (click)="cancel()" type="button" class="btn btn-secondary custom-button">{{'BACK_TO_OVERVIEW' |
					translate }}</button>
				<button (click)="openModal(deleteModalTemplate)" type="button"
					class="btn btn-danger custom-button">{{'DELETE_TOUR' | translate }}</button>
				<button (click)="openModal(cancelModalTemplate)" type="button" [disabled]="!this.tourChanged"
					class="btn btn-warning custom-button">{{'DISCARD' | translate }}</button>
				<button (click)="this.onSave()" type="button" [disabled]="!this.tourChanged"
					class="btn btn-success custom-button">{{'SAVE' | translate }}</button>
			</div>
		</ng-template>
	</div>
</div>

<ng-template #deleteModalTemplate>
	<div class="modal-header">
		<h4 *ngIf="!this.tour.title.de" class="modal-title text-break pull-left">{{'REALY_DELETE_TOUR' | translate }}
		</h4>
		<h4 *ngIf="this.tour.title.de" class="modal-title text-break pull-left">
			{{'REALY_DELETE_TOUR1' | translate }}
			<i>'{{this.tour.title.de}}'</i> {{'REALY_DELETE_TOUR2' | translate }}
		</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-footer">
		<button (click)="modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
		<button (click)="delete()" type="button" class="btn btn-danger">{{'YES_DELETE' | translate }}</button>
	</div>
</ng-template>

<ng-template #cancelModalTemplate>
	<div class="modal-header">
		<h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
	</div>
	<div class="modal-footer">
		<button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate
			}}</button>
		<button (click)="onDiscard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
	</div>
</ng-template>