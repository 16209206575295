import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Config } from "../../Config";
import { MediaData, MediaLoader } from "../../../helper-classes/MediaLoader";
import { Language } from "../../models/Language";
import { MultiLanguageData } from "../../models/MultiLanguageData";
import { ElementType } from "../../models/UiElements";
import { AlertService } from "../../services/alert.service";
import { CurrentContextService } from "../../services/currentContext.service";
import { MediaService } from "../../services/media.service";
import { StringService } from "../../services/string.service";
import { saveAs } from 'file-saver';

export interface ManageMediaDataAdapter {
	isReady(mediaData: any, lang: Language) : boolean;
	hasAny(mediaData: any) : boolean;
	get(mediaData: any, lang: Language) : string;
	set(mediaData: any, lang: Language, mediaId: string);
	unset(mediaData: any, lang: Language);
	shouldShowDropin(mediaData: any, lang: Language) : boolean;
}

class ManageMediaMultiLangAdapter implements ManageMediaDataAdapter {
	public isReady(mediaData: any, lang: Language): boolean {
		return lang !== null && mediaData;
	}
	public hasAny(mediaData: any): boolean { return mediaData?.hasAny(); }
	public get(mediaData: MultiLanguageData<any>, lang: Language) : string {
		return mediaData?.get(lang)?.mediaId;
	}
	public set(mediaData: MultiLanguageData<any>, lang: Language, mediaId: string) {
		let ctor: any = mediaData.getValueCtor();
		if(mediaData?.has(lang)) {
			mediaData.get(lang).mediaId = mediaId;
		} else {
			mediaData.set(lang, ctor.fromMediaId(mediaId));
		}
	}
	public unset(mediaData: any, lang: Language) { mediaData.unset(lang); }
	public shouldShowDropin(mediaData: any, lang: Language): boolean {
		return !mediaData.has(lang) || !this.get(mediaData, lang);
	}
}

class ManageMediaUnilangAdapter implements ManageMediaDataAdapter {
	public constructor(private propertyName: string, private unsetValue = null) {}
	public isReady(mediaData: any, lang: Language): boolean {
		return mediaData;
	}
	public hasAny(mediaData: any): boolean {
		return (this.get(mediaData, null) != null);
	}
	public get(mediaData: any, _lang: Language): string {
		return mediaData[this.propertyName]
	}
	public set(mediaData: any, _lang: Language, mediaId: string) {
		mediaData[this.propertyName] = mediaId;
	}
	public unset(mediaData: any, lang: Language) {
		mediaData[this.propertyName] = this.unsetValue;
	}
	public shouldShowDropin(mediaData: any, _lang: Language): boolean {
		return !this.hasAny(mediaData);
	}
}

export class ManageMedia2Adapters {
	/**
	 * Creates a Multi-Language adapter that plugs into MultiLanguageData<any>.
	 */
	public static Multilang() : ManageMediaDataAdapter {
		return new ManageMediaMultiLangAdapter();
	}
	/**
	 * Creates a Single-Language adapter that directly plugs into the data object containing the mediaId property.
	 * @param propertyName Name of the mediaId property within the data object.
	 * @param unsetValue Value that the property should be set to when unset.
	 */
	public static Unilang(propertyName: string, unsetValue = null) : ManageMediaDataAdapter {
		return new ManageMediaUnilangAdapter(propertyName, unsetValue);
	}
}

@Component({
	selector: 'manage-media2',
	templateUrl: './manage-media2.component.html',
	styleUrls: ['./manage-media2.component.scss']
})
export class ManageMedia2Component {
	// required
	@Input() elementType: ElementType;
	@Input() mediaLoader: MediaLoader;
	@Input() currentLanguage: Language = null;
	/**
	 * Data object containing the managed media data.
	 * All accesses to this object happen through the ManageMediaDataAdapter instance set to the
	 * dataAdapter property (defaulting to MultiLang).
	 */
	@Input() mediaData: any;
	@Input() maxFileSize: number;
	@Input() enableTrackingQuality: boolean = false;
	@Input() dataAdapter: ManageMediaDataAdapter = new ManageMediaMultiLangAdapter();
	@Output() mediaChanged: EventEmitter<Language> = new EventEmitter<Language>();

	public ElementType = ElementType;
	public config: Config = new Config();

	public constructor(
		public alertService: AlertService,
		public stringService: StringService,
		public contextService: CurrentContextService,
		public mediaService: MediaService
	) { }


	public handleFilesFromDropin(files: File[]): void {
		if (files.length > 0) {
			this.contextService.setSavingContent(true);
			this.mediaService.uploadFile(files[0]).subscribe(
				response => {
					if (response.status < 400) {
						const location: string = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
						const mediaId: string = location.substring(location.lastIndexOf('/') + 1);
						this.dataAdapter.set(this.mediaData, this.currentLanguage, mediaId);
						this.mediaChanged.emit(this.currentLanguage);
					}
					this.contextService.setSavingContent(false);
				}
			);
		}
	}

	public downloadFileToSystem(file: MediaData): void { saveAs(file.getRawFile(), file.media.name); }
	public getMaxContentFileSize(): number { return this.maxFileSize; }
	public getAcceptedFileTypes(): string {
		switch (this.elementType) {
			case ElementType.IMAGE: { return '.jpg,.jpeg,.png'; }
			case ElementType.AUDIO: { return 'audio/mp3'; }
			case ElementType.VIDEO: { return 'video/mp4'; }
			case ElementType.MODEL: { return ".glb"; }
		}
	}

	public isReady() : boolean { return this.dataAdapter.isReady(this.mediaData, this.currentLanguage); }
	public hasAny() : boolean { return this.dataAdapter.hasAny(this.mediaData); }
	public shouldShowDropin() { return this.dataAdapter.shouldShowDropin(this.mediaData, this.currentLanguage); }
	public shouldShowCurrentFiles() { return !this.shouldShowDropin(); }
	public getCurrentFile(): MediaData {
		let mediaId = this.dataAdapter.get(this.mediaData, this.currentLanguage);
		if (!mediaId) { return null; }
		return this.mediaLoader.get(mediaId);
	}
	public unsetCurrentFile() {
		this.dataAdapter.unset(this.mediaData, this.currentLanguage);
		this.mediaChanged.emit(this.currentLanguage);
	}
}
