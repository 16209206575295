import { Component, Input } from "@angular/core";
import { UserConfigService } from "../../services/userConfig.service";

@Component({
	selector: 'aacms-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

	@Input() size: string = 'auto';

	public constructor(
		public userConfigService: UserConfigService
	) {}

}
