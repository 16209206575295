<div class="modal-header">
  <div class="modal-title text-break">
    <b>{{'DEACTIVATE_EXHIBITION?' | translate }}</b>
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <a>{{'REALLY_DEACTIVATE1' |translate }}</a>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="onClose()">{{'ABORT' | translate }}</button>
  <button type="button" class="btn btn-danger" (click)="onDeactivate()">{{'YES_DEACTIVATE' | translate }}</button>
</div>
