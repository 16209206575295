import { AlertService } from './../services/alert.service';
import { AlertWithBoldValueInMiddle, AlertType } from './../models/Alert';
import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Subscription, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Config } from '../Config';
import { CuboidViewerComponent } from '../cuboid-viewer/cuboid-viewer.component';
import { AppState } from '../current.app.state';
import { ContentTypes } from '../models/ContentTypes';
import { CubeSides } from '../models/CubeSides';
import { Cuboid } from '../models/Cuboid';
import { FileDataType } from '../models/FileDataType';
import { Language } from '../models/Language';
import { Media } from '../models/Media';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { CuboidService } from '../services/cuboid.service';
import { CurrentContextService } from '../services/currentContext.service';
import { MediaService } from '../services/media.service';
import { PoiManagerService } from '../services/poi-manager.service';
import { StringService } from '../services/string.service';
import { Tools } from '../Tools';
import { TabType } from '../models/TabType';
import { UiElement } from '../models/UiElements';

@Component({
  selector: 'aacms-edit-cuboid-meta',
  templateUrl: './edit-cuboid-meta.component.html',
  styleUrls: ['./edit-cuboid-meta.component.scss']
})
export class EditCuboidMetaComponent implements OnInit, OnDestroy {

  public modalRef: BsModalRef;
  public tabType = TabType;

  public cuboid: Cuboid;
  public originalCuboid: Cuboid;

  public imageDataMap: Map<CubeSides, FileDataType> = new Map([
    [CubeSides.top, null],
    [CubeSides.bottom, null],
    [CubeSides.front, null],
    [CubeSides.back, null],
    [CubeSides.left, null],
    [CubeSides.right, null]
  ]);

  public originalImageDataMap: Map<CubeSides, FileDataType> = new Map([
    [CubeSides.top, null],
    [CubeSides.bottom, null],
    [CubeSides.front, null],
    [CubeSides.back, null],
    [CubeSides.left, null],
    [CubeSides.right, null]
  ]);

  public imageDataLoaded = false;

  public contentChanged = false;
  public cuboidImageChanged = false;

  public cubeSides = CubeSides;
  public config = new Config();

  @ViewChild('popupEditor') popupEditor;
  @ViewChild('cuboidViewer') cuboidViewer: CuboidViewerComponent;
  @ViewChild('cancelModalTemplate', { static: false }) cancelModalTemplate: TemplateRef<any>;

  public width: string;
  public height: string;
  public depth: string;

  public Language = Language;
  public appState = AppState;

  public currentLanguage: Language;
  public useCustomPopup: boolean = false;
  private customPopupBackup: UiElement[] = null;

  private saveContentSubject: Subscription;
  private navigationSubscription: Subscription;

  public mapIds: CubeSides[] = [CubeSides.top, CubeSides.bottom, CubeSides.front, CubeSides.back, CubeSides.left, CubeSides.right];

  public Tools = Tools;

  public constructor(
    public contextService: CurrentContextService,
    public pms: PoiManagerService,
    private mediaService: MediaService,
    private modalService: BsModalService,
    private cuboidService: CuboidService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private stringService: StringService,
    private alertService: AlertService
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof ActivationEnd) {
        this.cuboid = null;
        this.pms.selectedPoi = null;
        this.currentLanguage = Language.DE;
        const cuboidId = e.snapshot.paramMap.get('cuboidId');
        if (cuboidId) {
          this.contextService.setCurrentState(AppState.editCuboidMeta);
          this.getCuboid(cuboidId);
        }
      }
    });
  }

  public ngOnInit(): void {
    this.saveContentSubject = this.contextService.saveYourContent.subscribe(
      saveContent => {
        if (saveContent) {
          this.save();
        }
      }
    );
  }

  public ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
    this.saveContentSubject.unsubscribe();
    this.returnToCuboid();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (this.contentChanged || this.pms.poisChanged || this.cuboidImageChanged) ? false : true;
  }

  public cuboidWidthChanged(value: string, input: HTMLInputElement): void {
    value = Tools.checkForNumber(value, false);
    if (value === null) {
      value = this.cuboid.width.toString();
    } else if (value === '') {
      this.cuboid.width = 0;
    } else {
      this.cuboid.width = +value;
    }
    this.width = value;
    this.cuboidViewer.updateCuboidSize(this.cuboid);
    this.checkForContentChange();
    input.value = value;
  }

  public cuboidHeightChanged(value: string, input: HTMLInputElement): void {
    value = Tools.checkForNumber(value, false);
    if (value === null) {
      value = this.cuboid.height.toString();
    } else if (value === '') {
      this.cuboid.height = 0;
    } else {
      this.cuboid.height = +value;
    }
    this.height = value;
    this.cuboidViewer.updateCuboidSize(this.cuboid);
    this.checkForContentChange();
    input.value = value;
  }

  public cuboidDepthChanged(value: string, input: HTMLInputElement): void {
    value = Tools.checkForNumber(value, false);
    if (value === null) {
      value = this.cuboid.depth.toString();
    } else if (value === '') {
      this.cuboid.depth = 0;
    } else {
      this.cuboid.depth = +value;
    }
    this.depth = value;
    this.cuboidViewer.updateCuboidSize(this.cuboid);
    this.checkForContentChange();
    input.value = value;
  }

  public askToSave(): void {
    this.modalRef = this.modalService.show(this.cancelModalTemplate, { keyboard: false, ignoreBackdropClick: true });
  }

  public discard(): void {
    this.cuboid = new Cuboid(this.originalCuboid);
    this.imageDataMap = new Map(this.originalImageDataMap);
    this.width = this.originalCuboid.width.toString();
    this.height = this.originalCuboid.height.toString();
    this.depth = this.originalCuboid.depth.toString();
    this.cuboidViewer.updateCuboidSize(this.cuboid);
    this.popupEditor.onDiscard();
		this.useCustomPopup = this.cuboid.popupElements.length !== 0;
    this.checkForContentChange();
    this.hideModal();
  }

  public hideModal(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  private getCuboid(cuboidId: string) {
    this.imageDataLoaded = false;
    const sub = this.cuboidService.getCuboidById(cuboidId).subscribe(
      cuboid => {
        this.cuboid = new Cuboid(cuboid);
        this.useCustomPopup = this.cuboid.popupElements.length !== 0;
        this.customPopupBackup = this.cuboid.popupElements;
        this.contextService.getExhibition(this.cuboid.exhibitionIds[0]);
        this.originalCuboid = new Cuboid(cuboid);
        this.width = cuboid.width.toString();
        this.height = cuboid.height.toString();
        this.depth = cuboid.depth.toString();
        const requests = [];
        if (this.cuboid.trackerIds.top && this.cuboid.trackerIds.top.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.top.mediaId, CubeSides.top));
        }
        if (this.cuboid.trackerIds.bottom && this.cuboid.trackerIds.bottom.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.bottom.mediaId, CubeSides.bottom));
        }
        if (this.cuboid.trackerIds.left && this.cuboid.trackerIds.left.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.left.mediaId, CubeSides.left));
        }
        if (this.cuboid.trackerIds.right && this.cuboid.trackerIds.right.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.right.mediaId, CubeSides.right));
        }
        if (this.cuboid.trackerIds.front && this.cuboid.trackerIds.front.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.front.mediaId, CubeSides.front));
        }
        if (this.cuboid.trackerIds.back && this.cuboid.trackerIds.back.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.back.mediaId, CubeSides.back));
        }
        const sub1 = forkJoin(requests).subscribe(
          () => {
            this.imageDataLoaded = true;
            sub1.unsubscribe();
          }
        );
        sub.unsubscribe();
      }
    );
  }

  private loadCuboidImage(mediaId: string, mapId: CubeSides): Observable<any> {
    const fileDataType: FileDataType = new FileDataType();
    return this.mediaService.getMediaById(mediaId).pipe(
      tap(
        media => {
          fileDataType.mediaId = mediaId;
          fileDataType.media = new Media(media);
          const sub = this.mediaService.downloadFile(media.rawUrl).subscribe(
            blob => {
              const file = Tools.blobToFile(blob, 'image.jpg');
              fileDataType.file = file;
              fileDataType.fileSafeUrl = this.createImageUrl(file);
              this.imageDataMap.set(mapId, fileDataType);
              this.originalImageDataMap.set(mapId, new FileDataType(fileDataType));
              sub.unsubscribe();
            }
          );
        }),
        catchError(
          error => {
            return of([]);
        })
    );
  }

  private createImageUrl(file: File): SafeUrl {
    const urlCreator = window.URL;
    const url = this.sanitizer.bypassSecurityTrustUrl(
      urlCreator.createObjectURL(file));
    return url;
  }

  public getLanguageValue(text: MultiLanguageText): string {
    return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
  }

  public downloadFileToSystem(file: FileDataType): void {
    saveAs(file.file, file.media.name);
  }

  public getMaxContentFileSize(): number {
    return new Config().getMaxImageFileSize();
  }

  public checkForContentChange(): void {
    let imageChange = false;
    for (const imageDataKey of this.imageDataMap.keys()) {
      if (this.imageDataChanged(this.imageDataMap.get(imageDataKey), this.originalImageDataMap.get(imageDataKey))) {
        imageChange = true;
        break;
      }
    }
    this.cuboidImageChanged = imageChange;

    if (!this.cuboid.equal(this.originalCuboid)) {
      this.contentChanged = true;
      return;
    }
    this.contentChanged = false;
  }

  private imageDataChanged(imageData: FileDataType, originalImageData: FileDataType): boolean {
    if (
      imageData === null && originalImageData !== null ||
      imageData !== null && originalImageData === null
    ) {
      return true;
    }
    if (!imageData && !originalImageData) {
      return false;
    }
    if (!imageData.equal(originalImageData)) {
      return true;
    }
    return false;
  }

  public save(): void {
    this.contextService.setSaveYourContent(false);
    let saveContent = (this.contentChanged) ? true : false;
    let saveImages = (this.cuboidImageChanged) ? true : false;
    if (saveContent || saveImages) {
      this.contextService.setSavingContent(true);
    }
    const requests = [];
    if (this.cuboidImageChanged) {
      for (const side of this.mapIds) {
        if (
          this.imageDataMap.get(side) &&
          this.originalImageDataMap.get(side) &&
          !this.imageDataMap.get(side).equal(this.originalImageDataMap.get(side))
        ) {
          requests.push(this.mediaService.uploadFile(this.imageDataMap.get(side).file, true).pipe(
            tap(
              response => {
                const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
                this.cuboid.trackerIds.setSide(side, location.substring(location.lastIndexOf('/') + 1));
                if (this.imageDataMap.get(side)) {
                  this.originalImageDataMap.set(side, new FileDataType(this.imageDataMap.get(side)));
                } else {
                  this.originalImageDataMap.set(side, null);
                }
              }
            ),
            catchError(
              error => {
                if (error.status === 412) {
                  this.alertService.alert(
                    new AlertWithBoldValueInMiddle(
                      this.stringService.get('IMAGE_NOT_TRACKABLE_1'),
                      this.imageDataMap.get(side).file.name,
                      this.stringService.get('IMAGE_NOT_TRACKABLE_2'),
                      AlertType.Error
                    )
                  );
                  this.imageDataMap.set(side, null);
                  this.checkForContentChange();
                }
                return of([]);
              }
            )));
          if (this.originalImageDataMap.get(side)) {
            requests.push(this.mediaService.deleteMedia(this.originalImageDataMap.get(side).mediaId));
          }
        } else if (this.imageDataMap.get(side) && !this.originalImageDataMap.get(side)) {
          requests.push(this.mediaService.uploadFile(this.imageDataMap.get(side).file, true).pipe(
            tap(
              response => {
                const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
                this.cuboid.trackerIds.setSide(side, location.substring(location.lastIndexOf('/') + 1));
                if (this.imageDataMap.get(side)) {
                  this.originalImageDataMap.set(side, new FileDataType(this.imageDataMap.get(side)));
                } else {
                  this.originalImageDataMap.set(side, null);
                }
              }
            ),
            catchError(
              error => {
                if (error.status === 412) {
                  this.alertService.alert(
                    new AlertWithBoldValueInMiddle(
                      this.stringService.get('IMAGE_NOT_TRACKABLE_1'),
                      this.imageDataMap.get(side).file.name,
                      this.stringService.get('IMAGE_NOT_TRACKABLE_2'),
                      AlertType.Error
                    )
                  );
                  this.imageDataMap.set(side, null);
                  this.checkForContentChange();
                }
                return of([]);
              }
            )));
        } else if (!this.imageDataMap.get(side) && this.originalImageDataMap.get(side)) {
          requests.push(this.mediaService.deleteMedia(this.originalImageDataMap.get(side).mediaId));
          this.cuboid.trackerIds.setSide(side, null);
        }
      }
    }
    if (requests.length > 0) {
      const sub = forkJoin(requests).pipe(
        tap(
          () => {
            saveImages = false;
            const sub1 = this.cuboidService.updateCuboid(this.cuboid).subscribe(
              response => {
                this.originalCuboid = new Cuboid(this.cuboid);
                this.checkForContentChange();
                this.hideModal();
                saveContent = false;
                if (!saveContent && !saveImages) {
                  this.contextService.setSavingContent(false);
                }
                sub1.unsubscribe();
              });
          })
      ).subscribe(response => {
        sub.unsubscribe();
      });
    } else {
      if (this.contentChanged) {
        saveImages = false;
        const sub = this.cuboidService.updateCuboid(this.cuboid).subscribe(
          response => {
            this.originalCuboid = new Cuboid(this.cuboid);
            this.checkForContentChange();
            this.hideModal();
            saveContent = false;
            if (!saveContent && !saveImages) {
              this.contextService.setSavingContent(false);
            }
            sub.unsubscribe();
          });
      }
    }
  }

  public openModal(modalTemplate: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(modalTemplate);
  }

  public returnToCuboid(): void {
    this.router.navigate(['cuboids', this.cuboid.id]);
  }

  public setLanguage(language: Language): void {
    this.currentLanguage = language;
  }

  public isLanguage(language: Language): boolean {
    return language === this.currentLanguage;
  }

  public updateLanguage(language: Language): void {
    this.currentLanguage = language;
  }

  public toggleUseCustomPopup(): void {
    this.useCustomPopup = !this.useCustomPopup;
    if(!this.useCustomPopup) { // got disabled, backup elements into gui state then, clear model
      this.customPopupBackup = this.cuboid.popupElements;
      this.cuboid.popupElements = [];
    } else { // restore from backup, if any
      if(this.customPopupBackup != null) {
        this.cuboid.popupElements = this.customPopupBackup;
      }
    }
    this.checkForContentChange();
  }

  public toggleCuboidActivation(): void {
    this.cuboid.active = !this.cuboid.active;
    this.checkForContentChange();
  }

  public showContentTypesSelection(): boolean {
    return this.config.getEnabledContentTypes().length > 1;
  }

  public getStringNameForContentType(type: ContentTypes): string {
    switch (type) {
      case ContentTypes.Custom: {
        return this.stringService.get('CUSTOM');
      }
      case ContentTypes.URL: {
        return this.stringService.get('URL');
      }
    }
  }

  public routeTo(appState: AppState): void {
    switch (appState) {
      case AppState.exhibitions:
        this.router.navigate(['exhibitions']);
        break;
      case AppState.images:
        this.router.navigate(['exhibitions', this.cuboid.exhibitionIds[0], 'images']);
        break;
      case AppState.editCuboid:
        this.router.navigate(['cuboids', this.cuboid.id]);
        break;
      default:
        break;
    }
  }
}
