import { ICuboidSize } from "./Cuboid";
import { MultiLanguageData } from "./MultiLanguageData";

export class MultiLanguageThumburlValue {
	public thumbSrc: string = "";

	constructor(value?: MultiLanguageThumburlValue) {
		if (!value) { return; }
		this.thumbSrc = value.thumbSrc;
	}

	public static fromThumbUrl(thumbSrc: string): MultiLanguageThumburlValue {
		let result = new MultiLanguageThumburlValue();
		result.thumbSrc = thumbSrc;
		return result;
	}

	public equal(value: MultiLanguageThumburlValue) {
		return (this.thumbSrc === value.thumbSrc);
	}
}

/**
 * ExhibitionThumbnail structure as returned by the exhibitions/<id>/thumbs endpoint.
 */
export interface ExhibitionThumbnail {
    type: string;
}

export interface ExhibitionImageThumbnail extends ExhibitionThumbnail {
    type: 'image'
    src: string
}

export interface ExhibitionCuboidThumbnail extends ExhibitionThumbnail, ICuboidSize {
    type: 'cuboid';

    width: number;
    height: number;
    depth: number;
    src: {
        front?: string;
        right?: string;
        back?: string;
        left?: string;
        top?: string;
        bottom?: string;
    }
}

export interface ExhibitionHologramThumbnail extends ExhibitionThumbnail {
    type: 'hologram';
    src: MultiLanguageData<MultiLanguageThumburlValue>
}