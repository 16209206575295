import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Exhibition } from '../../models/Exhibition';
import { ExhibitionService } from '../../services/exhibition.service';

/**
 * DeactivateExhibitonModal shows up whenever you are trying to deactivate an exhibition.
 * Requests confirmation.
 */
@Component({
  selector: 'modal-content',
  templateUrl: './deactivate.modal.component.html',
  styleUrls: ['./deactivate.modal.component.scss']
})
export class DeactivateExhibitonModal {

  /**
	 * The exhibition object.
	 * Is set by the initialState.
	 */
  public exhibition: Exhibition;

  /**
	 * Constructor
	 * @param bsModalRef Reference of the modal
	 */
  public constructor(
    public bsModalRef: BsModalRef,
    public exhibitionService: ExhibitionService
  ) { }

  /**
	 * Triggert by a click on the close button.
	 * Hides the modal without further action.
	 */
  public onClose(): void {
    this.bsModalRef.hide();
  }

  /**
	 * Triggert by a click on the deactivate button.
	 * Sets deactivate variable to true and hides the modal.
	 */
  public onDeactivate(): void {
    this.exhibition.active = false;
    const sub = this.exhibitionService.updateExhibitionActivation(this.exhibition).subscribe(
      response => {
        sub.unsubscribe();
      }
    );
    this.bsModalRef.hide();
  }
}
