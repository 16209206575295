<div #sceneContainer class="sceneContainer" (resized)="onResize()">
  <div class="scene" [ngStyle]="getSceneStyle()">
    <div #cube class="cube" [ngStyle]="getCubeStyle()">
      <div class="cube__face" [ngStyle]="getCubeStyleFaceFront()">
        <img class="tracker-layer" *ngIf="this.front" [src]="this.front">
        <div class="tracker-layer-text" *ngIf="!this.front">
          {{'CUBOID_FRONT' | translate }}
        </div>
      </div>
      <div class="cube__face" [ngStyle]="getCubeStyleFaceBack()">
        <img class="tracker-layer" *ngIf="this.back" [src]="this.back">
        <div class="tracker-layer-text" *ngIf="!this.back">
          {{'CUBOID_BACK' | translate }}
        </div>
      </div>
      <div class="cube__face" [ngStyle]="getCubeStyleFaceRight()">
        <img class="tracker-layer" *ngIf="this.right" [src]="this.right">
        <div class="tracker-layer-text" *ngIf="!this.right">
          {{'CUBOID_RIGHT' | translate }}
        </div>
      </div>
      <div class="cube__face" [ngStyle]="getCubeStyleFaceLeft()">
        <img class="tracker-layer" *ngIf="this.left" [src]="this.left">
        <div class="tracker-layer-text" *ngIf="!this.left">
          {{'CUBOID_LEFT' | translate }}
        </div>
      </div>
      <div class="cube__face" [ngStyle]="getCubeStyleFaceTop()">
        <img class="tracker-layer" *ngIf="this.top" [src]="this.top">
        <div class="tracker-layer-text" *ngIf="!this.top">
          {{'CUBOID_TOP' | translate }}
        </div>
      </div>
      <div class="cube__face" [ngStyle]="getCubeStyleFaceBottom()">
        <img class="tracker-layer" *ngIf="this.bottom" [src]="this.bottom">
        <div class="tracker-layer-text" *ngIf="!this.bottom">
          {{'CUBOID_BOTTOM' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
