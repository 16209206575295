import { Component, OnInit } from '@angular/core';

import { Alert, AlertStyle } from './models/Alert';
import { AlertService } from './services/alert.service';
import { CurrentContextService } from './services/currentContext.service';

/**
 * This is the root component of the Application
 * and is necessary for the use of the Application
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  /**
   * stores all current visible Alerts
   */
  alerts: any[] = [];

  /**
   * Subscription of the contextService.loading Observable
   * it shows a loader if any service is sending requests
   */
  private savingContentSubscription;

  /**
  * indicates a loading time for the html element to show the loader
  */
  public savingContent = false;

  public AlertStyle = AlertStyle;

  /**
   * creates an instance of AppComponent
   * creates a subscription to the loading Observable from the contextService
   * @param alertService us used to get all current available alerts and manage them here
   * @param contextService is used to get a subscription for the loading indicator
   * @param router is used to detect route changes in the url
   */
  public constructor(
    private alertService: AlertService,
    private contextService: CurrentContextService
  ) {
    this.savingContentSubscription = this.contextService.savingContent.subscribe(
        savingContent => {
          this.savingContent = savingContent;
        });
  }

  /**
   * subscribst to the alerts to detect changes in the alerts list
   */
  public ngOnInit(): void {
    this.alertService.getAlert().subscribe((alert: Alert) => {
      if (!alert) {
        this.alerts = [];
        return;
      }
      this.alerts.push(alert);
    });
  }

  /**
   * detects when the closing button of the alert is pressed
   * end removes the alert from the list
   * @param alert the alert that is closed
   */
  onClosed(alert: Alert) {
    this.alerts = this.alerts.filter(x => x !== alert);
  }

}
