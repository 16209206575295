<div class="content">
	<div class="edit-image-left-side">
		<aacms-loader class="imageLoader" size="128px"
			*ngIf="image && image.mediaId && (!this.imageData || this.imageData && !this.imageData.file)">
		</aacms-loader>
		<div *ngIf="this.imageData && this.imageData.file" class="image-available">
			<aacms-poi-layer #poilayer class="poi-layer unselectable" [imageData]="this.imageData"
				[imageId]="this.image.id" [mapId]="cubeSides.image" [currentLanguage]="this.currentLanguage">
			</aacms-poi-layer>
		</div>
		<div *ngIf="image && !image.mediaId" class="no-image-avaiable">
			<i>{{'NO_IMAGE_AVAILABLE_PLEASE_GO_TO_IMAGE_SETTINGS' | translate}}</i>
		</div>
	</div>

	<div class="edit-image-right-side">
		<ng-template [ngIf]="this.image && this.image.title">
			<div class="header">
				<div class="path-title-container">
					<div class="path">
						<div class="path-option" (click)="this.routeTo(appState.exhibitions)">{{'EXHIBITIONS' | translate}}</div>
						/
						<div class="path-option" (click)="this.routeTo(appState.images)">{{
							Tools.shortenString(contextService.currentExhibition &&
							getLanguageValue(contextService.currentExhibition.title) &&
							getLanguageValue(contextService.currentExhibition.title).length > 0 ?
							getLanguageValue(contextService.currentExhibition.title) : ('TRACKERS' | translate)) }}
						</div>
						/
						<div class="last-path-option">{{ Tools.shortenString(image &&
							getLanguageValue(image.title).length > 0 ? getLanguageValue(image.title) : ('IMAGE' | translate)) }}</div>
					</div>
					<div class="arlayer-title-edit-container">
						<h1 class="edit-indicator">
							<i class="fa fa-pencil" aria-hidden="true"
								[ngStyle]="{'color': (this.pms.poisChanged || this.imageChanged) ? '#ffc107' : 'black' }"></i>
						</h1>
						<div class="arlayer-title-container">
							<h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.image.title).length > 0">
								{{this.getLanguageValue(this.image.title)}}
							</h1>
							<h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.image.title).length == 0">
								<i>{{'UNNAMED_IMAGE' | translate }}</i>
							</h1>
						</div>
					</div>
				</div>
				<aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
					(languageChanged)="this.updateLanguage($event)">
				</aacms-language-selector>
			</div>
			<div class="custom-hr"></div>
			<div>
				<i>{{ 'IMAGE_POI' | translate }}:</i>
			</div>
			<div class="image-poi" (click)="selectPoi(null)"
				[style.border]="!this.pms.selectedPoi ? '2px solid #87ceeb': '2px solid gainsboro'">
				<div class="size-missing-indicator" *ngIf="this.image.width === 0 || this.image.height === 0"
					[matTooltip]="'MISSING_META_INFORMATION' | translate" triggers="hover">
					<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
				</div>
				<div class="image-poi-meta">
					<div class="image-poi-title">
						<span *ngIf="this.getLanguageValue(this.image.title).length > 0">
							{{this.getLanguageValue(this.image.title)}}
						</span>
						<i *ngIf="this.getLanguageValue(this.image.title).length === 0">
							{{ 'UNNAMED_IMAGE' | translate }}
						</i>
					</div>
				</div>
				<div class="image-poi-edit-buttons">
					<button class="image-poi-edit-button" *ngIf="config.getEnabledContentTypes().length > 1"
						(click)="toggleTypeImage(image, $event)" [matTooltip]="'TOGGLE_TYPE' | translate"
						triggers="hover">
						<i *ngIf="image.contentType == contentTypes.URL" class="fa fa-globe exhibition-icon" aria-hidden="true"></i>
						<i *ngIf="image.contentType == contentTypes.Custom" class="fa fa-file exhibition-icon" aria-hidden="true"></i>
					</button>
					<button class="image-poi-edit-button" (click)="routeToEditImageMeta()" [matTooltip]="'EDIT' | translate" triggers="hover">
						<i class="fa fa-pencil" aria-hidden="true"></i>
					</button>
					<button class="image-poi-edit-button" (click)="this.removeImageDescription()"
						[matTooltip]="'REMOVE_IMAGE_DESCRIPTION' | translate" triggers="hover">
						<i class="fa fa-trash" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			<div class="custom-hr"></div>
			<div class="poi-list-label">
				{{ 'POI_LIST_LABEL' | translate }}
			</div>
			<div *ngIf="this.imageData && this.pms.pois.length > 0" class="poi-info">
				<div class="poi-list">
					<ng-template ngFor let-poi [ngForOf]="this.pms.pois">
						<div class="poi" (click)="selectPoi(poi)" [style]="getPoiListEntryStyle(poi)">
							<div class="poi-meta" [style]="getPoiListTitleStyle(poi)">
								<div class="poi-title">
									<span *ngIf="this.getLanguageValue(poi.title).length > 0">
										<b class="poi-position">#{{poi.position + 1}}</b>
										{{this.getLanguageValue(poi.title)}}
									</span>
									<i *ngIf="this.getLanguageValue(poi.title).length === 0">
										<b class="poi-position">#{{poi.position + 1}}</b> {{ 'UNNAMED_POI' | translate }}
									</i>
								</div>
							</div>
							<div class="poi-edit-buttons">
								<button class="poi-edit-button" *ngIf="config.getEnabledContentTypes().length > 1"
									(click)="toggleType(poi, $event)" [matTooltip]="'TOGGLE_TYPE' | translate"
									triggers="hover">
									<i *ngIf="poi.contentType == contentTypes.URL" class="fa fa-globe exhibition-icon" aria-hidden="true"></i>
									<i *ngIf="poi.contentType == contentTypes.Custom" class="fa fa-file exhibition-icon" aria-hidden="true"></i>
								</button>
								<button class="poi-edit-button" (click)="routeToEditPoi(poi)"
									[matTooltip]="'EDIT' | translate" triggers="hover">
									<i class="fa fa-pencil exhibition-icon" aria-hidden="true"></i>
								</button>
								<button *ngIf="!poi.active" class="poi-edit-button" (click)="toggleActive(poi, $event)" [matTooltip]="'ACTIVATE_FOR_PUBLISH' | translate" triggers="hover">
									<i class="fa fa-eye-slash exhibition-icon" aria-hidden="true"></i>
								</button>
								<button *ngIf="poi.active" class="poi-edit-button" (click)="toggleActive(poi, $event)" [matTooltip]="'DEACTIVATE_FOR_PUBLISH' | translate" triggers="hover">
									<i class="fa fa-eye exhibition-icon" aria-hidden="true"></i>
								</button>
								<button class="poi-edit-button" (click)="deletePoi(poi, $event)"
									[matTooltip]="'DELETE' | translate" triggers="hover">
									<i class="fa fa-trash exhibition-icon" aria-hidden="true"></i>
								</button>
							</div>
						</div>
					</ng-template>
				</div>
				<div class="move-buttons">
					<button type="submit" class="move-button btn btn-light" (click)="movePoiUp()"
						[matTooltip]="'MOVE_POI_UP' | translate" matTooltipPosition="left">
						<i class="fa fa-angle-up" aria-hidden="true"></i>
					</button>
					<button type="submit" class="move-button btn btn-light" (click)="movePoiDown()"
						[matTooltip]="'MOVE_POI_DOWN' | translate" matTooltipPosition="left">
						<i class="fa fa-angle-down" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			<div *ngIf="!this.pms.isLoadingPois && this.pms.pois.length === 0">
				<i>{{ 'NO_POIS_AVAILABLE' | translate }}</i>
			</div>
			<div class="custom-hr"></div>
			<div class="row button-row">
				<button (click)="cancel()" type="button" class="btn btn-secondary custom-button">{{'BACK_TO_OVERVIEW' | translate }}</button>
				<button (click)="openModal(deleteModalTemplate)" type="button"
					class="btn btn-danger custom-button">{{'DELETE_IMAGE' | translate }}</button>
				<button (click)="openModal(cancelModalTemplate)" type="button"
					[disabled]="!this.pms.poisChanged && !this.imageChanged"
					class="btn btn-warning custom-button">{{'DISCARD' | translate }}</button>
				<button (click)="this.onSave()" type="button" [disabled]="!this.pms.poisChanged && !this.imageChanged"
					class="btn btn-success custom-button">{{'SAVE' | translate }}</button>
			</div>
		</ng-template>
	</div>
</div>

<ng-template #deleteModalTemplate>
	<div class="modal-header">
		<h4 *ngIf="!image.title.de" class="modal-title text-break pull-left">{{'REALY_DELETE_IMAGE' | translate }}</h4>
		<h4 *ngIf="image.title.de" class="modal-title text-break pull-left">
			{{'REALY_DELETE_IMAGE1' | translate }}
			<i>'{{image.title.de}}'</i> {{'REALY_DELETE_IMAGE2' | translate }}
		</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-footer">
		<button (click)="modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
		<button (click)="delete()" type="button" class="btn btn-danger">{{'YES_DELETE' | translate }}</button>
	</div>
</ng-template>

<ng-template #cancelModalTemplate>
	<div class="modal-header">
		<h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
	</div>
	<div class="modal-footer">
		<button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate
			}}</button>
		<button (click)="onDiscard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
	</div>
</ng-template>