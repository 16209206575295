<div *ngIf="this.hologramCard" class="edit-hologramCard-meta-main-content">
	<div class="edit-hologramCard-meta-left-side">
		<ng-template [ngIf]="!this.has3DModel()">
			{{'NO_HOLOGRAM_AVAILABLE' | translate}}
		</ng-template>
		<ng-template [ngIf]="this.hologramCardData && this.hologramCardData.fileSafeUrl">
			<model-viewer #modelViewer class="modelViewer" auto-rotate="true" disable-zoom="true" camera-controls="true"
				[attr.src]="this.hologramCardData.fileSafeUrl" autoplay>
			</model-viewer>
		</ng-template>
		<ng-template [ngIf]="!this.hologramCard?.model.has(this.currentLanguage)">
			<div class="custom-hr"></div>
			{{'HOLOGRAMCARD_UPLOAD_CARDSPECIFIC_MODEL' | translate}}
			<dropin [maxFileSize]="this.config.getMax3DModelSize()" (sendFiles)="this.handleFilesFromDropin($event)"
				[accept]="'.glb'" [filterFileExtensions]="['.gltf']">
			</dropin>
		</ng-template>
		<ng-template [ngIf]="this.hologramCardData && this.hologramCard?.model.has(this.currentLanguage)">
			<div class="custom-hr"></div>
			<div class="cardspecific-model-available">
				{{'HOLOGRAMCARD_CURRENT_CARDSPECIFIC_MODEL' | translate}}
				<div class="current-file">
					<div class="current-file-name">
						{{this.hologramCardData.file ? hologramCardData.file.name : hologramCardData.media.name}}
					</div>
					<div class="update-buttons">
						<div *ngIf="this.hologramCardData.fileSafeUrl"
							class="download-file"
							(click)="this.downloadFileToSystem(this.hologramCardData)"
							[matTooltip]="'DOWNLOAD_MEDIA' | translate" triggers="hover">
							<i class="fa fa-download"></i>
						</div>
						<div class="file-delete" (click)="this.removeCardspecificMedia()"
							[matTooltip]="'REMOVE_MEDIA' | translate" triggers="hover">
							<i class="fa fa-times"></i>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
	<div class="edit-hologramCard-meta-right-side">
		<div class="header">
			<div class="path-title-container">
				<div class="path">
					<div class="path-option" (click)="this.routeTo(appState.exhibitions)">{{'EXHIBITIONS' | translate}}
					</div>
					/
					<div class="path-option" (click)="this.routeTo(appState.images)">{{
						Tools.shortenString(contextService.currentExhibition && contextService.currentExhibition.title
						&& getLanguageValue(contextService.currentExhibition.title).length > 0 ?
						getLanguageValue(contextService.currentExhibition.title) : ('TRACKERS' | translate)) }}</div>
					/
					<div class="path-option" (click)="this.routeTo(appState.editHologram)">{{
						Tools.shortenString(
							(hologram && this.getLanguageValue(hologram.title).length > 0) ? getLanguageValue(hologram.title) : ('UNNAMED_HOLOGRAM' | translate)
						)}}
					</div>
					/
					<div class="last-path-option">{{'HOLOGRAMCARD' | translate}}</div>
				</div>
				<div class="arlayer-title-edit-container">
					<h1 class="edit-indicator">
						<i class="fa fa-pencil" aria-hidden="true"
							[ngStyle]="{'color': this.contentChanged ? '#ffc107' : 'black' }"></i>
					</h1>
					<div class="arlayer-title-container">
						<h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.hologramCard.title).length > 0">
							{{this.getLanguageValue(this.hologramCard.title)}}
						</h1>
						<h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.hologramCard.title).length == 0">
							<i>{{'UNNAMED_HOLOGRAMCARD' | translate }}</i>
						</h1>
					</div>
				</div>
			</div>
			<aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
				(languageChanged)="this.updateLanguage($event)">
			</aacms-language-selector>
		</div>
		<div class="custom-hr"></div>
		<tabset [justified]="false">
			<tab heading="{{'HOLOGRAMCARD_GENERAL_TAB' | translate}}" class="hologramCard-general-tab tab"
				(selectTab)="this.currentTab = tabType.GeneralTab">
				<div>{{'TITLE' | translate }}</div>
				<div class="hologramCard-title-input">
					<div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
						<input class="form-control textInput" [(ngModel)]="this.hologramCard.title.de"
							maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
							(ngModelChange)="checkForContentChange()" />
						<div class="languageIndicator">
							<img class="language-button-image" src="../assets/flags/de.svg">
						</div>
						<div class="align-self-center px-2">
							{{this.config.getMaxTitleTextLength() - this.hologramCard.title.de.length}}
						</div>
					</div>
					<div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
						<input class="form-control textInput" [(ngModel)]="this.hologramCard.title.gb"
							maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
							(ngModelChange)="checkForContentChange()" />
						<div class="languageIndicator">
							<img class="language-button-image" src="../assets/flags/gb.svg">
						</div>
						<div class="align-self-center px-2">
							{{this.config.getMaxTitleTextLength() - this.hologramCard.title.gb.length}}
						</div>
					</div>
					<div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
						<input class="form-control textInput" [(ngModel)]="this.hologramCard.title.jp"
							maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
							(ngModelChange)="checkForContentChange()" />
						<div class="languageIndicator">
							<img class="language-button-image" src="../assets/flags/jp.svg">
						</div>
						<div class="align-self-center px-2">
							{{this.config.getMaxTitleTextLength() - this.hologramCard.title.jp.length}}
						</div>
					</div>
				</div>
				<div *ngIf="this.showContentTypesSelection()">{{ 'TYPE_OF_CONTENT_PAGE' | translate }}</div>
				<div *ngIf="this.showContentTypesSelection()" class="selection-container">
					<select class="selection" [(ngModel)]="this.hologramCard.contentType"
						(change)="this.checkForContentChange()">
						<ng-template ngFor let-type [ngForOf]="this.config.getEnabledContentTypes()">
							<option role="selection-item" [ngValue]="this.type">{{
								this.getStringNameForContentType(this.type) }}
							</option>
						</ng-template>
					</select>
				</div>
				<div class="checkbox-container">
					<label class="checkbox">
						<input type="checkbox" (click)="this.toggleHologramCardActivation()"
							[checked]="this.hologramCard.active">
						<span class="checkmark" triggers="hover"></span>
						<div class="checkbox-text">
							{{ 'HOLOGRAMCARD_ACTIVE_INACTIVE' | translate }}
						</div>
					</label>
				</div>
				<div *ngIf="this.hologramCardData && this.hologramCardData.fileSafeUrl">
					<div class="model-scale-input-group" *ngIf="this.hologramCard.model.has(this.currentLanguage)">
						<label>{{ 'HOLOGRAM_SCALE_FACTOR' | translate }}</label>
						<input-number min="0.000001" max="100"
							[value]="this.hologramCard.model.get(this.currentLanguage).scaleFactor"
							(valueChange)="this.setModelScaleFactor($event)"></input-number>
					</div>
					<br/>
					{{ 'HOLOGRAM_DIMENSIONS' | translate }}
					{{ 'HOLOGRAM_DIMENSIONS_WIDTH' | translate }}: {{ this.calculateScaledModelDimensions().x.toFixed(2) }}m,
					{{ 'HOLOGRAM_DIMENSIONS_HEIGHT' | translate }}: {{ this.calculateScaledModelDimensions().y.toFixed(2) }}m,
					{{ 'HOLOGRAM_DIMENSIONS_DEPTH' | translate }}: {{ this.calculateScaledModelDimensions().z.toFixed(2) }}m
				</div>
			</tab>
			<tab heading="{{ 'HOLOGRAMCARD_CARD_TAB' | translate }}" class="card-tab tab"
				(selectTab)="this.currentTab = tabType.CardTab">
				<div class="smartphone-container">
					<aacms-info-card-editor [card]="this.hologramCard.card" [title]="this.hologramCard.title"
						forceVisible="true"
						[currentLanguage]="this.currentLanguage" [trackerFileData]="this.hologramCardDataMap"
						[isTracker]="false" (cardChange)="this.checkForContentChange()"
						[uiElements]="this.hologramCard.uiElements">
					</aacms-info-card-editor>
				</div>
			</tab>
			<tab heading="{{ 'HOLOGRAMCARD_CONTENT_PAGE_TAB' | translate }}" class="content-page-tab tab"
				[disabled]="this.hologramCard.contentType !== contentTypes.Custom"
				*ngIf="this.isTabAllowed(contentTypes.Custom)" (selectTab)="this.currentTab = tabType.UiElementsTab">
				<div class="smartphone-container">
					<aacms-content-page #smartphone class="smartphone" [(uiElements)]="this.hologramCard.uiElements"
						[isVisible]="currentTab === tabType.UiElementsTab" (uiElementsChange)="checkForContentChange()"
						[language]="this.currentLanguage">
					</aacms-content-page>
				</div>
			</tab>
			<tab heading="{{ 'HOLOGRAMCARD_URL_PAGE_TAB' | translate }}" class="url-page-tab tab"
				[disabled]="this.hologramCard.contentType !== contentTypes.URL"
				*ngIf="this.isTabAllowed(contentTypes.URL)" (selectTab)="this.currentTab = tabType.UrlTab">
				<div class="smartphone-container">
					<aacms-url-page [url]="this.hologramCard.url" (urlChange)="this.checkForContentChange()"
						[language]="this.currentLanguage">
					</aacms-url-page>
				</div>
			</tab>
		</tabset>
		<div class="custom-hr"></div>
		<div class="button-row row">
			<button type="submit" class="btn btn-secondary custom-button" (click)="returnToHologram()">
				{{'BACK_TO_HOLOGRAM' | translate}}
			</button>
			<button type="submit" class="btn btn-warning custom-button" [disabled]="!this.contentChanged"
				(click)="openModal(cancelModalTemplate)">
				{{'DISCARD' | translate }}
			</button>
			<button type="submit" class="btn btn-success custom-button" [disabled]="!this.contentChanged"
				(click)="this.onSave()">
				{{'SAVE_CHANGES' | translate }}
			</button>
		</div>
	</div>
</div>

<ng-template #cancelModalTemplate>
	<div class="modal-header">
		<h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
	</div>
	<div class="modal-footer">
		<button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate
			}}</button>
		<button (click)="discard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
	</div>
</ng-template>

<ng-template #saveModalTemplate>
	<div class="modal-header">
		<h4 class="modal-title text-break pull-left">{{'REALY_SAVE_WITHOUT_DIMENSIONS' | translate}}</h4>
	</div>
	<div class="modal-footer">
		<button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'ABORT' | translate }}</button>
		<button (click)="this.save()" type="button" class="btn btn-success">{{'SAVE'| translate}}</button>
	</div>
</ng-template>