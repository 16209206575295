export enum AppState {
  login = 0,
  images = 1,
  editImage = 2,
  editPoint = 3,
  notFound = 4,
  exhibitions = 5,
  search = 6,
  admin = 7,
  editUser = 8,
  pushNotifications = 9,
  appMenu = 10,
  editImageMeta = 11,
  editARLayer = 12,
  editCuboid = 13,
  editArLayerMeta = 14,
  editArLayerPoi = 15,
  editPoiTextures = 16,
  editCuboidMeta = 17,
  tours = 18,
  editTour = 19,
  editTourMeta = 20,
  termsOfUse = 21,
  editPlace = 22,
  editPlaceMeta = 23,
  editInfoCard = 24,
  editHologram = 25,
  editHologramMeta = 26
}
