import { MultiLanguageText } from './MultiLanguageText';
import { UiElement, ElementType, AudioElement, ImageElement, ModelElement, TextElement, VideoElement } from './UiElements';
import { Config } from '../Config';
import { ContentTypes } from './ContentTypes';
import { CubeSides } from './CubeSides';
import { Card } from './Card';
import { MapElement } from './GeoJson';
import { MultiLanguageData } from './MultiLanguageData';
import { Tools } from '../Tools';

export class PoiTextureIdValue {
	public textureId: string;
	public rotation: [number, number, number] = [0, 0, 0];

	constructor(textureIdValue?: PoiTextureIdValue) {
		if (!textureIdValue) { return; }
		this.textureId = textureIdValue.textureId;
		this.rotation = [
      textureIdValue.rotation[0],
      textureIdValue.rotation[1],
      textureIdValue.rotation[2]
    ];
	}

	public static fromTextureId(textureId: string): PoiTextureIdValue {
		let result = new PoiTextureIdValue();
		result.textureId = textureId;
		return result;
	}

	public equal(textureIdValue: PoiTextureIdValue) {
		return (this.textureId === textureIdValue.textureId
      && this.rotation[0] === textureIdValue.rotation[0]
      && this.rotation[1] === textureIdValue.rotation[1]
      && this.rotation[2] === textureIdValue.rotation[2]);
	}
}

export class Poi {

  public id: string;
  public parentImageId: string;
  public title: MultiLanguageText = new MultiLanguageText();
  public imageSide: CubeSides;
  public x: number;
  public y: number;
  public position: number;
  public createdTime: number;
  public updatedTime: number;
  public uiElements: UiElement[] = [];
  public url: MultiLanguageText = new MultiLanguageText();
  public contentType: ContentTypes = new Config().getEnabledContentTypes()[0];
  public active = true;
  public textureId = '00000000-0000-0000-0000-000000000001';
  public multiLanguageTextureId: MultiLanguageData<PoiTextureIdValue> = new MultiLanguageData<PoiTextureIdValue>(PoiTextureIdValue);
  public scaleFactor = 0.1;
  public opener = false;
  public card: Card = new Card();
  public distanceToTracker = 0;

  public constructor(poi?: Poi) {
    if (!poi) {
      this.ensureMultiLangTextureIdInitialized();
      return;
    }
    this.id = poi.id;
    this.parentImageId = poi.parentImageId;
    this.title = new MultiLanguageText(poi.title);
    this.x = poi.x;
    this.y = poi.y;
    this.position = poi.position;
    this.createdTime = poi.createdTime;
    this.updatedTime = poi.updatedTime;
    this.uiElements = [];
    this.url = new MultiLanguageText(poi.url);
    this.contentType = poi.contentType;
    this.active = poi.active;
    this.imageSide = poi.imageSide;
    this.textureId = poi.textureId;
    this.multiLanguageTextureId = new MultiLanguageData<PoiTextureIdValue>(PoiTextureIdValue, poi.multiLanguageTextureId);
    this.ensureMultiLangTextureIdInitialized();
    this.scaleFactor = poi.scaleFactor;
    this.opener = poi.opener;
    this.card = new Card(poi.card);
    this.distanceToTracker = poi.distanceToTracker;
    if (!poi.contentType) {
      this.contentType = new Config().getEnabledContentTypes()[0];
    }
    for (const element of poi.uiElements) {
      if (element.type === ElementType.AUDIO) {
        this.uiElements.push(new AudioElement((<AudioElement>element)));
      } else if (element.type === ElementType.IMAGE) {
        this.uiElements.push(new ImageElement((<ImageElement>element)));
      } else if (element.type === ElementType.MODEL) {
        this.uiElements.push(new ModelElement((<ModelElement>element)));
      } else if (element.type === ElementType.TEXT) {
        this.uiElements.push(new TextElement((<TextElement>element)));
      } else if (element.type === ElementType.VIDEO) {
        this.uiElements.push(new VideoElement((<VideoElement>element)));
      } else if (element.type === ElementType.MAP) {
        this.uiElements.push(new MapElement((<MapElement>element)));
      }
    }
    this.uiElements = this.uiElements.sort((uie1, uie2) => uie1.position - uie2.position);
  }

  private ensureMultiLangTextureIdInitialized() {
    for(let lang of Tools.allLangs()) {
      if(!this.multiLanguageTextureId.has(lang)) {
        this.multiLanguageTextureId.set(lang, PoiTextureIdValue.fromTextureId('00000000-0000-0000-0000-000000000001'));
      }
    }
  }

  public equal(poi: Poi): boolean {
    if (
      this.parentImageId !== poi.parentImageId ||
      !this.title.equal(poi.title) ||
      this.x !== poi.x ||
      this.y !== poi.y ||
      this.position !== poi.position ||
      this.createdTime !== poi.createdTime ||
      !this.url.equal(poi.url) ||
      this.contentType !== poi.contentType ||
      this.imageSide !== poi.imageSide ||
      this.active !== poi.active ||
      this.textureId !== poi.textureId ||
      !this.multiLanguageTextureId.equal(poi.multiLanguageTextureId) ||
      this.scaleFactor !== poi.scaleFactor ||
      this.opener !== poi.opener ||
      !this.card.equal(poi.card) ||
      this.distanceToTracker !== poi.distanceToTracker
    ) {
      return false;
    }
    if (
      !this.uiElements && poi.uiElements ||
      this.uiElements && !poi.uiElements
    ) {
      return false;
    }
    if (this.uiElements && poi.uiElements) {
      if (this.uiElements.length !== poi.uiElements.length) {
        return false;
      } else if (this.uiElements.length > 0) {
        for (const uiElement of this.uiElements) {
          const poiUiElement = poi.uiElements.find(uie => uie.position === uiElement.position);
          if (
            !poiUiElement ||
            !uiElement.equal(poiUiElement)
          ) {
            return false;
          }
        }
      }
    }
    return true;
  }
}
