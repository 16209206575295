import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap, share } from 'rxjs/operators';

import { Config } from '../Config';

import { AlertService } from './alert.service';
import { StringService } from './string.service';
import { AlertType, AlertSimple } from '../models/Alert';
import { Menu, MenuType, ListMenu, GridMenu } from '../models/MenuElements';
import { AppMenu } from '../models/AppMenu';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root',
})
export class AppMenuService {

  private baseUrl = new Config().GetBaseUrl();

  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService
  ) { }


  public getAppMenu(): Observable<Menu> {
    return this.http.get<Menu>(this.getAppMenuUrl()).pipe(
      tap(
        response => { },
        error => { this.handleError<Menu>('getAppMenu', error); }
      ),
      share()
    );
  }

  public createAppMenu(menu: Menu): Observable<any> {
    return this.http.post<any>(this.getAppMenuUrl(), menu, httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(
            new AlertSimple(this.stringService.get('MENU_CREATED'), AlertType.Success), true
          );
        },
        error => { this.handleError<Menu>('createMenu', error); }
      ),
      share()
    );
  }

  public updateAppMenu(menu: Menu): Observable<any> {
    if (menu === undefined) {
      return this.deleteMenu();
    }
    const menuClone = new AppMenu(menu);
    menuClone.cleanForUpload();
    return this.http.put(this.getAppMenuUrl(), menuClone.getAsMenu(), httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(
            new AlertSimple(this.stringService.get('MENU_CREATED'), AlertType.Success), true
          );
        },
        error => { this.handleError<Menu>('updateMenu', error); }
      ),
      share()
    );
  }

  public deleteMenu(): Observable<any> {
    return this.http.delete(this.getAppMenuUrl(), httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(
            new AlertSimple(this.stringService.get('MENU_DELETED'), AlertType.Success), true
          );
        },
        error => { this.handleError<Menu>('deleteMenu', error); }
      ),
      share()
    );
  }

  public publishMenu(): Observable<any> {
    return this.http.post(this.getAppMenuUrl(`-/publish`), httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(
            new AlertSimple(
              this.stringService.get('MENU_PUBLISHED'),
              AlertType.Success),
            true);
        },
        error => {
          this.handleError<Menu>('publishMenu', error);
        }
      ),
      share()
    );
  }

  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }


  private logError(value: any): void {
    console.error(value);
  }

  private getAppMenuUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}app-menu/`;
    if (extension) {
      if (!extension.endsWith('/')) {
        extension = `${extension}/`;
      }
      url = `${url}${extension}`;
    }
    return url;
  }
}
