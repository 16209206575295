import { CubeSides } from './../../models/CubeSides';
import { AlertWithBoldValueInMiddle, AlertType } from './../../models/Alert';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { tap } from 'rxjs/operators';
import { Config } from '../../Config';
import { Exhibition } from '../../models/Exhibition';
import { Image } from '../../models/Image';
import { AlertService } from '../../services/alert.service';
import { ImageService } from '../../services/image.service';
import { MediaService } from '../../services/media.service';
import { StringService } from '../../services/string.service';

@Component({
  selector: 'modal-content',
  templateUrl: './multiUpload.modal.component.html',
  styleUrls: ['./multiUpload.modal.component.scss']
})
export class MultiUploadImagesModal implements OnInit {

  public currentFiles: File[] = [];
  public uploadInProgress = false;
  private loadedImages: Map<string, boolean> = new Map<string, boolean>();
  private currentExhibition: Exhibition;
  private amount = 0;

  public constructor(
    public bsModalRef: BsModalRef,
    private imageService: ImageService,
    private mediaService: MediaService,
    public alertService: AlertService,
    public stringService: StringService
  ) { }

  public handleFilesFromDropin(event: File[]) {
    this.handleFiles(event);
  }

  public ngOnInit(): void {
    this.uploadInProgress = false;
  }

  private handleFiles(files: File[]): void {
    for (const file of files) {
      this.currentFiles.push(file);
      this.loadedImages.set(file.name, false);
    }
  }

  private removeFile(file: File): void {
    if (this.currentFiles.includes(file)) {
      this.currentFiles = this.currentFiles.filter(element => element !== file);
    }
  }

  public onClose(): void {
    this.bsModalRef.hide();
  }

  public async onCreate(): Promise<void> {
    this.uploadInProgress = true;
    this.amount = this.currentFiles.length;
    for (let index = 0; index < this.currentFiles.length; index++) {
      let uploadWasGood = true;
      const file = this.currentFiles[index];
      const filename = file.name.substring(0, file.name.lastIndexOf('.'));
      const image = new Image();
      await this.uploadFile(file).then(
        response => {
        image.title.de = filename;
        image.title.gb = filename;
        image.title.jp = filename;
        image.exhibitionIds.push(this.currentExhibition.id);
        const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
        image.mediaId = location.substring(location.lastIndexOf('/') + 1);
      },
      error => {
        uploadWasGood = false;
      });
      if (uploadWasGood) {
        await this.createImage(image, file);
      } else {
        this.updateUploadProgress(file);
      }
    }
  }

  private async uploadFile(file: File): Promise<any> {
    let uploaded = false;
    return this.mediaService.uploadFile(file, true).pipe(
      tap(
        async response => {
          if (response.status < 400 && !uploaded) {
            uploaded = true;
          }
        },
        async error => {
          if (error.status === 412) {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('IMAGE_NOT_TRACKABLE_1'),
                file.name,
                this.stringService.get('IMAGE_NOT_TRACKABLE_2'),
                AlertType.Error
              )
            );
          }
        })).toPromise();
  }

  private async createImage(image: Image, file: File): Promise<any> {
    return this.imageService.createImage(image).pipe(tap(response2 => {
      this.updateUploadProgress(file);
    })).toPromise();
  }

  private updateUploadProgress(file: File): void {
    this.amount--;
      this.loadedImages.set(file.name, true);
      if (this.amount === 0) {
        this.bsModalRef.hide();
        this.uploadInProgress = false;
      }
  }

  private imageLoaded(file: File): boolean {
    return this.loadedImages.get(file.name);
  }

  public trackByFn(index, item) {
    return item.id;
  }

  public getMaxContentFileSize(): number {
    return new Config().getMaxImageFileSize();
  }
}
