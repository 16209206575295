import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { CurrentContextService } from '../../services/currentContext.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
    public onClose: Subject<boolean>;
    private saveContentSubscription: Subscription;
    private isSaving = false;

    public constructor(
        private _bsModalRef: BsModalRef,
        private contextService: CurrentContextService
    ) { }

    public ngOnInit(): void {
        this.onClose = new Subject();
        this.saveContentSubscription = this.contextService.savingContent.subscribe(
          change => {
            if (change) {
              this.isSaving = change;
            }
            if (this.isSaving && !change) {
              this.onConfirm();
            }
          }
        );
    }

    public ngOnDestroy(): void {
      this.saveContentSubscription.unsubscribe();
    }

    public showConfirmationModal(): void {

    }

    public onConfirm(): void {
        this.onClose.next(true);
        this._bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose.next(false);
        this._bsModalRef.hide();
    }

    public hideConfirmationModal(): void {
        this.onClose.next(null);
        this._bsModalRef.hide();
    }

    public saveAndCancel() {
      this.contextService.setSaveYourContent(true);
    }
}
