export class PoiTexture {
  public id: string;
  public name: string;
  public createdTime: number;
  public updatedTime: number;
  public mediaId: string;

  constructor(texture?: PoiTexture) {
    if (!texture) { return; }
    this.id = texture.id;
    this.name = texture.name;
    this.createdTime = texture.createdTime;
    this.updatedTime = texture.updatedTime;
    this.mediaId = texture.mediaId;
  }

  public equal(texture: PoiTexture): boolean {
    if (
      this.id !== texture.id ||
      this.name !== texture.name ||
      this.createdTime !== texture.createdTime ||
      this.updatedTime !== texture.updatedTime ||
      this.mediaId !== texture.mediaId
    ) {
      return false;
    }
    return true;
  }
}
