/**
 * AlertType enum
 */
export enum AlertType {
  Success = 'success',  //green
  Error = 'danger', // red
  Info = 'info', // blue
  Warning = 'warning' // yellow 
}

export enum AlertStyle {
  'ALERT_WITH_URL' = 0,
  'ALERT_SIMPLE' = 1,
  'ALERT_WITH_BOLD_VALUE_IN_MIDDLE' = 2
}

/**
 * Alert class.
 */
export class Alert {
  /**
   * One of the alert types (success, danger, info, warning)
   */
  public type: AlertType;

  public style: AlertStyle;

  /**
   * Time in ms which defines how long the alert is displayed.
   */
  public timeout: number;

  constructor(type: AlertType, style: AlertStyle, timeout: number = 7000) {
    this.type = type;
    this.style = style;
    this.timeout = timeout;
  }
}

export class AlertWithBoldValueInMiddle extends Alert {
  public firstText: string;
  public value: string;
  public secondText: string;

  constructor(firstText: string, value: string, secondText: string, type: AlertType, timeout: number = 7000) {
    super(type, AlertStyle.ALERT_WITH_BOLD_VALUE_IN_MIDDLE, timeout);
    this.firstText = firstText;
    this.value = value;
    this.secondText = secondText;
  }
}

export class AlertSimple extends Alert {
  public text: string;

  constructor(text: string, type: AlertType, timeout: number = 7000) {
    super(type, AlertStyle.ALERT_SIMPLE, timeout);
    this.text = text;
  }
}

export class AlertWithUrl extends Alert {
  public text: string;
  public url: string;

  constructor(text: string, url: string, type: AlertType, timeout: number = 7000) {
    super(type, AlertStyle.ALERT_WITH_URL, timeout);
    this.text = text;
    this.url = url;
  }
}
