export class User {

  public id: string;
  public name: string;
  public realName: string;
  public password: string;
  public privileges: string[] = [];
  public loggedIn = false;
  public lastRefresh = 0;
  public token = '';

  public constructor(user?: User) {
    if (!user) { return; }
    this.id = user.id;
    this.name = user.name;
    this.realName = user.realName;
    this.privileges = user.privileges;
    this.token = user.token;
    this.lastRefresh = user.lastRefresh;
    this.loggedIn = user.loggedIn;
  }

  public equal(user: User): boolean {
    if (this.id !== user.id ||
      this.name !== user.name ||
      this.realName !== user.realName) {
      return false;
    }

    let eq = true;
    for (let i = 0; i < this.privileges.length; i++) {
      if (this.privileges.indexOf(user.privileges[i]) === -1) {
        eq = false;
        break;
      }
    }
    return eq;
  }

  public refresh(token: string): void {
    this.token = token;
    this.lastRefresh = new Date().getTime();
  }

  public isLoggedIn(): boolean {
    return this.loggedIn;
  }

  public isAdmin(): boolean {
    if (this.privileges) {
      return this.privileges.includes('admin');
    } else {
      return false;
    }

  }
}
