import { UiElement, ElementType, ImageElement, AudioElement, VideoElement, ModelElement, TextElement } from './UiElements';
import { MultiLanguageText } from './MultiLanguageText';
import * as uuid from 'uuid';
import { Config } from '../Config';
import { ContentTypes } from './ContentTypes';
import { MapElement } from './GeoJson';
import { MultiLanguageFileDataType } from './MultiLanguageFileDataType';
import { MultiLanguageMediaId } from './MultiLanguageMediaId';

export enum MenuType {
	LIST = 0,
	GRID = 1,
	AR = 98,
	TOURS = 99
}

export class ContentPage {
	public contentType: ContentTypes = new Config().getEnabledContentTypes()[0];
	public uiElements: UiElement[] = [];
	public url: MultiLanguageText = new MultiLanguageText();

	constructor(contentPage?: ContentPage) {
		if (contentPage === undefined) { return; }
		this.contentType = contentPage.contentType;
		if (!contentPage.contentType) {
			this.contentType = new Config().getEnabledContentTypes()[0];
		}
		this.url = new MultiLanguageText(contentPage.url);
		for (const uiElement of contentPage.uiElements) {
			switch (uiElement.type) {
				case ElementType.IMAGE: {
					this.uiElements.push(new ImageElement(<ImageElement>uiElement));
					break;
				}
				case ElementType.AUDIO: {
					this.uiElements.push(new AudioElement(<AudioElement>uiElement));
					break;
				}
				case ElementType.VIDEO: {
					this.uiElements.push(new VideoElement(<VideoElement>uiElement));
					break;
				}
				case ElementType.MODEL: {
					this.uiElements.push(new ModelElement(<ModelElement>uiElement));
					break;
				}
				case ElementType.TEXT: {
					this.uiElements.push(new TextElement(<TextElement>uiElement));
					break;
				}
				case ElementType.MAP: {
					this.uiElements.push(new MapElement(<MapElement>uiElement));
					break;
				}
			}
		}
		this.uiElements = this.uiElements.sort((uie1, uie2) => uie1.position - uie2.position);
	}

	public equal(contentPage: ContentPage): boolean {
		if (
			this.contentType !== contentPage.contentType ||
			this.uiElements.length !== contentPage.uiElements.length ||
			!this.url.equal(contentPage.url)
		) {
			return false;
		}
		for (let i = 0; i < this.uiElements.length; i++) {
			if (!this.uiElements[i].equal(contentPage.uiElements[i])) {
				return false;
			}
		}
		return true;
	}
}

export class MenuEntry {
	public id: string;
	public title: MultiLanguageText = new MultiLanguageText();
	public mediaId: MultiLanguageMediaId = new MultiLanguageMediaId();
	public position = -1;
	public isMandatory: boolean = false;
	public href;

	constructor(item?: MenuEntry) {
		if (item === undefined) {
			this.id = uuid.v4();
			return;
		}
		this.id = item.id;
		this.title = new MultiLanguageText(item.title);
		this.mediaId = item.mediaId;
		this.position = item.position;
		if (item.isMandatory !== undefined) {
			this.isMandatory = item.isMandatory;
		}
		switch (item.href.type) {
			case MenuType.LIST:		this.href = new ListMenu(item.href); break;
			case MenuType.GRID:		this.href = new GridMenu(item.href); break;
			case MenuType.TOURS:	this.href = new TourMenu(item.href); break;
			case MenuType.AR:		this.href = new ARMenu(item.href); break;
			default:				this.href = new ContentPage(item.href);
		}
	}

	public equal(entry: MenuEntry): boolean {
		if (
			!this.title.equal(entry.title) ||
			this.mediaId !== entry.mediaId ||
			this.position !== entry.position ||
			this.isMandatory !== entry.isMandatory
		) {
			return false;
		}
		switch (this.href.type) {
			case MenuType.LIST:		return (<ListMenu>this.href).equal(<ListMenu>entry.href);
			case MenuType.GRID:		return (<GridMenu>this.href).equal(<GridMenu>entry.href);
			case MenuType.TOURS:	return (<TourMenu>this.href).equal(<TourMenu>entry.href);
			case MenuType.AR:		return (<ARMenu>this.href).equal(<ARMenu>entry.href);
			default:				return (<ContentPage>this.href).equal(<ContentPage>entry.href);
		}
	}
}

export class ListEntry extends MenuEntry {
	constructor(item?: ListEntry) {
		super(item);
	}
}

export class GridEntry extends MenuEntry {
	constructor(item?: GridEntry) {
		super(item);
	}
}


/**
 * Menu that can have child MenuEntry objects.
 * MenuEntry objects have a href attribute. If a MenuEntry is not actually a MenuEntry, but a submenu,
 * then its href attribute is set to an instance of a Menu class.
 */
export class Menu {
	public id: string;
	public type: MenuType;
	public elements: MenuEntry[] = [];
	public mediaId: MultiLanguageMediaId = new MultiLanguageMediaId();
	public showLogo = true;

	constructor(menu?: Menu) {
		if (menu === undefined) {
			this.id = uuid.v4();
			return;
		}
		this.id = menu.id;
		this.type = menu.type;
		if (menu.elements != undefined) {
			for (const element of menu.elements) {
				this.elements.push(new MenuEntry(element));
			}
			this.mediaId = menu.mediaId;
			this.showLogo = menu.showLogo;
		}
	}

	public equal(menu: Menu): boolean {
		if (
			this.type !== menu.type ||
			this.showLogo !== menu.showLogo ||
			this.mediaId !== undefined && menu.mediaId === undefined ||
			this.mediaId === undefined && menu.mediaId !== undefined
		) {
			return false;
		}
		if (this.mediaId !== undefined && !this.mediaId.equal(menu.mediaId)) {
			return false;
		}
		if (this.elements.length !== menu.elements.length) {
			return false;
		}
		for (let i = 0; i < this.elements.length; i++) {
			if (!this.elements[i].equal(menu.elements[i])) {
				return false;
			}
		}
		return true;
	}
}

export class ListMenu extends Menu {
	constructor(listMenu?: ListMenu) {
		super(listMenu);
		super.type = MenuType.LIST;
	}
}

export class GridMenu extends Menu {
	constructor(gridMenu?: GridMenu) {
		super(gridMenu);
		super.type = MenuType.GRID;
	}
}

export class TourMenu extends Menu {
	constructor(tourMenu?: TourMenu) {
		super(tourMenu);
		super.type = MenuType.TOURS;
	}
}

export class ARMenu extends Menu {
	constructor(arMenu?: ARMenu) {
		super(arMenu);
		super.type = MenuType.AR;
	}
}
