import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertService } from "./alert.service";
import { CurrentContextService } from "./currentContext.service";
import { StringService } from "./string.service";
import { Config }from "../Config";
import { Observable, of } from "rxjs";
import { Lock } from "../models/Lock";
import { share, tap } from "rxjs/operators";
import { AlertSimple, AlertType } from "../models/Alert";

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    observe: 'response' as 'body'
  };
  
@Injectable({
    providedIn: 'root',
})
export class LockService {

    private baseUrl = new Config().GetBaseUrl();

    public currentLock: Lock;

    constructor(
        private http: HttpClient,
        private alertService: AlertService,
        private stringService: StringService,
        private contextService: CurrentContextService
    ) { }

    public getLockById(lockId: string): Observable<any> {
        let extension = '';
        return this.http.get<any>(this.getLockUrl(`${lockId}`) + extension).pipe(
          tap(
            response => { 
                this.currentLock = new Lock(response.body);
            },
            error => { this.handleError<any>('getLockById', error); }
          ),
          share()
        );
    }

    public createLock(lock: Lock): Observable<any> {
        return this.http.post<any>(this.getLockUrl(), lock, httpOptions).pipe(
          tap(
            response => {
                this.currentLock = new Lock(response.body);
            },
            error => { 
              this.alertService.alert(
                new AlertSimple(
                  this.stringService.get('RESOURCE_LOCKED'),
                  AlertType.Error),
                true);
              this.handleError<Lock>('createLock', error); }
          ),
          share()
        );
    }

    public updateLock(lockId: string): Observable<any> {
        return this.http.put<any>(this.getLockUrl(`${lockId}`), httpOptions).pipe(
          tap(
            response => {
                this.currentLock = new Lock(response);
            },
            error => { this.handleError<Lock>('updateLock', error); }
          ),
          share()
        );
    }

    public deleteLock(lockId: string): Observable<any> {
        return this.http.delete(this.getLockUrl(`${lockId}`), httpOptions).pipe(
          tap(
            response => {
                this.currentLock = undefined;
            },
            error => { this.handleError<Lock>('deleteLock', error); }
          ),
          share()
        );
    }

    private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
        if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
          this.alertService.serverError();
        }
        return (error: any): Observable<T> => {
          this.logError(`${operation} failed: ${error.message}`);
          return of(result as T);
        };
    }
    
    private logError(value: any): void {
        console.error(value);
    }
    
    private getLockUrl(extension?: string): string {
        let url = this.baseUrl;
        if (!url.endsWith('/')) {
            url = `${url}/`;
        }
        url = `${url}mutex/`;
        if (extension) {
            if (!extension.endsWith('/')) {
            extension = `${extension}/`;
            }
            url = `${url}${extension}`;
        }
        return url;
    }
}