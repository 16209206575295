import { Media } from './Media';
import { SafeUrl } from '@angular/platform-browser';

export class FileDataType {
  public mediaId: string;
  public file: File;
  public fileSafeUrl: SafeUrl;
  public isLoaded: boolean;
  public media: Media;

  public constructor(fileData?: FileDataType) {
    if (!fileData) { return; }
    this.mediaId = fileData.mediaId;
    this.file = fileData.file;
    this.fileSafeUrl = fileData.fileSafeUrl;
    this.isLoaded = fileData.isLoaded;
    if (fileData.media !== undefined) {
      this.media = new Media(fileData.media);
    }
  }

  public equal(file: FileDataType): boolean {
    return this.mediaId === file.mediaId;
  }

  public static fromMediaId(mediaId: string): FileDataType {
		let result = new FileDataType();
		result.mediaId = mediaId;
		return result;
	}

  public clean(): void {
    this.file = undefined;
    this.fileSafeUrl = undefined;
    this.isLoaded = undefined;
    this.media = undefined;
  }
}
