<div class="richtext-editor-toolbar" *ngIf="showToolbar">
  <div class="richtext-editor-toolbar-set">
    <button type="button" [matTooltip]="'UNDO' | translate" class="richtext-editor-button" (click)="triggerCommand('undo')"
      tabindex="-1"><i class='fa fa-undo'></i></button>
    <button type="button" [matTooltip]="'REDO' | translate" class="richtext-editor-button" (click)="triggerCommand('redo')"
      tabindex="-1"><i class='fa fa-repeat'></i></button>
  </div>

  <div class="richtext-editor-toolbar-set">
    <ae-select class="select-heading" [options]="headings" [(ngModel)]="block" (change)="triggerCommand(block)"
      [disabled]="htmlMode" [hidden]="isButtonHidden('heading')" tabindex="-1"></ae-select>
  </div>
  <div class="richtext-editor-toolbar-set">
    <ae-select class="select-font" [options]="fonts" [(ngModel)]="fontName" (change)="setFontName(fontName)"
      [disabled]="htmlMode" [hidden]="isButtonHidden('fontName')" tabindex="-1"></ae-select>
  </div>
  <div class="richtext-editor-toolbar-set">
    <ae-select class="select-font-size" [options]="fontSizes" [(ngModel)]="fontSize" (change)="setFontSize(fontSize)"
      [disabled]="htmlMode" [hidden]="isButtonHidden('fontSize')" tabindex="-1">
    </ae-select>
  </div>

  <div class="richtext-editor-toolbar-set">
    <button [id]="'bold-'+id" type="button" [matTooltip]="'BOLD' | translate" class="richtext-editor-button"  
      (click)="triggerCommand('bold')" [disabled]="htmlMode" [hidden]="isButtonHidden('bold')" tabindex="-1"><i
        class='fa fa-bold'></i></button>
    <button [id]="'italic-'+id" type="button" [matTooltip]="'ITALIC' | translate" class="richtext-editor-button"  
      (click)="triggerCommand('italic')" [disabled]="htmlMode" [hidden]="isButtonHidden('italic')" tabindex="-1"><i
        class='fa fa-italic'></i>
    </button>
    <button [id]="'underline-'+id" type="button" [matTooltip]="'UNDERLINE' | translate" class="richtext-editor-button"  
      (click)="triggerCommand('underline')" [disabled]="htmlMode" [hidden]="isButtonHidden('underline')"
      tabindex="-1"><i class='fa fa-underline'></i></button>
    <button [id]="'strikeThrough-'+id" type="button" [matTooltip]="'STRIKE_THROUGH' | translate"
      class="richtext-editor-button" (click)="triggerCommand('strikeThrough')" [disabled]="htmlMode"
      [hidden]="isButtonHidden('strikeThrough')" tabindex="-1"><i class='fa fa-strikethrough'></i></button>
    <button [id]="'subscript-'+id" type="button" [matTooltip]="'SUBSCRIPT' | translate" class="richtext-editor-button"  
      (click)="triggerCommand('subscript')" [disabled]="htmlMode" [hidden]="isButtonHidden('subscript')"
      tabindex="-1"><i class='fa fa-subscript'></i></button>
    <button [id]="'superscript-'+id" type="button" [matTooltip]="'SUPERSCRIPT' | translate" class="richtext-editor-button"  
      (click)="triggerCommand('superscript')" [disabled]="htmlMode" [hidden]="isButtonHidden('superscript')"
      tabindex="-1"><i class='fa fa-superscript'></i></button>
  </div>

  <div class="richtext-editor-toolbar-set">
    <input style="display: none" type="color" (change)="insertColor(fgInput.value, 'textColor')" #fgInput>
    <button [id]="'foregroundColorPicker-'+id" type="button" class="richtext-editor-button" (click)="fgInput.click()"
      [matTooltip]="'TEXT_COLOR' | translate" [disabled]="htmlMode" [hidden]="isButtonHidden('textColor')"
      tabindex="-1"><span class="color-label foreground"><i class="fa fa-font"></i></span>
    </button>
    <input style="display: none" type="color" (change)="insertColor(bgInput.value, 'backgroundColor')" #bgInput>
    <button [id]="'backgroundColorPicker-'+id" type="button" class="richtext-editor-button" (click)="bgInput.click()"
      [matTooltip]="'BACKGROUND_COLOR' | translate" [disabled]="htmlMode" [hidden]="isButtonHidden('backgroundColor')"
      tabindex="-1"><span class="color-label background"><i class="fa fa-font"></i></span>
    </button>
  </div>

  <div class="richtext-editor-toolbar-set">
    <button [id]="'justifyLeft-'+id" type="button" [matTooltip]="'JUSTIFY_LEFT' | translate" class="richtext-editor-button"  
      (click)="triggerCommand('justifyLeft')" [disabled]="htmlMode" [hidden]="isButtonHidden('justifyLeft')"
      tabindex="-1"><i class='fa fa-align-left'></i></button>
    <button [id]="'justifyCenter-'+id" type="button" [matTooltip]="'JUSTIFY_CENTER' | translate"
      class="richtext-editor-button" (click)="triggerCommand('justifyCenter')" [disabled]="htmlMode"
      [hidden]="isButtonHidden('justifyCenter')" tabindex="-1"><i class='fa fa-align-center'></i></button>
    <button [id]="'justifyRight-'+id" type="button" [matTooltip]="'JUSTIFY_RIGHT' | translate"
      class="richtext-editor-button" (click)="triggerCommand('justifyRight')" [disabled]="htmlMode"
      [hidden]="isButtonHidden('justifyRight')" tabindex="-1">
      <i class='fa fa-align-right'></i></button>
    <button [id]="'justifyFull-'+id" type="button" [matTooltip]="'JUSTIFY_FULL' | translate" class="richtext-editor-button"  
      (click)="triggerCommand('justifyFull')" [disabled]="htmlMode" [hidden]="isButtonHidden('justifyFull')"
      tabindex="-1"><i class='fa fa-align-justify'></i></button>
  </div>

  <div class="richtext-editor-toolbar-set">
    <button [id]="'indent-'+id" type="button" [matTooltip]="'INDENT' | translate" class="richtext-editor-button"  
      (click)="triggerCommand('indent')" [disabled]="htmlMode" [hidden]="isButtonHidden('indent')" tabindex="-1"><i
        class='fa fa-indent'></i></button>
    <button [id]="'outdent-'+id" type="button" [matTooltip]="'OUTDENT' | translate" class="richtext-editor-button"  
      (click)="triggerCommand('outdent')" [disabled]="htmlMode" [hidden]="isButtonHidden('outdent')" tabindex="-1"><i
        class='fa fa-outdent'></i></button>
  </div>

  <div class="richtext-editor-toolbar-set">
    <button [id]="'insertOrderedList-'+id" type="button" [matTooltip]="'ORDERED_LIST' | translate"
      class="richtext-editor-button" (click)="triggerCommand('insertOrderedList')" [disabled]="htmlMode"
      [hidden]="isButtonHidden('insertOrderedList')" tabindex="-1"><i class='fa fa-list-ol'></i></button>
    <button [id]="'insertUnorderedList-'+id" type="button" [matTooltip]="'UNORDERED_LIST' | translate"
      class="richtext-editor-button" (click)="triggerCommand('insertUnorderedList')" [disabled]="htmlMode"
      [hidden]="isButtonHidden('insertUnorderedList')" tabindex="-1"><i class='fa fa-list-ul'></i></button>
  </div>

  <div *ngIf="_customClasses" class="richtext-editor-toolbar-set">
    <ae-select class="select-custom-style" [options]="customClassList" [(ngModel)]="customClassId"
      (change)="setCustomClass(customClassId)" [disabled]="htmlMode" [hidden]="isButtonHidden('customClasses')"
      tabindex="-1"></ae-select>
  </div>

  <div class="richtext-editor-toolbar-set">
    <button [id]="'link-'+id" type="button" class="richtext-editor-button" (click)="insertUrl()"
      [matTooltip]="'INSERT_LINK' | translate" [disabled]="isLinkButtonDisabled" [hidden]="isButtonHidden('link')"
      tabindex="-1">
      <i class="fa fa-link" aria-hidden="true"></i>
    </button>
    <button [id]="'unlink-'+id" type="button" class="richtext-editor-button" (click)="triggerCommand('unlink')"
      [matTooltip]="'UNLINK' | translate" [disabled]="htmlMode || !linkSelected" [hidden]="isButtonHidden('unlink')"
      tabindex="-1">
      <i class="fa fa-chain-broken" aria-hidden="true"></i>
    </button>
    <input style="display: none" accept="image/*" type="file" (change)="onFileChanged($event)" #fileInput>
    <button [id]="'insertImage-'+id" type="button" class="richtext-editor-button" (click)="fileInput.click()"
      [matTooltip]="'INSERT_IMAGE' | translate" [disabled]="htmlMode" [hidden]="isButtonHidden('insertImage')"
      tabindex="-1"><i class="fa fa-image" aria-hidden="true"></i>
    </button>
    <button [id]="'insertVideo-'+id" type="button" class="richtext-editor-button" (click)="insertVideo()"
      [matTooltip]="'INSERT_VIDEO' | translate" [disabled]="htmlMode" [hidden]="isButtonHidden('insertVideo')"
      tabindex="-1"><i class="fa fa-video-camera" aria-hidden="true"></i></button>
    <button [id]="'insertHorizontalRule-'+id" type="button" [matTooltip]="'HORIZONTAL_LINE' | translate"
      class="richtext-editor-button" (click)="triggerCommand('insertHorizontalRule')" [disabled]="htmlMode"
      [hidden]="isButtonHidden('insertHorizontalRule')" tabindex="-1"><i class="fa fa-minus" aria-hidden="true"></i></button>
  </div>
  <div class="richtext-editor-toolbar-set">
    <button [id]="'clearFormatting-'+id" type="button" [matTooltip]="'CLEAR_FORMATING' | translate"
      class="richtext-editor-button" (click)="triggerCommand('removeFormat')" [disabled]="htmlMode"
      [hidden]="isButtonHidden('removeFormat')" tabindex="-1"><i class='fa fa-eraser'></i>
    </button>
  </div>
  <div class="richtext-editor-toolbar-set">
    <button [id]="'toggleEditorMode-'+id" type="button" [matTooltip]="'TOGGLE_MODE' | translate"
      class="richtext-editor-button" (click)="triggerCommand('toggleEditorMode')"
      [hidden]="isButtonHidden('toggleEditorMode')" tabindex="-1"><i class='fa fa-code'></i></button>
  </div>
</div>
