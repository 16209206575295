import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { Config } from '../Config';
import { AlertType, AlertWithBoldValueInMiddle } from '../models/Alert';
import { Cuboid } from '../models/Cuboid';
import { Hologram } from '../models/Hologram';
import { Image } from '../models/Image';
import { Place } from '../models/Place';
import { Tracker } from '../models/Tracker';
import { AlertService } from './alert.service';
import { StringService } from './string.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root',
})
export class TrackerService {

  private baseUrl = new Config().GetBaseUrl();

  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService
  ) { }

  public getTrackers(): Observable<Tracker[]> {
    return this.http.get<any>(this.getTrackerUrl()).pipe(
      map(
        response => {
          const trackers: Tracker[] = [];
          for(const value of response) {
            trackers.push(this.transformResponse(value));
          }
          return trackers;
        },
        error => { this.handleError<Tracker[]>('getTrackers', error); }
      ),
      share()
    );
  }

  public getTrackerById(id: string): Observable<Tracker> {
    return this.http.get<any>(this.getTrackerUrl(`${id}`)).pipe(
      map(
        response => {
          return this.transformResponse(response);
        },
        error => {
          this.handleError<Tracker>('getTrackerById', error);
          this.alertService.alert(
            new AlertWithBoldValueInMiddle(
              this.stringService.get('TRACKER_NOT_FOUND1'),
              id,
              this.stringService.get('TRACKER_NOT_FOUND2'),
              AlertType.Error),
            true);
        }
      ),
      share()
    );
  }

  private transformResponse(response: any): Tracker {
    if (response.model === 'images') {
      return new Image(response.data);
    } else if (response.model === 'cuboids') {
      return new Cuboid(response.data);
    } else if (response.model === 'places') {
      return new Place(response.data);
    } else if (response.model === 'holograms') {
      return new Hologram(response.data);
    } else {
    }
  }

  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private logError(value: any): void {
    console.error(value);
  }

  private getTrackerUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}trackers/`;
    if (extension) {
      if (!extension.endsWith('/')) {
        extension = `${extension}/`;
      }
      url = `${url}${extension}`;
    }
    return url;
  }
}
