
import { timer as observableTimer, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { tap, share } from 'rxjs/operators';

import { Config } from '../Config';

import { AlertService } from './alert.service';
import { User } from '../models/User';
import { CurrentContextService } from './currentContext.service';
import { AppState } from '../current.app.state';
import { Status } from '../models/Status';

/**
 * constant basic http Options that will be sent with every request
 */
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'response' as 'body'
};

/**
 * The  AuthService handles all authentication request to the backend
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * URL to web api
  */
  private baseUrl = new Config().GetBaseUrl();

  /**
   * The current logged in user
  */
  private user: User;

  /**
   * Intervall to refresh the token
   * currently set to 5 min
  */
  private refreshIntervall = 0.5 * 1000 * 60;

  /**
   * The key to use for local storage
  */
  private sessionStorageKey = 'ar-cms.session';

  /**
   * Constructor that creates an instance of the AuthService it needs an instance of:
   * it also sets up an Observable to refresh the token in the given refresh Intervall
   * and loads the current User
   * @param http an HttpClient to send Request and reseive responses
   * @param alertService an instance of the AlertService to display new alerts
   * @param contextService the CurrentContextService to store temporary data
   */
  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private contextService: CurrentContextService
  ) {
    const timer = observableTimer(this.refreshIntervall, this.refreshIntervall);
    timer.subscribe(_ => {
      if (this.user && this.user.loggedIn) {
        this.refresh(this.user).subscribe(() => { });
      }
    });
    this.user = this.getStoredUser();
  }

  /**
   * Will be used if the user changes
   * writes the user to local storage in JSON format
   * @param user the user to be written in the local storage
   */
  private setStorageUser(user: User): void {
    if (typeof (Storage) !== 'undefined') {
      sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(user));
    }
  }

  /**
   * Will be used when logging out or the user element gets null
   * removes the current stored user from the local storage
   */
  private deleteStoredUser(): void {
    if (typeof (Storage) !== 'undefined') {
      sessionStorage.removeItem(this.sessionStorageKey);
    }
  }

  /**
   * Is used when the user object changes
   * is used to check if the user element exists and then decides to clear the user
   * object or store the new user object
   */
  protected UpdateStoredUser(): void {
    if (this.user) {
      this.setStorageUser(this.user);
    } else {
      this.deleteStoredUser();
    }
  }

  /**
   * Will used when the user object should be read from the local storage
   * parses the user object from the local storage to User object and returns it,
   * if it exists
   */
  private getStoredUser(): User {
    if (typeof (Storage) !== 'undefined') {
      const user = sessionStorage.getItem(this.sessionStorageKey);
      if (user) {
        const data = JSON.parse(user);
        if (data) {
          return new User(data);
        }
      }
    }
    return null;
  }

  /**
   * Sets the given user
   * @param user the user object to be set
   */
  protected setUser(user: User): void {
    this.user = user;
    this.UpdateStoredUser();
  }

  /**
   * This method delivers the current logged in user to the rest of the application
   * @returns the current logged in user
   */
  public getUser(): User {
    return this.user;
  }

  /**
   * Returns the current Used Token to the other Components in the Application
   * @returns returns the current Token if exists
   */
  public getLoginToken(): string {
    if (this.user && this.user.loggedIn) {
      return this.user.token;
    }
    return undefined;
  }

  /**
   * Returns the token with the correct suffix to send authorized requests to the backend
   * @returns the correct suffix with the current token
  */
  public getUrlTokenSuffix(): string {
    const token = this.getLoginToken();
    if (token) {
      return '?access_token=' + token;
    }
    return '?';
  }

  /**
   * Receives an url and adds the correct suffix to authenticate the request
   * @param url the url that should be extended with the token
   * @returns the new url with the suffix
   */
  public addTokenToUrl(url: string): string {
    const token = this.getLoginToken();
    if (token && url.indexOf('?') > -1) {
      return url;// + '&access_token=' + token;
    }
    return url;// + this.getUrlTokenSuffix();
  }

  /**
   * Tries to login the user with the given credentials, if correct it routes to the exhibitions,
   * if wrong it display it to the user
   * @param username the username the is used to login
   * @param password the password that the user entered to login
   */
  public login(username: string, password: string): Observable<User> {
    return this.http.post<User>(this.getAuthUrl('login'), {
      user: username,
      password: password
    }, httpOptions).pipe(
      tap(
        response => {
          const usr = new User((response as any).body);
          usr.refresh(usr.token); // sets the last refresh time
          usr.loggedIn = true;
          this.setUser(usr);
        },
        error => {
          if (this.contextService && this.contextService.getCurrentState() === AppState.editUser) {
            return;
          }
          this.handleError<User>('login', error);
        }
      ),
      share()
    );
  }

  /**
   * ends the session and removes the current logged in user
   */
  public logout(): void {
    this.user = null;
    this.deleteStoredUser();
  }

  /**
   * triggers every 5 minutes (given by the refreshIntervall variable)
   * renew the user token if possible
   * @param user the current User to be refreshed
   * @returns an Observable that contains the new User Object if the request was successful
   */
  public refresh(user: User): Observable<User> {
    const optionsWithToken = httpOptions;
    return this.http.get<any>(this.getAuthUrl('refresh'), optionsWithToken).pipe(
      tap(
        response => {
          const token = response.body.token;
          user.refresh(token); // sets the last refresh time
          this.user.refresh(token);
          this.UpdateStoredUser();
        },
        error => { this.handleError<User>('refresh', error); }
      ),
      share()
    );
  }

  /**
   * GET: status endpoint
   * @returns an Observable that contains the Status Object if the request was successful
   */
  public status(): Observable<Status> {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    return this.http.get<Status>(url + 'status').pipe(
      tap(
        response => { },
        error => {
          this.handleError<User>('status', error);
        }
      ),
      share()
    );
  }

  /**
	* Handle Http operation that failed.
	* Let the app continue.
	* @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    * @returns an errorObject or an Object of the given resultType
	*/
  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  /**
   * will trigger with every http error the occures (400-599)
   * will print the current error to the console
   * @param value any value that should be printed
   */
  private logError(value: any): void {
    console.error(value);
  }

  /**
   * will be used when a request should be sent to the backend auth endpoint
   * returns the baseUrl with the correct extension
   * @param extension optional extensions can be set after the auth path
   * @returns the created full authUrl
   */
  private getAuthUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}auth/-/`;
    if (extension) {
      if (!extension.endsWith('/')) {
        extension = `${extension}/`;
      }
      url = `${url}${extension}`;
    }
    return url;
  }
}

