import { Pipe, PipeTransform } from '@angular/core';
import { Language } from './models/Language';
import { StringService } from './services/string.service';

/**
 * The TranslateWithPipe is used to get the Strings from the StringService in the language that is passed,
 * as argument to the pipe.
 */
@Pipe({ name: 'translateWith' })
export class TranslateWithPipe implements PipeTransform {

  /**
   * creates an instance of the TranslatePipe
   * @param stringService used to find the String that should be displayed to the user
   */
  public constructor(private stringService: StringService) { }

  /**
   * will be called when the pipe is used in html
   * will take the given stringId and a language identifier, and search the StringService for it
   * @param stringId the Id of the String in the StringService
   * @param language Language to get the translation of the string with the given id in.
   * @returns the String if found, else it will also return an string when the id does not exist or the string could not be found
   */
  transform(stringId: string, language: Language): string {
    if (!stringId) { return 'STRING NO ID'; }
    const string = this.stringService.getWith(stringId, language);
    if (string && string !== '') {
      return string;
    } else {
      return 'STRING NOT FOUND';
    }
  }
}
