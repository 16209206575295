import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Config } from '../Config';
import { AppState } from '../current.app.state';
import { FileDataType } from '../models/FileDataType';
import { Media } from '../models/Media';
import { PoiTexture } from '../models/PoiTexture';
import { AuthService } from '../services/auth.service';
import { CurrentContextService } from '../services/currentContext.service';
import { MediaService } from '../services/media.service';
import { PoiTextureService } from '../services/poi-texture.service';
import { StringService } from '../services/string.service';
import { Tools } from '../Tools';
import { saveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PoiStyleUploadModal } from '../modals/poi-style-upload/poi-style-upload.modal.component';
import { MediaData, MediaLoader } from '../../helper-classes/MediaLoader';

@Component({
	selector: 'aacms-poi-textures-manager',
	templateUrl: './poi-textures-manager.component.html',
	styleUrls: ['./poi-textures-manager.component.scss']
})
export class PoiTexturesManagerComponent implements OnInit {

	public config = new Config();
	public textures: PoiTexture[] = [];
	public Tools = Tools;
	public modalRef: BsModalRef;
	public inEditMode: PoiTexture = null;

	public mediaLoader: MediaLoader;

	constructor(
		private contextService: CurrentContextService,
		private authService: AuthService,
		private textureService: PoiTextureService,
		private mediaService: MediaService,
		private sanitizer: DomSanitizer,
		private stringService: StringService,
		private modalService: BsModalService
	) {
		this.mediaLoader = new MediaLoader(this.mediaService, this.sanitizer);
		this.initializeDefaultTextures();
	}

	private initializeDefaultTextures() {
		let createDefaultPoiTexture = (id: string, langConstant: string, fileName: string) => {
			const defaultTexture = new PoiTexture();
			defaultTexture.id = id;
			defaultTexture.mediaId = id;
			defaultTexture.name = this.stringService.get(langConstant);
			this.textures.push(defaultTexture);
			// construct media
			const media = new Media();
			media.id = id;
			media.name = defaultTexture.name;
			media.thumbUrl = `../../assets/default-poi-textures/${fileName}.png`;
			media.rawUrl = media.thumbUrl;
			media.type = 'image/png';
			this.mediaLoader.insertMedia(media);
		};
		createDefaultPoiTexture('00000000-0000-0000-0000-000000000001', 'DEFAULT_TEXTURE_1', 'Default1');
		createDefaultPoiTexture('00000000-0000-0000-0000-000000000002', 'DEFAULT_TEXTURE_2', 'Default2');
		createDefaultPoiTexture('00000000-0000-0000-0000-000000000003', 'DEFAULT_TEXTURE_3', 'transparentCircle');
	}

	public isTextureLoaded(texture: PoiTexture) {
		return this.mediaLoader.isLoaded(texture.mediaId) && this.mediaLoader.get(texture.mediaId).isDownloadRunning;
	}

	public ngOnInit(): void {
		this.contextService.setCurrentState(AppState.editPoiTextures);
		this.loadTextures();
	}

	public loadTextures(): void {
		const sub = this.textureService.getTextures().subscribe(
			textures => {
				for (const texture of textures) {
					this.textures = [...this.textures, new PoiTexture(texture)];
				}
				sub.unsubscribe();
			}
		);
	}

	public createPoiStyles(): void {
		this.modalRef = this.modalService.show(PoiStyleUploadModal,
			{ class: 'modal-lg', keyboard: false, ignoreBackdropClick: true });
		const subscription = this.modalService.onHide.subscribe(() => {
			this.textures = [];
			this.loadTextures();
			subscription.unsubscribe();
		});
	}

	public getMaxContentFileSize(): number {
		return this.config.getPoiStyleFileSize();
	}

	public deletePoiTexture(textureToDelete: PoiTexture): void {
		const sub = this.textureService.deleteTexture(textureToDelete).subscribe(
			response => {
				this.textures = this.textures.filter(texture => texture.id !== textureToDelete.id);
				sub.unsubscribe();
			}
		);
	}

	public downloadTexture(texture: PoiTexture): void {
		let downloadRaw = async (mediaId: string) => {
			let mediaData = await this.mediaLoader.getAsync(mediaId);
			await mediaData.guaranteeRawAvailable();
			return mediaData.getRawFile();
		};
		downloadRaw(texture.mediaId)
			.then((file: File) => {
				saveAs(file, file.name);
				this.contextService.setSavingContent(false);
			}).catch(() => this.contextService.setSavingContent(false));
	}

	public editPoiTexture(texture: PoiTexture): void {
		if (!this.inEditMode) {
			this.inEditMode = new PoiTexture(texture);
		}
	}

	public saveTexture(texture: PoiTexture): void {
		let sub = this.textureService.updateTexture(texture).subscribe(
			response => {
				this.inEditMode = null;
				sub.unsubscribe();
			}
		);
	}

	public discardChanges(texture: PoiTexture): void {
		texture.name = this.inEditMode.name;
		this.inEditMode = null;
	}
}
