import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ImagesComponent } from './images/images.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { ExhibitionsComponent } from './exhibitions/exhibitions.component';
import { AdminComponent } from './admin-page/admin.component';

import { AuthGuard } from './guards/auth-guard';
import { AdminGuard } from './guards/admin-guard';
import { EditUserComponent } from './edit-user/edit-user.component';
import { PushNotificationComponent } from './push-notification/push-notification.component';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { EditImageComponent } from './edit-image/edit-image.component';
import { EditPoiComponent } from './edit-poi/edit-poi.component';
import { EditImageMetaComponent } from './edit-image-meta/edit-image-meta.component';
import { FeatureGuard } from './guards/feature-guard';
import { PendingChangesGuard } from './guards/pending-changes-guard';
import { EditCuboidComponent } from './edit-cuboid/edit-cuboid.component';
import { EditArLayerComponent } from './edit-ar-layer/edit-ar-layer.component';
import { EditArLayerMetaComponent } from './edit-ar-layer-meta/edit-ar-layer-meta.component';
import { EditArLayerPoiComponent } from './edit-ar-layer-poi/edit-ar-layer-poi.component';
import { PoiTexturesManagerComponent } from './poi-textures-manager/poi-textures-manager.component';
import { EditCuboidMetaComponent } from './edit-cuboid-meta/edit-cuboid-meta.component';
import { ToursComponent } from './tours/tours.component';
import { EditTourComponent } from './edit-tour/edit-tour.component';
import { EditTourMetaComponent } from './edit-tour-meta/edit-tour-meta.component';
import { MaintainanceComponent } from './maintainance/maintainance.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { Config } from './Config';
import { ResourceGuard } from './guards/resource-guard';
import { EditPlaceComponent } from './edit-place/edit-place.component';
import { EditPlaceMetaComponent } from './edit-place-meta/edit-place-meta.component';
import { EditInfoCardMetaComponent } from './edit-info-card/edit-info-card.component';
import { EditHologramComponent } from './edit-hologram/edit-hologram.component';
import { EditHologramMetaComponent } from './edit-hologram-meta/edit-hologram-meta.component';
import { EditHologramCardMetaComponent } from './edit-hologram-card/edit-hologram-card.component';

const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'images/:imageId',
    component: EditImageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'holograms/:hologramId',
    component: EditHologramComponent,
    canActivate: [AuthGuard, FeatureGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'holograms/:hologramId/meta',
    component: EditHologramMetaComponent,
    canActivate: [AuthGuard, FeatureGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'holograms/:hologramId/hologramCards/:hologramCardId',
    component: EditHologramCardMetaComponent,
    canActivate: [AuthGuard, FeatureGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'cuboids/:cuboidId',
    component: EditCuboidComponent,
    canActivate: [AuthGuard, FeatureGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'cuboids/:cuboidId/meta',
    component: EditCuboidMetaComponent,
    canActivate: [AuthGuard, FeatureGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'cuboids/:cuboidId/arlayers/:arLayerId',
    component: EditArLayerComponent,
    canActivate: [AuthGuard, FeatureGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'cuboids/:cuboidId/arlayers/:arLayerId/pois/:poiId',
    component: EditArLayerPoiComponent,
    canActivate: [AuthGuard, FeatureGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'cuboids/:cuboidId/arlayers/:arLayerId/meta',
    component: EditArLayerMetaComponent,
    canActivate: [AuthGuard, FeatureGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'images/:imageId/pois/:poiId',
    component: EditPoiComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'images/:imageId/meta',
    component: EditImageMetaComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'places/:placeId',
    component: EditPlaceComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'places/:placeId/meta',
    component: EditPlaceMetaComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'places/:placeId/infoCards/:infoCardId',
    component: EditInfoCardMetaComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'exhibitions/:exhibitionId/images',
    component: ImagesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'exhibitions',
    component: ExhibitionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'poi-textures',
    component: PoiTexturesManagerComponent,
    canActivate: [AuthGuard, FeatureGuard]
  },
  {
    path: 'tours',
    component: ToursComponent,
    canActivate: [AuthGuard, FeatureGuard]
  },
  {
    path: 'tours/:tourId',
    component: EditTourComponent,
    canActivate: [AuthGuard, FeatureGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'tours/:tourId/meta',
    component: EditTourMetaComponent,
    canActivate: [AuthGuard, FeatureGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard, AdminGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'user',
    component: EditUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'push-notifications',
    component: PushNotificationComponent,
    canActivate: [AuthGuard, FeatureGuard]
  },
  {
    path: 'app-menu',
    component: AppMenuComponent,
    canActivate: [AuthGuard, FeatureGuard, ResourceGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent
  },
  {
    path: '',
    component: ExhibitionsComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

const alternativeRoutes: Routes = [
  {
    path: '**',
    component: MaintainanceComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      new Config().isMaintainance() ? alternativeRoutes : appRoutes,
      { enableTracing: false, onSameUrlNavigation: 'reload' }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
