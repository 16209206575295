import { Component, HostListener, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { ArLayer } from '../models/ArLayer';
import { FileDataType } from '../models/FileDataType';
import { Language } from '../models/Language';
import { ArLayerService } from '../services/arLayer.service';
import { CurrentContextService } from '../services/currentContext.service';
import { PoiManagerService } from '../services/poi-manager.service';
import { Poi } from '../models/Poi';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { AppState } from '../current.app.state';
import { MediaService } from '../services/media.service';
import { Media } from '../models/Media';
import { Tools } from '../Tools';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { tap } from 'rxjs/operators';
import { Config } from '../Config';
import { CubeSides } from '../models/CubeSides';
import { Cuboid } from '../models/Cuboid';
import { CuboidService } from '../services/cuboid.service';
import { ContentTypes } from '../models/ContentTypes';


@Component({
  selector: 'aacms-edit-ar-layer',
  templateUrl: './edit-ar-layer.component.html',
  styleUrls: ['./edit-ar-layer.component.scss']
})
export class EditArLayerComponent implements OnInit, OnDestroy {
  public arLayer: ArLayer;
  public originalArLayer: ArLayer;

  public cuboid: Cuboid;

  public arLayerChanged = false;

  public modalRef: BsModalRef;

  public currentSelectedImage: CubeSides = CubeSides.front;

  public cuboidImageDataMap: Map<CubeSides, FileDataType> = new Map([
    [CubeSides.top, null],
    [CubeSides.bottom, null],
    [CubeSides.front, null],
    [CubeSides.back, null],
    [CubeSides.left, null],
    [CubeSides.right, null]
  ]);
  public arLayerImageDataMap: Map<CubeSides, FileDataType> = new Map([
    [CubeSides.top, null],
    [CubeSides.bottom, null],
    [CubeSides.front, null],
    [CubeSides.back, null],
    [CubeSides.left, null],
    [CubeSides.right, null]
  ]);

  public imageDataLoaded = false;

  public cubeSides = CubeSides;
  public contentTypes = ContentTypes;

  public arLayerOpacity = 100;
  public enableTrackerLayer = true;

  public config: Config = new Config();
  public appState = AppState;

  private enabledContentTypes: ContentTypes[];

  public currentLanguage: Language = null;
  private navigationSubscription: Subscription;
  private saveContentSubject: Subscription;

  public Tools = Tools;

  constructor(
    public pms: PoiManagerService,
    private router: Router,
    private modalService: BsModalService,
    private arLayerService: ArLayerService,
    public contextService: CurrentContextService,
    private mediaService: MediaService,
    private sanitizer: DomSanitizer,
    private cuboidService: CuboidService
  ) {
    this.enabledContentTypes = this.config.getEnabledContentTypes();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof ActivationEnd) {
        this.arLayer = null;
        this.cuboid = null;
        this.currentLanguage = Language.DE;
        const arlayerId = e.snapshot.paramMap.get('arLayerId');
        const cuboidId = e.snapshot.paramMap.get('cuboidId');
        if (arlayerId && cuboidId) {
          this.contextService.setCurrentState(AppState.editARLayer);
          this.getARLayer(arlayerId);
          this.getCuboid(cuboidId);
        }
      }
    });
  }

  ngOnInit(): void {
    this.saveContentSubject = this.contextService.saveYourContent.subscribe(
      saveContent => {
        if (saveContent) {
          this.onSave();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
    this.saveContentSubject.unsubscribe();
    this.pms.selectedPoi = null;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (this.arLayerChanged || this.pms.poisChanged) ? false : true;
  }

  private getARLayer(arLayerId: string) {
    this.imageDataLoaded = false;
    const sub = this.arLayerService.getArLayerById(arLayerId).subscribe(
      arLayer => {
        this.arLayer = new ArLayer(arLayer);
        this.originalArLayer = new ArLayer(arLayer);
        const requests = [];
        if (this.arLayer.mediaIds.top && this.arLayer.mediaIds.top.mediaId) {
          requests.push(this.loadARLayerImage(this.arLayer.mediaIds.top.mediaId, CubeSides.top));
        }
        if (this.arLayer.mediaIds.bottom && this.arLayer.mediaIds.bottom.mediaId) {
          requests.push(this.loadARLayerImage(this.arLayer.mediaIds.bottom.mediaId, CubeSides.bottom));
        }
        if (this.arLayer.mediaIds.left && this.arLayer.mediaIds.left.mediaId) {
          requests.push(this.loadARLayerImage(this.arLayer.mediaIds.left.mediaId, CubeSides.left));
        }
        if (this.arLayer.mediaIds.right && this.arLayer.mediaIds.right.mediaId) {
          requests.push(this.loadARLayerImage(this.arLayer.mediaIds.right.mediaId, CubeSides.right));
        }
        if (this.arLayer.mediaIds.front && this.arLayer.mediaIds.front.mediaId) {
          requests.push(this.loadARLayerImage(this.arLayer.mediaIds.front.mediaId, CubeSides.front));
        }
        if (this.arLayer.mediaIds.back && this.arLayer.mediaIds.back.mediaId) {
          requests.push(this.loadARLayerImage(this.arLayer.mediaIds.back.mediaId, CubeSides.back));
        }
        const sub1 = forkJoin(requests).subscribe(
          () => {
            this.imageDataLoaded = true;
            sub1.unsubscribe();
          }
        );
        sub.unsubscribe();
      }
    );
  }

  private loadARLayerImage(mediaId: string, mapId: CubeSides): Observable<any> {
    const fileDataType: FileDataType = new FileDataType();
    return this.mediaService.getMediaById(mediaId).pipe(
      tap(
        media => {
          fileDataType.mediaId = mediaId;
          fileDataType.media = new Media(media);
          const sub = this.mediaService.downloadFile(media.rawUrl).subscribe(
            blob => {
              const file = Tools.blobToFile(blob, 'image.jpg');
              fileDataType.file = file;
              fileDataType.fileSafeUrl = this.createImageUrl(file);
              this.arLayerImageDataMap.set(mapId, fileDataType);
              sub.unsubscribe();
            }
          );
        })
    );
  }

  private getCuboid(cuboidId: string) {
    this.imageDataLoaded = false;
    const sub = this.cuboidService.getCuboidById(cuboidId).subscribe(
      cuboid => {
        this.cuboid = new Cuboid(cuboid);
        this.contextService.getExhibition(this.cuboid.exhibitionIds[0]);
        const requests = [];
        if (this.cuboid.trackerIds.top && this.cuboid.trackerIds.top.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.top.mediaId, CubeSides.top));
        }
        if (this.cuboid.trackerIds.bottom && this.cuboid.trackerIds.bottom.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.bottom.mediaId, CubeSides.bottom));
        }
        if (this.cuboid.trackerIds.left && this.cuboid.trackerIds.left.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.left.mediaId, CubeSides.left));
        }
        if (this.cuboid.trackerIds.right && this.cuboid.trackerIds.right.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.right.mediaId, CubeSides.right));
        }
        if (this.cuboid.trackerIds.front && this.cuboid.trackerIds.front.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.front.mediaId, CubeSides.front));
        }
        if (this.cuboid.trackerIds.back && this.cuboid.trackerIds.back.mediaId) {
          requests.push(this.loadCuboidImage(this.cuboid.trackerIds.back.mediaId, CubeSides.back));
        }
        const sub1 = forkJoin(requests).subscribe(
          () => {
            this.imageDataLoaded = true;
            sub1.unsubscribe();
          }
        );
        sub.unsubscribe();
      }
    );
  }

  private loadCuboidImage(mediaId: string, mapId: CubeSides): Observable<any> {
    const fileDataType: FileDataType = new FileDataType();
    return this.mediaService.getMediaById(mediaId).pipe(
      tap(
        media => {
          fileDataType.mediaId = mediaId;
          fileDataType.media = new Media(media);
          const sub = this.mediaService.downloadFile(media.rawUrl).subscribe(
            blob => {
              const file = Tools.blobToFile(blob, 'image.jpg');
              fileDataType.file = file;
              fileDataType.fileSafeUrl = this.createImageUrl(file);
              this.cuboidImageDataMap.set(mapId, fileDataType);
              sub.unsubscribe();
            }
          );
        })
    );
  }

  private createImageUrl(file: File): SafeUrl {
    const urlCreator = window.URL;
    const url = urlCreator.createObjectURL(file);
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    return safeUrl;
  }

  public selectPoi(poi: Poi): void {
    if (!poi) {
      this.pms.selectedPoi = null;
    } else {
      this.pms.selectedPoi = poi;
      this.currentSelectedImage = poi.imageSide;
    }
  }

  public checkForArLayerChange(): void {
    if (!this.arLayer.equal(this.originalArLayer)) {
      this.arLayerChanged = true;
      return;
    }
    this.arLayerChanged = false;
  }

  public getPoiListEntryStyle(poi: Poi): Object {
    let borderStyle = '';
    if (poi.active) {
      borderStyle = (this.pms.selectedPoi !== null && this.pms.selectedPoi.id === poi.id) ? '2px solid #87ceeb' : '2px solid gainsboro';
    } else {
      borderStyle = (this.pms.selectedPoi !== null && this.pms.selectedPoi.id === poi.id) ? '2px solid rgba(255,0,0,0.3)' : '2px solid rgba(220,220,220,0.3)';
    }
    const style = {
      'border': borderStyle
    };
    return style;
  }

  public getPoiListTitleStyle(poi: Poi): Object {
    const style = {
      'opacity': poi.active ? '1' : '0.3'
    };
    return style;
  }


  public removeArLayerDescription() {
    this.arLayer.title = new MultiLanguageText();
    this.arLayer.uiElements = [];
    this.checkForArLayerChange();
  }

  public routeToEditPoi(poi: Poi): void {
    this.pms.selectedPoi = poi;
    this.router.navigate(['cuboids/' + this.arLayer.cuboidId + '/arlayers/' + this.arLayer.id + '/pois/' + poi.id]);
  }

  public routeToEditArLayerMeta() {
    this.pms.selectedPoi = null;
    this.router.navigate(['cuboids/' + this.arLayer.cuboidId + '/arlayers/' + this.arLayer.id + '/meta/']);
  }

  public getLanguageValue(text: MultiLanguageText): string {
    return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
  }

  public updateLanguage(language: Language): void {
    this.currentLanguage = language;
    setTimeout(() => {
      this.pms.reloadPoiLayer();
    });
  }

  public toggleActive(poi: Poi, event: MouseEvent): void {
    event.stopPropagation();
    poi.active = !poi.active;
    this.pms.checkForPoisChanged();
  }

  public movePoiUp(): void {
    if (this.pms.selectedPoi && this.pms.selectedPoi.position > 0) {
      const temp = this.pms.pois[this.pms.selectedPoi.position - 1];
      this.pms.pois[this.pms.selectedPoi.position - 1] = this.pms.pois[this.pms.selectedPoi.position];
      this.pms.pois[this.pms.selectedPoi.position] = temp;
      this.pms.updatePoiPositions();
    }
  }

  public movePoiDown(): void {
    if (this.pms.selectedPoi && this.pms.selectedPoi.position < this.pms.pois.length - 1) {
      const temp = this.pms.pois[this.pms.selectedPoi.position + 1];
      this.pms.pois[this.pms.selectedPoi.position + 1] = this.pms.pois[this.pms.selectedPoi.position];
      this.pms.pois[this.pms.selectedPoi.position] = temp;
      this.pms.updatePoiPositions();
    }
  }

  public deletePoi(poiToDelete: Poi, event: MouseEvent) {
    event.stopPropagation();
    this.pms.pois = this.pms.pois.filter(poi => !poiToDelete.equal(poi));
    if (this.pms.selectedPoi && poiToDelete.equal(this.pms.selectedPoi)) {
      this.pms.selectedPoi = null;
    }
    this.pms.updatePoiPositions();
    this.pms.reloadPoiLayer();
    this.pms.checkForPoisChanged();
  }

  public cancel(): void {
    if (this.modalRef) { this.modalRef.hide(); }
    this.router.navigate(['cuboids', this.arLayer.cuboidId]);
  }

  public openModal(modalTemplate: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(modalTemplate);
  }


  public onDiscard(): void {
    if (this.arLayerChanged) {
      this.arLayer = new ArLayer(this.originalArLayer);
      this.checkForArLayerChange();
    }
    if (this.pms.poisChanged) {
      this.pms.resetCurrentPois();
      this.pms.reloadPoiLayer();
    }
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  public onSave(): void {
    this.contextService.setSaveYourContent(false);
    let savingPois = this.pms.poisChanged ? true : false;
    let saveContent = this.arLayerChanged ? true : false;
    if (savingPois || saveContent) {
      this.contextService.setSavingContent(true);
    }
    const sub = this.arLayerService.updateArLayer(this.arLayer).subscribe(
      () => {
        saveContent = false;
        this.originalArLayer = new ArLayer(this.arLayer);
        if (!savingPois && !saveContent) {
          this.contextService.setSavingContent(false);
          this.checkForArLayerChange();
        }
        sub.unsubscribe();
      }
    );
    if (this.pms.poisChanged) {
      const savePoiSubscription = this.pms.saveChangedPois();
      savePoiSubscription.add(() => {
        savingPois = false;
        if (!savingPois && !saveContent) {
          this.contextService.setSavingContent(false);
        }
        savePoiSubscription.unsubscribe();
      });
    }
  }

  public delete(): void {
    this.modalRef.hide();
    this.arLayerChanged = false;
    this.pms.poisChanged = false;
    const sub = this.arLayerService.deleteArLayer(this.arLayer).subscribe(
      () => {
        this.cancel();
        sub.unsubscribe();
      }
    );
  }

  public toggleType(poi: Poi, event: MouseEvent): void {
    event.stopPropagation();

    const index = this.enabledContentTypes.indexOf(poi.contentType);
    poi.contentType = this.enabledContentTypes[(index + 1) % this.enabledContentTypes.length];

    this.pms.checkForPoisChanged();
  }

  public toggleTypeArLayer(arLayer: ArLayer, event: MouseEvent): void {
    event.stopPropagation();

    const index = this.enabledContentTypes.indexOf(arLayer.contentType);
    arLayer.contentType = this.enabledContentTypes[(index + 1) % this.enabledContentTypes.length];

    this.checkForArLayerChange();
  }

  public routeTo(appState: AppState): void {
    switch (appState) {
      case AppState.exhibitions:
        this.router.navigate(['exhibitions']);
        break;
      case AppState.images:
        this.router.navigate(['exhibitions', this.cuboid.exhibitionIds[0], 'images']);
        break;
      case AppState.editCuboid:
        this.router.navigate(['cuboids', this.cuboid.id]);
        break;
      default:
        break;
    }
  }
}
