import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AppState } from '../current.app.state';
import { FileDataType } from '../models/FileDataType';
import { Language } from '../models/Language';
import { Media } from '../models/Media';
import { MultiLanguageText } from '../models/MultiLanguageText';
import {  ElementType } from '../models/UiElements';
import { CurrentContextService } from '../services/currentContext.service';
import { MediaService } from '../services/media.service';
import { Tools } from '../Tools';
import { Config } from '../Config';
import { StringService } from '../services/string.service';
import { ContentTypes } from '../models/ContentTypes';
import { TabType } from '../models/TabType';
import { HologramCard } from '../models/HologramCard';
import { Hologram, HologramMediaIdValue } from '../models/Hologram';
import { HologramService } from '../services/hologram.service';
import { HologramCardService } from '../services/hologramCard.service';
import { saveAs } from 'file-saver';

@Component({
	selector: 'aacms-edit-hologram-card-meta',
	templateUrl: './edit-hologram-card.component.html',
	styleUrls: ['./edit-hologram-card.component.scss']
})
export class EditHologramCardMetaComponent implements OnInit, OnDestroy {
	public modalRef: BsModalRef;
	public hologramCard: HologramCard;
	private originalHologramCard: HologramCard;
	private hologram: Hologram;

	public hologramCardData: FileDataType = null;

	public elementType = ElementType;
	public contentChanged = false;

	public contentTypes = ContentTypes;

	@ViewChild('smartphone') smartphone;
	@ViewChild('cancelModalTemplate', { static: false }) cancelModalTemplate: TemplateRef<any>;
	@ViewChild('saveModalTemplate', { static: false }) saveModalTemplate: TemplateRef<any>;
	@ViewChild('modelViewer') modelViewer;

	public Language = Language;
	public appState = AppState;
	public Tools = Tools;

	public currentLanguage: Language;

	private saveContentSubject: Subscription;

	public config: Config = new Config();

	public currentTab: TabType;
	public tabType = TabType;

	public constructor(
		public contextService: CurrentContextService,
		private modalService: BsModalService,
		private router: Router,
		private route: ActivatedRoute,
		private stringService: StringService,
		private mediaService: MediaService,
		private hologramService: HologramService,
		private hologramCardService: HologramCardService
	) { }

	public ngOnInit(): void {
		this.contextService.setCurrentState(AppState.editInfoCard);
		this.currentLanguage = Language.DE;
		this.saveContentSubject = this.contextService.saveYourContent.subscribe(
			saveContent => {
				if (saveContent) {
					this.onSave();
				}
			}
		);
		this.getHologramCard();
		this.getHologram();
	}

	public ngOnDestroy(): void {
		this.saveContentSubject.unsubscribe();
		this.returnToHologram();
	}

	@HostListener('window:beforeunload')
	canDeactivate(): Observable<boolean> | boolean {
		return (this.contentChanged) ? false : true;
	}

	private getHologram(): void {
		const id = this.route.snapshot.paramMap.get('hologramId');
		const sub = this.hologramService.getHologramById(id).subscribe(
			hologram => {
				this.hologram = new Hologram(hologram);
				this.contextService.getExhibition(this.hologram.exhibitionIds[0]);
				this.getMedia();
				sub.unsubscribe();
			},
			error => {
				this.router.navigate(['images']);
			}
		);
	}

	private getHologramCard(): void {
		const id = this.route.snapshot.paramMap.get('hologramCardId');
		const sub = this.hologramCardService.getHologramCardById(id).subscribe(
			hologramCard => {
				this.hologramCard = new HologramCard(hologramCard);
				this.originalHologramCard = new HologramCard(hologramCard);
				this.getMedia();
				sub.unsubscribe();
			},
			error => {
				this.router.navigate(['images']);
			}
		);
	}

	private getMedia(): void {
		// wait until both hologramCard and hologram are loaded, for mediaId fallback logic
		if (!this.hologramCard || !this.hologram) { return; }
		let cardMediaId = this.hologramCard.model.getWithFallback(this.currentLanguage)?.mediaId
			|| this.hologram.model.getWithFallback(this.currentLanguage)?.mediaId;
		if(!cardMediaId) { return; }
		if(this.hologramCardData && this.hologramCardData.mediaId === cardMediaId) { return; }
		const sub = this.mediaService.getMediaById(cardMediaId).subscribe(media => {
			this.hologramCardData = new FileDataType();
			this.hologramCardData.mediaId = cardMediaId;
			this.hologramCardData.media = new Media(media);
			const sub1 = this.mediaService.downloadFile(this.hologramCardData.media.rawUrl).subscribe(
				blob => {
					const file = Tools.blobToFile(blob, media.name);
					this.hologramCardData.file = file;
					this.hologramCardData.fileSafeUrl = Tools.createModelUrl(this.hologramCardData);
					sub1.unsubscribe();
				}
			);
			sub.unsubscribe();
		});
	}

	public downloadFileToSystem(file: FileDataType): void {
		saveAs(file.file, file.media.name);
	}

	public removeCardspecificMedia(): void {
		this.hologramCard.model.unset(this.currentLanguage);
		this.checkForContentChange();
		this.getMedia();
	}

	public discard(): void {
		this.hologramCard = new HologramCard(this.originalHologramCard);
		this.smartphone.onDiscard();
		this.checkForContentChange();
		this.getMedia();
		this.hideModal();
	}

	public openModal(modalTemplate: TemplateRef<any>): void {
		this.modalRef = this.modalService.show(modalTemplate);
	}

	public hideModal(): void {
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	public has3DModel(): boolean {
		return (this.hologramCard && this.hologramCard.model.hasAny())
			|| (this.hologram && this.hologram.model.hasAny());
	}

	public getLanguageValue(text: MultiLanguageText): string {
		return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
	}

	public checkForContentChange(): void {
		this.contentChanged = !this.hologramCard.equal(this.originalHologramCard);
	}

	public calculateScaledModelDimensions() {
		if(this.modelViewer) {
			let dims = this.modelViewer.nativeElement.getDimensions();
			let scaleFactor = this.hologramCard.model.getWithFallback(this.currentLanguage)?.scaleFactor
				|| this.hologram.model.getWithFallback(this.currentLanguage).scaleFactor;
			return {
				x: dims.x * scaleFactor,
				y: dims.y * scaleFactor,
				z: dims.z * scaleFactor
			};
		}
		return {x: 0, y: 0, z: 0};
	}

	public setModelScaleFactor(scaleFactor: number) {
		this.hologramCard.model.get(this.currentLanguage).scaleFactor = scaleFactor;
		this.checkForContentChange();
	}

	public handleFilesFromDropin(files: File[]): void {
		let language = this.currentLanguage;
		if (files.length > 0) {
			this.contextService.setSavingContent(true);
			this.mediaService.uploadFile(files[0]).subscribe(
				response => {
					if (response.status < 400) {
						const location: string = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
						const mediaId: string = location.substring(location.lastIndexOf('/') + 1);
						this.hologramCard.model.set(language, HologramMediaIdValue.fromMediaId(mediaId));
						this.checkForContentChange();
						this.getMedia();
					}
					this.contextService.setSavingContent(false);
				});
		}
	}

	public onSave(): void {
		this.hideModal();
		this.contextService.setSaveYourContent(false);
		let saveContent = (this.contentChanged) ? true : false;
		if (saveContent) {
			this.contextService.setSavingContent(true);
		}
		if (this.contentChanged) {
			const requests: Observable<any>[] = [];
			const sub = forkJoin(requests).subscribe(
				response => {
				}, error => {
				},
				() => {
					const sub1 = this.hologramCardService.updateHologramCard(this.hologramCard).subscribe(
						response => {
							this.originalHologramCard = new HologramCard(this.hologramCard);
							saveContent = false;
							if (!saveContent) {
								this.checkForContentChange();
								this.contextService.setSavingContent(false);
							}
							sub1.unsubscribe();
							sub.unsubscribe();
						});
				});
		}
	}

	public returnToHologram(): void {
		this.router.navigate(['holograms', this.route.snapshot.paramMap.get('hologramId')]);
	}

	public isLanguage(language: Language): boolean {
		return language === this.currentLanguage;
	}

	public updateLanguage(language: Language): void {
		this.currentLanguage = language;
		this.getMedia();
	}

	public toggleHologramCardActivation(): void {
		this.hologramCard.active = !this.hologramCard.active;
		this.checkForContentChange();
	}

	public getStringNameForContentType(type: ContentTypes): string {
		switch (type) {
			case ContentTypes.Custom: {
				return this.stringService.get('CUSTOM');
			}
			case ContentTypes.URL: {
				return this.stringService.get('URL');
			}
		}
	}


	public showContentTypesSelection(): boolean {
		return this.config.getEnabledContentTypes().length > 1;
	}

	// public getMaxContentFileSize(): number {
	// 	return new Config().getMaxImageFileSize();
	// }

	public routeTo(appState: AppState): void {
		switch (appState) {
			case AppState.exhibitions:
				this.router.navigate(['exhibitions']);
				break;
			case AppState.images:
				this.router.navigate(['exhibitions', this.hologram.exhibitionIds[0], 'images']);
				break;
			case AppState.editHologram:
				const hologramId = this.route.snapshot.paramMap.get('hologramId');
				this.router.navigate(['holograms', hologramId]);
				break;
			default:
				break;
		}
	}

	public isTabAllowed(type: ContentTypes): boolean {
		for (const contentType of this.config.getEnabledContentTypes()) {
			if (contentType === type) {
				return true;
			}
		}
		return false;
	}
}
