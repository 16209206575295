import { AuthService } from './../services/auth.service';
import { Component, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Config } from '../Config';
import { AppState } from '../current.app.state';
import { FileDataType } from '../models/FileDataType';
import { Media } from '../models/Media';
import { Poi } from '../models/Poi';
import { PoiTexture } from '../models/PoiTexture';
import { CurrentContextService } from '../services/currentContext.service';
import { MediaService } from '../services/media.service';
import { PoiManagerService } from '../services/poi-manager.service';
import { PoiTextureService } from '../services/poi-texture.service';
import { Tools } from '../Tools';
import { Language } from '../models/Language';
import { MultiLanguageFileDataType } from '../models/MultiLanguageFileDataType';
import { ResizedEvent } from 'angular-resize-event';

@Component({
	selector: 'poi',
	templateUrl: './poi.component.html',
	styleUrls: ['./poi.component.scss']
})
export class POIComponent implements OnInit, OnDestroy {

	@ViewChild('point') point: ElementRef;
	@ViewChild('pointContainer') pointContainer: ElementRef;
	@ViewChild('pop') pop;

	@Input() poi: Poi;
	@Input() isActivePoi: boolean = false;
	@Input() imageView;
	_currentLanguage: Language;
	@Input() set currentLanguage(language: Language) {
		this._currentLanguage = language;
	}
	public Language = Language;

	private dragMode = false;

	public scaleModeTopLeft = false;
	public scaleModeTopRight = false;
	public scaleModeBottomLeft = false;
	public scaleModeBottomRight = false;

	private moveAllowed = false;

	public scaleAllowed = false;

	private popOveractive: BehaviorSubject<boolean>;

	public modalRef: BsModalRef;

	public popoverPlacement = 'right';

	public textureData: MultiLanguageFileDataType = new MultiLanguageFileDataType();

	public Tools = Tools;

	private pmsEventEmitterSubscription: Subscription;

	public config = new Config();

	public contentWidth = 0;
	public contentHeight = 0;
	public containerWidth = 0;
	public containerHeight = 0;
	public oldMouseX = 0;
	public oldMouseY = 0;

	public hovered = false;

	public constructor(
		private contextService: CurrentContextService,
		public pms: PoiManagerService,
		private modalService: BsModalService,
		private router: Router,
		private route: ActivatedRoute,
		private poiTextureService: PoiTextureService,
		private mediaService: MediaService,
		private sanitizer: DomSanitizer,
		private authService: AuthService
	) {
		this.popOveractive = new BehaviorSubject<boolean>(false);
		this.pmsEventEmitterSubscription = pms.eventEmitter.subscribe(
			value => {
				if (this.poi && value === 'reloadPoi:' + this.poi.id) {
					this.reloadPoi();
				}
			}
		);
	}

	public ngOnInit(): void {
		this.setPopoverPlacement();
		this.reloadPoi();
	}

	public ngOnDestroy(): void {
		this.pmsEventEmitterSubscription.unsubscribe();
	}

	private reloadPoi(): void {
		this.poi = this.pms.getCurrentPoiById(this.poi.id);
		for (let lang of Tools.allLangs()) {
			if (this.poi.multiLanguageTextureId.has(lang)) {
				this.getPoiTexture(this.poi.multiLanguageTextureId.get(lang).textureId, lang);
			}
		}
	}

	private getPoiTexture(textureId: string, language: Language): void {
		if (textureId === '00000000-0000-0000-0000-000000000001' ||
			textureId === '00000000-0000-0000-0000-000000000002' ||
			textureId === '00000000-0000-0000-0000-000000000003') {
			this.textureData.set(language, new FileDataType());
			this.textureData.get(language).mediaId = textureId;
			if (textureId === '00000000-0000-0000-0000-000000000001') {
				this.textureData.get(language).fileSafeUrl = '../../assets/default-poi-textures/Default1.png';
				this.textureData.get(language).isLoaded = true;
			} else if (textureId === '00000000-0000-0000-0000-000000000002') {
				this.textureData.get(language).fileSafeUrl = '../../assets/default-poi-textures/Default2.png';
				this.textureData.get(language).isLoaded = true;
			} else if (textureId === '00000000-0000-0000-0000-000000000003') {
				this.textureData.get(language).fileSafeUrl = '../../assets/default-poi-textures/transparentCircle.png';
				this.textureData.get(language).isLoaded = true;
			}
		} else {
			const sub = this.poiTextureService.getTextureById(textureId).subscribe(
				texture => {
					if (texture) {
						this.getTextureMedia(new PoiTexture(texture), language);
					}
					sub.unsubscribe();
				}
			);
		}
	}

	private getTextureMedia(texture: PoiTexture, language: Language): void {
		const sub = this.mediaService.getMediaById(texture.mediaId).subscribe(
			textureMedia => {
				this.textureData.set(language, new FileDataType());
				this.textureData.get(language).isLoaded = false;
				this.textureData.get(language).media = new Media(textureMedia);
				this.textureData.get(language).mediaId = textureMedia.id;
				this.downloadTexture(language);
				sub.unsubscribe();
			}
		);
	}

	private downloadTexture(language: Language): void {
		const sub = this.mediaService.downloadFile(this.textureData.get(language).media.rawUrl).subscribe(
			blob => {
				const file = Tools.blobToFile(blob, this.textureData.get(language).media.name);
				this.textureData.get(language).file = file;
				if (Tools.isFileModel(this.textureData.get(language))) {
					this.textureData.set(language, Tools.createModelUrl(this.textureData.get(language)));
				} else if (Tools.isFileImage(this.textureData.get(language))) {
					this.textureData.set(language, Tools.createImageUrl(this.textureData.get(language), this.sanitizer));
				}
				sub.unsubscribe();
			}
		);
	}

	public selectPoi() {
		if (this.contextService.getCurrentState() === AppState.editImage) {
			this.pms.selectedPoi = this.poi;
		}
	}

	public getPopoverActive(): Observable<boolean> {
		return this.popOveractive.asObservable();
	}

	public eventHandler(event): void {
		if (event.key === 'e') {
			this.editPoint(event);
		} else if (event.key === 'm') {
			this.movePoint(event);
		}
	}

	public onShadowContainerResized(event: ResizedEvent) {
		if(event.newRect) {
			this.contentWidth = event.newRect.width;
			this.contentHeight = event.newRect.height;
		}
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event): void {
		this.setPointStyle();
	}

	public setPointContainerStyle(): Object {
		if (
			this.textureData.get(this._currentLanguage) !== undefined &&
			this.pointContainer && this.textureData.get(this._currentLanguage).isLoaded &&
			this.imageView?.nativeElement?.offsetHeight &&
			this.imageView?.nativeElement?.offsetWidth &&
			this.contentWidth > 0 && this.contentHeight > 0
		) {
			const dimensions = this.imageView.nativeElement.getBoundingClientRect();

			const containerRatio = this.contentWidth / this.contentHeight;
			this.containerHeight = (dimensions.width / containerRatio) * this.poi.scaleFactor;
			this.containerWidth = dimensions.width * this.poi.scaleFactor;

			const top = this.poi.y / 100 * this.imageView.nativeElement.offsetHeight - this.containerHeight / 2;
			const left = this.poi.x / 100 * this.imageView.nativeElement.offsetWidth - this.containerWidth / 2;

			const maxWidth = this.imageView.nativeElement.getBoundingClientRect().width;
			const maxHeight = this.imageView.nativeElement.getBoundingClientRect().height;
			const style = {
				'width': this.containerWidth + 'px',
				'height': this.containerHeight + 'px',
				'max-width': Math.min(maxWidth, maxHeight) + 'px',
				'max-height': Math.min(maxWidth, maxHeight) + 'px',
				'min-width': this.imageView.nativeElement.offsetWidth * 0.05 + 'px',
				'min-height': this.imageView.nativeElement.offsetWidth * 0.05 + 'px',
				'left': left.toString() + 'px',
				'top': top.toString() + 'px'
			};
			return style;
		}
	}

	public setPointStyle(): Object {
		let style;
		if (
			this.imageView &&
			this.imageView.nativeElement &&
			this.imageView.nativeElement.offsetHeight &&
			this.imageView.nativeElement.offsetWidth
		) {
			if (this.moveAllowed) {
				style = {
					'border': '2px solid blue',
					'opacity': (this.poi.active ? '1.0' : '0.4')
				};
			} else if (this.scaleAllowed) {
				style = {
					'opacity': (this.poi.active ? '1.0' : '0.4')
				};
			} else {
				style = {
					'border': (this.pms.selectedPoi && this.poi.id === this.pms.selectedPoi.id ? '2px solid #87ceeb' : 'none'),
					'opacity': (this.poi.active ? '1.0' : '0.4')
				};
			}
			return style;
		}
	}

	@HostListener('document:mouseup', ['$event'])
	public onMouseup(event: MouseEvent): void {
		event.stopPropagation();
		if (this.dragMode) {
			this.dragMode = false;
			this.denyMove();
			this.setPopoverPlacement();
		}
		if (this.scaleModeBottomLeft || this.scaleModeBottomRight || this.scaleModeTopRight || this.scaleModeTopLeft) {
			this.scaleModeBottomLeft = false;
			this.scaleModeTopLeft = false;
			this.scaleModeBottomRight = false;
			this.scaleModeTopRight = false;
			this.denyScale();
		}
	}

	@HostListener('mousedown', ['$event'])
	public onMousedown(event: MouseEvent): void {
		event.stopPropagation();
		this.popOveractive.next(true);
		if (this.moveAllowed) {
			this.dragMode = true;
		}
		if (this.scaleAllowed) {
			const dimension = this.point.nativeElement.getBoundingClientRect();
			this.containerWidth = dimension.width;
			this.containerHeight = dimension.height;
			this.oldMouseY = event.clientY;
			this.oldMouseX = event.clientX;
		}
	}

	@HostListener('document:mousemove', ['$event'])
	public onMousemove(event: MouseEvent): void {
		if (this.dragMode) {
			const imageViewPosition = this.imageView.nativeElement.getBoundingClientRect();
			if (event.pageX - imageViewPosition.left - 5 <= imageViewPosition.width && event.pageX - imageViewPosition.left - 5 >= 0) {
				if (event.pageY - imageViewPosition.top - 5 <= imageViewPosition.height && event.pageY - imageViewPosition.top - 5 >= 0) {
					const percentageWidth = 100 / imageViewPosition.width;
					const percentageHeight = 100 / imageViewPosition.height;
					this.poi.x = (event.pageX - imageViewPosition.left - 5) * percentageWidth;
					this.poi.y = (event.pageY - imageViewPosition.top - 5) * percentageHeight;
				}
			}
		}
		if (this.scaleModeBottomLeft || this.scaleModeBottomRight || this.scaleModeTopRight || this.scaleModeTopLeft) {
			const offsetY = Math.abs(event.clientY - this.oldMouseY);
			const offsetX = Math.abs(event.clientX - this.oldMouseX);
			let offsetMove = Math.sqrt(offsetX * offsetX + offsetY * offsetY);

			if (
				(this.scaleModeTopLeft && (event.clientY > this.oldMouseY || event.clientX > this.oldMouseX)) ||
				(this.scaleModeBottomLeft && (event.clientY < this.oldMouseY || event.clientX > this.oldMouseX)) ||
				(this.scaleModeBottomRight && (event.clientY < this.oldMouseY || event.clientX < this.oldMouseX)) ||
				(this.scaleModeTopRight && (event.clientY > this.oldMouseY || event.clientX < this.oldMouseX))
			) {
				offsetMove = offsetMove * -1;
			}

			const maxWidth = this.imageView.nativeElement.getBoundingClientRect().width;
			const maxHeight = this.imageView.nativeElement.getBoundingClientRect().height;
			const maxSize = Math.min(maxWidth, maxHeight);
			const minSize = this.imageView.nativeElement.offsetWidth * 0.05;

			if (
				this.containerHeight + offsetMove >= minSize &&
				this.containerWidth + offsetMove >= minSize &&
				this.containerHeight + offsetMove <= maxSize &&
				this.containerWidth + offsetMove <= maxSize
			) {
				this.containerHeight += offsetMove;
				this.containerWidth += offsetMove;
			}

			this.poi.scaleFactor = this.containerWidth / this.imageView.nativeElement.offsetWidth;
			this.oldMouseY = event.clientY;
			this.oldMouseX = event.clientX;
		}
	}

	public editPoint(event): void {
		event.stopPropagation();
		this.pop.hide();
		this.popOveractive.next(false);

		const currentPoiId = this.route.snapshot.paramMap.get('poiId');
		if (currentPoiId && currentPoiId == this.poi.id) {
			return;
		}

		const appState = this.contextService.getCurrentState();
		if (appState === AppState.editPoint || appState === AppState.editImage || appState === AppState.editImageMeta) {
			this.router.navigate(['images', this.poi.parentImageId, 'pois', this.poi.id]);
		} else if (appState === AppState.editArLayerPoi || appState === AppState.editARLayer || appState === AppState.editArLayerMeta) {
			this.router.navigate(['cuboids', this.route.snapshot.paramMap.get('cuboidId'), 'arlayers', this.route.snapshot.paramMap.get('arLayerId'), 'pois', this.poi.id]);
		}
	}

	public movePoint(event): void {
		event.stopPropagation();
		this.pop.hide();
		this.popOveractive.next(false);
		this.allowMove();
	}

	public scalePoint(event): void {
		event.stopPropagation();
		this.pop.hide();
		this.popOveractive.next(false);
		this.scaleAllowed = true;
	}

	public cancelPoint(event): void {
		event.stopPropagation();
		this.pop.hide();
		this.popOveractive.next(false);
	}

	private allowMove(): void {
		this.moveAllowed = true;
	}

	private denyMove(): void {
		if (!this.moveAllowed) { return; }
		this.moveAllowed = false;
		this.pms.checkForPoisChanged();
	}

	private denyScale(): void {
		if (!this.scaleAllowed) { return; }
		this.scaleAllowed = false;
		this.pms.checkForPoisChanged();
	}

	private setPopoverPlacement(): void {
		this.popoverPlacement = 'right';
	}

	public openModal(modalTemplate: TemplateRef<any>): void {
		this.modalRef = this.modalService.show(modalTemplate);
	}

	public closeModal(): void {
		this.modalRef.hide();
		this.pointContainer.nativeElement.focus();
	}

	public showModel(): boolean {
		return (this.hovered || this.scaleAllowed || this.moveAllowed || this.pop.isOpen || this.isActivePoi);
	}

	public getThumbUrl(media: Media): string {
		if (media) {
			if (media.thumbUrl) {
				return this.authService.addTokenToUrl(media.thumbUrl);
			} else if (media.rawUrl) {
				return this.authService.addTokenToUrl(media.rawUrl);
			} else {
				return '../../assets/image-placeholder.png';
			}
		}
		return '';
	}

	public getOrientationString(): string {
		let rotation = this.poi.multiLanguageTextureId.getWithFallback(this._currentLanguage).rotation;
		let rotZ = -1.0 * rotation[2];
		let rotY = rotation[1];
		let rotX = rotation[0];
		return `${rotZ}deg ${rotX}deg ${rotY}deg`;
	}

	public isLanguage(language: Language): boolean {
		return language === this._currentLanguage;
	}
}
