import { ApplicationRef, Component, HostListener, TemplateRef } from '@angular/core';
import { AppState } from '../current.app.state';
import { CurrentContextService } from '../services/currentContext.service';
import * as L from 'leaflet';
import { Tour } from '../models/Tour';
import { TourService } from '../services/tour.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Language } from '../models/Language';
import { Config } from '../Config';
import { Tools } from '../Tools';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GeoJsonFeature, GeoJsonPoint, MapElement } from '../models/GeoJson';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { TrackerSelectionModal } from '../modals/tracker-selection/tracker-selection.modal.component';
import { Exhibition } from '../models/Exhibition';
import { ExhibitionService } from '../services/exhibition.service';
import { ImageService } from '../services/image.service';
import { CuboidService } from '../services/cuboid.service';
import { PlaceService } from '../services/place.service';
import { Tracker } from '../models/Tracker';
import { Image } from '../models/Image';
import { Cuboid } from '../models/Cuboid';
import { Place } from '../models/Place';
import { tap } from 'rxjs/operators';
import { Media } from '../models/Media';
import { MediaService } from '../services/media.service';
import { TrackerService } from '../services/tracker.service';
import { HologramService } from '../services/hologram.service';
import { Hologram } from '../models/Hologram';
import RouteHelper from '../../helper-classes/RouteHelper';
import { UserConfigService } from '../services/userConfig.service';

@Component({
	selector: 'aacms-edit-tour',
	templateUrl: './edit-tour.component.html',
	styleUrls: ['./edit-tour.component.scss']
})
export class EditTourComponent {
	public tour: Tour;
	public originalTour: Tour;
	public track: Location[] = [];
	public tourChanged = false;
	public currentLanguage: Language;
	public selectedMarker: GeoJsonFeature;

	public Language = Language;
	public config: Config = new Config();
	public appState = AppState;
	public Tools = Tools;
	public modalRef: BsModalRef;

	public currentMarkers: any[] = [];

	private saveContentSubject: Subscription;

	public mapElementRef: MapElement;
	public map: L.Map;
	public mapMarkers: L.LayerGroup;
	public mapFullscreen: boolean = false;

	public exhibitions: Map<Exhibition, Tracker[]>;
	private medias: Map<string, Media> = new Map<string, Media>();
	private loadedTrackers: Map<string, boolean> = new Map<string, boolean>();
	private selectedTrackers: Map<string, Tracker> = new Map<string, Tracker>();

	private trackerRadiusSteps: number[] = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100, 150, 250, 500];

	public clickTimer: number;

	private ractangle: any;

	constructor(
		private contextService: CurrentContextService,
		private tourService: TourService,
		private route: ActivatedRoute,
		private router: Router,
		private modalService: BsModalService,
		private exhibitionService: ExhibitionService,
		private imageService: ImageService,
		private cuboidService: CuboidService,
		private hologramService: HologramService,
		private placeService: PlaceService,
		private mediaService: MediaService,
		private trackerService: TrackerService,
		private userConfigService: UserConfigService
	) {

	}

	public ngOnInit(): void {
		this.contextService.setCurrentState(AppState.editTour);
		this.currentLanguage = Language.DE;
		this.getTour();
		this.saveContentSubject = this.contextService.saveYourContent.subscribe(
			saveContent => {
				if (saveContent && this.tour !== undefined) {
					this.onSave().then();
				}
			}
		);
	}

	public ngOnDestroy(): void {
		this.saveContentSubject.unsubscribe();
	}

	@HostListener('window:beforeunload')
	canDeactivate(): Observable<boolean> | boolean {
		return (this.tourChanged) ? false : true;
	}

	private getTour(): void {
		const id = this.route.snapshot.paramMap.get('tourId');
		const sub = this.tourService.getTourById(id).subscribe(
			tour => {
				this.tour = new Tour(tour);
				this.originalTour = new Tour(tour);
				this.findGeoJsonRef();
				this.loadTrackers();
				this.initMap();
				sub.unsubscribe();
			}
		);
	}

	private findGeoJsonRef(): void {
		this.mapElementRef = undefined;
		for (const uiElement of this.tour.href.uiElements) {
			if (uiElement instanceof MapElement && uiElement.isMandatory === true) {
				this.mapElementRef = (<MapElement>uiElement);
				break;
			}
		}
	}

	private loadTrackers(): void {
		if (this.mapElementRef.mapObject !== undefined) {
			const points = this.mapElementRef.mapObject.features.filter(a => a.geometry.type === 'Point');
			for (const point of points) {
				if (point.properties.trackerId !== undefined) {
					this.trackerService.getTrackerById(point.properties.trackerId).subscribe(
						response => {
							this.selectedTrackers.set(response.id, response);
						}
					)
				}
			}
		}
	}

	private createCustomMarker(env, feature, latlng) {
		let myCustomColour;
		if (feature.properties.active) {
			myCustomColour = env.userConfigService.getSync().highlightColor;
		} else {
			myCustomColour = '#D3D3D3';
		}
		const markerHtmlStyles = `
      background: ${myCustomColour};
      width: 46px;
      height: 46px;
      display: block;
      left: -22px;
      top: -31px;
      position: relative;
      border-radius: 50% 50% 50% 0;
      transform: rotate(-45deg);
      display: flex;
      justify-content: center;
      align-items: center;
    `
		const icon = L.divIcon({
			className: "leaflet-marker",
			iconAnchor: [0, 23],
			labelAnchor: [-6, 0],
			popupAnchor: [0, -36],
			html: `<span style="${markerHtmlStyles}"><div class="leaflet-marker-text-ui"><div>${feature.properties.position}</div></div></span>`
		});
		const marker = L.marker(latlng, { draggable: 'true', icon: icon, feature: feature });
		const triggerRadiusMarker: L.Circle = L.circle(latlng, {radius: feature.properties.radius, feature: feature});
		marker.on('dragend', (event) => {
			this.selectMarker(marker);
			for (const feature of this.mapElementRef.mapObject.features) {
				if (feature.properties.id === marker.options.feature.properties.id) {
					(<GeoJsonPoint>marker.options.feature.geometry).setCoordinates(event.target.getLatLng().lng, event.target.getLatLng().lat);
				}
			}

			this.checkForTourChanged();
		});
		marker.on('mousemove', (event: MouseEvent) => {
			triggerRadiusMarker.setLatLng(marker.getLatLng());
		});
		marker.on('click', (event) => {
			this.selectMarker(marker);
		});
		this.selectMarker(marker);

		L.layerGroup([marker, triggerRadiusMarker]).addTo(this.mapMarkers);
		// this is not very nice ... we bypass the geojson parsing and marker creation by
		// manually adding them to another layer. Then we just return nothing here
		// not great, not terrible...
		return null;
	}

	private includeGeoJsonFeatures(feature): boolean {
		if (feature.geometry.type === 'Point' || feature.geometry.type === 'LineString') {
			return true;
		}
		return false;
	}

	private reinitMap(): void {
		this.map.off();
		this.map.remove();
		this.initMap();
	}

	private initMap(): void {
		if (this.mapElementRef.mapObject !== undefined) {
			setTimeout(() => {
				this.map = L.map('map', {
					doubleClickZoom: false
				});
				this.mapMarkers = L.layerGroup();
				this.mapMarkers.addTo(this.map);
				this.map.on('click', (event) => {
					if (this.map.getZoom() >= 1) {
						const newPoint = new GeoJsonPoint();
						newPoint.setCoordinates(event.latlng.lng, event.latlng.lat);
						const newFeature = new GeoJsonFeature();
						newFeature.geometry = newPoint;
						const pointFeatureList = this.mapElementRef.mapObject.features.filter(f => f.geometry.type === 'Point');
						newFeature.properties.position = pointFeatureList.length + 1;
						this.mapElementRef.mapObject.features.push(newFeature);
						this.createCustomMarker(this, newFeature, event.latlng).addTo(this.mapMarkers);
						this.checkForTourChanged();
					}
				});
				const myLayerOptions = {
					pointToLayer: (feature, latlng) => this.createCustomMarker(this, feature, latlng),
					//onEachFeature: (feature, layer) => this.createTriggerRadiusMarker(feature, layer),
					//filter: (feature) => this.includeGeoJsonFeatures(feature)
				}
				const geoJsonElements = L.geoJSON(this.mapElementRef.mapObject, myLayerOptions);
				geoJsonElements.addTo(this.map);
				let bounds;
				if (this.mapElementRef.mapObject.properties.bottomLeft.containsLonAndLat() && this.mapElementRef.mapObject.properties.topRight.containsLonAndLat()) {
					bounds = L.latLngBounds(this.mapElementRef.mapObject.properties.bottomLeft, this.mapElementRef.mapObject.properties.topRight);
					if (this.ractangle !== undefined) {
						this.map.removeLayer(this.ractangle);
					}
					setTimeout(() => {
						this.ractangle = L.rectangle(bounds, { fill: false, color: "#00ff00" });
						this.ractangle.addTo(this.map);
					});
				} else {
					const group = new L.featureGroup(Object.values(geoJsonElements._layers));
					if (Object.values(this.map._layers).length > 0) {
						bounds = group.getBounds();
					} else {
						bounds = L.latLngBounds(L.latLng(47.26543, 5.864417), L.latLng(55.14777, 15.05078));
					}
				}
				this.map.fitBounds(bounds);
				const tiles = L.tileLayer(new Config().getMapUrl(), {
					tileSize: 256,
					minZoom: 1,
					attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
					crossOrigin: true
				});
				tiles.addTo(this.map);
				L.control.scale().addTo(this.map);
				let FullscreenButton = L.Control.extend({
					onAdd: function(map) {
						// ahh yes. AngularJS and css-isolation .. :)
						// good old manual javascript hover effects~
						var btn: HTMLDivElement = L.DomUtil.create('div');
						btn.style.width = '40px';
						btn.style.height = '40px';
						btn.style.border = '2px solid rgba(0,0,0,0.2)';
						btn.style.backgroundClip = 'padding-box';
						btn.style.borderRadius = '2px';
						btn.style.cursor = 'pointer';
						btn.style.backgroundColor = 'white';
						btn.style.textAlign = 'center';
						btn.style.lineHeight = '36px'; // 40 px - 4px border
						btn.style.fontSize = '23px';
						btn.onmouseover = function() { btn.style.backgroundColor = '#f4f4f4'; }
						btn.onmouseleave = function() { btn.style.backgroundColor = 'white'; }
						btn.onclick = function(event: MouseEvent) {
							event.preventDefault();
							event.stopPropagation();
							let mapContainer : HTMLDivElement = map._container.parentElement;
							if(mapContainer.classList.contains('fullscreenMap')) {
								mapContainer.classList.remove('fullscreenMap');
							} else {
								mapContainer.classList.add('fullscreenMap');
							}
							map.invalidateSize();
						}

						var icon = L.DomUtil.create('i');
						icon.classList.add('fa');
						icon.classList.add('fa-arrows-alt');
						icon.style.width = "100%";
						icon.style.height = "100%";
						btn.appendChild(icon);

						return btn;
					},
					onRemove: function(map) {}
				});
				new FullscreenButton({ position: 'bottomright' }).addTo(this.map);
			}
			)
		};
	}

	public selectMarker(marker: any): void {
		if (marker === undefined || marker.options === undefined || marker.options.feature === undefined) {
			this.selectedMarker = undefined;
			if (marker instanceof GeoJsonFeature) {
				this.selectedMarker = marker;
			}
		} else {
			this.selectedMarker = marker.options.feature;
			this.map.panTo(marker._latlng);
		}
		this.redrawMarker();
	}

	private redrawMarker(): void {
		setTimeout(() => {
			this.mapMarkers.eachLayer((layer) => {
				if(layer === undefined) { return; }
				// mapMarkers are LayerGroups consisting of a marker and a circle (radius) around the marker.
				let marker = null, markerRadius = null;
				layer.eachLayer(component => {
					if(component instanceof L.Marker) {
						marker = component;
					} else {
						markerRadius = component;
					}
				});

				if (marker && marker.options && marker.options.feature !== undefined) {
					let myCustomColour;
					if (marker.options.feature === this.selectedMarker) {
						myCustomColour = '#03cb03';
					} else {
						if (marker.options.feature.properties.active) {
							myCustomColour = this.userConfigService.getSync().highlightColor;
						} else {
							myCustomColour = '#a3a3a3';
						}

					}
					const markerHtmlStyles = `
            background: ${myCustomColour};
            width: 46px;
            height: 46px;
            display: block;
            left: -22px;
            top: -31px;
            position: relative;
            border-radius: 50% 50% 50% 0;
            transform: rotate(-45deg);
            display: flex;
            justify-content: center;
            align-items: center;
          `;
					const icon = L.divIcon({
						className: "leaflet-marker",
						iconAnchor: [0, 23],
						labelAnchor: [-6, 0],
						popupAnchor: [0, -36],
						html: `<span style="${markerHtmlStyles}"><div class="leaflet-marker-text-ui"><div>${marker.options.feature.properties.position}</div></div></span>`
					});
					marker.setIcon(icon);
				}
			});
		});
	}

	public toggleActive(marker: any, event: MouseEvent): void {
		event.stopPropagation();
		marker.properties.active = !marker.properties.active;
		this.redrawMarker();
		this.checkForTourChanged();
	}

	public moveWaypointUp(): void {
		if (this.selectedMarker && this.selectedMarker.properties.position > 1) {
			const list = this.mapElementRef.mapObject.features.filter(feature => feature.geometry instanceof GeoJsonPoint).sort((a, b) => a.properties.position - b.properties.position);
			const temp = list[this.selectedMarker.properties.position - 2];
			list[this.selectedMarker.properties.position - 2] = list[this.selectedMarker.properties.position - 1];
			list[this.selectedMarker.properties.position - 1] = temp;
			this.updateWaypointPositions(list);
		}
	}

	public moveWaypointDown(): void {
		if (this.selectedMarker && this.selectedMarker.properties.position < this.mapElementRef.mapObject.features.length) {
			const list = this.mapElementRef.mapObject.features.filter(feature => feature.geometry instanceof GeoJsonPoint).sort((a, b) => a.properties.position - b.properties.position);
			const temp = list[this.selectedMarker.properties.position];
			list[this.selectedMarker.properties.position] = list[this.selectedMarker.properties.position - 1];
			list[this.selectedMarker.properties.position - 1] = temp;
			this.updateWaypointPositions(list);
		}
	}

	private updateWaypointPositions(list: GeoJsonFeature[]): void {
		let counter = 1;
		for (const feature of list) {
			if (feature && feature.geometry instanceof GeoJsonPoint) {
				feature.properties.position = counter;
				counter++;
			}
		}
		this.redrawMarker();
		this.checkForTourChanged();
	}

	public checkForTourChanged(): void {
		if (this.tour.equal(this.originalTour)) {
			this.tourChanged = false;
		} else {
			this.tourChanged = true;
		}
	}

	public deleteMarker(marker: GeoJsonFeature, event: MouseEvent) {
		event.stopPropagation();
		this.map.eachLayer((layer) => {
			if (layer !== undefined && layer.options !== undefined && layer.options.feature !== undefined) {
				if (marker.equal(layer.options.feature)) {
					this.map.removeLayer(layer);
				}
			}
		});
		this.mapElementRef.mapObject.features = this.mapElementRef.mapObject.features.filter(feature => !feature.equal(marker));
		if (this.selectedMarker && this.selectedMarker.equal(marker)) {
			this.selectedMarker = undefined;
		}

		this.updateWaypointPositions(this.mapElementRef.mapObject.features);
		this.checkForTourChanged();
	}

	public getWaypointListEntryStyle(marker: any): Object {
		let borderStyle = '';
		if (marker && marker.properties && marker.properties.active) {
			borderStyle = (this.selectedMarker !== undefined && this.selectedMarker.properties.position === marker.properties.position) ? '2px solid #87ceeb' : '2px solid gainsboro';
		} else {
			borderStyle = (this.selectedMarker !== undefined && this.selectedMarker.properties.position === marker.properties.position) ? '2px solid rgba(255,0,0,0.3)' : '2px solid rgba(220,220,220,0.3)';
		}
		const style = {
			'border': borderStyle
		};
		return style;
	}

	public getWaypointListTitleStyle(marker: GeoJsonFeature): Object {
		const style = {
			'opacity': marker.properties.active ? '1' : '0.3'
		};
		return style;
	}

	public cancel(): void {
		if (this.modalRef) { this.modalRef.hide(); }
		this.routeTo(this.appState.tours);
	}

	public openModal(modalTemplate: TemplateRef<any>): void {
		this.modalRef = this.modalService.show(modalTemplate);
	}

	public async onSave(): Promise<any> {
		this.contextService.setSaveYourContent(false);
		this.contextService.setSavingContent(true);
		await this.tourService.updateTour(this.tour).pipe().toPromise().then();
		this.originalTour = new Tour(this.tour);
		this.checkForTourChanged();
		this.contextService.setSavingContent(false);
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	public delete(): void {
		this.modalRef.hide();
		this.tourChanged = false;
		this.contextService.setSavingContent(true);
		const sub = this.tourService.deleteTour(this.tour).subscribe(
			() => {
				this.contextService.setSavingContent(false);
				this.cancel();
				sub.unsubscribe();
			}
		);
	}

	public onDiscard(): void {
		if (this.tourChanged) {
			this.tour = new Tour(this.originalTour);
			this.findGeoJsonRef();
			this.checkForTourChanged();
			this.reinitMap();
		}
		this.modalRef.hide();
	}

	public isLanguage(language: Language): boolean {
		return language === this.currentLanguage;
	}

	public getLanguageValue(text: any): string {
		return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
	}

	public getLanguageValueFromMarker(marker: any): string {
		if (marker.properties.trackerId !== undefined && this.selectedTrackers.get(marker.properties.trackerId) !== undefined && this.selectedTrackers.get(marker.properties.trackerId).title !== undefined) {
			return this.selectedTrackers.get(marker.properties.trackerId).title.getInLanguage(this.currentLanguage);
		}
		return '';
	}

	public isMarkerActive(marker: any): boolean {
		if (marker.properties.active) {
			return true;
		} else {
			return false;
		}
	}

	public updateLanguage(language: Language): void {
		this.currentLanguage = language;
	}

	public routeTo(appState: AppState): void {
		switch (appState) {
			case AppState.tours:
				this.router.navigate(['tours']);
				break;
			default:
				break;
		}
	}

	public async getTrackers(getMedias: boolean): Promise<any> {
		this.contextService.setSavingContent(true);
		if (this.exhibitions === undefined) {
			await this.exhibitionService.getExhibitions().pipe(
				tap(
					exhibitions => {
						this.exhibitions = new Map();
						for (const exhibition of exhibitions) {
							this.exhibitions.set(new Exhibition(exhibition), []);
						}
					}
				)
			).toPromise();
		}
		for (const exhibition of this.exhibitions.keys()) {
			if (this.exhibitions.get(exhibition).length === 0) {
				await this.imageService.getImages(exhibition.id).pipe(
					tap(
						images => {
							for (const image of images) {
								this.exhibitions.get(exhibition).push(new Image(image));
							}
						}
					)
				).toPromise();
				await this.hologramService.getHolograms(exhibition.id).pipe(
					tap(
						holograms => {
							for (const hologram of holograms) {
								this.exhibitions.get(exhibition).push(new Hologram(hologram));
							}
						}
					)
				).toPromise();
				await this.cuboidService.getCuboids(exhibition.id).pipe(
					tap(
						cuboids => {
							for (const cuboid of cuboids) {
								this.exhibitions.get(exhibition).push(new Cuboid(cuboid));
							}
						}
					)
				).toPromise();
				await this.placeService.getPlaces(exhibition.id).pipe(
					tap(
						places => {
							for (const place of places) {
								this.exhibitions.get(exhibition).push(new Place(place));
							}
						}
					)
				).toPromise();
				if (getMedias) {
					await this.getTrackerMedias(this.exhibitions.get(exhibition)).pipe().toPromise();
				}
				this.exhibitions.get(exhibition).sort((tracker1, tracker2) => tracker2.createdTime - tracker1.createdTime);
			}
		}
		this.contextService.setSavingContent(false);
		return true;
	}

	public async openTrackerSelection(marker: GeoJsonFeature): Promise<any> {
		await this.getTrackers(true);
		const initialState = {
			exhibitions: this.exhibitions,
			selectedTrackerId: marker.properties.trackerId,
			medias: this.medias,
			loadedTrackers: this.loadedTrackers,
			currentLanguage: this.currentLanguage
		};
		this.modalRef = this.modalService.show(TrackerSelectionModal,
			{ class: 'modal-lg', initialState, keyboard: false, ignoreBackdropClick: true });
		let sub;
		const modalSub = this.modalService.onHide.subscribe(
			() => {
				if (sub !== undefined) {
					sub.unsubscribe();
				}
				modalSub.unsubscribe();
			}
		);
		sub = this.modalRef.content.event.subscribe(res => {
			marker.properties.trackerId = res.id;
			this.selectedTrackers.set(res.id, res);
			this.checkForTourChanged();
			if (modalSub !== undefined) {
				modalSub.unsubscribe();
			}
			sub.unsubscribe();
		});
	}

	private getTrackerMedias(trackers: Tracker[]): Observable<any> {
		const requests = [];
		for (const tracker of trackers) {
			if (tracker instanceof Image) {
				const req = this.getImageMedia(tracker);
				if (req) {
					requests.push(this.getImageMedia(tracker));
				}
			} else if (this.config.isCuboidTrackerEnabled() && tracker instanceof Cuboid) {
				const cuboidrequests = this.getCuboidMedias(tracker);
				for (const request of cuboidrequests) {
					if (request) {
						requests.push(request);
					}
				}
			}
		}
		return forkJoin(requests).pipe();
	}

	private getImageMedia(tracker: Image): Observable<any> {
		if (!tracker.mediaId) { return null; }
		return this.mediaService.getMediaById(tracker.mediaId).pipe(
			tap(
				media => {
					if (media) {
						this.medias.set(media.id, media);
					}
				}
			)
		);
	}

	private getCuboidMedias(tracker: Cuboid): Observable<any>[] {
		const requests: Observable<any>[] = [];
		requests.push(this.getCuboidMedia(tracker.trackerIds.front.mediaId));
		requests.push(this.getCuboidMedia(tracker.trackerIds.back.mediaId));
		requests.push(this.getCuboidMedia(tracker.trackerIds.top.mediaId));
		requests.push(this.getCuboidMedia(tracker.trackerIds.bottom.mediaId));
		requests.push(this.getCuboidMedia(tracker.trackerIds.left.mediaId));
		requests.push(this.getCuboidMedia(tracker.trackerIds.right.mediaId));
		return requests;
	}

	private getCuboidMedia(mediaId: string) {
		if (!mediaId) { return null; }
		return this.mediaService.getMediaById(mediaId).pipe(
			tap(
				media => {
					if (media) {
						this.medias.set(media.id, media);
					}
				}));
	}

	public routeToEditTourMeta(event) {
		event.stopPropagation();
		this.selectMarker = undefined;
		this.router.navigate(['tours/' + this.tour.id + '/meta/']);
	}

	public trackBy(position: number, item: GeoJsonFeature) {
		return item.properties.id;
	}

	public updateDistance(feature: GeoJsonFeature) {
		let cIdx = this.trackerRadiusSteps.findIndex((r) => r == feature.properties.radius);
		let newIdx = (cIdx + 1) % this.trackerRadiusSteps.length;
		feature.properties.radius = this.trackerRadiusSteps[newIdx];
		this.map.eachLayer((layer) => {
			if(layer instanceof L.Circle && layer.options.feature) {
				if(layer.options.feature.equal(feature)) {
					layer.setRadius(feature.properties.radius);
				}
			}
		});
		this.checkForTourChanged();
	}

	public async routeToTracker(marker: GeoJsonFeature) {
		let theTracker = null;
		if (this.exhibitions === undefined) {
			await this.getTrackers(false);
		}
		for (const exhibition of this.exhibitions.keys()) {
			for (const tracker of this.exhibitions.get(exhibition)) {
				if (tracker.id === marker.properties.trackerId) {
					theTracker = tracker;
					break;
				}
			}
			if(theTracker) { break; }
		}
		RouteHelper.routeToTracker(this.router, theTracker);
	}
}
