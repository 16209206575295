import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Config } from '../Config';
import { AppState } from '../current.app.state';
import { MultiUploadImagesModal } from '../modals/image/multiUpload.modal.component';
import { Cuboid } from '../models/Cuboid';
import { Exhibition } from '../models/Exhibition';
import { Image } from '../models/Image';
import { Language } from '../models/Language';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { Tracker } from '../models/Tracker';
import { AuthService } from '../services/auth.service';
import { CuboidService } from '../services/cuboid.service';
import { CurrentContextService } from '../services/currentContext.service';
import { ExhibitionService } from '../services/exhibition.service';
import { ImageService } from '../services/image.service';
import { MediaService } from '../services/media.service';
import { Place } from '../models/Place';
import { PlaceService } from '../services/place.service';
import * as L from 'leaflet';
import { Location } from '../models/Location';
import { MultiUploadHologramsUploadModal } from '../modals/hologram-upload/hologram-upload.modal.component';
import { Hologram } from '../models/Hologram';
import { HologramService } from '../services/hologram.service';
import RouteHelper from '../../helper-classes/RouteHelper';
import { MediaLoader } from '../../helper-classes/MediaLoader';
import { DomSanitizer } from '@angular/platform-browser';

class HoverHelper {
	public hoverId: string = null;
	private pendingHoverId: string = null;
	private pendingTimeout = null;

	public requestHover(id: string) {
		this.pendingHoverId = id;
		this.pendingTimeout = setTimeout(() => {
			if (this.pendingHoverId === id) { // newest pending is equal to our request
				this.hoverId = id;
				this.pendingHoverId = null;
			}
		}, 500);
	}
	public unrequestHover(id: string) {
		if (this.hoverId === id) { this.hoverId = null; }
		if (this.pendingHoverId === id) { this.pendingHoverId = null; }
		if (this.pendingTimeout !== null) {
			clearTimeout(this.pendingTimeout);
			this.pendingTimeout = null;
		}
	}
}

@Component({
	selector: 'images',
	templateUrl: './images.component.html',
	styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit, OnDestroy {

	private trackers: Tracker[];
	private mediaLoader: MediaLoader;
	private loadedTrackers: Map<string, boolean> = new Map<string, boolean>();
	public searchText = '';
	public modalRef: BsModalRef;
	public initDone = false;
	public currentExhibition: Exhibition = null;
	public config: Config = new Config();
	public currentLanguage: Language;

	public activeHover: HoverHelper = new HoverHelper();

	public constructor(
		private router: Router,
		private route: ActivatedRoute,
		private imageService: ImageService,
		private hologramService: HologramService,
		private contextService: CurrentContextService,
		private modalService: BsModalService,
		private exhibitionService: ExhibitionService,
		private authService: AuthService,
		private cuboidService: CuboidService,
		private placeService: PlaceService,
		mediaService: MediaService,
		sanitizer: DomSanitizer
	) {
		this.mediaLoader = new MediaLoader(mediaService, sanitizer);
	}

	public ngOnInit(): void {
		this.contextService.setCurrentState(AppState.images);
		this.initDone = false;
		this.currentLanguage = Language.DE;
		const exhibitionId = this.route.snapshot.paramMap.get('exhibitionId');
		this.contextService.setSavingContent(true);
		const request = [
			this.getExhibition(exhibitionId),
			this.getTrackers(exhibitionId)
		];
		const sub = forkJoin(request).subscribe(
			() => {
				this.contextService.setSavingContent(false);
				this.initDone = true;
				sub.unsubscribe();
			}
		);
	}

	ngOnDestroy() {
		this.initDone = false;
		this.currentExhibition = null;
	}

	private getExhibition(exhibitionId: string): Observable<any> {
		return this.exhibitionService.getExhibitionById(exhibitionId).pipe(
			tap(response => {
				this.currentExhibition = response;
			}
			));
	}

	public trackerLoaded(image: Tracker, loaded?: boolean): boolean {
		if (loaded) {
			this.loadedTrackers.set(image.id, loaded);
		}
		return !this.loadedTrackers.has(image.id);
	}

	public getTrackerTitle(tracker: Tracker): string {
		if (!tracker.title.de) { return ''; }
		let title = tracker.title.de;
		return title;
	}

	public createImage(): void {
		const image = new Image();
		if (this.currentExhibition.id && this.currentExhibition.id !== '') {
			image.exhibitionIds.push(this.currentExhibition.id);
		}
		const sub = this.imageService.createImage(image).subscribe(
			response => {
				const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
				image.id = location.substring(location.lastIndexOf('/') + 1);
				image.title = new MultiLanguageText();
				this.trackers.push(image);
				sub.unsubscribe();
			}
		);
	}

	public createCuboid(): void {
		const cuboid = new Cuboid();
		if (this.currentExhibition.id && this.currentExhibition.id !== '') {
			cuboid.exhibitionIds.push(this.currentExhibition.id);
		}
		const sub = this.cuboidService.createCuboid(cuboid).subscribe(
			response => {
				const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
				cuboid.id = location.substring(location.lastIndexOf('/') + 1);
				cuboid.title = new MultiLanguageText();
				this.trackers.push(cuboid);
				sub.unsubscribe();
			});
	}

	public createPlace(): void {
		const place = new Place();
		if (this.currentExhibition.id && this.currentExhibition.id !== '') {
			place.exhibitionIds.push(this.currentExhibition.id);
		}
		const sub = this.placeService.createPlace(place).subscribe(
			response => {
				const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
				place.id = location.substring(location.lastIndexOf('/') + 1);
				place.title = new MultiLanguageText();
				setTimeout(() => {
					this.initMap(place);
				});
				this.trackers.push(place);
				sub.unsubscribe();
			});
	}

	public createImages(): void {
		const modalOptions = { class: 'modal-lg', keyboard: false, ignoreBackdropClick: true }
		this.modalRef = this.modalService.show(MultiUploadImagesModal, modalOptions);
		this.modalRef.content.currentExhibition = this.currentExhibition;
		const modalSubscription = this.modalService.onHide.subscribe(() => {
			const sub = this.getTrackers(this.currentExhibition.id).subscribe(
				response => {
					sub.unsubscribe();
				}
			);
			modalSubscription.unsubscribe();
		});
	}

	public createHologram(): void {
		const modalOptions = { class: 'modal-lg', keyboard: false, ignoreBackdropClick: true }
		this.modalRef = this.modalService.show(MultiUploadHologramsUploadModal, modalOptions);
		this.modalRef.content.currentExhibition = this.currentExhibition;
		const modalSubscription = this.modalService.onHide.subscribe(() => {
			const sub = this.getTrackers(this.currentExhibition.id).subscribe(
				response => {
					sub.unsubscribe();
				}
			);
			modalSubscription.unsubscribe();
		});
	}

	private getTrackers(exhibitionId: string): Observable<any> {
		this.trackers = [];
		const requests = [];
		requests.push(this.getImages(exhibitionId));
		if (this.config.isHologramTrackerEnabled()) {
			requests.push(this.getHolograms(exhibitionId));
		}
		if (this.config.isToursEnabled()) {
			requests.push(this.getPlaces(exhibitionId));
		}
		if (this.config.isCuboidTrackerEnabled()) {
			requests.push(this.getCuboids(exhibitionId));
		}
		return forkJoin(requests).pipe(
			tap(
				() => {
					this.trackers.sort((tracker1, tracker2) => tracker2.createdTime - tracker1.createdTime);
				}
			));
	}

	private getCuboids(exhibitionId: string): Observable<any> {
		return this.cuboidService.getCuboids(exhibitionId)
			.pipe(
				tap(
					cuboids => {
						for (const cuboid of cuboids) {
							this.trackers.push(new Cuboid(cuboid));
						}
					}
				)
			);
	}

	private getImages(exhibitionId: string): Observable<any> {
		return this.imageService.getImages(exhibitionId)
			.pipe(
				tap(
					images => {
						for (const image of images) {
							this.trackers.push(new Image(image));
						}
					}
				)
			);
	}

	private getHolograms(exhibitionId: string): Observable<any> {
		return this.hologramService.getHolograms(exhibitionId)
			.pipe(
				tap(
					holgorams => {
						for (const hologram of holgorams) {
							this.trackers.push(new Hologram(hologram));
						}
					}
				)
			);
	}

	private getPlaces(exhibitionId: string): Observable<any> {
		return this.placeService.getPlaces(exhibitionId)
			.pipe(
				tap(
					places => {
						for (const place of places) {
							const newPlace = new Place(place);
							this.trackers.push(newPlace);
							setTimeout(() => {
								this.initMap(newPlace);
							});
						}
					}
				)
			);
	}

	private initMap(place: Place) {
		const map = L.map(
			'map:' + place.id,
			{
				zoomControl: false,
				attributionControl: false,
				dragging: false,
				boxZoom: false,
				doubleClickZoom: false,
				scrollWheelZoom: false,
				touchZoom: false,
				keyboard: false
			}
		);
		const boundOffset = 1000;
		let bounds;
		if (place.location !== undefined && place.location.lat !== undefined && place.location.lon !== undefined) {
			if (place.radius !== undefined) {
				bounds = L.latLngBounds(this.calculateBounds(place.location, place.radius + boundOffset), this.calculateBounds(place.location, -place.radius - boundOffset));
				L.circle(L.latLng(place.location.lat, place.location.lon), place.radius).addTo(map);
			} else {
				bounds = L.latLngBounds(this.calculateBounds(place.location, 100 + boundOffset), this.calculateBounds(place.location, -100 - boundOffset));
				L.circle(L.latLng(place.location.lat, place.location.lon), 1).addTo(map);
			}
		} else {
			bounds = L.latLngBounds(L.latLng(47.26543, 5.864417), L.latLng(55.14777, 15.05078));
		}
		map.fitBounds(bounds);
		const tiles = L.tileLayer(new Config().getMapUrl(), {
			tileSize: 256,
			minZoom: 1,
			attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
			crossOrigin: true
		});
		tiles.addTo(map);
	}

	private calculateBounds(location: Location, radius): void {
		const earthRadius = 6378137;
		const dLat = radius / earthRadius;
		const dLng = radius / (earthRadius * Math.cos(Math.PI * location.lat / 180));
		const latO = location.lat + dLat * 180 / Math.PI;
		const lngO = location.lon + dLng * 180 / Math.PI;
		return L.latLng(latO, lngO);
	}

	public getCuboidThumbUrl(mediaId: string): string {
		const media = this.mediaLoader.get(mediaId);
		if (media) {
			if (media.media.thumbUrl) {
				return this.authService.addTokenToUrl(media.media.thumbUrl);
			} else if (media.media.rawUrl) {
				return this.authService.addTokenToUrl(media.media.rawUrl);
			} else {
				return null;
			}
		}
		return null;
	}

	public trackersEmpty(): boolean {
		if (!this.trackers) { return true; }
		if (this.trackers.length === 0) { return true; }
		return false;
	}

	public getTrackersAsList(): Tracker[] {
		if (this.trackers) {
			const temp: Tracker[] = this.trackers;
			temp.sort((image1, image2) => image2.createdTime - image1.createdTime);
			return temp;
		} else {
			const temp: Tracker[] = [];
			return temp;
		}
	}

	public goToExhibitions() {
		this.router.navigateByUrl('exhibitions');
	}

	public trackByFn(index, item) {
		return item.id;
	}

	public routeTo(tracker: Tracker): void {
		RouteHelper.routeToTracker(this.router, tracker);
	}

	public onCheckbox(event: Event, tracker: Tracker): void {
		event.stopPropagation();
		tracker.active = !tracker.active;
		if (tracker instanceof Image) {
			const sub = this.imageService.updateImageActivation(tracker).subscribe(
				_ => {
					sub.unsubscribe();
				}
			);
		}
		else if (tracker instanceof Cuboid) {
			const sub = this.cuboidService.updateCuboidActivation(tracker).subscribe(
				_ => {
					sub.unsubscribe();
				}
			);
		}
		else if(tracker instanceof Hologram) {
			const sub = this.hologramService.updateHologramActivation(tracker).subscribe(
				_ => {
					sub.unsubscribe();
				}
			);
		}
		else if (tracker instanceof Place) {
		  const sub = this.placeService.updatePlaceActivation(tracker).subscribe(
        _ => {
          sub.unsubscribe();
        }
      );
    }
		else {
		  console.error('Can\'t enable/disable tracker: Type not handled!');
    }
	}

	public isActive(tracker: Tracker): boolean {
		return tracker.active;
	}

	public isImage(tracker: Tracker): boolean {
		return tracker instanceof Image;
	}

	public isHologram(tracker: Tracker): boolean {
		return tracker instanceof Hologram;
	}

	public isPlace(tracker: Tracker): boolean {
		return tracker instanceof Place;
	}

	public isCuboid(tracker: Tracker): boolean {
		return tracker instanceof Cuboid;
	}

	public getTrackerMediaId(tracker: Tracker) {
		if (tracker instanceof Hologram) {
			return tracker.model.getWithFallback(this.currentLanguage)?.mediaId;
		}
		return null;
	}

	public getTracker(tracker: Tracker): Cuboid {
		if (tracker instanceof Cuboid) {
			return <Cuboid>tracker;
		} else {
			return null;
		}
	}

	public getStyle(tracker: Tracker): Object {
		const style = {
			'opacity': tracker.active ? '1' : '0.3',
		};
		return style;
	}

	public getLanguageValue(text: MultiLanguageText): string {
		return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
	}

	public getExportStatus(tracker: Tracker): number {
		if (!tracker.exportTime || tracker.createdTime > tracker.exportTime) {
			return 0; // Nicht veröffentlicht
		}
		else if (tracker.exportTime < tracker.updatedTime) {
			return 1; // Halb veröffentlicht
		}
		else {
			return 2; // Komplett veröffentlicht
		}
	}

	public getBackgroundColor(exportStatus: number): string {
		switch (exportStatus) {
			case 0: return 'red';
			case 1: return 'yellow';
			case 2: return 'green';
		}
	}
}
