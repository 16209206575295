import { FileDataType } from './models/FileDataType';
import { DomSanitizer } from '@angular/platform-browser';
import { Media } from './models/Media';
import { Language } from './models/Language';

export class Tools {

  public static blobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, {
      lastModified: Date.now(),
      type: blob.type
    });
  }

  public static allLangs(): Language[] {
    return Object.keys(Language)
      .filter(v => !isNaN(Number(v)))
      .map(v => Number(v) as Language);
  }

  public static isFileImage(fileData: FileDataType): boolean {
    if (fileData && this.isDefultTexture(fileData.mediaId)) {
      return true;
    }
    if (fileData && fileData.file) {
      if (
        fileData.file.type.startsWith('image/') ||
        fileData.file.name.endsWith('.jpg') ||
        fileData.file.name.endsWith('.jpeg') ||
        fileData.file.name.endsWith('.png') ||
        fileData.file.name.endsWith('.svg')
      ) {
        return true;
      }
    }
    return false;
  }

  public static isFileModel(fileData: FileDataType): boolean {
    if (fileData && fileData.file) {
      if (
        fileData.file.type.startsWith('model/glb') ||
        fileData.file.name.endsWith('.glb')
      ) {
        return true;
      }
    }
    return false;
  }

  public static isMediaModel(media: Media): boolean {
    return (media.type.startsWith('model/glb') ||
            media.name.endsWith('.glb'));
  }

  public static isDefultTexture(id: string): boolean {
    if (id === '00000000-0000-0000-0000-000000000001' ||
     id === '00000000-0000-0000-0000-000000000002' ||
     id === '00000000-0000-0000-0000-000000000003') {
      return true;
    }
    return false;
  }

  public static createImageUrl(fileData: FileDataType, sanitizer: DomSanitizer): FileDataType {
    const urlCreator = window.URL;
    const url = urlCreator.createObjectURL(fileData.file);
    fileData.fileSafeUrl = sanitizer.bypassSecurityTrustUrl(url);
    fileData.isLoaded = true;
    return fileData;
  }

  public static createModelUrl(fileData: FileDataType): FileDataType {
    const reader = new FileReader();
    reader.onloadend = function () {
      fileData.fileSafeUrl = reader.result;
      fileData.isLoaded = true;
    };
    reader.readAsDataURL(fileData.file);
    return fileData;
  }

  public static checkForNumber(value: string, negativePossible: boolean = true): string {
    if (value.startsWith('-') && !negativePossible && value.length === 1) {
      return '';
    }
    if (value.startsWith('-') && !negativePossible && value.length > 1) {
      value = value.substring(1, value.length);
    }
    if (value.startsWith('--')) {
      value = value.substring(1, value.length);
    }
    if (value.startsWith('-00')) {
      value = '-' + value.substring(2, value.length);
    }
    if (value.startsWith('-0') && value.length > 2) {
      value = '-' + value.substring(2, value.length);
    }
    if (value.startsWith(',') || value.startsWith('.')) {
      value = '0.' + value.substring(1, value.length);
    }
    if (value.startsWith('-,') || value.startsWith('-.')) {
      value = '-0.' + value.substring(1, value.length);
    }
    if (value === '0.' || value === '-0.') {
      return value;
    }
    if (value === '0,') {
      return '0.';
    }
    if (value === '-0,') {
      return '-0.';
    }
    if (value === '-') {
      return value;
    }
    if (value.match(/-*\d*[\.,][\.,]+/)) {
      value = value.replace(',', '.');
      value = value.substring(0, value.indexOf('.')) + value.substring(value.lastIndexOf('.'), value.length);
    }
    if (value.startsWith('0') && value.length > 1 && (!value.startsWith('0.') && !value.startsWith('0,'))) {
      value = value.substring(1, value.length);
    }
    if (value.length === 0) {
      return '';
    }
    if (value && (negativePossible) ? !value.match(/^-*[0-9]+([\.,][0-9]*)?$/g) : !value.match(/^[0-9]+([\.,][0-9]*)?$/g)) {
      return null;
    } else if (value) {
      value = value.replace(',', '.');
    }
    return value;
  }

  public static getBackgroundColorForRating(rating: number): string {
    return this.percentageToHsl(rating, 0, 120);
  }

  // green(120) to red(0)
  // blue(225) to pink(315)
  // blue (225) to yellow(45 + 360)
  private static percentageToHsl(percentage, hue0, hue1): string {
    const hue = (percentage / 100 * (hue1 - hue0)) + hue0;
    return 'hsl(' + hue + ', 100%, 50%)';
  }

  public static shortenString(str: string): string {
    return str.length > 10 ? str.substr(0, 10) + '...' : str;
  }
}
