import { Injectable, OnDestroy } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from '../current.app.state';
import { Poi } from '../models/Poi';
import { CurrentContextService } from './currentContext.service';
import { PoiService } from './poi.service';

@Injectable({
  providedIn: 'root',
})
export class PoiManagerService implements OnDestroy {

  public eventEmitter: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public selectedPoi: Poi = null;
  public pois: Poi[] = [];
  public originalPois: Poi[] = [];
  public poisChanged = false;
  public isLoadingPois: Promise<any> = null;
  private navigationSubscription: Subscription;

  public constructor(
    private poiService: PoiService,
    private contextService: CurrentContextService,
    private router: Router
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof ActivationEnd) {
        const imageId = e.snapshot.paramMap.get('imageId');
        if (imageId) {
          this.loadPoisForId(imageId);
          return;
        }
        const arLayerId = e.snapshot.paramMap.get('arLayerId');
        if (arLayerId) {
          this.loadPoisForId(arLayerId);
          return;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  public loadPoisForId(id: string) {
    this.isLoadingPois = this.poiService.getPoisByImageId(id).pipe(
      tap(
        pois => {
          pois = pois.sort((poi1, poi2) => poi1.position - poi2.position);
          this.pois = [];
          for (const poi of pois) {
            const newPoi = new Poi(poi);
            this.pois.push(newPoi);
          }
          this.reloadPoiLayer();
          this.resetOriginalPois();
        }
      )).toPromise().then(() => this.isLoadingPois = null);
  }

  public resetOriginalPois(): void {
    this.originalPois = [];
    for (const poi of this.pois) {
      this.originalPois.push(new Poi(poi));
    }
    this.checkForPoisChanged();
  }

  public clearPois(): void {
    this.pois = [];
    this.originalPois = [];
    this.selectedPoi = null;
    this.poisChanged = false;
  }

  public resetCurrentPois(): void {
    this.pois = [];
    for (const poi of this.originalPois) {
      const newPoi = new Poi(poi);
      this.pois.push(newPoi);
      if (this.selectedPoi && poi.id === this.selectedPoi.id) {
        this.selectedPoi = newPoi;
      }
    }
    this.checkForPoisChanged();
  }

  public createNewPoi(newPoi: Poi): Promise<Poi> {
    return new Promise((resolve, reject) => {
      const sub = this.poiService.createPoi(newPoi).subscribe(
        response => {
          const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
          newPoi.id = location.substring(location.lastIndexOf('/') + 1);
          this.pois.push(new Poi(newPoi));
          this.originalPois.push(new Poi(newPoi));
          if (this.contextService.getCurrentState() === AppState.editImage) {
            this.selectedPoi = newPoi;
          }
          sub.unsubscribe();
          resolve(newPoi);
        },
        err => { reject(err) }
      );
    });
  }

  public getCurrentPoiById(id: string): Poi {
    return this.pois.find(poi => poi.id === id);
  }

  public resetPoiById(id: string, originalPoi: Poi): Poi {
    const index = this.pois.findIndex(poi => poi.id === id);
    if (index >= 0) {
      this.pois[index] = new Poi(originalPoi);
      this.reloadPoi(originalPoi);
      return this.pois[index];
    }
    return null;
  }

  public getOriginalPoiById(id: string): Poi {
    return this.originalPois.find(poi => poi.id === id);
  }

  public checkForPoisChanged(): void {
    if (this.pois.length !== this.originalPois.length) {
      this.poisChanged = true;
      return;
    }
    for (const poi of this.pois) {
      const originalPoi = this.originalPois.find(p => p.id === poi.id);
      if (
        !originalPoi ||
        !poi.equal(originalPoi)
      ) {
        this.poisChanged = true;
        return;
      }
    }
    this.poisChanged = false;
  }

  public saveChangedPois(): Subscription {
    const requests: Observable<any>[] = [];

    const changedPois = this.findChangedPois();
    const newPois = this.findNewPois();
    const deletedPois = this.findDeletedPois();

    for (const poi of changedPois) {
      requests.push(this.poiService.updatePoi(poi));
    }
    for (const poi of newPois) {
      requests.push(this.poiService.createPoi(poi));
    }
    for (const poi of deletedPois) {
      requests.push(this.poiService.deletePoi(poi));
    }

    return forkJoin(requests).subscribe(
      () => {
        this.resetOriginalPois();
      }
    );
  }

  private findNewPois(): Poi[] {
    return this.pois.filter(poi => !this.originalPois.find(p => p.id === poi.id));
  }

  private findDeletedPois(): Poi[] {
    return this.originalPois.filter(opoi => !this.pois.find(p => p.id === opoi.id));
  }

  private findChangedPois(): Poi[] {
    const changedPois = [];
    for (const poi of this.pois) {
      const originalPoi = this.originalPois.find(p => p.id === poi.id);
      if (!originalPoi) {
        break;
      }
      if (!poi.equal(originalPoi)) {
        changedPois.push(poi);
      }
    }
    return changedPois;
  }

  public reloadPoiLayer() {
    this.eventEmitter.next('reloadPoiLayer');
  }

  public reloadPoi(poi: Poi) {
    this.eventEmitter.next('reloadPoi:' + poi.id);
  }

  public updatePoiPositions(): void {
    let counter = 0;
    for (const poi of this.pois) {
      poi.position = counter;
      counter++;
    }
    this.checkForPoisChanged();
  }
}
