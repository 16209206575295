import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentContextService } from '../services/currentContext.service';
import { AuthService } from '../services/auth.service';
import { Config } from '../Config';
import { LockService } from '../services/lock.service';
import { AlertService } from '../services/alert.service';
import { AlertType, AlertWithBoldValueInMiddle } from '../models/Alert';
import { StringService } from '../services/string.service';
import { UserConfigService } from '../services/userConfig.service';

/**
 * Toolbar Component
 */
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  /**
	 * Variable for the configuration.
	 */
  public config: Config = new Config();

  public currentTime: number;
  public warningCounter: number = 0;

  /**
	 * Constructor
	 * @param router Router to navigate
	 * @param contextService Current context service
	 * @param authService Authentication service
	 */
  public constructor(
    private router: Router,
    private contextService: CurrentContextService,
    private authService: AuthService,
    private lockService: LockService,
    private alertService: AlertService,
    private stringService: StringService,
    public userConfigService: UserConfigService
  ) {
    setInterval(()=>{
      this.updateTime();
    }, 1000);
  }

  /**
	 * Triggered by a click on the app name or logo.
	 * Navigates to the exhibitions.
	 */
  public routeToHome(): void {
    if (this.isLoginState()) {
      this.router.navigate(['login']);
    } else {
      this.router.navigate(['exhibitions']);
    }
  }

  /**
	 * Triggered by a click on 'exhibitions'.
	 * Navigates to the exhibitions.
	 */
  public routeToExhibitions(): void {
    this.router.navigate(['exhibitions']);
  }

  /**
	 * Triggered by a click on 'images'.
	 * Navigates to the images.
	 */
  public routeToImages(): void {
    this.router.navigate(['images']);
  }

  public isResourceLocked(): boolean {
    if (this.lockService.currentLock !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  public updateTime(): void {
    let time: number;
    if (this.lockService.currentLock !== undefined) {
      if (this.lockService.currentLock.validUntil !== undefined) {
        if (Date.now() - this.lockService.currentLock.validUntil >= 0) {
          const sub = this.lockService.deleteLock(this.lockService.currentLock.lockId).subscribe(()=>{sub.unsubscribe()});
          this.contextService.setSaveYourContent(true);
          this.router.navigate(['/exhibitions']);
        } else {
          time = (new Date(this.lockService.currentLock.validUntil).getTime() - new Date().getTime());
        }
      }
      if (this.warningCounter === 0 && time <= 300000) {
        this.alertService.alert(new AlertWithBoldValueInMiddle(this.stringService.get('SESSION_EXPIRES_1'), '5', this.stringService.get('SESSION_EXPIRES_2'), AlertType.Warning), false);
        this.warningCounter++;
      } else if (this.warningCounter === 1 && time <= 240000) {
        this.alertService.alert(new AlertWithBoldValueInMiddle(this.stringService.get('SESSION_EXPIRES_1'), '4', this.stringService.get('SESSION_EXPIRES_2'), AlertType.Warning), false);
        this.warningCounter++;
      } else if (this.warningCounter === 2 && time <= 180000) {
        this.alertService.alert(new AlertWithBoldValueInMiddle(this.stringService.get('SESSION_EXPIRES_1'), '3', this.stringService.get('SESSION_EXPIRES_2'), AlertType.Warning), false);
        this.warningCounter++;
      } else if (this.warningCounter === 3 && time <= 120000) {
        this.alertService.alert(new AlertWithBoldValueInMiddle(this.stringService.get('SESSION_EXPIRES_1'), '2', this.stringService.get('SESSION_EXPIRES_2'), AlertType.Warning), false);
        this.warningCounter++;
      } else if (this.warningCounter === 3 && time <= 60000) {
        this.alertService.alert(new AlertWithBoldValueInMiddle(this.stringService.get('SESSION_EXPIRES_1'), '1', this.stringService.get('SESSION_EXPIRES_2'), AlertType.Warning), false);
        this.warningCounter++;
      }
    }
    this.currentTime = time;
  }

  public refreshResourceTimer(): void {
    this.warningCounter = 0;
    const sub = this.lockService.updateLock(this.lockService.currentLock.lockId).subscribe(()=>{sub.unsubscribe();});
  }

  /**
	 * Triggered by a click on 'push Notifications'.
	 * Navigates to the push Notifications.
	 */
  public routeToPushNotifications(): void {
    this.router.navigate(['push-notifications']);
  }

  /**
	 * Triggered by a click on 'App Menu'.
	 * Navigates to the App Menu.
   */
  public routeToAppMenu(): void {
    this.router.navigate(['app-menu']);
  }

  /**
	 * Triggered by a click on 'POI Textures'.
	 * Navigates to the POI Textures.
   */
  public routeToPoiTextures(): void {
    this.router.navigate(['poi-textures']);
  }

  public routeToTours(): void {
    this.router.navigate(['tours']);
  }

  /**
	 * Method to get the real name of the logged on user.
	 * @returns the real name of the logged on user.
	 */
  public getUserRealName(): string {
    return this.authService.getUser() ? this.authService.getUser().realName : '';
  }

  /**
	 * Triggered by a click on the users real name.
	 * Navigates to the edit user page.
	 */
  public routeToEditUser(): void {
    this.router.navigate(['user']);
  }

  /**
	 * Triggered by a click on the user administration icon.
	 * Navigates to the admin page.
	 */
  public routeToAdminPage(): void {
    this.router.navigate(['admin']);
  }

  /**
	 * Indicates whether the application is in login state.
	 * @returns true if the application is in login state.
	 */
  public isLoginState(): boolean {
    return !this.authService.getUser()
  }

  /**
	 * Specifies whether the logged on user has admin rights.
	 * @returns true if the logged on user has admin rights.
	 */
  public isAdmin(): boolean {
    const currentUser = this.authService.getUser();
    if (currentUser) {
      return currentUser.isAdmin();
    } else {
      return false;
    }
  }

  /**
	 * Triggered by a click on the logout icon.
	 * Logs off the current user.
	 * Navigates to the login page.
	 */
  public logout(): void {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  public routeToDoku(): void {
    window.open('https://doku.augmented-art.de/', '_blank').focus();
  }
}

