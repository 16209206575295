import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Config } from '../Config';
import { AppState } from '../current.app.state';
import { ContentTypes } from '../models/ContentTypes';
import { Hologram } from '../models/Hologram';
import { HologramCard } from '../models/HologramCard';
import { Language } from '../models/Language';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { CurrentContextService } from '../services/currentContext.service';
import { HologramService } from '../services/hologram.service';
import { HologramCardService } from '../services/hologramCard.service';
import { MediaService } from '../services/media.service';
import { Tools } from '../Tools';

@Component({
	selector: 'aacms-edit-hologram',
	templateUrl: './edit-hologram.component.html',
	styleUrls: ['./edit-hologram.component.scss']
})
export class EditHologramComponent implements OnInit, OnDestroy {

	public hologram: Hologram = new Hologram();
	public originalHologram: Hologram = new Hologram();
	public hologramChanged = false;

	public hologramCards: HologramCard[] = [];
	public originalHologramCards: HologramCard[] = [];
	public hologramCardsChanged = false;
	public selectedHologramCard: HologramCard;

	public saving = false;

	public modalRef: BsModalRef;

	public config: Config = new Config();
	public appState = AppState;
	public Language = Language;
	public contentTypes = ContentTypes;

	@ViewChild('modelViewer') modelViewer;

	public currentLanguage: Language = null;

	private saveContentSubject: Subscription;
	private enabledContentTypes: ContentTypes[];

	public Tools = Tools;

	public constructor(
		private route: ActivatedRoute,
		private hologramService: HologramService,
		private hologramCardService: HologramCardService,
		public contextService: CurrentContextService,
		private modalService: BsModalService,
		private router: Router
	) {
		this.enabledContentTypes = this.config.getEnabledContentTypes();
	}

	public ngOnInit(): void {
		this.contextService.setCurrentState(AppState.editHologram);
		this.currentLanguage = Language.DE;
		this.getHologram();
		this.getHologramCards();
		this.saveContentSubject = this.contextService.saveYourContent.subscribe(
			saveContent => {
				if (saveContent) {
					this.onSave();
				}
			}
		);
	}

	public ngOnDestroy(): void {
		this.saveContentSubject.unsubscribe();
	}

	@HostListener('window:beforeunload')
	canDeactivate(): Observable<boolean> | boolean {
		return (this.hologramChanged || this.hologramCardsChanged) ? false : true;
	}

	public getPreviewHologramMediaId() : string {
		if(this.selectedHologramCard && this.selectedHologramCard.model?.hasAny()) {
			return this.selectedHologramCard.model?.getWithFallback(this.currentLanguage)?.mediaId;
		}
		return this.hologram?.model?.getWithFallback(this.currentLanguage)?.mediaId;
	}

	private getHologram(): void {
		const id = this.route.snapshot.paramMap.get('hologramId');
		const sub = this.hologramService.getHologramById(id).subscribe(
			hologram => {
				this.hologram = new Hologram(hologram);
				this.originalHologram = new Hologram(hologram);
				this.contextService.getExhibition(this.hologram.exhibitionIds[0]);
				sub.unsubscribe();
			},
			error => {
				this.router.navigate(['images']);
			}
		);
	}

	private getHologramCards(): void {
		const id = this.route.snapshot.paramMap.get('hologramId');
		const sub = this.hologramCardService.getHologramCardsByHologramId(id).subscribe(
			hologramCards => {
				hologramCards = hologramCards.sort((a, b) => a.position - b.position);
				this.hologramCards = [];
				this.originalHologramCards = [];
				for (const hologramCard of hologramCards) {
					this.hologramCards.push(new HologramCard(hologramCard));
					this.originalHologramCards.push(new HologramCard(hologramCard));
				}
				this.checkForHologramCardsChanged();
				sub.unsubscribe();
			}
		);
	}

	private checkForHologramCardsChanged(): void {
		if (this.hologramCards.length === this.originalHologramCards.length) {
			for (let i = 0; i < this.hologramCards.length; i++) {
				if (!this.hologramCards[i].equal(this.originalHologramCards[i])) {
					this.hologramCardsChanged = true;
					return;
				}
			}
			this.hologramCardsChanged = false;
		} else {
			this.hologramCardsChanged = true;
		}
	}

	private checkForHologramChanged(): void {
		if (this.hologram.equal(this.originalHologram)) {
			this.hologramChanged = false;
		} else {
			this.hologramChanged = true;
		}
	}



	/* UI METHODS */
	public routeToEditHologramMeta(event) {
		event.stopPropagation();
		this.router.navigate(['holograms', this.hologram.id, 'meta']);
	}
	public routeToEditHologramCard(event: MouseEvent, hologramCard: HologramCard) {
		event.stopPropagation();
		this.router.navigate(['holograms', this.hologram.id, 'hologramCards', hologramCard.id]);
	}

	public getLanguageValue(text: any): string {
		return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
	}

	public getLanguageValueFromHologramCard(hologramCard: HologramCard): string {
		if (hologramCard.title) {
			return new MultiLanguageText(hologramCard.title).getInLanguage(this.currentLanguage);
		}
		return '';
	}

	public updateLanguage(language: Language): void {
		this.currentLanguage = language;
	}

	public openModal(modalTemplate: TemplateRef<any>): void {
		this.modalRef = this.modalService.show(modalTemplate);
	}

	public routeTo(appState: AppState): void {
		switch (appState) {
			case AppState.exhibitions:
				this.router.navigate(['exhibitions']);
				break;
			case AppState.images:
				this.router.navigate(['exhibitions', this.hologram.exhibitionIds[0], 'images']);
				break;
			default:
				break;
		}
	}

	public deleteHologram(): void {
		this.modalRef.hide();
		this.hologramChanged = false;
		this.contextService.setSavingContent(true);
		const sub = this.hologramService.deleteHologram(this.hologram).subscribe(
			() => {
				this.contextService.setSavingContent(false);
				this.cancel();
				sub.unsubscribe();
			}
		);
	}

	public getHologramCardListEntryStyle(hologramCard: HologramCard): Object {
		let borderStyle = '';
		if (hologramCard && hologramCard.active) {
			borderStyle = (this.selectedHologramCard !== undefined && this.selectedHologramCard.position === hologramCard.position) ? '2px solid #87ceeb' : '2px solid gainsboro';
		} else {
			borderStyle = (this.selectedHologramCard !== undefined && this.selectedHologramCard.position === hologramCard.position) ? '2px solid rgba(255,0,0,0.3)' : '2px solid rgba(220,220,220,0.3)';
		}
		const style = {
			'border': borderStyle
		};
		return style;
	}

	public getHologramCardListTitleStyle(hologramCard: HologramCard): Object {
		const style = {
			'opacity': hologramCard.active ? '1' : '0.3'
		};
		return style;
	}

	public isHologramCardActive(hologramCard: HologramCard): boolean {
		if (hologramCard.active) {
			return true;
		} else {
			return false;
		}
	}

	public addNewHologramCard(): void {
		const hologramCard = new HologramCard();
		hologramCard.position = this.hologramCards.length;
		hologramCard.parentHologramId = this.hologram.id;
		const sub = this.hologramCardService.createHologramCard(hologramCard).subscribe(
			response => {
				const location: string = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
				hologramCard.id = location.substring(location.lastIndexOf('/') + 1);
				this.hologramCards.push(hologramCard);
				this.originalHologramCards.push(new HologramCard(hologramCard));
				this.checkForHologramCardsChanged();
				sub.unsubscribe();
			}
		);
	}

	public selectHologramCard(hologramCard: HologramCard): void {
		if (hologramCard === undefined) {
			this.selectedHologramCard = undefined;
			if (hologramCard instanceof HologramCard) {
				this.selectedHologramCard = hologramCard;
			}
		} else {
			this.selectedHologramCard = hologramCard;
		}
	}

	public deleteHologramCard(hologramCard: HologramCard): void {
		if (hologramCard === this.selectedHologramCard) { this.selectHologramCard = null; }
		this.hologramCards = this.hologramCards.filter(oInfoCard => oInfoCard !== hologramCard);
		this.updateHologramCardPositions();
		this.checkForHologramCardsChanged();
	}

	private updateHologramCardPositions(): void {
		let counter = 0;
		for (const infoCard of this.hologramCards) {
			infoCard.position = counter;
			counter++;
		}
		this.checkForHologramCardsChanged();
	}

	public moveInfoCardUp(): void {
		if (this.selectedHologramCard && this.selectedHologramCard.position > 0) {
			const temp = this.hologramCards[this.selectedHologramCard.position - 1];
			this.hologramCards[this.selectedHologramCard.position - 1] = this.hologramCards[this.selectedHologramCard.position];
			this.hologramCards[this.selectedHologramCard.position] = temp;
			this.updateHologramCardPositions();
		}
	}

	public moveInfoCardDown(): void {
		if (this.selectedHologramCard && this.selectedHologramCard.position < this.hologramCards.length - 1) {
			const temp = this.hologramCards[this.selectedHologramCard.position + 1];
			this.hologramCards[this.selectedHologramCard.position + 1] = this.hologramCards[this.selectedHologramCard.position];
			this.hologramCards[this.selectedHologramCard.position] = temp;
			this.updateHologramCardPositions();
		}
	}

	public toggleHologramCardActive(hologramCard: HologramCard, event: MouseEvent): void {
		event.stopPropagation();
		hologramCard.active = !hologramCard.active;
		this.checkForHologramCardsChanged();
	}

	public cancel(): void {
		if (this.modalRef) { this.modalRef.hide(); }
		this.routeTo(this.appState.images);
	}

	public async onSave(): Promise<any> {
		this.contextService.setSavingContent(true);
		if (this.hologramChanged) {
			await this.hologramService.updateHologram(this.hologram).pipe().toPromise();
			this.originalHologram = new Hologram(this.hologram);
			this.checkForHologramChanged();
		}
		if (this.hologramCardsChanged) {
			const requests = [];
			const infoCardsToDelete = this.originalHologramCards.filter(oInfoCard => !this.hologramCards.find(infoCard => infoCard.id === oInfoCard.id));
			for (const hologramCard of infoCardsToDelete) {
				requests.push(this.hologramCardService.deleteHologramCard(hologramCard));
			}
			for (const hologramCard of this.hologramCards) {
				if (hologramCard.id !== undefined) {
					requests.push(this.hologramCardService.updateHologramCard(hologramCard));
				} else {
					requests.push(this.hologramCardService.updateHologramCard(hologramCard));
				}
			}
			if (requests.length > 0) {
				await forkJoin(requests).pipe(
					tap(
						response => {
							this.getHologramCards();
							this.checkForHologramCardsChanged();
							if (this.modalRef) {
								this.modalRef.hide();
							}
						}
					)
				).toPromise().then();
			} else {
				if (this.modalRef) {
					this.modalRef.hide();
				}
			}
		}
		this.contextService.setSavingContent(false);
	}

	public onDiscard(): void {
		if (this.hologramChanged) {
			this.hologram = new Hologram(this.originalHologram);
			this.hologramChanged = false;
		}
		if (this.hologramCardsChanged) {
			this.hologramCards = this.originalHologramCards
				.map((hC) => new HologramCard(hC));
			this.hologramCardsChanged = false;
		}
		this.modalRef.hide();
	}

	/** Determiens what model to show (depending on selected cards etc.) */
	private getModelPreviewMediaId(): string {
		return this.selectedHologramCard?.model.getWithFallback(this.currentLanguage)?.mediaId
			|| this.hologram.model.getWithFallback(this.currentLanguage)?.mediaId;
	}

	public has3DModel(): boolean {
		return this.getModelPreviewMediaId() != null;
	}
}
