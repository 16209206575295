import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { StringService } from '../services/string.service';
import { AlertSimple, AlertType } from '../models/Alert';
import { Config } from '../Config';

@Injectable()
export class FeatureGuard implements CanActivate {

  public config: Config = new Config();

  public constructor(
    private router: Router,
    private alertService: AlertService,
    private stringService: StringService
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let allowed = true;
    switch (state.url) {
      case '/app-menu': {
        allowed = this.config.isAppMenuEnabled();
        break;
      }
      case '/push-notifications': {
        allowed = this.config.isPushNotificationEnabled();
        break;
      }
      case '/poi-textures': {
        allowed = this.config.isPoiStylesEnabled();
        break;
      }
      case '/tours': {
        allowed = this.config.isToursEnabled();
      }
      default: {
        allowed = true;
      }
    }

    if (state.url.startsWith('/holograms')) {
      allowed = this.config.isHologramTrackerEnabled();
    }
    if (state.url.startsWith('/cuboids')) {
      allowed = this.config.isCuboidTrackerEnabled();
    }

    if (state.url.startsWith('/places')) {
      allowed = this.config.isToursEnabled();
    }

    if (allowed) {
        return true;
    } else {
        this.showError();
        this.router.navigate(['/']);
        return false;
    }
  }

  private showError(): void {
    this.alertService.alert(
      new AlertSimple(
        this.stringService.get('FEATURE_NOT_ENABLED'),
        AlertType.Error),
      true);
  }
}
