<div class="flex-box">
	<div class="searchbar-header">
		<aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
			(languageChanged)="this.updateLanguage($event)">
		</aacms-language-selector>
		<input [(ngModel)]="searchText" type="text" class="form-control form-control-lg searchbar"
			[placeholder]="'SEARCHING' | translate" aria-label="Suche" aria-describedby="basic-addon2"
			[matTooltip]="'FILTER_EXHIBITION_BY_NAME' | translate" triggers="hover">
		<div class="header-buttons">
			<button *ngIf="this.isAdmin()" type="button" class="btn btn-primary"
				(click)="this.openModal(applyExhibitionsModalTemplate)">{{'APPLY_ALL_EXHIBITIONS' | translate
				}}</button>
			<button type="button" class="btn btn-success" (click)="this.createExhibition()">{{'CREATE_NEW_EXHIBITION' |
				translate }}</button>
		</div>
	</div>

	<div *ngIf="this.exhibitionsNotEmpty()" class="exhibition-list">
		<div class="exhibition-element"
			*ngFor="let exhibition of this.getExhibitionsAsList() | ExhibitionFilter : searchText; trackBy: trackByFn">
			<div class="exhibition" [ngStyle]="this.getStyle(exhibition)">
				<div class="publish-indicator-container">
					<div class="publish-indicator"
						[matTooltip]="'EXPORTSTATUS_' + getExportStatus(exhibition) | translate"
						[style.background-color]="getBackgroundColor(getExportStatus(exhibition))">
					</div>
				</div>
				<div class="checkbox-container">
					<label *ngIf="this.isAdmin()" class="checkbox">
						<input type="checkbox" (click)="this.onCheckbox($event, exhibition)"
							[checked]="this.isActive(exhibition)">
						<span class="checkmark" [matTooltip]="'EXHIBITION_ACTIVE' | translate" triggers="hover">
						</span>
					</label>
				</div>
				<div class="preview" (click)="this.routeToExhibition(exhibition)">
					<div class="preview-container riffle-background" *ngIf="this.thumbs.has(exhibition.id)">
						<aacms-cuboid-quick-viewer #cuboidViewer class="cuboid-prview"
							*ngIf="this.currentThumbs.get(exhibition.id) && isThumbCuboid(this.currentThumbs.get(exhibition.id))"
							[cuboid]="this.currentThumbs.get(exhibition.id)"
							[top]="this.currentThumbs.get(exhibition.id).src.top"
							[bottom]="this.currentThumbs.get(exhibition.id).src.bottom"
							[left]="this.currentThumbs.get(exhibition.id).src.left"
							[right]="this.currentThumbs.get(exhibition.id).src.right"
							[back]="this.currentThumbs.get(exhibition.id).src.back"
							[front]="this.currentThumbs.get(exhibition.id).src.front">
						</aacms-cuboid-quick-viewer>
						<img class="image-preview"
							*ngIf="this.currentThumbs.get(exhibition.id) && isThumbImage(this.currentThumbs.get(exhibition.id))"
							[src]="this.currentThumbs.get(exhibition.id).src" />
						<img class="image-preview"
							*ngIf="this.currentThumbs.get(exhibition.id) && isThumbHologram(this.currentThumbs.get(exhibition.id))"
							[src]="this.getHologramThumbUrl(this.currentThumbs.get(exhibition.id))" />
						<img class="no-image-preview" src="/assets/no-images-found.png"
							*ngIf="!this.currentThumbs.get(exhibition.id)" />
					</div>
					<div class="loader-container" *ngIf="this.loadingMedias">
						<aacms-loader class="exhibitionLoader" size="64px">
						</aacms-loader>
					</div>
				</div>
				<!--Editmode Off-->
				<!--Meta-->
				<div *ngIf="editMode !== exhibition.id" class="editable">
					<div class="exhibition-meta">
						<div *ngIf="exhibition.title != undefined && getLanguageValue(exhibition.title).length != 0"
							class="exhibition-title" (click)="this.routeToExhibition(exhibition)">
							<b [matTooltip]="'SHOW_IMAGES_OF_EXHIBITION' | translate" triggers="hover">
								{{getLanguageValue(exhibition.title)}}
							</b>
						</div>
						<div *ngIf="!exhibition.title || getLanguageValue(exhibition.title).length == 0"
							class="exhibition-title" (click)="this.routeToExhibition(exhibition)">
							<i [matTooltip]="'SHOW_IMAGES_OF_EXHIBITION' | translate" triggers="hover">
								{{'UNNAMED_EXHIBITION' | translate }}</i>
						</div>
					</div>
					<!--Icons-->
					<div class="exhibition-icons">
						<button *ngIf="this.isAdmin()"
							(click)="this.applyToBackend(exhibition, applyExhibitionModalTemplate)"
							class="exhibition-icon-button exhibition-icon-button-apply"
							[matTooltip]="'APPLY_TO_BACKEND' | translate" triggers="hover">
							<i class="fa fa-cloud-upload exhibition-icon" aria-hidden="true"></i>
						</button>
						<button (click)="this.askForDeleteExhibition(exhibition)" class="exhibition-icon-button"
							[matTooltip]="'DELETE' | translate" triggers="hover">
							<i class="fa fa-trash exhibition-icon" aria-hidden="true"></i>
						</button>
						<button (click)="this.editExhibition(exhibition)" class="exhibition-icon-button"
							[matTooltip]="'EDIT' | translate" triggers="hover">
							<i class="fa fa-pencil exhibition-icon" aria-hidden="true"></i>
						</button>
					</div>
				</div>

				<!--Editmode On-->
				<!--Meta-->
				<div *ngIf="this.editMode === exhibition.id" class="editable">
					<div class="exhibition-meta">
						<a>{{'TITLE' | translate }}</a>
						<div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
							<input rows="1" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
								class="form-control textInput" aria-label="Large"
								[(ngModel)]="exhibitionToEdit.title.de">
							<div class="languageIndicator">
								<img class="language-button-image" src="../assets/flags/de.svg">
							</div>
							<div class="align-self-center px-2">
								{{this.config.getMaxExhibitionTitleLength() -exhibitionToEdit.title.de.length}}
							</div>
						</div>
						<div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
							<input rows="1" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
								class="form-control textInput" aria-label="Large"
								[(ngModel)]="exhibitionToEdit.title.gb">
							<div class="languageIndicator">
								<img class="language-button-image" src="../assets/flags/gb.svg">
							</div>
							<div class="align-self-center px-2">
								{{this.config.getMaxExhibitionTitleLength() -exhibitionToEdit.title.gb.length}}
							</div>
						</div>
						<div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
							<input rows="1" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
								class="form-control textInput" aria-label="Large"
								[(ngModel)]="exhibitionToEdit.title.jp">
							<div class="languageIndicator">
								<img class="language-button-image" src="../assets/flags/jp.svg">
							</div>
							<div class="align-self-center px-2">
								{{this.config.getMaxExhibitionTitleLength() -exhibitionToEdit.title.jp.length}}
							</div>
						</div>
					</div>
					<!--Icons-->
					<div class="exhibition-icons">
						<button (click)="this.dismissEdit()" class="exhibition-icon-button"
							[matTooltip]="'DISMISS_CHANGES' | translate" triggers="hover">
							<i class="fa fa-undo exhibition-icon" aria-hidden="true"></i>
						</button>
						<button (click)="this.saveExhibition()" class="exhibition-icon-button"
							[matTooltip]="'SAVE_CHANGES' | translate" triggers="hover">
							<i class="fa fa-save exhibition-icon" aria-hidden="true"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="!this.exhibitionsNotEmpty() && this.initDone" class="no-exhibitions">
		<div class="no-exhibitions-text">
			{{'NO_EXHIBITION_FOUND' | translate }}
		</div>
	</div>
</div>

<ng-template #applyExhibitionModalTemplate>
	<div class="modal-header">
		<h4 *ngIf="currentExhibitionToUpload.title != undefined && getLanguageValue(currentExhibitionToUpload.title).length != 0"
			class="modal-title text-break pull-left">{{'REALY_UPLOAD1' | translate }}
			<i>'{{ getLanguageValue(currentExhibitionToUpload.title) }}'</i> {{'REALY_UPLOAD2' | translate}}
		</h4>
		<h4 *ngIf="!currentExhibitionToUpload.title || getLanguageValue(currentExhibitionToUpload.title).length == 0"
			class="modal-title text-break pull-left">{{'REALY_UPLOAD1' | translate }}
			<i>'{{ currentExhibitionToUpload.id }}'</i> {{'REALY_UPLOAD2' | translate}}
		</h4>
	</div>
	<div class="modal-footer">
		<button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_DONT_UPLOAD' | translate
			}}</button>
		<button (click)="this.applyChanges()" type="button" class="btn btn-success">{{'YES_UPLOAD' | translate
			}}</button>
	</div>
</ng-template>

<ng-template #applyExhibitionsModalTemplate>
	<div class="modal-header">
		<h4 class="modal-title text-break pull-left">{{'REALY_UPLOAD_ALL' | translate }}</h4>
	</div>
	<div class="modal-footer">
		<button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_DONT_UPLOAD' | translate
			}}</button>
		<button (click)="this.applyAllChanges()" type="button" class="btn btn-success">{{'YES_UPLOAD' | translate
			}}</button>
	</div>
</ng-template>