<div class="meta">
  <div class="smartphone-left-side">
    <label> {{ 'URL' | translate }}:</label>
    <div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
      <input class="form-control textInput" [(ngModel)]="url.de" (ngModelChange)="emitUrlChange()"
        (keyup.enter)="updateUrl()" />
      <div class="languageIndicator">
        <img class="language-button-image" src="../assets/flags/de.svg">
      </div>
    </div>
    <div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
      <input class="form-control textInput" [(ngModel)]="url.gb" (ngModelChange)="emitUrlChange()"
        (keyup.enter)="updateUrl()" />
      <div class="languageIndicator">
        <img class="language-button-image" src="../assets/flags/gb.svg">
      </div>
    </div>
    <div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
      <input class="form-control textInput" [(ngModel)]="url.jp" (ngModelChange)="emitUrlChange()"
        (keyup.enter)="updateUrl()" />
      <div class="languageIndicator">
        <img class="language-button-image" src="../assets/flags/jp.svg">
      </div>
    </div>
    <div class="reload-resource">
      <button class="reload-resource-button btn btn-success" (click)="updateUrl()" triggers="hover"
        containerClass="customTooltip" placement="bottom">{{ 'UPDATE_VIEW' | translate }}
      </button>
    </div>
  </div>

  <div class="smartphone-right-side">
    <div #smartphone [ngStyle]="getSmartphoneStyle()" class="smartphone">
      <div class="nav-button-container">
        <button class="nav-button back" [disabled]="!this.showBackButton" (click)="onNavigateBack()">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
      </div>
      <div class="smartphone-content-container">
        <iframe *ngIf="!startsWithHttp()" class="smartphone-content" [src]="getSafeUrl()"></iframe>
        <p class="http-warning" *ngIf="startsWithHttp()">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i><br />
          {{ 'HTTP_SITE' | translate }}
        </p>
      </div>
    </div>
    <div class="hint">
      <i>{{ 'PREVIEW_MAY_LOOK_DIFFERENT' | translate }}</i>
    </div>
    <div class="screen-size-button-row">
      <div class="screen-size-button-group btn-group" btnRadioGroup [(ngModel)]="currentSelectedResolution">
        <label class="btn btn-light" btnRadio="small" uncheckable tabindex="0" role="button"
          (click)="changeSmartphoneStyle('14','7')">{{'SMALL_PHONE' | translate}}</label>
        <label class="btn btn-light" btnRadio="big" uncheckable tabindex="0" role="button"
          (click)="changeSmartphoneStyle('16','8')">{{'BIG_PHONE' | translate}}</label>
        <label class="btn btn-light" btnRadio="tablet" uncheckable tabindex="0" role="button"
          (click)="changeSmartphoneStyle('16','11')">{{'TABLET' | translate}}</label>
      </div>
      <div class="screen-rotate-button">
        <button type="button" class="btn btn-light" (click)="rotateSmartphone()"
          [matTooltip]="'ROTATE_SMARTPHONE' | translate">
          <img class="screen-rotate-button-image" src="../../assets/screen_rotation-24px.svg" />
        </button>
      </div>
    </div>
  </div>
</div>
