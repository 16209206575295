import { MultiLanguageText } from './MultiLanguageText';
import { MultiLanguageFileDataType } from './MultiLanguageFileDataType';
import { MultiLanguageData } from './MultiLanguageData';
import { FileDataType } from './FileDataType';

export enum ElementType {
  AUDIO = 0,
  VIDEO = 1,
  TEXT = 2,
  IMAGE = 3,
  MODEL = 4,
  MAP = 5
}

export abstract class UiElement {
  public type: ElementType;
  public position = -1;
  public active = true;
  public isMandatory = false;

  constructor(type, element?: UiElement) {
    this.type = type;
    if (element === undefined) { return; }
    this.type = element.type;
    this.position = element.position;
    this.active = element.active;
    if (element.isMandatory !== undefined) {
      this.isMandatory = element.isMandatory;
    }
  }

  public equal(uiElement: UiElement): boolean {
    if (!uiElement) {
      return false;
    }
    if (
      this.position !== uiElement.position ||
      this.type !== uiElement.type ||
      this.active !== uiElement.active
    ) {
      return false;
    }
    return true;
  }

  abstract cleanForUpLoad(): void;
}

export class TextElement extends UiElement {
  public text: MultiLanguageText = new MultiLanguageText();
  constructor(textElement?: TextElement) {
    super(ElementType.TEXT, textElement);
    if (!textElement) { return; }
    this.text = new MultiLanguageText(textElement.text);
  }

  public equal(textElement: TextElement): boolean {
    if (!textElement) {
      return false;
    }
    if (!this.text.equal(textElement.text)) {
      return false;
    }
    return super.equal(textElement);
  }

  public cleanForUpLoad(): void {

  }
}

export class AudioElement extends UiElement {
  public audioObject: MultiLanguageData<FileDataType> = new MultiLanguageData<FileDataType>(FileDataType);
  constructor(audioElement?: AudioElement) {
    super(ElementType.AUDIO, audioElement);
    if (audioElement && audioElement.audioObject) {
      this.audioObject = new MultiLanguageData<FileDataType>(FileDataType, audioElement.audioObject);
    }
  }

  public equal(audioElement: AudioElement): boolean {
    if (!audioElement) { return false; }
    if (
      !this.audioObject && audioElement.audioObject ||
      this.audioObject && !audioElement.audioObject
    ) {
      return false;
    }
    if (
      this.audioObject && audioElement.audioObject &&
      !this.audioObject.equal(audioElement.audioObject)
    ) {
      return false;
    }
    return super.equal(audioElement);
  }

  public cleanForUpLoad(): void {
    this.audioObject.forEach((_l, obj) => obj.clean());
  }
}

export class VideoElement extends UiElement {
  public videoObject: MultiLanguageData<FileDataType> = new MultiLanguageData<FileDataType>(FileDataType);
  constructor(videoElement?: VideoElement) {
    super(ElementType.VIDEO, videoElement);
    if (videoElement && videoElement.videoObject) {
      this.videoObject = new MultiLanguageData<FileDataType>(FileDataType, videoElement.videoObject);
    }
  }

  public equal(videoElement: VideoElement): boolean {
    if (!videoElement) {
      return false;
    }
    if (
      !this.videoObject && videoElement.videoObject ||
      this.videoObject && !videoElement.videoObject
    ) {
      return false;
    }
    if (
      this.videoObject && videoElement.videoObject &&
      !this.videoObject.equal(videoElement.videoObject)
    ) {
      return false;
    }
    return super.equal(videoElement);
  }

  public cleanForUpLoad(): void {
    this.videoObject.forEach((_l, obj) => obj.clean());
  }
}

export class ImageElement extends UiElement {
  public imageObject: MultiLanguageData<FileDataType> = new MultiLanguageData<FileDataType>(FileDataType);
  constructor(imageElement?: ImageElement) {
    super(ElementType.IMAGE, imageElement);
    if (imageElement && imageElement.imageObject) {
      this.imageObject = new MultiLanguageData<FileDataType>(FileDataType, imageElement.imageObject);
    }
  }
  public equal(imageElement: ImageElement): boolean {
    if (!imageElement) {
      return false;
    }
    if (
      !this.imageObject && imageElement.imageObject ||
      this.imageObject && !imageElement.imageObject
    ) {
      return false;
    }
    if (
      this.imageObject && imageElement.imageObject &&
      !this.imageObject.equal(imageElement.imageObject)
    ) {
      return false;
    }
    return super.equal(imageElement);
  }

  public cleanForUpLoad(): void {
    this.imageObject.forEach((_l, obj) => obj.clean());
  }
}

export class ModelElement extends UiElement {
  public modelObject: MultiLanguageData<FileDataType> = new MultiLanguageData<FileDataType>(FileDataType);
  constructor(modelElement?: ModelElement) {
    super(ElementType.MODEL, modelElement);
    if (modelElement && modelElement.modelObject) {
      this.modelObject = new MultiLanguageData<FileDataType>(FileDataType, modelElement.modelObject);
    }
  }

  public equal(modelElement: ModelElement): boolean {
    if (!modelElement) {
      return false;
    }
    if (
      !this.modelObject && modelElement.modelObject ||
      this.modelObject && !modelElement.modelObject
    ) {
      return false;
    }
    if (
      this.modelObject && modelElement.modelObject &&
      !this.modelObject.equal(modelElement.modelObject)
    ) {
      return false;
    }
    return super.equal(modelElement);
  }

  public cleanForUpLoad(): void {
    this.modelObject.forEach((_l, obj) => obj.clean());
  }
}