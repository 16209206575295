import { Injectable } from '@angular/core';
import { AppState } from '../current.app.state';
import { Language } from '../models/Language';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { BehaviorSubject } from 'rxjs';
import { Exhibition } from '../models/Exhibition';
import { ExhibitionService } from './exhibition.service';
import { tap } from 'rxjs/internal/operators/tap';

/**
 * the CurrentContextService stores temporary data to transfer data easy between Components
 */
@Injectable({
  providedIn: 'root',
})
export class CurrentContextService {

  /**
   * @ignore
   */
  public constructor(
    private exhibitionService: ExhibitionService
  ) { }

  public currentExhibition: Exhibition;

  /**
   * tracks the current State of the App (login, exhibitions, edit-image, ...)
   */
  private currentState: AppState = AppState.login;

  private appLanguage: Language = Language.DE;

  public savingContent = new BehaviorSubject<boolean>(false);

  public saveYourContent = new BehaviorSubject<boolean>(false);

  public setSaveYourContent(value: boolean): void {
    this.saveYourContent.next(value);
  }

  public getExhibition(exhibitionId): void {
    const sub = this.exhibitionService.getExhibitionById(exhibitionId).pipe(tap(
      exhibition => {
        this.currentExhibition = new Exhibition(exhibition);
      }
    )).subscribe(response => {
      sub.unsubscribe();
    });
  }

  /**
   * tracks the current selected Point of Interest,
   * the BehaviorSubject detects changes and can be subscribed
   */

  /**
   * returns the current State to every calling instance
   * @returns the current AppState
   */
  public getCurrentState(): AppState {
    return this.currentState;
  }

  /**
   * sets the Current AppState to the given AppState
   * @param currentState the new AppState that should be set
   */
  public setCurrentState(currentState: AppState): void {
    this.currentState = currentState;
  }

  public getInCurrentAppLanguage(text: MultiLanguageText) {
    if (Language.DE === this.appLanguage) {
      return text.de;
    } else if (Language.GB === this.appLanguage) {
      return text.gb;
    } else if (Language.JP === this.appLanguage) {
      return text.jp;
    } else {
      return 'ERROR LANGUAGE NOT CORRECTLY DEFINED';
    }
  }

  public setSavingContent(value: boolean): void {
    // run asynchronously as to break AngularJS update loop
    // and avoid error NG0100
    setTimeout(() => this.savingContent.next(value), 0);
  }
}
