<div *ngIf="this.infoCard" class="edit-infoCard-meta-main-content">
  <div class="edit-infoCard-meta-left-side">

  </div>
  <div class="edit-infoCard-meta-right-side">
    <div class="header">
      <div class="path-title-container">
        <div class="path">
          <div class="path-option" (click)="this.routeTo(appState.exhibitions)">{{'EXHIBITIONS' | translate}}</div>
          /
          <div class="path-option" (click)="this.routeTo(appState.images)">{{ Tools.shortenString(contextService.currentExhibition && contextService.currentExhibition.title && getLanguageValue(contextService.currentExhibition.title).length > 0 ? getLanguageValue(contextService.currentExhibition.title) : ('TRACKERS' | translate)) }}</div>
          /
          <div class="path-option" (click)="this.routeTo(appState.editPlace)">{{ Tools.shortenString(infoCard && getLanguageValue(infoCard.title).length > 0 ? getLanguageValue(infoCard.title) : ('INFOCARD' | translate)) }}</div>
          /
          <div class="last-path-option">{{'INFOCARD' | translate}}</div>
        </div>
        <div class="arlayer-title-edit-container">
          <h1 class="edit-indicator">
            <i class="fa fa-pencil" aria-hidden="true"
              [ngStyle]="{'color': this.contentChanged ? '#ffc107' : 'black' }"></i>
          </h1>
          <div class="arlayer-title-container">
            <h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.infoCard.title).length > 0">
              {{this.getLanguageValue(this.infoCard.title)}}
            </h1>
            <h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.infoCard.title).length == 0">
              <i>{{'UNNAMED_INFOCARD' | translate }}</i>
            </h1>
          </div>
        </div>
      </div>
      <aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
        (languageChanged)="this.updateLanguage($event)">
      </aacms-language-selector>
    </div>
    <div class="custom-hr"></div>
    <tabset [justified]="false">
      <tab heading="{{'INFOCARD_GENERAL_TAB' | translate}}" class="infoCard-general-tab tab" (selectTab)="this.currentTab = tabType.GeneralTab">
        <div>{{'TITLE' | translate }}</div>
        <div class="infoCard-title-input">
          <div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
            <input class="form-control textInput" [(ngModel)]="this.infoCard.title.de" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
              (ngModelChange)="checkForContentChange()" />
            <div class="languageIndicator">
              <img class="language-button-image" src="../assets/flags/de.svg">
            </div>
            <div class="align-self-center px-2">
              {{this.config.getMaxTitleTextLength() - this.infoCard.title.de.length}}
            </div>
          </div>
          <div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
            <input class="form-control textInput" [(ngModel)]="this.infoCard.title.gb" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
              (ngModelChange)="checkForContentChange()" />
            <div class="languageIndicator">
              <img class="language-button-image" src="../assets/flags/gb.svg">
            </div>
            <div class="align-self-center px-2">
              {{this.config.getMaxTitleTextLength() - this.infoCard.title.gb.length}}
            </div>
          </div>
          <div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
            <input class="form-control textInput" [(ngModel)]="this.infoCard.title.jp" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
              (ngModelChange)="checkForContentChange()" />
            <div class="languageIndicator">
              <img class="language-button-image" src="../assets/flags/jp.svg">
            </div>
            <div class="align-self-center px-2">
              {{this.config.getMaxTitleTextLength() - this.infoCard.title.jp.length}}
            </div>
          </div>
        </div>
        <div *ngIf="this.showContentTypesSelection()">{{ 'TYPE_OF_CONTENT_PAGE' | translate }}</div>
        <div *ngIf="this.showContentTypesSelection()" class="selection-container">
          <select class="selection" [(ngModel)]="this.infoCard.contentType" (change)="this.checkForContentChange()">
            <ng-template ngFor let-type [ngForOf]="this.config.getEnabledContentTypes()">
              <option role="selection-item" [ngValue]="this.type">{{ this.getStringNameForContentType(this.type) }}
              </option>
            </ng-template>
          </select>
        </div>
        <div class="checkbox-container">
          <label class="checkbox">
            <input type="checkbox" (click)="this.toggleInfoCardActivation()" [checked]="this.infoCard.active">
            <span class="checkmark" triggers="hover"></span>
            <div class="checkbox-text">
              {{ 'INFOCARD_ACTIVE_INACTIVE' | translate }}
            </div>
          </label>
        </div>
        <div class="checkbox-container">
          <label class="checkbox">
            <input type="checkbox" (click)="this.toggleArInfoCardCardsActivation()" [checked]="this.infoCard.cardsActive">
            <span class="checkmark" triggers="hover"></span>
            <div class="checkbox-text">
              {{ 'INFOCARD_CARDS_ACTIVE_INACTIVE' | translate }}
            </div>
          </label>
        </div>
      </tab>
      <tab heading="{{ 'INFOCARD_CARD_TAB' | translate }}" class="card-tab tab" (selectTab)="this.currentTab = tabType.CardTab">
        <div class="smartphone-container">
          <aacms-info-card-editor [card]="this.infoCard.card" [title]="this.infoCard.title"
            [currentLanguage]="this.currentLanguage" [trackerFileData]="this.infoCardDataMap" [isTracker]="false"
            (cardChange)="this.checkForContentChange()" [uiElements]="this.infoCard.uiElements">
          </aacms-info-card-editor>
        </div>
      </tab>
      <tab heading="{{ 'INFOCARD_CONTENT_PAGE_TAB' | translate }}" class="content-page-tab tab"
        *ngIf="this.isTabAllowed(contentTypes.Custom)" (selectTab)="this.currentTab = tabType.UiElementsTab">
        <div class="smartphone-container">
          <aacms-content-page #smartphone class="smartphone" [(uiElements)]="this.infoCard.uiElements" [isVisible]="currentTab === tabType.UiElementsTab"
            (uiElementsChange)="checkForContentChange()" [language]="this.currentLanguage">
          </aacms-content-page>
        </div>
      </tab>
      <tab heading="{{ 'INFOCARD_URL_PAGE_TAB' | translate }}" class="url-page-tab tab"
        *ngIf="this.isTabAllowed(contentTypes.URL)" (selectTab)="this.currentTab = tabType.UrlTab">
        <div class="smartphone-container">
          <aacms-url-page [url]="this.infoCard.url" (urlChange)="this.checkForContentChange()"
            [language]="this.currentLanguage">
          </aacms-url-page>
        </div>
      </tab>
    </tabset>
    <div class="custom-hr"></div>
    <div class="button-row row">
      <button type="submit" class="btn btn-secondary custom-button" (click)="returnToInfoCard()">
        {{'BACK_TO_PLACE' | translate}}
      </button>
      <button type="submit" class="btn btn-warning custom-button"
        [disabled]="!this.contentChanged"
        (click)="openModal(cancelModalTemplate)">
        {{'DISCARD' | translate }}
      </button>
      <button type="submit" class="btn btn-success custom-button"
        [disabled]="!this.contentChanged"
        (click)="this.save()">
        {{'SAVE_CHANGES' | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #cancelModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
  </div>
  <div class="modal-footer">
    <button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
    <button (click)="discard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
  </div>
</ng-template>

<ng-template #saveModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-break pull-left">{{'REALY_SAVE_WITHOUT_DIMENSIONS' | translate}}</h4>
  </div>
  <div class="modal-footer">
    <button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'ABORT' | translate }}</button>
    <button (click)="this.save()" type="button" class="btn btn-success">{{'SAVE'| translate}}</button>
  </div>
</ng-template>
