import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { Config } from '../Config';
import { AlertSimple, AlertType, AlertWithBoldValueInMiddle } from '../models/Alert';
import { Image } from '../models/Image';
import { AlertService } from './alert.service';
import { CurrentContextService } from './currentContext.service';
import { StringService } from './string.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root',
})
export class ImageService {

  private baseUrl = new Config().GetBaseUrl();

  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService,
    private contextService: CurrentContextService
  ) { }

  public getImages(exhibitionId?: string): Observable<Image[]> {
    let extension = '';
    if (exhibitionId && exhibitionId !== '') {
      extension = `?exhibitionIds=${exhibitionId}`;
    }
    return this.http.get<Image[]>(this.getImageUrl() + extension).pipe(
      tap(
        response => { },
        error => { this.handleError<Image[]>('getImages', error); }
      ),
      share()
    );
  }

  public getImageById(id: string): Observable<Image> {
    return this.http.get<Image>(this.getImageUrl(`${id}`)).pipe(
      tap(
        response => { },
        error => {
          this.handleError<Image>('getImageById', error);
          this.alertService.alert(
            new AlertWithBoldValueInMiddle(
              this.stringService.get('IMAGE_NOT_FOUND1'),
              id,
              this.stringService.get('IMAGE_NOT_FOUND2'),
              AlertType.Error),
            true);
        }
      ),
      share()
    );
  }

  public createImage(image: Image): Observable<any> {
    image.createdTime = new Date().getTime();
    image.updatedTime = image.createdTime;
    return this.http.post<any>(this.getImageUrl(), image, httpOptions).pipe(
      tap(
        response => {
          if (this.contextService.getInCurrentAppLanguage(image.title) !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('IMAGE_CREATED1'),
                this.contextService.getInCurrentAppLanguage(image.title),
                this.stringService.get('IMAGE_CREATED3'),
                AlertType.Success),
              true);
          } else {
            const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
            const id = location.substring(location.lastIndexOf('/') + 1);
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('IMAGE_CREATED2'),
                id,
                this.stringService.get('IMAGE_CREATED3'),
                AlertType.Success),
              true);
          }
        },
        error => { this.handleError<Image>('createImage', error); }
      ),
      share()
    );
  }

  public updateImage(image: Image): Observable<any> {
    image.updatedTime = new Date().getTime();
    const imageCopy = new Image(image);
    for (const uiElement of imageCopy.uiElements) {
      uiElement.cleanForUpLoad();
    }
    return this.http.put(this.getImageUrl(`${image.id}`), imageCopy, httpOptions).pipe(
      tap(
        response => {
          if (this.contextService.getInCurrentAppLanguage(image.title) !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('IMAGE_UPDATED1'),
                this.contextService.getInCurrentAppLanguage(image.title),
                this.stringService.get('IMAGE_UPDATED3'),
                AlertType.Success),
              true);
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('IMAGE_UPDATED2'),
                image.id,
                this.stringService.get('IMAGE_UPDATED3'),
                AlertType.Success),
              true);
          }
        },
        error => { this.handleError<Image>('updateImage', error); }
      ),
      share()
    );
  }

  public updateImageActivation(image: Image): Observable<any> {
    image.updatedTime = new Date().getTime();
    return this.http.put(this.getImageUrl(`${image.id}`), image, httpOptions).pipe(
      tap(
        response => {
          if (image.active) {
            this.alertService.alert(
              new AlertSimple(
                this.stringService.get('IMAGE_ACTIVATED'),
                AlertType.Success),
              true);
          } else {
            this.alertService.alert(
              new AlertSimple(
                this.stringService.get('IMAGE_DEACTIVATED'),
                AlertType.Info),
              true);
          }
        },
        error => { this.handleError<Image>('updateImageActivation', error); }
      ),
      share()
    );
  }

  public deleteImage(image: Image | string): Observable<any> {
    const id = typeof image === 'string' ? image : image.id;
    return this.http.delete(this.getImageUrl(`${id}`), httpOptions).pipe(
      tap(
        response => {
          if (typeof image === 'object' && this.contextService.getInCurrentAppLanguage(image.title) !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('IMAGE_DELETED1'),
                this.contextService.getInCurrentAppLanguage(image.title),
                this.stringService.get('IMAGE_DELETED3'),
                AlertType.Info),
              true);
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('IMAGE_DELETED2'),
                id,
                this.stringService.get('IMAGE_DELETED3'),
                AlertType.Info),
              true);
          }
        },
        error => { this.handleError<Image>('deleteImage', error); }
      ),
      share()
    );
  }

  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private logError(value: any): void {
    console.error(value);
  }

  private getImageUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}images/`;
    if (extension) {
      if (!extension.endsWith('/')) {
        extension = `${extension}/`;
      }
      url = `${url}${extension}`;
    }
    return url;
  }
}
