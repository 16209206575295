<div class="container">
  <h1>Nutzungsbedingungen Content Management System</h1>
  <p>
    Das Augmented Art Content Management System (CMS) erlaubt das Erstellen und Verwalten von Augmented Reality Inhalten, die von einer mit dem CMS verbundenen Augmented Art App (App) abgerufen werden können. Es dient als eine Art Baukasten, um eine App zu gestalten und diese mit Leben zu füllen.
  </p>

  <p>
    Nutzer erwerben beim oben genannten Betreiber des Angebotes (im Folgenden: Anbieter) eine eigenständige und isolierte Instanz des Augmented Art Content Management Systems (CMS). Diese Instanz ist über eine individuelle Subdomains {{subdomainName}} erreichbar.
  </p>

  <h2>Geltungsbereich</h2>
  <ul>
    <li>
      Für die Nutzung des CMS gelten im Verhältnis zwischen dem Nutzer und dem Anbieter die folgenden Nutzungsbedingungen. Die Nutzung des CMS und dessen Funktionen ist nur zulässig, wenn der Nutzer diese Nutzungsbedingungen akzeptiert.
    </li>
    <li>
      Für bestimmte Features oder Nutzungsmöglichkeiten innerhalb der Instanz können zusätzliche Nutzungsbedingungen vereinbart werden. Werden Leistungen oder Dienste von Dritten erbracht, können zusätzliche Bedingungen dieser gelten. Falls dies der Fall sein sollte, wird an ansprechender Stelle auf diese zusätzlichen Nutzungsbedingungen hingewiesen.
    </li>
  </ul>

  <h2>Vertragsgegenstand</h2>
  <p>
    Gegenstand des Vertrags sind die mit dem Anbieter im Kaufvertrag der jeweiligen Instanz vereinbarten Dienste und Leistungen.
  </p>

  <h2>Rechte und Pflichten der Nutzer</h2>
  <ul>
    <li>
      Der Nutzer darf seine Zugangsdaten Dritten nicht zur Nutzung überlassen. Der Nutzer ist verpflichtet, seine Zugangsdaten geheim zu halten und vor dem Zugriff Dritter zu schützen.
    </li>
    <li>
      Das CMS steht dem Nutzer ausschließlich zu Zwecken der Pflege und Wartung der Inhalte der App zur Verfügung.
    </li>
    <li>
      Der Nutzer ist verpflichtet, kontinuierlich auf die Rechtmäßigkeit und auf die Aktualität der ins CMS gestellten Inhalte und Links zu achten.  Vor der Setzung von Links sind die verlinkten Inhalte auf Rechtsverletzungen zu überprüfen.  Die Verlinkung auf rechtswidrige Seiten, insbesondere mit extremistischem, volksverhetzendem oder beleidigendem Inhalt ist unzulässig.
    </li>
    <li>
      Mit dem Hochladen von Inhalten verbunden ist die Zusicherung des Nutzers, dass Rechte der Urheber der Verbreitung nicht entgegenstehen. Insbesondere ist darauf zu achten, dass Stadtpläne urheberrechtlich geschützt sind.
    </li>
    <li>
      Der Nutzer verpflichtet sich, sämtliche gesetzlichen Bestimmungen, insbesondere die Bestimmungen des Datenschutzes einzuhalten.
    </li>
    <li>
      Der Nutzer ist für die Richtigkeit, Vollständigkeit, Verlässlichkeit, Aktualität und Brauchbarkeit der bereit gestellten Inhalte verantwortlich. Bei technischen Probleme des CMS hat der Nutzer Anspruch auf eine Beseitigung dieser durch den Anbieter.
    </li>
    <li>
      Die vorhandenen Betriebsmittel (z. B. Plattenspeicherplatz) müssen verantwortungsvoll und ökonomisch sinnvoll genutzt werden.
    </li>
  </ul>

  <h2>Rechte und Pflichten des Anbieters</h2>
  <ul>
    <li>
      Verstößt ein Nutzer gegen seine oben definierten Pflichten, ist der Anbieter berechtigt, die entsprechenden Inhalte in angemessener Weise abzuändern oder ggf. zu löschen. Der Nutzer ist verpflichtet, dem Anbieter einen durch die Pflichtverletzung entstandenen Schaden zu ersetzen.
    </li>
    <li>
      Der Nutzer stellt den Anbieter gegenüber Ansprüchen Dritter frei, die aus Verstößen des Nutzers gegen seine Pflichten entstehen und trägt in derartigen Fällen die entstandenen Kosten. Der Nutzer verpflichtet sich, den Anbieter bei der Abwehr derartiger Ansprüche zu unterstützen.
    </li>
    <li>
      Der Anbieter ist berechtigt, Änderungen an seinem Dienst vorzunehmen. Insbesondere zählen hierzu Sicherheits- und Versionsupdates der auf der Instanz laufenden Software. Hierbei können Veränderungen der Nutzeroberfläche und damit der Bedienung der Dienste nicht ausgeschlossen werden.
    </li>
    <li>
      Der Anbieter wird die eingepflegten Daten gegen Datenverlust schützen und regelmäßige Backups erstellen. Der Anbieter hat zu diesem Zweck das Recht Backups auf seinen Servern zu speichern.
    </li>
    <li>
      Das Urheberrecht für die eingestellten Inhalte verbleibt beim jeweiligen Nutzer. Der Nutzer räumt dem Anbieter mit dem Einstellen seiner Inhalte in die Instanz jedoch das Recht ein, die Inhalte über den Zeitraum der Vertragslaufzeit zum Abruf bereitzuhalten und für die an die Instanz angebundene mobile Applikation zugänglich zu machen. Der Anbieter hat das Recht, in begründeten Fällen Beiträge innerhalb der Instanz zu verschieben.
    </li>
    <li *ngIf="config.isDemoInstance()">
      Der Anbieter ist berechtigt, die Inhalte des CMS nach eigener Wahl ohne weitere Hinweise zurückzusetzen.
    </li>
  </ul>

  <h2>Rechtswahl</h2>
  <ul>
    <li>
      Auf die vertraglichen Beziehungen zwischen dem Anbieter und dem Nutzer findet das Recht der Bundesrepublik Deutschland Anwendung.
    </li>
    <li>
      Von dieser Rechtswahl ausgenommen ist der Fall, dass der Nutzer ein Verbraucher ist. Hier gelten die zwingenden Verbraucherschutzvorschriften des Landes, in dem der Nutzer seinen gewöhnlichen Aufenthalt hat.
    </li>
  </ul>
</div>
