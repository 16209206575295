import { MediaService } from './../services/media.service';
import { Media } from './../models/Media';
import { tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ImageService } from './../services/image.service';
import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Config } from '../Config';
import { AppState } from '../current.app.state';
import { DeactivateExhibitonModal } from '../modals/exhibition/deactivate.modal.component';
import { DeleteExhibitonModal } from '../modals/exhibition/delete.modal.component';
import { Exhibition } from '../models/Exhibition';
import { AuthService } from '../services/auth.service';
import { CurrentContextService } from '../services/currentContext.service';
import { ExhibitionService } from '../services/exhibition.service';
import { CuboidService } from '../services/cuboid.service';
import { Language } from '../models/Language';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { ExhibitionThumbnail, ExhibitionHologramThumbnail, MultiLanguageThumburlValue } from '../models/ExhibitionThumbnails';
import { MultiLanguageData } from '../models/MultiLanguageData';

@Component({
	selector: 'exhibitions',
	templateUrl: './exhibitions.component.html',
	styleUrls: ['./exhibitions.component.scss']
})
export class ExhibitionsComponent implements OnInit {

	private exhibitions = new Map<string, Exhibition>();
	public editMode: string;
	public modalRef: BsModalRef;
	public exhibitionToEdit: Exhibition = null;
	public initDone = false;
	public config: Config = new Config();
	public currentExhibitionToUpload: Exhibition = null;
	public searchText = '';

	public thumbs = new Map<string, ExhibitionThumbnail[]>();
	public currentThumbs = new Map<string, ExhibitionThumbnail>();
	public loadingMedias = false;

	public currentLanguage: Language;
	public Language = Language;

	public constructor(
		private router: Router,
		private contextService: CurrentContextService,
		private exhibitionService: ExhibitionService,
		private modalService: BsModalService,
		private authService: AuthService
	) { }

	public ngOnInit(): void {
		this.contextService.setCurrentState(AppState.exhibitions);
		this.currentLanguage = Language.DE;
		this.initDone = false;
		this.loadingMedias = true;
		this.getExhibitions();
		this.exhibitionToEdit = null;
	}

	public ngOnDestroy(): void {
		this.initDone = false;
		this.exhibitions.clear();
	}

	@HostListener('document:keypress', ['$event'])
	private handleKeyboardEvent(event: KeyboardEvent): void {
		if (event.key === 'Escape') {
			if (this.editMode != null || this.editMode !== '-1') {
				this.dismissEdit();
			}
		}
	}

	public createExhibition(): void {
		const exhibition = new Exhibition();
		exhibition.title = new MultiLanguageText();

		const sub = this.exhibitionService.createExhibition(exhibition).subscribe(
			response => {
				const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
				exhibition.id = location.substring(location.lastIndexOf('/') + 1);
				this.exhibitions.set(exhibition.id, exhibition);
				this.editExhibition(exhibition);
				sub.unsubscribe();
			});
	}

	private getExhibitions(): void {
		this.contextService.setSavingContent(true);
		const sub = this.exhibitionService.getExhibitions().subscribe(
			response => {
				for (const element of response) {
					this.exhibitions.set(element.id, element);
				}
				this.contextService.setSavingContent(false);
				this.initDone = true;
				this.loadImagesAndCuboids();
				sub.unsubscribe();
			}
		);
	}

	private loadImagesAndCuboids(): void {
		const thumbnailRequests = [];
		this.exhibitions.forEach((e, eId) => {
			thumbnailRequests.push(this.exhibitionService.getExhibitionThumbnails(eId).pipe(tap(exhibitionThumbnails => {
				for (var exThumb of exhibitionThumbnails) {
					if (exThumb.type === 'hologram') {
						(exThumb as any).src = new MultiLanguageData<MultiLanguageThumburlValue>(MultiLanguageThumburlValue, (exThumb as any).src);
					}
				}
				this.thumbs.set(eId, exhibitionThumbnails);
				this.updateCurrentThumb(eId, 0);
			})));
		});
		const sub = forkJoin(thumbnailRequests).subscribe(response => {
			this.loadingMedias = false;
			sub.unsubscribe();
		});
	}

	public updateCurrentThumb(exhibitionId: string, counter: number): void {
		this.currentThumbs.set(exhibitionId, this.thumbs.get(exhibitionId)[counter]);
		let newCounter = counter + 1;
		if (this.thumbs.get(exhibitionId).length <= newCounter) {
			newCounter = 0;
		}
		let timer = Math.max(2000, Math.random() * 5000);
		setTimeout(() => {
			this.updateCurrentThumb(exhibitionId, newCounter);
		}, timer);
	}

	public openModal(modalTemplate: TemplateRef<any>): void {
		this.modalRef = this.modalService.show(modalTemplate);
	}

	public applyAllChanges(): void {
		for (const exhibition of this.exhibitions.values()) {
			const sub = this.exhibitionService.applyChanges(exhibition).subscribe(
				response => {
					const sub1 = this.exhibitionService.getExhibitionById(exhibition.id).subscribe(
						response => {
							this.exhibitions.set(exhibition.id, new Exhibition(response));
							sub1.unsubscribe();
						}
					);
					sub.unsubscribe();
				}
			);
		}
		this.modalRef.hide();
	}

	public applyToBackend(exhibition: Exhibition, modalTemplate: TemplateRef<any>): void {
		this.currentExhibitionToUpload = exhibition;
		this.modalRef = this.modalService.show(modalTemplate);
	}

	public applyChanges(): void {
		const sub = this.exhibitionService.applyChanges(this.currentExhibitionToUpload).subscribe(
			response => {
				const sub1 = this.exhibitionService.getExhibitionById(this.currentExhibitionToUpload.id).subscribe(
					response => {
						this.exhibitions.set(this.currentExhibitionToUpload.id, new Exhibition(response));
						sub1.unsubscribe();
					}
				);
				sub.unsubscribe();
			}
		);
		this.modalRef.hide();
	}

	public routeToExhibition(exhibition: Exhibition): void {
		this.router.navigate(['exhibitions/' + exhibition.id + '/images']);
		this.contextService.currentExhibition = exhibition;
	}

	public editExhibition(exhibition: Exhibition): void {
		this.editMode = exhibition.id;
		this.exhibitionToEdit = new Exhibition(exhibition);
	}

	public askForDeleteExhibition(exhibition: Exhibition): void {
		const initialState = {
			currentLanguage: this.currentLanguage,
			exhibition: exhibition
		};
		this.modalRef = this.modalService.show(DeleteExhibitonModal, { initialState });
		const sub = this.modalService.onHide.subscribe(() => {
			if (this.modalRef && this.modalRef.content && this.modalRef.content.delete) {
				this.exhibitions.delete(exhibition.id);
			}
			sub.unsubscribe();
		});
	}

	public saveExhibition(): void {
		const sub = this.exhibitionService.updateExhibition(this.exhibitionToEdit).subscribe(response => {
			if (response.status < 400) {
				this.exhibitions.set(this.exhibitionToEdit.id, this.exhibitionToEdit);
				this.exhibitionToEdit = null;
				this.editMode = '-1';
			}
			sub.unsubscribe();
		});
	}

	public dismissEdit(): void {
		this.exhibitionToEdit = null;
		this.editMode = '-1';
	}

	public getExhibitionsAsList(): Exhibition[] {
		if (this.exhibitions) {
			const temp = Array.from(this.exhibitions.values());
			temp.sort((exhibition1, exhibition2) => exhibition2.createdTime - exhibition1.createdTime);
			return temp;
		} else {
			const temp: Exhibition[] = [];
			return temp;
		}
	}

	public exhibitionsNotEmpty(): boolean {
		const values = this.getExhibitionsAsList();
		return values.length > 0;
	}

	public isAdmin(): boolean {
		const currentUser = this.authService.getUser();
		if (currentUser) {
			return currentUser.isAdmin();
		} else {
			return false;
		}
	}

	public trackByFn(index, item) {
		return item.id;
	}

	public onCheckbox(event, exhibition: Exhibition): void {
		if (exhibition.active) {
			event.preventDefault();
			const initialState = {
				exhibition: exhibition
			};
			this.modalRef = this.modalService.show(DeactivateExhibitonModal, { initialState });
		} else {
			exhibition.active = true;
			const sub = this.exhibitionService.updateExhibitionActivation(exhibition).subscribe(
				response => {
					sub.unsubscribe();
				}
			);
		}
	}

	public isActive(exhibition: Exhibition): boolean {
		return exhibition.active;
	}

	public getStyle(exhibition: Exhibition): Object {
		const style = {
			'opacity': exhibition.active ? '1' : '0.3',
		};
		return style;
	}

	public isThumbImage(value: ExhibitionThumbnail): boolean {
		return value.type === 'image';
	}
	public isThumbCuboid(value: ExhibitionThumbnail): boolean {
		return value.type === 'cuboid';
	}
	public isThumbHologram(value: ExhibitionThumbnail): boolean {
		return value.type === 'hologram';
	}

	public getHologramThumbUrl(value: ExhibitionHologramThumbnail) {
		let thumbUrl = value.src.getWithFallback(this.currentLanguage)?.thumbSrc;
		if(thumbUrl) { return thumbUrl; }
		return '../assets/3DModellPlaceholder.png';
	}

	public setLanguage(language: Language): void {
		this.currentLanguage = language;
	}

	public isLanguage(language: Language): boolean {
		return language === this.currentLanguage;
	}

	public updateLanguage(language: Language): void {
		this.currentLanguage = language;
	}

	public getLanguageValue(text: MultiLanguageText): string {
		return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
	}

	public getExportStatus(exhibition: Exhibition): number {
		if (!exhibition.exportTime || exhibition.createdTime > exhibition.exportTime) {
			return 0; // Nicht veröffentlicht
		}
		else if (exhibition.exportTime < exhibition.updatedTime) {
			return 1; // Halb veröffentlicht
		}
		else {
			return 2; // Komplett veröffentlicht
		}
	}

	public getBackgroundColor(exportStatus: number): string {
		switch (exportStatus) {
			case 0: return 'red';
			case 1: return 'yellow';
			case 2: return 'green';
		}
	}
}
