import { Pipe, PipeTransform } from '@angular/core';
import { StringService } from './services/string.service';

/**
 * The TranslatePipe is used to get the Strings from the StringService in the current Application language,
 * so it is easyer to request the strings in html
 */
@Pipe({
  name: 'translate',
  pure: true
})
export class TranslatePipe implements PipeTransform {

  /**
   * creates an instance of the TranslatePipe
   * @param stringService used to find the String that should be displayed to the user
   */
  public constructor(private stringService: StringService) { }

  /**
   * will be called when the pipe is used in html
   * will take the given stringId and search the StringService for it
   * @param stringId the Id of the String in the StringService
   * @returns the String if found, else it will also return an string when the id does not exist or the string could not be found
   */
  transform(stringId: string): string {
    if (!stringId) { return 'STRING NO ID'; }
    const string = this.stringService.get(stringId);
    if (string && string !== '') {
      return string;
    } else {
      return 'STRING NOT FOUND';
    }

  }
}
