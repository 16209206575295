import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '../current.app.state';
import { Language } from '../models/Language';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { Tour } from '../models/Tour';
import { CurrentContextService } from '../services/currentContext.service';
import { TourService } from '../services/tour.service';
import * as L from 'leaflet';
import { ToursFilterPipe } from './tours.filter.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MultiUploadToursModal } from '../modals/tours-upload/tours-multi-upload.modal.component';
import { GeoJson, MapElement } from '../models/GeoJson';
import { Config } from '../Config';
import { AuthService } from '../services/auth.service';
import { UserConfigService } from '../services/userConfig.service';

@Component({
  selector: 'aacms-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.scss']
})
export class ToursComponent implements OnInit {

  public modalRef: BsModalRef;

  public searchText: string;
  public tours: Tour[];
  public filteredTours: Tour[];
  public initDone = false;
  public currentLanguage: Language;
  private loadedTours: Map<string, boolean> = new Map<string, boolean>();
  public toursGeoJson: Map<string, any> = new Map<string, any>();
  private config: Config = new Config();

  public currentTourToUpload: Tour;

  constructor(
    private tourService: TourService,
    private router: Router,
    private contextService: CurrentContextService,
    private tourFilterPipe: ToursFilterPipe,
    private modalService: BsModalService,
    private authService: AuthService,
    private userConfigService: UserConfigService
  ) {
  }

  public ngOnInit(): void {
    this.contextService.setCurrentState(AppState.tours);
    this.currentLanguage = Language.DE;
    this.initDone = false;
    this.contextService.setSavingContent(true);
    this.getTours();
  }

  private getTours(): void {
    const sub = this.tourService.getTours().subscribe(
      loadedTours => {
        this.tours = [];
        for (const loadedTour of loadedTours) {
          const tour = new Tour(loadedTour);
          this.updateGeoJsonMap(tour);
          this.initMapOfTour(tour);
          this.tours.push(tour);
        }
        this.filteredTours = this.tourFilterPipe.transform(this.tours, this.searchText);
        this.contextService.setSavingContent(false);
        this.initDone = true;
        sub.unsubscribe();
      }
    );
  }

  public updateGeoJsonMap(tour: Tour) {
    for (const uiElement of tour.href.uiElements) {
      if (uiElement instanceof MapElement && uiElement.isMandatory === true) {
        this.toursGeoJson.set(tour.id, {geoJson: (<MapElement>uiElement).mapObject, rectangle: undefined, map: undefined});
        break;
      }
    }
  }

  public filterTours(): void {
    this.filteredTours = this.tourFilterPipe.transform(this.tours, this.searchText);
    this.reloadMaps();
  }

  public reloadMaps(): void {
    for (const tour of this.filteredTours) {
      this.initMapOfTour(tour);
    }
  }

  private createCustomIcon(env, feature, latlng) {
    let myCustomColour;
    if (feature.properties.active) {
      myCustomColour = env.userConfigService.getSync().highlightColor;
    } else {
      myCustomColour = '#a3a3a3';
    }
    const markerHtmlStyles = `
      background: ${myCustomColour};
      width: 46px;
      height: 46px;
      display: block;
      left: -22px;
      top: -31px;
      position: relative;
      border-radius: 50% 50% 50% 0;
      transform: rotate(-45deg);
      display: flex;
      justify-content: center;
      align-items: center;
    `
    const icon = L.divIcon({
      className: "leaflet-marker",
      iconAnchor: [0, 23],
      labelAnchor: [-6, 0],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}"><div class="leaflet-marker-text-ui"><div>${feature.properties.position}</div></div></span>`
    });
    return L.marker(latlng, { icon: icon });
  }

  private initMapOfTour(tour: Tour): void {
    if (this.toursGeoJson.get(tour.id) !== undefined && this.toursGeoJson.get(tour.id).map !== undefined) {
      this.toursGeoJson.get(tour.id).map.off();
      this.toursGeoJson.get(tour.id).map.remove();
    }
    setTimeout(() => {
      const map = L.map(
        'map:' + tour.id,
        {
          zoomControl: false,
          attributionControl: false,
          dragging: false,
          boxZoom: false,
          doubleClickZoom: false,
          scrollWheelZoom: false,
          touchZoom: false,
          keyboard: false
        }
      );
      const myLayerOptions = {
        pointToLayer: (feature, latlng) => this.createCustomIcon(this, feature, latlng)
      }
      if (this.toursGeoJson.get(tour.id) !== undefined && this.toursGeoJson.get(tour.id).geoJson !== undefined) {
        const geoJsonElements = L.geoJSON(this.toursGeoJson.get(tour.id).geoJson, myLayerOptions)
        geoJsonElements.addTo(map);
        let bounds;
        if (this.toursGeoJson.get(tour.id).geoJson.properties.bottomLeft.containsLonAndLat() && this.toursGeoJson.get(tour.id).geoJson.properties.topRight.containsLonAndLat()) {
          bounds = L.latLngBounds(this.toursGeoJson.get(tour.id).geoJson.properties.bottomLeft, this.toursGeoJson.get(tour.id).geoJson.properties.topRight);
          if (this.toursGeoJson.get(tour.id).rectangle !== undefined) {
            map.removeLayer(this.toursGeoJson.get(tour.id).rectangle);
          }
          setTimeout(()=> {
            this.toursGeoJson.get(tour.id).rectangle = L.rectangle(bounds, {fill: false, color: "#00ff00"}).addTo(map);
          });
        } else {
          const group = new L.featureGroup(Object.values(geoJsonElements._layers));
          if (Object.values(map._layers).length > 0) {
            bounds = group.getBounds();
          } else {
            bounds = L.latLngBounds(L.latLng(47.26543, 5.864417), L.latLng(55.14777, 15.05078));
          }
        }
        map.fitBounds(bounds);
      }
      const tiles = L.tileLayer(new Config().getMapUrl(), {
        tileSize: 256,
        minZoom: 1,
        attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
        crossOrigin: true
      });
      tiles.addTo(map);
      this.toursGeoJson.get(tour.id).map = map;
    });
  }

  public createTours(): void {
    const initialState = { };
    this.modalRef = this.modalService.show(MultiUploadToursModal,
      { class: 'modal-lg', initialState, keyboard: false, ignoreBackdropClick: true });
    const modalSubscription = this.modalService.onHide.subscribe(() => {
      this.getTours();
      modalSubscription.unsubscribe();
    });
  }

  public openModal(modalTemplate: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(modalTemplate);
  }

  public routeToExhibitions(): void {
    this.router.navigateByUrl('exhibitions');
  }

  public routeTo(tour: Tour): void {
    this.router.navigate(['tours/' + tour.id]);
  }

  public tourLoaded(tour: Tour, loaded?: boolean): boolean {
    if (loaded) {
      this.loadedTours.set(tour.id, loaded);
    }
    return !this.loadedTours.has(tour.id);
  }

  public getStyle(tour: Tour): Object {
    const style = {
      'opacity': tour.active ? '1' : '0.3',
    };
    return style;
  }

  public onCheckbox(event: Event, tour: Tour): void {
    event.stopPropagation();
    tour.active = !tour.active;
    const sub = this.tourService.updateTourActivation(tour).subscribe(
      response => {
        sub.unsubscribe();
      }
    );
  }

  public getLanguageValue(text: MultiLanguageText): string {
    return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
  }

  public isAdmin(): boolean {
    const currentUser = this.authService.getUser();
    if (currentUser) {
      return currentUser.isAdmin();
    } else {
      return false;
    }
  }

  public applyAllChanges(): void {
    this.contextService.setSavingContent(true);
    const sub = this.tourService.applyAllChanges().subscribe(
      response => {
        let map = new Map<string, number>()  ;
        for (var value in response) {
            map.set(value, response[value])  ;
        }
        for( const tour of this.tours) {
          if (map.has(tour.id)) {
            tour.exportTime = map.get(tour.id);
          }
        }
        this.contextService.setSavingContent(false);
        sub.unsubscribe();
      }
    );
    this.modalRef.hide();
  }

  public applyChanges(): void {
    this.contextService.setSavingContent(true);
    const sub = this.tourService.applyChanges(this.currentTourToUpload).subscribe(
      response => {
        this.contextService.setSavingContent(false);
        sub.unsubscribe();
      }
    );
    this.modalRef.hide();
  }

  public applyToBackend(tour: Tour, modalTemplate: TemplateRef<any>): void {
    this.currentTourToUpload = tour;
    this.modalRef = this.modalService.show(modalTemplate);
  }

  public getExportStatus(tour: Tour): number {
      if (!tour.exportTime || tour.createdTime > tour.exportTime) {
        return 0; // Nicht veröffentlicht
      }
      else if (tour.exportTime < tour.updatedTime) {
        return 1; // Halb veröffentlicht
      }
      else {
        return 2; // Komplett veröffentlicht
      }
    }

  public getBackgroundColor(exportStatus: number): string {
    switch (exportStatus) {
      case 0: return 'red';
      case 1: return 'yellow';
      case 2: return 'green';
    }
  }

}
