import { Component, Output, Input, EventEmitter } from '@angular/core';
import { Language } from '../models/Language';
import { AlertService } from '../services/alert.service';
import { AlertSimple, AlertType } from '../models/Alert';
import { StringService } from '../services/string.service';

@Component({
  selector: 'aacms-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {

  /**
   * The language enum.
   */
  public Language = Language;

  @Input() currentLanguage: Language;
  @Output() languageChanged: EventEmitter<Language> = new EventEmitter<Language>();


  constructor(
    private alertService: AlertService,
    private stringService: StringService
  ) { }

  /**
	 * is used to change the brightness of the country flag images depending on the selected flag.
	 */
  public getStyle(language: Language): Object {
    const style = {
      'filter': this.currentLanguage === language ? 'brightness(100%)' : 'brightness(50%)'
    };
    return style;
  }

  /**
   * Sets the current language to the given language.
   * and emits the new language to the parent class
   * @param language the language to be set as current language.
   */
  public setLanguage(language: Language): void {
    let languageString;
    switch (language) {
      case 0: {
        languageString = this.stringService.get('GERMAN');
        break;
      }
      case 1: {
        languageString = this.stringService.get('ENGLISH');
        break;
      }
      case 2: {
        languageString = this.stringService.get('JAPANESE');
        break;
      }
    }
    const string = this.stringService.get('LANGUAGE_SELECTED') + ' ' + languageString;
    this.alertService.alert(new AlertSimple(string, AlertType.Info));
    this.currentLanguage = language;
    this.languageChanged.emit(language);
  }

}
