import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from '../current.app.state';
import { FileDataType } from '../models/FileDataType';
import { InfoCard } from '../models/InfoCard';
import { Language } from '../models/Language';
import { Media } from '../models/Media';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { AudioElement, ElementType, ImageElement, ModelElement, VideoElement } from '../models/UiElements';
import { CurrentContextService } from '../services/currentContext.service';
import { InfoCardService } from '../services/infoCard.service';
import { MediaService } from '../services/media.service';
import { Tools } from '../Tools';
import { CubeSides } from '../models/CubeSides';
import { Config } from '../Config';
import { StringService } from '../services/string.service';
import { ContentTypes } from '../models/ContentTypes';
import { TabType } from '../models/TabType';
import { Place } from '../models/Place';
import { PlaceService } from '../services/place.service';

@Component({
  selector: 'aacms-edit-info-card-meta',
  templateUrl: './edit-info-card.component.html',
  styleUrls: ['./edit-info-card.component.scss']
})
export class EditInfoCardMetaComponent implements OnInit, OnDestroy {

  public infoCardDataMap: Map<CubeSides, FileDataType> = new Map([[CubeSides.image, null]]);

  public modalRef: BsModalRef;
  public infoCard: InfoCard;
  private originalInfoCard: InfoCard;
  private place: Place;
  public elementType = ElementType;
  public contentChanged = false;

  public contentTypes = ContentTypes;

  @ViewChild('smartphone') smartphone;
  @ViewChild('cancelModalTemplate', { static: false }) cancelModalTemplate: TemplateRef<any>;
  @ViewChild('saveModalTemplate', { static: false }) saveModalTemplate: TemplateRef<any>;

  public Language = Language;
  public appState = AppState;
  public Tools = Tools;

  public currentLanguage: Language;

  private saveContentSubject: Subscription;

  public config: Config = new Config();

  public currentTab: TabType;
  public tabType = TabType;

  public constructor(
    public contextService: CurrentContextService,
    private modalService: BsModalService,
    private infoCardService: InfoCardService,
    private router: Router,
    private route: ActivatedRoute,
    private stringService: StringService,
    private mediaService: MediaService,
    private placeService: PlaceService
  ) { }

  public ngOnInit(): void {
    this.contextService.setCurrentState(AppState.editInfoCard);
    this.currentLanguage = Language.DE;
    this.saveContentSubject = this.contextService.saveYourContent.subscribe(
      saveContent => {
        if (saveContent) {
          this.save();
        }
      }
    );
    this.getInfoCard();
    this.getPlace();
  }

  public ngOnDestroy(): void {
    this.saveContentSubject.unsubscribe();
    this.returnToInfoCard();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (this.contentChanged) ? false : true;
  }

  public askToSave(): void {
    this.modalRef = this.modalService.show(this.cancelModalTemplate, { keyboard: false, ignoreBackdropClick: true });
  }


  public discard(): void {
    this.infoCard = new InfoCard(this.originalInfoCard);
    this.smartphone.onDiscard();
    this.checkForContentChange();
    this.hideModal();
  }

  public hideModal(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  public getPlace(): void {
    const id = this.route.snapshot.paramMap.get('placeId');
    const sub = this.placeService.getPlaceById(id).subscribe(
        place => {
          this.place = new Place(place);
          sub.unsubscribe();
        },
        error => {
          const placeId = this.route.snapshot.paramMap.get('placeId');
          this.router.navigate(['places', placeId]);
          sub.unsubscribe();
        });
  }

  public getInfoCard(): void {
    const id = this.route.snapshot.paramMap.get('infoCardId');
    const sub = this.infoCardService.getInfoCardById(id).subscribe(
        infoCard => {
          this.infoCard = new InfoCard(infoCard);
          this.originalInfoCard = new InfoCard(infoCard);
          sub.unsubscribe();
        },
        error => {
          const placeId = this.route.snapshot.paramMap.get('placeId');
          this.router.navigate(['places', placeId]);
          sub.unsubscribe();
        });
  }

  public getLanguageValue(text: MultiLanguageText): string {
    return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
  }

  public checkForContentChange(): void {
    this.contentChanged = !this.infoCard.equal(this.originalInfoCard);
  }

  public save(): void {
    this.hideModal();
    this.contextService.setSaveYourContent(false);
    let saveContent = (this.contentChanged) ? true : false;
    if (saveContent) {
      this.contextService.setSavingContent(true);
    }
    if (this.contentChanged) {
      const requests: Observable<any>[] = [];
      const sub = forkJoin(requests).subscribe(
        response => {
        }, error => {
        },
        () => {
          const sub1 = this.infoCardService.updateInfoCard(this.infoCard).subscribe(
            response => {
              this.originalInfoCard = new InfoCard(this.infoCard);
              saveContent = false;
              if (!saveContent) {
                this.checkForContentChange();
                this.contextService.setSavingContent(false);
              }
              sub1.unsubscribe();
              sub.unsubscribe();
            });
        });
    }
  }

  public openModal(modalTemplate: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(modalTemplate);
  }

  public returnToInfoCard(): void {
    this.router.navigate(['places', this.route.snapshot.paramMap.get('placeId')]);
  }

  public setLanguage(language: Language): void {
    this.currentLanguage = language;
  }

  public isLanguage(language: Language): boolean {
    return language === this.currentLanguage;
  }

  public updateLanguage(language: Language): void {
    this.currentLanguage = language;
  }

  public toggleInfoCardActivation(): void {
    this.infoCard.active = !this.infoCard.active;
    this.checkForContentChange();
  }

  public toggleArInfoCardCardsActivation(): void {
    this.infoCard.cardsActive = !this.infoCard.cardsActive;
    this.checkForContentChange();
  }

  public getStringNameForContentType(type: ContentTypes): string {
    switch (type) {
      case ContentTypes.Custom: {
        return this.stringService.get('CUSTOM');
      }
      case ContentTypes.URL: {
        return this.stringService.get('URL');
      }
    }
  }


  public showContentTypesSelection(): boolean {
    return this.config.getEnabledContentTypes().length > 1;
  }

  public getMaxContentFileSize(): number {
    return new Config().getMaxImageFileSize();
  }

  public routeTo(appState: AppState): void {
    switch (appState) {
      case AppState.exhibitions:
        this.router.navigate(['exhibitions']);
        break;
      case AppState.images:
        this.router.navigate(['exhibitions', this.place.exhibitionIds[0], 'images']);
        break;
      case AppState.editPlace:
        const placeId = this.route.snapshot.paramMap.get('placeId');
        this.router.navigate(['places', placeId]);
        break;
      default:
        break;
    }
  }

  public isTabAllowed(type: ContentTypes): boolean {
    for (const contentType of this.config.getEnabledContentTypes()) {
      if (contentType === type) {
        return true;
      }
    }
    return false;
  }
}
