import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { CubeSides } from '../models/CubeSides';
import { Cuboid, ICuboidSize } from '../models/Cuboid';
import { FileDataType } from '../models/FileDataType';

@Component({
  selector: 'aacms-cuboid-quick-viewer',
  templateUrl: './cuboid-quick-viewer.component.html',
  styleUrls: ['./cuboid-quick-viewer.component.scss']
})
export class CuboidQuickViewerComponent implements AfterViewInit {

  public _cuboid: ICuboidSize;
  @Input() set cuboid(value: ICuboidSize) {
    this._cuboid = value;
    this.updateCuboidSize(this._cuboid);
  }
  @Input() top: string;
  @Input() bottom: string;
  @Input() left: string;
  @Input() right: string;
  @Input() back: string;
  @Input() front: string;

  public _imageDataMap: Map<CubeSides, FileDataType>;

  @ViewChild('sceneContainer') sceneContainer: ElementRef;
  public currentClass = '';

  public cubeSides = CubeSides;

  private maxNumberPixels;
  private cubeWidth;
  private cubeHeight;
  private cubeDepth;
  private topPx;
  private leftPx;

  constructor() { }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.setMaxNumberPixels();
    this.updateCuboidSize(this._cuboid);
  }

  ngAfterViewInit(): void {
    this.setMaxNumberPixels();
    this.updateCuboidSize(this._cuboid);
  }

  private setMaxNumberPixels(): void {
    const arr = [this.sceneContainer.nativeElement.offsetWidth, this.sceneContainer.nativeElement.offsetHeight];
    this.maxNumberPixels = Math.min.apply(Math, arr);
  }

  public updateCuboidSize(cuboid: ICuboidSize) {
    const array = [(cuboid.width) ? cuboid.width : 10, (cuboid.height)? cuboid.height : 10, (cuboid.depth) ? cuboid.depth : 10];
    const biggest = Math.max.apply(Math, array);
    this.cubeWidth = (cuboid.width) ? cuboid.width / biggest * this.maxNumberPixels : 10 / biggest * this.maxNumberPixels;
    this.cubeHeight = (cuboid.height) ? cuboid.height / biggest * this.maxNumberPixels : 10 / biggest * this.maxNumberPixels;
    this.cubeDepth = (cuboid.depth) ? cuboid.depth / biggest * this.maxNumberPixels : 10 / biggest * this.maxNumberPixels;

    this.topPx = (this.cubeHeight / 2) - (this.cubeDepth / 2);
    this.leftPx = (this.cubeWidth / 2) - (this.cubeDepth / 2);
  }

  public getSceneStyle(): Object {
    return {
      'width': this.maxNumberPixels + 'px',
      'height': this.maxNumberPixels + 'px',
      'perspective': 2 * this.maxNumberPixels + 'px'
    };
  }

  public getCubeStyle(): Object {
    return {
      'width': this.cubeWidth + 'px',
      'height': this.cubeHeight + 'px',
      'transform': 'translateZ(' + (-2 * this.cubeDepth) + 'px) rotateY(45deg) rotateX(-45deg)'
    };
  }

  public getCubeStyleFaceFront(): Object {
    return {
      'width': this.cubeWidth + 'px',
      'height': this.cubeHeight + 'px',
      'transform': 'rotateY(0deg) translateZ(' + (0.5 * this.cubeDepth) + 'px)'
    };
  }

  public getCubeStyleFaceBack(): Object {
    return {
      'width': this.cubeWidth + 'px',
      'height': this.cubeHeight + 'px',
      'transform': 'rotateY(180deg) translateZ(' + (0.5 * this.cubeDepth) + 'px)'
    };
  }

  public getCubeStyleFaceRight(): Object {
    return {
      'width': this.cubeDepth + 'px',
      'height': this.cubeHeight + 'px',
      'left': this.leftPx + 'px',
      'transform': 'rotateY(90deg) translateZ(' + (0.5 * this.cubeWidth) + 'px)'
    };
  }

  public getCubeStyleFaceLeft(): Object {
    return {
      'width': this.cubeDepth + 'px',
      'height': this.cubeHeight + 'px',
      'left': this.leftPx + 'px',
      'transform': 'rotateY(-90deg) translateZ(' + (0.5 * this.cubeWidth) + 'px)'
    };
  }

  public getCubeStyleFaceTop(): Object {
    return {
      'width': this.cubeWidth + 'px',
      'height': this.cubeDepth + 'px',
      'top': this.topPx + 'px',
      'transform': 'rotateX(90deg) translateZ(' + (0.5 * this.cubeHeight) + 'px)'
    };
  }

  public getCubeStyleFaceBottom(): Object {
    return {
      'width': this.cubeWidth + 'px',
      'height': this.cubeDepth + 'px',
      'top': this.topPx + 'px',
      'transform': 'rotateX(-90deg) translateZ(' + (0.5 * this.cubeHeight) + 'px)'
    };
  }
}
