import { AlertService } from '../services/alert.service';
import { AlertSimple, AlertType, AlertWithBoldValueInMiddle } from '../models/Alert';
import { StringService } from '../services/string.service';
import { Injectable } from '@angular/core';
import { GeoJson, GeoJsonFeature } from '../models/GeoJson';

@Injectable({
  providedIn: 'root',
})
export class GeoJsonParserService {

  constructor(
    public alertService: AlertService,
    private stringService: StringService
  ) {

  }

  public parse(input: File): Promise<GeoJson> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const result = fileReader.result.toString().trim();
        if (result.substr(0, 1) === '{') {
          try {
            const json = JSON.parse(result);
            const geoJson = this.parseGeoJsonData(json);
            geoJson.properties.filename = input.name.substr(0, input.name.lastIndexOf('.'));
            resolve(geoJson);
          } catch(e) {
              let alert;
              if (!/\d/.test((<SyntaxError>e).message) && (<SyntaxError>e).message.indexOf(' line ') < 0) {
                alert = new AlertSimple(this.stringService.get('GEOJSON_FILE_BROKEN'), AlertType.Error);
              } else {
                let value = (<SyntaxError>e).message.split('line ').pop();
                value = value.substr(0, value.indexOf(' '));
                alert = new AlertWithBoldValueInMiddle(this.stringService.get('GEOJSON_FILE_NOT_PARSABLE1'), value, this.stringService.get('GEOJSON_FILE_NOT_PARSABLE2'), AlertType.Error);
              }
              reject(
                this.alertService.alert(alert) 
              );
          }
        } else {
          reject(
            this.alertService.alert(new AlertSimple(this.stringService.get('GEOJSON_FILE_BROKEN'), AlertType.Error))
          );
        }
      };
      fileReader.onerror = () => {
        this.alertService.alert(new AlertSimple(this.stringService.get('GEOJSON_FILE_BROKEN'), AlertType.Error));
      };
      fileReader.readAsText(input);
    });
  }

  private parseGeoJsonData(json: any): GeoJson {
    const geoJson = new GeoJson(json);
    let counter = 1;
    let toRemove: GeoJsonFeature[] = [];
    for(const feature of geoJson.features) {
      if (!feature.geometry.coordinatesValid()) {
        let index = geoJson.features.indexOf(feature) + 1;
        let type = feature.geometry.type.valueOf();
        let coordinates = JSON.stringify(feature.geometry.coordinates);
        const value = this.stringService.get('POSITION') + ': ' + index + ', ' + this.stringService.get('FEATURE_TYPE') + ': ' + type + ', ' + this.stringService.get('COORDINATES') + ': ' + coordinates;  
        this.alertService.alert(new AlertWithBoldValueInMiddle(this.stringService.get('GEOJSON_FEATURE_COORDINATES_BROKEN1'), value, this.stringService.get('GEOJSON_FEATURE_COORDINATES_BROKEN2'), AlertType.Warning));
        toRemove.push(feature);
      } else {
        if (feature.geometry.type === 'Point') {
          feature.properties.position = counter;
          counter++;
        }
      }
    }
    for (const feature of toRemove) {
      geoJson.features.splice(geoJson.features.indexOf(feature), 1);
    }
    return geoJson;
  }
}
