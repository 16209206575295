import {
  Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, Renderer2,
  OnDestroy, Input, ComponentRef, ComponentFactory
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Poi } from '../models/Poi';
import { PoiService } from '../services/poi.service';
import { POIComponent } from '../poi/poi.component';
import { Subscription, from } from 'rxjs';
import { AlertService } from '../services/alert.service';
import { StringService } from '../services/string.service';
import { Config } from '../Config';
import { FileDataType } from '../models/FileDataType';
import { PoiManagerService } from '../services/poi-manager.service';
import { CurrentContextService } from '../services/currentContext.service';
import { AppState } from '../current.app.state';
import { CubeSides } from '../models/CubeSides';
import { DomSanitizer } from '@angular/platform-browser';
import { Language } from '../models/Language';
import { Tools } from '../Tools';

/**
 * The EditComponent will communicate with the EditPoiComponent and the
 * EditImageComponent to let them interact
 */
@Component({
  selector: 'aacms-poi-layer',
  templateUrl: './poi-layer.component.html',
  styleUrls: ['./poi-layer.component.scss']
})
export class PoiLayerComponent implements OnDestroy {

  /**
	 * Watches all current present Points of Interest of the current Image
	 */
  @ViewChild('poiLayer', { read: ViewContainerRef, static: true }) poiLayer: ViewContainerRef;

  /**
	 * controls the image view on the left side of the page
	 */
  @ViewChild('imageView') imageView;

  @ViewChild('imageContainer') imageContainer;

  /**
	 * will be used to create a new poi when the user clicks on an empty space in the image
	 */
  @ViewChild('newPoint') newPoint;

  /**
	 * is the corresponding popover to the newPoint ViewChild
	 */
  @ViewChild('pop') pop;

  /**
	 * stores all poi that are present in the image
	 */
  private poimap: Map<string, ComponentRef<POIComponent>> = new Map<string, ComponentRef<POIComponent>>();

  /**
	 * the current relative x position of the new poi
	 */
  private newPoiPageX: number = null;

  /**
	 * the current relative y position of the new poi
	 */
  private newPoiPageY: number = null;

  private poiSubjects: Subscription[] = [];

  /**
	 * the current Application Config to set the tooltipdelays
	 */
  public config: Config = new Config();

  /**
	 * This variable will contain the base64-encoded image to use as source for the img tag.
	 */
  @Input() imageData: FileDataType = null;

  @Input() imageId: string;

  @Input() mapId: CubeSides;

  _currentLanguage: Language;
  @Input() set currentLanguage(value: Language) {
    this._currentLanguage = value;
  }

  private pmsEventEmitterSubscription: Subscription;
  /**
	 * @param poiService the poi service to request the pois of the image
	 * @param componentFactoryResolver creates the pois to display them on the image
	 * @param pms
	 * @param renderer is used to set the style of the page
	 * @param alertService informes the user of changes at the image or the pois
	 * @param stringService gets all texts in the correct languages
   * @param httpClient
	 */
  public constructor(
    private poiService: PoiService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public pms: PoiManagerService,
    private renderer: Renderer2,
    public alertService: AlertService,
    public stringService: StringService,
    public httpClient: HttpClient,
    public contextService: CurrentContextService,
    private sanitizer: DomSanitizer
  ) {
    this.pmsEventEmitterSubscription = pms.eventEmitter.subscribe(
      value => {
        if (value === 'reloadPoiLayer') {
          this.reloadPoiLayer();
        }
      }
    );
  }

  /**
	 * deletes the subscription of the currentSelectedPoi
	 */
  public ngOnDestroy(): void {
    for (const selection of this.poiSubjects) {
      selection.unsubscribe();
    }
    this.pmsEventEmitterSubscription.unsubscribe();
  }

  /**
	 * will be triggered after the points of interest were acquired
	 * will set the poiLayer to the new Points of Interest
	 * @param pois the new Pois that should be set
	 */
  public reloadPoiLayer(): void {
    if (this.poiLayer && this.imageId) {
      this.poiLayer.clear();
      for (const poi of this.pms.pois) {
        if (this.mapId === poi.imageSide) {
          this.createPOIComponent(poi);
        }
      }
    }
  }

  /**
	 * will be called after a new poi was created or received from the backend
	 * displays a new Poi on the page (white point)
	 * @param poi the poi that should be displayed on the page
	 */
  private createPOIComponent(poi: Poi): void {
    const componentFactory: ComponentFactory<POIComponent> = this.componentFactoryResolver.resolveComponentFactory(POIComponent);
    const poiComponentRef: ComponentRef<POIComponent> = this.poiLayer.createComponent(componentFactory);
    const poiComponent: POIComponent = poiComponentRef.instance;
    poiComponent.poi = poi;
    poiComponent.isActivePoi = (poi === this.pms.selectedPoi);
    poiComponent.imageView = this.imageView;
    poiComponent.currentLanguage = this._currentLanguage;

    this.poimap.set(poi.id, poiComponentRef);
    this.poiSubjects.push(poiComponent.getPopoverActive().subscribe(value => {
      if (value) {
        this.cancelNewPoi();
      }
    }));
  }

  /**
	 * triggers when the user clicks on an empty space of the image
	 * will set a green point on the image on opens the corresponding popup
	 * @param event the clicked position on the image
	 */
  public showNewPoint(event: any): void {
    if (!this.imageId) { return; }
    this.cancelNewPoi();
    const size = 45;
    const offset = size / 2;
    this.renderer.setStyle(this.newPoint.nativeElement, 'display', 'block');
    this.renderer.setStyle(this.newPoint.nativeElement, 'width', size.toString() + 'px');
    this.renderer.setStyle(this.newPoint.nativeElement, 'height', size.toString() + 'px');
    this.renderer.setStyle(this.newPoint.nativeElement, 'left', (event.offsetX - offset).toString() + 'px');
    this.renderer.setStyle(this.newPoint.nativeElement, 'top', (event.offsetY - offset).toString() + 'px');
    this.newPoiPageX = event.pageX;
    this.newPoiPageY = event.pageY;
    this.pop.show();
    this.newPoint.nativeElement.focus();
  }

  /**
	 * will be called after the user clicks on create Poi in the newPoiPopUp
	 * creates a new Point of Interest and starts the edit mode of that poi
	 * @param event the position of the new poi
	 */
  public addNewPoi(event: any): void {
    if (this.pop) { this.pop.hide(); }
    const imageViewPosition = this.imageView.nativeElement.getBoundingClientRect();
    const percentageWidth = 100 / imageViewPosition.width;
    const percentageHeight = 100 / imageViewPosition.height;
    const x = (this.newPoiPageX - imageViewPosition.left - 5) * percentageWidth;
    const y = (this.newPoiPageY - imageViewPosition.top - 5) * percentageHeight;

    const newPoi: Poi = new Poi();
    newPoi.parentImageId = this.imageId;
    newPoi.x = x;
    newPoi.y = y;
    newPoi.position = this.pms.pois.length;
    newPoi.imageSide = this.mapId;
    this.contextService.setSavingContent(true);
    this.pms.createNewPoi(newPoi).then((newPoi) => {
      this.contextService.setSavingContent(false);
      this.createPOIComponent(newPoi);
      this.renderer.setStyle(this.newPoint.nativeElement, 'display', 'none');
    });
  }

  /**
	 * will be used if the user aborts the creation of the poi
	 * closes the popUp and removes the green point from the page
	 * @param event must be stopped if there are underlying processes
	 */
  public cancelNewPoi(event?: any): void {
    if (event) { event.stopPropagation(); }
    if (this.pop) { this.pop.hide(); }
    if (this.newPoint) { this.renderer.setStyle(this.newPoint.nativeElement, 'display', 'none'); }
  }

  public getBorderStyle(): Object {
    let style;
    if (!this.pms.selectedPoi &&
      (this.contextService.getCurrentState() === AppState.editImage ||
        this.contextService.getCurrentState() === AppState.editImageMeta)) {
      style = {
        'border': '2px solid #87ceeb',
        'border-radius': '4px'
      };
    } else {
      style = {
        'border': '2px solid inherit',
        'border-radius': '0px'
      };
    }
    return style;
  }

  public isSelectedPoi3DModel(): boolean {
    let selectedPoi = this.poimap.get(this.pms.selectedPoi.id);
    let langTexture = selectedPoi?.instance.textureData?.get(this._currentLanguage);
    if(langTexture && langTexture.media) {
      return Tools.isMediaModel(langTexture.media);
    }
    return false;
  }
}
