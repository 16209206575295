import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable, of } from "rxjs";
import { tap, share } from "rxjs/operators";

import { HologramCard } from "../models/HologramCard";
import { Config } from "../Config";

import { AlertService } from "./alert.service";
import { StringService } from "./string.service";
import { AlertWithBoldValueInMiddle, AlertType } from "../models/Alert";
import { CurrentContextService } from "./currentContext.service";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
  observe: "response" as "body",
};

@Injectable({
  providedIn: "root",
})
export class HologramCardService {
  private baseUrl = new Config().GetBaseUrl();

  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService,
    private contextService: CurrentContextService
  ) {}

  public getHologramCardsByHologramId(parentHologramId: string): Observable<HologramCard[]> {
    return this.http
      .get<HologramCard[]>(this.getHologramCardUrl(`?parentHologramId=${parentHologramId}`))
      .pipe(
        tap(
          (response) => {
            return response;
          },
          (error) => {
            this.handleError<HologramCard[]>("getHologramCardsByHologramId", error);
          }
        ),
        share()
      );
  }

  public getHologramCardById(id: string): Observable<HologramCard> {
    return this.http.get<HologramCard>(this.getHologramCardUrl(`${id}`)).pipe(
      tap(
        (response) => {
          return response;
        },
        (error) => {
          this.alertService.alert(
            new AlertWithBoldValueInMiddle(
              this.stringService.get("HOLOGRAMCARD_NOT_FOUND1"),
              id,
              this.stringService.get("HOLOGRAMCARD_NOT_FOUND2"),
              AlertType.Error
            ),
            true
          );
          this.handleError<HologramCard>("getHologramCardById", error);
        }
      ),
      share()
    );
  }

  public createHologramCard(infoCard: HologramCard): Observable<any> {
    infoCard.createdTime = new Date().getTime();
    infoCard.updatedTime = infoCard.createdTime;
    return this.http.post<any>(this.getHologramCardUrl(), infoCard, httpOptions).pipe(
      tap(
        (response) => {
          const location = response.headers.get("Location")
            ? response.headers.get("Location")
            : response.headers.get("location");
          const id = location.substring(location.lastIndexOf("/") + 1);
          // infoCard.id = id;
          this.alertService.alert(
            new AlertWithBoldValueInMiddle(
              this.stringService.get("HOLOGRAMCARD_CREATED2"),
              id,
              this.stringService.get("HOLOGRAMCARD_CREATED3"),
              AlertType.Success
            ),
            true
          );
        },
        (error) => {
          this.handleError<HologramCard>("createHologramCard", error);
        }
      ),
      share()
    );
  }

  public updateHologramCard(infoCard: HologramCard): Observable<any> {
    infoCard.updatedTime = new Date().getTime();
    const infoCardCopy = new HologramCard(infoCard);
    for (const uiElement of infoCardCopy.uiElements) {
      uiElement.cleanForUpLoad();
    }
    return this.http.put(this.getHologramCardUrl(`${infoCard.id}`), infoCardCopy, httpOptions).pipe(
      tap(
        (response) => {
          if (this.contextService.getInCurrentAppLanguage(infoCard.title) !== "") {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get("HOLOGRAMCARD_UPDATED1"),
                this.contextService.getInCurrentAppLanguage(infoCard.title),
                this.stringService.get("HOLOGRAMCARD_UPDATED3"),
                AlertType.Success
              ),
              true
            );
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get("HOLOGRAMCARD_UPDATED2"),
                infoCard.id,
                this.stringService.get("HOLOGRAMCARD_UPDATED3"),
                AlertType.Success
              ),
              true
            );
          }
        },
        (error) => {
          this.handleError<HologramCard>("updateHologramCard", error);
        }
      ),
      share()
    );
  }

  public deleteHologramCard(infoCard: HologramCard | string): Observable<any> {
    const id = typeof infoCard === "string" ? infoCard : infoCard.id;

    return this.http.delete(this.getHologramCardUrl(`${id}`), httpOptions).pipe(
      tap(
        (response) => {
          if (
            typeof infoCard === "object" &&
            this.contextService.getInCurrentAppLanguage(infoCard.title) !== ""
          ) {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get("HOLOGRAMCARD_DELETED1"),
                this.contextService.getInCurrentAppLanguage(infoCard.title),
                this.stringService.get("HOLOGRAMCARD_DELETED3"),
                AlertType.Info
              ),
              true
            );
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get("HOLOGRAMCARD_DELETED2"),
                id,
                this.stringService.get("HOLOGRAMCARD_DELETED3"),
                AlertType.Info
              ),
              true
            );
          }
        },
        (error) => {
          this.handleError<HologramCard>("deleteHologramCard", error);
        }
      ),
      share()
    );
  }

  private handleError<T>(
    operation = "operation",
    error: HttpErrorResponse,
    result?: T
  ): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private logError(value: any): void {
    console.error(value);
  }

  private getHologramCardUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith("/")) {
      url = `${url}/`;
    }
    url = `${url}hologramcards/`;
    if (extension) {
      if (!(extension.indexOf("?") >= 0)) {
        if (!extension.endsWith("/")) {
          extension = `${extension}/`;
        }
      }
      url = `${url}${extension}`;
    }
    return url;
  }
}
