import { AppMenu, MenuStyle } from './../models/AppMenu';
import { Component, OnInit, TemplateRef, HostListener, OnDestroy } from '@angular/core';
import { Language } from '../models/Language';
import { CurrentContextService } from '../services/currentContext.service';
import { AppState } from '../current.app.state';
import { Menu, MenuType, MenuEntry, ListMenu, GridMenu, ARMenu, TourMenu } from '../models/MenuElements';
import { ElementType } from '../models/UiElements';
import { Config } from '../Config';
import { MediaService } from '../services/media.service';
import { Tools } from '../Tools';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { FileDataType } from '../models/FileDataType';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppMenuService } from '../services/app-menu.service';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { AuthService } from '../services/auth.service';
import { ContentTypes } from '../models/ContentTypes';
import { StringService } from '../services/string.service';
import { LockService } from '../services/lock.service';
import { Router } from '@angular/router';
import { TabType } from '../models/TabType';
import { TourService } from '../services/tour.service';
import { Tour } from '../models/Tour';
import { MultiLanguageFileDataType } from '../models/MultiLanguageFileDataType';
import { Media } from '../models/Media';
import { MultiLanguageMediaId, MultiLanguageMediaIdValue } from '../models/MultiLanguageMediaId';
import { MultilangMediaLoader } from '../../helper-classes/MultilangMediaLoader';
import { MediaData } from '../../helper-classes/MediaLoader';
import { UserConfigService } from '../services/userConfig.service';

@Component({
	selector: 'app-menu',
	templateUrl: './app-menu.component.html',
	styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements OnInit, OnDestroy {

	public Language = Language;
	public contentTypes = ContentTypes;
	public MenuType = MenuType;
	public ElementType = ElementType;
	public config = new Config();

	public currentLanguage: Language;
	public modalRef: BsModalRef;

	public MenuStyle = MenuStyle;
	public originalAppMenu: AppMenu;
	public fullAppMenu: AppMenu;
	public parentAppMenu: AppMenu;
	public currentAppMenu: AppMenu;

	public contentChanged: boolean;

	private appMenuDepthCounter = 0;

	public mediaLoader: MultilangMediaLoader<MultiLanguageMediaIdValue>;

	private smartphoneMultiplier = 2.7;
	private smartphoneHeight = 16;
	private smartphoneWidth = 8;
	private portraitMode = true;
	public currentSelectedResolution = 'big';

	private saveContentSubject: Subscription;

	public menuVisible = true;

	public currentTab: TabType;
	public tabType = TabType;

	public tours: Map<string, Tour> = new Map();

	public constructor(
		private appMenuService: AppMenuService,
		private contextService: CurrentContextService,
		private mediaService: MediaService,
		private sanitizer: DomSanitizer,
		private modalService: BsModalService,
		private authService: AuthService,
		private stringService: StringService,
		private lockService: LockService,
		private tourService: TourService,
		private router: Router,
		public userConfigService: UserConfigService,
	) {
		this.mediaLoader = new MultilangMediaLoader<MultiLanguageMediaIdValue>(this.mediaService, this.sanitizer);
	}

	public ngOnInit(): void {
		this.contextService.setCurrentState(AppState.appMenu);
		this.currentLanguage = Language.DE;
		document.documentElement.style.setProperty('--highlightColor', this.userConfigService.getSync().highlightColor);
		document.documentElement.style.setProperty('--highlightColorT', this.userConfigService.getSync().highlightColor + '60');
		this.getMenu();
		this.saveContentSubject = this.contextService.saveYourContent.subscribe(
			async saveContent => {
				if (saveContent) {
					if (this.contentChanged === true) {
						this.contentChanged = false;
						await this.onSave();
					}
				}
			}
		);
		window.onbeforeunload = () => this.ngOnDestroy();
	}

	public ngOnDestroy() {
		if (this.lockService.currentLock !== undefined) {
			this.lockService.deleteLock(this.lockService.currentLock.lockId).subscribe();
		}
		this.saveContentSubject.unsubscribe();
	}

	@HostListener('window:beforeunload')
	canDeactivate(): Observable<boolean> | boolean {
		return (this.contentChanged) ? false : true;
	}

	private getMenu(): void {
		this.contextService.setSavingContent(true);
		const sub = this.appMenuService.getAppMenu().subscribe(menu => {
			this.contextService.setSavingContent(false);
			if (menu.type === undefined) {
				return;
			}
			let rootMenu;
			if (menu.type === MenuType.LIST) {
				rootMenu = new ListMenu(<ListMenu>menu);
			} else if (menu.type === MenuType.GRID) {
				rootMenu = new GridMenu(<GridMenu>menu);
			}
			this.originalAppMenu = new AppMenu(rootMenu);
			if (!this.menuContainsStyle(rootMenu, MenuStyle.TOURS)) {
				const menuEntry = new MenuEntry();
				menuEntry.title.de = 'Stadtführungen';
				menuEntry.title.gb = 'City tours';
				menuEntry.title.jp = 'シティツアー';
				menuEntry.position = rootMenu.elements.length;
				menuEntry.isMandatory = true;
				const tourMenu = new TourMenu();
				menuEntry.href = tourMenu;
				rootMenu.elements.push(menuEntry);
			}
			if (!this.menuContainsStyle(rootMenu, MenuStyle.AR)) {
				const menuEntry = new MenuEntry();
				menuEntry.title.de = 'Selbstständig Erkunden';
				menuEntry.title.gb = 'Explore on your own';
				menuEntry.title.jp = '独立して探索する';
				menuEntry.position = rootMenu.elements.length;
				menuEntry.isMandatory = true;
				const arMenu = new ARMenu();
				menuEntry.href = arMenu;
				rootMenu.elements.push(menuEntry);
			}
			this.fullAppMenu = new AppMenu(rootMenu);
			this.initTourMenuElements();
			this.currentAppMenu = this.fullAppMenu;
			this.onItemSelect();
			this.checkForChange();
			sub.unsubscribe();
		});
	}

	private initTourMenuElements(): void {
		let tourMenu: AppMenu;
		for (const menu of this.fullAppMenu.childElements) {
			if (menu.menuStyle === MenuStyle.TOURS) {
				tourMenu = menu;
			}
		}
		let originalTourMenu: AppMenu;
		for (const menu of this.originalAppMenu.childElements) {
			if (menu.menuStyle === MenuStyle.TOURS) {
				originalTourMenu = menu;
			}
		}
		const sub = this.tourService.getTours().subscribe(
			tours => {
				this.tours.clear();
				for (const tour of tours) {
					this.tours.set(tour.id, new Tour(tour));
					const menu = new AppMenu();
					menu.menuStyle = MenuStyle.TOUR_ENTRY;
					menu.isMandatory = true;
					menu.title = new MultiLanguageText(tour.title);
					menu.mediaIdEntry = new MultiLanguageMediaId(tour.menuThumb);
					if (tour.position !== undefined) {
						menu.position = tour.position;
					} else {
						menu.position = tourMenu.childElements.length;
					}
					menu.menuId = tour.id;
					if (tourMenu !== undefined) {
						tourMenu.childElements.push(new AppMenu(menu));
					}
					if (originalTourMenu !== undefined) {
						originalTourMenu.childElements.push(new AppMenu(menu));
					}
				}
				if (originalTourMenu !== undefined) {
					originalTourMenu.childElements.sort((a, b) => a.position - b.position);
				}
				tourMenu.childElements.sort((a, b) => a.position - b.position);
				sub.unsubscribe();
			}
		);
	}

	private menuContainsStyle(menu: Menu, style: MenuStyle): boolean {
		for (const element of menu.elements) {
			if (element.href && element.href.type !== undefined && element.href.type === style) {
				return true;
			}
		}
		return false;
	}

	public getStyle(language: Language): Object {
		const style = {
			'filter': this.currentLanguage === language ? 'brightness(100%)' : 'brightness(50%)'
		};
		return style;
	}

	public setLanguage(language: Language): void {
		this.currentLanguage = language;
	}

	public isLanguage(language: Language): boolean {
		return language === this.currentLanguage;
	}

	public updateLanguage(language: Language): void {
		this.currentLanguage = language;
	}

	public getLanguageValue(text: MultiLanguageText): string {
		return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
	}

	public checkForChange(): void {
		if (this.fullAppMenu === undefined && this.originalAppMenu === undefined) {
			this.contentChanged = false;
			return;
		}
		if (
			this.fullAppMenu === undefined && this.originalAppMenu !== undefined ||
			this.fullAppMenu !== undefined && this.originalAppMenu === undefined
		) {
			this.contentChanged = true;
			return;
		}
		this.contentChanged = !this.fullAppMenu.equal(this.originalAppMenu);
	}

	public addMenuEntry(style: MenuStyle, menuEntry?: AppMenu): void {
		const newAppMenu = new AppMenu(null);
		newAppMenu.menuStyle = style;
		if (menuEntry) {
			this.appMenuDepthCounter = 1;
			this.findDepthOfMenu(menuEntry, this.fullAppMenu, 1);
			newAppMenu.depth = this.appMenuDepthCounter;
			menuEntry.childElements.push(newAppMenu);
			newAppMenu.position = menuEntry.childElements.length - 1;
			this.onItemSelect(newAppMenu);
			this.checkForChange();
		} else {
			newAppMenu.depth = 0;
			newAppMenu.position = 0;
			this.fullAppMenu = newAppMenu;
			this.currentAppMenu = newAppMenu;
			this.onItemSelect(newAppMenu);
			this.checkForChange();
		}
	}

	private findParentMenuFor(searchFor: AppMenu, searchIn: AppMenu = this.fullAppMenu) : AppMenu | null {
		if(searchIn === searchFor) { return null; } // no parent
		for(const childMenu of searchIn.childElements) {
			if(childMenu == searchFor) { return searchIn; }
			// tree recursion
			let tmp = this.findParentMenuFor(searchFor, childMenu);
			if(tmp != null) { return tmp; }
		}
		return null;
	}

	public findDepthOfMenu(menuEntry: AppMenu, currentMenuLevel: AppMenu, depth: number): void {
		if (currentMenuLevel !== menuEntry) {
			depth++;
			for (const childMenu of currentMenuLevel.childElements) {
				this.findDepthOfMenu(menuEntry, childMenu, depth);
			}
		} else {
			this.appMenuDepthCounter = depth;
		}
	}

	private isSubmenu(menuEntry: AppMenu) : boolean {
		return (menuEntry != null && (menuEntry.menuStyle == MenuStyle.GRID || menuEntry.menuStyle == MenuStyle.LIST));
	}

	public canMoveMenuEntryUp() : boolean {
		return this.moveMenuEntryUp(true);
	}
	public moveMenuEntryUp(dryRun: boolean = false): boolean {
		if(!this.parentAppMenu) { return false; }
		// make sure positions are updated (they seem to be 1-based sometimes oO)
		this.updatePosition(this.parentAppMenu);

		if (this.currentAppMenu.position > 0) { // move intra-menu
			if(!dryRun) {
				const temp = this.parentAppMenu.childElements[this.currentAppMenu.position - 1];
				if(this.isSubmenu(temp)) { // The element above us is a menu, move into it, instead of over it
					temp.childElements.push(this.currentAppMenu);
					this.parentAppMenu.childElements.splice(this.currentAppMenu.position, 1);
					this.updatePosition(temp);
					this.parentAppMenu = temp;
				} else {
					this.parentAppMenu.childElements[this.currentAppMenu.position - 1] = this.parentAppMenu.childElements[this.currentAppMenu.position];
					this.parentAppMenu.childElements[this.currentAppMenu.position] = temp;
				}
				this.updatePosition(this.parentAppMenu);
			}
			return true;
		} else { // move inter-menu
			if(!this.isSubmenu(this.parentAppMenu)) { return false; } // do not move out of special menus
			let parent = this.findParentMenuFor(this.parentAppMenu);
			this.updatePosition(parent);
			if(this.isSubmenu(parent)) {
				if(!dryRun) {
					// move before our current parentMenu within the new upper parent
					const insertIdx = this.parentAppMenu.position;
					parent.childElements.splice(insertIdx, 0, this.currentAppMenu);
					// remove from current parent
					this.parentAppMenu.childElements.splice(this.currentAppMenu.position, 1);
					this.updatePosition(this.parentAppMenu);
					this.updatePosition(parent);
					this.parentAppMenu = parent;
				}
				return true;
			}
		}
		this.checkForChange();
		return false;
	}

	public canMoveMenuEntryDown() : boolean {
		return this.moveMenuEntryDown(true);
	}
	public moveMenuEntryDown(dryRun: boolean = false): boolean {
		if(!this.parentAppMenu) { return false; }
		// make sure positions are updated (they seem to be 1-based sometimes oO)
		this.updatePosition(this.parentAppMenu);

		if (this.currentAppMenu.position < this.parentAppMenu.childElements.length - 1) {
			if(!dryRun) {
				const temp = this.parentAppMenu.childElements[this.currentAppMenu.position + 1];
				if(this.isSubmenu(temp)) { // the element below us is a menu, insert us as its first child
					temp.childElements.splice(0, 0, this.currentAppMenu);
					this.parentAppMenu.childElements.splice(this.currentAppMenu.position, 1);
					this.updatePosition(temp);
					this.parentAppMenu = temp;
				} else {
					this.parentAppMenu.childElements[this.currentAppMenu.position + 1] = this.parentAppMenu.childElements[this.currentAppMenu.position];
					this.parentAppMenu.childElements[this.currentAppMenu.position] = temp;
				}
				this.updatePosition(this.parentAppMenu);
			}
			return true;
		} else { // move inter-menu
			if(!this.isSubmenu(this.parentAppMenu)) { return false; } // don't move out of special menus
			let parent = this.findParentMenuFor(this.parentAppMenu);
			if(this.isSubmenu(parent)) {
				if(!dryRun) {
					// move below our current parentMenu within the new upper parent
					const insertIdx = this.parentAppMenu.position + 1;
					parent.childElements.splice(insertIdx, 0, this.currentAppMenu);
					// remove from current parent
					this.parentAppMenu.childElements.splice(this.currentAppMenu.position, 1);
					this.updatePosition(this.parentAppMenu);
					this.updatePosition(parent);
					this.parentAppMenu = parent;
				}
				return true;
			}
		}
		return false;
	}

	public deleteMenuEntry(): void {
		if (this.currentAppMenu === this.fullAppMenu) {
			this.fullAppMenu = undefined;
			this.currentAppMenu = undefined;
			this.onItemSelect();
		} else {
			this.parentAppMenu.childElements = this.parentAppMenu.childElements.filter(element => !element.equal(this.currentAppMenu));
			this.updatePosition(this.parentAppMenu);
			this.onItemSelect(this.parentAppMenu);
		}
		this.checkForChange();
	}

	private updatePosition(parent: AppMenu): void {
		if(!parent) { return; }
		for (let i = 0; i < parent.childElements.length; i++) {
			parent.childElements[i].position = i;
		}
		this.checkForChange();
	}

	public onItemSelect(appMenu?: AppMenu): void {
		if (appMenu && appMenu !== this.fullAppMenu) {
			this.setParentAppMenu(this.fullAppMenu, appMenu);
		} else {
			this.parentAppMenu = undefined;
			this.currentAppMenu = this.fullAppMenu;
		}
		this.menuVisible = false;
		setTimeout(() => {
			this.menuVisible = true;
		}, 1);
	}

	private setParentAppMenu(appMenu: AppMenu, searchedMenu: AppMenu) {
		for (const childMenu of appMenu.childElements) {
			if (childMenu === searchedMenu) {
				this.parentAppMenu = appMenu;
				this.currentAppMenu = childMenu;
				break;
			} else {
				this.setParentAppMenu(childMenu, searchedMenu);
			}
		}
	}

	public onNavigateBack(): void {
		this.onItemSelect(this.parentAppMenu);
	}

	public getSelectedStyle(menuEntry: AppMenu): Object {
		const style = {
			'font-weight': (this.currentAppMenu && this.currentAppMenu === menuEntry) ? 'bold' : 'normal'
		};
		return style;
	}

	private createImageUrl(file: File): SafeUrl {
		const urlCreator = window.URL;
		const url = this.sanitizer.bypassSecurityTrustUrl(
			urlCreator.createObjectURL(file)
		);
		return url;
	}

	public async onSave(): Promise<void> {
		this.contextService.setSaveYourContent(false);
		this.contextService.setSavingContent(true);
		const requests: Observable<any>[] = [];
		await this.saveTours();
		const sub = forkJoin(requests).subscribe(
			response => {

			}, error => {

			},
			() => {
				let menu;
				if (this.fullAppMenu) {
					menu = this.fullAppMenu.getAsMenu();
				}
				const sub1 = this.appMenuService.updateAppMenu(menu).subscribe(
					response => {
						if (this.fullAppMenu === undefined) {
							this.originalAppMenu = undefined;
						} else {
							this.originalAppMenu = new AppMenu(this.fullAppMenu);
						}
						this.contextService.setSavingContent(false);
						this.checkForChange();
						sub1.unsubscribe();
						sub.unsubscribe();
					}
				);
			}
		);
	}

	public async saveTours(): Promise<void> {
		if (this.fullAppMenu !== undefined) {
			let tourMenu: AppMenu;
			for (const menu of this.fullAppMenu.childElements) {
				if (menu.menuStyle === MenuStyle.TOURS) {
					tourMenu = menu;
				}
			}
			if (tourMenu !== undefined) {
				for (const menu of tourMenu.childElements) {
					const tour = this.tours.get(menu.menuId);
					tour.position = menu.position;
					tour.menuThumb = new MultiLanguageMediaId(menu.mediaIdEntry);
					await this.tourService.updateTour(tour).pipe(
						tap(response => { })
					).toPromise();
				}
			}
		}
	}

	public onDiscard(): void {
		if (this.originalAppMenu === undefined) {
			this.fullAppMenu = undefined;
			this.currentAppMenu = undefined;
		} else {
			this.fullAppMenu = new AppMenu(this.originalAppMenu);
			this.currentAppMenu = this.fullAppMenu;
			this.onItemSelect(this.currentAppMenu);
		}
		this.checkForChange();
		this.modalRef.hide();
	}

	public onPublish(): void {
		const sub = this.appMenuService.publishMenu().subscribe(
			response => {
				sub.unsubscribe();
			}
		);
		this.modalRef.hide();
	}

	public routeToTour(menuEntry: AppMenu): void {
		this.router.navigate(["tours/" + menuEntry.menuId]);
	}

	public openModal(modalTemplate: TemplateRef<any>): void {
		this.modalRef = this.modalService.show(modalTemplate);
	}

	public rotateSmartphone(): void {
		this.portraitMode = !this.portraitMode;
	}

	public changeSmartphoneStyle(height, width): void {
		this.smartphoneHeight = height;
		this.smartphoneWidth = width;
	}

	public getSmartphoneStyle() {
		const width = (this.smartphoneWidth * this.smartphoneMultiplier) + 'rem';
		const height = (this.smartphoneHeight * this.smartphoneMultiplier) + 'rem';
		const portraitBorder = '20px 10px 40px 10px';
		const landscapeBorder = '10px 40px 10px 20px';

		const smartphoneStyle = {
			'height': this.portraitMode ? height : width,
			'max-width': this.portraitMode ? width : height,
			'min-width': this.portraitMode ? width : height,
			'border-width': this.portraitMode ? portraitBorder : landscapeBorder
		};

		return smartphoneStyle;
	}

	public getListMenuHeaderImageStyle() {
		const width = (this.smartphoneWidth * this.smartphoneMultiplier);
		const height = (this.smartphoneHeight * this.smartphoneMultiplier);
		const style = {
			'height': this.portraitMode ? height * 0.33 + 'rem' : width * 0.33 + 'rem'
		};
		return style;
	}

	public isMediaLoaded(mediaData: MultiLanguageMediaId) : boolean {
		if(!this.mediaLoader.isLoadedWithFallback(mediaData, this.currentLanguage)) { return false; }
		return !this.mediaLoader.getWithFallback(mediaData, this.currentLanguage)?.isDownloadRunning();
	}
	public getMediaDataForId(mediaData: MultiLanguageMediaId): MediaData {
		return this.mediaLoader.getWithFallback(mediaData, this.currentLanguage);
	}
	public hasMedia(mediaData: MultiLanguageMediaId) : boolean {
		if(!mediaData) { return false; }
		return mediaData.hasAny();
	}

	public toggleShowLogo(): void {
		this.currentAppMenu.showLogo = !this.currentAppMenu.showLogo;
		this.checkForChange();
	}

	public isAdmin(): boolean {
		const currentUser = this.authService.getUser();
		if (currentUser) {
			return currentUser.isAdmin();
		} else {
			return false;
		}
	}

	public getStringNameForContentType(type: ContentTypes): string {
		switch (type) {
			case ContentTypes.Custom: {
				return this.stringService.get('CUSTOM');
			}
			case ContentTypes.URL: {
				return this.stringService.get('URL');
			}
		}
	}

	public getStringNameForMenuStyle(style: MenuStyle): string {
		switch (style) {
			case MenuStyle.LIST: {
				return this.stringService.get('LISTMENU');
			}
			case MenuStyle.GRID: {
				return this.stringService.get('GRIDMENU');
			}
		}
	}

	public getMenuStylesAsArray() {
		return [MenuStyle.LIST, MenuStyle.GRID];
	}

	public showContentTypesSelection(): boolean {
		return this.config.getEnabledContentTypes().length > 1;
	}

	public isTabAllowed(type: ContentTypes): boolean {
		for (const contentType of this.config.getEnabledContentTypes()) {
			if (contentType === type) {
				return true;
			}
		}
		return false;
	}

	public getTabHeaderName(): string {
		if (this.currentAppMenu.menuStyle === MenuStyle.LIST) {
			return this.stringService.get('LIST_MENU_POINT_SETTINGS');
		} else if (this.currentAppMenu.menuStyle === MenuStyle.GRID) {
			return this.stringService.get('GRID_MENU_POINT_SETTINGS');
		} else if (this.currentAppMenu.menuStyle === MenuStyle.CONTENT) {
			return this.stringService.get('CONTENT_MENU_POINT_SETTINGS');
		} else if (this.currentAppMenu.menuStyle === MenuStyle.TOURS) {
			return this.stringService.get('TOUR_MENU_POINT_SETTINGS');
		} else if (this.currentAppMenu.menuStyle === MenuStyle.AR) {
			return this.stringService.get('AR_MENU_POINT_SETTINGS');
		} else if (this.currentAppMenu.menuStyle === MenuStyle.TOUR_ENTRY) {
			return this.stringService.get('TOUR_SETTINGS');
		}
	}

	public getAppMenuTitle(appMenu: AppMenu, prefix?: string, postfix?: string, alternative?: string): string {
		let value = '';
		if (
			appMenu &&
			appMenu.title &&
			appMenu.title.getInLanguage(this.currentLanguage) !== undefined &&
			appMenu.title.getInLanguage(this.currentLanguage) !== ''
		) {
			if (prefix) {
				value = value + prefix;
			}
			value = value + appMenu.title.getInLanguage(this.currentLanguage);
			if (postfix) {
				value = value + postfix;
			}
		} else if (alternative) {
			value = alternative;
		}
		return value;
	}

	public getTourEntryStyleColor(menuEntry: AppMenu) {
		const tour = this.tours.get(menuEntry.menuId);
		if (tour.active === true) {
			return '#000000';
		} else {
			return '#C0C0C0';
		}
	}
}
