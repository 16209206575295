<div class="meta">
  <div class="smartphone-left-side">
    <div class="card-image-lable">
      {{'NOTIFY_IMAGE_LABEL' | translate}}
    </div>
    <div *ngIf="this._place && this._place.notifyImage && (this._place.notifyImage.get(this.currentLanguage) === undefined || this._place.notifyImage.get(this.currentLanguage).file === undefined)"
      class="no-image-available">
      <dropin [maxFileSize]="this.config.getMenuEntryImageFileSize()" (sendFiles)="this.handleFilesFromDropin($event)" [accept]="'.jpg,.jpeg,.png'">
      </dropin>
    </div>
    <aacms-loader class="imageLoader" size="128px"
      *ngIf="this._place && this._place.notifyImage && this._place.notifyImage.get(this.currentLanguage) && this._place.notifyImage.get(this.currentLanguage).file && !this._place.notifyImage.get(this.currentLanguage).isLoaded">
    </aacms-loader>
    <div
      *ngIf="this._place && this._place.notifyImage && this._place.notifyImage.get(this.currentLanguage) && this._place.notifyImage.get(this.currentLanguage).file && this._place.notifyImage.get(this.currentLanguage).isLoaded"
      class="image-available">
      <div class="current-file">
        <div class="current-file-name">
          {{this._place.notifyImage.get(this.currentLanguage).file ? this._place.notifyImage.get(this.currentLanguage).file.name : this._place.notifyImage.get(this.currentLanguage).media.name}}
        </div>
        <div class="update-buttons">
          <div *ngIf="this._place.notifyImage.get(this.currentLanguage).fileSafeUrl" class="download-file"
            (click)="this.downloadFileToSystem(this._place.notifyImage.get(this.currentLanguage))" [matTooltipPosition]="'DOWNLOAD_MEDIA' | translate" triggers="hover">
            <i class="fa fa-download"></i>
          </div>
          <div class="file-delete" (click)="this.removeImageFile()" [matTooltip]="'REMOVE_MEDIA' | translate" triggers="hover">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="card-content-lable">
      {{'NOTIFY_TEXT_LABEL' | translate}}
    </div>
    <div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
      <textarea rows="3" class="form-control textInput" aria-label="Large"
        [(ngModel)]="this._place.notifyText.de" maxlength="{{this.config.getMaxNotificationTextLength()}}" (ngModelChange)="acceptChangesToText($event)"></textarea>
      <div class="languageIndicator">
        <img class="language-button-image" src="../assets/flags/de.svg">
      </div>
      <div class="remainingCharacter">
        {{this.config.getMaxNotificationTextLength() - this._place.notifyText.de.length}}
      </div>
    </div>
    <div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
      <textarea rows="3" class="form-control textInput" aria-label="Large"
        [(ngModel)]="this._place.notifyText.gb" maxlength="{{this.config.getMaxNotificationTextLength()}}" (ngModelChange)="acceptChangesToText($event)"></textarea>
      <div class="languageIndicator">
        <img class="language-button-image" src="../assets/flags/gb.svg">
      </div>
      <div class="remainingCharacter">
        {{this.config.getMaxNotificationTextLength() - this._place.notifyText.gb.length}}
      </div>
    </div>
    <div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
      <textarea rows="3" class="form-control textInput" aria-label="Large"
        [(ngModel)]="this._place.notifyText.jp" maxlength="{{this.config.getMaxNotificationTextLength()}}" (ngModelChange)="acceptChangesToText($event)"></textarea>
      <div class="languageIndicator">
        <img class="language-button-image" src="../assets/flags/jp.svg">
      </div>
      <div class="remainingCharacter">
        {{this.config.getMaxNotificationTextLength() - this._place.notifyText.jp.length}}
      </div>
    </div>
  </div>
  <div class="smartphone-right-side">
    <div #smartphone [ngStyle]="this.getSmartphoneStyle()" class="smartphone">
      <div class="smartphone-content-container">
        <div class="smartphone-content">
          <div class="tracked-image-container">
            <img class="tracked-image" *ngIf="getNotifyImageSrc() === undefined" src="../../../assets/Place_Help.png">
            <img class="tracked-image" *ngIf="getNotifyImageSrc() !== undefined" [src]="getNotifyImageSrc()">
            <div class="notification-text" *ngIf="this._place.notifyText.getInLanguage(this.currentLanguage) !== undefined && this._place.notifyText.getInLanguage(this.currentLanguage).length > 0">
              {{this._place.notifyText.getInLanguage(this.currentLanguage)}}
            </div>
            <div class="notification-text" *ngIf="this._place.notifyText.getInLanguage(this.currentLanguage) === undefined || this._place.notifyText.getInLanguage(this.currentLanguage).length === 0">
              <div *ngIf="this.currentLanguage === Language.DE">Entdecke die Sehenswürdigkeit. Digitale Inhalte über die Vorschau aufrufen. Entdecke die Sehenswürdigkeit. Digitale Inhalte über die Vorschau aufrufen. Entdecke die Sehenswürdigkeit. Digitale Inhalte üb</div>
              <div *ngIf="this.currentLanguage === Language.GB">Discover the sight. Access digital content through the preview.</div>
              <div *ngIf="this.currentLanguage === Language.JP">その光景を発見してください。 プレビューを介してデジタルコンテンツを呼び出します。</div>
            </div>
          </div>
        </div>
        <div class="poi-card-container" *ngIf="this._place !== undefined && this._place.card !== undefined" [style]="this.getBorderStyle()">
          <div class="poi-card">
            <div class="poi-card-title poi-card-content">
              {{this.getLanguageValue(this._place.title)}}
            </div>
            <div class="poi-card-text poi-card-content" *ngIf="!this._place.card.autogenerateCard">
              {{this.getLanguageValue(this._place.card.cardText)}}
            </div>
            <div class="poi-card-text poi-card-content" *ngIf="this._place.card.autogenerateCard">
              {{this.getTextFromFirstUiElement()}}
            </div>
          </div>
          <div class="poi-card-image-container">
            <img class="poi-card-image" [style]="this.getAutoCardImageSytle()" *ngIf="getCardImageSrc() !== null"
              [src]="getCardImageSrc()">
          </div>
        </div>
      </div>
    </div>
    <div class="hint">
      <i>{{ 'PREVIEW_MAY_LOOK_DIFFERENT' | translate }}</i>
    </div>
    <div class="screen-size-button-row">
      <div class="screen-size-button-group btn-group" btnRadioGroup [(ngModel)]="this.currentSelectedResolution">
        <label class="btn btn-light" btnRadio="small" uncheckable tabindex="0" role="button"
          (click)="this.changeSmartphoneStyle('14','7')">{{'SMALL_PHONE' | translate}}</label>
        <label class="btn btn-light" btnRadio="big" uncheckable tabindex="0" role="button"
          (click)="this.changeSmartphoneStyle('16','8')">{{'BIG_PHONE' | translate}}</label>
        <label class="btn btn-light" btnRadio="tablet" uncheckable tabindex="0" role="button"
          (click)="this.changeSmartphoneStyle('16','11')">{{'TABLET' | translate}}</label>
      </div>
      <div class="screen-rotate-button">
        <button type="button" [disabled]="true" class="btn btn-light" (click)="this.rotateSmartphone()"
          [matTooltip]="'ROTATE_SMARTPHONE_DISABLED' | translate">
          <img class="screen-rotate-button-image" src="../../assets/screen_rotation-24px.svg" />
        </button>
      </div>
    </div>
  </div>
</div>
