import { Pipe, PipeTransform } from '@angular/core';
import { Exhibition } from '../models/Exhibition';

/**
 * the ExhibitionFilterPipe is used to Filter all Exhibitions by its title
 */
@Pipe({
    name: 'ExhibitionFilter'
})
export class ExhibitionFilterPipe implements PipeTransform {
    /**
     * filters the ExhibitionsList by the exhibitionsTitle containing the searchText
     * @param exhibitions the exhibitions to be filtered
     * @param searchText the text to be compared
     * @returns returns an exhibitonsList with all matching exhibitions
     */
    transform(exhibitions: Exhibition[], searchText: string): any[] {
        if (!exhibitions) return [];
        if (!searchText) return exhibitions;
        searchText = searchText.toLowerCase();
        return exhibitions.filter(exhibition => {
            if (exhibition.title) {
                return exhibition.title.de.toLowerCase().includes(searchText) || exhibition.title.gb.toLowerCase().includes(searchText) || exhibition.title.jp.toLowerCase().includes(searchText);
            }
        });
    }
}
