import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '../current.app.state';
import { FileDataType } from '../models/FileDataType';
import { Language } from '../models/Language';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { ElementType, UiElement } from '../models/UiElements';
import { CurrentContextService } from '../services/currentContext.service';
import { MediaService } from '../services/media.service';
import { Tools } from '../Tools';
import { Config } from '../Config';
import { StringService } from '../services/string.service';
import { ContentTypes } from '../models/ContentTypes';
import { TabType } from '../models/TabType';
import { Hologram } from '../models/Hologram';
import { HologramService } from '../services/hologram.service';
import { Media } from '../models/Media';
import { TrackerTriggerOpticalMarker, TrackerTriggerPosition } from '../models/TrackerTrigger';
import { GeoJsonFeature, GeoJsonPoint } from '../models/GeoJson';
import { LeafletMapComponent } from '../controls/leaflet-map/leaflet-map.component';
import { TriggerBehavior } from '../models/TriggerBehavior';
import { MediaLoader } from '../../helper-classes/MediaLoader';
import { DomSanitizer } from '@angular/platform-browser';
import { CubeSides } from '../models/CubeSides';

@Component({
	selector: 'aacms-edit-hologram-meta',
	templateUrl: './edit-hologram-meta.component.html',
	styleUrls: ['./edit-hologram-meta.component.scss']
})
export class EditHologramMetaComponent implements OnInit, OnDestroy {
	public modalRef: BsModalRef;
	public hologram: Hologram;
	private originalHologram: Hologram;
	public ElementType = ElementType;
	public contentChanged = false;

	public mediaLoader: MediaLoader;

	public contentTypes = ContentTypes;
	@ViewChild('smartphone') smartphone;
	@ViewChild('popupEditor') popupEditor;
	@ViewChild('cancelModalTemplate', { static: false }) cancelModalTemplate: TemplateRef<any>;
	@ViewChild('mediaViewer') mediaViewer;

	// trigger tab
	public triggerMapFeatures: GeoJsonFeature[] = [];
	@ViewChild('positionTriggerMap') positionTriggerMap: LeafletMapComponent;

	public Language = Language;
	public appState = AppState;
	public Tools = Tools;

	public currentLanguage: Language;
	public newPositionTrigger: TrackerTriggerPosition = new TrackerTriggerPosition();
	public newOpticalTrigger: TrackerTriggerOpticalMarker = new TrackerTriggerOpticalMarker();
	public useCustomPopup: boolean = false;
	private customPopupBackup: UiElement[] = null;

	private saveContentSubject: Subscription;

	public config: Config = new Config();

	public currentTab: TabType;
	public tabType = TabType;

	public constructor(
		public contextService: CurrentContextService,
		private modalService: BsModalService,
		private hologramService: HologramService,
		private router: Router,
		private route: ActivatedRoute,
		private stringService: StringService,
		mediaService: MediaService,
		sanitizer: DomSanitizer
	) {
		this.mediaLoader = new MediaLoader(mediaService, sanitizer);
	}

	public ngOnInit(): void {
		this.contextService.setCurrentState(AppState.editHologramMeta);
		this.currentLanguage = Language.DE;
		this.saveContentSubject = this.contextService.saveYourContent.subscribe(
			saveContent => {
				if (saveContent) {
					this.onSave();
				}
			}
		);
		this.getHologram();
	}

	public ngOnDestroy(): void {
		this.saveContentSubject.unsubscribe();
		this.returnToHologram();
	}

	@HostListener('window:beforeunload')
	canDeactivate(): Observable<boolean> | boolean {
		return (this.contentChanged) ? false : true;
	}

	public discard(): void {
		this.hologram = new Hologram(this.originalHologram);
		this.smartphone.onDiscard();
		this.popupEditor.onDiscard();
		this.useCustomPopup = this.hologram.popupElements.length !== 0;
		this.updateTriggerMapFeatures();
		this.hideModal();
		this.checkForContentChange();
	}

	private hideModal(): void {
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	public getHologram(): void {
		const id = this.route.snapshot.paramMap.get('hologramId');
		const sub = this.hologramService.getHologramById(id).subscribe(
			hologram => {
				this.originalHologram = new Hologram(hologram);
				this.hologram = new Hologram(hologram);
				this.useCustomPopup = this.hologram.popupElements.length !== 0;
				this.customPopupBackup = this.hologram.popupElements;
				this.contextService.getExhibition(this.hologram.exhibitionIds[0]);
				this.updateTriggerMapFeatures();
				sub.unsubscribe();
			},
			error => {
				console.log(error);
				this.router.navigate(['images']);
			});
	}

	public getTrackerFileData(): Map<CubeSides, FileDataType> {
		let thumbFileData = this.mediaLoader.getFileData(this.hologram.model.getWithFallback(this.currentLanguage)?.mediaId);
		return new Map([
			[CubeSides.image, thumbFileData]
		]);
	}

	public getLanguageValue(text: MultiLanguageText): string {
		return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
	}

	public checkForContentChange(): void {
		this.contentChanged = !this.hologram.equal(this.originalHologram);
	}

	public onSave(): void {
		this.hideModal();
		this.contextService.setSaveYourContent(false);
		let saveContent = (this.contentChanged) ? true : false;
		if (saveContent) {
			this.contextService.setSavingContent(true);
		}
		if (this.contentChanged) {
			const sub = this.hologramService.updateHologram(this.hologram).subscribe(
				response => {
					this.originalHologram = new Hologram(this.hologram);
					saveContent = false;
					if (!saveContent) {
						this.checkForContentChange();
						this.contextService.setSavingContent(false);
					}
					sub.unsubscribe();
				});
		}
	}

	public openModal(modalTemplate: TemplateRef<any>): void {
		this.modalRef = this.modalService.show(modalTemplate);
	}

	public returnToHologram(): void {
		if (this.modalRef) { this.modalRef.hide(); }
		this.router.navigate(['holograms', this.hologram.id]);
	}

	public isLanguage(language: Language): boolean {
		return language === this.currentLanguage;
	}

	public updateLanguage(language: Language): void {
		this.currentLanguage = language;
		this.checkForContentChange();
	}

	public toggleUseCustomPopup(): void {
		this.useCustomPopup = !this.useCustomPopup;
		if (!this.useCustomPopup) { // got disabled, backup elements into gui state then, clear model
			this.customPopupBackup = this.hologram.popupElements;
			this.hologram.popupElements = [];
		} else { // restore from backup, if any
			if (this.customPopupBackup != null) {
				this.hologram.popupElements = this.customPopupBackup;
			}
		}
		this.checkForContentChange();
	}

	public toggleHologramActivation(): void {
		this.hologram.active = !this.hologram.active;
		this.checkForContentChange();
	}

	public toggleHologramCardsActivation(): void {
		this.hologram.cardsActive = !this.hologram.cardsActive;
		this.checkForContentChange();
	}

	public getStringNameForContentType(type: ContentTypes): string {
		switch (type) {
			case ContentTypes.Custom: {
				return this.stringService.get('CUSTOM');
			}
			case ContentTypes.URL: {
				return this.stringService.get('URL');
			}
		}
	}

	public showContentTypesSelection(): boolean {
		return this.config.getEnabledContentTypes().length > 1;
	}

	public routeTo(appState: AppState): void {
		switch (appState) {
			case AppState.exhibitions:
				this.router.navigate(['exhibitions']);
				break;
			case AppState.images:
				this.router.navigate(['exhibitions', this.hologram.exhibitionIds[0], 'images']);
				break;
			case AppState.editHologram:
				this.router.navigate(['holograms', this.hologram.id]);
				break;
			default:
				break;
		}
	}

	public isTabAllowed(type: ContentTypes): boolean {
		for (const contentType of this.config.getEnabledContentTypes()) {
			if (contentType === type) {
				return true;
			}
		}
		return false;
	}

	public has3DModel(): boolean {
		return (this.hologram
			&& this.hologram.model.hasAny()
			&& this.hologram.model.getWithFallback(this.currentLanguage)?.mediaId != null);
	}

	public calculateScaledModelDimensions() {
		if(this.mediaViewer?.modelViewer) {
			let dims = this.mediaViewer?.modelViewer.getDimensions();
			let scaleFactor = this.hologram.model.getWithFallback(this.currentLanguage).scaleFactor;
			return {
				x: dims.x * scaleFactor,
				y: dims.y * scaleFactor,
				z: dims.z * scaleFactor
			};
		}
		return {x: 0, y: 0, z: 0};
	}

	public setModelScaleFactor(scaleFactor: number) {
		this.hologram.model.get(this.currentLanguage).scaleFactor = scaleFactor;
		this.checkForContentChange();
	}

	public addNewPositionTrigger() {
		this.hologram.positionTriggers.push(this.newPositionTrigger);
		this.newPositionTrigger = new TrackerTriggerPosition();
		this.checkForContentChange();
		this.updateTriggerMapFeatures();
	}
	public removePositionTrigger(idx: number) {
		this.hologram.positionTriggers.splice(idx, 1);
		this.checkForContentChange();
		this.updateTriggerMapFeatures();
	}

	public selectTab(newTab: TabType) {
		this.currentTab = newTab;
	}

	public updatedPositionTriggerType() {
		// binding already updated value -> translate back to leaflet-map data
		this.checkForContentChange();
		this.updateTriggerMapFeatures();
	}
	public updatePositionTriggerRadius(index: number, event: MouseEvent) {
		let feature = this.hologram.positionTriggers[index];
		if (feature.radius >= 500) {
			feature.radius = 10;
		} else if (feature.radius >= 250) {
			feature.radius = 500;
		} else if (feature.radius >= 100) {
			feature.radius = 250;
		} else if (feature.radius >= 50) {
			feature.radius = 100;
		} else if (feature.radius >= 25) {
			feature.radius = 50;
		} else if (feature.radius >= 10) {
			feature.radius = 25;
		}
		this.checkForContentChange();
		this.updateTriggerMapFeatures();
		event.stopPropagation();
	}
	public updateFromTriggerMapFeatures(features: GeoJsonFeature[]) {
		let result = [];
		for (let feature of features) {
			let posTrigger = new TrackerTriggerPosition();
			posTrigger.latitude = feature.geometry.coordinates[0];
			posTrigger.longitude = feature.geometry.coordinates[1];
			posTrigger.radius = feature.properties.radius;
			posTrigger.triggerBehavior = (feature.properties as any).triggerBehavior;
			result.push(posTrigger);
		}
		this.hologram.positionTriggers = result;
		this.checkForContentChange();
	}
	private updateTriggerMapFeatures() {
		let result = [];
		for (let posTrigger of this.hologram.positionTriggers) {
			let feature = new GeoJsonFeature({});
			feature.geometry = new GeoJsonPoint({});
			(feature.geometry as GeoJsonPoint).setCoordinates(posTrigger.latitude, posTrigger.longitude);
			feature.properties.radius = posTrigger.radius;
			feature.properties.active = false;
			(feature.properties as any).triggerBehavior = posTrigger.triggerBehavior;
			result.push(feature);
		}
		this.triggerMapFeatures = result;
	}
	public onNewTrigger(feature: GeoJsonFeature) {
		(feature.properties as any).triggerBehavior = TriggerBehavior.Always;
	}
	public selectPositionTrigger(index: number) {
		let feature = this.triggerMapFeatures[index];
		this.positionTriggerMap.selectFeature(feature);
		this.positionTriggerMap.panToFeature(feature);
	}
}
