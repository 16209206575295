import { CubeSides } from './../../models/CubeSides';
import { AlertWithBoldValueInMiddle, AlertType } from './../../models/Alert';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { tap } from 'rxjs/operators';
import { Config } from '../../Config';
import { Exhibition } from '../../models/Exhibition';
import { Hologram, HologramMediaIdValue } from '../../models/Hologram';
import { AlertService } from '../../services/alert.service';
import { MediaService } from '../../services/media.service';
import { StringService } from '../../services/string.service';
import { HologramService } from '../../services/hologram.service';
import { Language } from '../../models/Language';

@Component({
	selector: 'modal-content',
	templateUrl: './hologram-upload.modal.component.html',
	styleUrls: ['./hologram-upload.modal.component.scss']
})
export class MultiUploadHologramsUploadModal implements OnInit {

	public currentFiles: File[] = [];
	public uploadInProgress = false;
	private loadedModels: Map<string, boolean> = new Map<string, boolean>();
	private currentExhibition: Exhibition;
	private amount = 0;

	public constructor(
		public bsModalRef: BsModalRef,
		private hologramService: HologramService,
		private mediaService: MediaService,
		public alertService: AlertService,
		public stringService: StringService
	) { }

	public handleFilesFromDropin(event: File[]) {
		this.handleFiles(event);
	}

	public ngOnInit(): void {
		this.uploadInProgress = false;
	}

	private handleFiles(files: File[]): void {
		for (const file of files) {
			this.currentFiles.push(file);
			this.loadedModels.set(file.name, false);
		}
	}

	private removeFile(file: File): void {
		if (this.currentFiles.includes(file)) {
			this.currentFiles = this.currentFiles.filter(element => element !== file);
		}
	}

	public onClose(): void {
		this.bsModalRef.hide();
	}

	public async onCreate(): Promise<void> {
		this.uploadInProgress = true;
		this.amount = this.currentFiles.length;
		for (let index = 0; index < this.currentFiles.length; index++) {
			let uploadWasGood = true;
			const file = this.currentFiles[index];
			const filename = file.name.substring(0, file.name.lastIndexOf('.'));
			const hologram = new Hologram();
			await this.uploadFile(file).then(
				response => {
					hologram.title.de = filename;
					hologram.title.gb = filename;
					hologram.title.jp = filename;
					hologram.exhibitionIds.push(this.currentExhibition.id);
					const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
					hologram.model.set(
						Language.DE,
						HologramMediaIdValue.fromMediaId(location.substring(location.lastIndexOf('/') + 1))
					);
				},
				error => {
					uploadWasGood = false;
				});
			if (uploadWasGood) {
				await this.createHologram(hologram, file);
			} else {
				this.updateUploadProgress(file);
			}
		}
	}

	private async uploadFile(file: File): Promise<any> {
		let uploaded = false;
		return this.mediaService.uploadFile(file).pipe(
			tap(
				async response => {
					if (response.status < 400 && !uploaded) {
						uploaded = true;
					}
				})).toPromise();
	}

	private async createHologram(hologram: Hologram, file: File): Promise<any> {
		return this.hologramService.createHologram(hologram).pipe(tap(response2 => {
			this.updateUploadProgress(file);
		})).toPromise();
	}

	private updateUploadProgress(file: File): void {
		this.amount--;
		this.loadedModels.set(file.name, true);
		if (this.amount === 0) {
			this.bsModalRef.hide();
			this.uploadInProgress = false;
		}
	}

	private modelLoaded(file: File): boolean {
		return this.loadedModels.get(file.name);
	}

	public trackByFn(index, item) {
		return item.id;
	}

	public getMaxContentFileSize(): number {
		return new Config().getMax3DModelSize();
	}
}
