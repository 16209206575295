import { ContentTypes } from '../app/models/ContentTypes';

export const environment = {
  production: true,
  environmentName: 'DEV',
  apiUrl: '', // TODO
  defaultUsername: '',
  defaultPassword: '',
  appMenuEnabled: true,
  pushNotificationEnabled: true,
  toursEnabled: true,
  enabledContentTypes: [ContentTypes.Custom, ContentTypes.URL],
  cuboidTrackerEnabled: true,
  hologramTrackerEnabled: true,
  poiStylesEnabled: true,
  menuEntryImageFileSize: 524288,
  menuHeaderImageFileSize: 1048576,
  maxImageFileSize: 2097152,
  max3DModelFileSize: 20971520,
  maxVideoFileSize: 15728640,
  maxPoiContentFileSize: 6291456,
  poiStyleFileSize: 20971520,
  loginPictureVisible: 'monaLisaHands.png',
  loginPictureInvisible: 'monaLisaNoHands.png',
  maintainance: false
};
