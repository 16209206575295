<div class="header">
	<div class="title">
		<h2>{{ 'POI_TEXTURES' | translate }}</h2>
	</div>
	<div class="create-poi-styles-button-container">
		<button class="btn btn-success create-poi-styles-button" (click)="this.createPoiStyles()">
			{{'CREATE_NEW_POI_STYLES' | translate }}
		</button>
	</div>
</div>
<div class="custom-hr"></div>
<div class="main-content">
	<div class="textures">
		<div class="textures-title">
			<h6>{{ 'AVAILABLE_POI_TEXTURE' | translate }}:</h6>
		</div>
		<div class="texture-list">
			<ng-template ngFor let-texture [ngForOf]="this.textures | AlphabeticalOrderPipe">
				<div class="card">
					<div class="edit-buttons" *ngIf="texture.id !== inEditMode?.id">
						<button
							(click)="this.downloadTexture(texture)" class="editButton"
							[matTooltip]="'DOWNLOAD_MEDIA' | translate">
							<i class="fa fa-download" aria-hidden="true"></i>
						</button>
						<button *ngIf="!Tools.isDefultTexture(texture.id)" (click)="this.deletePoiTexture(texture)"
							class="editButton" [matTooltip]="'DELETE_POI_TEXTURE' | translate">
							<i class="fa fa-trash" aria-hidden="true"></i>
						</button>
						<button *ngIf="!Tools.isDefultTexture(texture.id)" (click)="this.editPoiTexture(texture)"
							class="editButton" [matTooltip]="'DELETE_POI_TEXTURE' | translate">
							<i class="fa fa-pencil" aria-hidden="true"></i>
						</button>
					</div>
					<div class="edit-buttons" *ngIf="texture.id === inEditMode?.id">
						<button (click)="this.discardChanges(texture)" class="editButton"
							[matTooltip]="'DISMISS_CHANGES' | translate">
							<i class="fa fa-undo" aria-hidden="true"></i>
						</button>
						<button (click)="this.saveTexture(texture)" class="editButton"
							[matTooltip]="'SAVE_CHANGES' | translate">
							<i class="fa fa-save" aria-hidden="true"></i>
						</button>
					</div>

					<aacms-media-viewer class="mediaViewer" [mediaId]="texture.mediaId" [mediaLoader]="this.mediaLoader">
					</aacms-media-viewer>

					<div class="card-title" *ngIf="texture.id !== inEditMode?.id">
						<h5 class="card-title-text">
							{{texture.name}}
						</h5>
					</div>
					<div class="card-title-input custom-input-group" *ngIf="texture.id === inEditMode?.id">
						<input rows="1" maxlength="{{this.config.getMaxPoiStyleNameLength()}}"
							class="form-control textInput" aria-label="Large" [(ngModel)]="texture.name">
						<div class="align-self-center px-2">
							{{this.config.getMaxPoiStyleNameLength() - texture.name.length}}
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>