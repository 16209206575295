import { MultiLanguageText } from './MultiLanguageText';
import { MultiLanguageMediaId } from './MultiLanguageMediaId';

export class Card {
	public autogenerateCard = true;
	public cardVisible = true;
	public cardImageFileData: MultiLanguageMediaId = new MultiLanguageMediaId();
	public cardText: MultiLanguageText = new MultiLanguageText();

	constructor(card?: Card) {
		if (!card) { return; }
		this.autogenerateCard = card.autogenerateCard;
		this.cardVisible = card.cardVisible;
		this.cardImageFileData = new MultiLanguageMediaId(card.cardImageFileData);
		this.cardText = new MultiLanguageText(card.cardText);
	}

	public equal(card: Card) {
		if (
			this.autogenerateCard !== card.autogenerateCard ||
			this.cardVisible !== card.cardVisible ||
			!this.cardText.equal(card.cardText)
		) {
			return false;
		}
		if (
			this.cardImageFileData === undefined && card.cardImageFileData !== undefined ||
			!this.cardImageFileData !== undefined && card.cardImageFileData === undefined
		) {
			return false;
		}
		if (this.cardImageFileData !== undefined && card.cardImageFileData !== undefined) {
			if (!this.cardImageFileData.equal(card.cardImageFileData)) {
				return false;
			}
		}
		return true;
	}
}
