<div *ngIf="this.arLayer" class="edit-arLayer-meta-main-content">
  <div class="edit-arlayer-meta-left-side" *ngIf="this.cuboid">
    <div class="slidecontainer">
      <input type="range" min="0" max="100" [(ngModel)]="this.arLayerOpacity" class="slider" id="myRange"
        [matTooltip]="'CHANGE_OPACITY' | translate" triggers="hover">
      <button class="enable-tracker-button btn btn-light" (click)="this.enableTrackerLayer = !this.enableTrackerLayer"
        [matTooltip]="'ENABLE_DISABLE_TRACKER' | translate" triggers="hover">
        <i *ngIf="this.enableTrackerLayer" class="fa fa-eye-slash" aria-hidden="true"></i>
        <i *ngIf="!this.enableTrackerLayer" class="fa fa-eye" aria-hidden="true"></i>
      </button>
    </div>
    <aacms-cuboid-viewer #cuboidViewer class="cuboid-viewer" [cuboid]="this.cuboid"
      [arLayerOpacity]="this.arLayerOpacity" [enableTrackerLayer]="this.enableTrackerLayer"
      [(imageDataMap)]="this.cuboidImageDataMap" [arLayer]="this.arLayer" [currentLanguage]="this.currentLanguage"
      [(arLayerImageDataMap)]="this.arLayerImageDataMap" (arLayerImageDataMapChanged)="this.checkForContentChange()">
    </aacms-cuboid-viewer>
  </div>
  <div class="edit-arlayer-meta-right-side">
    <div class="header">
      <div class="path-title-container">
        <div class="path">
          <div class="path-option" (click)="this.routeTo(appState.exhibitions)">{{'EXHIBITIONS' | translate}}</div>
          /
          <div class="path-option" (click)="this.routeTo(appState.images)">{{ Tools.shortenString(contextService.currentExhibition && contextService.currentExhibition.title && getLanguageValue(contextService.currentExhibition.title).length > 0 ? getLanguageValue(contextService.currentExhibition.title) : ('TRACKERS' | translate)) }}</div>
          /
          <div class="path-option" (click)="this.routeTo(appState.editCuboid)">{{ Tools.shortenString(cuboid && getLanguageValue(cuboid.title).length > 0 ? getLanguageValue(cuboid.title) : ('CUBOID' | translate)) }}</div>
          /
          <div class="path-option" (click)="this.routeTo(appState.editARLayer)">{{ Tools.shortenString(arLayer && getLanguageValue(arLayer.title).length > 0 ? getLanguageValue(arLayer.title) : ('AR_LAYER' | translate)) }}</div>
          /
          <div class="last-path-option">{{'AR_LAYER_POI' | translate}}</div>
        </div>
        <div class="arlayer-title-edit-container">
          <h1 class="edit-indicator">
            <i class="fa fa-pencil" aria-hidden="true"
              [ngStyle]="{'color': contentChanged || pms.poisChanged ? '#ffc107' : 'black' }"></i>
          </h1>
          <div class="arlayer-title-container">
            <h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.arLayer.title).length > 0">
              {{this.getLanguageValue(this.arLayer.title)}}
            </h1>
            <h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.arLayer.title).length == 0">
              <i>{{'UNNAMED_AR_LAYER' | translate }}</i>
            </h1>
          </div>
        </div>
      </div>
      <aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
        (languageChanged)="this.updateLanguage($event)">
      </aacms-language-selector>
    </div>

    <tabset [justified]="false">
      <tab heading="{{'AR_LAYER_GENERAL_TAB' | translate}}" class="arlayer-general-tab tab" (selectTab)="this.currentTab = tabType.GeneralTab">
        <div class="arlayer-title-input">
          <div>{{'TITLE' | translate }}</div>
          <div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
            <input class="form-control textInput" [(ngModel)]="this.arLayer.title.de" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
              (ngModelChange)="checkForContentChange()" />
            <div class="languageIndicator">
              <img class="language-button-image" src="../assets/flags/de.svg">
            </div>
            <div class="align-self-center px-2">
              {{this.config.getMaxTitleTextLength() - this.arLayer.title.de.length}}
            </div>
          </div>
          <div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
            <input class="form-control textInput" [(ngModel)]="this.arLayer.title.gb" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
              (ngModelChange)="checkForContentChange()" />
            <div class="languageIndicator">
              <img class="language-button-image" src="../assets/flags/gb.svg">
            </div>
            <div class="align-self-center px-2">
              {{this.config.getMaxTitleTextLength() - this.arLayer.title.gb.length}}
            </div>
          </div>
          <div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
            <input class="form-control textInput" [(ngModel)]="this.arLayer.title.jp" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
              (ngModelChange)="checkForContentChange()" />
            <div class="languageIndicator">
              <img class="language-button-image" src="../assets/flags/jp.svg">
            </div>
            <div class="align-self-center px-2">
              {{this.config.getMaxTitleTextLength() - this.arLayer.title.jp.length}}
            </div>
          </div>
        </div>
        <div class="ar-layer-qr-code">
          <div>{{'QR_CODE_ID' | translate }}</div>
          <input class="form-control .form-control-lg ar-layer-qr-code-input" [(ngModel)]="this.arLayer.qrCodeId"
            (ngModelChange)="this.checkForContentChange()" />
        </div>
        <div *ngIf="this.showContentTypesSelection()">{{ 'TYPE_OF_CONTENT_PAGE' | translate }}</div>
        <div class="content-type-selection-container">
          <div *ngIf="this.showContentTypesSelection()" class="selection-container">
            <select class="selection" [(ngModel)]="this.arLayer.contentType" (change)="checkForContentChange()">
              <ng-template ngFor let-type [ngForOf]="this.config.getEnabledContentTypes()">
                <option role="selection-item" [ngValue]="this.type">{{ this.getStringNameForContentType(type) }}
                </option>
              </ng-template>
            </select>
          </div>
        </div>
        <div class="checkbox-container">
          <label class="checkbox">
            <input type="checkbox" (click)="this.toggleArLayerActivation()" [checked]="this.arLayer.active">
            <span class="checkmark" triggers="hover"
              placement="bottom"></span>
            <div class="checkbox-text">
              {{ 'AR_LAYER_ACTIVE_INACTIVE' | translate }}
            </div>
          </label>
        </div>
        <div class="checkbox-container">
          <label class="checkbox">
            <input type="checkbox" (click)="this.toggleArLayerCardsActivation()" [checked]="this.arLayer.cardsActive">
            <span class="checkmark" triggers="hover"
              placement="bottom"></span>
            <div class="checkbox-text">
              {{ 'AR_LAYER_CARDS_ACTIVE_INACTIVE' | translate }}
            </div>
          </label>
        </div>
      </tab>
      <tab heading="{{ 'AR_LAYER_CARD_TAB' | translate }}" class="card-tab tab" (selectTab)="this.currentTab = tabType.CardTab">
        <div class="smartphone-container">
          <aacms-info-card-editor [card]="this.arLayer.card" [title]="this.arLayer.title"
            [currentLanguage]="this.currentLanguage" [trackerFileData]="getTrackerFileData()" [cuboid]="this.cuboid"
            (cardChange)="this.checkForContentChange()" [uiElements]="this.arLayer.uiElements">
          </aacms-info-card-editor>
        </div>
      </tab>
      <tab heading="{{ 'AR_LAYER_CONTENT_PAGE_TAB' | translate }}" class="content-page-tab tab"
        *ngIf="this.isTabAllowed(contentTypes.Custom)" (selectTab)="this.currentTab = tabType.UiElementsTab">
        <div class="smartphone-container">
          <aacms-content-page #smartphone [(uiElements)]="this.arLayer.uiElements" [isVisible]="currentTab === tabType.UiElementsTab"
            (uiElementsChange)="this.checkForContentChange()" [language]="this.currentLanguage">
          </aacms-content-page>
        </div>
      </tab>
      <tab heading="{{ 'AR_LAYER_URL_PAGE_TAB' | translate }}" class="url-page-tab tab" (selectTab)="this.currentTab = tabType.UrlTab"
        *ngIf="this.isTabAllowed(contentTypes.URL)">
        <div class="smartphone-container">
          <aacms-url-page [url]="this.arLayer.url" (urlChange)="this.checkForContentChange()"
            [language]="this.currentLanguage">
          </aacms-url-page>
        </div>
      </tab>
      <tab heading="{{'AR_LAYER_UNBOXING_MODEL_TAB' | translate}}" class="cuboid-open-experiance-tab tab" (selectTab)="this.currentTab = tabType.ArLayerUnboxingTab">
        <dropin [maxFileSize]="this.config.getMaxPoiContentFileSize()" (sendFiles)="this.handleFilesFromDropin($event)"
          [accept]="'.glb'" [filterFileExtensions]="['.gltf']" *ngIf="!this.arLayer.unboxingModel.get(this.currentLanguage)">
        </dropin>
        <div class="model-container" *ngIf="this.arLayer.unboxingModel.get(this.currentLanguage) && this.arLayer.unboxingModel.get(this.currentLanguage).media && this.arLayer.unboxingModel.get(this.currentLanguage).file">
          <img class="model-placeholder" (mouseenter)="this.hovered = true"
            *ngIf="this.arLayer.unboxingModel.get(this.currentLanguage).isLoaded && !this.arLayer.unboxingModel.get(this.currentLanguage).media.thumbUrl  && !this.hovered"
            src="../../assets/3DModellPlaceholder.png">
          <img class="model riffle-background" (mouseenter)="this.hovered = true"
            *ngIf="this.arLayer.unboxingModel.get(this.currentLanguage).isLoaded && this.arLayer.unboxingModel.get(this.currentLanguage).media.thumbUrl  && !this.hovered"
            [src]="this.getThumbUrl(this.arLayer.unboxingModel.get(this.currentLanguage).media)">
          <model-viewer class="model riffle-background" auto-rotate="true" *ngIf="this.arLayer.unboxingModel.get(this.currentLanguage).isLoaded && this.hovered"
            camera-controls="true" [attr.src]="this.arLayer.unboxingModel.get(this.currentLanguage).fileSafeUrl" autoplay
            (mouseleave)="this.hovered = false">
          </model-viewer>
          <aacms-loader class="imageLoader" size="64px"
            *ngIf="this.arLayer.unboxingModel.get(this.currentLanguage).file && !this.arLayer.unboxingModel.get(this.currentLanguage).isLoaded">
          </aacms-loader>
        </div>
        <div
          *ngIf="this.arLayer && this.arLayer.unboxingModel  && this.arLayer.unboxingModel.get(this.currentLanguage) && this.arLayer.unboxingModel.get(this.currentLanguage).file && this.arLayer.unboxingModel.get(this.currentLanguage).isLoaded"
          class="image-available">
          <div class="current-file">
            <div class="current-file-name">
              {{this.arLayer.unboxingModel.get(this.currentLanguage).file ? this.arLayer.unboxingModel.get(this.currentLanguage).file.name : this.arLayer.unboxingModel.get(this.currentLanguage).media.name}}
            </div>
            <div class="update-buttons">
              <div *ngIf="this.arLayer.unboxingModel.get(this.currentLanguage).fileSafeUrl" class="download-file"
                (click)="this.downloadFileToSystem(this.arLayer.unboxingModel.get(this.currentLanguage))"
                [matTooltip]="'DOWNLOAD_MEDIA' | translate" triggers="hover" >
                <i class="fa fa-download"></i>
              </div>
              <div class="file-delete" (click)="this.removeUnboxingModelFile()" [matTooltip]="'REMOVE_MEDIA' | translate" triggers="hover">
                <i class="fa fa-times"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="open-box-poi-selection-label">
          {{ 'POI_CLICK_TO_OPEN_BOX' | translate }}
        </div>
        <div class="open-box-poi-selection-container">
          <div class="selection-container">
            <select class="selection" [(ngModel)]="this.openingPoi" (change)="this.openBoxPoiChanged()">
              <option role="selection-item" [ngValue]="undefined" [style.color]="'lightgray'">Poi auswählen</option>
              <ng-template ngFor let-poi [ngForOf]="this.pms.pois">
                <option role="selection-item" [ngValue]="poi">
                  {{ this.getPoiSelectionValue(poi) }}
                </option>
              </ng-template>
            </select>
          </div>
        </div>
        <div class="notification-text">
          {{ 'UNBOXING_MODEL_NOTIFICATION' | translate }}
        </div>
      </tab>
    </tabset>

    <div class="custom-hr"></div>
    <div class="button-row row">
      <button type="submit" class="btn btn-secondary custom-button" (click)="this.returnToArLayer()">
        {{'BACK_TO_ARLAYER' | translate}}
      </button>
      <button type="submit" class="btn btn-warning custom-button" [disabled]="!this.contentChanged && !pms.poisChanged"
        (click)="openModal(cancelModalTemplate)">
        {{'DISCARD' | translate }}
      </button>
      <button type="submit" class="btn btn-success custom-button" [disabled]="!this.contentChanged && !pms.poisChanged"
        (click)="save()">
        {{'SAVE_CHANGES' | translate}}
      </button>
    </div>
  </div>
</div>

<ng-template #cancelModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
  </div>
  <div class="modal-footer">
    <button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
    <button (click)="discard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
  </div>
</ng-template>
