export class Media {

    public id: string;
    public name: string;
    public type: string;
    public rawUrl: string;
    public thumbUrl: string;
    public rating: number;

    public constructor(media?: Media) {
        if (!media) { return; }
        this.id = media.id;
        this.name = media.name;
        this.rawUrl = media.rawUrl;
        this.thumbUrl = media.thumbUrl;
        this.type = media.type;
        this.rating = media.rating;
    }

    public equal(media: Media): boolean {
      if (this.id !== media.id ||
          this.name !== media.name ||
          this.type !== media.type ||
          this.rawUrl !== media.rawUrl ||
          this.thumbUrl !== media.thumbUrl ||
          this.rating !== media.rating) {
        return false;
      }
      return true;
    }
}
