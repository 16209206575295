import { Tour, Waypoint } from '../models/Tour';
import { AlertService } from '../services/alert.service';
import { AlertSimple, AlertType, AlertWithBoldValueInMiddle } from '../models/Alert';
import { StringService } from '../services/string.service';
import { Injectable } from '@angular/core';
import { GeoJsonFeature, GeoJson, GeoJsonLineString, GeoJsonPoint } from '../models/GeoJson';
import { Location } from '../models/Location';

@Injectable({
  providedIn: 'root',
})
export class GPXParserService {

  public parseElements: string[];
  public joinTrackSegments = true;
  public waypoints: Waypoint[];
  public track: Location[];

  constructor(
    public alertService: AlertService,
    private stringService: StringService
  ) {

  }

  private gpxInfo = {
    name: null,
    author: null,
    desc: null,
    copyright: null,
    length: null,
    hr: {
      avg: null,
      total: null,
      points: []
    },
    cad: {
      avg: null,
      total: null,
      points: []
    },
    atemp: {
      avg: null,
      total: null,
      points: []
    },
    elevation: {
      min: null,
      max: null,
      gain: null,
      loss: null,
      points: []
    },
    duration: {
      start: null,
      moving: null,
      total: null,
      end: null
    }
  };

  public parse(input: File, parseElements = ['route', 'track', 'waypoint']): Promise<GeoJson> {
    this.waypoints = [];
    this.track = [];
    this.parseElements = parseElements;
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const result = fileReader.result.toString().trim();
        if (result.substr(0, 1) === '<') {
          const parser: DOMParser = new DOMParser();
          const parsedData = parser.parseFromString(result, 'text/xml');
          if (!this.isParseError(parsedData)) {
            const geoJson = this.createGeoJson(this.parseGPXData(parsedData));
            geoJson.properties.filename = input.name.substr(0, input.name.lastIndexOf('.'));
            for (const feature of geoJson.features) {
              if (!feature.geometry.coordinatesValid()) {
                let index = geoJson.features.indexOf(feature) + 1;
                let type = feature.geometry.type.valueOf();
                let coordinates = JSON.stringify(feature.geometry.coordinates);
                const value = this.stringService.get('POSITION') + ': ' + index + ', ' + this.stringService.get('FEATURE_TYPE') + ': ' + type + ', ' + this.stringService.get('COORDINATES') + ': ' + coordinates;  
                reject(
                  this.alertService.alert(new AlertWithBoldValueInMiddle(this.stringService.get('GEOJSON_FEATURE_COORDINATES_BROKEN1'), value, this.stringService.get('GEOJSON_FEATURE_COORDINATES_BROKEN2'), AlertType.Warning))
                );
                return;
              }
            }
            resolve(geoJson);
          } else {
            let value;
            if (parsedData.documentElement.textContent.indexOf('Number ') > -1) {
              value = parsedData.documentElement.textContent.split('Number ').pop();
            } else {
              value = parsedData.documentElement.textContent.split('Nr. ').pop();
            }
            value = value.substr(0, value.indexOf(','));
            reject(
              this.alertService.alert(new AlertWithBoldValueInMiddle(this.stringService.get('GPX_FILE_NOT_PARSABLE1'), value, this.stringService.get('GPX_FILE_NOT_PARSABLE2'), AlertType.Error))  
            );
          }
        } else {
          reject(
            this.alertService.alert(new AlertSimple(this.stringService.get('GPX_FILE_BROKEN'), AlertType.Error))
          );
        }
      };
      fileReader.onerror = () => {
        this.alertService.alert(new AlertSimple(this.stringService.get('GPX_FILE_BROKEN'), AlertType.Error));
      };
      fileReader.readAsText(input);
    });
  }

  private isParseError(parsedDocument) {
    // parser and parsererrorNS could be cached on startup for efficiency
    var parser = new DOMParser(),
        errorneousParse = parser.parseFromString('<', 'application/xml'),
        parsererrorNS = errorneousParse.getElementsByTagName("parsererror")[0].namespaceURI;

    if (parsererrorNS === 'http://www.w3.org/1999/xhtml') {
        // In PhantomJS the parseerror element doesn't seem to have a special namespace, so we are just guessing here :(
        return parsedDocument.getElementsByTagName("parsererror").length > 0;
    }

    return parsedDocument.getElementsByTagNameNS(parsererrorNS, 'parsererror').length > 0;
}

  private createGeoJson(tour: Tour): GeoJson {
    const geoJson = new GeoJson();
    geoJson.features = [];
    for(const waypoint of this.waypoints) {
      const point = new GeoJsonPoint();
      point.setCoordinates(waypoint.location.lon, waypoint.location.lat);
      const feature = new GeoJsonFeature();
      feature.geometry = point;
      feature.properties.position = geoJson.features.length + 1;
      geoJson.features.push(feature);
    }
    if (this.track && this.track.length > 0) {
      const points: Array<[number, number]> = [];
      for(const location of this.track) {
        points.push([location.lon, location.lat]);
      }
      const lineString = new GeoJsonLineString();
      lineString.setCoordinates(points);
      const feature = new GeoJsonFeature();
      feature.geometry = lineString;
      geoJson.features.push(feature);
    }
    return geoJson;
  }

  private parseGPXData(xml: XMLDocument): Tour {
    let tour = new Tour();
    if (this.parseElements.indexOf('route') > -1) {
      const routes: HTMLCollection = xml.getElementsByTagName('rte');
      if (routes.length > 0) {
        this.alertService.alert(new AlertSimple(this.stringService.get('GPX_CONTAINS_ROUTE'), AlertType.Warning));
      }
    }

    if (this.parseElements.indexOf('track') > -1) {
      const tracks: HTMLCollection = xml.getElementsByTagName('trk');
      if (tracks.length > 1) {
        this.alertService.alert(new AlertSimple(this.stringService.get('GPX_CONTAINS_MORE_THAN_ONE_TRACK'), AlertType.Warning));
      }
      if (tracks.length === 0) {
        this.alertService.alert(new AlertSimple(this.stringService.get('GPX_CONTAINS_NO_TRACK'), AlertType.Warning));
      }
      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        if (this.joinTrackSegments) {
          const element = track.getElementsByTagName('trkpt');
          this.track = this.track.concat(this.parseSegment(element));
        } else {
          const segments: HTMLCollection = track.getElementsByTagName('trkseg');
          for (let j = 0; j < segments.length; j++) {
            const element = segments[j].getElementsByTagName('trkpt');
            this.track = this.track.concat(this.parseSegment(element));
          }
        }
      }
    }
    if (this.parseElements.indexOf('waypoint') > -1) {
      let element: HTMLCollection = xml.getElementsByTagName('wpt');
      const segments = this.parseSegment(element);
      for (const segment of segments) {
        const waypoint = new Waypoint();
        waypoint.location = segment;
        this.waypoints.push(waypoint);
      }
    }
    return tour;
  }

  private parseSegment(elements): Location[] {
    const coords = [];
    for (let i = 0; i < elements.length; i++) {
      const location = new Location();
      location.lat = parseFloat(elements[i].getAttribute('lat'));
      location.lon = parseFloat(elements[i].getAttribute('lon'));
      if (location.lat > 90 || location.lat < -90) {
        this.alertService.alert(new AlertSimple(this.stringService.get('LAT_DOES_NOT_MATCH'), AlertType.Error));
      } else if (location.lon > 180 || location.lon < -180) {
        this.alertService.alert(new AlertSimple(this.stringService.get('LON_DOES_NOT_MATCH'), AlertType.Error));
      } else {
        const ele = elements[i].getElementsByTagName('ele');
        if (ele.length > 0) {
          location.ele = parseFloat(ele[0].textContent);
        }
        coords.push(location);
      }
    }
    return coords;
  }
}
