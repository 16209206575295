export interface LonLatCoord {
    lon: number;
    lat: number;
}

/**
 * SYNC WITH BACKEND!
 */
export class UserConfig {
    public highlightColor: string;
    public mapDownloadActive: boolean;
    // mapBoundaries is in (northeast, southwest) order!
    public mapBoundaries: [LonLatCoord, LonLatCoord];
    public overrideMapboxApiKey: string | null = null;
    public mapboxMapId: string;
    public logoMediaId: string | null = null;

    public constructor(config?: UserConfig) {
        if(!config) {
            return;
        }
        this.highlightColor = config.highlightColor;
        this.mapDownloadActive = config.mapDownloadActive;
        this.mapBoundaries = [
            { lon: config.mapBoundaries[0].lon, lat: config.mapBoundaries[0].lat },
            { lon: config.mapBoundaries[1].lon, lat: config.mapBoundaries[1].lat }
        ];
        this.overrideMapboxApiKey = config.overrideMapboxApiKey;
        this.mapboxMapId = config.mapboxMapId;
        this.logoMediaId = config.logoMediaId;
    }

    public equal(config: UserConfig): boolean {
        if(
            this.highlightColor !== config.highlightColor
            || this.mapDownloadActive !== config.mapDownloadActive
            || this.mapBoundaries[0].lat !== config.mapBoundaries[0].lat
            || this.mapBoundaries[0].lon !== config.mapBoundaries[0].lon
            || this.mapBoundaries[1].lat !== config.mapBoundaries[1].lat
            || this.mapBoundaries[1].lon !== config.mapBoundaries[1].lon
            || this.overrideMapboxApiKey !== config.overrideMapboxApiKey
            || this.mapboxMapId !== config.mapboxMapId
            || this.logoMediaId !== config.logoMediaId
        ) { return false; }
        return true;
    }
}
