import { AuthService } from './../services/auth.service';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { Config } from '../Config';
import { FileDataType } from '../models/FileDataType';
import { Language } from '../models/Language';
import { Media } from '../models/Media';
import {
	AudioElement,
	ElementType, ImageElement,
	ModelElement, TextElement, UiElement, VideoElement
} from '../models/UiElements';
import { RichtextEditorConfig } from '../richtext-editor/config';
import { MediaService } from '../services/media.service';
import { GeoJson, MapElement } from '../models/GeoJson';
import { GPXParserService } from '../services/gpx.parser.service';
import { GeoJsonParserService } from '../services/geoJson.parser.service';
import * as L from 'leaflet';
import { CurrentContextService } from '../services/currentContext.service';
import { MediaData } from '../../helper-classes/MediaLoader';
import { MultiLanguageData } from '../models/MultiLanguageData';
import { MultilangMediaLoader } from '../../helper-classes/MultilangMediaLoader';
import { UserConfigService } from '../services/userConfig.service';

// vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
// #####################################################################
// TODO: The popup editor, and the content editor share most of their  #
// code. Should probably unify this before any further changes to it!  #
// #####################################################################
// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

@Component({
	selector: 'aacms-content-page',
	templateUrl: './content-page.component.html',
	styleUrls: ['./content-page.component.scss']
})
export class ContentPageComponent implements OnChanges {

	@Input() showBackButton: boolean;
	@Output() onBackButton: EventEmitter<void> = new EventEmitter<void>();

	@Input() uiElements: UiElement[];
	@Output() uiElementsChange: EventEmitter<UiElement[]> = new EventEmitter<UiElement[]>();

	public currentSelected: UiElement = undefined;
	private previousSelected: UiElement = undefined;

	public reload: boolean = false;
	@Input() language: Language;

	@ViewChild('smartphone') smartphoneHTML: ElementRef;

	@Input() isVisible: boolean = false;

	public Language = Language;

	public ElementType = ElementType;
	public elementType = ElementType; //TODO: remove

	public currentSelectedResolution = 'big';

	public config: Config = new Config();
	public editorConfig: RichtextEditorConfig = {
		editable: true,
		height: '450px',
		outline: false,
		placeholder: '',
		sanitize: true,
		toolbarHiddenButtons: [
			// ['superscript', 'subscript'],
			['fontName', 'fontSize', 'backgroundColor'],
			['indent', 'outdent'],
			// ['cut', 'copy', 'delete', 'removeFormat'],
			['paragraph', 'blockquote', 'removeBlockquote', 'insertHorizontalRule'],
			['link', 'unlink', 'insertImage', 'insertVideo', 'toggleEditorMode']
		]
	};

	public hovered: string = '';

	public mediaLoader: MultilangMediaLoader<FileDataType>;

	private smartphoneMultiplier = 2.7;
	private smartphoneHeight = 16;
	private smartphoneWidth = 8;
	private portraitMode = true;

	private maps: Map<MapElement, any> = new Map();

	public constructor(
		private mediaService: MediaService,
		private sanitizer: DomSanitizer,
		private authService: AuthService,
		private gpxParser: GPXParserService,
		private geoJsonParser: GeoJsonParserService,
		private contextService: CurrentContextService,
		private userConfigService: UserConfigService
	) {
		this.mediaLoader = new MultilangMediaLoader(mediaService, sanitizer);
	}

	private forceReload() {
		this.reload = true;
		setTimeout(() => {
			this.reload = false;
		})
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes['language']) {
			this.forceReload();
		}
		if (changes['uiElements']) {
			const currentUiElements = changes['uiElements'].currentValue;
			const previousUiElements = changes['uiElements'].previousValue;

			if (previousUiElements) {
				const sameUiElements = previousUiElements.filter(uie => currentUiElements.includes(uie));
				if (sameUiElements.length === 0) {
					this.unsetCurrentSelected();
					this.loadContent();
				}
			} else {
				this.unsetCurrentSelected();
				this.loadContent();
			}
		}
		if (this.isVisible === true) {
			this.reloadMaps();
		}
	}

	public fireChangeEvent() {
		this.uiElementsChange.emit(this.uiElements);
	}

	public reloadMaps(): void {
		for (let element of this.maps.keys()) {
			this.reloadMap(element);
		}
	}

	public reloadMap(element: MapElement): void {
		if (element.mapObject !== undefined) {
			if (this.maps.has(element) && this.maps.get(element) !== undefined) {
				const map = this.maps.get(element).map;
				setTimeout(
					() => {
						map.invalidateSize();
						let bounds;
						if (element.mapObject.properties.bottomLeft.containsLonAndLat() && element.mapObject.properties.topRight.containsLonAndLat()) {
							if (this.maps.get(element).ractangle !== undefined) {
								map.removeLayer(this.maps.get(element).ractangle);
							}
							setTimeout(() => {
								this.maps.get(element).ractangle = L.rectangle(bounds, { fill: false, color: "#00ff00" });
								this.maps.get(element).ractangle.addTo(map);
							});
							bounds = L.latLngBounds(element.mapObject.properties.bottomLeft, element.mapObject.properties.topRight);
						} else {
							const group = new L.featureGroup(this.maps.get(element).features);
							if (Object.values(map._layers).length > 0) {
								bounds = group.getBounds();
							} else {
								bounds = L.latLngBounds(L.latLng(47.26543, 5.864417), L.latLng(55.14777, 15.05078));
							}
						}
						map.fitBounds(bounds);
					}
				);
			}
		}
	}

	public onDiscard(): void {
		this.reloadMaps();
		this.unsetCurrentSelected();
	}

	public unsetCurrentSelected(): void {
		this.setPreviouslySelected();
		this.currentSelected = undefined;
		const previousSelectedElements = document.getElementsByClassName('element selected');
		for (let i = 0; i < previousSelectedElements.length; i++) {
			previousSelectedElements.item(i).classList.remove('selected');
		}
	}

	public loadContent(): void {
		for (const element of this.uiElements) {
			if (element.type === ElementType.MAP) {
				if ((<MapElement>element).mapObject) {
					this.initMap(element);
				}
			}
		}
	}

	private createCustomIcon(env, feature, latlng, showNumbers) {
		let myCustomColour;
		if (feature.properties.active) {
			myCustomColour = env.userConfigService.getSync().highlightColor;
		} else {
			myCustomColour = '#a3a3a3';
		}
		const markerHtmlStyles = `
      background: ${myCustomColour};
      width: 46px;
      height: 46px;
      display: block;
      left: -22px;
      top: -31px;
      position: relative;
      border-radius: 50% 50% 50% 0;
      transform: rotate(-45deg);
      display: flex;
      justify-content: center;
      align-items: center;
    `;
		let html;
		if (showNumbers == true) {
			html = `<span style="${markerHtmlStyles}"><div class="leaflet-marker-text-ui"><div>${feature.properties.position}</div></div></span>`;
		} else {
			html = `<span style="${markerHtmlStyles}"></span>`;
		}
		const icon = L.divIcon({
			className: "leaflet-marker",
			iconAnchor: [0, 23],
			labelAnchor: [-6, 0],
			popupAnchor: [0, -36],
			html: html
		});
		return L.marker(latlng, { icon: icon });
	}

	public updateMap(mapElement: MapElement): void {
		this.reloadMap(mapElement);
	}

	private initMap(uiElement: UiElement): void {
		if (uiElement.type === ElementType.MAP) {
			const mapElement: MapElement = (<MapElement>uiElement);
			if (mapElement.mapObject !== undefined) {
				setTimeout(() => {
					const id = 'map-' + mapElement.position;
					const map = L.map(
						id,
						{
							zoomControl: true,
							attributionControl: false,
							dragging: true,
							boxZoom: false,
							doubleClickZoom: true,
							scrollWheelZoom: true,
							touchZoom: true,
							keyboard: false
						}
					);
					const tiles = L.tileLayer(new Config().getMapUrl(), {
						tileSize: 256,
						minZoom: 1,
						attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
						crossOrigin: true
					});
					tiles.addTo(map);
					const myLayerOptions = {
						pointToLayer: (feature, latlng) => this.createCustomIcon(this, feature, latlng, mapElement.isMandatory)
					}
					const geoJsonElement = L.geoJSON(mapElement.mapObject, myLayerOptions);
					geoJsonElement.addTo(map);
					L.control.scale().addTo(map);
					this.maps.set(mapElement, { "map": map, "features": Object.values(geoJsonElement._layers), rectangle: undefined });
					this.reloadMaps();
				});
			}
		}
	}

	public moveElementUp() {
		if (this.currentSelected === undefined) {
			return;
		}
		if (this.currentSelected.position > 0) {
			[this.uiElements[this.currentSelected.position - 1], this.uiElements[this.currentSelected.position]] =
				[this.uiElements[this.currentSelected.position], this.uiElements[this.currentSelected.position - 1]];
			this.changeInSortable(this.uiElements);
		}
	}

	public moveElementDown() {
		if (this.currentSelected === undefined) {
			return;
		}
		if (this.currentSelected.position < this.uiElements.length - 1) {
			[this.uiElements[this.currentSelected.position], this.uiElements[this.currentSelected.position + 1]] =
				[this.uiElements[this.currentSelected.position + 1], this.uiElements[this.currentSelected.position]];
			this.changeInSortable(this.uiElements);
		}
	}

	public changeInSortable(event: UiElement[]) {
		if (event.length === this.uiElements.length) {
			let changed = false;
			let counter = 0;
			for (const element of event) {
				if (element.position !== counter) {
					changed = true;
				}
				counter++;
			}
			if (changed) {
				this.uiElements = [];
				counter = 0;
				for (const element of event) {
					element.position = counter;
					this.uiElements.push(element);
					counter++;
				}
			}
		}
		this.uiElementsChange.emit(this.uiElements);
	}

	public deleteElement() {
		this.uiElements = this.uiElements.filter(element => this.currentSelected !== element);
		this.unsetCurrentSelected();
		this.uiElementsChange.emit(this.uiElements);
	}

	public addNewElement(type: ElementType): void {
		let newElement: UiElement;
		if (type === ElementType.AUDIO) {
			newElement = new AudioElement();
		} else if (type === ElementType.VIDEO) {
			newElement = new VideoElement();
		} else if (type === ElementType.TEXT) {
			newElement = new TextElement();
		} else if (type === ElementType.MODEL) {
			newElement = new ModelElement();
		} else if (type === ElementType.IMAGE) {
			newElement = new ImageElement();
		} else if (type === ElementType.MAP) {
			newElement = new MapElement();
		}
		if (newElement) {
			newElement.position = this.uiElements.length;
			this.uiElements.push(newElement);
			this.setCurrentSelectedElement(this.uiElements[this.uiElements.length - 1]);
		}
		this.uiElementsChange.emit(this.uiElements);
	}

	private setPreviouslySelected(): void {
		if (!this.currentSelected) {
			this.previousSelected = undefined;
		} else {
			if (this.currentSelected && this.currentSelected.type === ElementType.AUDIO) {
				this.previousSelected = new AudioElement(<AudioElement>this.currentSelected);
			} else if (this.currentSelected && this.currentSelected.type === ElementType.VIDEO) {
				this.previousSelected = new VideoElement(<VideoElement>this.currentSelected);
			} else if (this.currentSelected && this.currentSelected.type === ElementType.TEXT) {
				this.previousSelected = new TextElement(<TextElement>this.currentSelected);
			} else if (this.currentSelected && this.currentSelected.type === ElementType.MODEL) {
				this.previousSelected = new ModelElement(<ModelElement>this.currentSelected);
			} else if (this.currentSelected && this.currentSelected.type === ElementType.IMAGE) {
				this.previousSelected = new ImageElement(<ImageElement>this.currentSelected);
			} else if (this.currentSelected && this.currentSelected.type === ElementType.MAP) {
				this.previousSelected = new MapElement(<MapElement>this.currentSelected);
			}
		}
	}

	public setCurrentSelectedElement(element: UiElement): void {
		if (this.currentSelected && !element.equal(this.currentSelected)) {
			for (const node of this.smartphoneHTML.nativeElement.childNodes) {
				if (node.id === 'element-' + this.currentSelected.position) {
					this.checkForVideoOrAudioAndPause(node);
				}
			}
		}
		for (const node of this.smartphoneHTML.nativeElement.childNodes) {
			if (node.id === 'element-' + element.position) {
				this.activateControls(node);
			}
		}
		if (!this.currentSelected || (this.currentSelected && !this.currentSelected.equal(element))) {
			this.manageSelectedElementStyle(element);
		}
		this.setPreviouslySelected();
		this.currentSelected = element;
	}

	private activateControls(HTMLElement): void {
		if (HTMLElement.nodeName === 'VIDEO') {
			HTMLElement.controls = true;
		} else {
			if (HTMLElement.childNodes) {
				for (const element of HTMLElement.childNodes) {
					this.activateControls(element);
				}
			}
		}
	}

	private checkForVideoOrAudioAndPause(element: HTMLElement): void {
		if (element instanceof HTMLVideoElement) {
			element.pause();
			element.controls = false;
		} else if (element instanceof HTMLAudioElement) {
			element.pause();
		} else {
			element.childNodes?.forEach((child) => this.checkForVideoOrAudioAndPause(child as HTMLElement));
		}
	}

	private manageSelectedElementStyle(element: UiElement): void {
		if (this.currentSelected !== undefined) {
			const previousSelectedElementContainer = this.smartphoneHTML.nativeElement.querySelector('#element-' + this.currentSelected.position);
			previousSelectedElementContainer.classList.remove('selected');
		}

		setTimeout(() => {
			const elementContainer = this.smartphoneHTML.nativeElement.querySelector('#element-' + element.position);
			if (elementContainer !== null) {
				elementContainer.classList.add('selected');
			}
		});
	}

	public currentSelectedIs(type): boolean {
		if (this.currentSelected === undefined && type === 'None') {
			return true;
		}
		if (this.currentSelected !== undefined && this.currentSelected.type === type) {
			return true;
		}
		return false;
	}

	public isImageElementEmpty(element: ImageElement): boolean {
		if (element.imageObject === undefined || !element.imageObject.hasAny()) {
			return true;
		}
		return false;
	}

	public isVideoElementEmpty(element: VideoElement): boolean {
		if (element.videoObject === undefined || !element.videoObject.hasAny()) {
			return true;
		}
		return false;
	}

	public isTextElementEmpty(element: TextElement): boolean {
		if (element.text === undefined) {
			return true;
		} else if (this.language === Language.DE && (element.text.de === '' ||
			element.text.de === '<br>' || element.text.de === '<div><br></div>')) {
			return true;
		} else if (this.language === Language.GB && (element.text.gb === '' ||
			element.text.gb === '<br>' || element.text.gb === '<div><br></div>')) {
			return true;
		} else if (this.language === Language.JP && (element.text.jp === '' ||
			element.text.jp === '<br>' || element.text.jp === '<div><br></div>')) {
			return true;
		}
		return false;
	}

	public isAudioElementEmpty(element: AudioElement): boolean {
		if (element.audioObject === undefined || !element.audioObject.hasAny()) {
			return true;
		}
		return false;
	}

	public isModelElementEmpty(element: ModelElement): boolean {
		if (element.modelObject === undefined || !element.modelObject.hasAny()) {
			return true;
		}
		return false;
	}

	public isMapElementEmpty(element: MapElement): boolean {
		if (element.mapObject === undefined) {
			return true;
		}
		return false;
	}

	public updateVisibility(): void {
		this.currentSelected.active = !this.currentSelected.active;
		this.uiElementsChange.emit(this.uiElements);
	}

	public async updateMapFile(file: File): Promise<any> {
		let errorOccured = false;
		if (this.currentSelected.type === ElementType.MAP) {
			if (file.name.endsWith('gpx')) {
				await this.gpxParser.parse(file).then(
					geoJson => {
						(<MapElement>this.currentSelected).setGeoJson(new GeoJson(geoJson));
					},
					error => {
						console.log(error);
						errorOccured = true;
					}
				);
			} else if (file.name.endsWith('geojson')) {
				await this.geoJsonParser.parse(file).then(
					geoJson => {
						(<MapElement>this.currentSelected).setGeoJson(new GeoJson(geoJson));
					},
					error => {
						console.log(error);
						errorOccured = true;
					}
				);
			}
			if (errorOccured === false) {
				const mapElement: MapElement = (<MapElement>this.currentSelected);
				mapElement.filename = file.name.substring(0, file.name.lastIndexOf('.')) + '.geojson';
				if (mapElement !== undefined && this.maps.get(mapElement) !== undefined) {
					this.maps.get(mapElement).map.off();
					this.maps.get(mapElement).map.remove();
				}
				this.initMap(this.currentSelected);
			}
			this.uiElementsChange.emit(this.uiElements);
		}
	}

	public removeMapFile(): void {
		if (this.currentSelected.type === ElementType.MAP && !(<MapElement>this.currentSelected).isMandatory) {
			if (this.maps.get((<MapElement>this.currentSelected)) !== undefined) {
				this.maps.get((<MapElement>this.currentSelected)).map.off();
				this.maps.get((<MapElement>this.currentSelected)).map.remove();
				this.maps.delete((<MapElement>this.currentSelected));
			}
			(<MapElement>this.currentSelected).mapObject = undefined;
			this.uiElementsChange.emit(this.uiElements);
		}
	}

	public acceptChangesToText(change: string): void {
		this.uiElementsChange.emit(this.uiElements);
	}

	public setLanguage(language: Language): void {
		this.language = language;
	}

	public isLanguage(language: Language): boolean {
		return language === this.language;
	}

	public changeSmartphoneStyle(height, width): void {
		this.smartphoneHeight = height;
		this.smartphoneWidth = width;
	}

	public rotateSmartphone(): void {
		this.portraitMode = !this.portraitMode;
		this.reloadMaps();
	}

	public getSmartphoneStyle() {
		const width = (this.smartphoneWidth * this.smartphoneMultiplier) + 'rem';
		const height = (this.smartphoneHeight * this.smartphoneMultiplier) + 'rem';
		const portraitBorder = '20px 10px 40px 10px';
		const landscapeBorder = '10px 40px 10px 20px';

		const smartphoneStyle = {
			'height': this.portraitMode ? height : width,
			'width': this.portraitMode ? width : height,
			'border-width': this.portraitMode ? portraitBorder : landscapeBorder
		};

		return smartphoneStyle;
	}

	public getSanitizedText(text: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(text);
	}

	public onNavigateBack(): void {
		this.onBackButton.emit();
	}

	public getImageElementStyle(item: UiElement): Object {
		const style = {};
		return style;
	}

	public setBoundingBox(): void {
		if (this.currentSelected !== undefined && this.currentSelected instanceof MapElement) {
			const bounds = this.maps.get(this.currentSelected).map.getBounds();
			this.currentSelected.mapObject.properties.bottomLeft.lat = bounds._southWest.lat;
			this.currentSelected.mapObject.properties.bottomLeft.lon = bounds._southWest.lng;
			this.currentSelected.mapObject.properties.topRight.lat = bounds._northEast.lat;
			this.currentSelected.mapObject.properties.topRight.lon = bounds._northEast.lng;
			this.reloadMaps();
			this.uiElementsChange.next(this.uiElements);
		}
	}

	public getMapProperties(): any {
		if (this.currentSelected !== undefined && this.currentSelected instanceof MapElement) {
			return this.currentSelected.mapObject.properties;
		}
	}
}
