import { Component, Input } from "@angular/core";
import { UserConfigService } from "../../services/userConfig.service";

@Component({
	selector: 'aacms-logo',
	templateUrl: './app-logo.component.html',
	styleUrls: ['./app-logo.component.scss']
})
export class AppLogoComponent {

	@Input() size: string = 'auto';

	public constructor(
		public userConfigService: UserConfigService
	) {}

}
