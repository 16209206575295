import { Router } from "@angular/router";
import { Image } from "../app/models/Image";
import { Cuboid } from "../app/models/Cuboid";
import { Hologram } from "../app/models/Hologram";
import { Place } from "../app/models/Place";
import { Tracker } from "../app/models/Tracker";

/**
 * Turns out that sprinkling the entire Frontend code with hard-coded route
 * url construction is not a good idea when the addition of new tracker types should
 * be easily possible...
 * Who could've guessed that!
 */
export default class RouteHelper {

    static routeToTracker(router: Router, tracker: Tracker) {
		if (tracker instanceof Image) {
			router.navigate(['images', tracker.id]);
		} else if (tracker instanceof Cuboid) {
			router.navigate(['cuboids', tracker.id]);
		} else if (tracker instanceof Place) {
			router.navigate(['places', tracker.id]);
		} else if (tracker instanceof Hologram) {
			router.navigate(['holograms', tracker.id]);
		} else {
			throw new Error("Encountered unknown tracker type!");
		}
    }

}