import { FileDataType } from './FileDataType';
import { MultiLanguageText } from './MultiLanguageText';
import { AudioElement, ElementType, ImageElement, ModelElement, TextElement, UiElement, VideoElement } from './UiElements';
import { CuboidMediaIds } from './CuboidMediaIds';
import { Card } from './Card';
import { ContentTypes } from './ContentTypes';
import { Config } from '../Config';
import { MapElement } from './GeoJson';
import { MultiLanguageFileDataType } from './MultiLanguageFileDataType';

export class ArLayer {
  public id: string;
  public cuboidId: string;
  public mediaIds: CuboidMediaIds = new CuboidMediaIds();
  public title: MultiLanguageText = new MultiLanguageText();
  public uiElements: UiElement[] = [];
  public active = true;
  public createdTime: number;
  public updatedTime: number;
  public position: number;
  public qrCodeId = '';
  public url: MultiLanguageText = new MultiLanguageText();
  public card: Card = new Card();
  public cardsActive = true;
  public contentType: ContentTypes = new Config().getEnabledContentTypes()[0];
  public unboxingModel: MultiLanguageFileDataType = new MultiLanguageFileDataType();

  constructor(arLayer?: ArLayer) {
    if (!arLayer) {
      return;
    }
    this.id = arLayer.id;
    this.cuboidId = arLayer.cuboidId;
    this.title = new MultiLanguageText(arLayer.title);
    this.mediaIds = new CuboidMediaIds(arLayer.mediaIds);
    this.active = arLayer.active;
    this.createdTime = arLayer.createdTime;
    this.updatedTime = arLayer.updatedTime;
    this.position = arLayer.position;
    this.qrCodeId = arLayer.qrCodeId;
    this.url = new MultiLanguageText(arLayer.url);
    this.card = new Card(arLayer.card);
    this.cardsActive = arLayer.cardsActive;
    this.contentType = arLayer.contentType;
    this.unboxingModel = new MultiLanguageFileDataType(arLayer.unboxingModel);
    for (const element of arLayer.uiElements) {
      if (element.type === ElementType.AUDIO) {
        this.uiElements.push(new AudioElement(<AudioElement>element));
      } else if (element.type === ElementType.IMAGE) {
        this.uiElements.push(new ImageElement(<ImageElement>element));
      } else if (element.type === ElementType.MODEL) {
        this.uiElements.push(new ModelElement(<ModelElement>element));
      } else if (element.type === ElementType.TEXT) {
        this.uiElements.push(new TextElement(<TextElement>element));
      } else if (element.type === ElementType.VIDEO) {
        this.uiElements.push(new VideoElement(<VideoElement>element));
      } else if (element.type === ElementType.MAP) {
        this.uiElements.push(new MapElement(<MapElement>element));
      }
    }
  }

  public equal(arLayer: ArLayer): boolean {
    if (this.id !== arLayer.id ||
      this.cuboidId !== arLayer.cuboidId ||
      this.uiElements.length !== arLayer.uiElements.length ||
      this.active !== arLayer.active ||
      this.createdTime !== arLayer.createdTime ||
      this.updatedTime !== arLayer.updatedTime ||
      this.position !== arLayer.position ||
      this.qrCodeId !== arLayer.qrCodeId ||
      !this.title.equal(arLayer.title) ||
      !this.mediaIds.equal(arLayer.mediaIds) ||
      !this.url.equal(arLayer.url) ||
      !this.card.equal(arLayer.card) ||
      this.cardsActive !== arLayer.cardsActive ||
      !this.unboxingModel.equal(arLayer.unboxingModel) ||
      this.contentType !== arLayer.contentType
    ) {
      return false;
    }
    for (let i = 0; i < this.uiElements.length; i++) {
      if (!this.uiElements[i].equal(arLayer.uiElements[i])) {
        return false;
      }
    }
    return true;
  }
}
