import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, share } from 'rxjs/operators';
import { Config } from '../Config';
import { AlertService } from './alert.service';
import { Tour } from '../models/Tour';
import { AlertSimple, AlertType, AlertWithBoldValueInMiddle } from '../models/Alert';
import { StringService } from './string.service';
import { CurrentContextService } from './currentContext.service';
import { MapElement } from '../models/GeoJson';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root',
})
export class TourService {

  private baseUrl = new Config().GetBaseUrl();

  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService,
    private contextService: CurrentContextService
  ) { }

  public getTours(): Observable<Tour[]> {
    return this.http.get<Tour[]>(this.getTourUrl()).pipe(
      tap(
        response => { },
        error => { this.handleError<Tour[]>('getTours', error); }
      ),
      share()
    );
  }

  public getTourById(id: string): Observable<Tour> {
    return this.http.get<Tour>(this.getTourUrl(`${id}`)).pipe(
      tap(
        response => { },
        error => { this.handleError<Tour>('getTourById', error); }
      ),
      share()
    );
  }

  public createTour(tour: Tour): Observable<any> {
    return this.http.post<any>(this.getTourUrl(), tour, httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(
            new AlertSimple(
              this.stringService.get('TOUR_CREATED'),
              AlertType.Success),
            true);
        },
        error => { this.handleError<Tour>('createTour', error); }
      ),
      share()
    );
  }

  public updateTour(tour: Tour): Observable<any> {
    const tourCopy = new Tour(tour);
    for (const uiElement of tourCopy.href.uiElements) {
      uiElement.cleanForUpLoad();
    }
    return this.http.put(this.getTourUrl(`${tour.id}`), tourCopy, httpOptions).pipe(
      tap(
        response => {
          if (this.contextService.getInCurrentAppLanguage(tour.title) !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('TOUR_UPDATED1'),
                this.contextService.getInCurrentAppLanguage(tour.title),
                this.stringService.get('TOUR_UPDATED3'),
                AlertType.Success),
              true);
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('TOUR_UPDATED2'),
                tour.id,
                this.stringService.get('TOUR_UPDATED3'),
                AlertType.Success),
              true);
          }
        },
        error => { this.handleError<Tour>('updateTour', error); }
      ),
      share()
    );
  }

  public applyChanges(tour: Tour): Observable<any> {
    return this.http.post(this.getTourUrl(`${tour.id}/apply`), httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(
            new AlertSimple(
              this.stringService.get('TOUR_PUBLISHED'),
              AlertType.Success),
            true);
        },
        error => {
          this.handleError<Tour>('applyChanges', error);
        }
      ),
      share()
    );
  }

  public applyAllChanges(): Observable<any> {
    return this.http.post(this.getTourUrl(`-/apply`), httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(
            new AlertSimple(
              this.stringService.get('TOUR_PUBLISHED'),
              AlertType.Success),
            true);
        },
        error => {
          this.handleError<Tour>('applyChanges', error);
        }
      ),
      share()
    );
  }

  public updateTourActivation(tour: Tour): Observable<any> {
    return this.http.put(this.getTourUrl(`${tour.id}`), tour, httpOptions).pipe(
      tap(
        response => {
          if (tour.active) {
            this.alertService.alert(
              new AlertSimple(
                this.stringService.get('TOUR_ACTIVATED'),
                AlertType.Success),
              true);
          } else {
            this.alertService.alert(
              new AlertSimple(
                this.stringService.get('TOUR_DEACTIVATED'),
                AlertType.Info),
              true);
          }
        },
        error => { this.handleError<Tour>('updateTourActivation', error); }
      ),
      share()
    );
  }

  public deleteTour(tour: Tour | string): Observable<any> {
    const id = typeof tour === 'string' ? tour : tour.id;
    return this.http.delete(this.getTourUrl(`${id}`), httpOptions).pipe(
      tap(
        response => { },
        error => { this.handleError<Tour>('deleteTour', error); }
      ),
      share()
    );
  }

  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private logError(value: any): void {
    console.error(value);
  }

  private getTourUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}tours/`;
    if (extension) {
      if (!extension.endsWith('/')) {
        extension = `${extension}/`;
      }
      url = `${url}${extension}`;
    }
    return url;
  }
}
