import { MultiLanguageFileDataType } from './MultiLanguageFileDataType';
import { MultiLanguageText } from './MultiLanguageText';

export class Tracker {

  public id: string;
  public title: MultiLanguageText = new MultiLanguageText();
  public exhibitionIds: string[] = [];
  public createdTime: number;
  public updatedTime: number;
  public exportTime: number;
  public active = true;

  public constructor(tracker?: Tracker) {
    if (!tracker) { return; }
    this.id = tracker.id;
    this.title = new MultiLanguageText(tracker.title);
    this.exhibitionIds = [];
    for (const exhibitionId of tracker.exhibitionIds) {
      this.exhibitionIds.push(exhibitionId);
    }
    this.createdTime = tracker.createdTime;
    this.updatedTime = tracker.updatedTime;
    this.exportTime = tracker.exportTime;
    this.active = tracker.active;
  }

  public equal(tracker: Tracker) {
    if (
      this.id !== tracker.id ||
      this.createdTime !== tracker.createdTime ||
      this.updatedTime !== tracker.updatedTime ||
      this.active !== tracker.active ||
      this.exhibitionIds.length !== tracker.exhibitionIds.length ||
      !this.title.equal(tracker.title)
    ) {
      return false;
    }
    for (let i = 0; i < this.exhibitionIds.length; i++) {
      if (this.exhibitionIds[i] !== tracker.exhibitionIds[i]) {
        return false;
      }
    }
    return true;
  }
}
