import { Language } from "./Language";

/**
 * Multi language text class
 * Can store a text in multiple languages.
 * Is used for image-/poi- title and description.
 */
export class MultiLanguageText {
    /**
     * Text in German language.
     */
    public de = '';

    /**
     * Text in English language.
     */
    public gb = '';

    /**
     * Text in Japanese language.
     */
    public jp = '';

    /**
     * Constructor
     * Sets the different language variables.
     * @param text MultiLanguageText object.
     */
    public constructor(text?: MultiLanguageText) {
        if (!text) {
            this.de = '';
            this.gb = '';
            this.jp = '';
        } else {
            if (!text.de) {
                this.de = '';
            } else {
                this.de = text.de;
            }
            if (!text.gb) {
                this.gb = '';
            } else {
                this.gb = text.gb;
            }
            if (!text.jp) {
                this.jp = '';
            } else {
                this.jp = text.jp;
            }
        }
    }

    public getInLanguage(language: Language): string {
      if (Language.DE === language) {
        return this.de;
      } else if (Language.GB === language) {
        return this.gb;
      } else if (Language.JP === language) {
        return this.jp;
      }
    }

    /**
     * Checks if two MultiLanguageText objects are equal.
     * @param text MultiLanguageText object.
     * @returns true if the given text is equal to this in each language.
     */
    public equal(text: MultiLanguageText): boolean {
      if (
        text === undefined ||
        this.de !== text.de ||
        this.gb !== text.gb ||
        this.jp !== text.jp
      ) { return false; }
      return true;
    }
}
