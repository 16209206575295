<div class="modal-header">
  <h4 class="modal-title text-break pull-left">{{'SELECT_TRACKER' | translate }}</h4>
  <button type="button" class="close pull-right" aria-label="Close"
    (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="selected-tracker-container">
    <div class="selected-tracker-text">
      <b>{{'SELECTED_TRACKER' | translate }}</b>
    </div>
    <div class="card-container" *ngIf="this.selectedTracker !== undefined"> <!-- && this.selectedTracker.active === true-->
      <div *ngIf="isTrackerImage(this.selectedTracker)">
        <ng-template *ngTemplateOutlet="imageTrackerTemplate; context: {$implicit: this.selectedTracker}"></ng-template>
      </div>
      <div *ngIf="isTrackerCuboid(this.selectedTracker)">
        <ng-template *ngTemplateOutlet="cuboidTrackerTemplate; context: {$implicit: this.selectedTracker}"></ng-template>
      </div>
      <div *ngIf="isTrackerPlace(this.selectedTracker)">
        <div class="image-card">
          <div class="card-content">
            <div class="map-container">
              <div id="selectedTrackerMap" class="map"></div>
            </div>
            <div class="card-title">
              {{this.getTrackerTitle(this.selectedTracker)}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="this.selectedTracker === undefined">
      <i>{{'NO_TRACKER_SELECTED' | translate}}</i>
    </div>
  </div>
  <b>{{'EXHIBITIONS' | translate }}</b>
  <div *ngFor="let exhibition of this.exhibitions.keys(); let i = index" id="trackerAccordion" class="accordion">
    <div *ngIf="this.exhibitions.get(exhibition).length > 0">
      <div type="button" class="collapseToggle" [attr.data-toggle]="'collapse'" [attr.data-target]="'#collapse'+i" [attr.aria-expanded]="false"
        [attr.aria-controls]="'collapse'+i" (click)="this.toggleCollapse(exhibition)" aria-controls="collapseBasic">
          <div *ngIf="exhibition.title.getInLanguage(this.currentLanguage).length === 0">
            <i>{{'UNNAMED_EXHIBITION' | translate }}</i>
          </div>
          <div *ngIf="exhibition.title.getInLanguage(this.currentLanguage).length > 0">
            {{exhibition.title.getInLanguage(this.currentLanguage)}}
          </div>
      </div>

      <div class="collapse" [id]="'collapse'+i" [collapse]="this.expanded !== exhibition.id" [isAnimated]="true" [attr.data-parent]="'#trackerAccordion'">
        <div class="cards">
          <div class="card-container" *ngFor="let tracker of this.exhibitions.get(exhibition); let j = index" (click)="this.selectTracker(tracker)">
            <div> <!--*ngIf="tracker.active === true"-->
              <ng-template *ngTemplateOutlet="trackerTemplate; context: {$implicit: tracker}"></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="onClose()">
    {{'ABORT' | translate }}
  </button>
  <button type="button" class="btn btn-success" (click)="onSave()">
    {{'SAVE' | translate}}
  </button>
</div>

<ng-template #trackerTemplate let-tracker>
    <div *ngIf="isTrackerImage(tracker)">
      <ng-template *ngTemplateOutlet="imageTrackerTemplate; context: {$implicit: tracker}"></ng-template>
    </div>
    <div *ngIf="isTrackerHologram(tracker)">
      <ng-template *ngTemplateOutlet="hologramTrackerTemplate; context: {$implicit: tracker}"></ng-template>
    </div>
    <div *ngIf="isTrackerCuboid(tracker)">
      <ng-template *ngTemplateOutlet="cuboidTrackerTemplate; context: {$implicit: tracker}"></ng-template>
    </div>
    <div *ngIf="isTrackerPlace(tracker)">
      <ng-template *ngTemplateOutlet="placeTrackerTemplate; context: {$implicit: tracker}"></ng-template>
    </div>
</ng-template>

<ng-template #placeTrackerTemplate let-tracker>
  <div class="image-card" [style.border-color]="this.isSelectedTracker(tracker) ? '#87ceeb' : 'lightgray'">
    <div class="card-content">
      <div class="map-container">
        <div id="{{'map:' + tracker.id}}" class="map"></div>
      </div>
      <div class="card-title">
        {{this.getTrackerTitle(tracker)}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #imageTrackerTemplate let-tracker>
  <div class="image-card" [style.border-color]="this.isSelectedTracker(tracker) ? '#87ceeb' : 'lightgray'">
    <div class="card-content">
      <div class="image">
        <img class="card-img-top riffle-background" [src]="this.getThumbUrl(tracker.mediaId)"
          (load)="this.trackerLoaded(tracker, true)">
        <aacms-loader class="imageLoader" size="64px" *ngIf="this.trackerLoaded(tracker) && tracker.mediaId">
        </aacms-loader>
      </div>
      <div class="card-title">
        {{this.getTrackerTitle(tracker)}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #hologramTrackerTemplate let-tracker>
  <div class="image-card" [style.border-color]="this.isSelectedTracker(tracker) ? '#87ceeb' : 'lightgray'">
    <div class="card-content">
      <div class="image">
        <aacms-media-viewer [mediaId]="getTrackerMediaId(tracker)" [modelHover]="false" [styles]="{ height: '100%' }">
        </aacms-media-viewer>
      </div>
      <div class="card-title">
        {{this.getTrackerTitle(tracker)}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cuboidTrackerTemplate let-tracker>
  <div class="image-card" [style.border-color]="this.isSelectedTracker(tracker) ? '#87ceeb' : 'lightgray'">
    <div class="card-content">
      <div *ngIf="this.initDone && getTracker(tracker).trackerIds" class="cuboid riffle-background">
        <aacms-cuboid-quick-viewer #cuboidViewer class="cuboid-viewer" [cuboid]="getTracker(tracker)"
          [top]="this.getCuboidThumbUrl(getTracker(tracker).trackerIds.top.mediaId)"
          [bottom]="this.getCuboidThumbUrl(getTracker(tracker).trackerIds.bottom.mediaId)"
          [left]="this.getCuboidThumbUrl(getTracker(tracker).trackerIds.left.mediaId)"
          [right]="this.getCuboidThumbUrl(getTracker(tracker).trackerIds.right.mediaId)"
          [back]="this.getCuboidThumbUrl(getTracker(tracker).trackerIds.back.mediaId)"
          [front]="this.getCuboidThumbUrl(getTracker(tracker).trackerIds.front.mediaId)">
        </aacms-cuboid-quick-viewer>
        <aacms-loader class="imageLoader" size="64px" *ngIf="this.trackerLoaded(tracker) && tracker.mediaId">
        </aacms-loader>
      </div>
      <div class="card-title">
        {{this.getTrackerTitle(tracker)}}
      </div>
    </div>
  </div>
</ng-template>
