import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { StringService } from '../services/string.service';
import { AlertSimple, AlertType } from '../models/Alert';
import { Config } from '../Config';
import { LockService } from '../services/lock.service';
import { Lock } from '../models/Lock';
import { AuthService } from '../services/auth.service';
import { catchError, first, map, share, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class ResourceGuard implements CanActivate {

  public config: Config = new Config();

  public constructor(
    private router: Router,
    private alertService: AlertService,
    private stringService: StringService,
    private lockService: LockService,
    private authSerive: AuthService,
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const lock = new Lock();
    lock.userId = this.authSerive.getUser().id;
    lock.resource = state.url;
    lock.type = 'full';
    await this.lockService.createLock(lock).pipe(
      map(
        response => {
          return of(true);
        },
        catchError((error) => {
          this.router.navigate(['exhibitions']);
          return of(false);
        })
      )
    ).toPromise();

    return of(true).toPromise();
  }

}
