import { AuthService } from './../services/auth.service';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Config } from '../Config';
import { FileDataType } from '../models/FileDataType';
import { Language } from '../models/Language';
import { Media } from '../models/Media';
import {
  ElementType, ImageElement,
  TextElement, UiElement
} from '../models/UiElements';
import { RichtextEditorConfig } from '../richtext-editor/config';
import { MediaService } from '../services/media.service';
import { Tools } from '../Tools';
import { CurrentContextService } from '../services/currentContext.service';

// vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
// #####################################################################
// TODO: The popup editor, and the content editor share most of their  #
// code. Should probably unify this before any further changes to it!  #
// #####################################################################
// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

@Component({
  selector: 'aacms-popup-page',
  templateUrl: './popup-page.component.html',
  styleUrls: ['./popup-page.component.scss'],
  // required so embedded ng-content default-content is styled by the
  // popup element styling rules
  encapsulation: ViewEncapsulation.None
})
export class PopupPageComponent implements OnChanges {

  @Input() showBackButton: boolean;
  @Output() onBackButton: EventEmitter<void> = new EventEmitter<void>();

  @Input() title: string = "";
  @Input() enabled: boolean = true;
  @Input() uiElements: UiElement[];
  @Output() uiElementsChange: EventEmitter<UiElement[]> = new EventEmitter<UiElement[]>();

  public currentSelected: UiElement = undefined;
  private previousSelected: UiElement = undefined;

  public reload: boolean = false;
  @Input() language: Language;

  @ViewChild('popup') popupHTML: ElementRef;

  @Input() isVisible: boolean = false;

  public Language = Language;

  public elementType = ElementType;

  public currentSelectedResolution = 'big';

  public config: Config = new Config();
  public editorConfig: RichtextEditorConfig = {
    editable: true,
    height: '450px',
    outline: false,
    placeholder: '',
    sanitize: true,
    toolbarHiddenButtons: [
      // ['superscript', 'subscript'],
      ['fontName', 'fontSize', 'backgroundColor'],
      ['indent', 'outdent'],
      // ['cut', 'copy', 'delete', 'removeFormat'],
      ['paragraph', 'blockquote', 'removeBlockquote', 'insertHorizontalRule'],
      ['link', 'unlink', 'insertImage', 'toggleEditorMode']
    ]
  };

  public hovered: string = '';

  private smartphoneMultiplier = 2.7;
  private smartphoneHeight = 16;
  private smartphoneWidth = 8;
  private portraitMode = true;

  public constructor(
    private mediaService: MediaService,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private contextService: CurrentContextService
  ) { }

  private forceReload() {
    this.reload = true;
    setTimeout(()=> {
      this.reload = false;
    })
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['language']) {
      this.forceReload();
    }
    if (changes['uiElements']) {
      const currentUiElements = changes['uiElements'].currentValue;
      const previousUiElements = changes['uiElements'].previousValue;

      if (previousUiElements) {
        const sameUiElements = previousUiElements.filter(uie => currentUiElements.includes(uie));
        if (sameUiElements.length === 0) {
          this.unsetCurrentSelected();
          this.loadContent();
        }
      } else {
        this.unsetCurrentSelected();
        this.loadContent();
      }
    }
    if (changes['enabled'] && changes['enabled'].currentValue === false) {
      this.unsetCurrentSelected();
    }
  }

  public isEmpty(): boolean {
    return (this.uiElements.length > 0);
  }

  public onDiscard(): void {
    this.unsetCurrentSelected();
  }

  public unsetCurrentSelected(): void {
    this.setPreviouslySelected();
    this.currentSelected = undefined;
    const previousSelectedElements = document.getElementsByClassName('element selected');
    for (let i = 0; i < previousSelectedElements.length; i++) {
      previousSelectedElements.item(i).classList.remove('selected');
    }
  }

  public loadContent(): void {
    const requests: Observable<any>[] = [];
    for (const element of this.uiElements) {
      if (element.type === ElementType.IMAGE) {
        if ((<ImageElement>element).imageObject) {
          for(const value of (<ImageElement>element).imageObject.getAllValues()) {
            if (value !== undefined) {
              requests.push(this.loadMediaAndFile(value));
            }
          }
        }
      }
    }
    if (requests.length > 0) {
      const sub = forkJoin(requests).subscribe(
        response => {
          sub.unsubscribe();
        }
      );
    }
  }

  private loadMediaAndFile(object: FileDataType): Observable<any> {
    object.isLoaded = false;
    return this.mediaService.getMediaById(object.mediaId).pipe(tap(
      response => {
        object.media = new Media(response);
        const sub = this.mediaService.downloadFile(object.media.rawUrl).subscribe(
          blob => {
            object.file = Tools.blobToFile(blob, object.media.name);
            object.fileSafeUrl = this.getBlobUrl(object.file);
            object.isLoaded = true;
            sub.unsubscribe();
          }
        );
      }
    ));
  }

  public moveElementUp() {
    if (this.currentSelected === undefined) {
      return;
    }
    if (this.currentSelected.position > 0) {
      [this.uiElements[this.currentSelected.position - 1], this.uiElements[this.currentSelected.position]] =
        [this.uiElements[this.currentSelected.position], this.uiElements[this.currentSelected.position - 1]];
      this.changeInSortable(this.uiElements);
    }
  }

  public moveElementDown() {
    if (this.currentSelected === undefined) {
      return;
    }
    if (this.currentSelected.position < this.uiElements.length - 1) {
      [this.uiElements[this.currentSelected.position], this.uiElements[this.currentSelected.position + 1]] =
        [this.uiElements[this.currentSelected.position + 1], this.uiElements[this.currentSelected.position]];
      this.changeInSortable(this.uiElements);
    }
  }

  public changeInSortable(event: UiElement[]) {
    if (event.length === this.uiElements.length) {
      let changed = false;
      let counter = 0;
      for (const element of event) {
        if (element.position !== counter) {
          changed = true;
        }
        counter++;
      }
      if (changed) {
        this.uiElements = [];
        counter = 0;
        for (const element of event) {
          element.position = counter;
          this.uiElements.push(element);
          counter++;
        }
      }
    }
    this.uiElementsChange.emit(this.uiElements);
  }

  public deleteElement() {
    this.uiElements = this.uiElements.filter(element => this.currentSelected !== element);
    this.unsetCurrentSelected();
    this.uiElementsChange.emit(this.uiElements);
  }

  public addNewElement(type: ElementType): void {
    let newElement: UiElement;
    if (type === ElementType.TEXT) {
      newElement = new TextElement();
    } else if (type === ElementType.IMAGE) {
      newElement = new ImageElement();
    }
    if (newElement) {
      newElement.position = this.uiElements.length;
      this.uiElements.push(newElement);
      this.setCurrentSelectedElement(this.uiElements[this.uiElements.length - 1]);
    }
    this.uiElementsChange.emit(this.uiElements);
  }

  private setPreviouslySelected(): void {
    if (!this.currentSelected) {
      this.previousSelected = undefined;
    } else {
      if (this.currentSelected && this.currentSelected.type === ElementType.TEXT) {
        this.previousSelected = new TextElement(<TextElement>this.currentSelected);
      } else if (this.currentSelected && this.currentSelected.type === ElementType.IMAGE) {
        this.previousSelected = new ImageElement(<ImageElement>this.currentSelected);
      }
    }
  }

  public setCurrentSelectedElement(element: UiElement): void {
    if (!this.currentSelected || (this.currentSelected && !this.currentSelected.equal(element))) {
      this.manageSelectedElementStyle(element);
    }
    this.setPreviouslySelected();
    this.currentSelected = element;
  }

  private manageSelectedElementStyle(element: UiElement): void {
    if (this.currentSelected !== undefined) {
      const previousSelectedElementContainer = this.popupHTML.nativeElement.querySelector('#element-' + this.currentSelected.position);
      previousSelectedElementContainer.classList.remove('selected');
    }

    setTimeout(() => {
      const elementContainer = this.popupHTML.nativeElement.querySelector('#element-' + element.position);
      if (elementContainer !== null) {
        elementContainer.classList.add('selected');
      }
    });
  }

  public currentSelectedIs(type): boolean {
    if (this.currentSelected === undefined && type === 'None') {
      return true;
    }
    if (this.currentSelected !== undefined && this.currentSelected.type === type) {
      return true;
    }
    return false;
  }

  public isImageElementEmpty(element: ImageElement): boolean {
    if (element.imageObject === undefined || !element.imageObject.hasAny()) {
      return true;
    }
    return false;
  }

  public isTextElementEmpty(element: TextElement): boolean {
    if (element.text === undefined) {
      return true;
    } else if (this.language === Language.DE && (element.text.de === '' ||
      element.text.de === '<br>' || element.text.de === '<div><br></div>')) {
      return true;
    } else if (this.language === Language.GB && (element.text.gb === '' ||
      element.text.gb === '<br>' || element.text.gb === '<div><br></div>')) {
      return true;
    } else if (this.language === Language.JP && (element.text.jp === '' ||
      element.text.jp === '<br>' || element.text.jp === '<div><br></div>')) {
      return true;
    }
    return false;
  }

  public updateVisibility(): void {
    this.currentSelected.active = !this.currentSelected.active;
    this.uiElementsChange.emit(this.uiElements);
  }

  public updateImageFile(file: File, language: Language): void {
    if (this.currentSelected.type === ElementType.IMAGE) {
      this.contextService.setSavingContent(true);
      this.mediaService.uploadFile(file).subscribe(
        response => {
          if (response.status < 400) {
            const location: string = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
            const mediaId: string = location.substring(location.lastIndexOf('/') + 1);
            const newImageData: FileDataType = new FileDataType();
            newImageData.isLoaded = false;
            newImageData.file = file;
            newImageData.fileSafeUrl = this.getBlobUrl(newImageData.file);
            newImageData.isLoaded = true;
            newImageData.mediaId = mediaId;
            (<ImageElement>this.currentSelected).imageObject.set(language, newImageData);
            this.uiElementsChange.emit(this.uiElements);
          }
          this.contextService.setSavingContent(false);
        }
      );
    }
  }

  public removeImageFile(language: Language): void {
    if (this.currentSelected.type === ElementType.IMAGE) {
      (<ImageElement>this.currentSelected).imageObject.set(language, undefined);
      this.uiElementsChange.emit(this.uiElements);
    }
  }

  private getBlobUrl(file: File): SafeUrl {
    const urlCreator = window.URL;
    const url = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(file));
    return url;
  }

  public acceptChangesToText(change: string): void {
    this.uiElementsChange.emit(this.uiElements);
  }

  public setLanguage(language: Language): void {
    this.language = language;
  }

  public isLanguage(language: Language): boolean {
    return language === this.language;
  }

  public changeSmartphoneStyle(height, width): void {
    this.smartphoneHeight = height;
    this.smartphoneWidth = width;
  }

  public rotateSmartphone(): void {
    this.portraitMode = !this.portraitMode;
  }

  public getSmartphoneStyle() {
    const width = (this.smartphoneWidth * this.smartphoneMultiplier) + 'rem';
    const height = (this.smartphoneHeight * this.smartphoneMultiplier) + 'rem';
    const portraitBorder = '20px 10px 40px 10px';
    const landscapeBorder = '10px 40px 10px 20px';

    const smartphoneStyle = {
      'height': this.portraitMode ? height : width,
      'width': this.portraitMode ? width : height,
      'border-width': this.portraitMode ? portraitBorder : landscapeBorder
    };

    return smartphoneStyle;
  }

  public getSanitizedText(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  public onNavigateBack(): void {
    this.onBackButton.emit();
  }

  public getThumbUrl(media: Media): string {
    if (media) {
      if (media.thumbUrl) {
        return this.authService.addTokenToUrl(media.thumbUrl);
      } else if (media.rawUrl) {
        return this.authService.addTokenToUrl(media.rawUrl);
      } else {
        return '../../assets/image-placeholder.png';
      }
    }
    return '';
  }
}
