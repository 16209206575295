<div class="content">
	<div class="edit-hologram-left-side">
		<aacms-media-viewer class="preview"
			[mediaId]="this.getPreviewHologramMediaId()"
			[modelAlways]="true"
			backgroundStyle="white">
		</aacms-media-viewer>
		<ng-template [ngIf]="!this.has3DModel()">
			{{'NO_HOLOGRAM_AVAILABLE' | translate}}
		</ng-template>
	</div>

	<div class="edit-hologram-right-side">
		<ng-template [ngIf]="this.hologram && this.hologram.title">
			<div class="header">
				<div class="path-title-container">
					<div class="path">
						<div class="path-option" (click)="this.routeTo(appState.exhibitions)">{{'EXHIBITIONS' | translate}}</div>
						/
						<div class="path-option" (click)="this.routeTo(appState.images)">{{
							Tools.shortenString(contextService.currentExhibition &&
							contextService.currentExhibition.title &&
							getLanguageValue(contextService.currentExhibition.title).length > 0 ?
							getLanguageValue(contextService.currentExhibition.title) : ('TRACKERS' | translate)) }}
						</div>
						/
						<div class="path-option" (click)="this.routeTo(appState.editHologram)">{{
							Tools.shortenString(
								(hologram && this.getLanguageValue(hologram.title).length > 0) ? getLanguageValue(hologram.title) : ('UNNAMED_HOLOGRAM' | translate)
							)}}
						</div>
					</div>
					<div class="hologram-title-edit-container">
						<h1 class="edit-indicator">
							<i class="fa fa-pencil" aria-hidden="true"
								[ngStyle]="{'color': this.hologramChanged || this.hologramCardsChanged ? '#ffc107' : 'black' }"></i>
						</h1>
						<div class="hologram-title-container">
							<h1 class="hologram-title" *ngIf="this.getLanguageValue(this.hologram.title).length > 0">
								{{this.getLanguageValue(this.hologram.title)}}
							</h1>
							<h1 class="hologram-title" *ngIf="this.getLanguageValue(this.hologram.title).length == 0">
								<i>{{'UNNAMED_HOLOGRAM' | translate }}</i>
							</h1>
						</div>
					</div>
				</div>
				<aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
					(languageChanged)="this.updateLanguage($event)">
				</aacms-language-selector>
			</div>
			<div class="custom-hr"></div>
			<div>
				<i>{{ 'HOLOGRAM_SETTINGS' | translate }}:</i>
			</div>
			<div class="image-hologramCard" (click)="this.selectHologramCard(undefined)"
				[style.border]="!this.selectedHologramCard ? '2px solid #87ceeb': '2px solid gainsboro'">
				<div class="image-hologramCard-meta">
					<div class="image-hologramCard-title">
						<span *ngIf="this.getLanguageValue(this.hologram.title).length > 0">
							{{this.getLanguageValue(this.hologram.title)}}
						</span>
						<i *ngIf="this.getLanguageValue(this.hologram.title).length === 0">
							{{ 'UNNAMED_HOLOGRAM' | translate }}
						</i>
					</div>
				</div>
				<div class="image-hologramCard-edit-buttons">
					<button class="image-hologramCard-edit-button" (click)="routeToEditHologramMeta($event)"
						[matTooltip]="'EDIT' | translate" triggers="hover">
						<i class="fa fa-pencil" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			<div class="custom-hr"></div>
			<div class="hologramCard-list-label">
				{{ 'HOLOGRAM_HOLOGRAMCARDS' | translate }}
			</div>
			<div *ngIf="this.hologram && this.hologramCards.length > 0" class="hologramCard-info">
				<div class="hologramCard-list">
					<ng-template ngFor let-hologramCard [ngForOf]="this.hologramCards" [ngForTrackBy]="trackBy">
						<div class="hologramCard" (click)="selectHologramCard(hologramCard)"
							[style]="getHologramCardListEntryStyle(hologramCard)">
							<div class="hologramCard-meta" [style]="getHologramCardListTitleStyle(hologramCard)">
								<div class="hologramCard-title">
									<span *ngIf="this.getLanguageValueFromHologramCard(hologramCard).length > 0">
										<b class="hologramCard-position">#{{hologramCard.position + 1}}</b>
										{{this.getLanguageValueFromHologramCard(hologramCard)}}
									</span>
									<i *ngIf="this.getLanguageValueFromHologramCard(hologramCard).length === 0">
										<b class="hologramCard-position">#{{hologramCard.position + 1}}</b> {{'UNNAMED_HOLOGRAMCARD' | translate }}
									</i>
								</div>
							</div>
							<div class="hologramCard-edit-buttons">
								<button class="hologramCard-edit-button"
									(click)="routeToEditHologramCard($event, hologramCard)"
									[matTooltip]="'EDIT' | translate" triggers="hover">
									<i class="fa fa-pencil exhibition-icon" aria-hidden="true"></i>
								</button>
								<button *ngIf="!isHologramCardActive(hologramCard)" class="hologramCard-edit-button"
									(click)="toggleHologramCardActive(hologramCard, $event)"
									[matTooltip]="'ACTIVATE_FOR_PUBLISH' | translate" triggers="hover">
									<i class="fa fa-eye-slash exhibition-icon" aria-hidden="true"></i>
								</button>
								<button *ngIf="isHologramCardActive(hologramCard)" class="hologramCard-edit-button"
									(click)="toggleHologramCardActive(hologramCard, $event)"
									[matTooltip]="'DEACTIVATE_FOR_PUBLISH' | translate" triggers="hover">
									<i class="fa fa-eye exhibition-icon" aria-hidden="true"></i>
								</button>
								<button class="hologramCard-edit-button"
									(click)="deleteHologramCard(hologramCard, $event)"
									[matTooltip]="'DELETE' | translate" triggers="hover">
									<i class="fa fa-trash exhibition-icon" aria-hidden="true"></i>
								</button>
							</div>
						</div>
					</ng-template>
				</div>
				<div class="move-buttons">
					<button type="submit" class="move-button btn btn-light" (click)="moveHologramCardUp()"
						[matTooltip]="'MOVE_HOLOGRAMCARD_UP' | translate" matTooltipPosition="left">
						<i class="fa fa-angle-up" aria-hidden="true"></i>
					</button>
					<button type="submit" class="move-button btn btn-light" (click)="addNewHologramCard()"
						[matTooltip]="'ADD_NEW_HOLOGRAMCARD' | translate" matTooltipPosition="left">
						<i class="fa fa-plus" aria-hidden="true"></i>
					</button>
					<button type="submit" class="move-button btn btn-light" (click)="moveHologramCardDown()"
						[matTooltip]="'MOVE_HOLOGRAMCARD_DOWN' | translate" matTooltipPosition="left">
						<i class="fa fa-angle-down" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			<div *ngIf="this.hologram && this.hologramCards.length === 0" class="no-hologramCard-info">
				<i class="no-hologramCard-info-text">{{ 'NO_HOLOGRAMCARDS_AVAILABLE' | translate }}</i>
				<div class="move-buttons">
					<button type="submit" class="move-button btn btn-light" (click)="addNewHologramCard()"
						[matTooltip]="'ADD_NEW_HOLOGRAMCARD' | translate" matTooltipPosition="left">
						<i class="fa fa-plus" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			<div class="custom-hr"></div>
			<div class="row button-row">
				<button (click)="cancel()" type="button" class="btn btn-secondary custom-button">{{'BACK_TO_OVERVIEW' | translate }}</button>
				<button (click)="openModal(deleteModalTemplate)" type="button"
					class="btn btn-danger custom-button">{{'DELETE_HOLOGRAM' | translate }}</button>
				<button (click)="openModal(cancelModalTemplate)" type="button"
					[disabled]="!this.hologramChanged && !this.hologramCardsChanged"
					class="btn btn-warning custom-button">{{'DISCARD' | translate }}</button>
				<button (click)="this.onSave()" type="button" [disabled]="!this.hologramChanged && !this.hologramCardsChanged"
					class="btn btn-success custom-button">{{'SAVE' | translate }}</button>
			</div>
		</ng-template>
	</div>
</div>

<ng-template #deleteModalTemplate>
	<div class="modal-header">
		<h4 *ngIf="!hologram.title.de" class="modal-title text-break pull-left">{{'REALLY_DELETE_HOLOGRAM' | translate}}
		</h4>
		<h4 *ngIf="hologram.title.de" class="modal-title text-break pull-left">
			{{'REALLY_DELETE_HOLOGRAM1' | translate }}
			<i>'{{hologram.title.de}}'</i> {{'REALLY_DELETE_HOLOGRAM2' | translate }}
		</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-footer">
		<button (click)="modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
		<button (click)="deleteHologram()" type="button" class="btn btn-danger">{{'YES_DELETE' | translate }}</button>
	</div>
</ng-template>

<ng-template #cancelModalTemplate>
	<div class="modal-header">
		<h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
	</div>
	<div class="modal-footer">
		<button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate
			}}</button>
		<button (click)="onDiscard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
	</div>
</ng-template>