import { Injectable } from '@angular/core';
import { Config } from '../Config';
import { Language } from '../models/Language';

export class LanguageString {

  public de = '';
  public gb = '';
  public jp = '';

  public constructor(deString: string = '', gbString: string = '', jpString: string = '') {
    this.de = deString;
    this.gb = gbString;
    this.jp = jpString;
  }
}

const stringMap: Map<string, LanguageString> = new Map<string, LanguageString>([
  // new LanguageString("test")
  // toolbar
  ['EXHIBITIONS', new LanguageString('Ausstellungen')],
  ['IMAGES', new LanguageString('Bilder')],
  ['USER_MANAGEMENT', new LanguageString('Benutzerverwaltung')],
  ['LOGOUT', new LanguageString('Abmelden')],
  ['PUSH_NOTIFICATIONS', new LanguageString('Push-Benachrichtigungen')],
  ['POI_TEXTURES', new LanguageString('POI Styles')],
  ['TRACKERS', new LanguageString('Ausstellung')],
  // Push-Notifications
  ['ALREADY_SENT_PUSH_NOTIFICATIONS', new LanguageString('Bereits gesendete Push-Benachrichtigungen')],
  ['CREATE_NEW_PUSH_NOTIFICATION', new LanguageString('Neue Push-Benachrichtigung erstellen')],
  ['PUSH_NOTIFICATION_TITLE', new LanguageString('Push-Benachrichtigungstitel')],
  ['PUSH_NOTIFICATION_TEXT', new LanguageString('Push-Benachrichtigungstext')],
  ['PUSH_NOTIFICATION_URL', new LanguageString('Push-Benachrichtigungsurl')],
  ['PUSH_NOTIFICATION_CREATED_1', new LanguageString('Die Push-Benachrichtigung mit dem Titel')],
  ['PUSH_NOTIFICATION_CREATED_2', new LanguageString('wurde erfolgreich erstellt.')],
  ['NO_TITLE_AVAILABLE', new LanguageString('In dieser Sprache wurde kein Titel eingegeben')],
  ['NO_TEXT_AVAILABLE', new LanguageString('In dieser Sprache wurde kein Text eingegeben')],
  ['NO_URL_AVAILABLE', new LanguageString('In dieser Sprache wurde kein Url eingegeben')],
  ['SEND_PUSH_NOTIFICATION', new LanguageString('Nachricht senden')],
  ['REALY_SEND_PUSH_NOTIFICATION', new LanguageString('Möchten Sie die Push Benachrichtigung wirklich absenden? Diese wird umgehend in allen Apps angezeigt!')],
  ['SEND_PUSH_NOTIFICATION', new LanguageString('Nachricht bereitstellen')],
  ['URL_DOES_NOT_MATCH_1', new LanguageString('Die eingegebene Url in der Sprache')],
  ['URL_DOES_NOT_MATCH_2', new LanguageString('ist fehlerhaft!')],
  ['EMPTY_FIELDS', new LanguageString('Warnung! Folgende Felder sind nicht ausgefüllt!')],
  ['WHEN_TO_SEND', new LanguageString('Wann soll die Push-Benachrichtigung versendet werden?')],
  ['DATE', new LanguageString('Datum')],
  ['EXECUTION_TIME', new LanguageString('Ausführungszeitpunkt')],
  ['DATE_IN_PAST', new LanguageString('Der Ausführungszeitpunkt darf nicht in der Vergangenheit liegen')],
  ['DELETE_PUSH_NOTIFICATION', new LanguageString('Push-Benachrichtigung löschen')],
  ['PUSH_DELETE_QUESTION', new LanguageString('Wollen Sie die Push-Benachrichtigung wirklich löschen?')],
  ['PUSHNOTIFICATION_DELETED', new LanguageString('Push-Benachrichtigung erfolgreich gelöscht')],
  ['PUSHNOTIFICATION_SEND_FAIL', new LanguageString('Die Push-Benachrichtigung kann nicht erstellt werden. Möglicherweise liegt diese Push-Benachrichtigung zeitlich zu nahe an einer anderen oder das Feature ist deaktiviert.')],
  ['MAX_PUSHNOTIFICATIONS_PER_DAY_REACHED', new LanguageString('Das Limit von 2 Push Benachrichtigungen pro Tag wurde bereits erreicht.')],
  ['NEXT_AVAILABLE_TIME', new LanguageString('Der nächste mögliche Zeitpunkt ist der ')],
  ['CONTACT_TO_INCREASE_LIMIT', new LanguageString('Kontaktieren Sie uns, falls Sie das Limit gerne erhöhen möchten.')],
  // Richtext Editor
  ['UNDO', new LanguageString('Rückgängig')],
  ['REDO', new LanguageString('Wiederholen')],
  ['BOLD', new LanguageString('Fett')],
  ['ITALIC', new LanguageString('Kursiv')],
  ['UNDERLINE', new LanguageString('Unterstrichen')],
  ['STRIKE_THROUGH', new LanguageString('Durchgestrichen')],
  ['SUBSCRIPT', new LanguageString('Tiefsatz')],
  ['SUPERSCRIPT', new LanguageString('Hochsatz')],
  ['TEXT_COLOR', new LanguageString('Textfarbe')],
  ['BACKGROUND_COLOR', new LanguageString('Hintergrundfarbe')],
  ['JUSTIFY_LEFT', new LanguageString('Linksbündig')],
  ['JUSTIFY_CENTER', new LanguageString('Zentriert')],
  ['JUSTIFY_RIGHT', new LanguageString('Rechtsbündig')],
  ['JUSTIFY_FULL', new LanguageString('Blocksatz')],
  ['INDENT', new LanguageString('Einrückung')],
  ['OUTDENT', new LanguageString('Ausrückung')],
  ['ORDERED_LIST', new LanguageString('Nummerierte Liste')],
  ['UNORDERED_LIST', new LanguageString('Ungeordnete Liste')],
  ['INSERT_LINK', new LanguageString('Link einfügen')],
  ['UNLINK', new LanguageString('Link entfernen')],
  ['INSERT_IMAGE', new LanguageString('Bild einfügen')],
  ['INSERT_VIDEO', new LanguageString('Video einfügen')],
  ['HORIZONTAL_LINE', new LanguageString('Trennlinie einfügen')],
  ['CLEAR_FORMATING', new LanguageString('Formatierung entfernen')],
  ['TOGGLE_MODE', new LanguageString('Ansicht ändern')],
  ['HEADING1', new LanguageString('Überschrift 1')],
  ['HEADING2', new LanguageString('Überschrift 2')],
  ['HEADING3', new LanguageString('Überschrift 3')],
  ['NORMAL_TEXT', new LanguageString('Normaler Text')],
  ['SMALL_TEXT', new LanguageString('Kleiner Text')],
  // tag search
  ['SEARCH', new LanguageString('Suchen')],
  ['SEARCH_IMAGES_OR_EXHIBITIONS', new LanguageString('Bilder oder Ausstellungen nach Tag suchen:')],
  ['FILTER_SEARCH', new LanguageString('Suche Filtern:')],
  ['CONTAINS', new LanguageString('contains')],
  ['!=', new LanguageString('!=')],
  ['==', new LanguageString('==')],
  ['SMALL_BIG', new LanguageString('Groß-Klein Schreibung:')],
  ['CASE_INSENSITIVE', new LanguageString('case-insensitive')],
  ['CASE_SENSITIVE', new LanguageString('case-sensitive')],
  ['UNNAMED_EXHIBITION', new LanguageString('Unbenannte Ausstellung')],
  ['NO_RESULTS', new LanguageString('Suche liefert keine Ergebnisse')],
  ['RESULTS', new LanguageString('Suchergebnisse:')],
  ['THE_SEARCH', new LanguageString('Suche:')],
  // poi
  ['EDIT', new LanguageString('Bearbeiten')],
  ['MOVE', new LanguageString('Verschieben')],
  ['SCALE', new LanguageString('Skalieren')],
  ['DELETE', new LanguageString('Löschen')],
  ['ABORT', new LanguageString('Abbrechen')],
  // delete modal
  ['DELETE_EXHIBITION?', new LanguageString('Ausstellung löschen?')],
  ['REALY_DELETE1', new LanguageString('Wollen Sie die Ausstellung')],
  ['REALY_DELETE2', new LanguageString('und alle dazugehörigen Bilder wirklich löschen? Diese Aktion wird sofort ausgeführt und bewirkt, dass auch alle Inhalte der Ausstellung aus den Apps entfernt werden.')],
  ['YES_DELETE', new LanguageString('Ja, Löschen')],
  // Deactivate Modal
  ['DEACTIVATE_EXHIBITION?', new LanguageString('Ausstellung deaktivieren?')],
  ['REALLY_DEACTIVATE1', new LanguageString('Achtung! Wenn Sie eine Austellung deaktivieren, werden die Inhalte dieser Ausstellung von allen Endgeräten entfernt. Dies geschieht unmittelbar nach dem nächsten Start der App. Bei einer Reaktivierung müssen alle Inhalte neu heruntergeladen werden. Wollen Sie die Ausstellung dennoch deaktivieren?')],
  ['YES_DEACTIVATE', new LanguageString('Ja, Deaktivieren')],
  // multiuploadModal
  ['CREATE_IMAGES', new LanguageString('Bilder anlegen')],
  ['KLICK_OR_DRAG1_SINGLE', new LanguageString('Klicken oder Datei per')],
  ['KLICK_OR_DRAG1_MULTIPLE', new LanguageString('Klicken oder Dateien per')],
  ['KLICK_OR_DRAG2', new LanguageString('Drag & Drop einfügen')],
  ['YES_CREATE', new LanguageString('Ja, Anlegen')],
  // login
  ['WRONG_CREDENTIALS', new LanguageString('Falscher Benutzername oder Passwort')],
  ['SHOW', new LanguageString('Anzeigen')],
  ['REMEMBER_ME', new LanguageString('Anmeldename merken')],
  ['LOGIN', new LanguageString('Anmelden')],
  ['FORGOT_PASSWORD', new LanguageString('Passwort vergessen?')],
  // images
  ['EXHIBITION', new LanguageString('Ausstellung:')],
  ['CREATE_NEW_TRACKER', new LanguageString('Neuen Tracker anlegen')],
  ['CREATE_NEW_IMAGES', new LanguageString('Neue Bilder anlegen')],
  ['CREATE_NEW_CUBOID', new LanguageString('Neue Schachtel anlegen')],
  ['NO_TRACKER_FOUND', new LanguageString('Kein Tracker vorhanden')],
  ['BACK_TO_EXHIBITIONS', new LanguageString('Zurück zu den Ausstellungen')],
  ['DE_ACTIVATE_FOR_PUBLISH', new LanguageString('Für Bereitstellung aktivieren / deaktivieren')],
  ['ACTIVATE_FOR_PUBLISH', new LanguageString('Für Bereitstellung aktivieren')],
  ['DEACTIVATE_FOR_PUBLISH', new LanguageString('Für Bereitstellung deaktivieren')],
  // exhibitions
  ['CREATE_NEW_EXHIBITION', new LanguageString('Neue Ausstellung anlegen')],
  ['TITLE', new LanguageString('Titel:')],
  ['ADD', new LanguageString('Hinzufügen')],
  ['NO_EXHIBITION_FOUND', new LanguageString('Keine Ausstellung vorhanden')],
  ['EXHIBITION_PUBLISHED', new LanguageString('Die Änderungen wurden bereitgestellt. Es kann einige Zeit dauern, bis diese als Update auf den Geräten bereit stehen.')],
  ['EXHIBITION_PUBLISHED_BUT_INACTIVE', new LanguageString('Die Änderungen wurden bereitgestellt. Achtung: Die Exhibition ist derzeit noch deaktiviert. Um die Änderungen auf den Endgeräten zu sehen, aktivieren Sie diese bitte!')],
  ['ERROR_EXHIBITION_PUBLISH_CONFLICT', new LanguageString('Das vorherige Update ist noch nicht fertig gestellt. Versuchen Sie es später erneut.')],
  ['ERROR_EXHIBITION_PUBLISH_IMAGE_MISSING_SCALE', new LanguageString('Die Ausstellung konnte nicht bereitgestellt werden. Es fehlen reale Größen der Tracker: ')],
  ['ERROR_EXHIBITION_PUBLISH_PLACE_MISSING_COORDINATES', new LanguageString('Die Ausstellung konnte nicht bereitgestellt werden. Einige Orte sind nicht spezifiziert: ')],
  ['ERROR_EXHIBITION_PUBLISH_GENERIC_PRECONDITION_FALURE', new LanguageString('Die Ausstellung konnte nicht bereitgestellt werden, da einige Daten invalide sind. Bitte prüfen Sie die folgenden Elemente: ')],
  ['EXHIBITION_ACTIVE', new LanguageString('Aktivieren / Deaktivieren')],
  ['EXPORTSTATUS_0', new LanguageString('Noch nicht bereitgestellt')],
  ['EXPORTSTATUS_1', new LanguageString('Es gibt nicht veröffentlichte Änderungen')],
  ['EXPORTSTATUS_2', new LanguageString('Erfolgreich bereitgestellt')],
  // edit poi
  ['POI', new LanguageString('POI')],
  ['UNNAMED_POI', new LanguageString('Unbenannter POI')],
  ['EDIT_POI', new LanguageString('POI bearbeiten')],
  ['TEXTBOX', new LanguageString('Textfeld')],
  ['SELECT_LANGUAGE', new LanguageString('Sprache auswählen:')],
  ['NO_DESCRIPTION', new LanguageString('Keine Beschreibung vorhanden')],
  ['BACK_TO_IMAGE', new LanguageString('Zurück zum Bild')],
  ['DELETE_POI', new LanguageString('POI Löschen')],
  ['DESCRIPTION', new LanguageString('Beschreibung:')],
  ['ABORT_EDIT', new LanguageString('Bearbeiten abbrechen')],
  ['EDIT_DETAILS', new LanguageString('Infos Bearbeiten')],
  ['VIDEO', new LanguageString('Video')],
  ['AUDIO', new LanguageString('Audio')],
  ['IMAGE', new LanguageString('Bild')],
  ['DELETE_POI_QUESTION', new LanguageString('Wollen Sie den POI wirklich löschen?')],
  ['DELETE_POI_QUESTION1', new LanguageString('Wollen Sie den POI')],
  ['DELETE_POI_QUESTION2', new LanguageString('wirklich löschen?')],
  ['NO_ABORT', new LanguageString('Nein, Abbrechen')],
  ['REALY_DISMISS', new LanguageString('Wollen Sie Ihre Änderungen wirklich verwerfen?')],
  ['SAVE', new LanguageString('Speichern')],
  ['YES_DISMISS', new LanguageString('Ja, Verwerfen')],
  ['FAILED_TO_UPLOAD', new LanguageString('Bild konnte nicht hochgeladen werden. Bitte überprüfen sie die Dateigröße!')],
  ['VIDEO_TAG_NOT_SUPPORTED', new LanguageString('Ihr Browser unterstützt die Wiedergabe des Videos nicht.')],
  ['AUDIO_TAG_NOT_SUPPORTED', new LanguageString('Ihr Browser unterstützt die Wiedergabe der Tonspur nicht.')],
  ['ACCEPTED_VIDEO_FORMAT', new LanguageString('Unterstütztes Format: MP4 container, H.264 Codec')],
  ['ACCEPTED_AUDIO_FORMAT', new LanguageString('Unterstütztes Format: mp3')],
  ['POI_ACTIVE_INACTIVE', new LanguageString('POI aktiv')],
  ['POI_OPENER', new LanguageString('POI öffnet Box beim Klicken')],
  ['POI_GENERAL_TAB', new LanguageString('Allgemein')],
  ['POI_CARD_TAB', new LanguageString('Karte')],
  ['POI_CONTENT_PAGE_TAB', new LanguageString('Inhaltseditor')],
  ['POI_URL_PAGE_TAB', new LanguageString('Webadresse')],
  ['POI_CARD_AUTOGENERATE', new LanguageString('Info Karte manuell erstellen')],
  ['POI_CARD_TITLE_LABEL', new LanguageString('Karten Titel:')],
  ['POI_CARD_TEXT_LABEL', new LanguageString('Karten Text:')],
  ['POI_CARD_IMAGE_LABEL', new LanguageString('Karten Bild:')],
  ['POI_CARD_VISIBILITY', new LanguageString('Info Karte in App sichtbar')],

  // edit image
  ['UNNAMED_IMAGE', new LanguageString('Unbenanntes Bild')],
  ['CURRENT_IMAGE', new LanguageString('Aktuelles Bild:')],
  ['REAL_CUBOID_DESIGN', new LanguageString('Reales Verpackungsdesign')],
  ['AR_CUBOID_DESIGN', new LanguageString('Virtuelles Verpackungsdesign verwenden')],
  ['NO_IMAGE_AVAILABLE', new LanguageString('Kein Bild vorhanden')],
  ['BACK_TO_OVERVIEW', new LanguageString('Zurück zur Übersicht')],
  ['DELETE_IMAGE', new LanguageString('Bild Löschen')],
  ['EDIT_IMAGE', new LanguageString('Bild bearbeiten')],
  ['REALY_DELETE_IMAGE', new LanguageString('Wollen Sie das Bild wirklich löschen?')],
  ['REALY_DELETE_IMAGE1', new LanguageString('Wollen Sie das Bild')],
  ['REALY_DELETE_IMAGE2', new LanguageString('wirklich löschen?')],
  ['REALLY_DELETE_HOLOGRAM', new LanguageString('Wollen Sie das Hologram wirklich löschen?')],
  ['REALLY_DELETE_HOLOGRAM1', new LanguageString('Wollen Sie das Hologram')],
  ['REALLY_DELETE_HOLOGRAM2', new LanguageString('wirklich löschen?')],
  ['CREATE', new LanguageString('Anlegen')],
  ['REMOVE_IMAGE_DESCRIPTION', new LanguageString('Bildbeschreibung löschen')],
  ['IMAGE_GENERAL_TAB', new LanguageString('Allgemein')],
  ['IMAGE_CARD_TAB', new LanguageString('Karte')],
  ['IMAGE_CONTENT_PAGE_TAB', new LanguageString('Inhaltseditor')],
  ['IMAGE_URL_PAGE_TAB', new LanguageString('Webadresse')],
  ['TRACKER_POPUP_PAGE_TAB', new LanguageString('Popup')],
  ['TRACKER_POPUP_PAGE_HELP', new LanguageString('Wenn dieser Tracker teil einer Tour ist, dann wird dieses Popup auf dem Bildschirm zur Unterstützung des Nutzers angezeigt.\nWenn Sie diese Seite leer lassen, wird der Standard-Popup angezeigt.')],
  ['TRACKER_POPUP_BUTTON_SEARCH_NOW', new LanguageString('Ich mach mich auf die Suche', 'Okay, let\'s go!')],
  ['TRACKER_POPUP_BUTTON_FOR_LATER', new LanguageString('Für später im Verlauf speichern', 'Save in history')],
  ['IMAGE_ACTIVE_INACTIVE', new LanguageString('Bild aktiv')],
  ['IMAGE_CARDS_ACTIVE_INACTIVE', new LanguageString('Kartenkarussell in der App anzeigen')],
  ['NO_IMAGE_AVAILABLE_PLEASE_GO_TO_IMAGE_SETTINGS', new LanguageString('Kein Bild verfügbar! Sie können ein Bild über die Bildeinstellungen einfügen.')],
  ['POI_LIST_LABEL', new LanguageString('Liste der POIs:')],
  // Edit ArLayer
  ['AR_LAYER', new LanguageString('AR-Schicht')],
  ['UNNAMED_AR_LAYER', new LanguageString('Unbenannte AR-Schicht')],
  ['AR_LAYER_POI', new LanguageString('AR-Schichteinstellungen')],
  ['REMOVE_ARLAYER_DESCRIPTION', new LanguageString('AR-Schichtbeschreibung löschen')],
  ['DELETE_ARLAYER', new LanguageString('AR-Schicht löschen')],
  ['ENABLE_DISABLE_TRACKER', new LanguageString('Tracker anzeigen/ausblend')],
  ['CHANGE_OPACITY', new LanguageString('AR-Schicht Transparenz ändern')],
  ['BACK_TO_ARLAYER', new LanguageString('Zurück zur AR-Schicht')],
  ['BACK_TO_CUBOID', new LanguageString('Zurück zur Schachtel')],
  ['QR_CODE_ID', new LanguageString('QR-Code Kennung:')],
  ['USE_ARLAYER_IMAGE', new LanguageString('Virtuelles Verpackungsdesign für die ausgewählte Seite verwenden')],
  ['AR_LAYER_GENERAL_TAB', new LanguageString('Allgemein')],
  ['AR_LAYER_CARD_TAB', new LanguageString('Karte')],
  ['AR_LAYER_CONTENT_PAGE_TAB', new LanguageString('Inhaltseditor')],
  ['AR_LAYER_URL_PAGE_TAB', new LanguageString('Webadresse')],
  ['AR_LAYER_ACTIVE_INACTIVE', new LanguageString('AR-Schicht aktiv')],
  ['AR_LAYER_CARDS_ACTIVE_INACTIVE', new LanguageString('Kartenkarussell in der App anzeigen')],
  ['AR_LAYER_UNBOXING_MODEL_TAB', new LanguageString('Produkt Modell')],
  ['POI_CLICK_TO_OPEN_BOX', new LanguageString('POI der die Box öffnet')],
  ['UNBOXING_MODEL_NOTIFICATION', new LanguageString('Unboxing Animation:\n1. Laden Sie hier ein 3D Modell des Produktes hoch. \n2. Wählen Sie einen POI in der List aus.\n3. Klickt der Nutzer nun diesen POI in der mobilen Applikation, so wird die Unboxing Animation gestartet.')],
  // admin
  ['CREATE_USER', new LanguageString('Benutzer anlegen')],
  ['EDIT_USER', new LanguageString('Benutzer bearbeiten')],
  ['NAME', new LanguageString('Name')],
  ['NAME:', new LanguageString('Name:')],
  ['USERNAME:', new LanguageString('Benutzername:')],
  ['PASSWORD:', new LanguageString('Passwort:')],
  ['USER_ROLE', new LanguageString('Berechtigungsrolle:')],
  ['LIST_OF_USERS', new LanguageString('Liste aller Benutzer')],
  ['USER_ROLES', new LanguageString('Berechtigungsrollen:')],
  ['USER_DELETE_QUESTION1', new LanguageString('Wollen Sie den Benutzer')],
  ['USER_DELETE_QUESTION2', new LanguageString('wirklich löschen?')],
  ['MISSING_USER_ROLES', new LanguageString('Es muss mindestens eine Berechtigungsrolle ausgewählt werden')],
  // edit user
  ['PASSWORD_OLD:', new LanguageString('Altes Passwort:')],
  ['PASSWORD_NEW:', new LanguageString('Neues Passwort:')],
  ['PASSWORD_NEW_CONFIRM:', new LanguageString('Neues Passwort bestätigen:')],
  ['OLD_PASSWORD', new LanguageString('Altes Passwort')],
  ['NEW_PASSWORD', new LanguageString('Neues Passwort')],
  ['ERROR_OLD_PWD_EQL_NEW_PWD', new LanguageString('Das neue Passwort darf nicht dem alten Passwort entsprechen.')],
  ['ERROR_NEW_PWD_NOT_EQL_NEW_PWD_CONFIRM', new LanguageString('Die eingegebenen Passwörter stimmen nicht überein.')],
  ['OLD_PASSWORD_IS_INCORRECT', new LanguageString('Das eingegebene alte Passwort ist nicht korrekt!')],
  // TOOLTIPS
  // toolbar
  ['SHOW_ALL_EXHIBITIONS', new LanguageString('Alle Ausstellungen anzeigen')],
  ['SHOW_ALL_IMAGES', new LanguageString('Alle Bilder anzeigen')],
  ['DOKUMENTATION', new LanguageString('Zum Benutzerhandbuch')],
  // tag search
  // none
  // poi
  // none
  // deleteModal
  // none
  // multiuploadModel
  // none
  // login
  // none
  // images
  ['FILTER_TRACKERS_BY_NAME', new LanguageString('Bilder nach Namen filtern')],
  ['GO_TO_IMAGEVIEW', new LanguageString('Zur Bildansicht dieses Bildes wechseln')],
  ['GO_TO_HOLOGRAMVIEW', new LanguageString('Zur Deteailansicht dieses Holograms wechseln')],
  ['GO_TO_CUBOIDVIEW', new LanguageString('Zur Detailansicht dieser Schachtel wechseln')],
  // exhibitions
  ['REMOVE_TAG', new LanguageString('gewählten Tag entfernen')],
  ['SAVE_CHANGES', new LanguageString('Änderungen speichern')],
  ['DISMISS_CHANGES', new LanguageString('Änderungen verwerfen')],
  ['FILTER_EXHIBITION_BY_NAME', new LanguageString('Ausstellungen nach Namen filtern')],
  ['SHOW_IMAGES_OF_EXHIBITION', new LanguageString('Bilder der Ausstellung anzeigen')],
  ['START_TAG_SEARCH', new LanguageString('Tag suchen')],
  // edit poi
  ['GERMAN', new LanguageString('Deutsch')],
  ['ENGLISH', new LanguageString('Englisch')],
  ['JAPANESE', new LanguageString('Japanisch')],
  ['NO_FILE_AVAILABLE', new LanguageString('Keine Datei vorhanden')],
  ['NO_FILES_AVAILABLE', new LanguageString('Keine Dateien vorhanden')],
  // edit image
  ['REMOVE_SELECTED_TAG', new LanguageString('ausgewählten Tag entfernen')],
  ['NEW_TAG', new LanguageString('Neuer Tag')],
  ['SEARCHING', new LanguageString('Suche')],
  ['USERNAME', new LanguageString('Benutzername')],
  ['PASSWORD', new LanguageString('Passwort')],
  ['MOVE_POI_UP', new LanguageString('Aktuellen POI nach oben verschieben')],
  ['MOVE_POI_DOWN', new LanguageString('Aktuellen POI nach unten verschieben')],
  ['NO_POIS_AVAILABLE', new LanguageString('Keine POIs vorhanden')],
  ['IMAGE_POI', new LanguageString('Bildeinstellungen')],
  ['IMAGE_DIMENSIONS', new LanguageString('Reale Größe des Bildes:')],
  ['WIDTH', new LanguageString('Breite in cm:')],
  ['HEIGHT', new LanguageString('Höhe in cm:')],
  ['REALY_SAVE_WITHOUT_DIMENSIONS', new LanguageString('Sie haben die reale Bildgröße noch nicht eingetragen. Das kann zu Problemen bei der Anzeige in der App führen. Wollen Sie trotzdem speichern?')],
  ['RATING_IS_UNDERWHELMING', new LanguageString('Achtung: Die App könnte Probleme bei der Erkennung dieses Bildes bekommen.')],
  // admin
  ['EDIT_USER_DETAILS', new LanguageString('Benutzerinformationen bearbeiten')],
  ['DELETE_USER', new LanguageString('Benutzer löschen')],
  // alerts
  ['ADD_NEW_TAG_TO_IMAGE_ERROR1', new LanguageString('Das Bild hat den Tag')],
  ['ADD_NEW_TAG_TO_IMAGE_ERROR2', new LanguageString('bereits!')],
  ['UPLOAD_TEXTURE_ERROR1', new LanguageString('Die Datei mit dem Namen')],
  ['UPLOAD_TEXTURE_ERROR2', new LanguageString('konnte wegen Serverproblemen nicht hochgeladen werden!')],
  ['DOUBLE_FILE_UPLOAD_ERROR1', new LanguageString('Die Datei mit dem Namen')],
  ['DOUBLE_FILE_UPLOAD_ERROR2', new LanguageString('ist bereits Vorhanden!')],
  ['ADD_NEW_TAG_TO_EXHIBITION_ERROR1', new LanguageString('Die Ausstellung hat den Tag')],
  ['ADD_NEW_TAG_TO_EXHIBITION_ERROR2', new LanguageString('bereits!')],
  ['ADMIN_USER_REQUIRED', new LanguageString('Nur Benutzer mit Adminrechten können diese Seite sehen')],
  ['FEATURE_NOT_ENABLED', new LanguageString('Dieses Feature steht Ihnen zurzeit nicht zur Verfügung. Bitte kontaktieren Sie info@augmented-art.de für mehr Informationen.')],
  ['LOGIN_REQUIRED', new LanguageString('Nur angemeldete Benutzer können diese Seite sehen!')],
  ['EXHIBITION_NOT_FOUND1', new LanguageString('Fehler: Die Ausstellung mit der Id')],
  ['EXHIBITION_NOT_FOUND2', new LanguageString('wurde nicht gefunden.')],
  ['EXHIBITION_CREATED1', new LanguageString('Eine neue Ausstellung wurde mit der Id')],
  ['EXHIBITION_CREATED2', new LanguageString('angelegt.')],
  ['EXHIBITION_UPDATED1', new LanguageString('Die Ausstellung')],
  ['EXHIBITION_UPDATED2', new LanguageString('Die Ausstellung mit der Id')],
  ['EXHIBITION_UPDATED3', new LanguageString('wurde erfolgreich gespeichert.')],
  ['EXHIBITION_DELETED1', new LanguageString('Die Ausstellung')],
  ['EXHIBITION_DELETED2', new LanguageString('Die Ausstellung mit der Id')],
  ['EXHIBITION_DELETED3', new LanguageString('wurde erfolgreich gelöscht.')],
  ['IMAGE_NOT_FOUND1', new LanguageString('Fehler: Das Bild mit der Id')],
  ['IMAGE_NOT_FOUND2', new LanguageString('wurde nicht gefunden.')],
  ['CUBOID_NOT_FOUND1', new LanguageString('Fehler: Die Schachtel mit der Id')],
  ['CUBOID_NOT_FOUND2', new LanguageString('wurde nicht gefunden.')],
  ['ARLAYER_NOT_FOUND1', new LanguageString('Fehler: Die AR-Schicht mit der Id')],
  ['ARLAYER_NOT_FOUND2', new LanguageString('wurde nicht gefunden.')],
  ['IMAGE_CREATED1', new LanguageString('Ein neues Bild mit dem Name')],
  ['IMAGE_CREATED2', new LanguageString('Ein neues Bild mit der Id')],
  ['IMAGE_CREATED3', new LanguageString('wurde angelegt.')],
  ['CUBOID_CREATED1', new LanguageString('Eine neue Schachtel mit dem Namen')],
  ['CUBOID_CREATED2', new LanguageString('Eine neue Schachtel mit der Id')],
  ['CUBOID_CREATED3', new LanguageString('wurde angelegt.')],
  ['ARLAYER_CREATED1', new LanguageString('Eine neue AR-Schicht mit dem Namen')],
  ['ARLAYER_CREATED2', new LanguageString('Eine neue AR-Schicht mit der Id')],
  ['ARLAYER_CREATED3', new LanguageString('wurde angelegt.')],
  ['IMAGE_UPDATED1', new LanguageString('Das Bild mit dem Name')],
  ['IMAGE_UPDATED2', new LanguageString('Das Bild mit der Id')],
  ['IMAGE_UPDATED3', new LanguageString('wurde gespeichert.')],
  ['CUBOID_UPDATED1', new LanguageString('Die Schachtel mit dem Namen')],
  ['CUBOID_UPDATED2', new LanguageString('Die Schachtel mit der Id')],
  ['CUBOID_UPDATED3', new LanguageString('wurde gespeichert.')],
  ['ARLAYER_UPDATED1', new LanguageString('Die AR-Schicht mit dem Namen')],
  ['ARLAYER_UPDATED2', new LanguageString('Die AR-Schicht mit der Id')],
  ['ARLAYER_UPDATED3', new LanguageString('wurde gespeichert.')],
  ['IMAGE_DELETED1', new LanguageString('Das Bild mit dem Namen')],
  ['IMAGE_DELETED2', new LanguageString('Das Bild mit der Id')],
  ['IMAGE_DELETED3', new LanguageString('wurde erfolgreich gelöscht.')],
  ['CUBOID_DELETED1', new LanguageString('Die Schachtel mit dem Namen')],
  ['CUBOID_DELETED2', new LanguageString('Die Schachtel mit der Id')],
  ['CUBOID_DELETED3', new LanguageString('wurde erfolgreich gelöscht.')],
  ['ARLAYER_DELETED1', new LanguageString('Die AR-Schicht mit dem Namen')],
  ['ARLAYER_DELETED2', new LanguageString('Die AR-Schicht mit der Id')],
  ['ARLAYER_DELETED3', new LanguageString('wurde erfolgreich gelöscht.')],
  ['POI_NOT_FOUND1', new LanguageString('Der POI mit der Id')],
  ['POI_NOT_FOUND2', new LanguageString('wurde nicht gefunden!')],
  ['POI_CREATED1', new LanguageString('Ein neuer POI wurde mit der Id ')],
  ['POI_CREATED2', new LanguageString('angelegt.')],
  ['POI_UPDATED1', new LanguageString('Der POI')],
  ['POI_UPDATED2', new LanguageString('Der POI mit der Id')],
  ['POI_UPDATED3', new LanguageString('wurde erfolgreich gespeichert.')],
  ['POI_DELETED1', new LanguageString('Der POI')],
  ['POI_DELETED2', new LanguageString('Der POI mit der Id')],
  ['POI_DELETED3', new LanguageString('wurde erfolgreich gelöscht.')],
  ['USER_CREATED', new LanguageString('Es wurde ein neuer Benutzer angelegt.')],
  ['USER_COULD_NOT_CREATED1', new LanguageString('Der Benutzer konnte nicht angelegt werden.')],
  ['USER_COULD_NOT_CREATED2', new LanguageString('Der Benutzer ')],
  ['USER_COULD_NOT_CREATED3', new LanguageString(' konnte nicht angelegt werden.')],
  ['USER_COULD_NOT_UPDATED2', new LanguageString('Der Benutzername')],
  ['USER_COULD_NOT_UPDATED3', new LanguageString('Die Benutzerinformationen konnten nicht aktualisiert werden.')],
  ['USER_UPDATED', new LanguageString('Die Benutzerinformationen wurde aktualisiert.')],
  ['USER_COULD_NOT_UPDATED', new LanguageString('Die Benutzerinformationen konnten nicht aktualisiert werden.')],
  ['USER_NOT_FOUND1', new LanguageString('Fehler: Der Benutzer mit der Id')],
  ['USER_NOT_FOUND2', new LanguageString('wurde nicht gefunden.')],
  ['USER_DELETED', new LanguageString('Der Benutzer wurde erfolgreich gelöscht.')],
  ['INTERNAL_SERVER_ERROR', new LanguageString('SERVER FEHLER : Bitte versuchen Sie es später erneut!')],
  ['APPLY_TO_BACKEND', new LanguageString('Änderungen verteilen')],
  ['REALY_UPLOAD1', new LanguageString('Wollen Sie die Änderungen an der Ausstellung')],
  ['REALY_UPLOAD2', new LanguageString('auf den mobilen Geräten bereitstellen?')],
  ['YES_UPLOAD', new LanguageString('Ja, Bereitstellen')],
  ['NO_DONT_UPLOAD', new LanguageString('Nein, Abbrechen')],
  ['APPLY_ALL_EXHIBITIONS', new LanguageString('Alle Änderungen bereitstellen')],
  ['REALY_UPLOAD_ALL', new LanguageString('Wollen Sie wirklich alle Änderungen aller Ausstellungen bereitstellen?')],
  ['IMAGE_ACTIVATED', new LanguageString('Das Bild wurde für die nächste Bereitstellung aktiviert.')],
  ['IMAGE_DEACTIVATED', new LanguageString('Das Bild wurde für die nächste Bereitstellung deaktiviert.')],
  ['CUBOID_ACTIVATED', new LanguageString('Die Schachtel wurde für die nächste Bereitstellung aktiviert.')],
  ['CUBOID_DEACTIVATED', new LanguageString('Die Schachtel wurde für die nächste Bereitstellung deaktiviert.')],
  ['ARLAYER_ACTIVATED', new LanguageString('Die AR-Schicht wurde für die nächste Bereitstellung aktiviert.')],
  ['ARLAYER_DEACTIVATED', new LanguageString('Die AR-Schicht wurde für die nächste Bereitstellung deaktiviert.')],
  ['EXHIBITION_ACTIVATED', new LanguageString('Die Ausstellung wurde aktiviert. Ein Update steht zur Verfügung.')],
  ['EXHIBITION_DEACTIVATED', new LanguageString('Die Ausstellung wurde deaktiviert. Ein Update steht zur Verfügung')],
  // media service
  ['FILE_TO_BIG1', new LanguageString('Die Datei ist zu Groß, maximal sind')],
  ['FILE_TO_BIG2', new LanguageString('möglich!')],
  ['HOW_TO_COMPRESS', new LanguageString('So können Sie eine Datei komprimieren')],
  // Dropin
  ['MAX_FILE_SIZE', new LanguageString('Max')],
  ['WRONG_FILE_FORMAT', new LanguageString('Die Datei entspricht nicht dem erwarteten Format')],
  ['REMOVE_MEDIA', new LanguageString('Datei entfernen')],
  // Edit POI Elements
  ['IMAGE_ELEMENT', new LanguageString('Bildelement')],
  ['AUDIO_ELEMENT', new LanguageString('Audioelement')],
  ['VIDEO_ELEMENT', new LanguageString('Videoelement')],
  ['MODEL_ELEMENT', new LanguageString('3D-Element')],
  ['MAP_ELEMENT', new LanguageString('Karten-Element')],
  ['TEXT_ELEMENT', new LanguageString('Textelement')],
  ['EMPTY_ELEMENT', new LanguageString('Ich enthalte gerade keine Informationen und werde nicht in der App angezeigt!)')],
  ['IMAGE_ELEMENT_MULTILANG_FALLBACK', new LanguageString('Bildelement - Nutze eine andere für diese Sprache')],
  ['VIDEO_ELEMENT_MULTILANG_FALLBACK', new LanguageString('Videoelement - Nutze eine andere für diese Sprache')],
  ['AUDIO_ELEMENT_MULTILANG_FALLBACK', new LanguageString('Audiooelement - Nutze eine andere für diese Sprache')],
  ['MODEL_ELEMENT_MULTILANG_FALLBACK', new LanguageString('Modelelement - Nutze eine andere für diese Sprache')],
  ['POPUP_TITLE_IMAGE_ELEMENT', new LanguageString('Augmented Reality Inhalte', 'Augmented Reality Content')],
  ['POPUP_TEXT_IMAGE_ELEMENT', new LanguageString('In der Nähe befindet sich ein Objekt, das mit Augmented Reality Inhalten erweitert wird. Finde es und richte das Kamerabild so darauf, dass es möglichst komplett zu sehen ist. Nutze unten die Vorschau um das Objekt leichter zu finden.', 'There is an object nearby that is enhanced with augmented reality content. Find it and aim the camera at it so that the object is entirely visible on the screen. Use the preview below to find the object more easily.')],
  ['POPUP_TITLE_CUBOID_ELEMENT', new LanguageString('Augmented Reality Inhalte', 'Augmented Reality Content')],
  ['POPUP_TEXT_CUBOID_ELEMENT', new LanguageString('In der Nähe befindet sich ein Objekt, das mit Augmented Reality Inhalten erweitert wird. Finde es und richte das Kamerabild so darauf, dass es möglichst komplett zu sehen ist. Nutze unten die Vorschau um das Objekt leichter zu finden.', 'There is an object nearby that is enhanced with augmented reality content. Find it and aim the camera at it so that the object is entirely visible on the screen. Use the preview below to find the object more easily.')],
  ['POPUP_TITLE_PLACE_ELEMENT', new LanguageString('Sehenswürdigkeit in der Nähe', 'Place of interest')],
  ['POPUP_TEXT_PLACE_ELEMENT', new LanguageString('Du hast eine Sehenswürdigkeit in deiner Nähe entdeckt. Nutze unten die Vorschau um sie zu finden. Auf den Inhaltsseiten kannst du spannende Inhalte wie Videos, 3D Objekte und Bildern abrufen. Einfach die Vorschau mit dem Finger berühren.', 'You have reached a place of interest. Use the preview below to spot the attraction. The content pages will show you interesting related content like videos, 3D objects and images - just tap the preview.')],
  ['POPUP_TITLE_HOLOGRAM_ELEMENT', new LanguageString('Hologramm abrufen', 'Summon hologram')],
  ['POPUP_TEXT_HOLOGRAM_ELEMENT', new LanguageString('In diesem Bereich hast du die Möglichkeit ein Hologram abzurufen. Halte die Kamera leicht nach unten, sodass du auch den Boden siehst. Bewege dein Gerät nun langsam von links nach rechts. Nach kurzer Zeit sollte das Hologramm erscheinen.', 'You have the option to view a hologram in this area. Slightly angle your camera towards the ground and start moving the device from left to right slowly. After a short while, you should see a hologram.')],
  ['USE_CUSTOM_POPUP', new LanguageString('Popup manuell erstellen')],
  ['ADD_IMAGE_ELEMENT', new LanguageString('Bildelement hinzufügen')],
  ['ADD_AUDIO_ELEMENT', new LanguageString('Audioelement hinzufügen')],
  ['ADD_VIDEO_ELEMENT', new LanguageString('Videoelement hinzufügen')],
  ['ADD_MODEL_ELEMENT', new LanguageString('3D-Element hinzufügen')],
  ['ADD_MAP_ELEMENT', new LanguageString('Karten-Element hinzufügen')],
  ['ADD_TEXT_ELEMENT', new LanguageString('Textelement hinzufügen')],
  ['NO_FILE_PRESENT', new LanguageString('Keine Datei vorhanden')],
  ['NO_ELEMENT_SELECTED', new LanguageString('Aktuell ist kein Element ausgewählt!')],
  ['MOVE_ELEMENT_UP', new LanguageString('Aktuelles Element nach oben verschieben')],
  ['MOVE_ELEMENT_DOWN', new LanguageString('Aktuelles Element nach unten verschieben')],
  ['DELETE_ELEMENT', new LanguageString('Aktuelles Element löschen')],
  ['UNSELECT_ELEMENT', new LanguageString('Aktuelles Element abwählen')],
  // App Menu
  ['APP_MENU', new LanguageString('App Menü')],
  ['ADD_LIST_MENU', new LanguageString('Neues Listenmenü hinzufügen')],
  ['ADD_GRID_MENU', new LanguageString('Neues Rastermenü hinzufügen')],
  ['ADD_CONTENT_PAGE', new LanguageString('Neue Inhaltsseite hinzufügen')],
  ['CREATE_NEW_MENU', new LanguageString('Neues Menü anlegen')],
  ['LISTMENU', new LanguageString('Listenmenü')],
  ['GRIDMENU', new LanguageString('Rastermenü')],
  ['MENU_ENTRY', new LanguageString('Menüpunkt')],
  ['MENU_ENTRY_LISTMENU', new LanguageString('(→Listenmenü)')],
  ['MENU_ENTRY_GRIDMENU', new LanguageString('(→Rastermenü)')],
  ['MENU_ENTRY_CONTENTPAGE', new LanguageString('(→Inhaltsseite)')],
  ['DISCARD', new LanguageString('Verwerfen')],
  ['REALLY_DISCARD', new LanguageString('Wollen Sie die Änderungen wirklich verwerfen?')],
  ['YES_DISCARD', new LanguageString('Ja, verwerfen')],
  ['MENU_CREATED', new LanguageString('Das Menü wurde erfolgreich gespeichert.')],
  ['MENU_DELETED', new LanguageString('Das Menü wurde erfolgreich gelöscht.')],
  ['PUBLISH', new LanguageString('Bereitstellen')],
  ['PUBLISH_MENU', new LanguageString('Wollen Sie das Menü auf alles Geräten bereitstellen?')],
  ['MENU_PUBLISHED', new LanguageString('Das Menü wurde bereitgestellt.')],
  ['SHOW_LOGO', new LanguageString('Logo im Kopfzeilen-Bild anzeigen')],
  ['TYPE_OF_MENU_ENTRY', new LanguageString('Typ des Menüpunktes:')],

  ['TITEL_OF_LIST_MENUENTRY', new LanguageString('Titel dieses Listenmenüpunktes:')],
  ['TITEL_OF_GRID_MENUENTRY', new LanguageString('Titel dieses Rastermenüpunktes:')],
  ['TITEL_OF_CONTENT_MENUENTRY', new LanguageString('Titel des Inhaltsmenüpunktes:')],
  ['IMAGE_OF_LIST_MENUENTRY', new LanguageString('Bild des übergeordneten Listenmenüpunktes:')],
  ['IMAGE_OF_MENUENTRY', new LanguageString('Bild des übergeordneten Menüpunktes:')],
  ['IMAGE_OF_GRID_MENUENTRY', new LanguageString('Bild des übergeordneten Rastermenüpunktes:')],
  ['IMAGE_OF_CONTENT_MENUENTRY', new LanguageString('Bild des übergeordneten Inhaltsmenüpunktes:')],
  ['HEADERIMAGE_FOR_LIST_MENU', new LanguageString('Kopfzeilen-Bild für dieses Listenmenü:')],
  ['HEADERIMAGE_FOR_GRID_MENU', new LanguageString('Kopfzeilen-Bild für dieses Rastermenü:')],
  ['GRID_MENU_POINT_SETTINGS', new LanguageString('Rastermenüpunkt Einstellungen')],
  ['LIST_MENU_POINT_SETTINGS', new LanguageString('Listenmenüpunkt Einstellungen')],
  ['CONTENT_MENU_POINT_SETTINGS', new LanguageString('Inhaltsmenüpunkt Einstellungen')],
  ['MENU_POINT_CONTENT', new LanguageString('Inhaltseditor')],
  ['MENU_POINT_URL', new LanguageString('Webadresse')],

  // Manage Media
  ['MEDIA_FILE', new LanguageString('Media-Datei:')],
  // Smartphone
  ['SMALL_PHONE', new LanguageString('Kleines Handy')],
  ['BIG_PHONE', new LanguageString('Großes Handy')],
  ['TABLET', new LanguageString('Tablet')],
  ['ROTATE_SMARTPHONE', new LanguageString('Smartphone rotieren')],
  ['ROTATE_SMARTPHONE_DISABLED', new LanguageString('Smartphone rotieren in dieser Ansicht deaktiviert')],
  ['ELEMENT_VISIBLE', new LanguageString('Element in der App anzeigen')],
  ['ELEMENT_HIDDEN', new LanguageString('Element in der App ausblenden')],
  ['HIDE_ELEMENT', new LanguageString('Element in der App ausblenden')],
  ['SMARTPHONE_SINGLE_ELEMENT_INFO', new LanguageString('Ist nur eines der Elemente (Bild, Video, Audio, 3D Objekt) hinterlegt, wird dieses in der App direkt in Vollbild angezeigt!')],
  // OnDeactivateGuard
  ['UNSAVED_CHANGES', new LanguageString('Sie haben nicht gespeicherte Änderungen!')],
  ['STAY', new LanguageString('Auf Seite bleiben')],
  ['DISCARD_CHANGES_AND_LEAVE', new LanguageString('Verwerfen und weiter')],
  ['SAVE_AND_LEAVE', new LanguageString('Speichern und weiter')],
  ['WARNING', new LanguageString('Warnung')],
  // Language Selector
  ['LANGUAGE_SELECTED', new LanguageString('Ausgewählte Sprache:')],
  // POI Types
  ['CUSTOM', new LanguageString('Benutzerdefiniert')],
  ['URL', new LanguageString('Webadresse')],
  ['TYPE_OF_CONTENT_PAGE', new LanguageString('Typ der Inhaltsseite:')],
  ['UPDATE_VIEW', new LanguageString('Anzeige aktualisieren')],
  ['TOGGLE_TYPE', new LanguageString('Typ wechseln')],
  // URL Page
  ['HTTP_SITE', new LanguageString('HTTP Seiten können aus Sicherheitsgründen nicht angezeigt werden. Bitte verwenden Sie wenn möglich eine HTTPS Seite.')],
  // Edit Cuboid
  ['CUBOID', new LanguageString('Schachtel')],
  ['EDIT_CUBOID', new LanguageString('Schachtel bearbeiten')],
  ['REALY_DELETE_CUBOID', new LanguageString('Wollen Sie die Schachtel wirklich löschen?')],
  ['MOVE_ARLAYER_UP', new LanguageString('Aktuelle AR-Schicht nach oben verschieben')],
  ['MOVE_ARLAYER_DOWN', new LanguageString('Aktuelle AR-Schicht nach unten verschieben')],
  ['ADD_ARLAYER', new LanguageString('Neue AR-Schicht hinzufügen')],
  ['CUBOID_DIMENSIONS', new LanguageString('Reale Größe der Schachtel:')],
  ['DEPTH', new LanguageString('Tiefe in cm:')],
  ['UNNAMED_CUBOID', new LanguageString('Unbenannte Schachtel')],
  ['CUBOID_GENERAL_TAB', new LanguageString('Allgemein')],
  ['CUBOID_ACTIVE_INACTIVE', new LanguageString('Schachtel aktiv')],
  ['CUBOID_CARDS_ACTIVE_INACTIVE', new LanguageString('Kartenkarussell in der App anzeigen')],
  ['REMOVE_CUBOID_DESCRIPTION', new LanguageString('Schachteleinstellungen zurücksetzen')],
  ['CUBOID_META', new LanguageString('Schachteleinstellungen')],
  ['BACK_TO_CUBOID', new LanguageString('Zurück zur Schachtel')],
  ['DELETE_CUBOID', new LanguageString('Schachtel löschen')],
  // Cuboid Viewer
  ['CUBOID_FRONT', new LanguageString('Vorne')],
  ['CUBOID_RIGHT', new LanguageString('Rechts')],
  ['CUBOID_BACK', new LanguageString('Hinten')],
  ['CUBOID_LEFT', new LanguageString('Links')],
  ['CUBOID_TOP', new LanguageString('Oben')],
  ['CUBOID_BOTTOM', new LanguageString('Unten')],
  ['AR_LAYER_LIST_LABEL', new LanguageString('Liste der AR-Schichten:')],

  ['TRACKING_QUALITY', new LanguageString('Erkennungsqualität des Bildes')],
  // POI Textures
  ['TEXTURE_DELETED', new LanguageString('Der POI-Style wurde erfolgreich gelöscht.')],
  ['TEXTURE_UPDATED', new LanguageString('Die Änderungen am POI-Style wurde erfolgreich geändert.')],
  ['TEXTURE_CREATED', new LanguageString('Der POI-Style wurde gespeichert.')],
  ['TEXTURE_NOT_FOUND', new LanguageString('Der POI-Style wurde nicht gefunden.')],
  ['DELETE_POI_TEXTURE', new LanguageString('POI-Style löschen')],
  ['CREATE_NEW_POI_TEXTURE', new LanguageString('Neuen POI-Style erstellen')],
  ['AVAILABLE_POI_TEXTURE', new LanguageString('Verfügbare POI-Styles')],
  ['CREATE_POI_TEXTURE', new LanguageString('POI-Style erstellen')],
  ['DEFAULT_TEXTURE_1', new LanguageString('Standard Style 1')],
  ['DEFAULT_TEXTURE_2', new LanguageString('Standard Style 2')],
  ['DEFAULT_TEXTURE_3', new LanguageString('Nicht sichtbar')],
  ['DISTANCE' , new LanguageString('Abstand zum Tracker in cm:')],
  ['CREATE_NEW_POI_STYLES', new LanguageString('POI Styles erstellen')],
  ['MISSING_META_INFORMATION', new LanguageString('Wichtige Informationen sind noch nicht eingegeben worden')],
  ['PREVIEW_MAY_LOOK_DIFFERENT', new LanguageString('Diese Vorschau kann von der App abweichen')],
  ['POI_STYLE_IN_USE', new LanguageString('Dieser Style kann nicht geloescht werden, da er noch in einem POI verwendet wird.')],
  ['POI_STYLE_ROTATION', new LanguageString('3-Achsen Rotation des 3D-Modells:')],
  ['IMAGE_NOT_TRACKABLE_1', new LanguageString('Das Bild mit dem Namen ')],
  ['IMAGE_NOT_TRACKABLE_2', new LanguageString(' konnte nicht gespeichert werden. Das Bild ist nicht dazu geeignet von der App erkannt zu werden!')],
  // TASKS
  ['IMPORT_DATABASE', new LanguageString('Daten importieren')],
  ['DUMP_DATABASE', new LanguageString('Daten exportieren')],
  ['TASK_EXECUTED', new LanguageString('Der Task wurde ausgeführt, bitte haben Sie etwas Gedult.')],

  ['RESOURCE_LOCKED', new LanguageString('Die gewünschte Resource wird gerade von einem anderen Nutzer bearbeitet.')],
  ['SESSION_EXPIRES_1', new LanguageString('Ihre Session wird in')],
  ['SESSION_EXPIRES_2', new LanguageString('Minute(n) ablaufen. Um diese zu verlängern klicken Sie auf die Zeit oben rechts im Fenster.')],
  // TOURS
  ['TOUR_EDITOR', new LanguageString('Tourenplaner')],
  ['GPX_FILE_BROKEN', new LanguageString('Die GPX-Datei ist nicht in einem gültigen Format!')],
  ['GEOJSON_FILE_BROKEN', new LanguageString('Die GeoJson-Datei ist nicht in einem gültigen Format!')],
  ['GPX_CONTAINS_ROUTE', new LanguageString('Die GPX-Datei enthält ein Routenelement welches Ignoriert wird!')],
  ['GPX_CONTAINS_MORE_THAN_ONE_TRACK', new LanguageString('Die GPX-Datei enthält mehrere Routen!')],
  ['GPX_CONTAINS_NO_TRACK', new LanguageString('Die GPX-Datei enthält keine Routen!')],
  ['TOURS', new LanguageString('Touren')],
  ['FILTER_TOURS_BY_NAME', new LanguageString('Touren nach Namen Filtern')],
  ['CREATE_NEW_TOUR', new LanguageString('Neue Tour anlegen')],
  ['NO_TOUR_FOUND', new LanguageString('Keine Touren gefunden')],
  ['GO_TO_TOURVIEW', new LanguageString('Tour anzeigen')],
  ['TOUR_CREATED', new LanguageString('Eine neue Tour wurde angelegt.')],
  ['TOUR_ACTIVATED', new LanguageString('Die Tour wurde für die nächste Bereitstellung aktiviert.')],
  ['TOUR_DEACTIVATED', new LanguageString('Die Tour wurde für die nächste Bereitstellung deaktiviert.')],
  ['UNNAMED_TOUR', new LanguageString('Unbenannte Tour')],
  ['TOUR', new LanguageString('Tour')],
  ['TOUR_SETTINGS', new LanguageString('Tour Einstellungen')],
  ['TOUR_WAYPOINTS', new LanguageString('Tour Wegpunkte')],
  ['UNNAMED_WAYPOINT', new LanguageString('Unbenannter Wegpunkt')],
  ['NO_WAYPOINTS_AVAILABLE', new LanguageString('Keine Wegpunkte verfügbar')],
  ['DELETE_TOUR', new LanguageString('Tour löschen')],
  ['REALY_DELETE_TOUR', new LanguageString('Wollen Sie die Tour wirklich löschen?')],
  ['REALY_DELETE_TOUR1', new LanguageString('Wollen Sie die Tour')],
  ['REALY_DELETE_TOUR2', new LanguageString('wirklich löschen?')],
  ['CREATE_TOURS', new LanguageString('Touren anlegen')],
  ['NO_TOUR_AVAILABLE', new LanguageString('Keine GPX-Tour vorhanden')],
  ['TOUR_META', new LanguageString('Toureinstellungen')],
  ['TOUR_GENERAL_TAB', new LanguageString('Allgemein')],
  ['TOUR_CARD_TAB', new LanguageString('Karte')],
  ['TOUR_CONTENT_PAGE_TAB', new LanguageString('Inhaltseditor')],
  ['TOUR_URL_PAGE_TAB', new LanguageString('Webadresse')],
  ['TOUR_ACTIVE_INACTIVE', new LanguageString('Tour aktiv')],
  ['BACK_TO_TOUR', new LanguageString('Zurück zur Tour')],
  ['CURRENT_TOUR_DATA', new LanguageString('Aktuelle Tourdaten:')],
  ['CREATE_NEW_PLACE', new LanguageString('Neuen Ort anlegen')],
  ['CREATE_NEW_HOLOGRAM', new LanguageString('Neues Hologramm anlegen')],
  ['MOVE_WAYPOINT_UP', new LanguageString('Aktuellen Wegpunkt nach oben verschieben')],
  ['MOVE_WAYPOINT_DOWN', new LanguageString('Aktuellen Wegpunkt nach unten verschieben')],
  // Locations / Infocards
  ['PLACE_NOT_FOUND1', new LanguageString('Fehler: Der Ort mit der Id')],
  ['PLACE_NOT_FOUND2', new LanguageString('wurde nicht gefunden.')],
  ['PLACE_CREATED1', new LanguageString('Ein neuer Ort mit dem Name')],
  ['PLACE_CREATED2', new LanguageString('Ein neuer Ort mit der Id')],
  ['PLACE_CREATED3', new LanguageString('wurde angelegt.')],
  ['PLACE_UPDATED1', new LanguageString('Der Ort mit dem Name')],
  ['PLACE_UPDATED2', new LanguageString('Der Ort mit der Id')],
  ['PLACE_UPDATED3', new LanguageString('wurde gespeichert.')],
  ['PLACE_DELETED1', new LanguageString('Der Ort mit dem Namen')],
  ['PLACE_DELETED2', new LanguageString('Der Ort mit der Id')],
  ['PLACE_DELETED3', new LanguageString('wurde erfolgreich gelöscht.')],
  ['PLACE_ACTIVATED', new LanguageString('Der Ort wurde für die nächste Bereitstellung aktiviert.')],
  ['PLACE_DEACTIVATED', new LanguageString('Der Ort wurde für die nächste Bereitstellung deaktiviert.')],

  ['PLACES', new LanguageString('Orte')],
  ['PLACE', new LanguageString('Ort')],
  ['UNNAMED_PLACE', new LanguageString('Unbenannter Ort')],
  ['PLACE_SETTINGS', new LanguageString('Ort Einstellungen')],
  ['PLACE_INFOCARDS', new LanguageString('Infokarten')],
  ['MOVE_INFOCARD_UP', new LanguageString('Aktuelle Infokarte nach oben verschieben')],
  ['MOVE_INFOCARD_DOWN', new LanguageString('Aktuelle Infokarte nach unten verschieben')],
  ['DELETE_PLACE', new LanguageString('Ort löschen')],
  ['REALY_DELETE_PLACE', new LanguageString('Wollen Sie die Ort wirklich löschen?')],
  ['REALY_DELETE_PLACE1', new LanguageString('Wollen Sie die Ort')],
  ['REALY_DELETE_PLACE2', new LanguageString('wirklich löschen?')],
  ['UNNAMED_INFOCARD', new LanguageString('Unbenannte Infokarte')],
  ['NO_INFOCARDS_AVAILABLE', new LanguageString('Keine Infokarten verfügbar')],
  ['INFOCARD_NOT_FOUND1', new LanguageString('Fehler: Die Infokarte mit der Id')],
  ['INFOCARD_NOT_FOUND2', new LanguageString('wurde nicht gefunden.')],
  ['INFOCARD_CREATED1', new LanguageString('Eine neue Infokarte mit dem Name')],
  ['INFOCARD_CREATED2', new LanguageString('Eine neue Infokarte mit der Id')],
  ['INFOCARD_CREATED3', new LanguageString('wurde angelegt.')],
  ['INFOCARD_UPDATED1', new LanguageString('Die Infokarte mit dem Name')],
  ['INFOCARD_UPDATED2', new LanguageString('Die Infokarte mit der Id')],
  ['INFOCARD_UPDATED3', new LanguageString('wurde gespeichert.')],
  ['INFOCARD_DELETED1', new LanguageString('Die Infokarte mit dem Namen')],
  ['INFOCARD_DELETED2', new LanguageString('Die Infokarte mit der Id')],
  ['INFOCARD_DELETED3', new LanguageString('wurde erfolgreich gelöscht.')],
  ['INFOCARD_ACTIVATED', new LanguageString('Die Infokarte wurde für die nächste Bereitstellung aktiviert.')],
  ['INFOCARD_DEACTIVATED', new LanguageString('Die Infokarte wurde für die nächste Bereitstellung deaktiviert.')],
  ['ADD_NEW_INFOCARD', new LanguageString('Infokarte erstellen')],
  ['PLACE_POI', new LanguageString('Orteinstellungen')],
  ['PLACE_CARDS_ACTIVE_INACTIVE', new LanguageString('Kartenkarussell in der App anzeigen')],
  ['PLACE_GENERAL_TAB', new LanguageString('Allgemein')],
  ['PLACE_ACTIVE_INACTIVE', new LanguageString('Ort aktiv')],
  ['PLACE_CARD_TAB', new LanguageString('Karte')],
  ['BACK_TO_PLACE', new LanguageString('Zurück zum Ort')],
  ['PLACE_CONTENT_PAGE_TAB', new LanguageString('Inhaltseditor')],
  ['PLACE_URL_PAGE_TAB', new LanguageString('Webadresse')],
  ['PLACE_LOCATION_INPUT', new LanguageString('Positionseingabe:')],
  ['PLACE_LOCATION_LON_INPUT', new LanguageString('Längengrad:')],
  ['PLACE_LOCATION_LAT_INPUT', new LanguageString('Breitengrad:')],
  ['PLACE_RADIUS_INPUT', new LanguageString('Radiuseingabe in Metern:')],
  ['PLACE_TRIGGER_BEHAVIOR', new LanguageString('Trigger Verhalten')],

  ['GPS_TRIGGER_BEHAVIOR_ALWAYS', new LanguageString('Triggert in jedem Kontext')],
  ['GPS_TRIGGER_BEHAVIOR_ACTIVE_TOUR', new LanguageString('Triggert nur in aktiven Touren')],
  ['GPS_TRIGGER_BEHAVIOR_ACTIVE_LINKED_TOUR', new LanguageString('Triggert nur in aktiven Touren, die diesen Ort als Wegpunkt enthalten')],
  ['GPS_TRIGGER_BEHAVIOR_NONACTIVE_TOUR', new LanguageString('Triggert nur, wenn keine Tour aktiv ist')],

  ['INFOCARD', new LanguageString('Infokarte')],
  ['INFOCARD_GENERAL_TAB', new LanguageString('Allgemein')],
  ['INFOCARD_ACTIVE_INACTIVE', new LanguageString('Infokarte aktiv')],
  ['INFOCARD_CARDS_ACTIVE_INACTIVE', new LanguageString('Kartenkarussell in der App anzeigen')],
  ['INFOCARD_CARD_TAB', new LanguageString('Karte')],
  ['BACK_TO_PLACE', new LanguageString('Zurück zum Ort')],
  ['INFOCARD_CONTENT_PAGE_TAB', new LanguageString('Inhaltseditor')],
  ['INFOCARD_URL_PAGE_TAB', new LanguageString('Webadresse')],

  ['UNNAMED_HOLOGRAM', new LanguageString('Unbenanntes Hologramm')],
  ['UNNAMED_HOLOGRAMCARD', new LanguageString('Unbenannte Hologrammkarte')],
  ['NO_HOLOGRAMCARDS_AVAILABLE', new LanguageString('Keine Hologrammkarten verfügbar')],
  ['HOLOGRAM_SETTINGS', new LanguageString('Hologramm Einstellungen')],
  ['HOLOGRAM_HOLOGRAMCARDS', new LanguageString('Hologrammkarten')],
  ['MOVE_HOLOGRAMCARD_UP', new LanguageString('Aktuelle Hologrammkarte nach oben verschieben')],
  ['MOVE_HOLOGRAMCARD_DOWN', new LanguageString('Aktuelle Hologrammkarte nach unten verschieben')],
  ['ADD_NEW_HOLOGRAMCARD', new LanguageString('Hologramkarte erstellen')],
  ['HOLOGRAMCARD_NOT_FOUND1', new LanguageString('Fehler: Die Hologrammkarte mit der Id')],
  ['HOLOGRAMCARD_NOT_FOUND2', new LanguageString('wurde nicht gefunden.')],
  ['HOLOGRAMCARD_CREATED2', new LanguageString('Eine neue Hologrammkarte mit der Id')],
  ['HOLOGRAMCARD_CREATED3', new LanguageString('wurde angelegt.')],
  ['HOLOGRAMCARD_UPDATED1', new LanguageString('Die Hologrammkarte mit dem Name')],
  ['HOLOGRAMCARD_UPDATED2', new LanguageString('Die Hologrammkarte mit der Id')],
  ['HOLOGRAMCARD_UPDATED3', new LanguageString('wurde gespeichert.')],
  ['HOLOGRAMCARD_DELETED1', new LanguageString('Die Hologrammkarte mit dem Namen')],
  ['HOLOGRAMCARD_DELETED2', new LanguageString('Die Hologrammkarte mit der Id')],
  ['HOLOGRAMCARD_DELETED3', new LanguageString('wurde erfolgreich gelöscht.')],
  ['DELETE_HOLOGRAM', new LanguageString('Hologram löschen')],
  ['REALLY_DELETE_HOLOGRAM', new LanguageString('Wollen Sie dieses Hologramm wirklich löschen?')],
  ['REALLY_DELETE_HOLOGRAM1', new LanguageString('Wollen Sie dieses Hologramm')],
  ['REALLY_DELETE_HOLOGRAM2', new LanguageString('wirklich löschen?')],
  ['HOLOGRAM_NOT_FOUND1', new LanguageString('Fehler: Das Hologram mit der Id')],
  ['HOLOGRAM_NOT_FOUND2', new LanguageString('wurde nicht gefunden.')],
  ['HOLOGRAM_ACTIVE_INACTIVE', new LanguageString('Hologram aktiv')],
  ['HOLOGRAM_CARDS_ACTIVE_INACTIVE', new LanguageString('Kartenkarussell in der App anzeigen')],
  ['BACK_TO_HOLOGRAM', new LanguageString('Zurück zum Hologramm')],
  ['HOLOGRAMCARD', new LanguageString('Hologrammkarte')],
  ['HOLOGRAMCARD_GENERAL_TAB', new LanguageString('Allgemein')],
  ['HOLOGRAMCARD_ACTIVE_INACTIVE', new LanguageString('Hologrammkarte aktiv')],
  ['HOLOGRAMCARD_CARD_TAB', new LanguageString('Karte')],
  ['HOLOGRAMCARD_CONTENT_PAGE_TAB', new LanguageString('Inhaltseditor')],
  ['HOLOGRAMCARD_URL_PAGE_TAB', new LanguageString('Webadresse')],
  ['HOLOGRAMCARD_UPLOAD_CARDSPECIFIC_MODEL', new LanguageString('Standardmäßig übernimmt eine Hologrammkarte das 3D-Modell vom Hologramm. Hier können Sie ein kartenspezifisches & sprachspezifisches 3D-Model hochladen.')],
  ['HOLOGRAMCARD_CURRENT_CARDSPECIFIC_MODEL', new LanguageString('Hologrammkarten-spezifisches 3D-Modell:')],
  ['CURRENT_HOLOGRAM', new LanguageString('Aktuelles Hologramm 3D-Modell:')],
  ['NO_HOLOGRAM_AVAILABLE', new LanguageString('Kein Hologramm 3D-Modell vorhanden')],
  ['HOLOGRAM_UPLOAD_LANGSPECIFIC_MODEL', new LanguageString('Ein anderes Hologramm 3D-Modell für diese Sprache verwenden:')],
  ['HOLOGRAM_UPDATED1', new LanguageString('Das Hologramm')],
  ['HOLOGRAM_UPDATED2', new LanguageString('Das Hologramm mit der Id')],
  ['HOLOGRAM_UPDATED3', new LanguageString('wurde erfolgreich gespeichert.')],
  ['HOLOGRAM_POSITION_TRIGGERS', new LanguageString('Positions Trigger für dieses Hologramm:')],
  ['HOLOGRAM_OPTICAL_TRIGGERS', new LanguageString('Optische Trigger für dieses Hologramm:')],
  ['HOLOGRAM_DIMENSIONS', new LanguageString('Voraussichtliche Dimensionen des Hologramms:')],
  ['HOLOGRAM_DIMENSIONS_WIDTH', new LanguageString('Breite')],
  ['HOLOGRAM_DIMENSIONS_HEIGHT', new LanguageString('Höhe')],
  ['HOLOGRAM_DIMENSIONS_DEPTH', new LanguageString('Tiefe')],
  ['HOLOGRAM_SCALE_FACTOR', new LanguageString('Skalierungsfaktor für die Anzeige des Hologramms:')],
  ['HOLOGRAM_POI', new LanguageString('Hologrammeinstellungen')],
  ['HOLOGRAM_GENERAL_TAB', new LanguageString('Allgemein')],
  ['HOLOGRAM_CARD_TAB', new LanguageString('Karte')],
  ['HOLOGRAM_CONTENT_PAGE_TAB', new LanguageString('Inhaltseditor')],
  ['HOLOGRAM_URL_PAGE_TAB', new LanguageString('Webadresse')],
  ['HOLOGRAM_TRIGGER_BEHAVIOR', new LanguageString('Triggerverhalten')],

  ['REMOVE_TRIGGER', new LanguageString('Trigger löschen')],
  ['SELECT_TRACKER', new LanguageString('Tracker auswählen')],
  ['SELECTED_TRACKER', new LanguageString('Ausgewählter Tracker')],
  ['NO_TRACKER_SELECTED', new LanguageString('Kein Tracker ausgewählt')],
  ['ROUTE_TO_TRACKER', new LanguageString('Zum Tracker navigieren')],
  ['BOUNDING_TEXT', new LanguageString('Um den scrollbaren Bereich der Karte für die App einzuschränken, die Karte auf der rechten Seite auf die entsprechende Größe zoomen und den Button unten klicken. Dies verhindert, das Nutzer den von Ihnen vorgegeben Bereich verlassen und an einem beliebigen Ort der Welt landen.')],
  ['BOUNDING_BUTTON_TEXT', new LanguageString('Bereich festlegen')],
  ['LON_DOES_NOT_MATCH', new LanguageString('Längengrad eines Punktes liegt außerhalb des gültigen Bereichs von -180° - +180°')],
  ['LAT_DOES_NOT_MATCH', new LanguageString('Breitengrad eines Punktes liegt außerhalb des gültigen Bereichs von -90° - +90°')],
  ['UPDATE_DISTANCE', new LanguageString('Der Radius des Geofence in Meter. Beim Betreten dieser virtuellen Zone wird dieser Wegpunkt aktiviert.')],
  ['DOWNLOAD_MEDIA', new LanguageString('Datei herunterladen')],

  ['TOUR_MENU_POINT_SETTINGS', new LanguageString('Tourmenüpunkt Einstellungen')],
  ['AR_MENU_POINT_SETTINGS', new LanguageString('AR-Menüpunkt Einstellungen')],

  ['TOUR_UPDATED1', new LanguageString('Die Tour')],
  ['TOUR_UPDATED2', new LanguageString('Die Tour mit der Id')],
  ['TOUR_UPDATED3', new LanguageString('wurde erfolgreich gespeichert.')],

  ['TOUR_PUBLISHED', new LanguageString('Die Änderungen wurden bereitgestellt. Es kann einige Zeit dauern, bis diese als Update auf den Geräten bereit stehen.')],
  ['APPLY_ALL_TOURS', new LanguageString('Alle Änderungen bereitstellen')],
  ['REALY_UPLOAD_TOUR1', new LanguageString('Wollen Sie die Änderungen an der Tour')],
  ['REALY_UPLOAD_TOUR2', new LanguageString('auf den mobilen Geräten bereitstellen?')],
  ['REALY_UPLOAD_ALL_TOURS', new LanguageString('Wollen Sie wirklich alle Änderungen aller Touren bereitstellen?')],

  ['GEOJSON_FEATURE_COORDINATES_BROKEN1', new LanguageString('Die Koordinaten eines Features sind Fehlerhaft! Das Feature mit folgenden Eigenschaften:')],
  ['GEOJSON_FEATURE_COORDINATES_BROKEN2', new LanguageString('wird ignoriert!')],
  ['POSITION', new LanguageString('Position')],
  ['FEATURE_TYPE', new LanguageString('Typ')],
  ['COORDINATES', new LanguageString('Koordinaten')],

  ['GEOJSON_FILE_NOT_PARSABLE1', new LanguageString('Die übergebene GeoJson Datei hat in Zeile')],
  ['GEOJSON_FILE_NOT_PARSABLE2', new LanguageString('einen Fehler! Die Datei kann so nicht verwendet werden!')],
  ['GPX_FILE_NOT_PARSABLE1', new LanguageString('Die übergebene GPX Datei hat in Zeile')],
  ['GPX_FILE_NOT_PARSABLE2', new LanguageString('einen Fehler! Die Datei kann so nicht verwendet werden!')],
  ['NAVIGATE_TO_TOUR', new LanguageString('Zur Tour wechseln')],
  ['LON_LAT_EXAMPLETEXT', new LanguageString('Der Wert für Längen- und Breitengrad muss mit . separiert eingegeben werden. Beispielsweiße: 51.315980242098270')],
  ['WALK_TOUR_IN_ORDER_TOGGLE', new LanguageString('Reihenfolge der Stationen in der App beachten')],
  ['BOUNDINGBOX_VALUES_BOTTOMLEFT', new LanguageString('Grenze unten links:')],
  ['BOUNDINGBOX_VALUES_TOPRIGHT', new LanguageString('Grenze oben rechts:')],

  ['TRACKER_NOT_FOUND1', new LanguageString('Fehler: Der Tracker mit der Id')],
  ['TRACKER_NOT_FOUND2', new LanguageString('wurde nicht gefunden.')],
  ['CLEAR_POSITION_INPUT', new LanguageString('Position löschen')],
  ['TOUR_ACCESSABILITY', new LanguageString('Barrierefreiheit:')],
  ['BARRIER_FREE', new LanguageString('barrierefrei')],
  ['NOT_BARRIER_FREE', new LanguageString('nicht barrierefrei')],
  ['PARTIAL_BARRIER_FREE', new LanguageString('teilweise barrierefrei')],
  ['NO_MAP_PRESENT', new LanguageString('Keine Karte hinterlegt!')],
  ['PLACE_NOTIFY_EDITOR_TAB', new LanguageString('Ortsbeschreibung')],
  ['NOTIFY_IMAGE_LABEL', new LanguageString('Bild des Ortes:')],
  ['NOTIFY_TEXT_LABEL', new LanguageString('Textbeschreibung:')],

  ['LONGITUDE', new LanguageString('Longitude')],
  ['LATITUDE', new LanguageString('Latitude')],
  ['POSITION_TRIGGER_NEW', new LanguageString('Einen neuen Positionstrigger hinzufügen:')],

  ['EDIT_TAB_TRIGGERS', new LanguageString('Trigger')],

  ['ADMIN_USERS_TAB', new LanguageString('Nutzer')],
  ['ADMIN_CONFIG_TAB', new LanguageString('Konfiguration')],
  ['ADMIN_CONFIG_HIGHLIGHTCOLOR', new LanguageString('Highlight Color')],
  ['ADMIN_CONFIG_OFFLINEMAP', new LanguageString('Kartenbereich')],
  ['ADMIN_CONFIG_OFFLINEMAP_DOWNLOADACTIVE', new LanguageString('Markierten Kartenbereich herunterladen und für die App bereitstellen')],
  ['ADMIN_CONFIG_LOGO', new LanguageString('Seitenlogo')]
]);

@Injectable({
  providedIn: 'root',
})
export class StringService {

  private config: Config = new Config();

  public constructor() { }

  public get(id: string) : string {
    return this.getWith(id, this.config.getApplicationLanguage());
  }

  public getWith(id: string, language: Language) : string {
    if (stringMap.has(id)) {
      const string = stringMap.get(id);
      let value = '';
      if (language === Language.DE) {
        value = string.de;
      } else if (language === Language.GB) {
        value = string.gb;
      } else if (language === Language.JP) {
        value = string.jp;
      }
      return value.trim();
    } else {
      console.log('id not found: ' + id);
      return '';
    }
  }
}
