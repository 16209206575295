import { Component, AfterViewInit, OnInit, ViewChild, TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { PushNotificationService } from '../services/pushNotification.service';
import { PushNotification } from '../models/PushNotification';
import { CurrentContextService } from '../services/currentContext.service';
import { AppState } from '../current.app.state';
import { Config } from '../Config';
import { Language } from '../models/Language';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from '../services/alert.service';
import { AlertType, AlertWithBoldValueInMiddle, Alert, AlertSimple } from '../models/Alert';
import { StringService } from '../services/string.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';

@Component({
  selector: 'push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit, AfterViewInit, OnChanges {

  public modalRef: BsModalRef;

  private regex = new RegExp('^(?:(?:https?|ftp):\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!10(?:\\.\\d{1,3}){3})(?!127(?:\\.\\d{1,3}){3})(?!169\\.254(?:\\.\\d{1,3}){2})(?!192\\.168(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\x{00a1}\\-\\x{ffff}0-9]+-?)*[a-z\\x{00a1}\\-\\x{ffff}0-9]+)(?:\\.(?:[a-z\\x{00a1}\\-\\x{ffff}0-9]+-?)*[a-z\\x{00a1}\\-\\x{ffff}0-9]+)*(?:\\.(?:[a-z\\x{00a1}\\-\\x{ffff}]{2,})))(?::\\d{2,5})?(?:\\/[^\\s]*)?$');

  @ViewChild('sendModalTemplate', { static: false }) sendModalTemplate: TemplateRef<any>;

  public notifications: PushNotification[] = [];
  public currentNotification: PushNotification = new PushNotification();
  public config: Config = new Config();
  public emptyGermanFields: string[] = [];
  public emptyEnglishFields: string[] = [];
  public emptyJapaneseFields: string[] = [];
  public minDate: Date = new Date();
  public currentDate: Date = new Date(new Date().getTime() + 60000);

  private notificationToDelete: PushNotification;

  public Language = Language;

  public nextTime: Date;

  public currentLanguage: Language;

  constructor(
    private pushNotificationService: PushNotificationService,
    private currentContextService: CurrentContextService,
    private modalService: BsModalService,
    private alertService: AlertService,
    private stringService: StringService,
    private localeService: BsLocaleService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.minDate = new Date();
  }

  public ngOnInit() {
    this.getNextAvailableTime();
    this.currentContextService.setCurrentState(AppState.pushNotifications);
    this.currentLanguage = Language.DE;
    if (new Config().getApplicationLanguage() === Language.DE) {
      defineLocale('de', deLocale);
      this.localeService.use('de');
    } else if (new Config().getApplicationLanguage() === Language.GB) {
      this.localeService.use('en');
    }
  }

  private getNextAvailableTime(): void {
    const limitSubscription = this.pushNotificationService.getPushNotificationSchedule().subscribe(
      response => {
        this.nextTime = new Date((response as any).nextViable);
        limitSubscription.unsubscribe();
      }
    )
  }

  public ngAfterViewInit() {
    const sub = this.pushNotificationService.getPushNotifications().subscribe(
      notifications => {
        this.notifications = notifications.sort(
          (notification1, notification2) => {
            if (notification1.executionDate && notification2.executionDate) {
              return (notification1.executionDate - notification2.executionDate) * -1;
            } else {
              return (notification1.date - notification2.date) * -1;
            }
          }
        );
        sub.unsubscribe();
      }
    );
  }

  public isNotificationEmpty(): boolean {
    return (this.currentNotification.title.de.length === 0 &&
      this.currentNotification.title.gb.length === 0 &&
      this.currentNotification.title.jp.length === 0 &&
      this.currentNotification.text.de.length === 0 &&
      this.currentNotification.text.gb.length === 0 &&
      this.currentNotification.text.jp.length === 0 &&
      this.currentNotification.url.de.length === 0 &&
      this.currentNotification.url.gb.length === 0 &&
      this.currentNotification.url.jp.length === 0
    );
  }

  public isDisabled(): boolean {
    return this.isNotificationEmpty() || this.isLimitReached();
  }

  public isLimitReached(): boolean {
    if (!this.nextTime) {
      return true;
    }
    return this.currentNotification.date < this.nextTime.getTime();
  }

  public askToSend(): void {
    if (this.currentDate.getTime() < this.minDate.getTime()) {
      this.alertService.alert(new AlertSimple(this.stringService.get('DATE_IN_PAST'), AlertType.Error));
      return;
    }
    this.currentNotification.url.de = this.checkUrl(this.currentNotification.url.de);
    this.currentNotification.url.gb = this.checkUrl(this.currentNotification.url.gb);
    this.currentNotification.url.jp = this.checkUrl(this.currentNotification.url.jp);

    let error = false;
    if (this.currentNotification.url.de.length > 0 && !this.regex.test(this.currentNotification.url.de)) {
      this.alertService.alert(
        new AlertWithBoldValueInMiddle(
          this.stringService.get('URL_DOES_NOT_MATCH_1'),
          this.stringService.get('GERMAN'),
          this.stringService.get('URL_DOES_NOT_MATCH_2'),
          AlertType.Error));
      error = true;
    }
    if (this.currentNotification.url.gb.length > 0 && !this.regex.test(this.currentNotification.url.gb)) {
      this.alertService.alert(
        new AlertWithBoldValueInMiddle(
          this.stringService.get('URL_DOES_NOT_MATCH_1'),
          this.stringService.get('ENGLISH'),
          this.stringService.get('URL_DOES_NOT_MATCH_2'),
          AlertType.Error));
      error = true;
    }
    if (this.currentNotification.url.jp.length > 0 && !this.regex.test(this.currentNotification.url.jp)) {
      this.alertService.alert(
        new AlertWithBoldValueInMiddle(
          this.stringService.get('URL_DOES_NOT_MATCH_1'),
          this.stringService.get('JAPANESE'),
          this.stringService.get('URL_DOES_NOT_MATCH_2'),
          AlertType.Error));
      error = true;
    }
    if (!error) {
      this.checkEmptyFields();
      this.modalRef = this.modalService.show(this.sendModalTemplate, { keyboard: false, ignoreBackdropClick: true });
    }
  }

  public openModal(modalTemplate: TemplateRef<any>, pushNotification: PushNotification): void {
    this.notificationToDelete = pushNotification;
    this.modalRef = this.modalService.show(modalTemplate);
  }

  private checkEmptyFields(): void {
    this.emptyGermanFields = [];
    this.emptyEnglishFields = [];
    this.emptyJapaneseFields = [];

    if (this.currentNotification.url.de.length === 0) {
      this.emptyGermanFields.push(this.stringService.get('PUSH_NOTIFICATION_URL'));
    }
    if (this.currentNotification.url.gb.length === 0) {
      this.emptyEnglishFields.push(this.stringService.get('PUSH_NOTIFICATION_URL'));
    }
    if (this.currentNotification.url.jp.length === 0) {
      this.emptyJapaneseFields.push(this.stringService.get('PUSH_NOTIFICATION_URL'));
    }
    if (this.currentNotification.title.de.length === 0) {
      this.emptyGermanFields.push(this.stringService.get('PUSH_NOTIFICATION_TITLE'));
    }
    if (this.currentNotification.title.gb.length === 0) {
      this.emptyEnglishFields.push(this.stringService.get('PUSH_NOTIFICATION_TITLE'));
    }
    if (this.currentNotification.title.jp.length === 0) {
      this.emptyJapaneseFields.push(this.stringService.get('PUSH_NOTIFICATION_TITLE'));
    }
    if (this.currentNotification.text.de.length === 0) {
      this.emptyGermanFields.push(this.stringService.get('PUSH_NOTIFICATION_TEXT'));
    }
    if (this.currentNotification.text.gb.length === 0) {
      this.emptyEnglishFields.push(this.stringService.get('PUSH_NOTIFICATION_TEXT'));
    }
    if (this.currentNotification.text.jp.length === 0) {
      this.emptyJapaneseFields.push(this.stringService.get('PUSH_NOTIFICATION_TEXT'));
    }
  }

  public discard(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  public createPushNotification(): void {
    this.currentNotification.executionDate = this.currentDate.getTime();
    const sub = this.pushNotificationService.createPushNotification(this.currentNotification).subscribe(
      response => {
        let newPushNotifications: PushNotification[] = [this.currentNotification];
        newPushNotifications = newPushNotifications.concat(this.notifications);
        this.notifications = newPushNotifications;
        this.currentNotification = new PushNotification();
        this.currentDate = new Date(new Date().getTime() + 60000);
        this.getNextAvailableTime();
        this.discard();
        sub.unsubscribe();
      }
    );
  }

  public deletePushNotification(): void {
    const deleteSubcription = this.pushNotificationService.deletePushNotifications(this.notificationToDelete).subscribe(
      response => {
        this.notifications = this.notifications.filter(n => n.id !== this.notificationToDelete.id);
        this.notificationToDelete = null;
        this.modalRef.hide();
        deleteSubcription.unsubscribe();
      }
    );
  }

  private checkUrl(valueToCheck: string): string {
    if (valueToCheck.length > 0 &&
      !valueToCheck.includes(':') &&
      !valueToCheck.startsWith('https://') &&
      !valueToCheck.startsWith('http://')) {
      return 'http://' + valueToCheck;
    } else {
      return valueToCheck;
    }
  }

  public setLanguage(language: Language): void {
    this.currentLanguage = language;
  }

  public getStyle(language: Language): Object {
    const style = {
      'filter': this.currentLanguage === language ? 'brightness(100%)' : 'brightness(50%)'
    };
    return style;
  }

  public isLanguage(language: Language): boolean {
    return language === this.currentLanguage;
  }

  public updateLanguage(language: Language): void {
    this.currentLanguage = language;
  }

  public timeChanged(): void {
    if (!this.currentDate) {
      this.currentDate = new Date(new Date().getTime() + 60000);
    } else {
      this.currentNotification.date = this.currentDate.getTime();
    }
  }
}
