import { AlertType, AlertWithBoldValueInMiddle } from './../models/Alert';
import { AlertService } from './../services/alert.service';
import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Subscription, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AppState } from '../current.app.state';
import { FileDataType } from '../models/FileDataType';
import { Image } from '../models/Image';
import { Language } from '../models/Language';
import { Media } from '../models/Media';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { ElementType, UiElement } from '../models/UiElements';
import { CurrentContextService } from '../services/currentContext.service';
import { ImageService } from '../services/image.service';
import { MediaService } from '../services/media.service';
import { PoiManagerService } from '../services/poi-manager.service';
import { Tools } from '../Tools';
import { CubeSides } from '../models/CubeSides';
import { Config } from '../Config';
import { StringService } from '../services/string.service';
import { ContentTypes } from '../models/ContentTypes';
import { saveAs } from 'file-saver';
import { AlertSimple } from '../models/Alert';
import { TabType } from '../models/TabType';
import { MediaLoader } from '../../helper-classes/MediaLoader';

@Component({
	selector: 'aacms-edit-image-meta',
	templateUrl: './edit-image-meta.component.html',
	styleUrls: ['./edit-image-meta.component.scss']
})
export class EditImageMetaComponent implements OnInit, OnDestroy {

	public modalRef: BsModalRef;
	public image: Image;
	public imageDataMap: Map<CubeSides, FileDataType> = new Map([[CubeSides.image, null]]);
	public originalImageDataMap: Map<CubeSides, FileDataType> = new Map([[CubeSides.image, null]]);
	private originalImage: Image;
	public ElementType = ElementType;
	public contentChanged = false;
	public imageDataChanged = false;
	public mediaLoader: MediaLoader;

	public cubeSides = CubeSides;
	public contentTypes = ContentTypes;

	public imageWidth = 0;
	public imageHeight = 0;

	public width: string;
	public height: string;

	@ViewChild('smartphone') smartphone;
	@ViewChild('popupEditor') popupEditor;
	@ViewChild('cancelModalTemplate', { static: false }) cancelModalTemplate: TemplateRef<any>;
	@ViewChild('saveModalTemplate', { static: false }) saveModalTemplate: TemplateRef<any>;

	public Language = Language;
	public appState = AppState;
	public Tools = Tools;

	public currentLanguage: Language;
	public useCustomPopup: boolean = false;
	private customPopupBackup: UiElement[] = null;

	private saveContentSubject: Subscription;

	public config: Config = new Config();

	public currentTab: TabType;
	public tabType = TabType;

	public constructor(
		public contextService: CurrentContextService,
		public pms: PoiManagerService,
		private mediaService: MediaService,
		private modalService: BsModalService,
		private imageService: ImageService,
		private router: Router,
		private route: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private stringService: StringService,
		private alertService: AlertService
	) {
		this.mediaLoader = new MediaLoader(this.mediaService, this.sanitizer);
	}

	public ngOnInit(): void {
		this.contextService.setCurrentState(AppState.editImageMeta);
		this.pms.selectedPoi = null;
		this.currentLanguage = Language.DE;
		this.saveContentSubject = this.contextService.saveYourContent.subscribe(
			saveContent => {
				if (saveContent) {
					this.save();
				}
			}
		);
		this.getImage();
	}

	public ngOnDestroy(): void {
		this.saveContentSubject.unsubscribe();
		this.returnToImage();
	}

	@HostListener('window:beforeunload')
	canDeactivate(): Observable<boolean> | boolean {
		return (this.contentChanged || this.pms.poisChanged || this.imageDataChanged) ? false : true;
	}

	public askToSave(): void {
		this.modalRef = this.modalService.show(this.cancelModalTemplate, { keyboard: false, ignoreBackdropClick: true });
	}


	public discard(): void {
		if (this.imageDataChanged) {
			this.imageDataMap.set(CubeSides.image, new FileDataType(this.originalImageDataMap.get(CubeSides.image)));
			this.checkForImageDataChange();
		}
		this.width = this.originalImage.width.toString();
		this.height = this.originalImage.height.toString();
		this.image = new Image(this.originalImage);
		this.smartphone.onDiscard();
		this.popupEditor.onDiscard();
		this.useCustomPopup = this.image.popupElements.length !== 0;
		this.checkForContentChange();
		this.hideModal();
	}

	public hideModal(): void {
		if (this.modalRef) {
			this.modalRef.hide();
		}
	}

	public getImage(): void {
		const id = this.route.snapshot.paramMap.get('imageId');
		const sub = this.imageService.getImageById(id).subscribe(
			image => {
				this.image = new Image(image);
				this.useCustomPopup = this.image.popupElements.length !== 0;
				this.customPopupBackup = this.image.popupElements;
				this.contextService.getExhibition(this.image.exhibitionIds[0]);
				this.originalImage = new Image(image);
				this.width = this.image.width.toString();
				this.height = this.image.height.toString();
				const imageData = new FileDataType();
				this.imageDataMap.set(CubeSides.image, imageData);
				imageData.isLoaded = false;
				imageData.mediaId = image.mediaId;
				if (image.mediaId) {
					this.mediaService.getMediaById(image.mediaId).subscribe(
						media => {
							imageData.media = new Media(media);
							const sub1 = this.mediaService.downloadFile(media.rawUrl).subscribe(
								blob => {
									imageData.file = Tools.blobToFile(blob, media.name);
									imageData.fileSafeUrl = this.createImageUrl(imageData.file);
									imageData.isLoaded = true;
									this.originalImageDataMap.set(CubeSides.image, new FileDataType(imageData));
									sub1.unsubscribe();
								}
							);
						}
					);
				}
				sub.unsubscribe();
			},
			error => {
				this.router.navigate(['images']);
			});
	}

	private createImageUrl(file: File): SafeUrl {
		const urlCreator = window.URL;
		const url = urlCreator.createObjectURL(file);
		this.storeWidthHeight(url);
		const safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
		return safeUrl;
	}

	private storeWidthHeight(url: string): void {
		const htmlImageElement = document.createElement('img');
		htmlImageElement.src = url;
		htmlImageElement.onload = () => {
			this.imageWidth = htmlImageElement.width;
			this.imageHeight = htmlImageElement.height;
		};
	}

	public getLanguageValue(text: MultiLanguageText): string {
		return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
	}

	public checkForContentChange(): void {
		this.contentChanged = !this.image.equal(this.originalImage);
	}

	private checkForImageDataChange(): void {
		if (!this.imageDataMap.get(CubeSides.image) && !this.originalImageDataMap.get(CubeSides.image)) {
			this.imageDataChanged = false;
			return;
		}
		if (
			!this.imageDataMap.get(CubeSides.image) && this.originalImageDataMap.get(CubeSides.image) ||
			this.imageDataMap.get(CubeSides.image) && !this.originalImageDataMap.get(CubeSides.image)
		) {
			this.imageDataChanged = true;
			return;
		}
		if (!this.imageDataMap.get(CubeSides.image).equal(this.originalImageDataMap.get(CubeSides.image))) {
			this.imageDataChanged = true;
			return;
		}
		this.imageDataChanged = false;
	}

	public save(): void {
		this.hideModal();
		this.contextService.setSaveYourContent(false);
		let saveContent = (this.contentChanged) ? true : false;
		let savePois = (this.pms.poisChanged) ? true : false;
		let saveImageData = (this.imageDataChanged) ? true : false;
		if (saveContent || savePois || saveImageData) {
			this.contextService.setSavingContent(true);
		}
		if (this.imageDataChanged) {
			if (this.imageDataMap.get(CubeSides.image) && this.imageDataMap.get(CubeSides.image).file) {
				const upateImageSubscription = this.updateImage();
				upateImageSubscription.then(
					() => {
						saveImageData = false;
						if (!saveImageData && !savePois && !saveContent) {
							this.checkForImageDataChange();
							this.checkForContentChange();
							this.contextService.setSavingContent(false);
						}
					});
			} else {
				const saveImageSubscription = this.imageService.updateImage(this.image).subscribe(
					() => {
						this.originalImage = new Image(this.image);
						this.imageDataMap.set(CubeSides.image, new FileDataType(this.originalImageDataMap.get(CubeSides.image)));
						saveImageSubscription.unsubscribe();
						saveImageData = false;
						if (!saveImageData && !savePois && !saveContent) {
							this.checkForImageDataChange();
							this.checkForContentChange();
							this.contextService.setSavingContent(false);
						}
					}
				);
			}
		}
		if (this.contentChanged) {
			const requests: Observable<any>[] = [];
			const sub = forkJoin(requests).subscribe(
				response => {
				}, error => {
				},
				() => {
					const sub1 = this.imageService.updateImage(this.image).subscribe(
						response => {
							this.originalImage = new Image(this.image);
							saveContent = false;
							if (!saveContent && !savePois && !saveImageData) {
								this.checkForContentChange();
								this.checkForImageDataChange();
								this.contextService.setSavingContent(false);
							}
							sub1.unsubscribe();
							sub.unsubscribe();
						});
				});
		}
		if (this.pms.poisChanged) {
			const savePoiSubscription = this.pms.saveChangedPois();
			savePoiSubscription.add(() => {
				savePois = false;
				if (!saveContent && !savePois && !saveImageData) {
					this.checkForContentChange();
					this.checkForImageDataChange();
					this.contextService.setSavingContent(false);
				}
				savePoiSubscription.unsubscribe();
			});
		}
	}

	private async updateImage(): Promise<any> {
		let location = '';
		let imageError = false;
		await this.mediaService.uploadFile(this.imageDataMap.get(CubeSides.image).file, true).pipe(
			tap(
				response => {
					if (response.status < 400) {
						location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
						location = location.substring(location.lastIndexOf('/') + 1);
						this.image.mediaId = location;
						this.imageDataMap.get(CubeSides.image).mediaId = location;
						this.originalImageDataMap.set(CubeSides.image, new FileDataType(this.imageDataMap.get(CubeSides.image)));
					}
				}
			),
			catchError(
				error => {
					if (error.status === 412) {
						this.alertService.alert(
							new AlertWithBoldValueInMiddle(
								this.stringService.get('IMAGE_NOT_TRACKABLE_1'),
								this.imageDataMap.get(CubeSides.image).file.name,
								this.stringService.get('IMAGE_NOT_TRACKABLE_2'),
								AlertType.Error
							)
						);
					}
					imageError = true;
					return of([]);
				}
			)
		).toPromise();

		if (imageError) {
			this.removeImage();
			return of([]).toPromise();
		}

		await this.mediaService.getMediaById(location).pipe(
			tap(
				media => {
					if (media.rating < 50) {
						this.alertService.alert(new AlertSimple(this.stringService.get('RATING_IS_UNDERWHELMING'), AlertType.Warning));
					}
					this.imageDataMap.get(CubeSides.image).media = media;
					if (this.originalImageDataMap.get(CubeSides.image)) {
						this.originalImageDataMap.get(CubeSides.image).media = media;
					}
				})).toPromise();

		return this.imageService.updateImage(this.image).pipe(
			tap(
				() => {
					this.originalImage = new Image(this.image);
				})).toPromise();
	}

	public calcWidth(value: string, input: HTMLInputElement): void {
		value = Tools.checkForNumber(value, false);
		if (value === null) {
			value = this.image.height.toString();
		} else if (value === '') {
			this.image.height = 0;
		} else {
			this.image.height = +value;
			if (this.imageHeight > 0 && this.imageWidth > 0) {
				this.image.width = (this.imageWidth / this.imageHeight) * +value;
				this.width = this.image.width.toString();
			}
		}
		this.height = value;
		this.checkForContentChange();
		input.value = value;
	}

	public calcHeight(value: string, input: HTMLInputElement): void {
		value = Tools.checkForNumber(value, false);
		if (value === null) {
			value = this.image.width.toString();
		} else if (value === '') {
			this.image.width = 0;
		} else {
			this.image.width = +value;
			if (this.imageHeight > 0 && this.imageWidth > 0) {
				this.image.height = (this.imageHeight / this.imageWidth) * +value;
				this.height = this.image.height.toString();
			}
		}
		this.width = value;
		this.checkForContentChange();
		input.value = value;
	}

	public openModal(modalTemplate: TemplateRef<any>): void {
		this.modalRef = this.modalService.show(modalTemplate);
	}

	public returnToImage(): void {
		this.router.navigate(['images', this.route.snapshot.paramMap.get('imageId')]);
	}

	public setLanguage(language: Language): void {
		this.currentLanguage = language;
	}

	public isLanguage(language: Language): boolean {
		return language === this.currentLanguage;
	}

	public updateLanguage(language: Language): void {
		this.currentLanguage = language;
		setTimeout(() => {
			this.pms.reloadPoiLayer();
		});
	}

	public toggleUseCustomPopup(): void {
		this.useCustomPopup = !this.useCustomPopup;
		if (!this.useCustomPopup) { // got disabled, backup elements into gui state then, clear model
			this.customPopupBackup = this.image.popupElements;
			this.image.popupElements = [];
		} else { // restore from backup, if any
			if (this.customPopupBackup != null) {
				this.image.popupElements = this.customPopupBackup;
			}
		}
		this.checkForContentChange();
	}

	public toggleImageActivation(): void {
		this.image.active = !this.image.active;
		this.checkForContentChange();
	}

	public toggleArImageCardsActivation(): void {
		this.image.cardsActive = !this.image.cardsActive;
		this.checkForContentChange();
	}

	public getStringNameForContentType(type: ContentTypes): string {
		switch (type) {
			case ContentTypes.Custom: {
				return this.stringService.get('CUSTOM');
			}
			case ContentTypes.URL: {
				return this.stringService.get('URL');
			}
		}
	}


	public showContentTypesSelection(): boolean {
		return this.config.getEnabledContentTypes().length > 1;
	}

	public checkForSave(): void {
		if (
			!this.image.width || this.image.width === 0 ||
			!this.image.height || this.image.height === 0
		) {
			this.openModal(this.saveModalTemplate);
			return;
		}
		this.save();
	}

	public downloadFileToSystem(file: FileDataType): void {
		saveAs(file.file, file.media.name);
	}

	public removeImage(): void {
		this.imageDataMap.set(this.cubeSides.image, null);
		this.image.mediaId = null;
		this.checkForImageDataChange();
	}

	public handleFilesFromDropin(event: File[]): void {
		if (event.length > 0) {
			this.contextService.setSavingContent(true);
			this.mediaService.uploadFile(event[0]).subscribe(
				response => {
					if (response.status < 400) {
						const location: string = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
						const mediaId: string = location.substring(location.lastIndexOf('/') + 1);
						let imageData: FileDataType;
						if (this.imageDataMap && !this.imageDataMap.get(this.cubeSides.image)) {
							imageData = new FileDataType();
							this.imageDataMap.set(CubeSides.image, imageData);
						} else {
							imageData = this.imageDataMap.get(CubeSides.image);
						}
						imageData.isLoaded = false;
						imageData.mediaId = mediaId;
						this.image.mediaId = imageData.mediaId;
						imageData.file = event[0];
						imageData.fileSafeUrl = this.createImageUrl(event[0]);
						imageData.isLoaded = true;
					}
					this.contextService.setSavingContent(false);
					this.checkForImageDataChange();
				}
			);
		}
	}

	public getMaxContentFileSize(): number {
		return new Config().getMaxImageFileSize();
	}

	public routeTo(appState: AppState): void {
		switch (appState) {
			case AppState.exhibitions:
				this.router.navigate(['exhibitions']);
				break;
			case AppState.images:
				this.router.navigate(['exhibitions', this.image.exhibitionIds[0], 'images']);
				break;
			case AppState.editImage:
				this.router.navigate(['images', this.image.id]);
				break;
			default:
				break;
		}
	}

	public isTabAllowed(type: ContentTypes): boolean {
		for (const contentType of this.config.getEnabledContentTypes()) {
			if (contentType === type) {
				return true;
			}
		}
		return false;
	}

	public getBackgroundColorForRating(): string {
		if (this.imageDataMap.get(CubeSides.image).media && this.imageDataMap.get(CubeSides.image).media.rating !== undefined) {
			return Tools.getBackgroundColorForRating(this.imageDataMap.get(CubeSides.image).media.rating);
		} else {
			return '#E0FDEB';
		}
	}
}
