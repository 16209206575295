import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { tap, share } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { Config } from '../Config';

import { AlertService } from './alert.service';

import { User } from '../models/User';
import { StringService } from './string.service';
import { AlertSimple, AlertType, AlertWithUrl, AlertWithBoldValueInMiddle } from '../models/Alert';

/**
 * basic httpHeaders that will be used with every request
 */
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  observe: 'response' as 'body'
};

/**
 * The UserService will be used to handle all requests with the user endpoint of the backend
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {

  /**
   * the baseurl of the current backend
   */
  private baseUrl = new Config().GetBaseUrl();

  /**
	 * Creates an instance of the userService
	 * @param http is used to send Request to the backend and process the response.
	 * @param alertService is used to display relevant information to the user.
     * @param stringService the StringService is used to get strings in the correct Language, that are displayed to the user.
	 */
  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService
  ) { }

  /**
   * GET
   * request all users from the database
   * @returns a list of all users that can be found in the database
   */
  public getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.getUserUrl()).pipe(
      tap(
        response => { },
        error => { this.handleError<User[]>('getUsers', error); }
      ),
      share()
    );
  }

  /**
   * GET
   * requests the user with the given id from the database
   * @param id the user id that should be requested
   * @returns the user if found in the database, else returns an error
   */
  public getUserById(id: string): Observable<User> {
    return this.http.get<User>(this.getUserUrl(`${id}`)).pipe(
      tap(
        response => { },
        error => {
          this.handleError<User>('getUserById', error);
          this.alertService.alert(
            new AlertWithBoldValueInMiddle(
              this.stringService.get('USER_NOT_FOUND1'),
              id,
              this.stringService.get('USER_NOT_FOUND2'),
              AlertType.Error),
            true);
        }
      ),
      share()
    );
  }

  /**
   * POST
   * creates an new user in the database
   * @param user the user to be created
   * @returns returns an locationHeader where to find the created User
   */
  public createUser(user: User): Observable<any> {
    return this.http.post<any>(this.getUserUrl(), user, httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(new AlertSimple(this.stringService.get('USER_CREATED'), AlertType.Success));
        },
        (error: HttpErrorResponse) => {
          if (error.status === 409) {
            this.alertService.alert(new AlertSimple(this.stringService.get('USER_COULD_NOT_CREATED1'), AlertType.Error));
          } else {
            this.alertService.alert(new AlertWithBoldValueInMiddle(this.stringService.get('USER_COULD_NOT_CREATED2'),
              user.name, this.stringService.get('USER_COULD_NOT_CREATED3'), AlertType.Error));
          }
          this.handleError<User>('createUser', error);
        }
      ),
      share()
    );
  }

  /**
   * PUT
   * updates the given user with the new information
   * @param user the user to be updated
   * @returns nothing if the request was successful, else returns an error
   */
  public updateUser(user: User): Observable<any> {
    return this.http.put<any>(this.getUserUrl(`${user.id}`), user, httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(new AlertSimple(this.stringService.get('USER_UPDATED'), AlertType.Success), true);
        },
        error => {
          this.alertService.alert(new AlertSimple(this.stringService.get('USER_COULD_NOT_UPDATED'), AlertType.Error));
          this.handleError<User>('createUser', error);
        }
      ),
      share()
    );
  }

  /**
   * DELETE
   * deletes the given user from the database
   * @param user the user to be deleted
   * @returns nothing if the request was successful, else returns an error
   */
  public deleteUser(user: User | string): Observable<any> {
    const id = typeof user === 'string' ? user : user.id;
    return this.http.delete(this.getUserUrl(`${id}`), httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(new AlertSimple(this.stringService.get('USER_DELETED'), AlertType.Info), true);
        },
        error => { this.handleError<User>('deleteUser', error); }
      ),
      share()
    );
  }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  * @returns an errorObject or an Object of the given resultType
  */
  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }

    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  /**
   * triggers when an httpError occurs
   * writes the value to the console
   * @param value the value that should be written
   */
  private logError(value: any): void {
    console.error(value);
  }

  /**
 * creates the url to the user endpoint of the backend
   * @param extension possible extension for the url
   * @returns the new created url
   */
  private getUserUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}users/`;
    if (extension) {
      if (!(extension.indexOf('?') >= 0)) {
        if (!extension.endsWith('/')) {
          extension = `${extension}/`;
        }
      }
      url = `${url}${extension}`;
    }
    return url;
  }
}
