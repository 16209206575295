<div class="toolbar">

  <h1 class="toolbar-title toolbar-title-login" (click)="routeToHome()">
    <!-- logo -->
    <aacms-logo class="toolbar-title-icon"></aacms-logo>
    <a class="toolbar-title-text">AUGMENTED ART CMS</a>
  </h1>

  <div *ngIf="!isLoginState()" class="routing-buttons">
    <a class="routing-button" (click)="routeToExhibitions()" triggers="hover" containerClass="customTooltip"
      placement="bottom">{{ 'EXHIBITIONS' | translate }}
    </a>
    <a *ngIf="config.isAppMenuEnabled()" class="routing-button"
      (click)="routeToAppMenu()">{{ 'APP_MENU' | translate }}
    </a>
    <a *ngIf="isAdmin() && config.isPushNotificationEnabled()" class="routing-button"
      (click)="routeToPushNotifications()">{{ 'PUSH_NOTIFICATIONS' | translate }}
    </a>
    <a *ngIf="isAdmin() && config.isPoiStylesEnabled()" class="routing-button"
      (click)="routeToPoiTextures()">{{ 'POI_TEXTURES' | translate }}
    </a>
    <a *ngIf="config.isToursEnabled()" class="routing-button"
      (click)="routeToTours()">{{ 'TOUR_EDITOR' | translate }}
    </a>
    <a *ngIf="isResourceLocked()" class="routing-button" (click)="refreshResourceTimer()">{{ this.currentTime | date:'m:ss' }}<i class="fa fa-refresh routing-button-icon" aria-hidden="true"></i></a>
  </div>

  <span class="toolbar-spacer"></span>

  <div *ngIf="!isLoginState()" class="routing-buttons" [matTooltip]="'EDIT_USER' | translate"
    triggers="hover">
    <a class="routing-button" (click)="routeToEditUser()" >{{getUserRealName()}}</a>
  </div>

  <button *ngIf="!isLoginState() && isAdmin()" class="app-icon-button" (click)="routeToAdminPage()"
    [matTooltip]="'USER_MANAGEMENT' | translate"  triggers="hover">
    <i class="fa fa-cogs" aria-hidden="true"></i>
  </button>

  <button class="app-icon-button" [matTooltip]="'DOKUMENTATION' | translate"  triggers="hover" (click)="routeToDoku()">
    <i class="fa fa-question-circle link" aria-hidden="true"></i>
  </button>

  <button id="logout-button" *ngIf="!isLoginState()" class="app-icon-button" (click)="logout()"
    [matTooltip]="'LOGOUT' | translate"  triggers="hover">
    <i class="fa fa-sign-out" aria-hidden="true"></i>
  </button>

</div>
<div *ngIf="config.isDemoInstance()" class="demo-warning text-center">
  <b>Achtung:</b> Alle Inhalte der Seite werden regelmäßig zurückgesetzt. Alle Nutzer können und dürfen sich gerne ausprobieren.
  Ein Bereitstellen der eigenen Inhalte in die App kann aus urheberrechtlichen Gründen nicht ohne unsere Kontrolle gestattet werden.
  Bei Bedarf bitte an <a href="mailto:info@augmented-art.de">info@augmented-art.de</a> wenden.
</div>
