<div class="edit-tour-meta-main-content">
  <div class="edit-tour-meta-left-side">
    <div *ngIf="this.tour && (!this.mapElementRef || !this.mapElementRef.mapObject)" class="no-tour-available">
      <dropin [maxFileSize]="this.getMaxContentFileSize()" (sendFiles)="this.handleFilesFromDropin($event)"
        [accept]="'.gpx, .geojson'">
      </dropin>
      <div class="custom-hr"></div>
      <i class="no-tour-file">{{'NO_TOUR_AVAILABLE' | translate }}</i>
    </div>
    <aacms-loader class="tourLoader" size="128px"
      *ngIf="!this.tour || !this.map">
    </aacms-loader>
    <div class="image-available" *ngIf="this.tour && this.mapElementRef && this.mapElementRef.mapObject">
      <div class="map-container" ><div id="map"></div></div>
      <div class="custom-hr"></div>
      <div>{{'CURRENT_TOUR_DATA' | translate }}</div>
      <div class="current-file">
        <div class="current-file-name text-truncate">
          {{this.getFilename(currentLanguage)}}.geojson
        </div>
        <div class="update-buttons">
          <div *ngIf="this.mapElementRef.mapObject" class="download-file"
            (click)="this.downloadFileToSystem(this.mapElementRef.mapObject)" [matTooltip]="'DOWNLOAD_MEDIA' | translate" triggers="hover">
            <i class="fa fa-download"></i>
          </div>
          <div class="file-delete" (click)="this.removeGeoJson()" [matTooltip]="'REMOVE_MEDIA' | translate" triggers="hover">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="edit-tour-meta-right-side" *ngIf="this.tour">
    <div class="header">
      <div class="path-title-container">
        <div class="path">
          <div class="path-option" (click)="this.routeTo(appState.tours)">{{'TOURS' | translate}}</div>
          /
          <div class="path-option" (click)="this.routeTo(appState.editTour)">{{ Tools.shortenString(tour && getLanguageValue(tour.title).length > 0 ? getLanguageValue(tour.title) : ('TOUR' | translate)) }}</div>
          /
          <div class="last-path-option">{{'TOUR_META' | translate}}</div>
        </div>
        <div class="arlayer-title-edit-container">
          <h1 class="edit-indicator">
            <i class="fa fa-pencil" aria-hidden="true"
              [ngStyle]="{'color': this.tourChanged ? '#ffc107' : 'black' }"></i>
          </h1>
          <div class="arlayer-title-container">
            <h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.tour.title).length > 0">
              {{this.getLanguageValue(this.tour.title)}}
            </h1>
            <h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.tour.title).length == 0">
              <i>{{'UNNAMED_TOUR' | translate }}</i>
            </h1>
          </div>
        </div>
      </div>
      <aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
        (languageChanged)="this.updateLanguage($event)">
      </aacms-language-selector>
    </div>
    <div class="custom-hr"></div>
    <tabset [justified]="false">
      <tab heading="{{'TOUR_GENERAL_TAB' | translate}}" class="tour-general-tab tab"  (selectTab)="this.currentTab = tabType.GeneralTab">
        <div>{{'TITLE' | translate }}</div>
        <div class="tour-title-input">
          <div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
            <input class="form-control textInput" [(ngModel)]="this.tour.title.de" rows="1" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
              (ngModelChange)="checkForTourChanged()" />
            <div class="languageIndicator">
              <img class="language-button-image" src="../assets/flags/de.svg">
            </div>
            <div class="align-self-center px-2">
              {{this.config.getMaxTitleTextLength() - this.tour.title.de.length}}
            </div>
          </div>
          <div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
            <input class="form-control textInput" [(ngModel)]="this.tour.title.gb" rows="1" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
              (ngModelChange)="checkForTourChanged()" />
            <div class="languageIndicator">
              <img class="language-button-image" src="../assets/flags/gb.svg">
            </div>
            <div class="align-self-center px-2">
              {{this.config.getMaxTitleTextLength() - this.tour.title.gb.length}}
            </div>
          </div>
          <div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
            <input class="form-control textInput" [(ngModel)]="this.tour.title.jp" rows="1" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
              (ngModelChange)="checkForTourChanged()" />
            <div class="languageIndicator">
              <img class="language-button-image" src="../assets/flags/jp.svg">
            </div>
            <div class="align-self-center px-2">
              {{this.config.getMaxTitleTextLength() - this.tour.title.jp.length}}
            </div>
          </div>
        </div>
        <div class="checkbox-container">
          <label class="checkbox">
            <input type="checkbox" (click)="this.toggleTourActivation()" [checked]="this.tour.active">
            <span class="checkmark"triggers="hover"></span>
            <div class="checkbox-text">
              {{ 'TOUR_ACTIVE_INACTIVE' | translate }}
            </div>
          </label>
        </div>
        <div class="checkbox-container">
          <label class="checkbox">
            <input type="checkbox" (click)="this.toggleWalkTourInOrder()" [checked]="this.tour.walkTourInOrder">
            <span class="checkmark" triggers="hover"></span>
            <div class="checkbox-text">
              {{ 'WALK_TOUR_IN_ORDER_TOGGLE' | translate }}
            </div>
          </label>
        </div>
        <div class="barrier-container">
          <div>{{'TOUR_ACCESSABILITY' | translate}}</div>
          <div class="selection-container">
            <select class="selection" [(ngModel)]="this.tour.accessibility" (change)="this.checkForTourChanged()">
              <ng-template ngFor let-type [ngForOf]="this.getAccessibilityValues()">
                <option role="selection-item" [ngValue]="this.type">{{ this.getStringNameForAccessibilityEnum(this.type) }}
                </option>
              </ng-template>
            </select>
          </div>
        </div>
      </tab>
      <tab heading="{{ 'IMAGE_CARD_TAB' | translate }}" class="card-tab tab" (selectTab)="this.currentTab = tabType.CardTab">
        <div class="smartphone-container">
          <aacms-info-card-editor [card]="this.tour.card" [title]="this.tour.title"
            [currentLanguage]="this.currentLanguage" [trackerFileData]="this.cardImageDataMap" [isTracker]="false"
            (cardChange)="this.checkForTourChanged()" [uiElements]="this.tour.href.uiElements">
          </aacms-info-card-editor>
        </div>
      </tab>
      <tab heading="{{ 'TOUR_CONTENT_PAGE_TAB' | translate }}" class="content-page-tab tab"
        *ngIf="this.isTabAllowed(contentTypes.Custom)" (selectTab)="this.currentTab = tabType.UiElementsTab">
        <div class="smartphone-container">
          <aacms-content-page #smartphone class="smartphone" [(uiElements)]="this.tour.href.uiElements"
            (uiElementsChange)="checkForTourChanged()" [language]="this.currentLanguage" [isVisible]="currentTab === tabType.UiElementsTab">
          </aacms-content-page>
        </div>
      </tab>
      <!--
      <tab heading="{{ 'TOUR_URL_PAGE_TAB' | translate }}" class="url-page-tab tab"  (selectTab)="this.currentTab = tabType.UrlTab"
        *ngIf="this.isTabAllowed(contentTypes.URL)">
        <div class="smartphone-container">
          <aacms-url-page [url]="this.tour.href.url" (urlChange)="this.checkForTourChanged()"
            [language]="this.currentLanguage">
          </aacms-url-page>
        </div>
      </tab>
      -->
    </tabset>
    <div class="custom-hr"></div>
    <div class="button-row row">
      <button type="submit" class="btn btn-secondary custom-button" (click)="returnToTour()">
        {{'BACK_TO_TOUR' | translate}}
      </button>
      <button type="submit" class="btn btn-warning custom-button"
        [disabled]="!this.tourChanged"
        (click)="openModal(cancelModalTemplate)">
        {{'DISCARD' | translate }}
      </button>
      <button type="submit" class="btn btn-success custom-button"
        [disabled]="!this.tourChanged"
        (click)="this.save()">
        {{'SAVE_CHANGES' | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #cancelModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
  </div>
  <div class="modal-footer">
    <button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
    <button (click)="discard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
  </div>
</ng-template>

<ng-template #saveModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-break pull-left">{{'REALY_SAVE_WITHOUT_DIMENSIONS' | translate}}</h4>
  </div>
  <div class="modal-footer">
    <button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'ABORT' | translate }}</button>
    <button (click)="this.save()" type="button" class="btn btn-success">{{'SAVE'| translate}}</button>
  </div>
</ng-template>
