<div class="dropin">
  <label [ngStyle]="getDropzoneStyle()" class="dropzone" nv-file-over uploader="uploader" over-class="in" (mouseover)="this.hover=true" (mouseleave)="this.hover=false"
    (drop)="drop($event)" (dragover)="dragover($event)" (dragenter)="dragenter()" (dragleave)="dragleave()" [style.cursor]="this.useMousePointer() ? 'pointer' : 'default'">
    <input #input id="input" [disabled]="this.disabled" class="hidden" nv-file-select uploader="uploader" type="file"
      [multiple]="this.multipleAllowed" (change)="handleFileInput($event)" accept="{{accept}}" />
    <div class="empty-text">
      <i class="fa fa-upload" aria-hidden="true"></i>
    </div>
    <div class="max-file-size">
      <div class="max-file-size-text"><i>{{'MAX_FILE_SIZE' | translate }}:</i></div>
      <div class="max-file-size-text"><i>{{ formatBytes(maxFileSize) }}</i></div>
    </div>
  </label>
</div>
