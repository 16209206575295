import { Component, HostListener, TemplateRef } from '@angular/core';
import { AppState } from '../current.app.state';
import { CurrentContextService } from '../services/currentContext.service';
import * as L from 'leaflet';
import { ActivatedRoute, Router } from '@angular/router';
import { Language } from '../models/Language';
import { Config } from '../Config';
import { Tools } from '../Tools';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { Place } from '../models/Place';
import { PlaceService } from '../services/place.service';
import { InfoCard } from '../models/InfoCard';
import { InfoCardService } from '../services/infoCard.service';
import { tap } from 'rxjs/operators';
import { ContentTypes } from '../models/ContentTypes';

@Component({
    selector: 'aacms-edit-place',
    templateUrl: './edit-place.component.html',
    styleUrls: ['./edit-place.component.scss']
  })
  export class EditPlaceComponent {

    public map;

    public place: Place;
    public originalPlace: Place;
    public placeChanged = false;
    public infoCardsChanged = false;
    public currentLanguage: Language;
    public selectedInfoCard: InfoCard;

    public infoCards: InfoCard[] = [];
    public originalInfoCards: InfoCard[] = [];

    public Language = Language;
    public config: Config = new Config();
    public contentTypes = ContentTypes;
    public appState = AppState;
    public Tools = Tools;
    public modalRef: BsModalRef;

    private saveContentSubject: Subscription;
    private enabledContentTypes: ContentTypes[];

    constructor(
      private contextService: CurrentContextService,
      private placeService: PlaceService,
      private route: ActivatedRoute,
      private router: Router,
      private modalService: BsModalService,
      private infoCardService: InfoCardService
    ) {
      this.enabledContentTypes = this.config.getEnabledContentTypes();
    }

    public ngOnInit(): void {
      this.contextService.setCurrentState(AppState.editPlace);
      this.currentLanguage = Language.DE;
      this.getPlace();
      this.getInfoCards();
      this.saveContentSubject = this.contextService.saveYourContent.subscribe(
        saveContent => {
          if (saveContent) {
            this.onSave();
          }
        }
      );
    }

    public ngOnDestroy(): void {
      this.saveContentSubject.unsubscribe();
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
      return (this.placeChanged || this.infoCardsChanged) ? false : true;
    }

    private getPlace(): void {
      const id = this.route.snapshot.paramMap.get('placeId');
      const sub = this.placeService.getPlaceById(id).subscribe(
        place => {
          this.place = new Place(place);
          this.originalPlace = new Place(place);
          this.contextService.getExhibition(this.place.exhibitionIds[0]);
          this.initMap();
          sub.unsubscribe();
        }
      );
    }

    private getInfoCards(): void {
      const id = this.route.snapshot.paramMap.get('placeId');
      const sub = this.infoCardService.getInfoCardsByPlaceId(id).subscribe(
        infoCards => {
          infoCards = infoCards.sort((a,b) => a.position - b.position);
          this.infoCards = [];
          this.originalInfoCards = [];
          for (const infoCard of infoCards) {
            this.infoCards.push(new InfoCard(infoCard));
            this.originalInfoCards.push(new InfoCard(infoCard));
          }
          this.checkForInfoCardsChanged();
          sub.unsubscribe();
        }
      );
    }

    private initMap(): void {
      const map = L.map('map', {center:[1, 1], zoom: 1, doubleClickZoom: false});
      if (this.place.location !== undefined && this.place.location.containsLonAndLat()) {
        let marker;
        if (this.place.radius !== undefined) {
          marker = L.circle(L.latLng(this.place.location.lat, this.place.location.lon), this.place.radius).addTo(map);
        } else {
          marker = L.circle(L.latLng(this.place.location.lat, this.place.location.lon), 1).addTo(map);
        }
        map.fitBounds(marker.getBounds());
      } else {
        const bounds = L.latLngBounds(L.latLng(47.26543, 5.864417), L.latLng(55.14777, 15.05078));
        map.fitBounds(bounds);
      }
      const tiles = L.tileLayer(new Config().getMapUrl(), {
        tileSize: 256,
        minZoom: 1,
        attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
        crossOrigin: true
      });
      tiles.addTo(map);
      L.control.scale().addTo(map);
    }

    public selectInfoCard(infoCard: InfoCard): void {
      if (infoCard === undefined) {
        this.selectedInfoCard = undefined;
        if (infoCard instanceof InfoCard) {
          this.selectedInfoCard = infoCard;
        }
      } else {
        this.selectedInfoCard = infoCard;
      }
    }

    public toggleActive(infoCard: InfoCard, event: MouseEvent): void {
      event.stopPropagation();
      infoCard.active = !infoCard.active;
      this.checkForInfoCardsChanged();
    }

    public addNewInfoCard(): void {
      const infoCard = new InfoCard();
      infoCard.position = this.infoCards.length;
      infoCard.parentPlaceId = this.place.id;
      const sub = this.infoCardService.createInfoCard(infoCard).subscribe(
        response => {
          const location: string = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
          infoCard.id = location.substring(location.lastIndexOf('/') + 1);
          this.infoCards.push(infoCard);
          this.originalInfoCards.push(new InfoCard(infoCard));
          this.checkForInfoCardsChanged();
          sub.unsubscribe();
        }
      );
    }

    public moveInfoCardUp(): void {
      if (this.selectedInfoCard && this.selectedInfoCard.position > 0) {
        const temp = this.infoCards[this.selectedInfoCard.position - 1];
        this.infoCards[this.selectedInfoCard.position - 1] = this.infoCards[this.selectedInfoCard.position];
        this.infoCards[this.selectedInfoCard.position] = temp;
        this.updateInfoCardPositions();
      }
    }

    public moveInfoCardDown(): void {
      if (this.selectedInfoCard && this.selectedInfoCard.position < this.infoCards.length - 1) {
        const temp = this.infoCards[this.selectedInfoCard.position + 1];
        this.infoCards[this.selectedInfoCard.position + 1] = this.infoCards[this.selectedInfoCard.position];
        this.infoCards[this.selectedInfoCard.position] = temp;
        this.updateInfoCardPositions();
      }
    }

    private updateInfoCardPositions(): void {
      let counter = 0;
      for (const infoCard of this.infoCards) {
        infoCard.position = counter;
        counter++;
      }
      this.checkForInfoCardsChanged();
    }

    public checkForPlaceChanged(): void {
      if (this.place.equal(this.originalPlace)) {
        this.placeChanged = false;
      } else {
        this.placeChanged = true;
      }
    }

    public checkForInfoCardsChanged(): void {
      if (this.infoCards.length === this.originalInfoCards.length) {
        for (let i = 0; i < this.infoCards.length; i++) {
          if (!this.infoCards[i].equal(this.originalInfoCards[i])) {
            this.infoCardsChanged = true;
            return;
          }
        }
        this.infoCardsChanged = false;
      } else {
        this.infoCardsChanged = true;
      }
    }

    public getInfoCardListEntryStyle(infoCard: InfoCard): Object {
      let borderStyle = '';
      if (infoCard && infoCard.active) {
        borderStyle = (this.selectedInfoCard !== undefined && this.selectedInfoCard.position === infoCard.position) ? '2px solid #87ceeb' : '2px solid gainsboro';
      } else {
        borderStyle = (this.selectedInfoCard !== undefined && this.selectedInfoCard.position === infoCard.position) ? '2px solid rgba(255,0,0,0.3)' : '2px solid rgba(220,220,220,0.3)';
      }
      const style = {
        'border': borderStyle
      };
      return style;
    }

    public getInfoCardListTitleStyle(infoCard: InfoCard): Object {
      const style = {
        'opacity': infoCard.active ? '1' : '0.3'
      };
      return style;
    }

    public cancel(): void {
      if (this.modalRef) { this.modalRef.hide(); }
      this.routeTo(this.appState.images);
    }

    public openModal(modalTemplate: TemplateRef<any>): void {
      this.modalRef = this.modalService.show(modalTemplate);
    }

    public async onSave(): Promise<any> {
      this.contextService.setSavingContent(true);
      if (this.placeChanged) {
        await this.placeService.updatePlace(this.place).pipe().toPromise();
        this.originalPlace = new Place(this.place);
        this.checkForPlaceChanged();
      }
      if (this.infoCardsChanged) {
        const requests = [];
        const infoCardsToDelete = this.originalInfoCards.filter(oInfoCard => !this.infoCards.find(infoCard => infoCard.id === oInfoCard.id));
        for(const infoCard of infoCardsToDelete) {
          requests.push(this.infoCardService.deleteInfoCard(infoCard));
        }
        for(const infoCard of this.infoCards) {
          if (infoCard.id !== undefined) {
            requests.push(this.infoCardService.updateInfoCard(infoCard));
          } else {
            requests.push(this.infoCardService.createInfoCard(infoCard));
          }
        }
        if (requests.length > 0) {
          await forkJoin(requests).pipe(
            tap(
              response => {
                this.getInfoCards();
                this.checkForInfoCardsChanged();
                if (this.modalRef) {
                  this.modalRef.hide();
                }
              }
            )
          ).toPromise().then();
        } else {
          if (this.modalRef) {
            this.modalRef.hide();
          }
        }
      }
      this.contextService.setSavingContent(false);
    }

    public deletePlace(): void {
      this.modalRef.hide();
      this.placeChanged = false;
      this.contextService.setSavingContent(true);
      const sub = this.placeService.deletePlace(this.place).subscribe(
        () => {
          this.contextService.setSavingContent(false);
          this.cancel();
          sub.unsubscribe();
        }
      );
    }

    public deleteInfoCard(infoCard: InfoCard): void {
      this.infoCards = this.infoCards.filter(oInfoCard => !oInfoCard.equal(infoCard));
      this.updateInfoCardPositions();
    }

    public onDiscard(): void {
      if (this.placeChanged) {
        this.place = new Place(this.originalPlace);
        this.placeChanged = false;
      }
      if (this.infoCardsChanged) {
        this.infoCards = [];
        for(const infoCard of this.originalInfoCards) {
          this.infoCards.push(new InfoCard(infoCard));
        }
        this.infoCardsChanged = false;
      }
      this.modalRef.hide();
    }

    public isLanguage(language: Language): boolean {
      return language === this.currentLanguage;
    }

    public getLanguageValue(text: any): string {
      return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
    }

    public getLanguageValueFromInfoCard(infoCard: InfoCard): string {
      if (infoCard.title) {
        return new MultiLanguageText(infoCard.title).getInLanguage(this.currentLanguage);
      }
      return '';
    }

    public isInfoCardActive(infoCard: InfoCard): boolean {
      if (infoCard.active) {
        return true;
      } else {
        return false;
      }
    }

    public updateLanguage(language: Language): void {
      this.currentLanguage = language;
    }

    public routeTo(appState: AppState): void {
      switch (appState) {
        case AppState.images:
          this.router.navigate(['exhibitions', this.place.exhibitionIds[0], 'images']);
          break;
        default:
          break;
      }
    }

    public routeToEditPlaceMeta(event) {
      event.stopPropagation();
      this.selectedInfoCard = undefined;
      this.router.navigate(['places/' + this.place.id + '/meta/']);
    }

    public routeToEditInfoCard(event, infoCard: InfoCard) {
      event.stopPropagation();
      this.selectedInfoCard = undefined;
      this.router.navigate(['places/' + this.place.id + '/infoCards/' + infoCard.id]);
    }

    public trackBy(position: number, item: InfoCard) {
      return item.id;
    }

    public toggleType(infoCard: InfoCard, event: MouseEvent): void {
      event.stopPropagation();

      const index = this.enabledContentTypes.indexOf(infoCard.contentType);
      infoCard.contentType = this.enabledContentTypes[(index + 1) % this.enabledContentTypes.length];

      this.checkForInfoCardsChanged();
    }
}
