import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Exhibition } from '../../models/Exhibition';
import { Language } from '../../models/Language';
import { MultiLanguageText } from '../../models/MultiLanguageText';
import { ExhibitionService } from '../../services/exhibition.service';
import { ImageService } from '../../services/image.service';

/**
 * DeleteExhibitionModal shows up whenever you are trying to delete an exhibition.
 * Requests confirmation.
 */
@Component({
  selector: 'modal-content',
  templateUrl: './delete.modal.component.html',
  styleUrls: ['./delete.modal.component.scss']
})
export class DeleteExhibitonModal implements OnInit {

  public currentLanguage: Language;

  /**
	 * The exhibition object.
	 * Is set by the initialState.
	 */
  public exhibition: Exhibition;

  /**
	 * boolean variable: Indicates if the exhibition can be deleted.
	 */
  public delete = false;

  /**
	 * Constructor
	 * @param bsModalRef Reference of the modal
	 * @param exhibitionService The exhibition service
	 */
  public constructor(
    public bsModalRef: BsModalRef,
    private exhibitionService: ExhibitionService,
    private imageService: ImageService
  ) { }

  /**
	 * Is called after Angular has initialized all data-bound properties of a directive.
	 * Sets delete to false.
	 */
  public ngOnInit(): void {
    this.delete = false;
  }

  /**
	 * Triggert by a click on the close button.
	 * Hides the modal without further action.
	 */
  public onClose(): void {
    this.bsModalRef.hide();
  }

  /**
	 * Triggert by a click on the delete button.
	 * Calls deleteImage method of image service to delete all images related to this exhibition.
	 * Calls the delete method of the exhibition service to delete the exhibition on the server.
	 * Sets delete variable to true and hides the modal.
	 */
  public onDelete(): void {
    const sub = this.exhibitionService.deleteExhibition(this.exhibition).subscribe(() => {
      this.delete = true;
      this.bsModalRef.hide();
      sub.unsubscribe();
    });
  }

  public getLanguageValue(text: MultiLanguageText): string {
    return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
  }
}

