<div class="window">
  <aacms-loader class="imageLoader" size="64px" *ngIf="!this.imageData || !this.imageData.fileSafeUrl">
  </aacms-loader>
  <div #imageContainer class="image-container">
    <img #imageView class="image unselectable" *ngIf="this.imageData && this.imageData.fileSafeUrl"
      [src]="this.imageData.fileSafeUrl" (click)="showNewPoint($event)" (load)="reloadPoiLayer()"
      [style]="this.getBorderStyle()" draggable="false">
    <ng-template #poiLayer></ng-template>
  </div>
  <button #newPoint class="newpoi" [popover]="popTemplate" #pop="bs-popover" placement="right" outsideClick="true"
    (keyup.escape)="cancelNewPoi($event)" (keyup.enter)="addNewPoi($event)" container="body"
    [adaptivePosition]="false"></button>
</div>

<ng-template #popTemplate>
  <div>
    <button type="button" class="btn btn-success popup-button" (click)="addNewPoi($event)">
      <i class="fa fa-check" aria-hidden="true"></i> {{'CREATE' | translate }}
    </button>
  </div>
  <div>
    <button type="button" class="btn btn-secondary popup-button" (click)="cancelNewPoi($event)">
      <i class="fa fa-times" aria-hidden="true"></i> {{'ABORT' | translate }}
    </button>
  </div>
</ng-template>
