import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { StringService } from '../services/string.service';
import { ConfirmationModalComponent } from '../modals/confirmation/confirmation-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(
    private stringService: StringService,
    private bsModalService: BsModalService
  ) {
  }

  public canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    if (component.canDeactivate()) {
      return true;
    } else {
      return this.showConfirmationModal();
    }
  }

  public showConfirmationModal(): Observable<boolean> {
    const modal = this.bsModalService.show(ConfirmationModalComponent);
    (<ConfirmationModalComponent>modal.content).showConfirmationModal();

    return (<ConfirmationModalComponent>modal.content).onClose.asObservable();
  }
}
