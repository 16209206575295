import { Tracker } from './Tracker';
import { UiElement } from './UiElements';
import { Card } from './Card';
import { MultiLanguageText } from './MultiLanguageText';
import { ContentTypes } from './ContentTypes';
import { Config } from '../Config';
import { ModelHelper } from './ModelHelper';
import { MultiLanguageData } from './MultiLanguageData';
import { TrackerTriggerOpticalMarker, TrackerTriggerPosition } from './TrackerTrigger';
import { MultiLanguageMediaIdValue } from './MultiLanguageMediaId';

export class HologramMediaIdValue extends MultiLanguageMediaIdValue {
	public scaleFactor: number = 1.0;

	constructor(mediaIdValue?: HologramMediaIdValue) {
		super(mediaIdValue);
		if (!mediaIdValue) { return; }
		this.mediaId = mediaIdValue.mediaId;
		this.scaleFactor = mediaIdValue.scaleFactor;
	}

	public static fromMediaId(mediaId: string): HologramMediaIdValue {
		let result = new HologramMediaIdValue();
		result.mediaId = mediaId;
		return result;
	}

	public equal(mediaIdValue: HologramMediaIdValue) {
		return (super.equal(mediaIdValue) && this.scaleFactor === mediaIdValue.scaleFactor);
	}
}

export class Hologram extends Tracker {
	public uiElements: UiElement[] = [];
	public popupElements: UiElement[] = [];

	/** 3D Model id */
	public model: MultiLanguageData<HologramMediaIdValue> = new MultiLanguageData<HologramMediaIdValue>(HologramMediaIdValue);
	public positionTriggers: TrackerTriggerPosition[] = [];
	public opticalTriggers: TrackerTriggerOpticalMarker[] = [];

	public width = 0;
	public height = 0;

	public card: Card = new Card();
	public url: MultiLanguageText = new MultiLanguageText();
	public cardsActive = true;
	public contentType: ContentTypes = new Config().getEnabledContentTypes()[0];

	public constructor(hologram?: Hologram) {
		super(hologram);
		if (!hologram) { return; }
		this.model = new MultiLanguageData(HologramMediaIdValue, hologram.model);
		this.uiElements = ModelHelper.cloneUiElementArray(hologram.uiElements).sort((uie1, uie2) => uie1.position - uie2.position);
		this.popupElements = ModelHelper.cloneUiElementArray(hologram.popupElements).sort((uie1, uie2) => uie1.position - uie2.position);
		this.positionTriggers = ModelHelper.arrayClone<TrackerTriggerPosition>(TrackerTriggerPosition, hologram.positionTriggers);
		this.opticalTriggers = ModelHelper.arrayClone<TrackerTriggerOpticalMarker>(TrackerTriggerOpticalMarker, hologram.opticalTriggers);
		this.cardsActive = hologram.cardsActive;
		this.contentType = hologram.contentType;
		this.width = hologram.width;
		this.height = hologram.height;
		this.card = new Card(hologram.card);
		this.url = new MultiLanguageText(hologram.url);
	}

	public equal(hologram: Hologram): boolean {
		if (!this.model.equal(hologram.model) ||
			!this.card.equal(hologram.card) ||
			!this.url.equal(hologram.url) ||
			this.cardsActive !== hologram.cardsActive ||
			this.contentType !== hologram.contentType) {
			return false;
		}

		if (!ModelHelper.arrayEqual(this.uiElements, hologram.uiElements)) { return false; }
		if (!ModelHelper.arrayEqual(this.popupElements, hologram.popupElements)) { return false; }
		if (!ModelHelper.arrayEqual(this.positionTriggers, hologram.positionTriggers)) { return false; }
		if (!ModelHelper.arrayEqual(this.opticalTriggers, hologram.opticalTriggers)) { return false; }
		return super.equal(hologram);
	}
}
