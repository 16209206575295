import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { FileDataType } from "../app/models/FileDataType";
import { Media } from "../app/models/Media";
import { MediaService } from "../app/services/media.service";
import { Tools } from "../app/Tools";

export enum MediaType {
	Undertermined,
	Image,
    Video,
    Audio,
	Model
}

export class MediaData {
    private rawFile: File = null;
    private rawFileData: SafeUrl = null;
    private rawFileDownloaded: boolean = false;

    private thumbFile: File = null;
    private thumbFileData: SafeUrl = null;
    private thumbFileDownloaded: boolean = false;

    private activeDownloads: number = 0;

    public type: MediaType = MediaType.Undertermined;
	constructor(
        private mediaService: MediaService,
        private sanitizer: DomSanitizer,
		public media: Media
	) {
        if(media.type.startsWith("image/")) { this.type = MediaType.Image; }
        else if(media.type.startsWith("video/")) { this.type = MediaType.Video; }
        else if(media.type.startsWith("audio/")) { this.type = MediaType.Audio; }
        else if(media.type.startsWith("model/")) { this.type = MediaType.Model; }
    }

    public getRawFile() : File | null {
        this.downloadRawIfRequired();
        return this.rawFile;
    }
    public getRawUrl() : SafeUrl | null {
        this.downloadRawIfRequired();
        return this.rawFileData;
    }

    /**
     * Asynchronous function that awaits the availability of the raw
     * file data.
     */
    public async guaranteeRawAvailable() {
        this.downloadRawIfRequired();
        return new Promise<MediaData>((resolve, reject) => {
            // Yeah, well... I mean... It works, so who cares? xD
            let checkInt = setInterval(() => {
                if(this.rawFileDownloaded) {
                    if(this.rawFile) {
                        resolve(this);
                        clearInterval(checkInt);
                    } else if(this.activeDownloads === 0) {
                        reject();
                        clearInterval(checkInt);
                    }
                }
            }, 100);
        });
    }

    /**
     * Helper method that prioritizes thumbnails, but if this media doesn't have one, falls back
     * to providing the raw content.
     */
    public getThumbOrRawUrl() : SafeUrl | null {
        if(this.media.thumbUrl) { return this.getThumbUrl(); }
        return this.getRawUrl();
    }

    private downloadRawIfRequired() {
        if(this.rawFileDownloaded) { return; }
        this.rawFileDownloaded = true;
        if(!this.media.rawUrl) { return; }
        this.startDownloadIfRequired(this.media.rawUrl, (file) => {
            this.rawFile = file;
            if(this.type == MediaType.Image || this.type == MediaType.Video || this.type == MediaType.Audio) {
                this.rawFileData = this.createBlobUrl(file);
            } else if(this.type == MediaType.Model) {
                this.createModelUrl(file, (safeUrl) => {
                    this.rawFileData = safeUrl;
                });
            }
        });
    }

    public getName() : string {
        return this.media.name;
    }

    public getThumbFile() : File {
        this.downloadThumbIfRequired();
        return this.thumbFile;
    }
    public getThumbUrl() : SafeUrl | null {
        this.downloadThumbIfRequired();
        return this.thumbFileData;
    }

    public isDownloadRunning() : boolean {
        return (this.activeDownloads != 0);
    }

    private downloadThumbIfRequired() {
        if(this.thumbFileDownloaded) { return; }
        this.thumbFileDownloaded = true;
        if(!this.media.thumbUrl) { return; }
        this.startDownloadIfRequired(this.media.thumbUrl, (file) => {
            this.thumbFile = file;
            this.thumbFileData = this.createBlobUrl(file);
        });
    }

    private startDownloadIfRequired(url: string, cb: (File) => void) {
        this.activeDownloads += 1;
        const sub = this.mediaService.downloadFile(url).subscribe(
            blob => {
                console.log("MultilangMediaLoader", "Download finished", url);
                const file = Tools.blobToFile(blob, this.media.name);
                cb(file);
                this.activeDownloads -= 1;
                sub.unsubscribe();
            },
            () => {
                this.activeDownloads -= 1;
            }
        );
    }

    private createBlobUrl(file: File): SafeUrl {
		const urlCreator = window.URL;
		const url = this.sanitizer.bypassSecurityTrustUrl(
			urlCreator.createObjectURL(file)
		);
		return url;
	}
    private createModelUrl(file: File, cb: (any) => void) {
        const reader = new FileReader();
        reader.onloadend = function () {
            cb(reader.result);
        };
        reader.readAsDataURL(file);
    }
}

export class MediaLoader {

    private mediaData: Map<string, MediaData> = new Map<string, MediaData>();
    private mediasFailed: Set<string> = new Set<string>();
    private mediasPending: Set<string> = new Set<string>();

    constructor(
        private mediaService: MediaService,
        private sanitizer: DomSanitizer
    ) {}

    public reset() {
        this.mediaData.clear();
        this.mediasPending.clear();
    }

    /**
     * Allows side-injecting custom media. For example to avoid unnecessary download of already available data.
     * Or to insert custom media into the loader infrastructure.
     * @param media
     */
    public insertMedia(media: Media) {
        if(!this.mediaData.has(media.id)) {
            let mediaData = new MediaData(this.mediaService, this.sanitizer, media);
            this.mediaData.set(media.id, mediaData);
        }
    }

    public isLoaded(mediaId: string) : boolean {
        if(!mediaId) { return false; }
        if(!this.mediaData.has(mediaId)) {
            this.startDownloadIfRequired(mediaId);
            return false;
        }
        return true;
    }
    public get(mediaId: string) : MediaData {
        if(!mediaId) { return null; }
        if(!this.mediaData.has(mediaId)) {
            this.startDownloadIfRequired(mediaId);
            return null;
        }
        return this.mediaData.get(mediaId);
    }

    public async getAsync(mediaId: string) : Promise<MediaData> {
        return new Promise<MediaData>((resolve, reject) => {
            // Yeah, well... I mean... It works, so who cares? xD
            let checkInt = setInterval(() => {
                if(this.mediaData.has(mediaId)) {
                    clearInterval(checkInt);
                    resolve(this.mediaData.get(mediaId));
                } else if(this.mediasFailed.has(mediaId)) {
                    clearInterval(checkInt);
                    reject();
                }
            }, 100);
        });
    }

    /**
     * Backwards-compatible getter producing FileDataType instances.
     * @param mediaId
     */
    public getFileData(mediaId: string) : FileDataType {
        let media = this.get(mediaId);
        let result = new FileDataType();
        result.mediaId = mediaId;
        if(media) {
            result.media = media?.media;
            if(media.type == MediaType.Audio || media.type == MediaType.Video) {
                result.fileSafeUrl = media.getRawUrl();
                result.file = media.getRawFile();
            } else {
                result.fileSafeUrl = media.getThumbUrl();
                result.file = media.getThumbFile();
            }
        }
        result.isLoaded = (media != null && result.fileSafeUrl != null);
        return result;
    }

    private startDownloadIfRequired(mediaId: string) {
        if(mediaId && this.mediasPending.has(mediaId)) { return; }
        if(this.mediasFailed.has(mediaId)) { return; }
        this.mediasPending.add(mediaId);
        setTimeout(() => {
            console.log("MultilangMediaLoader", "Starting download", mediaId);

            const sub = this.mediaService.getMediaById(mediaId).subscribe(
                media => {
                    media = new Media(media);
                    this.mediaData.set(mediaId, new MediaData(this.mediaService, this.sanitizer, media));
                    this.mediasPending.delete(mediaId);
                    sub.unsubscribe();
                },
                _ => {
                    this.mediasFailed.add(mediaId);
                    this.mediasPending.delete(mediaId);
                    sub.unsubscribe();
                }
            );
        }, 0);
    }
}