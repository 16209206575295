import { Component, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AppState } from '../current.app.state';
import { FileDataType } from '../models/FileDataType';
import { Language } from '../models/Language';
import { MultiLanguageText } from '../models/MultiLanguageText';
import { AudioElement, ElementType, ImageElement, ModelElement, VideoElement } from '../models/UiElements';
import { CurrentContextService } from '../services/currentContext.service';
import { MediaService } from '../services/media.service';
import { Tools } from '../Tools';
import { Config } from '../Config';
import { StringService } from '../services/string.service';
import { ContentTypes } from '../models/ContentTypes';
import * as L from 'leaflet';
import { Tour } from '../models/Tour';
import { TourService } from '../services/tour.service';
import { GPXParserService } from '../services/gpx.parser.service';
import { GeoJsonParserService } from '../services/geoJson.parser.service';
import { GeoJson, MapElement } from '../models/GeoJson';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { tap } from 'rxjs/operators';
import { Media } from '../models/Media';
import { CubeSides } from '../models/CubeSides';
import { TabType } from '../models/TabType';
import { Accessibility } from '../models/Accessibility';
import { UserConfigService } from '../services/userConfig.service';

@Component({
  selector: 'aacms-edit-tour',
  templateUrl: './edit-tour-meta.component.html',
  styleUrls: ['./edit-tour-meta.component.scss']
})
export class EditTourMetaComponent {
  public modalRef: BsModalRef;
  public tour: Tour;
  private originalTour: Tour;

  public map: any;

  @ViewChild('smartphone') smartphone;
  @ViewChild('cancelModalTemplate', { static: false }) cancelModalTemplate: TemplateRef<any>;
  @ViewChild('saveModalTemplate', { static: false }) saveModalTemplate: TemplateRef<any>;

  public elementType = ElementType;
  public Language = Language;
  public appState = AppState;
  public Tools = Tools;
  public contentTypes = ContentTypes;
  public config: Config = new Config();
  public tourChanged = false;

  public cardImageDataMap: Map<CubeSides, FileDataType> = new Map([[CubeSides.image, null]]);

  public currentLanguage: Language;

  public currentTab: TabType;
  public tabType = TabType;

  public mapElementRef: MapElement;

  private saveContentSubject: Subscription;

  public ractangle: any;
  public Accessibility = Accessibility;

  public constructor(
    public contextService: CurrentContextService,
    private mediaService: MediaService,
    private modalService: BsModalService,
    private tourService: TourService,
    private router: Router,
    private route: ActivatedRoute,
    private stringService: StringService,
    private gpxParser: GPXParserService,
    private geoJsonParser: GeoJsonParserService,
    private sanitizer: DomSanitizer,
    private userConfigService: UserConfigService
  ) { }

  public ngOnInit(): void {
    this.contextService.setCurrentState(AppState.editTourMeta);
    this.currentLanguage = Language.DE;
    this.saveContentSubject = this.contextService.saveYourContent.subscribe(
      saveContent => {
        if (saveContent && this.tour !== undefined) {
          this.save().then();
        }
      }
    );
  }

  public ngAfterViewInit(): void {
    this.getTour();
  }

  public ngOnDestroy(): void {
    this.saveContentSubject.unsubscribe();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (this.tourChanged) ? false : true;
  }

  public askToSave(): void {
    this.modalRef = this.modalService.show(this.cancelModalTemplate, { keyboard: false, ignoreBackdropClick: true });
  }

  public getStringNameForAccessibilityEnum(type: Accessibility) {
    if (type === Accessibility.BARRIER_FREE) {
      return this.stringService.get('BARRIER_FREE');
    } else if (type === Accessibility.NOT_BARRIER_FREE) {
      return this.stringService.get('NOT_BARRIER_FREE');
    } else if (type === Accessibility.PARTIAL_BARRIER_FREE) {
      return this.stringService.get('PARTIAL_BARRIER_FREE');
    }
  }

  public getAccessibilityValues(): any {
    return Object.keys(Accessibility).filter(e => !isNaN(+e)).map(o => { return +o});
  }

  public discard(): void {
    this.tour = new Tour(this.originalTour);
    this.findGeoJsonRef();
    setTimeout(()=>{
      this.clearMap();
      this.initMap();
      this.smartphone.onDiscard();
    });
    this.checkForTourChanged();
    this.hideModal();
  }

  public hideModal(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  private getTour(): void {
    const id = this.route.snapshot.paramMap.get('tourId');
    const sub = this.tourService.getTourById(id).subscribe(
      tour => {
        this.tour = new Tour(tour);
        this.originalTour = new Tour(tour);
        this.findGeoJsonRef();
        setTimeout(() => {
          this.initMap();
        });
        sub.unsubscribe();
      }
    );
  }

  private findGeoJsonRef(): void {
    this.mapElementRef = undefined;
    for (const uiElement of this.tour.href.uiElements) {
      if (uiElement instanceof MapElement && uiElement.isMandatory === true) {
        this.mapElementRef = (<MapElement>uiElement);
        break;
      }
    }
  }

  private createImageUrl(file: File): SafeUrl {
    const urlCreator = window.URL;
    const url = urlCreator.createObjectURL(file);
    const safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    return safeUrl;
  }

  private createCustomMarker(env, feature, latlng) {
    let myCustomColour;
    if (feature.properties.active) {
      myCustomColour = env.userConfigService.getSync().highlightColor;
    } else {
      myCustomColour = '#a3a3a3';
    }
    const markerHtmlStyles = `
      background: ${myCustomColour};
      width: 46px;
      height: 46px;
      display: block;
      left: -22px;
      top: -31px;
      position: relative;
      border-radius: 50% 50% 50% 0;
      transform: rotate(-45deg);
      display: flex;
      justify-content: center;
      align-items: center;
    `
    const icon = L.divIcon({
      className: "leaflet-marker",
      iconAnchor: [0, 23],
      labelAnchor: [-6, 0],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}"><div class="leaflet-marker-text-ui"><div>${feature.properties.position}</div></div></span>`
    });
    const marker = L.marker(latlng, { draggable: false, icon: icon, feature: feature });
    return marker;
  }

  private includeGeoJsonFeatures(feature): boolean {
    if (feature.geometry.type === 'Point' || feature.geometry.type === 'LineString') {
      return true;
    }
    return false;
  }


  public clearMap(): void {
    if (this.map !== undefined) {
      this.map.off();
      this.map.remove();
      this.map = undefined;
    }
  }

  private initMap(): void {
    if (this.mapElementRef.mapObject !== undefined) {
      const map = L.map('map', {
        doubleClickZoom: false
      });
      const tiles = L.tileLayer(new Config().getMapUrl(), {
        tileSize: 256,
        minZoom: 1,
        attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
        crossOrigin: true
      });
      tiles.addTo(map);
      this.map = map;
      const myLayerOptions = {
        pointToLayer: (feature, latlng) => this.createCustomMarker(this, feature, latlng),
        //filter: (feature) => this.includeGeoJsonFeatures(feature)
      }
      const geoJsonElements = L.geoJSON(this.mapElementRef.mapObject, myLayerOptions);
      geoJsonElements.addTo(map);
      let bounds;
      if (this.mapElementRef.mapObject.properties.bottomLeft.containsLonAndLat() && this.mapElementRef.mapObject.properties.topRight.containsLonAndLat()) {
        bounds = L.latLngBounds(this.mapElementRef.mapObject.properties.bottomLeft, this.mapElementRef.mapObject.properties.topRight);
        setTimeout(()=> {
          this.ractangle = L.rectangle(bounds, {fill: false, color: "#00ff00"});
          this.ractangle.addTo(map);
        });
      } else {
        const group = new L.featureGroup(Object.values(geoJsonElements._layers));
        if (Object.values(map._layers).length > 0) {
          bounds = group.getBounds();
        } else {
          bounds = L.latLngBounds(L.latLng(47.26543, 5.864417), L.latLng(55.14777, 15.05078));
        }
      }
      map.fitBounds(bounds);
      L.control.scale().addTo(map);
    }
  }

  public getLanguageValue(text: MultiLanguageText): string {
    return new MultiLanguageText(text).getInLanguage(this.currentLanguage);
  }

  public async save(): Promise<any> {
    this.contextService.setSaveYourContent(false);
    this.contextService.setSavingContent(true);
    await this.tourService.updateTour(this.tour).pipe().toPromise().then();
    this.originalTour = new Tour(this.tour);
    this.checkForTourChanged();
    this.contextService.setSavingContent(false);
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  public openModal(modalTemplate: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(modalTemplate);
  }

  public returnToTour(): void {
    this.router.navigate(['tours', this.route.snapshot.paramMap.get('tourId')]);
  }

  public setLanguage(language: Language): void {
    this.currentLanguage = language;
  }

  public isLanguage(language: Language): boolean {
    return language === this.currentLanguage;
  }

  public updateLanguage(language: Language): void {
    this.currentLanguage = language;
  }

  public toggleTourActivation(): void {
    this.tour.active = !this.tour.active;
    this.checkForTourChanged();
  }

  public getStringNameForContentType(type: ContentTypes): string {
    switch (type) {
      case ContentTypes.Custom: {
        return this.stringService.get('CUSTOM');
      }
      case ContentTypes.URL: {
        return this.stringService.get('URL');
      }
    }
  }

  public showContentTypesSelection(): boolean {
    return this.config.getEnabledContentTypes().length > 1;
  }

  public downloadFileToSystem(geoJsonData: GeoJson): void {
    const json = JSON.stringify(geoJsonData);
    const uri = "data:text/json;charset=UTF-8," + encodeURIComponent(json);
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", uri);
    downloadAnchorNode.setAttribute("download", this.getFilename() + ".geojson");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  public getFilename(): string {
    return this.mapElementRef.mapObject.properties.filename;
  }

  public removeGeoJson(): void {
    if (this.mapElementRef) {
      this.mapElementRef.mapObject = undefined;
    }
    if (this.map !== undefined) {
      this.clearMap();
    }
    this.checkForTourChanged();
  }

  public handleFilesFromDropin(event: File[]): void {
    if (event.length > 0) {
      if (event[0].name.endsWith('gpx')) {
        this.gpxParser.parse(event[0]).then(
          geoJson => {
            this.mapElementRef.setGeoJson(new GeoJson(geoJson));
            setTimeout(() => {
              this.initMap();
              this.checkForTourChanged();
            });
          },
           error => {
             console.log(error);
           }
        );
      } else if (event[0].name.endsWith('geojson')) {
        this.geoJsonParser.parse(event[0]).then(
          geoJson => {
            this.mapElementRef.setGeoJson(new GeoJson(geoJson));
            setTimeout(() => {
              this.initMap();
              this.checkForTourChanged();
            });
          },
           error => {
             console.log(error);
           }
        );
      }
    }
  }

  public getMaxContentFileSize(): number {
    return new Config().getMaxGPXFileSize();
  }

  public routeTo(appState: AppState): void {
    switch (appState) {
      case AppState.tours:
        this.router.navigate(['tours']);
        break;
      case AppState.editTour:
        this.router.navigate(['tours', this.tour.id]);
        break;
      default:
        break;
    }
  }

  public isTabAllowed(type: ContentTypes): boolean {
    for (const contentType of this.config.getEnabledContentTypes()) {
      if (contentType === type) {
        return true;
      }
    }
    return false;
  }

  public checkForTourChanged(): void {
    if (this.tour.equal(this.originalTour)) {
      this.tourChanged = false;
    } else {
      this.tourChanged = true;
    }
    if (this.map !== undefined) {
      if (this.ractangle !== undefined) {
        this.map.removeLayer(this.ractangle);
      }
      if (this.mapElementRef.mapObject.properties.bottomLeft.containsLonAndLat() && this.mapElementRef.mapObject.properties.topRight.containsLonAndLat()) {
        const bounds = L.latLngBounds(this.mapElementRef.mapObject.properties.bottomLeft, this.mapElementRef.mapObject.properties.topRight);
        setTimeout(()=> {
          this.ractangle = L.rectangle(bounds, {fill: false, color: "#00ff00"});
          this.ractangle.addTo(this.map);
        });
      }
    }
  }

  public toggleWalkTourInOrder(): void {
    this.tour.walkTourInOrder = !this.tour.walkTourInOrder;
    this.checkForTourChanged();
  }
}
