<div *ngIf="this.image" class="edit-image-meta-main-content">
	<div class="edit-image-meta-left-side">
		<div *ngIf="image && !image.mediaId" class="no-image-available">
			<dropin [maxFileSize]="this.getMaxContentFileSize()" (sendFiles)="this.handleFilesFromDropin($event)"
				[accept]="'image/*'">
			</dropin>
			<div class="custom-hr"></div>
			<i class="no-image-file">{{'NO_IMAGE_AVAILABLE' | translate }}</i>
		</div>
		<aacms-loader class="imageLoader" size="128px"
			*ngIf="this.imageDataMap && this.imageDataMap.get(cubeSides.image) && !this.imageDataMap.get(cubeSides.image).isLoaded  && this.imageDataMap.get(cubeSides.image).file">
		</aacms-loader>
		<div *ngIf="this.imageDataMap && this.imageDataMap.get(cubeSides.image) && this.imageDataMap.get(cubeSides.image).isLoaded && this.imageDataMap.get(cubeSides.image).file"
			class="image-available">
			<aacms-poi-layer #poilayer class="poi-layer unselectable"
				[imageData]="this.imageDataMap.get(cubeSides.image)" [imageId]="this.image.id" [mapId]="cubeSides.image"
				[currentLanguage]="this.currentLanguage">
			</aacms-poi-layer>
			<div class="custom-hr"></div>
			<div>{{'CURRENT_IMAGE' | translate }}</div>
			<div class="current-file" [style.background-color]="this.getBackgroundColorForRating()">
				<div class="current-file-name text-truncate">
					{{this.imageDataMap.get(cubeSides.image).media ? this.imageDataMap.get(cubeSides.image).media.name :
					this.imageDataMap.get(cubeSides.image).file.name}}
				</div>
				<div class="update-buttons">

					<div class="tracking-quality"
						*ngIf="this.imageDataMap.get(cubeSides.image).media && this.imageDataMap.get(cubeSides.image).media.rating !== undefined"
						[matTooltip]="'TRACKING_QUALITY' | translate" triggers="hover">
						{{this.imageDataMap.get(cubeSides.image).media.rating}}%
					</div>
					<div *ngIf="this.imageDataMap.get(cubeSides.image).fileSafeUrl" class="download-file"
						(click)="this.downloadFileToSystem(this.imageDataMap.get(cubeSides.image))"
						[matTooltip]="'DOWNLOAD_MEDIA' | translate" triggers="hover">
						<i class="fa fa-download"></i>
					</div>
					<div class="file-delete" (click)="this.removeImage()" [matTooltip]="'REMOVE_MEDIA' | translate"
						triggers="hover">
						<i class="fa fa-times">
						</i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="edit-image-meta-right-side">
		<div class="header">
			<div class="path-title-container">
				<div class="path">
					<div class="path-option" (click)="this.routeTo(appState.exhibitions)">{{'EXHIBITIONS' | translate}}
					</div>
					/
					<div class="path-option" (click)="this.routeTo(appState.images)">{{
						Tools.shortenString(contextService.currentExhibition && contextService.currentExhibition.title
						&& getLanguageValue(contextService.currentExhibition.title).length > 0 ?
						getLanguageValue(contextService.currentExhibition.title) : ('TRACKERS' | translate)) }}</div>
					/
					<div class="path-option" (click)="this.routeTo(appState.editImage)">{{ Tools.shortenString(image &&
						getLanguageValue(image.title).length > 0 ? getLanguageValue(image.title) : ('IMAGE' |
						translate)) }}</div>
					/
					<div class="last-path-option">{{'IMAGE_POI' | translate}}</div>
				</div>
				<div class="arlayer-title-edit-container">
					<h1 class="edit-indicator">
						<i class="fa fa-pencil" aria-hidden="true"
							[ngStyle]="{'color': this.contentChanged || this.pms.poisChanged || this.imageDataChanged ? '#ffc107' : 'black' }"></i>
					</h1>
					<div class="arlayer-title-container">
						<h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.image.title).length > 0">
							{{this.getLanguageValue(this.image.title)}}
						</h1>
						<h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.image.title).length == 0">
							<i>{{'UNNAMED_IMAGE' | translate }}</i>
						</h1>
					</div>
				</div>
			</div>
			<aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
				(languageChanged)="this.updateLanguage($event)">
			</aacms-language-selector>
		</div>
		<div class="custom-hr"></div>
		<tabset [justified]="false">
			<tab heading="{{'IMAGE_GENERAL_TAB' | translate}}" class="image-general-tab tab"
				(selectTab)="this.currentTab = tabType.GeneralTab">
				<div>{{'TITLE' | translate }}</div>
				<div class="image-title-input">
					<div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
						<input class="form-control textInput" [(ngModel)]="this.image.title.de"
							maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
							(ngModelChange)="checkForContentChange()" />
						<div class="languageIndicator">
							<img class="language-button-image" src="../assets/flags/de.svg">
						</div>
						<div class="align-self-center px-2">
							{{this.config.getMaxTitleTextLength() - this.image.title.de.length}}
						</div>
					</div>
					<div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
						<input class="form-control textInput" [(ngModel)]="this.image.title.gb"
							maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
							(ngModelChange)="checkForContentChange()" />
						<div class="languageIndicator">
							<img class="language-button-image" src="../assets/flags/gb.svg">
						</div>
						<div class="align-self-center px-2">
							{{this.config.getMaxTitleTextLength() - this.image.title.gb.length}}
						</div>
					</div>
					<div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
						<input class="form-control textInput" [(ngModel)]="this.image.title.jp"
							maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
							(ngModelChange)="checkForContentChange()" />
						<div class="languageIndicator">
							<img class="language-button-image" src="../assets/flags/jp.svg">
						</div>
						<div class="align-self-center px-2">
							{{this.config.getMaxTitleTextLength() - this.image.title.jp.length}}
						</div>
					</div>
				</div>
				<div *ngIf="this.showContentTypesSelection()">{{ 'TYPE_OF_CONTENT_PAGE' | translate }}</div>
				<div *ngIf="this.showContentTypesSelection()" class="selection-container">
					<select class="selection" [(ngModel)]="this.image.contentType"
						(change)="this.checkForContentChange()">
						<ng-template ngFor let-type [ngForOf]="this.config.getEnabledContentTypes()">
							<option role="selection-item" [ngValue]="this.type">{{
								this.getStringNameForContentType(this.type) }}
							</option>
						</ng-template>
					</select>
				</div>
				<div class="checkbox-container">
					<label class="checkbox">
						<input type="checkbox" (click)="this.toggleImageActivation()" [checked]="this.image.active">
						<span class="checkmark" triggers="hover"></span>
						<div class="checkbox-text">
							{{ 'IMAGE_ACTIVE_INACTIVE' | translate }}
						</div>
					</label>
				</div>
				<div class="checkbox-container">
					<label class="checkbox">
						<input type="checkbox" (click)="this.toggleArImageCardsActivation()"
							[checked]="this.image.cardsActive">
						<span class="checkmark" triggers="hover"></span>
						<div class="checkbox-text">
							{{ 'IMAGE_CARDS_ACTIVE_INACTIVE' | translate }}
						</div>
					</label>
				</div>
				<div> {{ 'IMAGE_DIMENSIONS' | translate }} </div>
				<div class="image-dimensions">
					<div class="size-input-group">
						<div>{{ 'WIDTH' | translate}}</div>
						<input #widthInput class="form-control .form-control-sm" [(ngModel)]="this.width"
							[style.border]="this.image.width === 0 ? '1px solid red' : ''"
							[style.box-shadow]="this.image.width === 0 ? '0 0 0 .2rem rgba(255,0,0,.25)' : ''"
							(ngModelChange)="this.calcHeight($event, widthInput)">
					</div>
					<div class="size-input-group">
						<div>{{ 'HEIGHT' | translate}}</div>
						<input #heightInput class="form-control .form-control-sm" [(ngModel)]="this.height"
							[style.border]="this.image.height === 0 ? '1px solid red' : ''"
							[style.box-shadow]="this.image.height === 0 ? '0 0 0 .2rem rgba(255,0,0,.25)' : ''"
							(ngModelChange)="this.calcWidth($event, heightInput)">
					</div>
				</div>
			</tab>
			<tab heading="{{ 'IMAGE_CARD_TAB' | translate }}" class="card-tab tab"
				(selectTab)="this.currentTab = tabType.CardTab">
				<div class="smartphone-container">
					<aacms-info-card-editor [card]="this.image.card" [title]="this.image.title"
						[currentLanguage]="this.currentLanguage" [trackerFileData]="this.imageDataMap"
						(cardChange)="this.checkForContentChange()" [uiElements]="this.image.uiElements">
					</aacms-info-card-editor>
				</div>
			</tab>
			<tab heading="{{ 'IMAGE_CONTENT_PAGE_TAB' | translate }}" class="content-page-tab tab"
				*ngIf="this.isTabAllowed(contentTypes.Custom)" (selectTab)="this.currentTab = tabType.UiElementsTab">
				<div class="smartphone-container">
					<aacms-content-page #smartphone class="smartphone" [(uiElements)]="this.image.uiElements"
						[isVisible]="currentTab === tabType.UiElementsTab" (uiElementsChange)="checkForContentChange()"
						[language]="this.currentLanguage">
					</aacms-content-page>
				</div>
			</tab>
			<tab heading="{{ 'IMAGE_URL_PAGE_TAB' | translate }}" class="url-page-tab tab"
				*ngIf="this.isTabAllowed(contentTypes.URL)" (selectTab)="this.currentTab = tabType.UrlTab">
				<div class="smartphone-container">
					<aacms-url-page [url]="this.image.url" (urlChange)="this.checkForContentChange()"
						[language]="this.currentLanguage">
					</aacms-url-page>
				</div>
			</tab>
			<tab heading="{{ 'TRACKER_POPUP_PAGE_TAB' | translate }}" class="popup-page-tab tab"
				(selectTab)="this.currentTab = tabType.PopupTab">
				<div class="smartphone-container">
					<div class="checkbox-container">
						<label class="checkbox">
							<input type="checkbox" (click)="this.toggleUseCustomPopup()"
								[checked]="this.useCustomPopup">
							<span class="checkmark" triggers="hover"></span>
							<div class="checkbox-text">
								{{ 'USE_CUSTOM_POPUP' | translate }}
							</div>
						</label>
					</div>
					<aacms-popup-page #popupEditor class="smartphone" [(uiElements)]="this.image.popupElements"
						[isVisible]="currentTab === tabType.PopupTab" (uiElementsChange)="checkForContentChange()"
						[language]="this.currentLanguage"
						title="{{ 'POPUP_TITLE_IMAGE_ELEMENT' | translateWith:this.currentLanguage }}"
						[enabled]="this.useCustomPopup">
						<p popup-left-side-above>
							{{ 'TRACKER_POPUP_PAGE_HELP' | translate }}
						</p>
						<ng-container popup-editor-default-content>
							<ng-template [ngIf]="!this.useCustomPopup">
								<div class="element">
									<div class="image-element">
										<img class="image" src="../../assets/popups/Augmented_Reality_Tut.png">
									</div>
								</div>
								<div class="element">
									<div class="text-element">
										{{ 'POPUP_TEXT_IMAGE_ELEMENT' | translateWith:this.currentLanguage }}
									</div>
								</div>
							</ng-template>
						</ng-container>
					</aacms-popup-page>
				</div>
			</tab>
		</tabset>
		<div class="custom-hr"></div>
		<div class="button-row row">
			<button type="submit" class="btn btn-secondary custom-button" (click)="returnToImage()">
				{{'BACK_TO_IMAGE' | translate}}
			</button>
			<button type="submit" class="btn btn-warning custom-button"
				[disabled]="!this.contentChanged && !this.pms.poisChanged && !this.imageDataChanged"
				(click)="openModal(cancelModalTemplate)">
				{{'DISCARD' | translate }}
			</button>
			<button type="submit" class="btn btn-success custom-button"
				[disabled]="!this.contentChanged && !this.pms.poisChanged && !this.imageDataChanged"
				(click)="this.checkForSave()">
				{{'SAVE_CHANGES' | translate }}
			</button>
		</div>
	</div>
</div>

<ng-template #cancelModalTemplate>
	<div class="modal-header">
		<h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
	</div>
	<div class="modal-footer">
		<button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate
			}}</button>
		<button (click)="discard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
	</div>
</ng-template>

<ng-template #saveModalTemplate>
	<div class="modal-header">
		<h4 class="modal-title text-break pull-left">{{'REALY_SAVE_WITHOUT_DIMENSIONS' | translate}}</h4>
	</div>
	<div class="modal-footer">
		<button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'ABORT' | translate }}</button>
		<button (click)="this.save()" type="button" class="btn btn-success">{{'SAVE'| translate}}</button>
	</div>
</ng-template>