<div
	class="media-viewer"
	[ngStyle]="this.styles"
	[ngClass]="{ 'autosized': this.autoSize, 'fitsized': !this.autoSize, 'riffle-background': this.backgroundStyle=='riffle' }"
	(mouseenter)="this.onMouseEnter()"
	(mouseleave)="this.onMouseLeave()" (click)="this.onClick($event)">

	<ng-template [ngIf]="this.shouldShowLoadAnimation()">
		<div class="loaderOverlay">
			<aacms-loader class="imageLoader">
			</aacms-loader>
		</div>
	</ng-template>
	<ng-template [ngIf]="!this.shouldShowLoadAnimation()">

		<!-- IMAGE -->
		<ng-template [ngIf]="this.getMediaType() == MediaType.Image">
			<img class="image"
				[src]="this.getThumbnailUrl()">
		</ng-template>

		<!-- VIDEO -->
		<ng-template [ngIf]="this.getMediaType() == MediaType.Video">
			<!-- hack to force-rerender video/source -->
			<video class="video" *ngIf="this.getDelayedRawUrl() != null" (focus)="this.focus.emit($event)">
				<source [src]="this.getRawUrl()"/>
			</video>
		</ng-template>

		<!-- AUDIO -->
		<ng-template [ngIf]="this.getMediaType() == MediaType.Audio">
			<!-- hack to force-rerender audio/source -->
			<audio class="audio" *ngIf="this.getDelayedRawUrl() != null" controls (focus)="this.focus.emit($event)">
				<source [src]="this.getRawUrl()"/>
			</audio>
		</ng-template>

		<!-- MODEL -->
		<ng-template [ngIf]="this.getMediaType() == MediaType.Model">
			<ng-template [ngIf]="!this.shouldShowModel()">
				<img class="image"
					[src]="this.getThumbnailUrl()">
			</ng-template>
			<ng-template [ngIf]="this.shouldShowModel()">
				<model-viewer #modelViewer
					class="model"
					[ngClass]="{'riffle-background': this.backgroundStyle=='riffle'}"
					[attr.auto-rotate]="this.modelAutorotate"
					[attr.camera-controls]="this.modelCameraControls"
					[attr.src]="this.getRawUrl()"
					disable-zoom="true" autoplay>
				</model-viewer>
			</ng-template>
		</ng-template>
	</ng-template>
</div>