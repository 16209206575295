import { Tracker } from './Tracker';
import { AudioElement, ElementType, ImageElement, ModelElement, TextElement, UiElement, VideoElement } from './UiElements';
import { Card } from './Card';
import { MultiLanguageText } from './MultiLanguageText';
import { ContentTypes } from './ContentTypes';
import { Config } from '../Config';
import { MapElement } from './GeoJson';
import { ModelHelper } from './ModelHelper';

export class Image extends Tracker {

  public uiElements: UiElement[] = [];
  public popupElements: UiElement[] = [];

  public mediaId: string;

  public width = 0;
  public height = 0;

  public card: Card = new Card();
  public url: MultiLanguageText = new MultiLanguageText();
  public cardsActive = true;
  public contentType: ContentTypes = new Config().getEnabledContentTypes()[0];

  public constructor(image?: Image) {
    super(image);
    if (!image) { return; }
    this.mediaId = image.mediaId;
    this.uiElements = ModelHelper.cloneUiElementArray(image.uiElements).sort((uie1, uie2) => uie1.position - uie2.position);
    this.popupElements = ModelHelper.cloneUiElementArray(image.popupElements).sort((uie1, uie2) => uie1.position - uie2.position);
    this.cardsActive = image.cardsActive;
    this.contentType = image.contentType;
    this.width = image.width;
    this.height = image.height;
    this.card = new Card(image.card);
    this.url = new MultiLanguageText(image.url);
  }

  public equal(image: Image): boolean {
    if (
      this.mediaId !== image.mediaId ||
      this.width !== image.width ||
      this.height !== image.height ||
      !this.card.equal(image.card) ||
      !this.url.equal(image.url) ||
      this.cardsActive !== image.cardsActive ||
      this.contentType !== image.contentType
    ) {
      return false;
    }

    if(!ModelHelper.arrayEqual(this.uiElements, image.uiElements)) { return false; }
    if(!ModelHelper.arrayEqual(this.popupElements, image.popupElements)) { return false; }
    return super.equal(image);
  }
}
