import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap, share } from 'rxjs/operators';

import { Config } from '../Config';

import { AlertService } from './alert.service';
import { Exhibition } from '../models/Exhibition';
import { StringService } from './string.service';
import { AlertType, AlertSimple, AlertWithBoldValueInMiddle, Alert, AlertWithUrl } from '../models/Alert';
import { ExhibitionThumbnail } from '../models/ExhibitionThumbnails';

/**
 * basic HttpHeaders that will be used with every request
 */
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'response' as 'body'
};

/**
 * The ExhibitionService handles all requests that will get or change the exhibitions in the backend
 * It communicates over the /exhibition endpoint of the backend
 */
@Injectable({
  providedIn: 'root',
})
export class ExhibitionService {

  /**
	 * contains the baseUrl to the Backend
	 */
  private baseUrl = new Config().GetBaseUrl();

  /**
	 * Constructor
	 * Initializes the ExhibitionService.
	 * @param http is used to send Request to the backend and to process Responses from the backend.
	 * @param alertService is used to display information for the user.
	 * @param stringService the StringService is used to get the displayed strings in the correct languages.
	 */
  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService
  ) { }

  /**
	 * GET
	 * receives all Exhibitions stored in the database
	 * @returns an Array of all received Exhibitions, or an error if the request was not successful
	 */
  public getExhibitions(): Observable<Exhibition[]> {
    return this.http.get<Exhibition[]>(this.getExhibitionUrl()).pipe(
      tap(
        response => { },
        error => { this.handleError<Exhibition[]>('getExhibitions', error); }
      ),
      share()
    );
  }

  /**
	 * GET
	 * receives a structure containing all thumbnails for the exhibition with the given id.
	 * @returns structure containing thumbnails for images and cuboids contained in the exhibition with the given id.
	 */
  public getExhibitionThumbnails(id: string): Observable<ExhibitionThumbnail[]> {
    return this.http.get<ExhibitionThumbnail[]>(this.getExhibitionUrl(`${id}`) + 'thumbs').pipe(
      tap(
        response => { },
        error => {
          this.handleError<Exhibition>('getExhibitionThumbnails', error);
          if (error.status !== 500) {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('EXHIBITION_NOT_FOUND1'),
                id,
                this.stringService.get('EXHIBITION_NOT_FOUND2'),
                AlertType.Error),
              true);
          }
        }
      ),
      share()
    );
  }

  /**
	 * GET
	 * receives a single Exhibitions with the given id,
	 * if it exists in the database
	 * @param id the id of the requested exhibition
	 * @returns the given exhibition or and error if the exhibition does not exist in the database,
   *  or an error if the request was not successful
	 */
  public getExhibitionById(id: string): Observable<Exhibition> {
    return this.http.get<Exhibition>(this.getExhibitionUrl(`${id}`)).pipe(
      tap(
        response => { },
        error => {
          this.handleError<Exhibition>('getExhibitionById', error);
          if (error.status !== 500) {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('EXHIBITION_NOT_FOUND1'),
                id,
                this.stringService.get('EXHIBITION_NOT_FOUND2'),
                AlertType.Error),
              true);
          }
        }
      ),
      share()
    );
  }

  /**
	 * POST
	 * creates a new Exhibition with the given data in the database
	 * @param exhibition the Exhibitiondata to be created
	 * @returns the new created Exhibiton with the id from the backend or and error if the request was not successful
	 */
  public createExhibition(exhibition: Exhibition): Observable<any> {
    exhibition.createdTime = new Date().getTime();
    exhibition.updatedTime = exhibition.createdTime;
    return this.http.post<any>(this.getExhibitionUrl(), exhibition, httpOptions).pipe(
      tap(
        response => {
          const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
          const id = location.substring(location.lastIndexOf('/') + 1);
          this.alertService.alert(
            new AlertWithBoldValueInMiddle(
              this.stringService.get('EXHIBITION_CREATED1'),
              id,
              this.stringService.get('EXHIBITION_CREATED2'),
              AlertType.Success),
            true);
        },
        error => { this.handleError<Exhibition>('createExhibition', error); }
      ),
      share()
    );
  }

  /**
	 * POST
	 * updates the given Exhibition on mobile devices
	 * @param exhibition the Exhibition that should be updated on all mobile devices
	 * @returns nothing if the request was successful, else it returns an error
	 */
  public applyChanges(exhibition: Exhibition): Observable<any> {
    return this.http.post(this.getExhibitionUrl(`${exhibition.id}/apply`), httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(
            new AlertSimple(
              this.stringService.get(exhibition.active ? 'EXHIBITION_PUBLISHED' : 'EXHIBITION_PUBLISHED_BUT_INACTIVE'),
              exhibition.active ? AlertType.Success : AlertType.Warning),
            true);
        },
        error => {
          this.handleError<Exhibition>('applyChanges', error);
        }
      ),
      share()
    );
  }

  public publishExhibition(exhibition: Exhibition): Observable<any> {
    return this.http.post(this.getExhibitionUrl(`${exhibition.id}/publish`), httpOptions).pipe(
      tap(
        response => {
          this.alertService.alert(
            new AlertSimple(
              this.stringService.get(exhibition.active ? 'EXHIBITION_PUBLISHED' : 'EXHIBITION_PUBLISHED_BUT_INACTIVE'),
              exhibition.active ? AlertType.Success : AlertType.Warning),
            true);
        },
        error => {
          this.handleError<Exhibition>('publishExhibition', error);
        }
      ),
      share()
    );
  }

  /**
	 * PUT
	 * updates an existing Exhibition in the database
	 * @param exhibition the exhibition that should be updated
	 * @returns nothing if the request was successful, else it returns an error
	 */
  public updateExhibition(exhibition: Exhibition): Observable<any> {
    exhibition.updatedTime = new Date().getTime();
    return this.http.put(this.getExhibitionUrl(`${exhibition.id}`), exhibition, httpOptions).pipe(
      tap(
        response => {
          if (exhibition.title.de !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('EXHIBITION_UPDATED1'),
                exhibition.title.de,
                this.stringService.get('EXHIBITION_UPDATED3'),
                AlertType.Success),
              true);
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('EXHIBITION_UPDATED2'),
                exhibition.id,
                this.stringService.get('EXHIBITION_UPDATED3'),
                AlertType.Success),
              true);
          }
        },
        error => { this.handleError<Exhibition>('updateExhibition', error); }
      ),
      share()
    );
  }

  /**
	 * PUT
	 * updates the given Exhibition in the database
	 * @param exhibition the exhibition that should be updated
	 * @returns nothing if the request was successful, else it returns an error
	 */
  public updateExhibitionActivation(exhibition: Exhibition): Observable<any> {
    exhibition.updatedTime = new Date().getTime();
    return this.http.put(this.getExhibitionUrl(`${exhibition.id}`), exhibition, httpOptions).pipe(
      tap(
        response => {
          if (exhibition.active) {
            this.alertService.alert(
              new AlertSimple(
                this.stringService.get('EXHIBITION_ACTIVATED'),
                AlertType.Success),
              true);
          } else {
            this.alertService.alert(
              new AlertSimple(
                this.stringService.get('EXHIBITION_DEACTIVATED'),
                AlertType.Info),
              true);
          }
        },
        error => { this.handleError<Exhibition>('updateExhibition', error); }
      ),
      share()
    );
  }

  /**
	 * DELETE
	 * deletes the given exhibition from the database
	 * @param exhibition the exhibition object, or the exhibitionId as string
	 * @returns nothing if the request was successful, else it returns an error
	 */
  public deleteExhibition(exhibition: Exhibition | string): Observable<any> {
    const id = typeof exhibition === 'string' ? exhibition : exhibition.id;
    return this.http.delete(this.getExhibitionUrl(`${id}`), httpOptions).pipe(
      tap(
        response => {
          if (typeof exhibition === 'object' && exhibition.title.de !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('EXHIBITION_DELETED1'),
                exhibition.title.de,
                this.stringService.get('EXHIBITION_DELETED3'),
                AlertType.Info),
              true);
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('EXHIBITION_DELETED2'),
                id,
                this.stringService.get('EXHIBITION_DELETED3'),
                AlertType.Info),
              true);
          }
        },
        error => { this.handleError<Exhibition>('deleteExhibition', error); }
      ),
      share()
    );
  }

  /**
	* Handle Http operation that failed.
	* Let the app continue.
	* @param operation - name of the operation that failed
	* @param result - optional value to return as the observable result
	* @returns an errorObject or an Object of the given resultType
	*/
  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    if (error.status === 409) {
      this.alertService.alert(
        new AlertSimple(
          this.stringService.get('ERROR_EXHIBITION_PUBLISH_CONFLICT'),
          AlertType.Error),
        true);
    }
    if (error.status === 412) {
      let trackerstr = '';
      error.error.affected.forEach((tracker, index) => {
        trackerstr += tracker.title.de + (index < error.error.affected.length - 1 ? ', ' : '');
      });

      let alert;
      switch(error.error.error) { // yes, seriously
        case 'missing-coordinates':
          alert = new AlertSimple(
            this.stringService.get('ERROR_EXHIBITION_PUBLISH_PLACE_MISSING_COORDINATES') + ' ' + trackerstr, AlertType.Error
          );
          break;
        case 'invalid-size':
          alert = new AlertSimple(
            this.stringService.get('ERROR_EXHIBITION_PUBLISH_IMAGE_MISSING_SCALE') + ' ' + trackerstr, AlertType.Error
          );
          break;
        default:
          alert = new AlertSimple(
            this.stringService.get('ERROR_EXHIBITION_PUBLISH_GENERIC_PRECONDITION_FALURE') + ' ' + trackerstr, AlertType.Error
          );
          break;
      }

      this.alertService.alert(alert, true);
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  /**
	 * is called when an httpError occures
	 * logs the value as an error in the console
	 * @param value the value that should be logged
	 */
  private logError(value: any): void {
    console.error(value);
  }

  /**
	 * returns the full path to the exhibition endpoint and adds possible extensions to the path
	 * @param extension the extension to be added
	 * @returns the new created url
	 */
  private getExhibitionUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}exhibitions/`;
    if (extension) {
      if (!extension.endsWith('/')) {
        extension = `${extension}/`;
      }
      url = `${url}${extension}`;
    }
    return url;
  }
}
