import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { User } from '../models/User';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { CurrentContextService } from '../services/currentContext.service';
import * as L from 'leaflet';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppState } from '../current.app.state';
import { Config } from '../Config';
import { AlertService } from '../services/alert.service';
import { AlertSimple, AlertType } from '../models/Alert';
import { StringService } from '../services/string.service';
import { TaskService } from '../services/task.service';
import { environment } from '../../environments/environment';
import { UserConfig } from '../models/UserConfig';
import { UserConfigService } from '../services/userConfig.service';
import { MediaService } from '../services/media.service';
import { MediaLoader } from '../../helper-classes/MediaLoader';
import { DomSanitizer } from '@angular/platform-browser';
import { ManageMedia2Adapters } from '../controls/manage-media2/manage-media2.component';
import { ElementType } from '../models/UiElements';
import { Observable, Subscription } from 'rxjs';


/**
 * AdminComponent for creating and updating user informations
 */
@Component({
	selector: 'admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

	/**
	   * Users privileges. This is shown as checkboxes.
	   */
	public roles: string[] = ['user', 'admin'];

	/**
	   * List of all users.
	   */
	public users: User[];

	/**
	   * The user object that is currently being edited / created.
	   */
	public user: User;

	/**
	   * boolean variable: Indicates whether a user is being edited or created.
	   */
	public editMode = false;

	/**
	   * The configuration.
	   * (Mainly used for the tooltip delay.)
	   */
	public config: Config = new Config();

	/**
	   * Reference of the modal
	   */
	public modalRef: BsModalRef;

	public ElementType = ElementType;
	public ManageMedia2Adapters = ManageMedia2Adapters;
	public mediaLoader: MediaLoader;

	private originalUserConfig: UserConfig;
	public userConfig: UserConfig;
	private offlineMapBoundary: EditRect;
	private map: any = null;

	private saveContentSubject: Subscription;

	/**
	   * Constructor
	   * Initializes the user and calls the method getUsers.
	   *
	   * @param userService the user service for getting, creating and updating users.
	   * @param authService the authentication service to get the current logged in user.
	   * @param currentContextService the current context service to set the app state.
	   * @param modalService a modal service to open modals.
	   */
	public constructor(
		private userService: UserService,
		private authService: AuthService,
		private currentContextService: CurrentContextService,
		private modalService: BsModalService,
		private alertService: AlertService,
		private stringService: StringService,
		private mediaService: MediaService,
		private sanitizer: DomSanitizer,
		public taskService: TaskService,
		public userConfigService: UserConfigService
	) {
		this.mediaLoader = new MediaLoader(this.mediaService, this.sanitizer);
		this.user = new User();
		this.user.privileges.push('user');
		this.getUsers();
	}

	/**
	   * Is called after Angular has initialized all data-bound properties of a directive.
	   * Sets the current app state to 'admin'.
	   */
	public ngOnInit(): void {
		this.saveContentSubject = this.currentContextService.saveYourContent.subscribe(
			saveContent => {
				if (saveContent) {
					this.saveConfig();
				}
			}
		);
		this.currentContextService.setCurrentState(AppState.admin);
		this.getUserConfig();
	}

	/**
	   * Calls the getUsers method of the user service to get all existing users.
	   */
	private getUsers(): void {
		const sub = this.userService.getUsers().subscribe(
			users => {
				this.users = users;
				sub.unsubscribe();
			}
		);
	}

	private getUserConfig() {
		this.userConfigService.getConfig().then((userConfig) => {
			this.originalUserConfig = new UserConfig(userConfig);
			this.userConfig = new UserConfig(this.originalUserConfig);
			this.initMap();
		});
	}

	public hasUserConfigChanged(): boolean {
		return !this.userConfig?.equal(this.originalUserConfig);
	}

	@HostListener('window:beforeunload')
	canDeactivate(): Observable<boolean> | boolean {
	  return !this.hasUserConfigChanged();
	}

	/**
	   * Sets editMode to true.
	   * Sets this user to the given user.
	   * @param user the user object to be edited
	   */
	public editUser(user: User): void {
		this.editMode = true;
		this.user = user;
	}

	/**
	   * Calls the createUser method of the user service to create this user on the server.
	   */
	public createUser(): void {
		if (this.user.privileges.length > 0) {
			const sub = this.userService.createUser(this.user).subscribe(
				response => {
					this.cancel();
					sub.unsubscribe();
				}
			);
		} else {
			this.alertService.alert(new AlertSimple(this.stringService.get('MISSING_USER_ROLES'), AlertType.Error));
		}
	}

	/**
	   * Calls the updateUser method of the user service to update this user on the server.
	   */
	public updateUser(): void {
		if (this.user.privileges.length > 0) {
			const sub = this.userService.updateUser(this.user).subscribe(
				response => {
					this.cancel();
					sub.unsubscribe();
				}
			);
		} else {
			this.alertService.alert(new AlertSimple(this.stringService.get('MISSING_USER_ROLES'), AlertType.Error));
		}
	}

	/**
	   * Calls deleteUser method of the user service to delete this user on the server.
	   */
	public deleteUser(): void {
		const sub = this.userService.deleteUser(this.user).subscribe(
			response => {
				this.cancel();
				sub.unsubscribe();
			}
		);
	}

	/**
	   * Checks if the given user is the current logged on user.
	   * Prevents you from being able to delete yourself.
	   * @param user the user object to be checked.
	   * @returns true if delete is disabled.
	   */
	public isLoggedInUser(user: User): boolean {
		return this.authService.getUser().id === user.id
	}

	/**
	   * Closes the modal.
	   * Calls the getUsers function to reload all users.
	   * Sets the editMode to false.
	   * Sets a new user as this user.
	   */
	private cancel(): void {
		if (this.modalRef) { this.modalRef.hide(); }
		this.getUsers();
		this.editMode = false;
		this.user = new User();
	}

	/**
	   * Checks or unchecks a privileges checkbox.
	   * @param role the role to be selected.
	   */
	public check(role: string): void {
		if (this.user.privileges.includes(role)) {
			this.user.privileges = this.user.privileges.filter(element => element !== role);
			return;
		}
		if (role === 'admin' && this.user.privileges.includes('user')) {
			this.user.privileges = this.user.privileges.filter(element => element !== 'user');
		} else if (role === 'user' && this.user.privileges.includes('admin')) {
			this.user.privileges = this.user.privileges.filter(element => element !== 'admin');
		}
		this.user.privileges.push(role);
	}

	/**
	   * Checks if the given role is selected.
	   * @param role the role to be checked.
	   * @returns true if the given role is selected.
	   */
	public isChecked(role: string): boolean {
		return this.user.privileges.includes(role);
	}

	/**
	   * Sets the given user to this user.
	   * Opens the given modal.
	   * @param modalTemplate the modal template to be opened.
	   * @param user the user object to be set.
	   */
	public openModal(modalTemplate: TemplateRef<any>, user?: User): void {
		if(user) {
			this.user = user;
		}
		this.modalRef = this.modalService.show(modalTemplate);
	}

	public trackByFn(index, item) {
		return item.id;
	}

	public showTasksButtons(): boolean {
		return environment.environmentName === 'DEMO' || environment.environmentName === 'DEV';
	}

	public userConfigChanged() : boolean {
		return !this.originalUserConfig?.equal(this.userConfig);
	}

	public saveConfig() {
		this.currentContextService.setSavingContent(true);
		const sub = this.userConfigService.setConfig(this.userConfig).subscribe(
			config => {
				this.currentContextService.setSavingContent(false);
				this.originalUserConfig = new UserConfig(config);
				this.userConfig = new UserConfig(config);
				this.modalRef?.hide();
				sub.unsubscribe();
			},
			_ => {
				this.currentContextService.setSavingContent(false);
				this.modalRef?.hide();
			}
		);
	}

	public discardConfig() {
		this.userConfig = new UserConfig(this.originalUserConfig);
		this.initMap(); // reinit
		this.modalRef.hide();
	}

	private initMap() {
		if(this.map != null) {
			this.map.remove(); // remove before reinitializing
		}
		this.map = L.map('map', {center:[1, 1], zoom: 1, doubleClickZoom: false});
		const tiles = L.tileLayer(new Config().getMapUrl(), {
			tileSize: 256,
			minZoom: 1,
			attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
			crossOrigin: true
		});
		tiles.addTo(this.map);

		const mapRectangle = L.rectangle([[0, 0], [0, 0]], {color: '#ff0000', weight: 1, bubblingMouseEvents: false, interactive: true, className: 'regionRect'})
			.on('mouseover', () => this.offlineMapBoundary.setContainsMouse(true))
			.on('mouseout', () => this.offlineMapBoundary.setContainsMouse(false))
			.on('mousedown', (evt) => {
				if(evt.originalEvent.button === 0) {
					this.map.dragging.disable();
					this.offlineMapBoundary.startDrag(evt.containerPoint);
				}
			})
			.addTo(this.map);

		this.offlineMapBoundary = new EditRect(this.map, mapRectangle, (bounds) => {
			this.userConfig.mapBoundaries[0].lat = bounds[0].lat;  // [northEast, southWest]
			this.userConfig.mapBoundaries[0].lon = bounds[0].lng;
			this.userConfig.mapBoundaries[1].lat = bounds[1].lat;
			this.userConfig.mapBoundaries[1].lon = bounds[1].lng;
		});
		const initBounds = this.userConfig.mapBoundaries; // [northEast, southWest]
		this.offlineMapBoundary.setBounds([new L.LatLng(initBounds[0].lat, initBounds[0].lon), new L.LatLng(initBounds[1].lat, initBounds[1].lon)]);

		this.map.on('mousemove', (evt) => {
			if(this.offlineMapBoundary.isDragRunning()) {
				this.offlineMapBoundary.move(evt.containerPoint);
			} else {
				this.offlineMapBoundary.update();
				let regionRect = this.map.getContainer().querySelector('.regionRect');
				if(this.offlineMapBoundary.getContainsMouse()) {
					let cursor = this.offlineMapBoundary.getEditingCursor(evt.containerPoint);
					regionRect.style.cursor = cursor;
				} else {
					regionRect.style.cursor = '';
				}
			}
		})
		.on('mouseup', (evt) => {
			if(this.offlineMapBoundary.isDragRunning()) {
				this.map.dragging.enable();
				this.offlineMapBoundary.stopDrag();
			}
		});
		L.control.scale().addTo(this.map);
	}
}


enum EditRectEdge {
	NORTH = 0, SOUTH = 1, EAST = 2, WEST = 3
}
class EditRect {
	private bounds;
	private boundsPxl;
	private containsMouse: boolean = false;
	private dragRunning: boolean = false;
	private lastMousePos: {x: number, y: number} = null;
	private movedEdges: number[] = [];
	public constructor(private map, private rect, private boundsUpdatedFn: (bounds: [any, any]) => void) {
		this.update();
	}
	public update() {
		this.bounds = this.rect.getBounds();
		this.boundsPxl = [
			this.map.latLngToContainerPoint(this.bounds.getNorthEast()),
			this.map.latLngToContainerPoint(this.bounds.getSouthWest())
		];
	}
	public setBounds(bounds: [L.LatLng, L.LatLng]) {
		this.rect.setBounds(bounds);
		this.map.fitBounds(bounds);
	}
	public setContainsMouse(contains: boolean) { this.containsMouse = contains; }
	public getContainsMouse() : boolean { return this.containsMouse; }
	public getEditingEdges(mousePos: {x: number, y: number}) : number[] {
		const PXL_THRESHOLD = 10;
		let result = [];
		if(Math.abs(this.boundsPxl[0].y - mousePos.y) < PXL_THRESHOLD) { result.push(EditRectEdge.NORTH); }
		if(Math.abs(this.boundsPxl[1].y - mousePos.y) < PXL_THRESHOLD) { result.push(EditRectEdge.SOUTH); }
		if(Math.abs(this.boundsPxl[0].x - mousePos.x) < PXL_THRESHOLD) { result.push(EditRectEdge.EAST); }
		if(Math.abs(this.boundsPxl[1].x - mousePos.x) < PXL_THRESHOLD) { result.push(EditRectEdge.WEST); }
		return result;
	}
	public getEditingCursor(mousePos: {x: number, y: number}) : string {
		let edges = this.getEditingEdges(mousePos);
		if(edges.length == 0) { return 'move'; }
		if(edges.length == 1) {
			if(edges[0] == EditRectEdge.NORTH || edges[0] == EditRectEdge.SOUTH) { return 'ns-resize'; }
			if(edges[0] == EditRectEdge.EAST || edges[0] == EditRectEdge.WEST) { return 'ew-resize'; }
		} else {
			if(edges[0] == EditRectEdge.SOUTH) {
				if(edges[1] == EditRectEdge.EAST) { return 'nwse-resize'; }
				if(edges[1] == EditRectEdge.WEST) { return 'nesw-resize'; }
			} else if(edges[0] == EditRectEdge.NORTH) {
				if(edges[1] == EditRectEdge.EAST) { return 'nesw-resize'; }
				if(edges[1] == EditRectEdge.WEST) { return 'nwse-resize'; }
			}
		}
		return null;
	}
	public isDragRunning() : boolean {
		return this.dragRunning;
	}
	public startDrag(mousePos: {x: number, y: number}) {
		this.update();
		this.dragRunning = true;
		this.movedEdges = this.getEditingEdges(mousePos);
		this.lastMousePos = mousePos;
	}
	public move(mousePos: {x: number, y: number}) {
		this.update();
		let northEast = this.bounds.getNorthEast();
		let southWest = this.bounds.getSouthWest();
		let mapPos = this.map.containerPointToLatLng(mousePos);
		if(this.movedEdges.length == 0) { // moving
			let lastMapPos = this.map.containerPointToLatLng(this.lastMousePos);
			// apply delta to both bounds
			northEast.lat += (mapPos.lat - lastMapPos.lat);
			southWest.lat += (mapPos.lat - lastMapPos.lat);
			northEast.lng += (mapPos.lng - lastMapPos.lng);
			southWest.lng += (mapPos.lng - lastMapPos.lng);
			this.lastMousePos = mousePos;
		} else { // resizing
			if(this.movedEdges.includes(EditRectEdge.NORTH)) {
				northEast.lat = Math.max(mapPos.lat, southWest.lat);
			} else if(this.movedEdges.includes(EditRectEdge.SOUTH)) {
				southWest.lat = Math.min(mapPos.lat, northEast.lat);
			}
			if(this.movedEdges.includes(EditRectEdge.EAST)) {
				northEast.lng = Math.max(mapPos.lng, southWest.lng);
			} else if(this.movedEdges.includes(EditRectEdge.WEST)) {
				southWest.lng = Math.min(mapPos.lng, northEast.lng);
			}
		}
		this.boundsUpdatedFn([northEast, southWest]);
		this.rect.setBounds([northEast, southWest]);
	}
	public stopDrag() {
		this.update();
		this.dragRunning = false;
		// ensure region has a minimum size of 20px at the current zoom level
		// anything else doesn't make sense, it won't be grabbable anymore!
		let widthPxl = (this.boundsPxl[0].x - this.boundsPxl[1].x);
		let heightPxl = (this.boundsPxl[1].y - this.boundsPxl[0].y);
		if(widthPxl < 20) { this.boundsPxl[0].x += (20 - widthPxl); }
		if(heightPxl < 20) { this.boundsPxl[1].y += (20 - heightPxl); }
		this.bounds = [
			this.map.containerPointToLatLng(this.boundsPxl[0]),
			this.map.containerPointToLatLng(this.boundsPxl[1]),
		];
		this.boundsUpdatedFn(this.bounds);
		this.rect.setBounds(this.bounds);
	}
}