import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap, share } from 'rxjs/operators';

import { Config } from '../Config';

import { AlertService } from './alert.service';
import { StringService } from './string.service';
import { AlertType, AlertWithBoldValueInMiddle, AlertSimple } from '../models/Alert';
import { CurrentContextService } from './currentContext.service';
import { Cuboid } from '../models/Cuboid';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root',
})
export class CuboidService {

  private baseUrl = new Config().GetBaseUrl();

  public constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private stringService: StringService,
    private contextService: CurrentContextService
  ) { }

  public getCuboids(exhibitionId?: string): Observable<Cuboid[]> {
    let extension = '';
    if (exhibitionId && exhibitionId !== '') {
      extension = `?exhibitionIds=${exhibitionId}`;
    }
    return this.http.get<Cuboid[]>(this.getCuboidUrl() + extension).pipe(
      tap(
        response => { },
        error => { this.handleError<Cuboid[]>('getCuboids', error); }
      ),
      share()
    );
  }

  public getCuboidById(id: string): Observable<Cuboid> {
    return this.http.get<Cuboid>(this.getCuboidUrl(`${id}`)).pipe(
      tap(
        response => { },
        error => {
          this.handleError<Cuboid>('getCuboidById', error);
          this.alertService.alert(
            new AlertWithBoldValueInMiddle(
              this.stringService.get('CUBOID_NOT_FOUND1'),
              id,
              this.stringService.get('CUBOID_NOT_FOUND2'),
              AlertType.Error),
            true);
        }
      ),
      share()
    );
  }

  public createCuboid(cuboid: Cuboid): Observable<any> {
    cuboid.createdTime = new Date().getTime();
    cuboid.updatedTime = cuboid.createdTime;
    return this.http.post<any>(this.getCuboidUrl(), cuboid, httpOptions).pipe(
      tap(
        response => {
          if (this.contextService.getInCurrentAppLanguage(cuboid.title) !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('CUBOID_CREATED1'),
                this.contextService.getInCurrentAppLanguage(cuboid.title),
                this.stringService.get('CUBOID_CREATED3'),
                AlertType.Success),
              true);
          } else {
            const location = response.headers.get('Location') ? response.headers.get('Location') : response.headers.get('location');
            const id = location.substring(location.lastIndexOf('/') + 1);
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('CUBOID_CREATED2'),
                id,
                this.stringService.get('CUBOID_CREATED3'),
                AlertType.Success),
              true);
          }
        },
        error => { this.handleError<Cuboid>('createCuboid', error); }
      ),
      share()
    );
  }

  public updateCuboid(cuboid: Cuboid): Observable<any> {
    cuboid.updatedTime = new Date().getTime();
    return this.http.put(this.getCuboidUrl(`${cuboid.id}`), cuboid, httpOptions).pipe(
      tap(
        response => {
          if (this.contextService.getInCurrentAppLanguage(cuboid.title) !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('CUBOID_UPDATED1'),
                this.contextService.getInCurrentAppLanguage(cuboid.title),
                this.stringService.get('CUBOID_UPDATED3'),
                AlertType.Success),
              true);
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('CUBOID_UPDATED2'),
                cuboid.id,
                this.stringService.get('CUBOID_UPDATED3'),
                AlertType.Success),
              true);
          }
        },
        error => { this.handleError<Cuboid>('updateCuboid', error); }
      ),
      share()
    );
  }

  public updateCuboidActivation(cuboid: Cuboid): Observable<any> {
    cuboid.updatedTime = new Date().getTime();
    return this.http.put(this.getCuboidUrl(`${cuboid.id}`), cuboid, httpOptions).pipe(
      tap(
        response => {
          if (cuboid.active) {
            this.alertService.alert(
              new AlertSimple(
                this.stringService.get('CUBOID_ACTIVATED'),
                AlertType.Success),
              true);
          } else {
            this.alertService.alert(
              new AlertSimple(
                this.stringService.get('CUBOID_DEACTIVATED'),
                AlertType.Info),
              true);
          }
        },
        error => { this.handleError<Cuboid>('updateCuboidActivation', error); }
      ),
      share()
    );
  }

  public deleteCuboid(cuboid: Cuboid | string): Observable<any> {
    const id = typeof cuboid === 'string' ? cuboid : cuboid.id;
    return this.http.delete(this.getCuboidUrl(`${id}`), httpOptions).pipe(
      tap(
        response => {
          if (typeof cuboid === 'object' && this.contextService.getInCurrentAppLanguage(cuboid.title) !== '') {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('CUBOID_DELETED1'),
                this.contextService.getInCurrentAppLanguage(cuboid.title),
                this.stringService.get('CUBOID_DELETED3'),
                AlertType.Info),
              true);
          } else {
            this.alertService.alert(
              new AlertWithBoldValueInMiddle(
                this.stringService.get('CUBOID_DELETED2'),
                id,
                this.stringService.get('CUBOID_DELETED3'),
                AlertType.Info),
              true);
          }
        },
        error => { this.handleError<Cuboid>('deleteCuboid', error); }
      ),
      share()
    );
  }

  /**
	* Handle Http operation that failed.
	* Let the app continue.
	* @param operation - name of the operation that failed
	* @param result - optional value to return as the observable result
	* @returns an errorObject or an Object of the given resultType
	*/
  private handleError<T>(operation = 'operation', error: HttpErrorResponse, result?: T): any {
    if (error.status === 0 || (error.status >= 500 && error.status < 600)) {
      this.alertService.serverError();
    }
    return (error: any): Observable<T> => {
      this.logError(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  /**
	 * triggers when a httpResponse error occurs
	 * will print the value to the console
	 * @param value value to be printed
	 */
  private logError(value: any): void {
    console.error(value);
  }

  /**
	 * returns the url to the Cuboid endpoint of the backend
	 * @param extension optional extension that could be added to the url
	 * @returns the created url to the Cuboid endpoint
	 */
  private getCuboidUrl(extension?: string): string {
    let url = this.baseUrl;
    if (!url.endsWith('/')) {
      url = `${url}/`;
    }
    url = `${url}cuboids/`;
    if (extension) {
      if (!extension.endsWith('/')) {
        extension = `${extension}/`;
      }
      url = `${url}${extension}`;
    }
    return url;
  }
}
