import { CuboidMediaIds } from './CuboidMediaIds';
import { Tracker } from './Tracker';
import { ModelHelper } from './ModelHelper';
import { UiElement } from './UiElements';

export interface ICuboidSize {
  width: number;
  height: number;
  depth: number;
}

export class Cuboid extends Tracker implements ICuboidSize {

  public trackerIds: CuboidMediaIds = new CuboidMediaIds();
  public popupElements: UiElement[] = [];

  public width: number = 0;
  public height: number = 0;
  public depth: number = 0;

  constructor(cuboid?: Cuboid) {
    super(cuboid);
    if (!cuboid) { return; }
    this.trackerIds = new CuboidMediaIds(cuboid.trackerIds);
    this.popupElements = ModelHelper.cloneUiElementArray(cuboid.popupElements).sort((uie1, uie2) => uie1.position - uie2.position);
    this.width = cuboid.width;
    this.height = cuboid.height;
    this.depth = cuboid.depth;
  }

  public equal(cuboid: Cuboid): boolean {
    if (
      this.width !== cuboid.width ||
      this.height !== cuboid.height ||
      this.depth !== cuboid.depth ||
      !this.trackerIds.equal(cuboid.trackerIds)
    ) {
      return false;
    }

    if(!ModelHelper.arrayEqual(this.popupElements, cuboid.popupElements)) { return false; }

    return super.equal(cuboid);
  }
}
