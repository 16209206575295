<div *ngIf="this.cuboid" class="edit-cuboid-meta-main-content">
  <div class="edit-cuboid-meta-left-side">
    <aacms-cuboid-viewer #cuboidViewer class="cuboid-viewer" [cuboid]="this.cuboid" [(imageDataMap)]="this.imageDataMap" [currentLanguage]="this.currentLanguage"
      (imageDataMapChanged)="this.checkForContentChange()">
    </aacms-cuboid-viewer>
  </div>
  <div class="edit-cuboid-meta-right-side">
    <div class="header">
      <div class="path-title-container">
        <div class="path">
          <div class="path-option" (click)="this.routeTo(appState.exhibitions)">{{'EXHIBITIONS' | translate}}</div>
          /
          <div class="path-option" (click)="this.routeTo(appState.images)">{{ Tools.shortenString(contextService.currentExhibition && contextService.currentExhibition.title && getLanguageValue(contextService.currentExhibition.title).length > 0 ? getLanguageValue(contextService.currentExhibition.title) : ('TRACKERS' | translate)) }}</div>
          /
          <div class="path-option" (click)="this.routeTo(appState.editCuboid)">{{ Tools.shortenString(cuboid && getLanguageValue(cuboid.title).length > 0 ? getLanguageValue(cuboid.title) : ('CUBOID' | translate)) }}</div>
          /
          <div class="last-path-option" >{{'CUBOID_META' | translate }}</div>
        </div>
        <div class="cuboid-title-edit-container">
          <h1 class="edit-indicator">
            <i class="fa fa-pencil" aria-hidden="true"
              [ngStyle]="{'color': contentChanged || pms.poisChanged || this.cuboidImageChanged ? '#ffc107' : 'black' }"></i>
          </h1>
          <div class="cuboid-title-container">
            <h1 class="cuboid-title" *ngIf="this.getLanguageValue(this.cuboid.title).length > 0">
              {{this.getLanguageValue(this.cuboid.title)}}
            </h1>
            <h1 class="cuboid-title" *ngIf="this.getLanguageValue(this.cuboid.title).length == 0">
              <i>{{'UNNAMED_CUBOID' | translate }}</i>
            </h1>
          </div>
        </div>
      </div>
      <aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
        (languageChanged)="this.updateLanguage($event)">
      </aacms-language-selector>
    </div>

    <tabset [justified]="false">
      <tab heading="{{'CUBOID_GENERAL_TAB' | translate}}" class="cuboid-general-tab tab">
        <div class="cuboid-title-input">
          <div>{{'TITLE' | translate }}</div>
          <div *ngIf="this.isLanguage(Language.DE)" class="custom-input-group input-group">
            <input class="form-control textInput" [(ngModel)]="this.cuboid.title.de" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
              (ngModelChange)="checkForContentChange()" />
            <div class="languageIndicator">
              <img class="language-button-image" src="../assets/flags/de.svg">
            </div>
            <div class="align-self-center px-2">
              {{this.config.getMaxTitleTextLength() - this.cuboid.title.de.length}}
            </div>
          </div>
          <div *ngIf="this.isLanguage(Language.GB)" class="custom-input-group input-group">
            <input class="form-control textInput" [(ngModel)]="this.cuboid.title.gb" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
              (ngModelChange)="checkForContentChange()" />
            <div class="languageIndicator">
              <img class="language-button-image" src="../assets/flags/gb.svg">
            </div>
            <div class="align-self-center px-2">
              {{this.config.getMaxTitleTextLength() - this.cuboid.title.gb.length}}
            </div>
          </div>
          <div *ngIf="this.isLanguage(Language.JP)" class="custom-input-group input-group">
            <input class="form-control textInput" [(ngModel)]="this.cuboid.title.jp" maxlength="{{this.config.getMaxExhibitionTitleLength()}}"
              (ngModelChange)="checkForContentChange()" />
            <div class="languageIndicator">
              <img class="language-button-image" src="../assets/flags/jp.svg">
            </div>
            <div class="align-self-center px-2">
              {{this.config.getMaxTitleTextLength() - this.cuboid.title.jp.length}}
            </div>
          </div>
        </div>
        <div class="checkbox-container">
          <label class="checkbox">
            <input type="checkbox" (click)="this.toggleCuboidActivation()" [checked]="this.cuboid.active">
            <span class="checkmark" triggers="hover"></span>
            <div class="checkbox-text">
              {{ 'CUBOID_ACTIVE_INACTIVE' | translate }}
            </div>
          </label>
        </div>
        <div> {{ 'CUBOID_DIMENSIONS' | translate }} </div>
        <div class="cuboid-dimensions">
          <div class="size-input-group">
            <div>{{ 'WIDTH' | translate}}</div>
            <input #widthInput class="form-control .form-control-sm" [(ngModel)]="this.width" [style.border]="this.cuboid.width === 0 ? '1px solid red' : ''" [style.box-shadow]="this.cuboid.width === 0 ? '0 0 0 .2rem rgba(255,0,0,.25)' : ''"
              (ngModelChange)="this.cuboidWidthChanged($event, widthInput)">
          </div>
          <div class="size-input-group">
            <div>{{ 'HEIGHT' | translate}}</div>
            <input #heightInput class="form-control .form-control-sm" [(ngModel)]="this.height" [style.border]="this.cuboid.height === 0 ? '1px solid red' : ''" [style.box-shadow]="this.cuboid.height === 0 ? '0 0 0 .2rem rgba(255,0,0,.25)' : ''"
              (ngModelChange)="this.cuboidHeightChanged($event, heightInput)">
          </div>
          <div class="size-input-group">
            <div>{{ 'DEPTH' | translate}}</div>
            <input #depthInput class="form-control .form-control-sm" [(ngModel)]="this.depth" [style.border]="this.cuboid.depth === 0 ? '1px solid red' : ''" [style.box-shadow]="this.cuboid.depth === 0 ? '0 0 0 .2rem rgba(255,0,0,.25)' : ''"
              (ngModelChange)="this.cuboidDepthChanged($event, depthInput)">
          </div>
        </div>
      </tab>
      <tab heading="{{ 'TRACKER_POPUP_PAGE_TAB' | translate }}" class="popup-page-tab tab"
        (selectTab)="this.currentTab = tabType.PopupTab">
        <div class="smartphone-container">
          <div class="checkbox-container">
            <label class="checkbox">
              <input type="checkbox" (click)="this.toggleUseCustomPopup()" [checked]="this.useCustomPopup">
              <span class="checkmark" triggers="hover"></span>
              <div class="checkbox-text">
                {{ 'USE_CUSTOM_POPUP' | translate }}
              </div>
            </label>
          </div>
          <aacms-popup-page #popupEditor class="smartphone" [(uiElements)]="this.cuboid.popupElements" [isVisible]="currentTab === tabType.PopupTab"
            (uiElementsChange)="checkForContentChange()" [language]="this.currentLanguage" title="{{ 'POPUP_TITLE_CUBOID_ELEMENT' | translateWith:this.currentLanguage }}"
            [enabled]="this.useCustomPopup">
            <p popup-left-side-above>
              {{ 'TRACKER_POPUP_PAGE_HELP' | translate }}
            </p>
            <ng-container popup-editor-default-content>
              <ng-template [ngIf]="!this.useCustomPopup">
                <div class="element">
                  <div class="image-element">
                    <img class="image" src="../../assets/popups/Augmented_Reality_Tut.png">
                  </div>
                </div>
                <div class="element">
                  <div class="text-element">
                    {{ 'POPUP_TEXT_CUBOID_ELEMENT' | translateWith:this.currentLanguage }}
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </aacms-popup-page>
        </div>
      </tab>
    </tabset>

    <div class="custom-hr"></div>
    <div class="button-row row">
      <button type="submit" class="btn btn-secondary custom-button" (click)="this.returnToCuboid()">
        {{'BACK_TO_CUBOID' | translate}}
      </button>
      <button type="submit" class="btn btn-warning custom-button"
        [disabled]="!this.contentChanged && !pms.poisChanged && !this.cuboidImageChanged"
        (click)="openModal(cancelModalTemplate)">
        {{'DISCARD' | translate }}
      </button>
      <button type="submit" class="btn btn-success custom-button"
        [disabled]="!this.contentChanged && !pms.poisChanged && !this.cuboidImageChanged" (click)="save()">
        {{'SAVE_CHANGES' | translate}}
      </button>
    </div>
  </div>
</div>

<ng-template #cancelModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
  </div>
  <div class="modal-footer">
    <button (click)="this.modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
    <button (click)="discard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
  </div>
</ng-template>
