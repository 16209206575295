<div class="ar-layer-main-content">
  <div class="ar-layer-left-side" *ngIf="this.cuboid">
    <div class="slidecontainer">
      <input type="range" min="0" max="100" [(ngModel)]="this.arLayerOpacity" class="slider" id="myRange"
        [matTooltip]="'CHANGE_OPACITY' | translate" triggers="hover"
       >
      <button class="enable-tracker-button btn btn-light" (click)="this.enableTrackerLayer = !this.enableTrackerLayer"
        [matTooltip]="'ENABLE_DISABLE_TRACKER' | translate" triggers="hover"
       >
        <i *ngIf="this.enableTrackerLayer" class="fa fa-eye-slash" aria-hidden="true"></i>
        <i *ngIf="!this.enableTrackerLayer" class="fa fa-eye" aria-hidden="true"></i>
      </button>
    </div>
    <aacms-cuboid-viewer #cuboidViewer class="cuboid-viewer" [cuboid]="this.cuboid"
      [arLayerOpacity]="this.arLayerOpacity" [enableTrackerLayer]="this.enableTrackerLayer"
      [(imageDataMap)]="this.cuboidImageDataMap" [arLayer]="this.arLayer"
      [(arLayerImageDataMap)]="this.arLayerImageDataMap" [(sideModel)]="this.currentSelectedImage"
      [enableFileControl]="false" [currentLanguage]="this.currentLanguage">
    </aacms-cuboid-viewer>
  </div>
  <div class="ar-layer-right-side">
    <ng-template [ngIf]="this.arLayer && this.arLayer.title">
      <div class="header">
        <div class="path-title-container">
          <div class="path">
            <div class="path-option" (click)="this.routeTo(appState.exhibitions)">{{'EXHIBITIONS' | translate}}</div>
            /
            <div class="path-option" (click)="this.routeTo(appState.images)">{{ Tools.shortenString(contextService.currentExhibition && contextService.currentExhibition.title && getLanguageValue(contextService.currentExhibition.title).length > 0 ? getLanguageValue(contextService.currentExhibition.title) : ('TRACKERS' | translate)) }}</div>
            /
            <div class="path-option" (click)="this.routeTo(appState.editCuboid)">{{ Tools.shortenString(cuboid && getLanguageValue(cuboid.title).length > 0 ? getLanguageValue(cuboid.title) : ('CUBOID' | translate)) }}</div>
            /
            <div class="last-path-option">{{ Tools.shortenString(arLayer && getLanguageValue(arLayer.title).length > 0 ? getLanguageValue(arLayer.title) : ('AR_LAYER' | translate)) }}</div>
          </div>
          <div class="arlayer-title-edit-container">
            <h1 class="edit-indicator">
              <i class="fa fa-pencil" aria-hidden="true"
                [ngStyle]="{'color': arLayerChanged || pms.poisChanged ? '#ffc107' : 'black' }"></i>
            </h1>
            <div class="arlayer-title-container">
              <h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.arLayer.title).length > 0">
                {{this.getLanguageValue(this.arLayer.title)}}
              </h1>
              <h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.arLayer.title).length == 0">
                <i>{{'UNNAMED_AR_LAYER' | translate }}</i>
              </h1>
            </div>
          </div>
        </div>
        <aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
          (languageChanged)="this.updateLanguage($event)">
        </aacms-language-selector>
      </div>
      <div class="custom-hr"></div>
      <div>
        <i>{{ 'AR_LAYER_POI' | translate }}:</i>
      </div>
      <div class="ar-layer-poi" (click)="selectPoi(null)"
        [style.border]="!this.pms.selectedPoi ? '2px solid #87ceeb': '2px solid gainsboro'">
        <div class="ar-layer-poi-meta">
          <div class="ar-layer-poi-title">
            <span *ngIf="this.getLanguageValue(this.arLayer.title).length > 0">
              {{this.getLanguageValue(this.arLayer.title)}}
            </span>
            <i *ngIf="this.getLanguageValue(this.arLayer.title).length === 0">
              {{ 'UNNAMED_AR_LAYER' | translate }}
            </i>
          </div>
        </div>
        <div class="ar-layer-poi-edit-buttons">
          <button class="ar-layer-poi-edit-button" *ngIf="config.getEnabledContentTypes().length > 1"
            (click)="toggleTypeArLayer(this.arLayer, $event)" [matTooltip]="'TOGGLE_TYPE' | translate" triggers="hover"
           >
            <i *ngIf="this.arLayer.contentType == contentTypes.URL" class="fa fa-globe exhibition-icon"
              aria-hidden="true"></i>
            <i *ngIf="this.arLayer.contentType == contentTypes.Custom" class="fa fa-file exhibition-icon"
              aria-hidden="true"></i>
          </button>
          <button class="ar-layer-poi-edit-button" (click)="routeToEditArLayerMeta()" [matTooltip]="'EDIT' | translate" triggers="hover">
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </button>
          <button class="ar-layer-poi-edit-button" (click)="this.removeArLayerDescription()"
            [matTooltip]="'REMOVE_ARLAYER_DESCRIPTION' | translate" triggers="hover">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="custom-hr"></div>
      <div class="poi-list-label">
        {{ 'POI_LIST_LABEL' | translate }}
      </div>
      <div class="poi-info">
        <div class="poi-list">
          <ng-template ngFor let-poi [ngForOf]="this.pms.pois">
            <div class="poi" (click)="selectPoi(poi)" [style]="getPoiListEntryStyle(poi)">
              <div class="poi-meta" [style]="getPoiListTitleStyle(poi)">
                <div class="poi-title">
                  <span *ngIf="this.getLanguageValue(poi.title).length > 0">
                    <b class="poi-position">#{{poi.position + 1}}</b> {{this.getLanguageValue(poi.title)}}
                  </span>
                  <i *ngIf="this.getLanguageValue(poi.title).length === 0">
                    <b class="poi-position">#{{poi.position + 1}}</b> {{ 'UNNAMED_POI' | translate }}
                  </i>
                </div>
              </div>
              <div class="poi-edit-buttons">
                <button class="poi-edit-button" *ngIf="config.getEnabledContentTypes().length > 1"
                  (click)="toggleType(poi, $event)" [matTooltip]="'TOGGLE_TYPE' | translate" triggers="hover"
                 >
                  <i *ngIf="poi.contentType == contentTypes.URL" class="fa fa-globe exhibition-icon"
                    aria-hidden="true"></i>
                  <i *ngIf="poi.contentType == contentTypes.Custom" class="fa fa-file exhibition-icon"
                    aria-hidden="true"></i>
                </button>
                <button class="poi-edit-button" (click)="routeToEditPoi(poi)" [matTooltip]="'EDIT' | translate" triggers="hover">
                  <i class="fa fa-pencil exhibition-icon" aria-hidden="true"></i>
                </button>
                <button *ngIf="!poi.active" class="poi-edit-button" (click)="toggleActive(poi, $event)"
                  [matTooltip]="'ACTIVATE_FOR_PUBLISH' | translate" triggers="hover"
                 >
                  <i class="fa fa-eye-slash exhibition-icon" aria-hidden="true"></i>
                </button>
                <button *ngIf="poi.active" class="poi-edit-button" (click)="toggleActive(poi, $event)"
                  [matTooltip]="'DEACTIVATE_FOR_PUBLISH' | translate" triggers="hover"
                 >
                  <i class="fa fa-eye exhibition-icon" aria-hidden="true"></i>
                </button>
                <button class="poi-edit-button" (click)="deletePoi(poi, $event)" [matTooltip]="'DELETE' | translate" triggers="hover">
                  <i class="fa fa-trash exhibition-icon" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="move-buttons">
          <button type="submit" class="move-button btn btn-light" (click)="movePoiUp()"
            [matTooltip]="'MOVE_POI_UP' | translate" matTooltipPosition="left">
            <i class="fa fa-angle-up" aria-hidden="true"></i>
          </button>
          <button type="submit" class="move-button btn btn-light" (click)="movePoiDown()"
            [matTooltip]="'MOVE_POI_DOWN' | translate" matTooltipPosition="left">
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="custom-hr"></div>
      <div class="row button-row">
        <button (click)="cancel()" type="button"
          class="btn btn-secondary custom-button">{{'BACK_TO_CUBOID' | translate }}</button>
        <button (click)="openModal(deleteModalTemplate)" type="button"
          class="btn btn-danger custom-button">{{'DELETE_ARLAYER' | translate }}</button>
        <button (click)="openModal(cancelModalTemplate)" type="button"
          [disabled]="!this.pms.poisChanged && !this.arLayerChanged"
          class="btn btn-warning custom-button">{{'DISCARD' | translate }}</button>
        <button (click)="onSave()" type="button" [disabled]="!this.pms.poisChanged && !this.arLayerChanged"
          class="btn btn-success custom-button">{{'SAVE' | translate }}</button>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #deleteModalTemplate>
  <div class="modal-header">
    <h4 *ngIf="!arLayer.title.de" class="modal-title text-break pull-left">{{'REALY_DELETE_IMAGE' | translate }}</h4>
    <h4 *ngIf="arLayer.title.de" class="modal-title text-break pull-left">
      {{'REALY_DELETE_IMAGE1' | translate }}
      <i>'{{arLayer.title.de}}'</i> {{'REALY_DELETE_IMAGE2' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
    <button (click)="delete()" type="button" class="btn btn-danger">{{'YES_DELETE' | translate }}</button>
  </div>
</ng-template>

<ng-template #cancelModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
  </div>
  <div class="modal-footer">
    <button (click)="onSave()" type="button" class="btn btn-success">{{'SAVE'| translate}}</button>
    <button (click)="onDiscard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
  </div>
</ng-template>
