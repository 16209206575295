<div class="cuboid-main-content">
  <div class="cuboid-left-side" *ngIf="this.cuboid">
    <aacms-cuboid-viewer #cuboidViewer class="cuboid-viewer" [cuboid]="this.cuboid" [(imageDataMap)]="imageDataMap" [currentLanguage]="this.currentLanguage"
      [enableFileControl]="false"></aacms-cuboid-viewer>
  </div>

  <div class="cuboid-right-side">
    <ng-template [ngIf]="this.cuboid">
      <div class="header">
        <div class="path-title-container">
          <div class="path">
            <div class="path-option" (click)="this.routeTo(appState.exhibitions)">{{'EXHIBITIONS' | translate}}</div>
            /
            <div class="path-option" (click)="this.routeTo(appState.images)">{{ Tools.shortenString(contextService.currentExhibition && contextService.currentExhibition.title && getLanguageValue(contextService.currentExhibition.title).length > 0 ? getLanguageValue(contextService.currentExhibition.title) : ('TRACKERS' | translate)) }}</div>
            /
            <div class="last-path-option">{{ Tools.shortenString(cuboid && getLanguageValue(cuboid.title).length > 0 ? getLanguageValue(cuboid.title) : ('CUBOID' | translate)) }}</div>
          </div>
          <div class="arlayer-title-edit-container">
            <h1 class="edit-indicator">
              <i class="fa fa-pencil" aria-hidden="true"
                [ngStyle]="{'color': this.cuboidChanged || this.arLayersChanged ? '#ffc107' : 'black' }"></i>
            </h1>
            <div class="arlayer-title-container">
              <h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.cuboid.title).length > 0">
                {{this.getLanguageValue(this.cuboid.title)}}
              </h1>
              <h1 class="arlayer-title" *ngIf="this.getLanguageValue(this.cuboid.title).length == 0">
                <i>{{'UNNAMED_CUBOID' | translate }}</i>
              </h1>
            </div>
          </div>
        </div>
        <aacms-language-selector class="language-selector" [currentLanguage]="this.currentLanguage"
          (languageChanged)="this.updateLanguage($event)">
        </aacms-language-selector>
      </div>
      <div class="custom-hr"></div>
      <div>
        <i>{{ 'CUBOID_META' | translate }}:</i>
      </div>
      <div class="cuboid-info">
        <div class="size-missing-indicator"
          *ngIf="this.cuboid.width === 0 || this.cuboid.height === 0 || this.cuboid.depth === 0"
          [matTooltip]="'MISSING_META_INFORMATION' | translate" triggers="hover">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        </div>
        <div class="cuboid-info-meta">
          <div class="cuboid-title">
            <span *ngIf="this.getLanguageValue(this.cuboid.title).length > 0">
              {{this.getLanguageValue(this.cuboid.title)}}
            </span>
            <i *ngIf="this.getLanguageValue(this.cuboid.title).length === 0">
              {{ 'UNNAMED_CUBOID' | translate }}
            </i>
          </div>
        </div>
        <div class="cuboid-edit-buttons">
          <button class="cuboid-edit-button" (click)="this.routeToEditCuboidMeta()" [matTooltip]="'EDIT' | translate" triggers="hover">
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </button>
          <button class="cuboid-edit-button" (click)="this.removeCuboidDescription()"
            [matTooltip]="'REMOVE_CUBOID_DESCRIPTION' | translate" triggers="hover"
            matTooltipPosition="left" >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="custom-hr"></div>
      <div class="arlayer-list-label">
        {{ 'AR_LAYER_LIST_LABEL' | translate }}
      </div>
      <div *ngIf="this.arLayers" class="arlayer-info">
        <div class="arlayer-list">
          <ng-template ngFor let-arlayer [ngForOf]="this.arLayers">
            <div class="arlayer">
              <div class="arlayer-meta" [style]="this.getArLayerListTitleStyle(arlayer)">
                <div class="arlayer-title">
                  <span *ngIf="this.getLanguageValue(arlayer.title).length > 0">
                    <b class="arlayer-position">#{{arlayer.position + 1}}</b> {{this.getLanguageValue(arlayer.title)}}
                  </span>
                  <i *ngIf="this.getLanguageValue(arlayer.title).length === 0">
                    <b class="arlayer-position">#{{arlayer.position + 1}}</b> {{ 'UNNAMED_AR_LAYER' | translate }}
                  </i>
                </div>
              </div>
              <div class="arlayer-edit-buttons">
                <button class="arlayer-edit-button" (click)="routeToEditArLayer(arlayer)" [matTooltip]="'EDIT' | translate" triggers="hover">
                  <i class="fa fa-pencil exhibition-icon" aria-hidden="true"></i>
                </button>
                <button *ngIf="!arlayer.active" class="arlayer-edit-button" (click)="toggleActive(arlayer, $event)"
                  [matTooltip]="'ACTIVATE_FOR_PUBLISH' | translate" triggers="hover"
                  >
                  <i class="fa fa-eye-slash exhibition-icon" aria-hidden="true"></i>
                </button>
                <button *ngIf="arlayer.active" class="arlayer-edit-button" (click)="toggleActive(arlayer, $event)"
                  [matTooltip]="'DEACTIVATE_FOR_PUBLISH' | translate" triggers="hover"
                  >
                  <i class="fa fa-eye exhibition-icon" aria-hidden="true"></i>
                </button>
                <button class="arlayer-edit-button" (click)="deleteArLayer(arlayer, $event)"
                  [matTooltip]="'DELETE' | translate" triggers="hover" 
                 >
                  <i class="fa fa-trash exhibition-icon" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="move-buttons">
          <button type="button" class="move-button btn btn-light" (click)="moveArLayerUp()"
            [matTooltip]="'MOVE_ARLAYER_UP' | translate" matTooltipPosition="left" >
            <i class="fa fa-angle-up" aria-hidden="true"></i>
          </button>
          <button type="button" class="move-button btn btn-light" (click)="addArLayer()"
            [matTooltip]="'ADD_ARLAYER' | translate" matTooltipPosition="left" >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
          <button type="button" class="move-button btn btn-light" (click)="moveArLayerDown()"
            [matTooltip]="'MOVE_ARLAYER_DOWN' | translate" matTooltipPosition="left" >
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="custom-hr"></div>
      <div class="row button-row">
        <button (click)="cancel()" type="button"
          class="btn btn-secondary custom-button">{{'BACK_TO_OVERVIEW' | translate }}</button>
        <button (click)="openModal(deleteModalTemplate)" type="button"
          class="btn btn-danger custom-button">{{'DELETE_CUBOID' | translate }}</button>
        <button (click)="openModal(cancelModalTemplate)" type="button" [disabled]="!cuboidChanged && !arLayersChanged"
          class="btn btn-warning custom-button">{{'DISCARD' | translate }}</button>
        <button (click)="onSave()" type="button" [disabled]="!cuboidChanged && !arLayersChanged"
          class="btn btn-success custom-button">{{'SAVE' | translate }}</button>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #deleteModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-break pull-left">{{'REALY_DELETE_CUBOID' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-warning">{{'NO_ABORT' | translate }}</button>
    <button (click)="delete()" type="button" class="btn btn-danger">{{'YES_DELETE' | translate }}</button>
  </div>
</ng-template>

<ng-template #cancelModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title text-break pull-left">{{'REALY_DISMISS' | translate}}</h4>
  </div>
  <div class="modal-footer">
    <button (click)="onSave()" type="button" class="btn btn-success">{{'SAVE'| translate}}</button>
    <button (click)="onDiscard()" type="button" class="btn btn-danger">{{'YES_DISMISS' | translate }}</button>
  </div>
</ng-template>
