import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { AlertService } from '../services/alert.service';
import { StringService } from '../services/string.service';
import { AlertSimple, AlertType } from '../models/Alert';

/**
 * Auth Guard
 * Activates / deactivates routes.
 * Only logged in users have access.
 */
@Injectable()
export class AuthGuard implements CanActivate {

    /**
     * Constructor
     * @param authService The authentication service.
     * @param router A Router to navigate.
     * @param alertService The alert service to display alerts.
     * @param stringService The string service
     */
    public constructor(
        private authService: AuthService,
        private router: Router,
        private alertService: AlertService,
        private stringService: StringService
    ) { }

    /**
     * Decides whether a user can see the page / the route can be activated.
     * @returns true if the user is logged in.
     */
    public canActivate(): boolean {
        if (this.authService.getUser() && this.authService.getUser().isLoggedIn()) {
            return true;
        } else {
            this.alertService.alert(
              new AlertSimple(
                this.stringService.get('LOGIN_REQUIRED'),
                AlertType.Error),
              true);
            this.router.navigate(['/login']);
            return false;
        }
    }
}
