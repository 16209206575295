import { Output, EventEmitter, Component, Input } from '@angular/core';
import { TriggerBehavior } from '../../models/TriggerBehavior';

@Component({
	selector: 'input-trigger-behavior',
	templateUrl: './input-trigger-behavior.component.html',
	styleUrls: ['./input-trigger-behavior.component.scss']
})
export class InputTriggerBehaviorComponent {

	public TriggerBehavior = TriggerBehavior;

	@Input() triggerBehavior: TriggerBehavior = TriggerBehavior.Always;
	@Output() triggerBehaviorChange: EventEmitter<TriggerBehavior> = new EventEmitter<TriggerBehavior>();

	public constructor() { }

	public update() {
		this.triggerBehaviorChange.emit(this.triggerBehavior);
	}

	public onSelectClicked(event: MouseEvent) {
		event.stopPropagation();
	}

}
